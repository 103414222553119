import { useState } from "react";
import axios from "axios";
import { PostMarketingAdd, UpdateMarketingAdd } from "../../services/constant";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Textarea } from '@mui/joy';
import { useDispatch } from "react-redux";


export const AddLeadForm = ({ condition, row, callback }) => {
    const dispatch = useDispatch()
    const authToken = localStorage.getItem('authToken')

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });

        !row && setFormData({})

    };

    const [formdata, setFormData] = useState({
        contactPerson: row?.contactPerson,
        mobileNumber: row?.mobileNumber,
        organizationNameOrBizGenerator: row?.organizationNameOrBizGenerator,
        email: row?.email,
        address: row?.address,
        designation: row?.designation,
        aiTotalShipments: row?.aiTotalShipments,
        aeTotalShipments: row?.aeTotalShipments,
        oifclTotalShipments: row?.oifclTotalShipments,
        oilclTotalShipments: row?.oilclTotalShipments,
        oefclTotalShipments: row?.oefclTotalShipments,
        oelclTotalShipments: row?.oelclTotalShipments,
        referredBy: row?.referredBy,
        File: row?.file,
        specialRequirementOrRemarks: row?.specialRequirementOrRemarks
    });


    const [choosenFile, setChoosenFile] = useState(row?.file);
    const [aiShipments, setaiShipments] = useState(row?.aiShipments || false)
    const [aeShipments, setaeShipments] = useState(row?.aeShipments || false)
    const [oifclShipments, setoifclShipments] = useState(row?.oifclShipments || false)
    const [oilclShipments, setoilclShipments] = useState(row?.oilclShipments || false)
    const [oefclShipments, setoefclShipments] = useState(row?.oefclShipments || false)
    const [oelclShipments, setoelclShipments] = useState(row?.oelclShipments || false)

    const requestData = {
        Lead: (() => {
            const leadObject = {
                contactPerson: formdata.contactPerson,
                mobileNumber: formdata.mobileNumber,
                // organizationNameOrBizGenerator: formdata.organizationNameOrBizGenerator,
                // email: formdata.email,
                // address: formdata.address,
                // designation: formdata.designation,
                aiShipments: aiShipments,
                aeShipments: aeShipments,
                oifclShipments: oifclShipments,
                oilclShipments: oilclShipments,
                oefclShipments: oefclShipments,
                oelclShipments: oelclShipments,
                // referredBy: formdata.referredBy,
                File: formdata?.File,
                specialRequirementOrRemarks: formdata.specialRequirementOrRemarks,
                state: 1
            };

            // Conditionally add properties with default values if the condition is met
            if (formdata.aiTotalShipments || aiShipments) leadObject.aiTotalShipments = formdata.aiTotalShipments;
            if (formdata.aeTotalShipments || aeShipments) leadObject.aeTotalShipments = formdata.aeTotalShipments;
            if (formdata.oifclTotalShipments || oifclShipments) leadObject.oifclTotalShipments = formdata.oifclTotalShipments;
            if (formdata.oilclTotalShipments || oilclShipments) leadObject.oilclTotalShipments = formdata.oilclTotalShipments;
            if (formdata.oefclTotalShipments || oefclShipments) leadObject.oefclTotalShipments = formdata.oefclTotalShipments;
            if (formdata.oelclTotalShipments || oelclShipments) leadObject.oelclTotalShipments = formdata.oelclTotalShipments;

            if (formdata.organizationNameOrBizGenerator) leadObject.organizationNameOrBizGenerator = formdata.organizationNameOrBizGenerator;
            if (formdata.email) leadObject.email = formdata.email;
            if (formdata.address) leadObject.address = formdata.address;
            if (formdata.designation) leadObject.designation = formdata.designation;
            if (formdata.referredBy) leadObject.referredBy = formdata.referredBy;



            return leadObject;
        })(),
        File: formdata.File

    }


    const formSubmit = () => {
        dispatch({ type: "LOADER", payload: true });


        const leadLogValiator = hasUndefinedValue(requestData.Lead)

        function hasUndefinedValue(obj) {
            const excludedKeys = ['aiShipments', 'aeShipments', 'oifclShipments', 'oilclShipments', 'oefclShipments', 'oelclShipments'];
            let changes = {};
            // Check if any value in the object is undefined
            Object.entries(obj).forEach(([key, value]) => {
                // debugger
                if (
                    (key === 'aiTotalShipments' && !aiShipments) ||
                    (key === 'aeTotalShipments' && !aeShipments) ||
                    (key === 'oifclTotalShipments' && !oifclShipments) ||
                    (key === 'oilclTotalShipments' && !oilclShipments) ||
                    (key === 'oefclTotalShipments' && !oefclShipments) ||
                    (key === 'oelclTotalShipments' && !oelclShipments) ||
                    key === 'File' ||
                    key === 'referredBy' ||
                    key === 'designation' ||
                    key === 'address' ||
                    key === 'email' ||
                    key === 'organizationNameOrBizGenerator'
                ) {
                    dispatch({ type: "LOADER", payload: false });

                    return false;
                }
                else if (!value && !excludedKeys.includes(key)) {
                    // changes[key] = value;
                    changes[key + 'error'] = !value;
                }
            });
            return changes;

        }

        if (Object.keys(leadLogValiator).length > 0) {
            setFormData({
                ...formdata,
                ...leadLogValiator
            })
        }
        else if (
            !aiShipments &&
            !aeShipments &&
            !oifclShipments &&
            !oilclShipments &&
            !oefclShipments &&
            !oelclShipments
        ) {
            dispatch({
                type: "SNACKBARALERT", payload: {
                    active: true,
                    message: 'select atleast one shipment',
                    type: 'error'
                }
            });
        }
        else {
            const formData = new FormData();

            const {
                File, // Assuming this is a file
                Lead: {
                    leadLogList,
                    leadEmailList,
                    leadEmailAttechmentList,
                    ...restFormData
                },
            } = requestData;

            // Append the main form data
            Object.entries(restFormData).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    // Handle arrays
                    value.forEach((item, index) => {
                        Object.entries(item).forEach(([nestedKey, nestedValue]) => {
                            formData.append(`Lead.${key}[${index}].${nestedKey}`, nestedValue);
                        });
                    });
                } else {
                    // Handle non-array values
                    formData.append(`Lead.${key}`, value);
                }
            });

            // Handle File
            formData.append('File', File);

            // Logging for debugging
            console.log('formData', formData);

            const headers = {
                Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
            };

            // Assuming PostMarketingAdd() returns the correct URL
            axios.post(row ? UpdateMarketingAdd() : PostMarketingAdd(), formData, { headers })
                .then((response) => {
                    // after submit success close the drawer 
                    callback()
                    setState({ ...state, ['right']: false });

                    console.log(response.data);

                })
                .catch((error) => {
                    console.error('Error submitting form:', error);
                });
        }


    };


    const handleFieldChange = (fieldName, value) => {


        setFormData({
            ...formdata,
            [fieldName]: value,
            [fieldName + 'error']: !value,
        });


        console.log(formdata)
    };








    return (
        <>
            <Tooltip title={condition?.tooltitle ? condition?.tooltitle : 'Add Lead'}>
                <IconButton className={condition?.iconClass ? condition.iconClass : 'table-toggle'} onClick={toggleDrawer('right', true)} >
                    {condition?.icon ? condition?.icon : <AddIcon />}
                </IconButton>
            </Tooltip >

            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >
                <Box
                    sx={{ width: 470 }}
                    role="presentation"
                // onKeyDown={toggleDrawer('right', false)}
                >
                    <List>
                        <div className='table-divi-drawer'>

                            <div>
                                <h5>{condition?.title ? condition?.title : 'Add Lead'}</h5>
                            </div>

                            <div >
                                <Tooltip title='close'>
                                    <IconButton onClick={toggleDrawer('right', false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>

                        </div>

                        <Divider />

                        <ListItem>
                            <div className='' style={{ width: "418px" }}>

                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <TextField fullWidth label={'Contact Person*'} type='text' id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.contactPerson} error={formdata.contactPersonerror} onChange={(e) => handleFieldChange('contactPerson', e.target.value)} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <TextField fullWidth label={'Mobile NO*'} type="number" id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.mobileNumber} error={formdata.mobileNumbererror} onChange={(e) => handleFieldChange('mobileNumber', e.target.value)} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <TextField fullWidth label={'Org. Name/Biz. Generator'} id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.organizationNameOrBizGenerator} error={false && formdata.organizationNameOrBizGeneratorerror} onChange={(e) => handleFieldChange('organizationNameOrBizGenerator', e.target.value)} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <TextField fullWidth label={'Email'} type="email" id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.email} error={false && formdata.emailerror} onChange={(e) => handleFieldChange('email', e.target.value)} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <TextField fullWidth label={'Address*'} id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.address} error={false && formdata.addresserror} onChange={(e) => handleFieldChange('address', e.target.value)} />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <TextField fullWidth label={'Designation'} id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.designation} error={false && formdata.designationerror} onChange={(e) => handleFieldChange('designation', e.target.value)} />
                                    </div>
                                    <div className="col-12">
                                        <p style={{ color: "gray", fontSize: '13px', marginTop: "2rem" }}>Type & Expected No. of Shipments</p>


                                        <ul className="addleadform-ul">
                                            <li>
                                                <Tooltip title='Air Import'><span>   <input type="checkbox" checked={aiShipments} onClick={() => setaiShipments(!aiShipments)} /> Al</span></Tooltip>
                                                <TextField fullWidth label={'No'} type="number" id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.aiTotalShipments} error={formdata.aiTotalShipmentserror && aiShipments} onChange={(e) => handleFieldChange('aiTotalShipments', e.target.value)} />
                                            </li>
                                            <li>
                                                <Tooltip title='Air Export'><span> <input type="checkbox" checked={aeShipments} onClick={() => setaeShipments(!aeShipments)} /> AE</span></Tooltip>
                                                <TextField fullWidth label={'No'} type="number" id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.aeTotalShipments} error={formdata.aeTotalShipmentserror && aeShipments} onChange={(e) => handleFieldChange('aeTotalShipments', e.target.value)} />
                                            </li>
                                            <li>
                                                <Tooltip title='OIFCL'><span> <input type="checkbox" checked={oifclShipments} onClick={() => setoifclShipments(!oifclShipments)} /> OIFCL</span></Tooltip>
                                                <TextField fullWidth label={'No'} type="number" id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.oifclTotalShipments} error={formdata.oifclTotalShipmentserror && oifclShipments} onChange={(e) => handleFieldChange('oifclTotalShipments', e.target.value)} />
                                            </li>
                                            <li>
                                                <Tooltip title='OILCL'><span> <input type="checkbox" checked={oilclShipments} onClick={() => setoilclShipments(!oilclShipments)} /> OILCL</span></Tooltip>
                                                <TextField fullWidth label={'No'} type="number" id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.oilclTotalShipments} error={formdata.oilclTotalShipmentserror && oilclShipments} onChange={(e) => handleFieldChange('oilclTotalShipments', e.target.value)} />
                                            </li>
                                            <li>
                                                <Tooltip title='OE-FCL'><span> <input type="checkbox" checked={oefclShipments} onClick={() => setoefclShipments(!oefclShipments)} /> OE-FCL</span></Tooltip>
                                                <TextField fullWidth label={'No'} type="number" id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.oefclTotalShipments} error={formdata.oefclTotalShipmentserror && oefclShipments} onChange={(e) => handleFieldChange('oefclTotalShipments', e.target.value)} />
                                            </li>
                                            <li>
                                                <Tooltip title='OE-LCL'><span> <input type="checkbox" checked={oelclShipments} onClick={() => setoelclShipments(!oelclShipments)} /> OE-LCL</span></Tooltip>
                                                <TextField fullWidth label={'No'} type="number" id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.oelclTotalShipments} error={formdata.oelclTotalShipmentserror && oelclShipments} onChange={(e) => handleFieldChange('oelclTotalShipments', e.target.value)} />
                                            </li >
                                        </ul >

                                    </div >

                                    <div className="col-md-6 mt-5">
                                        <TextField fullWidth label={'Referred By'} id="fullWidth" sx={{ width: '100%', fontSize: '13px' }} size="small" value={formdata.referredBy} error={false && formdata.referredByerror} onChange={(e) => handleFieldChange('referredBy', e.target.value)} />
                                    </div>

                                    <div className="col-md-6 mt-5">
                                        <label htmlFor="Choose-File" className='attach-btn ' style={{ color: "gray", borderColor: formdata.Fileerror ? 'red' : '' }}>
                                            {<AttachFileIcon />} <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{choosenFile ? choosenFile : 'Choose File'}</span>
                                        </label>
                                        <input type="file" id="Choose-File" name='Choose-File' onChange={(e) => { handleFieldChange('File', e.target.files[0]); setChoosenFile(e.target.value) }} className="d-none" />
                                    </div>
                                    <div className="col-12">
                                        <p style={{ color: "gray", fontSize: '13px', marginTop: "2rem", marginBottom: "0" }}>Special Requirements/ Remarks*</p>

                                        <Textarea minRows={4} value={formdata.specialRequirementOrRemarks} error={formdata.specialRequirementOrRemarkserror} onChange={(e) => { handleFieldChange('specialRequirementOrRemarks', e.target.value) }} />
                                        {/* <FloatingLabelTextarea option={{ placeholder: "Special Requirements/ Remarks", label: "Special Requirements/ Remarks" }}
            callback={onChange} /> */}
                                    </div>
                                </div >


                            </div >
                        </ListItem>



                        <ListItem disablePadding sx={{ justifyContent: "center" }}>
                            <Button sx={{ m: 2, borderRadius: "10px" }} onClick={toggleDrawer('right', false)} variant="outlined">Cancel</Button>
                            <Button sx={{ m: 2, borderRadius: "10px" }} type='submit' onClick={formSubmit} variant="contained">Submit</Button>
                        </ListItem>



                    </List>
                    {/* <Divider /> */}
                </Box>
            </Drawer>
        </>
    );
}



import React, { useEffect, useState } from "react";
import Dropdown, { DateField, InputField, InputFileField, StackDropdown, TextAreafield, TimeField } from "../../component/controls";
import { GetAirlineMasterByPage, GetAirportMasterByPage, GetAllEmployee, GetChargeHeadByPage, GetConsigneeSubAgencyPage, GetCragoTypeByPage, GetCustomerByPage, GetCustomerBySearch, GetEmployeeByPage, GetFinancialYear, GetMasterBranchByPage, GetMasterCityByPage, GetMasterVendorTypeByPage, GetNotifyMasterByPage, GetVehicalTypeByPage, PostVendorMaster } from "../../../services/constant";
import GetCaller, { PostCaller } from "../../../services/apiServices";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AirExportCustomerTypeFrightOptions, ChallanType, DestinationType, EnquiryDimension, GoodsConditions, PickUpType, SingleShipmentType, TypeOfCustomer, TypeOfFPC, applicableFormat, frightpp, goodsExpectedItem, mawbshippers, pretransport } from "../../../services/modalData";
import { Job_MawbRate } from "./component/mawbRate";
import { Job_HawbRate } from "./component/hawbRate";
import { GetAirExportPlannerDetailsJobbyId, UpdateAirExportPermanentJob } from "../../../services/APIConstant/jobConstant";
import { Box, Button, IconButton, Tab } from "@mui/material";
import { ShipperInvoiceNumber } from "./component/shipperInvoicenum";
import { SlotNumber } from "./component/slotNumber.";
import { TcNumber } from "./component/tcNumbers";
import { airexportjoblist } from "../../../navigation/constant";
import { Add, Close } from "@mui/icons-material";
import { columnConstant, formconstant } from "../../constant/formconstant";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CreateCddForm } from "./component/createCddForm";
import { AddAirExportPermanentJob, GetAirExportPermanentJobDetailsById } from "../../../services/APIConstant/AirExport.jsx/finalJob";
import FinalBuying from "./component/finalBuying";
import FinalSelling from "./component/finalSelling";
import GetSelectedFreightDetails from "../../../services/Frieght Services/AirExportFreightServices/GetSelectedFreightDetails";
import getFrieghtServices from "../../../services/Frieght Services/AirExportFreightServices/getFrieghtServices";
import { MultiDeliveryAddress, MultiPickupAddress } from "../enquiry/airexport";
import { CalculateTotalAirVolumnWeight } from "../../../services/utilities/calculateTotalVolumeWeight";
import { ConvertValue } from "../../../services/utilities/valueConverter";
import { ArrayObjectHasUndefinedValue } from "../../../services/utilities/ArrayObjectHasUndefinedValue";

const CreateNewJobForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [tabValue, setTabValue] = useState('1');
    const [openTable, setOpenTable] = useState(true);
    const [edit, setEdit] = useState(false);
    const [jobDetailsData, setJobDetailsData] = useState(false)


    const [FreightItems, setFreightItems] = useState([])
    const [FreightServiceSubType, setFreightServiceSubType] = useState([])
    const [BizCategoryType, setBizCategoryType] = useState([])
    const [BizType, setBizType] = useState([])
    const [BizSubType, setBizSubType] = useState([])

    const [ONP, setONP] = useState({})
    const [MultiPickUp, setMultiPickUp] = useState(false)
    const [MultiDelivery, setMultiDelivery] = useState(false)

    const [shipperMasterType, setShipperMasterType] = useState(false)
    const [consigneeMasterType, setConsigneeMasterType] = useState(false)

    const [deliveryDetailsError, setDeliveryDetailsError] = useState({});
    const [pickupDetailsError, setPickupDetailsError] = useState({});

    const [deliveryTotalPackages, setDeliveryTotalPackages] = useState(0);
    const [deliveryTotalGrossWeight, setDeliveryTotalGrossWeight] = useState(0);

    const [pickupTotalPackages, setPickupTotalPackages] = useState(0);
    const [pickupTotalGrossWeight, setPickupTotalGrossWeight] = useState(0);


    const [financialyear, setFinancialYear] = useState([])
    const [branchitem, setBranchItem] = useState([]);
    const [cargoItem, setCargoItem] = useState([])
    const [cityItem, setCityItem] = useState([])

    const [airlineitem, setAirlineItem] = useState([])
    const [airportItem, setAirportItem] = useState([])
    const [employeeItem, setEmployeeItem] = useState([])
    const [consigneeItem, setConsigneeItem] = useState([])
    const [consigneeAddressItem, setConsigneeAddressItem] = useState([])
    const [notifyPartyItem, setNotifyPartyItem] = useState([])
    const [chargeHeadItem, setChargeHeadItem] = useState([])
    const [customerItem, setCustomerItem] = useState([])
    const [customerAddressItem, setCustomerAddressItem] = useState([])

    const [incoTermItem, setIncoTermItem] = useState([])
    const [totalVolumeWeight, setTotalVolumeWeight] = useState(0)
    const [totalPackages, setTotalPackages] = useState(0);
    const [dimentionDetailsError, setDimentionDetailsError] = useState({});

    const [finalJobVendorsError, setFinalJobVendorsError] = useState({});
    const [transporterDetailsError, setTransporterDetailsError] = useState({});
    const [vendorType, setVendorType] = useState([])
    const [transportNameItem, setTransPortNameItem] = useState([]);
    const [vehicalTypeItem, setVehicalTypeItem] = useState([]);
    const [totalGrossWeight, setTotalGrossWeight] = useState(0);
    const [totalCost, setTotalCost] = useState(0);


    const convertDimensions = (data) => {
        if (data && data.length !== 0) {
            return data?.map(item => {
                return `${item.oddDimension || false}*${item.length || 0}*${item.width || 0}*${item.height || 0}*${item.boxes || 0}*${item.airVolumeWeight || 0}`;
            }).join(',');
        } else {
            return ''
        }
    };


    const convertfinaljobvendors = (data) => {
        if (data && data.length !== 0) {
            return data?.map(item => {
                return `${item.vendorTypeId || 0}*${item.vendorId || 0}`;
            }).join(',');
        } else {
            return ''
        }
    };



    const revertData = (dataString) => {
        if (dataString) {
            return dataString.split(',')?.map(item => {
                const [oddDimension, length, width, height, boxes, airVolumeWeight] = item.split('*')?.map(Number);
                return { length, width, height, boxes, airVolumeWeight };
            });
        } else {
            return []
        }

    };

    const revertFinaljobvendorsData = (dataString) => {
        if (dataString) {
            return dataString.split(',')?.map(item => {
                const [vendorTypeId, vendorId] = item.split('*')?.map(Number);
                return { vendorTypeId, vendorId };
            });
        } else {
            return []
        }

    };

    const [formData, setFormData] = useState({
        customerType: "",
        freightServiceType: "",
        freightServiceSubType: "",
        bizCategoryType: "",
        bizType: "",
        bizSubType: "",

        financialYear: "",
        branch: "",
        airportOfLoading: "",
        airportOfDestination: "",
        commodity: "",
        consoleType: "",
        consoleShipmentType: "",
        freightPP: "",
        shipperId: "",
        shipperAddressId: 1,
        subAgent: "",
        subAgentAddressId: "",
        onp1: "",
        onp2: "",
        onp3: "",
        consignee: "",
        consigneeAddressId: 1,
        notifyParty: "",
        NotifyPartyAddressId: "",
        bizGenerator: "",
        allotHawbNumber: "",
        allotMawbNumber: "",
        shipperIncoTerms: "",
        bookingWithAL: 2,
        checkList: "",
        checkListApprovedByShipper: "",
        CheckListByEmployeeId: "",
        checkListByEmployeeDocument: "",
        checkListByEmployeeFile: "",
        shipperInvoiceReceiveDate: "",
        inputClearenceDate: "",
        handOverTime: "",
        preTransportation: "",
        goodsExpectedAt: "",
        airVolumeWeight: "",
        packages: "",
        grossWeight: "",
        qtyChargeWeight: "",
        placeOfDelivery: "",
        awbInstructionFile: "",
        docReceiveDate: "",
        bookingWithALDate: "",
        shipperInvoiceDate: "",
        shipperInvoiceReceiveDate: "",
        remarks: "",
        courierByHand: "",
        courierNumber: "",
        salesSupport: "",
        salesPersonId: "",
        tentativeDateOfShipment: "",
        tcNumber: "",
        tcDate: "",
        tcFile: "",
        carrierId: "", // Airline 
        dimention: "",
        slotTimes: "",



        mawbRate: [],
        hawbRate: [],
        cargoList: [],
        tcNumbers: [],
        shipperInvoiceNumber: [],
        slotTime: [],
        dimensions: [],
        transporters: [],
        deliveryAddressList: [],
        pickupAddressList: [],









        // TotalGrossWeightFromWarehouse: "",
        // TotalPackagesFromWarehouse: "",
        // TotalGrossWeightFromAirport: "",
        // TotalPackagesFromAirport: "",
        // MawbType: "",





        finaljobvendors: [],
        SpecialRequirements: [],

        shipperPackagingFile: "",
        cooUploadFile: "",
        shippingInstructionFile: "",


        ConsoleNumber: "",
        OutChallanNumber: "",
        CheckCreditLimit: false,
        IsKyc: false,

    });


    const get_VendorType = () => {
        GetCaller({
            API: GetMasterVendorTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {

                const data = response?.data?.data?.records

                const vendorType = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setVendorType(vendorType)
            }

        });
    }
    const get_EmployeeItem = () => {
        GetCaller({
            API: GetAllEmployee,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {

                const data = response?.data?.data?.records

                const employeelist = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setEmployeeItem(employeelist)
            }

        });
    }

    const get_FinancialYear = () => {

        GetCaller({
            API: GetFinancialYear,
            dispatch: (e) => {
                // dispatch({ type: e.type, paylode: e.paylode })
            },
            item: { status: true },
            callback: ({ response }) => {
                const finalcialYear = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                }))

                setFinancialYear(finalcialYear)
            }
        })

    }

    const set_BranchItemData = () => {

        GetCaller({
            API: GetMasterBranchByPage,
            dispatch: (e) => {
                // dispatch({ type: e.type, paylode: e.paylode })
            },
            item: { status: true },
            callback: ({ response }) => {
                const newBranchItemData = response?.data?.data?.records.map((item) => ({

                    value: item.id,
                    name: item.name,
                }))

                setBranchItem(newBranchItemData)
            }
        })

    }

    const get_CargoItem = () => {

        GetCaller({
            API: GetCragoTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const cargoItem = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setCargoItem(cargoItem)
            }
        })
    }

    const set_CustomerItemData = (e) => {
        GetCaller({
            API: GetCustomerBySearch,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const customerdata = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.legalName,
                    customerBranchList: item?.customerBranchList
                }));
                setCustomerItem(customerdata)

            }

        });
    };

    const set_ConsigneeSubAgencyItem = () => {
        GetCaller({
            API: GetConsigneeSubAgencyPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.name,
                    value: res?.id,
                    consigneeSubAgentAddress: res?.consigneeSubAgentAddress
                }))

                setConsigneeItem(item)
            },

        })

    }

    const set_NotifyItemList = () => {
        GetCaller({
            API: GetNotifyMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.name,
                    value: res?.id
                }))

                setNotifyPartyItem(item)

            },

        })
    }

    const set_ChargeHeadItem = () => {

        GetCaller({
            API: GetChargeHeadByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.chargeName,
                    value: res?.id
                }))

                setChargeHeadItem(item)

            },

        })
    }

    const set_VendorMasterItem = () => {

        GetCaller({
            API: PostVendorMaster,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.legalName
                    }));

                setTransPortNameItem(newCountryItemData);

            }

        });
    }

    const set_VehiCleTypeItem = () => {

        GetCaller({
            API: GetVehicalTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.name
                    }));

                setVehicalTypeItem(newCountryItemData);

            }

        });
    }

    const get_AirportItem = () => {
        GetCaller({
            API: GetAirportMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const airportItem = data?.map(res => ({
                    value: res?.id,
                    name: res?.name
                }))
                setAirportItem(airportItem)
            }
        })
    }

    const Set_DeliveryItem = () => {
        GetCaller({
            API: GetMasterCityByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const cityItems = data?.map(res => ({
                    value: res?.id,
                    name: res?.name
                }))
                setCityItem(cityItems)
            }
        })
    }

    const Set_AirlineItem = () => {

        GetCaller({
            API: GetAirlineMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.legalName,
                    value: res?.id
                }))

                setAirlineItem(item)

            },
        })

    }


    const GetAirExportPlanerJobbyId = (id) => {
        GetCaller({
            // API: GetAirExportPlannerDetailsJobbyId,
            API: GetAirExportPermanentJobDetailsById,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { id: id },

            callback: ({ response }) => {
                let newData = response?.data?.data

                newData.bookingWithAL = newData?.bookingWithAL === true ? 1 : 2
                newData.checkListApprovedByShipper = newData?.checkListApprovedByShipper === true ? 1 : 2
                newData.checkList = newData?.checkList === true ? 1 : 2
                newData.dimensions = revertData(newData?.dimensions || '')
                newData.finaljobvendors = revertFinaljobvendorsData(newData?.finaljobvendors || '')

                const FreightFilterData = getFrieghtServices(newData?.customerType)
                setFreightItems(FreightFilterData || [])

                const FreightDetails = GetSelectedFreightDetails(newData?.customerType, newData?.freightServiceType, newData.bizCategoryType, newData.bizType)

                setFreightServiceSubType(FreightDetails?.FreightServiceSubType || [])
                setONP(FreightDetails?.ONP || {})
                setMultiPickUp(FreightDetails?.MultiPickUp || false)
                setMultiDelivery(FreightDetails?.MultiDelivery || false)
                setBizCategoryType(FreightDetails?.BizCategoryType || [])
                setBizType(FreightDetails?.BizType || [])
                setBizSubType(FreightDetails?.BizSubType || [])
                setIncoTermItem(FreightDetails?.IncoTerm || [])

                const BizDetails = (FreightDetails?.BizSubType || []).find(item => item?.name === newData?.bizSubType)
                setShipperMasterType(BizDetails?.ShipperFromMaster)
                setConsigneeMasterType(BizDetails?.ConsigneeFromMaster)

                setFormData({
                    ...formData,
                    ...newData
                })

                setJobDetailsData(newData)
            },

        })
    }



    useEffect(() => {
        get_AirportItem()
        set_BranchItemData();
        get_FinancialYear();
        get_CargoItem();
        set_CustomerItemData();
        set_ConsigneeSubAgencyItem();
        set_NotifyItemList();
        set_ChargeHeadItem();
        set_VendorMasterItem();
        set_VehiCleTypeItem();
        Set_DeliveryItem();
        get_EmployeeItem();
        Set_AirlineItem();
        get_VendorType();

        const FinalJob = sessionStorage.getItem("FinalJob")

        if (FinalJob) {
            GetAirExportPlanerJobbyId(Number(FinalJob));
            setEdit(true)
        }

    }, [])




    const requestData = (() => {

        const data = {
            id: formData?.id,
            financialYear: formData.financialYear,
            branch: formData.branch,
            airportOfLoading: formData.airportOfLoading,
            airportOfDestination: formData.airportOfDestination,
            commodity: formData.commodity,
            shipperIncoTerms: formData?.shipperIncoTerms,
            consoleShipmentType: formData?.consoleShipmentType,
            freightPP: formData?.freightPP,
            packages: formData?.packages,
            grossWeight: formData?.grossWeight,
            qtyChargeWeight: formData?.qtyChargeWeight,
            airVolumeWeight: formData?.airVolumeWeight,
            customerType: formData?.customerType,
            freightServiceType: formData.freightServiceType,


            bookingWithAL: formData?.bookingWithAL === 1 ? true : false,
            checkList: formData?.checkList === 1 ? true : false,
            checkListApprovedByShipper: formData?.checkListApprovedByShipper === 1 ? true : false,

            dimention: formData?.dimention,
            deliveryAddressList: formData?.deliveryAddressList,
            pickupAddressList: formData?.pickupAddressList,
        }

        if (FreightServiceSubType?.length !== 0) data.freightServiceSubType = formData.freightServiceSubType
        if (BizCategoryType?.length !== 0) data.bizCategoryType = formData.bizCategoryType
        if (BizType?.length !== 0) data.bizType = formData.bizType
        if (BizSubType?.length !== 0) data.bizSubType = formData.bizSubType


        if (formData.enquiryId) data.enquiryId = formData?.enquiryId
        if (formData.placeOfDelivery) data.placeOfDelivery = formData?.placeOfDelivery
        if (formData.cargoList.length !== 0) data.cargoList = formData?.cargoList

        if (formData.mawbRate) data.mawbRate = formData?.mawbRate
        if (formData.hawbRate) data.hawbRate = formData?.hawbRate

        if (formData.notifyParty) data.notifyParty = formData?.notifyParty
        if (formData.docReceiveDate) data.docReceiveDate = formData?.docReceiveDate
        if (formData.bookingWithALDate) data.bookingWithALDate = formData?.bookingWithALDate

        if (formData.shipperInvoiceDate) data.shipperInvoiceDate = formData?.shipperInvoiceDate
        if (formData.shipperInvoiceReceiveDate) data.shipperInvoiceReceiveDate = formData?.shipperInvoiceReceiveDate
        if (formData.preTransportation) data.goodsExpectedAt = formData?.goodsExpectedAt
        if (formData.goodsExpectedAt) data.preTransportation = formData?.preTransportation
        if (formData.allotMawbNumber) data.allotMawbNumber = formData?.allotMawbNumber
        if (formData.inputClearenceDate) data.inputClearenceDate = formData?.inputClearenceDate
        if (formData.handOverTime) data.handOverTime = formData?.handOverTime
        if (formData.allotHawbNumber) data.allotHawbNumber = formData?.allotHawbNumber
        if (formData.shipperInvoiceNumber) data.shipperInvoiceNumber = formData?.shipperInvoiceNumber
        if (formData.tcNumbers) data.tcNumbers = formData?.tcNumbers
        if (formData.slotTime) data.slotTime = formData?.slotTime
        if (formData.remarks) data.remarks = formData?.remarks
        if (formData.courierByHand) data.courierByHand = formData.courierByHand
        if (formData.courierNumber) data.courierNumber = formData.courierNumber
        if (formData.salesSupport) data.salesSupport = formData.salesSupport
        if (formData.salesPersonId) data.salesPersonId = formData.salesPersonId
        if (formData.tentativeDateOfShipment) data.tentativeDateOfShipment = formData.tentativeDateOfShipment
        if (formData.tcNumber) data.tcNumber = formData.tcNumber
        if (formData.tcDate) data.tcDate = formData.tcDate
        if (formData.tcFile) data.tcFile = formData.tcFile
        if (formData.carrierId) data.carrierId = formData.carrierId
        if (formData.slotTimes) data.slotTimes = formData.slotTimes
        if (formData.shipperPackagingFile) data.shipperPackagingFile = formData.shipperPackagingFile
        if (formData.cooUploadFile) data.cooUploadFile = formData.cooUploadFile
        if (formData.shippingInstructionFile) data.shippingInstructionFile = formData.shippingInstructionFile






        if (formData?.dimensions?.length !== 0 && formData?.dimensions) data.dimensions = convertDimensions(formData?.dimensions)
        if (formData?.finaljobvendors?.length !== 0 && formData?.finaljobvendors) data.finaljobvendors = convertfinaljobvendors(formData?.finaljobvendors)
        if (formData.transporters) data.transporters = formData?.transporters || []







        // FF & CC Conditions Base On Module Exports 

        if (formData.consignee) data.consignee = formData?.consignee
        if (formData.consignee) data.consigneeAddressId = formData?.consigneeAddressId

        if (shipperMasterType?.value !== 3) data.shipperId = formData?.shipperId
        if (shipperMasterType?.value !== 3 && formData.shipperId) data.shipperAddressId = formData?.shipperAddressId

        if (shipperMasterType?.value === 3) data.subAgent = formData?.subAgent
        if (formData.subAgent) data.subAgentAddressId = formData.subAgentAddressId





        if (formData.bizSubType !== 'CC Only' && formData.consoleType) data.consoleType = formData?.consoleType
        if (formData.bizSubType !== 'CC Only' && formData.bizGenerator) data.bizGenerator = formData?.bizGenerator



        if (((formData.bizSubType !== 'CC Only') && ONP.ONP1isrequired) || formData.onp1) data.onp1 = formData?.onp1
        if (((formData.bizSubType !== 'CC Only') && ONP.ONP2isrequired) || formData.onp2) data.onp2 = formData?.onp2
        if (((formData.bizSubType !== 'CC Only') && ONP.ONP3isrequired) || formData.onp3) data.onp3 = formData?.onp3

        return data;

    })();

    const AirExportPermanentJobHandler = (requestData) => {


        PostCaller({
            API: edit ? UpdateAirExportPermanentJob : AddAirExportPermanentJob,
            callback: () => {
                navigate(airexportjoblist)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            contenttype: true,
            item: requestData
        })
    }




    const handleBasicInputChange = (value, field) => {
        setFormData({
            ...formData,
            [field.id]: value,
            [field.id + 'error']: !value
        })
    }

    const handleFormSubmit = () => {

        const transporterDetailshasUndefinedValue = (array) => {

            let newarray = array && array?.length !== 0 ? array :
                []


            let changes = {};
            newarray?.map((obj, index) => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (
                        (formData?.goodsExpectedAt !== 3 && key === 'goodsExpectedAt') ||
                        (obj.pickup !== 1 && key === 'vendorId') ||
                        (obj.pickup !== 1 && key === 'cost') ||
                        (obj.pickup !== 2 && key === 'uploadReceivingFile') ||
                        (obj.goodsCondition !== 4 && obj.goodsCondition !== 5 && key === "number") ||
                        (obj.goodsCondition === 1 && key === 'comment') ||
                        (obj.goodsCondition === 1 && key === 'uploadFile') ||
                        ((obj.goodsExpectedAt === 2 && obj.pickup === 2) &&
                            (key === 'pickupVehicleTypeId' ||
                                key === 'vehicleNumber' ||
                                key === 'driverName' ||
                                key === 'driverNumber'
                            )
                        )



                    ) {

                    }
                    else if (!value && (
                        (key === 'slotTime' && obj.goodsExpectedAt === 1) ||
                        key === 'goodsExpectedAt' ||
                        key === 'vendorId' ||
                        key === 'pickup' ||
                        key === 'pickupVehicleTypeId' ||
                        key === 'vehicleNumber' ||
                        key === 'driverName' ||
                        key === 'driverNumber' ||
                        key === 'expectedTotalPackages' ||
                        key === 'expectedGrossWeight' ||
                        key === 'number' ||
                        key === 'comment' ||
                        key === 'cost' ||
                        key === 'uploadFile' ||
                        // key === 'dimensionFilePath' ||
                        key === 'uploadReceivingFile'
                    )) {
                        changes[key + index] = !value;
                    }
                });
            })

            return changes;
        };

        debugger
        const changes = handleSubmit(requestData);

        const dimentionChanges = ArrayObjectHasUndefinedValue(formData.dimensions || [], [{
            length: "",
            width: "",
            height: "",
            boxes: "",
            airVolumeWeight: ""
        }], (key) => (key !== 'oddDimension'))

        const finaljobvendorsChanges = ArrayObjectHasUndefinedValue(formData.finaljobvendors || [], [])
        const transporterDetailsChanges = transporterDetailshasUndefinedValue(requestData?.transporters);

        if (Object.keys(changes).length > 0 ||
            Object.keys(dimentionChanges).length > 0 ||
            Object.keys(finaljobvendorsChanges).length > 0 ||
            (Object.keys(transporterDetailsChanges).length > 0)
        ) {
            setFormData({ ...formData, ...changes });
            setDimentionDetailsError(dimentionChanges)
            setTransporterDetailsError(transporterDetailsChanges)
            setFinalJobVendorsError(finaljobvendorsChanges)
        }

        else {
            AirExportPermanentJobHandler(requestData);
        }
    };

    const handleSubmit = (obj) => {

        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (
                key === "id" ||
                key === 'bookingWithAL' ||
                key === 'checkList' ||
                key === 'checkListApprovedByShipper' ||
                key === 'finaljobvendors'
            ) {
            }
            else if (!value) {
                changes[key] = value;
                changes[key + "error"] = !value;
            }
        });
        return changes;
    };

    useEffect(() => {
        FindShipperAddress(formData?.shipperId)
        FindConsigneeAddress(formData?.consignee)
    }, [customerItem, consigneeItem, formData?.shipperAddressId, formData?.consigneeAddressId, formData?.subAgentAddressId])

    const FindShipperAddress = (value) => {
        const filterList = customerItem?.find((res) => res?.value === value)
        const AddressItem = filterList?.customerBranchList?.map(res => (
            {
                name: res?.addressLabel,
                value: res?.id
            }
        ))

        setCustomerAddressItem(AddressItem)
    }

    const FindConsigneeAddress = (value) => {
        const filterList = consigneeItem?.find((res) => res?.value === value)
        const AddressItem = filterList?.consigneeSubAgentAddress?.map(res => (
            {
                name: res?.address1,
                value: res?.id
            }
        ))

        setConsigneeAddressItem(AddressItem)
    }




    const HandleCustomerTypeChanges = (value, field) => {
        let option = {
            [field.id]: value,
            [field.id + 'error']: !value,
            ['freightServiceType']: "",
            ['freightServiceSubType']: "",
            ['bizCategoryType']: "",
            ['bizType']: "",
            ['bizSubType']: '',
            ['freightServiceTypeerror']: false,
            ['freightServiceSubTypeerror']: false,
            ['bizCategoryTypeerror']: false,
            ['bizTypeerror']: false,
            ['bizSubTypeerror']: false
        }

        setFreightItems(getFrieghtServices(value));
        setFreightServiceSubType([]);
        setONP({})
        setBizCategoryType([]);
        setBizType([]);
        setBizSubType([]);
        setIncoTermItem([]);
        setMultiDelivery(false);
        setMultiPickUp(false);
        setShipperMasterType(false)
        setConsigneeMasterType(false)

        return option;
    }

    const HandleFreightServiceTypeChanges = (value, field) => {

        let option = {
            [field.id]: value,
            [field.id + 'error']: !value,
            ['bizCategoryType']: '',
            ['bizCategoryTypeerror']: false,
            ['bizType']: '',
            ['bizTypeerror']: false,
            ['bizSubType']: '',
            ['bizSubTypeerror']: false,
        }

        const FreightDetails = GetSelectedFreightDetails(formData?.customerType, value)
        setMultiDelivery(FreightDetails?.MultiDelivery)
        setMultiPickUp(FreightDetails?.MultiPickUp)
        setFreightServiceSubType(FreightDetails?.FreightServiceSubType)
        setONP(FreightDetails?.ONP || {})
        setIncoTermItem(FreightDetails?.IncoTerm)


        setBizCategoryType(FreightDetails?.BizCategoryType)
        if (FreightDetails?.BizCategoryType?.length === 1) {
            option.bizCategoryType = FreightDetails?.BizCategoryType[0]?.name
        }

        setBizType(FreightDetails.BizType)
        if (FreightDetails?.BizType?.length === 1) {
            option.bizType = FreightDetails?.BizType[0]?.name
        }

        setBizSubType(FreightDetails.BizSubType)
        if (FreightDetails?.BizSubType?.length === 1) {
            option.bizSubType = FreightDetails?.BizSubType[0]?.name
            setShipperMasterType(FreightDetails?.BizSubType[0]?.ShipperFromMaster)
            setConsigneeMasterType(FreightDetails?.BizSubType[0]?.ConsigneeFromMaster)
        } else {
            setShipperMasterType(false)
            setConsigneeMasterType(false)

        }


        return option;
    }


    const HandleBizCategoryChanges = (value, field) => {

        let option = {
            [field.id]: value,
            [field.id + 'error']: !value,
            ['bizType']: '',
            ['bizTypeerror']: false,
            ['bizSubType']: '',
            ['bizSubTypeerror']: false,
        }

        const FreightDetails = GetSelectedFreightDetails(formData?.customerType, formData?.freightServiceType, value)

        setBizType(FreightDetails.BizType)
        if (FreightDetails?.BizType?.length === 1) {
            option.bizType = FreightDetails?.BizType[0]?.name
        }

        setBizSubType(FreightDetails.BizSubType)
        if (FreightDetails?.BizSubType?.length === 1) {
            option.bizSubType = FreightDetails?.BizSubType[0]?.name
            setShipperMasterType(FreightDetails?.BizSubType[0]?.ShipperFromMaster)
            setConsigneeMasterType(FreightDetails?.BizSubType[0]?.ConsigneeFromMaster)
        } else {
            setShipperMasterType(false)
            setConsigneeMasterType(false)

        }
        return option;
    }

    const HandleBizTypeChanges = (value, field) => {

        let option = {
            [field.id]: value,
            [field.id + 'error']: !value,
            ['bizSubType']: '',
            ['bizSubTypeerror']: false,
        }

        const FreightDetails = GetSelectedFreightDetails(formData?.customerType, formData?.freightServiceType, formData?.bizCategoryType, value)

        setBizSubType(FreightDetails.BizSubType)
        if (FreightDetails?.BizSubType?.length === 1) {
            option.bizSubType = FreightDetails?.BizSubType[0]?.name
            setShipperMasterType(FreightDetails?.BizSubType[0]?.ShipperFromMaster)
            setConsigneeMasterType(FreightDetails?.BizSubType[0]?.ConsigneeFromMaster)
        } else {
            setShipperMasterType(false)
            setConsigneeMasterType(false)

        }

        return option;
    }




    const calculateTotalVolumeWeight = (inputFields, typeOfDimenion) => {

        const obj = CalculateTotalAirVolumnWeight(inputFields, typeOfDimenion)

        DimentionChangesHandler(obj, inputFields, typeOfDimenion)

        return obj;
    };

    const DimentionChangesHandler = (obj, inputFields, typeOfDimenion) => {
        setTotalVolumeWeight(obj.totalWeight);
        setTotalPackages(obj.totalPackages)
        setFormData({
            ...formData,
            ['airVolumeWeight']: ConvertValue(obj.totalWeight),
            ["packages"]: obj.totalPackages,
            ['CourierVolumeWeight']: ConvertValue(obj.CourierVolumeWeight),
            ['CBM']: ConvertValue(obj.CBM),
            ['dimensions']: inputFields,
            ['dimention']: typeOfDimenion
        })
    }


    const Dimension = () => {
        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };

        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                length: '',
                width: '',
                height: '',
                boxes: '',
                airVolumeWeight: ''
            },
        ]);

        useEffect(() => {

            if (jobDetailsData?.dimensions && jobDetailsData?.dimensions?.length !== 0) {
                let item = jobDetailsData?.dimensions?.map(res => (
                    {
                        ...res,
                        key: generateUniqueKey(),
                    }
                ));
                setInputFields(item);
            }
        }, [jobDetailsData])


        const addInputField = () => {
            setInputFields([...inputFields, { key: generateUniqueKey(), length: '', width: '', height: '', boxes: '', airVolumeWeight: '' }]);
        };



        const handleInputChange = (key, field, value, index) => {

            const updatedInputFields = [...inputFields];
            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

            if (fieldToUpdate) {
                setDimentionDetailsError({
                    ...dimentionDetailsError,
                    [field + index]: !value
                })
                // fieldToUpdate[field] = value
                // const totalVolumeWeight = calculateTotalVolumeWeight([fieldToUpdate]);
                // fieldToUpdate['airVolumeWeight'] = totalVolumeWeight;

                fieldToUpdate[field] = value;
                setInputFields(updatedInputFields);


                const data = []
                updatedInputFields && updatedInputFields.map((res) => {

                    return data.push(
                        {
                            id: res?.id,
                            length: res.length,
                            width: res.width,
                            height: res.height,
                            boxes: res.boxes,
                            airVolumeWeight: res.airVolumeWeight,
                            oddDimension: res.oddDimension || false
                        }
                    )

                })
                calculateTotalVolumeWeight(updatedInputFields, formData?.dimention);

                // handleBasicInputChange(data, { id: 'dimensions' });
            }
        };

        const removeInputField = (key) => {
            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res?.id,
                        length: res.length,
                        width: res.width,
                        height: res.height,
                        boxes: res.boxes,
                        airVolumeWeight: res.airVolumeWeight,
                        oddDimension: res.oddDimension || false

                    }
                )

            })
            calculateTotalVolumeWeight(data, formData?.dimention);
            // handleBasicInputChange(data, { id: 'dimensions' });
        };


        return <>
            <div className=''>
                <div className="d-flex align-items-center">
                    <h4 className="m-0">Dimensions</h4>
                </div>


                {inputFields.map((field, key) => (
                    <div className='row' key={field.key}>
                        <div className="col-md-11">
                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "length",
                                                label: "Length",
                                                type: 'number',
                                                value: field.length,
                                                required: true,
                                                error: dimentionDetailsError["length" + key],
                                                helperText: 'Contact Person Name Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'length', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "width",
                                                label: "Width",
                                                type: 'number',
                                                value: field.width,
                                                required: true,
                                                error: dimentionDetailsError["width" + key],
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'width', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        // className="mt-0"
                                        field={
                                            {
                                                id: "height",
                                                label: "Height",
                                                type: 'number',
                                                value: field.height,
                                                required: true,
                                                error: dimentionDetailsError["height" + key],
                                                helperText: 'Department Type Required',

                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'height', value, key);
                                                }
                                            }
                                        }

                                    />

                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "boxes",
                                                label: "Boxes",
                                                type: 'number',
                                                value: field.boxes,
                                                required: true,
                                                error: dimentionDetailsError["boxes" + key],
                                                helperText: 'Mobile No Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'boxes', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "airVolumeWeight",
                                                label: "Air Volume Weight",
                                                type: 'number',
                                                value: field.airVolumeWeight,
                                                required: false,
                                                error: dimentionDetailsError["airVolumeWeight" + key],
                                                disable: true,
                                                helperText: 'Land Line Required',
                                                // onChange: (value) => {
                                                //     handleInputChange(field.key, 'airVolumeWeight', value);
                                                // }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 cmd" style={{ justifyContent: "right" }}>
                            {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                <Add />
                            </Button> : (
                                <Button
                                    sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                    onClick={() => removeInputField(field.key)}
                                    variant='contained'
                                >
                                    <Close />
                                </Button>
                            )}
                        </div>
                    </div>
                ))}
            </div>

        </>
    }

    function TransportMultiDetails() {

        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };


        useEffect(() => {
            if (jobDetailsData?.transporters && jobDetailsData?.transporters?.length !== 0) {
                let item = jobDetailsData?.transporters?.map(res => (
                    {
                        ...res,
                        key: generateUniqueKey(),
                    }
                ));
                setInputFields(item);
            }
        }, [jobDetailsData])




        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                id: "",
                slotTime: "",
                vendorId: "",
                goodsExpectedAt: '',
                pickup: "",
                pickupVehicleTypeId: "",
                vehicleNumber: "",
                driverName: "",
                driverNumber: "",
                packages: "",
                grossWeight: "",
                expectedGrossWeight: "",
                expectedTotalPackages: "",
                number: "",
                comment: "",
                cost: "",
                goodsCondition: "",
                uploadFile: "",
                goodsOutRegisterId: 1,
                sealNumber: 1,
                dimensionFilePath: "",
                uploadReceivingFile: ""
            },
        ]);


        const totalValueCounter = () => {
            let totalPackages = 0
            let totalGrossWeight = 0
            let totalCost = 0

            inputFields?.map(res => {
                const cost = res.cost !== "" ? Number(res.cost) : 0;
                const grossWeight = res.expectedGrossWeight !== "" ? Number(res.expectedGrossWeight) : 0;
                const packages = res.expectedTotalPackages !== "" ? Number(res.expectedTotalPackages) : 0;

                totalCost = Number(totalCost + cost);
                totalGrossWeight = Number(totalGrossWeight + grossWeight);
                totalPackages = Number(totalPackages + packages);
            })

            setTotalCost(totalCost)
            setTotalGrossWeight(totalGrossWeight)
            setTotalPackages(totalPackages)

        }

        useEffect(() => {
            totalValueCounter()
            SubmitChangesToMainState(inputFields)
        }, [inputFields])

        useEffect(() => {
            debugger
            if (formData.preTransportation === 1 ||
                formData.preTransportation === 2 ||
                formData.goodsExpectedAt === 1 ||
                formData.goodsExpectedAt === 2
            ) {
                setInputFields((prev) =>
                    prev.map((item) => ({
                        ...item,
                        pickup: formData.preTransportation === 3 ? item.pickup : formData.preTransportation,
                        goodsExpectedAt: formData.goodsExpectedAt === 3 ? item.goodsExpectedAt : formData.goodsExpectedAt
                    }))
                );

            }
        }, [formData.preTransportation, formData.goodsExpectedAt])



        const addInputField = () => {
            setInputFields([...inputFields, {
                key: generateUniqueKey(),
                id: "",
                slotTime: "",
                vendorId: "",
                goodsExpectedAt: "",
                pickup: "",
                pickupVehicleTypeId: "",
                vehicleNumber: "",
                driverName: "",
                driverNumber: "",
                packages: "",
                grossWeight: "",
                expectedGrossWeight: "",
                expectedTotalPackages: "",
                number: "",
                comment: "",
                cost: "",
                goodsCondition: "",
                uploadFile: "",
                goodsOutRegisterId: 1,
                sealNumber: 1,
                dimensionFilePath: "",
                uploadReceivingFile: ""

            }]);
            setTransporterDetailsError({})
        };

        const handleDuplicateInputChange = (key, field, value, index) => {
            const updatedInputFields = [...inputFields];

            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
            if (fieldToUpdate) {

                setTransporterDetailsError({
                    ...transporterDetailsError,
                    [field + index]: !value
                })

                if (field === 'pickup') {
                    fieldToUpdate['cost'] = 0;
                }
                fieldToUpdate[field] = value;

                if (formData?.goodsExpectedAt === 1) {
                    fieldToUpdate['goodsExpectedAt'] = 1;
                } else if (formData?.goodsExpectedAt === 2) {
                    fieldToUpdate['goodsExpectedAt'] = 2;
                }

                if (formData?.preTransportation === 1) {
                    fieldToUpdate['pickup'] = 1;
                } else if (formData?.preTransportation === 2) {
                    fieldToUpdate['pickup'] = 2;
                }

                setInputFields(updatedInputFields);
            }
        };

        const removeInputField = (key) => {
            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
        };



        const SubmitChangesToMainState = (updatedData) => {
            const data = []
            updatedData?.map((res) => {
                return data.push(
                    {
                        id: res.id || null,
                        goodsInRegisterId: res.goodsInRegisterId || null,
                        slotTime: res.slotTime,
                        vendorId: res.vendorId,
                        goodsExpectedAt: res?.goodsExpectedAt,
                        pickup: res.pickup,
                        pickupVehicleTypeId: (res.goodsExpectedAt === 2 && res.pickup === 2) ? '' : res.pickupVehicleTypeId,
                        vehicleNumber: (res.goodsExpectedAt === 2 && res.pickup === 2) ? '' : res.vehicleNumber,
                        driverName: (res.goodsExpectedAt === 2 && res.pickup === 2) ? '' : res.driverName,
                        driverNumber: (res.goodsExpectedAt === 2 && res.pickup === 2) ? '' : res.driverNumber,
                        number: res.number,
                        comment: res.comment,
                        goodsCondition: res.goodsCondition || 1,
                        uploadFile: res.uploadFile,
                        cost: Number(res.cost),
                        packages: Number(res.packages),
                        grossWeight: Number(res.grossWeight),
                        expectedGrossWeight: Number(res.expectedGrossWeight),
                        expectedTotalPackages: Number(res.expectedTotalPackages),
                        goodsOutRegisterId: 1,
                        sealNumber: 1,
                        dimensionFilePath: "",
                        uploadReceivingFile: res.uploadReceivingFile,
                        goodsStatus: res?.goodsStatus || 1
                    }
                )

            })

            handleBasicInputChange(data, { id: 'transporters' });

        }

        return (

            <>

                {
                    (formData.preTransportation && formData.goodsExpectedAt) &&
                    <div className='hr-form-box'>

                        <div className="cmd">
                            <h5 className="m-0 p-0">
                                <Button sx={{ m: 0, marginRight: '0.5rem', borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                    <Add />
                                </Button> Add Transporter
                            </h5>

                        </div>

                        {inputFields.map((field, key) => (
                            <div className='d-flex' key={field.key}>
                                <div className="me-2 mt-4" >
                                    {
                                        // key !== 0 ? (
                                        <Button
                                            sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                            onClick={() => removeInputField(field.key)}
                                            variant='outlined'
                                        >
                                            <Close />
                                        </Button>
                                        // )
                                        // :
                                        // <Button sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                        //     <Add />
                                        // </Button>
                                    }
                                </div>
                                <div className="">
                                    <div className="row my-2">
                                        {
                                            field.goodsExpectedAt === 1 &&
                                            <div className="col-md-2">
                                                <InputField
                                                    field={
                                                        {
                                                            id: 'slotTime',
                                                            label: 'Slot Time',
                                                            type: "text",
                                                            value: field.slotTime,
                                                            required: true,
                                                            error: transporterDetailsError['slotTime' + key],
                                                            onChange: (value) => {
                                                                handleDuplicateInputChange(field.key, 'slotTime', value, key);
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        }

                                        {
                                            formData?.goodsExpectedAt === 3 &&
                                            <div className="col-md-2">
                                                <Dropdown
                                                    field={
                                                        {
                                                            id: 'goodsExpectedAt',
                                                            label: "Goods Expected ",
                                                            type: "select",
                                                            value: field.goodsExpectedAt,
                                                            required: true,
                                                            error: transporterDetailsError['goodsExpectedAt' + key],
                                                            item: DestinationType,
                                                            onChange: (value) => {
                                                                handleDuplicateInputChange(field.key, 'goodsExpectedAt', value, key);
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        }

                                        <div className="col-md-2">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: 'pickup',
                                                        label: formconstant.pickuptype,
                                                        type: "select",
                                                        value: field.pickup,
                                                        required: true,
                                                        disable: (formData?.preTransportation === 1 || formData?.preTransportation === 2) ? true : false,
                                                        error: transporterDetailsError['pickup' + key],
                                                        item: PickUpType,
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'pickup', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        {
                                            field.pickup === 1 &&
                                            <>
                                                <div className="col-md-2">
                                                    <Dropdown
                                                        field={
                                                            {
                                                                id: 'vendorId',
                                                                label: formconstant.transportname,
                                                                type: "select",
                                                                value: field.vendorId,
                                                                required: true,
                                                                error: transporterDetailsError['vendorId' + key],
                                                                item: transportNameItem,
                                                                onChange: (value) => {
                                                                    handleDuplicateInputChange(field.key, 'vendorId', value, key);
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </>
                                        }
                                        {
                                            (field.goodsExpectedAt === 2 && field.pickup === 2) ? <></> :
                                                <>
                                                    <div className="col-md-2">
                                                        <Dropdown
                                                            field={
                                                                {
                                                                    id: 'pickupVehicleTypeId',
                                                                    label: formconstant.pickupvehicaltype,
                                                                    type: "select",
                                                                    value: field.pickupVehicleTypeId,
                                                                    required: true,
                                                                    error: transporterDetailsError['pickupVehicleTypeId' + key],
                                                                    item: vehicalTypeItem,
                                                                    onChange: (value) => {
                                                                        handleDuplicateInputChange(field.key, 'pickupVehicleTypeId', value, key);
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <InputField
                                                            field={
                                                                {
                                                                    id: 'vehicleNumber',
                                                                    label: formconstant.vehicalno,
                                                                    type: "text",
                                                                    value: field.vehicleNumber,
                                                                    required: true,
                                                                    error: transporterDetailsError['vehicleNumber' + key],
                                                                    onChange: (value) => {
                                                                        handleDuplicateInputChange(field.key, 'vehicleNumber', value, key);
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <InputField
                                                            field={
                                                                {
                                                                    id: 'driverName',
                                                                    label: formconstant.drivername,
                                                                    type: "text",
                                                                    value: field.driverName,
                                                                    required: true,
                                                                    error: transporterDetailsError['driverName' + key],
                                                                    onChange: (value) => {
                                                                        handleDuplicateInputChange(field.key, 'driverName', value, key);
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <InputField
                                                            field={
                                                                {
                                                                    id: 'driverNumber',
                                                                    label: formconstant.driverno,
                                                                    type: "number",
                                                                    value: field.driverNumber,
                                                                    required: true,
                                                                    error: transporterDetailsError['driverNumber' + key],
                                                                    onChange: (value) => {
                                                                        handleDuplicateInputChange(field.key, 'driverNumber', value, key);
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </>
                                        }
                                        <div className="col-md-2">
                                            <InputField
                                                field={
                                                    {
                                                        id: 'expectedTotalPackages',
                                                        label: formconstant.packages,
                                                        type: "number",
                                                        value: field.expectedTotalPackages,
                                                        required: true,
                                                        error: transporterDetailsError['expectedTotalPackages' + key],
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'expectedTotalPackages', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <InputField
                                                field={
                                                    {
                                                        id: 'expectedGrossWeight',
                                                        label: formconstant.grossweight,
                                                        type: "number",
                                                        value: field.expectedGrossWeight,
                                                        required: true,
                                                        error: transporterDetailsError['expectedGrossWeight' + key],
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'expectedGrossWeight', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        {/* <div className="col-md-2">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: 'goodsCondition',
                                                        label: formconstant.goodsconditions  ,
                                                        type: "select",
                                                        value: field.goodsCondition,
                                                        required: true,
                                                        error: transporterDetailsError['goodsCondition' + key],
                                                        item: GoodsConditions,
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'goodsCondition', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div> */}
                                        {
                                            (field.goodsCondition === 4 || field.goodsCondition === 5) &&
                                            <div className="col-md-2">
                                                <InputField
                                                    field={
                                                        {
                                                            id: 'number',
                                                            label: formconstant.number,
                                                            type: "number",
                                                            value: field.number,
                                                            required: true,
                                                            error: transporterDetailsError['number' + key],
                                                            onChange: (value) => {
                                                                handleDuplicateInputChange(field.key, 'number', value, key);
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        }
                                        {
                                            field.goodsCondition && field.goodsCondition !== 1 && <>
                                                <div className="col-md-3">
                                                    <InputField
                                                        field={
                                                            {
                                                                id: 'comment',
                                                                label: formconstant.comments,
                                                                type: "comment",
                                                                value: field.comment,
                                                                required: true,
                                                                error: transporterDetailsError['comment' + key],
                                                                onChange: (value) => {
                                                                    handleDuplicateInputChange(field.key, 'comment', value, key);
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <InputFileField
                                                        className='mt-3'
                                                        field={{
                                                            id: "uploadFile",
                                                            label: formconstant.uploadpic,
                                                            type: 'file',
                                                            required: true,
                                                            error: transporterDetailsError['uploadFile' + key],
                                                            helperText: 'Upload Pan Card Required',
                                                            onChange: (value) => {
                                                                handleDuplicateInputChange(field.key, 'uploadFile', value, key);
                                                            }
                                                        }}
                                                    />
                                                </div>

                                            </>
                                        }

                                        {
                                            field.pickup === 2 &&
                                            <>
                                                <div className="col-md-2">
                                                    <InputFileField
                                                        className='mt-3'
                                                        field={{
                                                            id: "uploadReceivingFile",
                                                            label: "Upload Receiving File",
                                                            type: 'file',
                                                            required: true,
                                                            error: transporterDetailsError['uploadReceivingFile' + key],
                                                            helperText: 'Upload Pan Card Required',
                                                            onChange: (value) => {
                                                                handleDuplicateInputChange(field.key, 'uploadReceivingFile', value, key);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        }


                                        {
                                            field.pickup === 1 &&
                                            <>
                                                <div className="col-md-2">
                                                    <InputField
                                                        field={
                                                            {
                                                                id: 'cost',
                                                                label: formconstant.cost,
                                                                type: "number",
                                                                value: field.cost,
                                                                required: true,
                                                                error: transporterDetailsError['cost' + key],
                                                                onChange: (value) => {
                                                                    handleDuplicateInputChange(field.key, 'cost', value, key);
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </>
                                        }

                                    </div>
                                </div>

                            </div>
                        ))}

                    </div>
                }
            </>


        );
    }

    const CDDTabHandler = (row) => {
        return <>
            <CreateCddForm
                employeeItem={[]}
                row={row}
                callBack={() => { }}
            />
        </>
    }

    const airportItemFilterData = (id) => {
        let newFilterItem = airportItem.filter(item => item.value !== id)
        return newFilterItem
    }

    return <>
        <h5>
            Export JOB
        </h5>




        {/* 
        <div className="hr-form-box">

            <div className='Initial_Quote_head cmd'>
                <div><span>Job Id</span> - {formData?.enquiryId}</div>
                <div><span>Shipper Name</span>- {formData?.shipperName}</div>
                <div><span>Organization Name/Biz. Generator</span>- {formData?.bizGenerator}</div>
                <div>
                    <KeyboardArrowUpIcon onClick={() => setOpenTable(!openTable)} className={openTable ? 'rotate_up' : 'rotate_down'} />
                </div>
            </div>

            {
                openTable &&
                <div className='check-customer-table' style={{ textAlign: "left" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>{columnConstant.packages}</th>
                                <th>{formData?.packages}</th>
                                <th>{columnConstant.grosswt}</th>
                                <th>{formData?.grossWeight}</th>
                                <th>{columnConstant.airvolumeweightcbm}</th>
                                <th>{formData?.airVolumeWeight}</th>
                                <th>{"Freight Services"}</th>
                                <th>{formData?.freightServiceType}</th>
                            </tr>
                            <tr>
                                <th>{columnConstant.airportofloading}</th>
                                <th>{formData?.airportOfLoadingName}</th>
                                <th>{columnConstant.airportofdestination}</th>
                                <th>{formData?.airportOfDestinationName}</th>
                                <th>{columnConstant.chargeableweight}</th>
                                <th>{formData?.qtyChargeWeight}</th>
                                <th>{columnConstant.tentativedateofship}</th>
                                <th>{formData?.tentativeDateOfShipment}</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            }

        </div> */}

        <div>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, value) => { setTabValue(value) }}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Main Details" value="1" />
                        <Tab label="CDD" value="2" />
                        <Tab label="Final Buying" value="3" />
                        <Tab label="Final Selling" value="4" />
                        {/* <Tab label="Flight Tracking" value="5" />
                        <Tab label="Warehouse" value="6" />
                        <Tab label="Airport" value="7" /> */}


                    </TabList>
                </Box>

            </TabContext>
        </div>


        <TabContext value={tabValue} >

            <TabPanel className="p-0 mt-3" value="1">


                <div className="hr-form-box">
                    <h5>Freight Services</h5>

                    <div className="row">
                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "financialYear",
                                    label: 'Financial Year',
                                    value: formData.financialYear,
                                    required: true,
                                    error: formData.financialYearerror,
                                    item: financialyear,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>

                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "branch",
                                    label: 'Branch',
                                    value: formData.branch,
                                    required: true,
                                    error: formData.brancherror,
                                    item: branchitem,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>

                        <div className="col-md-2">
                            <Dropdown
                                field={
                                    {
                                        id: "airportOfLoading",
                                        label: "APOL ",
                                        value: formData.airportOfLoading,
                                        required: true,
                                        error: formData.airportOfLoadingerror,
                                        item: airportItemFilterData(formData.airportOfDestination),
                                        disable: false,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>

                        <div className="col-md-2">
                            <Dropdown
                                field={
                                    {
                                        id: "airportOfDestination",
                                        label: "APOD ",
                                        value: formData.airportOfDestination,
                                        required: true,
                                        error: formData.airportOfDestinationerror,
                                        item: airportItemFilterData(formData.airportOfLoading),
                                        disable: false,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>


                        <div className="col-md-2">
                            <Dropdown
                                field={
                                    {
                                        id: "placeOfDelivery",
                                        label: "Place Of Delivery ",
                                        value: formData.placeOfDelivery,
                                        required: true,
                                        error: formData.placeOfDeliveryerror,
                                        item: cityItem,
                                        disable: false,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>

                        <div className="col-md-2">
                            <InputField
                                field={
                                    {
                                        id: "commodity",
                                        label: "Commodity",
                                        type: 'text',
                                        value: formData.commodity,
                                        required: true,
                                        error: formData.commodityerror,
                                        helperText: 'Identification Required',
                                        onChange: (value, field) => {
                                            let newValue = value.toUpperCase();
                                            handleBasicInputChange(newValue, field)
                                        }
                                    }
                                }
                            />
                        </div>

                        {
                            formData.bizSubType !== 'CC Only' &&
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "consoleType",
                                            label: "Console Type ",
                                            value: formData.consoleType,
                                            required: true,
                                            error: formData.consoleTypeerror,
                                            item: mawbshippers,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                        }



                        {
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "customerType",
                                            label: "Customer Type ",
                                            value: formData.customerType,
                                            required: true,
                                            error: formData.customerTypeerror,
                                            helperText: 'Customer Type Required',
                                            item: AirExportCustomerTypeFrightOptions,
                                            onChange: (value, field) => {
                                                const data = HandleCustomerTypeChanges(value, field)
                                                setFormData({
                                                    ...formData,
                                                    ...data
                                                })
                                            }
                                        }
                                    }
                                />

                            </div>
                        }

                        {
                            formData.customerType === 5 &&
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "FPCCustomerType",
                                            label: "FPC Customer Type ",
                                            value: formData.FPCCustomerType,
                                            required: true,
                                            error: formData.FPCCustomerTypeerror,
                                            helperText: 'Customer Type Required',
                                            item: TypeOfFPC,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />

                            </div>
                        }


                        {
                            formData.customerType && FreightItems?.length !== 0 && < div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "freightServiceType",
                                            label: "Freight/Service Type ",
                                            value: formData.freightServiceType,
                                            required: true,
                                            error: formData.freightServiceTypeerror,
                                            helperText: 'Freight/Service Type  Required',
                                            item: FreightItems,
                                            onChange: (value, field) => {
                                                const data = HandleFreightServiceTypeChanges(value, field)
                                                setFormData({
                                                    ...formData,
                                                    ...data
                                                })
                                            }
                                        }
                                    }
                                />

                            </div>
                        }

                        {

                            FreightServiceSubType?.length !== 0 && <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "freightServiceSubType",
                                            label: "Freight/Service Sub Type ",
                                            value: formData.freightServiceSubType,
                                            required: true,
                                            error: formData.freightServiceSubTypeerror,
                                            helperText: 'Freight ServiceSubType Required',
                                            item: FreightServiceSubType,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />

                            </div>

                        }


                        {

                            BizCategoryType?.length !== 0 && <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "bizCategoryType",
                                            label: "Biz Category Type ",
                                            value: formData.bizCategoryType,
                                            required: true,
                                            error: formData.bizCategoryTypeerror,
                                            helperText: 'Inco Terms Required',
                                            item: BizCategoryType,
                                            disable: BizCategoryType?.length === 1 ? true : false,
                                            onChange: (value, field) => {

                                                const data = HandleBizCategoryChanges(value, field)
                                                setFormData({
                                                    ...formData,
                                                    ...data
                                                })
                                            }
                                        }
                                    }
                                />
                            </div>
                        }

                        {
                            BizType?.length !== 0 && (formData.bizType !== 'N/A') && <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "bizType",
                                            label: "Biz Type ",
                                            value: formData.bizType,
                                            required: true,
                                            error: formData.bizTypeerror,
                                            helperText: 'Inco Terms Required',
                                            disable: BizType?.length === 1 ? true : false,
                                            item: BizType,
                                            onChange: (value, field) => {

                                                const data = HandleBizTypeChanges(value, field)
                                                setFormData({
                                                    ...formData,
                                                    ...data
                                                })
                                            }
                                        }
                                    }
                                />

                            </div>
                        }

                        {
                            BizSubType?.length !== 0 && <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "bizSubType",
                                            label: "Biz Sub Type ",
                                            value: formData.bizSubType,
                                            required: true,
                                            error: formData.bizSubTypeerror,
                                            helperText: 'Inco Terms Required',
                                            item: BizSubType,
                                            disable: BizSubType?.length === 1 ? true : false,
                                            onChange: (value, field) => {
                                                handleBasicInputChange(value, field)
                                                const BizDetails = BizSubType.find(item => item.name === value)
                                                setShipperMasterType(BizDetails?.ShipperFromMaster)
                                                setConsigneeMasterType(BizDetails?.ConsigneeFromMaster)
                                            }
                                        }
                                    }
                                />

                            </div>
                        }



                    </div>

                </div>

                <div className="hr-form-box">
                    <div>
                        <div className="row">
                            {
                                shipperMasterType?.value !== 3 &&
                                <div className="col-md-2">
                                    <Dropdown
                                        field={{
                                            id: "shipperId",
                                            label: "Shipper ",
                                            value: formData?.shipperId,
                                            required: true,
                                            error: formData?.shipperIderror,
                                            item: customerItem,
                                            onChange: (value, field) => {
                                                handleBasicInputChange(value, field)
                                                FindShipperAddress(value)
                                            }

                                        }}
                                    />
                                </div>
                            }

                            {
                                formData?.shipperId && <div className="col-md-2">
                                    <Dropdown
                                        field={{
                                            id: "shipperAddressId",
                                            label: "Shipper Address ",
                                            value: formData?.shipperAddressId,
                                            required: true,
                                            error: formData?.shipperAddressIderror,
                                            item: customerAddressItem,
                                            onChange: handleBasicInputChange
                                        }}
                                    />
                                </div>
                            }

                            <div className="col-md-2">
                                <Dropdown
                                    field={{
                                        id: "consignee",
                                        label: "Consignee",
                                        value: formData?.consignee,
                                        required: false,
                                        error: formData?.consigneeerror,
                                        item: consigneeItem,
                                        onChange: (value, field) => {
                                            handleBasicInputChange(value, field)
                                            FindConsigneeAddress(value)

                                        }
                                    }}
                                />
                            </div>
                            {
                                formData?.consignee && <div className="col-md-2">
                                    <Dropdown
                                        field={{
                                            id: "consigneeAddressId",
                                            label: "Consignee Address",
                                            value: formData?.consigneeAddressId,
                                            required: true,
                                            error: formData?.consigneeAddressIderror,
                                            item: consigneeAddressItem,
                                            onChange: handleBasicInputChange
                                        }}
                                    />
                                </div>
                            }



                            {
                                (shipperMasterType?.value === 3) &&
                                <>
                                    <div className="col-md-2">
                                        <Dropdown
                                            field={{
                                                id: "subAgent",
                                                label: "Sub Agent ",
                                                value: formData?.subAgent,
                                                required: true,
                                                error: formData?.subAgenterror,
                                                item: customerItem,
                                                onChange: (value, field) => {
                                                    handleBasicInputChange(value, field)
                                                    FindShipperAddress(value)
                                                }
                                            }}
                                        />
                                    </div>

                                    {
                                        formData.subAgent &&
                                        <div className="col-md-2">
                                            <Dropdown
                                                field={{
                                                    id: "subAgentAddressId",
                                                    label: "Sub Agent Address ",
                                                    value: formData?.subAgentAddressId,
                                                    required: true,
                                                    error: formData?.subAgentAddressIderror,
                                                    item: customerAddressItem,
                                                    onChange: handleBasicInputChange
                                                }}
                                            />
                                        </div>
                                    }

                                </>

                            }

                            {
                                formData.bizSubType !== 'CC Only' && <>

                                    {
                                        ONP.ONP1 && <div className="col-md-2">
                                            <Dropdown
                                                field={{
                                                    id: "onp1",
                                                    label: "Onp1",
                                                    value: formData?.onp1,
                                                    required: ONP.ONP1isrequired,
                                                    error: formData?.onp1error,
                                                    item: customerItem,
                                                    onChange: handleBasicInputChange
                                                }}
                                            />
                                        </div>
                                    }

                                    {
                                        ONP.ONP2 &&
                                        <div className="col-md-2">
                                            <Dropdown
                                                field={{
                                                    id: "onp2",
                                                    label: "Onp2",
                                                    value: formData?.onp2,
                                                    required: ONP.ONP2isrequired,
                                                    error: formData?.onp2error,
                                                    item: customerItem,
                                                    onChange: handleBasicInputChange
                                                }}
                                            />
                                        </div>
                                    }

                                    {
                                        ONP.ONP3 &&
                                        <div className="col-md-2">
                                            <Dropdown
                                                field={{
                                                    id: "onp3",
                                                    label: "Onp3",
                                                    value: formData?.onp3,
                                                    required: ONP.ONP3isrequired,
                                                    error: formData?.onp3error,
                                                    item: customerItem,
                                                    onChange: handleBasicInputChange
                                                }}
                                            />

                                        </div>
                                    }

                                </>
                            }



                            {
                                formData.bizSubType !== 'CC Only' &&
                                <div className="col-md-2">
                                    <InputField
                                        field={{
                                            id: "bizGenerator",
                                            label: "Biz Generator",
                                            value: formData?.bizGenerator,
                                            required: true,
                                            error: formData?.bizGeneratorerror,
                                            onChange: handleBasicInputChange
                                        }}
                                    />
                                </div>
                            }

                            <div className="col-md-2">
                                <Dropdown
                                    field={{
                                        id: "notifyParty",
                                        label: "Notify Party ",
                                        value: formData?.notifyParty,
                                        required: true,
                                        error: formData?.notifyPartyerror,
                                        item: notifyPartyItem,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>

                            <div className="col-md-2">
                                <InputField
                                    field={{
                                        id: "allotMawbNumber",
                                        label: "MAWB NO",
                                        type: 'number',
                                        value: formData?.allotMawbNumber,
                                        required: true,
                                        error: formData?.allotMawbNumbererror,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                            <div className="col-md-2">
                                <InputFileField
                                    className='mt-3'
                                    field={
                                        {
                                            id: "awbInstructionFile",
                                            label: "Upload AWB Instruction",
                                            type: 'file',
                                            value: formData?.awbInstructionFile,
                                            required: true,
                                            error: formData.awbInstructionFileerror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-2">
                                <InputField
                                    field={{
                                        id: "allotHawbNumber",
                                        label: "HAWB NO",
                                        type: 'number',
                                        value: formData?.allotHawbNumber,
                                        required: true,
                                        error: formData?.allotHawbNumbererror,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "salesSupport",
                                            label: "Sales Support",
                                            value: formData.salesSupport,
                                            required: false,
                                            error: formData.salesSupporterror,
                                            // disable: totalPackages === 0 ? false : true,
                                            item: employeeItem,
                                            helperText: 'Packages Required',
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "salesPersonId",
                                            label: "Sales Person",
                                            value: formData.salesPersonId,
                                            required: false,
                                            error: formData.salesPersonIderror,
                                            // disable: totalPackages === 0 ? false : true,
                                            item: employeeItem,
                                            helperText: 'Packages Required',
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>




                        </div>
                    </div>
                </div>

                <div className="hr-form-box">
                    <div className="row">
                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "dimention",
                                    label: "Dimension",
                                    value: formData?.dimention,
                                    required: true,
                                    error: formData?.dimentionerror,
                                    item: EnquiryDimension,
                                    onChange: (value) => {
                                        calculateTotalVolumeWeight(formData.dimensions, value)
                                    }
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "shipperIncoTerms",
                                    label: "Inco Term  ",
                                    value: formData?.shipperIncoTerms,
                                    required: true,
                                    error: formData?.shipperIncoTermserror,
                                    item: incoTermItem,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>

                        <div className="col-md-2">
                            <StackDropdown field={{
                                id: "cargoList",
                                label: 'Cargo Type ',
                               
                                value: formData.cargoList,
                                required: true,
                                error: formData.cargoListerror,
                                item: cargoItem,
                                onChange: handleBasicInputChange
                            }} />
                        </div>

                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "specialRequirement",
                                    label: "Special Requirement  ",
                                    value: formData?.specialRequirement,
                                    required: true,
                                    error: formData?.specialRequirementerror,
                                    item: [],
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>

                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "consoleShipmentType",
                                    label: "Shipment Type  ",
                                    value: formData?.consoleShipmentType,
                                    required: true,
                                    error: formData?.consoleShipmentTypeerror,
                                    item: SingleShipmentType,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>

                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "freightPP",
                                    label: "Freight Type  ",
                                    value: formData?.freightPP,
                                    required: true,
                                    error: formData?.freightPPerror,
                                    item: frightpp,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>

                        {
                            formData.bizSubType !== 'CC Only' &&
                            <div className="col-md-2">
                                <Dropdown
                                    field={{
                                        id: "bookingWithAL",
                                        label: "Booking With Airline  ",
                                        value: formData?.bookingWithAL,
                                        required: true,
                                        error: formData?.bookingWithALerror,
                                        item: applicableFormat,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                        }




                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "checkList",
                                    label: "Check list ",
                                    value: formData?.checkList,
                                    required: true,
                                    error: formData?.checkListerror,
                                    item: applicableFormat,
                                    onChange: handleBasicInputChange
                                }}
                            />

                        </div>

                        {formData.checkList === 1 &&
                            <div className="col-md-2">
                                <InputFileField
                                    className='mt-3'
                                    field={
                                        {
                                            id: "checkListByEmployeeFile",
                                            label: "Upload Document",
                                            type: 'file',
                                            value: formData.checkListByEmployeeFile,
                                            required: true,
                                            error: formData.checkListByEmployeeFileerror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                        }


                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "checkListApprovedByShipper",
                                    label: "Approved By Shipper ",
                                    value: formData?.checkListApprovedByShipper,
                                    required: true,
                                    error: formData?.checkListApprovedByShippererror,
                                    item: applicableFormat,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>




                    </div>
                </div>


                <div className="hr-form-box">
                    <div className="row">
                        {
                            formData.bizSubType !== 'CC Only' &&
                            <div className="col-md-2">
                                <DateField
                                    field={{
                                        id: "bookingWithALDate",
                                        label: "Booking With Airline Date ",
                                        value: formData?.bookingWithALDate,
                                        required: true,
                                        error: formData?.bookingWithALDateerror,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                        }
                        <div className="col-md-2">
                            <DateField
                                field={{
                                    id: "docReceiveDate",
                                    label: "Doc Receive Date ",
                                    value: formData?.docReceiveDate,
                                    required: true,
                                    error: formData?.docReceiveDateerror,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>


                        <div className="col-md-2">
                            <DateField
                                field={{
                                    id: "shipperInvoiceReceiveDate",
                                    label: "Shipper In. Receive Date ",
                                    value: formData?.shipperInvoiceReceiveDate,
                                    required: true,
                                    error: formData?.shipperInvoiceReceiveDateerror,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <DateField
                                field={{
                                    id: "shipperInvoiceDate",
                                    label: "Shipper Invoice Date ",
                                    value: formData?.shipperInvoiceDate,
                                    required: true,
                                    error: formData?.shipperInvoiceDateerror,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>





                        <div className="col-md-2">
                            <DateField
                                field={{
                                    id: "inputClearenceDate",
                                    label: "Clearance Date ",
                                    value: formData?.inputClearenceDate,
                                    required: true,
                                    error: formData?.inputClearenceDateerror,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <DateField
                                field={{
                                    id: "tentativeDateOfShipment",
                                    label: "Date Of Shipment",
                                    value: formData?.tentativeDateOfShipment,
                                    required: true,
                                    error: formData?.tentativeDateOfShipmenterror,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <TimeField
                                field={{
                                    id: "handOverTime",
                                    label: "Handover Time ",
                                    value: formData?.handOverTime,
                                    required: true,
                                    error: formData?.handOverTimeerror,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>

                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "preTransportation",
                                    label: "Pre Transportation ",
                                    value: formData?.preTransportation,
                                    required: true,
                                    error: formData?.preTransportationerror,
                                    item: pretransport,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "goodsExpectedAt",
                                    label: "Goods Expected At ",
                                    value: formData?.goodsExpectedAt,
                                    required: true,
                                    error: formData?.goodsExpectedAterror,
                                    item: goodsExpectedItem,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>

                        {/* {
                            formData?.preTransportation === 1 && (formData?.goodsExpectedAt === 1 || formData?.goodsExpectedAt === 3) &&
                            <div className="col-md-2">
                                <Dropdown
                                    field={{
                                        id: "challanStatus",
                                        label: "Challan Status ",
                                        value: formData?.challanStatus,
                                        required: true,
                                        error: formData?.challanStatuserror,
                                        item: ChallanType,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                        } */}





                        <div className="col-md-2">
                            <Dropdown
                                field={
                                    {
                                        id: "carrierId",
                                        label: "Carrier",
                                        value: formData.carrierId,
                                        required: false,
                                        error: formData.carrierIderror,
                                        // disable: totalPackages === 0 ? false : true,
                                        item: airlineitem,
                                        helperText: 'Packages Required',
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                        <div className="col-md-2">
                            <InputField
                                field={
                                    {
                                        id: "tcNumber",
                                        label: "TC Number",
                                        type: 'Number',
                                        value: formData.tcNumber,
                                        required: false,
                                        error: formData.tcNumbererror,
                                        // disable: totalPackages === 0 ? false : true,
                                        helperText: 'Packages Required',
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                        <div className="col-md-2">
                            <DateField
                                field={{
                                    id: "tcDate",
                                    label: "TC Date ",
                                    value: formData?.tcDate,
                                    required: true,
                                    error: formData?.tcDateerror,
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <InputFileField
                                className='mt-3'
                                field={{
                                    id: "tcFile",
                                    label: 'TC File',
                                    type: 'file',
                                    required: true,
                                    error: formData?.tcFileerror,
                                    helperText: 'Upload Pan Card Required',
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>

                        <div className="col-md-2">
                            <InputField
                                className='mt-3'
                                field={{
                                    id: "slotTimes",
                                    label: 'Slot Time',
                                    type: 'text',
                                    value: formData?.slotTimes,
                                    required: true,
                                    error: formData?.slotTimeserror,
                                    helperText: 'Upload Pan Card Required',
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>

                        <div className="col-md-2">
                            <InputFileField
                                className='mt-4'
                                field={{
                                    id: "shipperPackagingFile",
                                    label: 'Shipper Packing List',
                                    type: 'file',
                                    required: true,
                                    error: formData?.shipperPackagingFileerror,
                                    helperText: 'Upload Pan Card Required',
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <InputFileField
                                className='mt-4'
                                field={{
                                    id: "cooUploadFile",
                                    label: 'Coo Upload File',
                                    type: 'file',
                                    required: true,
                                    error: formData?.cooUploadFileerror,
                                    helperText: 'Upload Pan Card Required',
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <InputFileField
                                className='mt-4'
                                field={{
                                    id: "shippingInstructionFile",
                                    label: 'Shipping Instruction File',
                                    type: 'file',
                                    required: true,
                                    error: formData?.shippingInstructionFileerror,
                                    helperText: 'Upload Pan Card Required',
                                    onChange: handleBasicInputChange
                                }}
                            />
                        </div>


                        <div className="col-md-2">
                            < TcNumber

                                data={formData.tcNumbers}
                                callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                fieldData={formData}
                            />
                        </div>

                        <div className="col-md-2">
                            < SlotNumber

                                data={formData.slotTime}
                                callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                fieldData={formData}
                            />
                        </div>


                        {/* <div className="col-md-2">

                            <Job_MawbRate
                                chargeHeadItem={chargeHeadItem}
                                data={formData.mawbRate}
                                callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                fieldData={formData}
                            />

                        </div>

                        <div className="col-md-2">
                            <Job_HawbRate
                                chargeHeadItem={chargeHeadItem}
                                data={formData.hawbRate}
                                callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                fieldData={formData}
                            />
                        </div> */}

                        <div className="col-md-2">
                            < ShipperInvoiceNumber
                                data={formData.shipperInvoiceNumber}
                                callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                fieldData={formData}
                            />
                        </div>

                        <div className="col-md-2">
                            <InputField
                                field={
                                    {
                                        id: "courierByHand",
                                        label: "Courier/Byhand",
                                        type: 'text',
                                        value: formData.courierByHand,
                                        required: false,
                                        error: formData.courierByHanderror,
                                        // disable: totalPackages === 0 ? false : true,
                                        helperText: 'Packages Required',
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>

                        <div className="col-md-2">
                            <InputField
                                field={
                                    {
                                        id: "courierNumber",
                                        label: "Courier Number",
                                        type: 'number',
                                        value: formData.courierNumber,
                                        required: false,
                                        error: formData.courierNumbererror,
                                        // disable: totalPackages === 0 ? false : true,
                                        helperText: 'Packages Required',
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>



                    </div>
                </div>


                <div className="hr-form-box">
                    <div className="row mb-4">
                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: "grossWeight",
                                        label: "Gross Weight",
                                        type: 'number',
                                        value: formData.grossWeight,
                                        required: true,
                                        error: formData.grossWeighterror,
                                        // disable: totalPackages === 0 ? false : true,
                                        helperText: 'Packages Required',
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: "qtyChargeWeight",
                                        label: "Chargeable Weight",
                                        type: 'number',
                                        value: formData.qtyChargeWeight,
                                        required: true,
                                        error: formData.qtyChargeWeighterror,
                                        // disable: totalPackages === 0 ? false : true,
                                        helperText: 'Packages Required',
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: "packages",
                                        label: "Packages",
                                        type: 'number',
                                        value: formData.packages,
                                        required: true,
                                        error: formData.packageserror,
                                        // disable: totalPackages === 0 ? false : true,
                                        helperText: 'Packages Required',
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>



                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: "airVolumeWeight",
                                        label: "Air Volume Weight",
                                        type: 'number',
                                        value: formData.airVolumeWeight,
                                        required: true,
                                        error: formData.airVolumeWeighterror,
                                        // disable: totalPackages === 0 ? false : true,
                                        helperText: 'Packages Required',
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>


                    </div>


                    {
                        Dimension()
                    }

                </div>


                {

                    TransportMultiDetails()

                }

                {
                    (MultiPickUp || MultiDelivery) &&
                    <div className="hr-form-box">
                        <MultiDeliveryAddress
                            setDeliveryDetailsError={setDeliveryDetailsError}
                            deliveryDetailsError={deliveryDetailsError}
                            MultiDelivery={MultiDelivery}
                            handleBasicInputChange={handleBasicInputChange}
                            setDeliveryTotalPackages={setDeliveryTotalPackages}
                            setDeliveryTotalGrossWeight={setDeliveryTotalGrossWeight}
                        />


                        <MultiPickupAddress
                            setPickupDetailsError={setPickupDetailsError}
                            pickupDetailsError={pickupDetailsError}
                            MultiPickUp={MultiPickUp}
                            handleBasicInputChange={handleBasicInputChange}
                            setPickupTotalGrossWeight={setPickupTotalGrossWeight}
                            setPickupTotalPackages={setPickupTotalPackages}
                        />
                    </div>
                }


                <div className="hr-form-box">
                    <div className="row">
                        <div className="col-md-6">
                            <FinalJobVendors
                                jobDetailsData={jobDetailsData}
                                handleBasicInputChange={handleBasicInputChange}
                                vendorItem={transportNameItem}
                                vendorType={vendorType}
                                finalJobVendorsError={finalJobVendorsError}
                                setFinalJobVendorsError={setFinalJobVendorsError}
                            />
                        </div>

                        <div className="col-md-3">
                            <TextAreafield
                                field={
                                    {
                                        id: "remarks",
                                        label: "Remarks",
                                        type: 'text',
                                        value: formData.remarks,
                                        required: false,
                                        error: formData.remarkserror,
                                        helperText: 'Packages Required',
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className="cmd mt-4">
                    <div></div>
                    <Button variant="contained" onClick={handleFormSubmit}>Submit</Button>
                </div>

            </TabPanel>

            <TabPanel className="p-0 mt-3" value="2">
                {CDDTabHandler(formData)}
            </TabPanel>

            <TabPanel className="p-0 mt-3" value="3">
                <FinalBuying />
            </TabPanel>

            <TabPanel className="p-0 mt-3" value="4">
                <FinalSelling />
            </TabPanel>
        </TabContext>

    </>
}

export default CreateNewJobForm;


export const FinalJobVendors = ({
    jobDetailsData,
    vendorItem,
    vendorType,
    handleBasicInputChange,
    MawbForm,
    finalJobVendorsError,
    setFinalJobVendorsError }) => {

    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };
    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            vendorTypeId: '',
            vendorId: '',
        },
    ]);



    useEffect(() => {
        if (jobDetailsData?.finaljobvendors && jobDetailsData?.finaljobvendors?.length !== 0) {
            let item = jobDetailsData?.finaljobvendors?.map(res => (
                {
                    ...res,
                    key: generateUniqueKey(),
                }
            ));
            setInputFields(item);
        }
    }, [jobDetailsData])

    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            vendorTypeId: '',
            vendorId: '',
        }]);
        setFinalJobVendorsError({})
    };


    const handleMultiInputChange = (key, field, value, index) => {

        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
        if (fieldToUpdate) {
            setFinalJobVendorsError({
                ...finalJobVendorsError,
                [field + index]: !value
            })
            fieldToUpdate[field] = value;



            setInputFields(updatedInputFields);

            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        // id: res?.id || 0,
                        vendorTypeId: res.vendorTypeId,
                        vendorId: res.vendorId,

                    }
                )
            })


            handleBasicInputChange(data, { id: 'finaljobvendors' });
        }
    };

    const removeInputField = (key) => {
        setFinalJobVendorsError({})

        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    // id: res?.id || 0,
                    vendorTypeId: res.vendorTypeId,
                    vendorId: res.vendorId,

                }
            )

        })
        handleBasicInputChange(data, { id: 'finaljobvendors' });
    };

    const filterSubgroupItems = (currentID) => {
        let filterData = vendorItem?.filter(res => !inputFields.some(item => (currentID !== res.value && item.vendorId === res.value)));
        return filterData;
    }


    return <>
        <div className=''>


            <div className="dimensions_table " >
                <table style={{ tableLayout: 'fixed' }}>
                    <tr>
                        <th style={{ width: '55px' }}>
                            <IconButton onClick={addInputField} variant="contained">
                                <Add />
                            </IconButton>
                        </th>
                        <th>Vendor Type</th>
                        <th>Vendor Name</th>

                    </tr>


                    {
                        inputFields.map((field, key) => (
                            <tr>
                                <td>
                                    <IconButton
                                        onClick={() => removeInputField(field.key)}
                                        variant='contained'
                                    >
                                        <Close />
                                    </IconButton>
                                </td>
                                <td>
                                    <Dropdown
                                        className={'mt-0'}
                                        field={
                                            {
                                                id: "vendorTypeId",
                                                label: "",
                                                value: field.vendorTypeId,
                                                required: true,
                                                error: finalJobVendorsError["vendorTypeId" + key],
                                                helperText: 'Contact Person Name Required',
                                                item: vendorType,
                                                onChange: (value) => {
                                                    handleMultiInputChange(field.key, 'vendorTypeId', value, key);
                                                }
                                            }
                                        }
                                    />
                                </td>
                                <td>
                                    <Dropdown
                                        className={'mt-0'}
                                        field={
                                            {
                                                id: "vendorId",
                                                label: "",
                                                value: field.vendorId,
                                                required: true,
                                                error: finalJobVendorsError["vendorId" + key],
                                                helperText: 'Contact Person Name Required',
                                                item: filterSubgroupItems(field.vendorId),
                                                onChange: (value) => {
                                                    handleMultiInputChange(field.key, 'vendorId', value, key);
                                                }
                                            }
                                        }
                                    />
                                </td>

                            </tr>

                        ))
                    }

                </table>
            </div>

        </div>

    </>
}







import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Dropdown, { DateField, InputField } from "../../../component/controls";
import { carrierListawb } from "../../../../services/modalData";
import { GetAirlineMasterByPage, GetGSAMasterByPage } from "../../../../services/constant";
import GetCaller, { PostCaller } from "../../../../services/apiServices";
import { useDispatch } from "react-redux";
import { AddAwbStock, UpdateAwbStock } from "../../../../services/APIConstant/jobConstant";
import { awbstock } from "../../../../navigation/constant";
import { json, useNavigate } from "react-router-dom";


const CreateAwbStock = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [airlineitem, setAirlineItem] = useState([])
    const [gsaItem, setGsaItem] = useState([])

    const [isedit, setIsEdit] = useState(false)
    const [formData, setFormData] = useState({

        airlineId: '',
        airlineGSAId: "",
        airlineName: "",
        awbNumber: '',
        stockDate: "",
        numberOfAwb: ''

    });

    const requestData = {
        airlineId: formData.airlineId,
        airlineGSAId: formData.airlineGSAId,
        airlineName: formData.airlineName,
        awbNumber: formData.awbNumber,
        stockDate: formData.stockDate,
        numberOfAwb: formData.numberOfAwb
    }

    useEffect(() => {
        debugger
        if (sessionStorage.awbstock) {
            const awbstock = JSON.parse(sessionStorage.getItem("awbstock"));
            debugger
            setFormData(awbstock);
            setIsEdit(awbstock)
            sessionStorage.removeItem('awbstock');
        }


        GetCaller({
            API: GetAirlineMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                console.log('GetConsigneeSubAgencyPage', response?.data?.data?.records)
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.legalName,
                    value: res?.id,
                    gsaItem: res?.airlineGSAs || []
                }))

                setAirlineItem(item)

            },
        })


    }, []);
    const [awbList, setAwbList] = useState([]);
    const [carrier, setCarrier] = useState([]);

    const carrierList = [
        { name: "carrier1", value: "1" },
        { name: "carrier2", value: "2" },
    ]

    const handleInputChange = (value, field) => {
        debugger
        const fieldName = field.name;

        setFormData({
            ...formData,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    };


    const handleFormSubmit = () => {
        debugger
        const hasUndefinedValue = (obj) => {
            let changes = {};
            Object.entries(obj).forEach(([key, value]) => {
                if (
                    key === 'airlineName'
                ) {

                }
                else if (!value) {
                    changes[key + "error"] = !value;
                }
            });
            return changes;
        };

        const changes = hasUndefinedValue(requestData);
        if (Object.keys(changes).length > 0) {
            setFormData({
                ...formData, ...changes,

            });
        }
        else if (isedit != 0) {
            EditAwbStock(requestData);
        }
        else {
            PostAwbStock(requestData)

        }


    };

    const PostAwbStock = (requestData) => {
        debugger

        PostCaller({
            API: AddAwbStock,
            callback: () => {
                navigate(`${awbstock}`)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestData
        })
    };



    const EditAwbStock = (requestData) => {
        debugger
        PostCaller({
            API: UpdateAwbStock,
            callback: () => {
                navigate(`${awbstock}`)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestData
        })
    };

    const onChangeHandler = (event) => {
        const { name, value } = event.target;
        setFormData((pre) => {
            return { ...pre, [name]: value };
        });
    };
    const submitHandler = () => {
        if (formData.awbNumber.length > 6) {
            let tempValue = Number(formData.awbNumber);
            const tempAwbList = [];
            for (let i = 0; i < Number(formData.numberOfAwb); i++) {
                const remainder = tempValue % 7;
                tempAwbList.push(`${tempValue}${remainder}`);
                tempValue = tempValue + 1;
            }
            setAwbList(tempAwbList);
        }

        console.log("41", formData);
    };
    return (
        <>


            <div className="hr-form-box">
                <h6>Create AWB Stock</h6>
                <div className="row">
                    {
                        <div className="col-md-3">
                            <Dropdown
                                field={{
                                    id: "airlineId",
                                    label: "Carrier ",
                                    type: 'select',
                                    value: formData.airlineId,
                                    required: true,
                                    error: formData.airlineIderror,
                                    item: airlineitem,
                                    onChange: (value, field) => {
                                        handleInputChange(value, field)
                                        const data = airlineitem.find(res => res?.value === value)
                                        const GSAItems = data?.gsaItem?.map(res => ({
                                            name: res?.gsaName || res?.gsaId,
                                            value: res?.gsaId
                                        }))
                                        setGsaItem(GSAItems)
                                    }
                                }}
                            />
                        </div>

                    }

                    {
                        <div className="col-md-3">
                            <Dropdown
                                field={{
                                    id: "airlineGSAId",
                                    label: "GSA ",
                                    type: 'select',
                                    value: formData.airlineGSAId,
                                    required: true,
                                    error: formData.airlineGSAIderror,
                                    item: gsaItem,
                                    onChange: handleInputChange
                                }}
                            />
                        </div>

                    }




                    {isedit ? "" : <div className="col-md-3">
                        <DateField
                            format='YYYY-MM-DD'
                            field={
                                {
                                    id: "stockDate",
                                    label: 'Stock Date',
                                    type: "date",
                                    value: formData.stockDate,
                                    required: true,
                                    error: formData.stockDateerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    }

                    {isedit ?
                        <div className="col-md-3">
                            <InputField
                                style={{ borderRadius: '0px' }}
                                field={
                                    {
                                        id: "awbNumber",
                                        label: "AWB Number",
                                        type: "text",
                                        disable: true,
                                        value: formData.awbNumber,
                                        required: true,
                                        error: formData.awbNumbererror,
                                        onChange: handleInputChange
                                    }
                                }
                            />
                        </div> :

                        <div className="col-md-3">
                            <div className="d-flex gstWrapper">
                                <InputField
                                    field={
                                        {
                                            id: "",
                                            label: "Awb",
                                            type: "dualInput",
                                            value: formData.airlineId,
                                            required: false,
                                            disable: true,
                                            error: '',
                                            onChange: handleInputChange
                                        }
                                    }
                                />
                                <InputField
                                    style={{ borderRadius: '0px' }}
                                    field={
                                        {
                                            id: "awbNumber",
                                            label: "AWB Number",
                                            type: "text",
                                            disable: false,
                                            value: formData.awbNumber,
                                            required: true,
                                            error: formData.awbNumbererror,
                                            onChange: handleInputChange
                                        }
                                    }
                                />

                            </div>
                        </div>
                    }

                    {isedit ? "" :

                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: "numberOfAwb",
                                        label: 'Number of AWB',
                                        type: "text",
                                        value: formData.numberOfAwb,
                                        required: true,
                                        error: formData.numberOfAwberror,
                                        onChange: handleInputChange
                                    }
                                }
                            />
                        </div>
                    }  {isedit ? "" :
                        <div className="col-md-3 mt-4">
                            <Button variant="contained" onClick={submitHandler}>
                                GET LIST
                            </Button>
                        </div>
                    }
                    {isedit ? "" :
                        <div className="col-md-2 mt-3 " style={{ maxHeight: "150px", minWidth: "160px", backgroundColor: "#ebebeb", borderRadius: '20px', }}>
                            <Box
                                sx={{
                                    backgroundColor: "#ebebeb",
                                    borderRadius: '20px',
                                    height: "150px",
                                    padding: "0px",
                                    minWidth: "160px",
                                    overflowY: 'auto',


                                }}
                            >
                                <List

                                >
                                    {awbList.map((value) => (
                                        <ListItem
                                            sx={{
                                                padding: '0px 0px 0px 10px'
                                            }}
                                        >
                                            <ListItemText primary={value} />
                                        </ListItem>
                                    ))}{" "}
                                </List>
                            </Box>
                        </div>
                    }
                </div>
            </div>

            <div className="cmd">
                <div></div>
                <Button
                    sx={{ m: 2, borderRadius: "10px" }}
                    onClick={handleFormSubmit}
                    variant="contained"
                >
                    Create
                </Button>
            </div>

        </>


    );
};

export default CreateAwbStock;

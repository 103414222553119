import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { GetMasterDepartmentByPage, GetMasterDesignationByPage, GetMasterDivisionByPage, GetMasterNetworkTypeByPage, GetMasterSubGroupByPage, PostMasterDepartment, PostMasterDesignation, PostMasterNetworkType, UpdateMasterDesignation, UpdateMasterDesignationStatus, UpdateMasterNetworkType, UpdateMasterNetworkTypeStatus } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';


const NetworkName = () => {
    const dispatch = useDispatch()
    const [networkNameMasterData, setNetworkNameMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)


    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterNetworkTypeByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({

            sn: index + 1,
            id: res.id,
            networkname: res.name,
            status: res.status
        }));
        setNetworkNameMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
       
        {
            id: 'networkname',
            label: 'Network Name',
            numeric: false,
        },


        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API:   UpdateMasterNetworkTypeStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>
            <MultiTable
                data={networkNameMasterData}
                columns={columns}
                // filterItems={filterItems}
                title={'Network Name'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default NetworkName




const DesignationDrawer = ({ callAPI, editor, row }) => {
    const [closeValidator, setcloseValidator] = useState(false)
    const [networkName, setNetworkName] = useState(row?.networkname || '')
    const [networkNameError, setNetworkNameError] = useState(false)

    const dispatch = useDispatch()


    const drawerOpenCallbackHandler = () => {
        setNetworkName(row?.networkname || '')
        setNetworkNameError(false)
    }

    const handleSubmit = () => {
        if (!networkName) {
            setNetworkNameError(!networkName)


        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: networkName,           
                status: row.status
              } : {
                 name: networkName,        
              }             
              editor ?
                PostCaller({
                  API: UpdateMasterNetworkType,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
        
                :
        
                PostCaller({
                  API: PostMasterNetworkType,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }

    useEffect(() => {
        setcloseValidator(false)

    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'networkname':
                setNetworkName(value)
                setNetworkNameError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Network Name",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Network Name',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Network",
            tooltitle: 'Add Network Name',
            defaultbtnfalse: true
        }





    const FieldData = [
        {
            id: 'networkname',
            label: 'Network Name',
            type: 'text',
            value: networkName,
            required: true,
            error: networkNameError,
            helperText: 'Network Name required',
            onChange: handleInputChange,

        },
    ];

    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody} />

    </>

}


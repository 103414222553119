import { Avatar, Switch } from '@mui/material';
import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
    accountledger,
    acgroup,
    airport,
    areamaster,
    assetcategory,
    assetmaster,
    bizInstituteType,
    branch,
    city,
    country,
    department,
    designation,
    division,
    gststatus,
    home,
    hremployelist,
    networktype,
    newlead,
    materializelead,
    deadlead,
    packagemaster,
    primarygroup,
    reasontype,
    sacmaster,
    sectormaster,
    sploperation,
    state,
    subdepartment,
    subgroup,
    taxratemaster,
    tdsmaster,
    unitmaster,
    vendormaster,
    vendortype,
    customerlist,
    gsamaster,
    airline,
    chargehead,
    createenquiry,
    expectedgoodofexports,
    goodsinregisterlist,
    goodsoutregisterlist,
    stockRegister,
    vehicletype,
    airportlist,
    airenquirylist,
    subagencyshippeplist,
    airimportenquirylist,
    jobplanner,
    notifymaster,
    cargotype,
    consigneesubagencylist,
    hawb,
    mawb,
    thirdcountrymisccustomerlist,
    awbstock,
    iatastock,
    courierstock,
    airexportjoblist,
    flightlist
} from '../../navigation/constant';
import { SmallSwitch } from './controls';
import IataStock from '../pages/job/iata/iatastock';


const SideBar = ({ callback, remoteShowCallback }) => {

    const location = useLocation();

    const isSubItemActive = (subItems) => {
        return subItems?.some((subItem) =>
            subItem?.link === location.pathname ||
            (subItem?.subItems && subItem?.subItems.some(item => item.link === location.pathname))
        );
    };

    const sidebarList = [
        {
            path: home,
            icon: <span className="las la-store"></span>,
            title: <div data-parent="Analytics">Dashboard</div>
        },
        {
            path: 'N/A',
            icon: <span className="las la-business-time"></span>,
            title: <div data-parent="Layouts">HR <br />& <br /> Admin</div>,
            subsidebarData: [


                {
                    label: 'Department',
                    link: department,
                    icon: <i className="las la-warehouse"></i>
                },
                {
                    label: 'Sub Department',
                    link: subdepartment,
                    icon: <i className="lab la-simplybuilt"></i>
                },
                {
                    label: 'Designation',
                    link: designation,
                    icon: <i className="las la-user-graduate"></i>
                },
                {
                    label: 'Asset Category',
                    link: assetcategory,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Asset',
                    link: assetmaster,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Employee',
                    link: hremployelist,
                    icon: <i className="las la-user-friends"></i>
                },


            ]
        },
        {
            path: 'N/A',
            icon: <span className="las la-desktop"></span>,
            title: <div data-parent="Layouts">Governing Master</div>,
            subsidebarData: [
                {
                    label: 'Sub Group',
                    link: subgroup,
                    icon: <i className="lab la-buffer"></i>
                },

                {
                    label: 'Airport',
                    link: airport,
                    icon: <i className="lab la-buffer"></i>
                },

                {
                    label: 'Account Ledger',
                    link: accountledger,
                    icon: <i className="lab la-buffer"></i>
                },


                {
                    label: 'Tax Rate',
                    link: taxratemaster,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'TDS',
                    link: tdsmaster,
                    icon: <i className="lab la-buffer"></i>
                },

                {
                    label: 'Vendor',
                    link: vendormaster,
                    icon: <i className="lab la-buffer"></i>
                },

                {
                    label: 'Customer',
                    link: customerlist,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: '3rd Country / Misc. Customer',
                    link: thirdcountrymisccustomerlist,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Airline',
                    link: airline,
                    icon: <i className="lab la-buffer"></i>
                },

                {
                    label: 'GSA Master',
                    link: gsamaster,
                    icon: <i className="lab la-buffer"></i>
                },


                {
                    label: 'Charge Head',
                    link: chargehead,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Vehicle Type',
                    link: vehicletype,
                    icon: <i className="lab la-buffer"></i>
                },

                // {
                //     label: 'GSA Master',
                //     link: gsamaster,
                //     icon: <i className="lab la-buffer"></i>
                // },



                // {
                //     label: 'Accounts & Finance',
                //     link: 'N/A',
                //     icon: <i className="lab la-buffer"></i>,
                //     subItems: [
                //         // { label: 'Account', link: 'N/A' },
                //         {
                //             label: 'Account',
                //             subItems: [
                //                 { label: 'A/C Groups', link: 'N/A' },
                //                 { label: 'Primary Group', link: 'N/A' },
                //                 { label: 'Sub-Group', link: 'N/A' },

                //                 // {
                //                 //     label: 'Sub-Option 1.2.2',
                //                 //     subItems: [
                //                 //         { label: 'Sub-Option 1.2.2.1', link: 'N/A' },
                //                 //         { label: 'Sub-Option 1.2.2.2', link: 'N/A' },
                //                 //     ],
                //                 // },
                //             ],
                //         },
                //         {
                //             label: 'Account Ledger',
                //             subItems: [
                //                 { label: 'GSA Master', link: 'N/A' },
                //                 { label: 'Airline Master', link: 'N/A' },
                //                 { label: 'S/Line Master', link: 'N/A' },
                //                 { label: 'Customer Master', link: 'N/A' },
                //                 { label: 'Vendor Master', link: 'N/A' },
                //                 { label: 'Charges Master', link: 'N/A' },
                //             ],
                //         },
                //         {
                //             label: 'Taxtation',
                //             subItems: [
                //                 { label: 'TDS', link: 'N/A' },
                //                 { label: 'GST Tax Rate Slab', link: 'N/A' },
                //             ],
                //         },
                //         {
                //             label: 'Other',
                //             subItems: [
                //                 { label: 'Vendor Type', link: 'N/A' },
                //                 { label: 'FN Due Date', link: 'N/A' },
                //                 { label: 'Inv. Locking Master', link: 'N/A' },
                //                 { label: 'Biz Institute Type', link: 'N/A' },
                //                 { label: 'Customer Type', link: 'N/A' },
                //                 { label: 'GST Status', link: 'N/A' },
                //                 { label: 'Payable Type', link: 'N/A' },
                //                 { label: 'Receivable Types', link: 'N/A' },
                //                 { label: 'M.S.P Master', link: 'N/A' },
                //                 { label: 'Company Profile', link: 'N/A' },
                //                 { label: 'Jump FortNight', link: 'N/A' },

                //             ],
                //         },
                //         {
                //             label: 'Invoicing',
                //             subItems: [

                //                 { label: 'AE-INV List', link: 'N/A' },
                //                 { label: 'AE- E INV List', link: 'N/A' },
                //                 { label: 'AE-Pending INV List', link: 'N/A' },
                //                 { label: 'AE-Int INV', link: 'N/A' },
                //                 { label: 'AI-INV List', link: 'N/A' },
                //                 { label: 'AI- E INV List', link: 'N/A' },
                //                 { label: 'AI-Pending INV List', link: 'N/A' },
                //                 { label: 'AI-Int INV', link: 'N/A' },
                //                 { label: 'OE-INV List', link: 'N/A' },
                //                 { label: 'OE- E INV List', link: 'N/A' },
                //                 { label: 'OE-Pending INV List', link: 'N/A' },
                //                 { label: 'OE-Int INV', link: 'N/A' },
                //                 { label: 'OI-INV List', link: 'N/A' },
                //                 { label: 'AOI- E INV List', link: 'N/A' },
                //                 { label: 'OI-Pending INV List', link: 'N/A' },
                //                 { label: 'OI-Int INV', link: 'N/A' },
                //                 { label: 'CRN Register', link: 'N/A' },
                //                 { label: 'CRN Creation', link: 'N/A' },

                //             ],
                //         },
                //         {
                //             label: 'Purchase & Payment',
                //             subItems: [
                //                 { label: 'AE-PI Booking', link: 'N/A' },
                //                 { label: 'AI-PI Booking', link: 'N/A' },
                //                 { label: 'OE- PI Booking', link: 'N/A' },
                //                 { label: 'OI-PI Booking', link: 'N/A' },
                //                 { label: 'CRN Register', link: 'N/A' },
                //                 { label: 'Purchase Register', link: 'N/A' },
                //                 { label: 'Vendor payment', link: 'N/A' },
                //                 { label: 'Pending Jobs for P.INV', link: 'N/A' },
                //             ],
                //         },
                //         {
                //             label: 'Collections',
                //             subItems: [
                //                 { label: 'Payment Receipts', link: 'N/A' },
                //                 { label: 'SOA', link: 'N/A' },
                //                 { label: 'Outstanding', link: 'N/A' },
                //                 { label: 'Unmapped', link: 'N/A' },
                //             ],
                //         },
                //         {
                //             label: 'Reports',
                //             subItems: [
                //                 { label: 'Airline CSR', link: 'N/A' },
                //                 { label: 'IATA CSR', link: 'N/A' },
                //                 { label: 'Vendor CSR', link: 'N/A' },
                //                 { label: 'Customer CSR', link: 'N/A' },
                //                 { label: 'G.P Report', link: 'N/A' },
                //                 { label: 'TDS Payable', link: 'N/A' },
                //                 { label: 'GSTR1', link: 'N/A' },
                //                 { label: 'GSTR2', link: 'N/A' },
                //                 { label: 'TDS Receivable', link: 'N/A' },
                //                 { label: 'Chargeheadwise income', link: 'N/A' },
                //                 { label: 'Chargeheadwise Expense', link: 'N/A' },
                //                 { label: 'S/Line and co-loader', link: 'N/A' },
                //                 { label: 'Customer Payment', link: 'N/A' },
                //                 { label: 'Error Report', link: 'N/A' },

                //             ],
                //         },
                //     ],
                // },

            ]
        },
        {
            path: 'N/A',
            icon: <span className="las la-project-diagram"></span>,
            title: <div data-parent="Layouts">Operational<br />Master</div>,
            subsidebarData: [
                {
                    label: 'Network',
                    link: networktype,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Reason',
                    link: reasontype,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'SAC Code',
                    link: sacmaster,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Special Requirement',
                    link: sploperation,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'UNIT',
                    link: unitmaster,
                    icon: <i className="lab la-buffer"></i>
                },
                {

                    label: 'Vendor Type',
                    link: vendortype,
                    icon: <i className="lab la-buffer"></i>
                },

            ]
        },
        {
            path: 'N/A',
            icon: <span className="las la-credit-card"></span>,
            title: <div data-parent="Layouts">Transaction<br />Master</div>
        },

        {
            path: 'N/A',
            icon: <span className="las la-map-marked"></span>,
            title: <div data-parent="Layouts" style={{ textAlign: "center" }}>Marketing
                <br />& <br /> Sales
            </div>,
            subsidebarData: [
                {
                    label: 'Marketing',
                    path: 'N/A',
                    icon: <i className="las la-map-marked"></i>,
                    subItems: [
                        {
                            label: 'New Lead',
                            link: newlead,
                            icon: <i className="lab la-buffer"></i>
                        },
                        {
                            label: 'Metalized Lead',
                            link: materializelead,
                            icon: <i className="lab la-buffer"></i>
                        },
                        {
                            label: 'Dead Lead',
                            link: deadlead,
                            icon: <i className="lab la-buffer"></i>
                        },
                    ]
                },
                {
                    label: 'Enquiry',
                    path: 'N/A',
                    icon: <i className="las la-search-dollar"></i>,
                    subItems: [
                        {
                            label: 'Air Freight',
                            // link: 'N/A', 
                            icon: <i className="lab la-buffer"></i>,
                            subItems: [
                                { label: 'Air Export', link: airenquirylist },
                                { label: 'Air Import', link: airimportenquirylist },
                            ]
                        },
                        {
                            label: 'Ocean Freight',
                            link: materializelead,
                            icon: <i className="lab la-buffer"></i>
                        },
                        {
                            label: 'RFQ',
                            link: deadlead,
                            icon: <i className="lab la-buffer"></i>
                        },
                    ]
                },
                {
                    label: 'Canned Response',
                    path: 'N/A',
                    icon: <i className="las la-file-invoice"></i>,
                }
            ]

        },
        {
            path: 'N/A',
            icon: <span className="las la-folder-open"></span>,
            title: <div data-parent="Layouts">Job <br />Transaction</div>,
            subsidebarData: [
                {
                    label: 'Planner',
                    link: jobplanner,
                    icon: <i className="lab la-buffer"></i>,
                },
                {
                    label: 'Export Job',
                    link: airexportjoblist,
                    icon: <i className="lab la-buffer"></i>,
                },
                {
                    label: 'HAWB',
                    link: hawb,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'MAWB',
                    link: mawb,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'AWB Stock',
                    link: awbstock,
                    icon: <i className="lab la-buffer"></i>,
                },
                {
                    label: 'IATA Stock',
                    link: iatastock,
                    icon: <i className="lab la-buffer"></i>,
                },
                // {
                //     label: 'Courier Stock',
                //     link: courierstock,
                //     icon: <i className="lab la-buffer"></i>,
                // }
            ]
        },
        {
            path: flightlist,
            icon: <span className="las la-plane"></span>,
            title: <div data-parent="Layouts">Flight <br /> Register</div>,
      
        },
        {
            path: 'N/A',
            icon: <span className="las la-home"></span>,
            title: <div data-parent="Layouts">Warehouse</div>,
            subsidebarData: [
                {
                    label: 'Seal Stock',
                    link: stockRegister,
                    icon: <i className="lab la-buffer"></i>
                },

                {
                    label: 'Expected Goods',
                    link: expectedgoodofexports,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Goods In Register',
                    link: goodsinregisterlist,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Goods Out Register',
                    link: goodsoutregisterlist,
                    icon: <i className="lab la-buffer"></i>
                },

            ]
        },
        {
            path: airportlist,
            icon: <span className="las la-road"></span>,
            title: <div data-parent="Layouts">Airport <br /> (APT)</div>,
        },
        {
            path: 'N/A',
            icon: <span className="las la-folder-open"></span>
            ,
            title: <div data-parent="Layouts">My <br />Barge</div>
        },
        {
            path: 'N/A',
            icon: <span className="las la-folder-open"></span>,
            title: <div data-parent="Layouts">Reports</div>
        },
        {
            path: 'N/A',
            icon: <span className="las la-folder-open"></span>,
            title: <div data-parent="Layouts">Hidden<br /> Master</div>,
            subsidebarData: [
                {
                    label: 'Area',
                    link: areamaster,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Sector',
                    link: sectormaster,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Country',
                    link: country,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'State',
                    link: state,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'City',
                    link: city,
                    icon: <i className="lab la-buffer"></i>
                },
              
               
                {
                    label: 'Branch',
                    link: branch,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Division',
                    link: division,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Primary Group',
                    link: primarygroup,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Account Group',
                    link: acgroup,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Biz Institute Type',
                    link: bizInstituteType,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Package',
                    link: packagemaster,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'GST Status',
                    link: gststatus,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Sub Agency Shipper',
                    link: subagencyshippeplist,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Consignee',
                    link: consigneesubagencylist,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Notify',
                    link: notifymaster,
                    icon: <i className="lab la-buffer"></i>
                },
                {
                    label: 'Cargo Type',
                    link: cargotype,
                    icon: <i className="lab la-buffer"></i>
                },



            ]
        },
    ]

    return (
        <>
            {/* <!-- Left side bar section start --> */}

            <div className="leftSidebar">
                <div className="sidebar">
                    <div className="sidebar-brand">
                        <div className="brand-flex">
                            <img src={require('../assets/img/avatars/smallheaderlogo.jpg')} alt="" />
                        </div>
                    </div>


                    {/* <!-- Side bar logo section end --> */}
                    <div className="scrollbar" id='style-1'>
                        <div className="">
                            <div className="sidebar-menu">
                                <ul className="menu-head">
                                    <li className="remote-work-title" onClick={remoteShowCallback}>
                                        Remote Work
                                        <SmallSwitch />
                                    </li>
                                    {
                                        sidebarList.map((res, key) => {
                                            return <li className="menu-item no_submenu" key={key} onMouseEnter={() => callback(res?.subsidebarData ? res?.subsidebarData : false)} onClick={() => callback(res?.subsidebarData ? res?.subsidebarData : false)}>
                                                <NavLink to={res.path}
                                                    className={`${isSubItemActive(res?.subsidebarData) ? 'active' : ''}`}
                                                    onClick={(e) => {
                                                        if (res.path === 'N/A') {
                                                            e.preventDefault();
                                                        }
                                                    }}

                                                >
                                                    {res.icon}
                                                    {/* <!-- <img src="../img/home.png" width="30px" style="color: #922c88;" alt=""> --> */}
                                                    {res.title}
                                                </NavLink>
                                            </li>
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- sidebar end --> */}


            </div>



            {/* <!-- Left side bar section end --></ul> */}
        </>
    )
}

export default SideBar;


export const SubMenuSideBar = ({ submenuKey, onNavLinkClick }) => {


    return <>
        <div id='style-1' className={`submenu-sidebar  ${submenuKey ? 'openSubSideBar' : ''}  `} >
            <div className="sub-sidebar">
                <ul>
                    {submenuKey && submenuKey.map((item) => (
                        <SidebarItem key={item.label} item={item} depth={1} onNavLinkClick={onNavLinkClick} />
                    ))}
                </ul>
            </div>
        </div >
    </>
}




function SidebarItem({ item, depth, onNavLinkClick }) {
    const [subItemsVisible, setSubItemsVisible] = useState(false);

    const toggleSubItems = () => {
        setSubItemsVisible(!subItemsVisible);
    };

    const indentation = { paddingLeft: `${depth * 10}px` }; // Adjust the indentation as needed

    const handleLinkClick = () => {
        if (item.subItems) {
            toggleSubItems();
        }
        else {
            onNavLinkClick(false);
        }
        // Call the parent component's click handler

    };


    return (
        <li className='dropdownBody'>
            <NavLink to={item.link} onClick={handleLinkClick} style={indentation}>
                <div className="cmd">
                    <div style={{ display: "flex", alignItems: "center" }}> {item.icon} {item.label}</div>
                    <div className={`${subItemsVisible ? 'rotate-icon' : "false"}`}>{item.subItems && (<ExpandLessIcon />)}</div>
                </div>
            </NavLink>
            {/* {item.subItems && subItemsVisible && (
                <ul className="dropdown">
                    {item.subItems.map((subItem) => (
                        <SidebarItem key={subItem.label} item={subItem} depth={depth + 1} onNavLinkClick={onNavLinkClick} />
                    ))}
                </ul>
            )} */}

            <ul className={`dropdown ${item.subItems && subItemsVisible && 'opendropdown'}`}>
                {item?.subItems?.map((subItem) => (
                    <SidebarItem key={subItem.label} item={subItem} depth={depth + 1} onNavLinkClick={onNavLinkClick} />
                ))}
            </ul>

        </li>
    );
}


import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
    accountledger, acgroup, bizInstituteType, areamaster, branch, city, country, createairline, createcustomer,
    createvendor, deadlead, department, designation, division, home, hremployelist, hremployelistForm, login, materializelead,
    networktype, newlead, primarygroup, sectormaster, state, subdepartment, subgroup, technicalerror, vendortype, sploperation,
    tdsmaster, createtdsmaster, sacmaster, reasontype, assetmaster, assetcategory, createtaxmaster, taxratemaster, gsamaster,
    creategsamaster, vendormaster, airport, unitmaster, packagemaster, gststatus, customerlist, checkcustomer, customerbranch,
    customercontactdetail, airline, vendormanagebalance, vendoropeningbalance, chargeheadform, chargeheadmaster, createchargehead,
    createenquiry, chargehead, createnotify, notifymaster, subagencyshipperform, subagencyshippeplist, consigneesubagency, consigneesubagencyaddress, createconsigneesubagency, consigneesubagencylist, airenquirylist, employeedetails, expectedgoodofexports, stockRegister, creategoodinregister, goodsinregisterlist, goodsoutregisterlist, vehicletype, chalanform, airportlist, airportchecklist, airexportjoblist, jobregister, createinitialquote, pdfaircustomer, inlinepdf, createairimport, airimportenquirylist, enquiryvendorpdf, jobplanner, adesign, createjob, preview, hawb, createhawbform, cargotype, mawb, createmawbform, createfinalquote, thirdcountrymisccustomerlist, checkthirdcountrymisccustomer, createthirdcountrymisccustomer, createiatastock, createawbstock, createcourierawb, awbstock, courierstock, iatastock,
    creaeholdawb,
    createholdawb,
    createiatahold,
    flightlist,
    flightRegister,
    goodsinregisterform,
    goodsoutregisterform,
    failedtoconnect
} from './constant'


import Layout from '../layout';
import Notify from '../mycomponent/pages/master/notify';
import { CreateNotifyForm } from '../mycomponent/forms/createNotifyForm';
import Areamaster from '../mycomponent/pages/master/areamaster';
import Sectormaster from '../mycomponent/pages/master/sectormaster';
import Country from '../mycomponent/pages/master/country';
import State from '../mycomponent/pages/master/state';
import City from '../mycomponent/pages/master/city';
import Branch from '../mycomponent/pages/master/branch';
import Division from '../mycomponent/pages/master/division';
import SubDepartment from '../mycomponent/pages/master/subDepartment';
import Department from '../mycomponent/pages/master/department';
import Designation from '../mycomponent/pages/master/designation';
import SubGroup from '../mycomponent/pages/master/subGroup';
import Newlead from '../mycomponent/pages/newlead';
import Materializelead from '../mycomponent/pages/materializelead';
import Deadlead from '../mycomponent/pages/deadlead';
import HrEmployelist from '../mycomponent/pages/hrEmployelist';
import HrEmployeeLIstForm from '../mycomponent/forms/hrEmployeeLIstForm';
import Acgroup from '../mycomponent/pages/master/accountgroup';
import PrimaryGroup from '../mycomponent/pages/master/primaryGroup';
import Home from '../mycomponent/pages';
import UserLogin from '../mycomponent/pages/auth/login';
import TexhnicalError from '../mycomponent/pages/auth/technical_error';
import Unauthorized from '../mycomponent/pages/auth/unauthorized';
import AccountLedger from '../mycomponent/pages/master/accountLedger';
import { VendorForm } from '../mycomponent/forms/createVendorform';
import { AirlineForm } from '../mycomponent/forms/creatAirlineform';
import BizInstituteType from '../mycomponent/pages/master/bizInstituteType';
import VendorType from '../mycomponent/pages/master/vendorType';
import NetworkName from '../mycomponent/pages/master/networkName';
import SplOperationReq from '../mycomponent/pages/master/splOperationReq';
import TdsMaster from '../mycomponent/pages/master/tdsmaster';
import { TdsMasterForm } from '../mycomponent/forms/createTdsMasterform';
import SacCodeMaster from '../mycomponent/pages/master/saccodemaster';
import ReasonType from '../mycomponent/pages/master/reasonTypeMaster';
import AssetMaster from '../mycomponent/pages/master/assetmaster';
import AssetCategory from '../mycomponent/pages/master/assetCategory';
import { TaxMasterForm } from '../mycomponent/forms/createTaxMasterForm';
import TaxRateMaster from '../mycomponent/pages/master/taxrateMaster';
import GsaMaster from '../mycomponent/pages/master/gsamaster';
import AirlineMaster from '../mycomponent/pages/master/airline';
import { CreateGsaForm } from '../mycomponent/forms/createGsaForm';
import VendorMaster from '../mycomponent/pages/master/vandorMaster';
import AirportMaster from '../mycomponent/pages/master/airportMaster';
import UnitMaster from '../mycomponent/pages/master/unitMaster';
import PackageMaster from '../mycomponent/pages/master/packageMaster';
import GstStatus from '../mycomponent/pages/master/gstStatus';
import PageNotFound from '../mycomponent/pages/auth/pagenotFound';
import CustomerList from '../mycomponent/pages/customer/customerList';
import CheckCustomer from '../mycomponent/pages/customer/checkCustomer';
import CustomerBranch from '../mycomponent/pages/customer/customerBranchForm';
import VendorManageBalance from '../mycomponent/pages/vendor/vendorManagebalance';
import VendorOpeningBalance from '../mycomponent/pages/vendor/vendorOpeningBalance';
import { ChargeHeadForm } from '../mycomponent/forms/chargeheadform';

import CreateCustomer from '../mycomponent/pages/customer/createCustomer';
import EnquiryAirExport from '../mycomponent/pages/enquiry/airexport';
import EnquiryLayout from '../mycomponent/pages/enquiry';
import ChargeHeadMaster from '../mycomponent/pages/master/chargehead';
import { SubAgencyShipperForm } from '../mycomponent/pages/subAgencyShipper/createSubAgencyShipper';
import SubAgencyShipperList from '../mycomponent/pages/subAgencyShipper/subAgencyShipperList';
import { SubAgencyAddressForm } from '../mycomponent/pages/subAgencyShipper/SubAgencyAddressShipper';
import ConsigneeAgencyList from '../mycomponent/pages/addConsignee/consigneeSubAgencyList';
import { ConsigneeSubAgencyForm } from '../mycomponent/pages/addConsignee/createConsigneeSubAgency';
import { useDispatch } from 'react-redux';
import EmployeeDetails from '../mycomponent/component/employeeDetails';
import ExpectedGoodOfExportsList from '../mycomponent/pages/warehouse/expectedGoodOfExportsList';
import StockRegister from '../mycomponent/pages/warehouse/stockRegister';
import CreateGoodInRegister from '../mycomponent/pages/warehouse/createGoodInRegister';
import GoodInRegisterList from '../mycomponent/pages/warehouse/goodInRegisterList';
import GoodOutRegisterList from '../mycomponent/pages/warehouse/goodsOutRegisterList';
import VehicleType from '../mycomponent/pages/master/vehicleType';
import ChalanForm from '../mycomponent/pages/warehouse/chalan';
import AirportList from '../mycomponent/pages/airport/airportlist';
import AirExportJob from '../mycomponent/pages/job/airexportJoblist';
import { JobRegister } from '../mycomponent/pages/job/jobregister';
import CreateInitialQuote from '../mycomponent/pages/enquiry/createInitialQuote';
import { PdfAirCustomer } from '../mycomponent/pages/enquiry/pdfairCustomer';
import { InlinePdfAir } from '../mycomponent/pages/enquiry/inlinepdf';

import AirImportEnquiryList from '../mycomponent/pages/enquiry/airImportEnquiryList';
import AirImportEnquiryLayout from '../mycomponent/pages/enquiry/airimportformLayout';
import EnquiryVendorPdf from '../mycomponent/pages/enquiry/enquiryvendorPdf';
import JobPlanner from '../mycomponent/pages/job/jobplanner';
import Adesign from '../mycomponent/pages/enquiry/intialQuote/preview';
import CreateJobForm from '../mycomponent/pages/job/createJobForm';
import Hawb from '../mycomponent/pages/master/hawb';
import CreateHawbForm from '../mycomponent/forms/hawbforms/createhawbform';
import CargoType from '../mycomponent/pages/master/cargotype';
import CreateMawbForm from '../mycomponent/forms/mawbforms/createmawbform';
import Mawb from '../mycomponent/pages/master/mawb';
import CreateFinalQuote from '../mycomponent/pages/enquiry/finalQuote/createFinalQuote';
import ThirdCountryMiscCustomerList from '../mycomponent/pages/customer/thirdCountryMiscList';
import CheckThirdCountryMiscCustomer from '../mycomponent/pages/customer/thirdcountryCheckCustomer';
import CreateThirdCountryMiscCustomer from '../mycomponent/pages/customer/createThirdCountryMiscCustomer';
import CreateAwbStock from '../mycomponent/pages/job/awb/createawbStock';
import AwbStock from '../mycomponent/pages/job/awb/awbstock';
import CreateHoldAwb from '../mycomponent/pages/job/awb/holdawb';
import CreateIataHold from '../mycomponent/pages/job/iata/iatahold';
import IataStock from '../mycomponent/pages/job/iata/iatastock';
import CourierStore from '../mycomponent/pages/job/courier/courierstock';
import CreateCarrierStock from '../mycomponent/pages/job/courier/createCourierAwb';
import CreateIataStock from '../mycomponent/pages/job/iata/createIATAStock';
import AirExportEnquiryList from '../mycomponent/pages/enquiry/airExportEnquiryList';
import FlightRegister from '../mycomponent/pages/job/component/flight';
import FlightRegisterList from '../mycomponent/pages/job/component/flightList';
import ExpectedGoodsByJob from '../mycomponent/pages/warehouse/expectedGoodsByJob';
import CreateNewJobForm from '../mycomponent/pages/job/createNewJobForm';
import CreateGoodsInRegisterFormF from '../mycomponent/pages/warehouse/createGoodsinRegisterForm';
import CreateGoodsInRegisterForm from '../mycomponent/pages/warehouse/createGoodsinRegisterForm';
import CreateGoodsOutRegisterForm from '../mycomponent/pages/warehouse/createGoodsOutRegister';
import AirportListPlanForToday from '../mycomponent/pages/airport/airportlist_planefortoday';








const RouterConfig = () => {

    const token = localStorage.getItem("authToken");


    return (
        <>
            <Routes>
                <Route path={'*'} element={token ? <PageNotFound /> : <Unauthorized />} />
                <Route path={technicalerror} element={<TexhnicalError />} />
                <Route path={failedtoconnect} element={<TexhnicalError />} />

                {
                    token ? (
                        <Route path={login} element={<Layout />}>
                            <Route index path={home} element={<Home />} />
                            <Route path={areamaster} element={<Areamaster />} />
                            <Route path={sectormaster} element={<Sectormaster />} />
                            <Route path={country} element={<Country />} />
                            <Route path={state} element={<State />} />
                            <Route path={city} element={<City />} />
                            <Route path={branch} element={<Branch />} />
                            <Route path={division} element={<Division />} />
                            <Route path={department} element={<Department />} />
                            <Route path={subdepartment} element={<SubDepartment />} />
                            <Route path={designation} element={<Designation />} />
                            <Route path={subgroup} element={<SubGroup />} />
                            <Route path={hremployelist} element={<HrEmployelist />} />
                            <Route path={hremployelistForm} element={<HrEmployeeLIstForm />} />
                            <Route path={newlead} element={<Newlead />} />
                            <Route path={materializelead} element={<Materializelead />} />
                            <Route path={deadlead} element={<Deadlead />} />
                            <Route path={acgroup} element={<Acgroup />} />
                            <Route path={primarygroup} element={<PrimaryGroup />} />
                            <Route path={createvendor} element={<VendorForm />} />
                            <Route path={createairline} element={<AirlineForm />} />
                            <Route path={createcustomer} element={<CreateCustomer />} />
                            <Route path={createthirdcountrymisccustomer} element={<CreateThirdCountryMiscCustomer />} />
                            <Route path={vendortype} element={<VendorType />} />
                            <Route path={bizInstituteType} element={<BizInstituteType />} />
                            <Route path={accountledger} element={<AccountLedger />} />
                            <Route path={networktype} element={<NetworkName />} />
                            <Route path={sploperation} element={<SplOperationReq />} />
                            <Route path={tdsmaster} element={<TdsMaster />} />
                            <Route path={createtdsmaster} element={<TdsMasterForm />} />
                            <Route path={sacmaster} element={<SacCodeMaster />} />
                            <Route path={reasontype} element={<ReasonType />} />
                            <Route path={assetmaster} element={<AssetMaster />} />
                            <Route path={assetcategory} element={<AssetCategory />} />
                            <Route path={createtaxmaster} element={<TaxMasterForm />} />
                            <Route path={taxratemaster} element={<TaxRateMaster />} />
                            <Route path={gsamaster} element={<GsaMaster />} />
                            <Route path={gststatus} element={<GstStatus />} />
                            <Route path={creategsamaster} element={<CreateGsaForm />} />
                            <Route path={vendormaster} element={<VendorMaster />} />
                            <Route path={airport} element={<AirportMaster />} />
                            <Route path={unitmaster} element={<UnitMaster />} />
                            <Route path={packagemaster} element={<PackageMaster />} />
                            <Route path={customerlist} element={<CustomerList />} />
                            <Route path={thirdcountrymisccustomerlist} element={<ThirdCountryMiscCustomerList />} />
                            <Route path={checkcustomer} element={<CheckCustomer />} />
                            <Route path={checkthirdcountrymisccustomer} element={<CheckThirdCountryMiscCustomer />} />
                            <Route path={customerbranch} element={<CustomerBranch />} />
                            <Route path={airline} element={<AirlineMaster />} />
                            <Route path={vendormanagebalance} element={<VendorManageBalance />} />
                            <Route path={vendoropeningbalance} element={<VendorOpeningBalance />} />
                            <Route path={chargeheadform} element={<ChargeHeadForm />} />
                            <Route path={createenquiry} element={<EnquiryLayout />} />
                            <Route path={createairimport} element={<AirImportEnquiryLayout />} />
                            <Route path={createchargehead} element={<ChargeHeadForm />} />
                            <Route path={chargehead} element={<ChargeHeadMaster />} />
                            <Route path={notifymaster} element={<Notify />} />
                            <Route path={createnotify} element={<CreateNotifyForm />} />
                            <Route path={subagencyshipperform} element={<SubAgencyShipperForm />} />
                            <Route path={subagencyshippeplist} element={<SubAgencyShipperList />} />
                            <Route path={createconsigneesubagency} element={<ConsigneeSubAgencyForm />} />
                            <Route path={consigneesubagencyaddress} element={<SubAgencyAddressForm />} />
                            <Route path={consigneesubagencylist} element={<ConsigneeAgencyList />} />
                            <Route path={airenquirylist} element={<AirExportEnquiryList />} />
                            <Route path={airimportenquirylist} element={<AirImportEnquiryList />} />
                            <Route path={employeedetails} element={<EmployeeDetails />} />


                            {/* <Route path={expectedgoodofexports} element={<ExpectedGoodOfExportsList />} /> */}
                            <Route path={expectedgoodofexports} element={<ExpectedGoodsByJob />} />
                            <Route path={stockRegister} element={<StockRegister />} />
                            <Route path={goodsinregisterlist} element={<GoodInRegisterList />} />
                            <Route path={creategoodinregister} element={<CreateGoodInRegister />} />
                            <Route path={goodsinregisterform} element={<CreateGoodsInRegisterForm />} />
                            <Route path={goodsoutregisterform} element={<CreateGoodsOutRegisterForm />} />

                            <Route path={goodsoutregisterlist} element={<GoodOutRegisterList />} />


                            <Route path={vehicletype} element={<VehicleType />} />
                            <Route path={chalanform} element={<ChalanForm />} />
                            <Route path={airportlist} element={<AirportListPlanForToday />} />
                            {/* <Route path={airportlist} element={<AirportList />} /> */}
                            <Route path={airexportjoblist} element={<AirExportJob />} />
                            <Route path={jobregister} element={<JobRegister />} />
                            <Route path={createinitialquote} element={<CreateInitialQuote />} />
                            <Route path={createfinalquote} element={<CreateFinalQuote />} />
                            <Route path={pdfaircustomer} element={<PdfAirCustomer />} />
                            <Route path={inlinepdf} element={<InlinePdfAir />} />
                            <Route path={enquiryvendorpdf} element={<EnquiryVendorPdf />} />
                            <Route path={preview} element={<Adesign />} />
                            <Route path={jobplanner} element={<JobPlanner />} />

                            {/* old version  */}
                            <Route path={'createjob'} element={<CreateJobForm />} />
                            {/* liatest testing  */}
                            <Route path={createjob} element={<CreateNewJobForm />} />

                            <Route path={hawb} element={<Hawb />} />
                            <Route path={createhawbform} element={<CreateHawbForm />} />
                            <Route path={cargotype} element={<CargoType />} />
                            <Route path={mawb} element={<Mawb />} />
                            <Route path={createmawbform} element={<CreateMawbForm />} />
                            <Route path={createawbstock} element={<CreateAwbStock />} />
                            <Route path={createiatastock} element={<CreateIataStock />} />
                            <Route path={awbstock} element={<AwbStock />} />
                            <Route path={createcourierawb} element={<CreateCarrierStock />} />
                            <Route path={courierstock} element={<CourierStore />} />
                            <Route path={iatastock} element={<IataStock />} />
                            <Route path={createholdawb} element={<CreateHoldAwb />} />
                            <Route path={createiatahold} element={<CreateIataHold />} />
                            <Route path={flightlist} element={<FlightRegisterList />} />


                        </Route>
                    ) :
                        (
                            <Route path={login} element={<UserLogin />} />
                        )
                }

            </Routes>
        </>
    )
}

export default RouterConfig;

import { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown, { InputField, TextAreafield } from "../../component/controls";
import { GetCustomerBySearch, GetMasterCityByPage, GetMasterStateByPage, PostConsigneeSubAgency, PostMasterBizInstituteType, PostMasterCity, PostMasterCountry, PostMasterState, UpdateConsigneeSubAgency } from "../../../services/constant";
import GetCaller, { PostCaller } from "../../../services/apiServices";
import { consigneesubagencylist, subagencyshippeplist } from "../../../navigation/constant";
import { consgneeSubAgencyType } from "../../../services/modalData";
import formValueReseter from "../../../services/utilities/formValueReseter";
import { formconstant } from "../../constant/formconstant";
import CheckMail from "../../../services/utilities/checkEmailFormat";
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in vendorFormthe Authorization header
};


export const ConsigneeSubAgencyForm = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);
    const [cityItem, setCityItem] = useState([]);
    const [stateItem, setStateItem] = useState([]);
    const [countryItem, setCountryItem] = useState([]);
    const [customerItem, setCustomerItem] = useState([]);
    const [attachkyc, setAttachKyc] = useState('Attach Kyc')
    const [bizInstituteItem, setBizInstituteItem] = useState([]);
    const enquirybranch = useSelector(state => state.enquirybranch)
    const [subagencyform, setSubagencyForm] = useState({
        id: '',
        name: "",
        status: true,
        type: "",
        customerId: '',
        address1: "",
        address2: "",
        address3: "",
  
        cityId: "",
        pinCode: "",
        telephone: "",
        email: "",
        eoriNo: "",
        contactPerson: "",
        linkedConsignee: "",
        openAsNotify: false,
        opneAsOA: false,
        bankId: "",
        bankName: "",
        status: true,
        beneficiaryName: "",
        accountNumber: "",
        bankCode: "",
        swiftCode: "",
        bankAddess: ""

    })



    const handleInputChange = (value, field) => {

        let fieldData = {};

        // if (field.id === 'countryId') {
        //     set_StateItemData(value);
        //     fieldData = formValueReseter(["cityId", "stateId"])
        // }

        // if (field.id === 'stateId') {
        //     fieldData = formValueReseter(["cityId"])
        //     set_CityItemData(value);
        // }
        debugger;
        setSubagencyForm({
            ...subagencyform,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    };

    const handleFormSubmit = () => {
        debugger;
        debugger
        console.log('subagencyform', requestdata)
        const changes = handleSubmit(requestdata);
        const bankDetailsChanges = handleSubmit(requestdata.bankDetails[0], "bankName")
        debugger

        const isMail = CheckMail(requestdata?.email)
        if (Object.keys(changes).length > 0 || Object.keys(bankDetailsChanges).length > 0 || isMail) {
            setSubagencyForm({ ...subagencyform, ...changes, ...bankDetailsChanges,
            ['emailerror'] :isMail
            });


        }
        else if (isEdit) {

            EditConsigneeSubAgency(requestdata);
            setIsEdit(false);
        }
        else {
            AddConsigneeSubAgency(requestdata);
        }
    };

    const handleSubmit = (obj, bankName) => {
        debugger
        let change = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (
                key === "id" ||
                key === "customerId" ||
                key === "address2" ||
                key === "address3" ||
                key === "openAsNotify" ||
                key === "opneAsOA" ||
                key === "countryId" ||
                key === "stateId" ||
                key === "status"


            ) {
            }
            else if (!value) {
                change[(bankName && key === 'name' ? bankName : key) + "error"] = !value;
            }
        });
        return change;
    };

    const set_CityItemData = () => {
        GetCaller({
            API: PostMasterCity
            ,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({response }) => {
                debugger
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                    gstCode: item.gstCode                                     
                }))
                setCityItem(newCityItemData)
            }
    
        })
    
    }


    const set_BizInstituteItemData = (e) => {
        GetCaller({
            API: PostMasterBizInstituteType,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newbizinstitude = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }));
                setBizInstituteItem(newbizinstitude)
            }

        });
    };
    // const set_CountryItemData = (e) => {
    //     GetCaller({
    //         API: PostMasterCountry,
    //         dispatch: (e) => {
    //             dispatch({ type: e.type, payload: e.payload })
    //         },
    //         item: {},
    //         callback: ({ response }) => {
    //             const newcountrydata = response?.data?.data?.records.map((item) => ({
    //                 value: item.id,
    //                 name: item.name
    //             }));

    //             setCountryItem(newcountrydata)
    //         }

    //     });
    // };
    // const set_StateItemData = (countryId) => {
    //     const requestData = {
    //         searchKey: '',
    //         sortKey: '',
    //         sortDirection: '',
    //         itemsPerPage: 100,
    //         page: 1,
    //     };

    //     axios.get(GetMasterStateByPage(requestData), { headers })
    //         .then((response) => {

    //             let newStateItemData;
    //             if (countryId) {

    //                 newStateItemData = response?.data?.data?.records
    //                     .filter((item) => item.country.key === countryId)
    //                     .map((item) => ({
    //                         value: item.id,
    //                         name: item.name,
    //                     }));
    //             }
    //             setStateItem(newStateItemData);
    //         })
    //         .catch((error) => {
    //             // Handle error
    //             console.error("Error fetching state data:", error);
    //         });
    // }

    // const set_CityItemData = (stateId) => {
    //     const requestData = {
    //         searchKey: "",
    //         sortKey: "",
    //         sortDirection: "",
    //         itemsPerPage: 100,
    //         page: 1,
    //     };
    //     axios
    //         .get(GetMasterCityByPage(requestData), { headers })
    //         .then((response) => {
    //             // console.log(e)
    //             let newCityItemData;
    //             if (stateId) {
    //                 newCityItemData = response?.data?.data?.records
    //                     .filter((item) => item.state.key === stateId)
    //                     .map((item) => ({
    //                         value: item.id,
    //                         name: item.name,
    //                         key: item.country.key
    //                     }));
    //             }

    //             setCityItem(newCityItemData);

    //         });
    // };

    const set_CustomerItemData = (e) => {
        GetCaller({
            API: GetCustomerBySearch,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newcitydata = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.legalName
                }));
                setCustomerItem(newcitydata)
            }

        });
    };


    const requestdata = (() => {

        const data = {
            name: subagencyform.name,
            status: subagencyform.status,
            type: subagencyform.type,
            customerId: subagencyform.customerId,
            address1: subagencyform.address1,
            address2: subagencyform.address2,
            address3: subagencyform.address3,   
            cityId: subagencyform.cityId,
            pinCode: subagencyform.pinCode,
            telephone: subagencyform.telephone,
            email: subagencyform.email,
            eoriNo: subagencyform.eoriNo,
            contactPerson: subagencyform.contactPerson,
            linkedConsignee: subagencyform.linkedConsignee,
            openAsNotify: subagencyform.openAsNotify,
            opneAsOA: subagencyform.opneAsOA,
            bankDetails: [
                {
                    id: 0,
                    name: subagencyform.bankName,
                    status: subagencyform.status,
                    beneficiaryName: subagencyform.beneficiaryName,
                    accountNumber: subagencyform.accountNumber,
                    bankCode: subagencyform.bankCode,
                    swiftCode: subagencyform.swiftCode,
                    bankAddess: subagencyform.bankAddess
                }
            ]
        }
        if (subagencyform.customerId) data.customerId = subagencyform.customerId
        return data;
    })();

    useEffect(() => {
        set_BizInstituteItemData();     
        set_CustomerItemData();

        debugger;
        if (sessionStorage.sunAgencyShipperlist) {
            let sunAgencyShipperlist = JSON.parse(sessionStorage.getItem("sunAgencyShipperlist"));
            debugger;

            sunAgencyShipperlist.bankName = sunAgencyShipperlist.bankDetails[0].name
            sunAgencyShipperlist.accountNumber = sunAgencyShipperlist.bankDetails[0].accountNumber
            sunAgencyShipperlist.bankAddess = sunAgencyShipperlist.bankDetails[0].bankAddess
            sunAgencyShipperlist.bankCode = sunAgencyShipperlist.bankDetails[0].bankCode
            sunAgencyShipperlist.beneficiaryName = sunAgencyShipperlist.bankDetails[0].beneficiaryName
            sunAgencyShipperlist.swiftCode = sunAgencyShipperlist.bankDetails[0].swiftCode

            setSubagencyForm(sunAgencyShipperlist);
            setIsEdit(sunAgencyShipperlist.id);
 
            sessionStorage.removeItem('sunAgencyShipperlist');


        } else {
 
            set_CityItemData();
        }
    }, []);

    const AddConsigneeSubAgency = (requestdata) => {

        PostCaller({
            API: PostConsigneeSubAgency,
            callback: () => {
                navigate(`${consigneesubagencylist}`)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })

    }

    const EditConsigneeSubAgency = (requestdata) => {
        debugger;
        requestdata.id = isEdit;

        PostCaller({
            API: UpdateConsigneeSubAgency,
            callback: () => {
                navigate(`${consigneesubagencylist}`)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })
    }

    return (
        <>
            <div className="hr-form-box">
                {isEdit ? <h6>Edit Consignee Sub Agency</h6> : <h6>Add Consignee Sub Agency</h6>}


                <div className="row">

                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'type',
                                    label: 'Type',
                                    type: 'select',
                                    value: subagencyform.type,
                                    required: true,
                                    error: subagencyform.typeerror,
                                    item: consgneeSubAgencyType,
                                    disable: false,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'name',
                                    label: formconstant.name,
                                    type: 'text',
                                    value: subagencyform.name,
                                    required: true,
                                    error: subagencyform.nameerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'customerId',
                                    label: formconstant.subAgent,
                                    type: 'select',
                                    value: subagencyform.customerId,
                                    required: true,
                                    error: subagencyform.customerIderror,
                                    item: customerItem,
                                    disable: false,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'email',
                                    label: formconstant.email,
                                    type: "text",
                                    value: subagencyform.email,
                                    required: true,
                                    error: subagencyform.emailerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'telephone',
                                    label: 'Mobile No',
                                    type: "number",
                                    value: subagencyform.telephone,
                                    required: true,
                                    error: subagencyform.telephoneerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'address1',
                                    label: formconstant.address1,
                                    type: "text",
                                    value: subagencyform.address1,
                                    required: true,
                                    error: subagencyform.address1error,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'address2',
                                    label: formconstant.address2,
                                    type: "text",
                                    value: subagencyform.address2,
                                    required: false,
                                    error: subagencyform.address2error,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'address3',
                                    label: formconstant.address3,
                                    type: "text",
                                    value: subagencyform.address3,
                                    required: false,
                                    error: subagencyform.address3error,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    {/* <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "countryId",
                                    label: formconstant.country,
                                    type: "select",
                                    value: subagencyform.countryId,
                                    required: true,
                                    error: subagencyform.countryIderror,
                                    item: countryItem,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "stateId",
                                    label: formconstant.state,
                                    type: "select",
                                    value: subagencyform.stateId,
                                    required: true,
                                    error: subagencyform.stateIderror,
                                    item: stateItem,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div> */}
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "cityId",
                                    label: formconstant.city,
                                    type: "select",
                                    value: subagencyform.cityId,
                                    required: true,
                                    error: subagencyform.cityIderror,
                                    item: cityItem,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'pinCode',
                                    label: formconstant.zipCode,
                                    type: "text",
                                    value: subagencyform.pinCode,
                                    required: true,
                                    error: subagencyform.pinCodeerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'eoriNo',
                                    label: 'Eori Number',
                                    type: "text",
                                    value: subagencyform.eoriNo,
                                    required: true,
                                    error: subagencyform.eoriNoerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'contactPerson',
                                    label: formconstant.contactPerson,
                                    type: "text",
                                    value: subagencyform.contactPerson,
                                    required: true,
                                    error: subagencyform.contactPersonerror,
                                    onChange: handleInputChange

                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'linkedConsignee',
                                    label: formconstant.linkedconsignee,
                                    type: "number",
                                    value: subagencyform.linkedConsignee,
                                    required: true,
                                    error: subagencyform.linkedConsigneeerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    <div className="row">
                        <div className="col-md-3 mt-2">
                            <Checkbox
                                checked={subagencyform.openAsNotify}
                                onChange={(e) => {
                                    handleInputChange(e.target.checked, { id: 'openAsNotify' });
                                }}
                            />
                            {"Open As Notify"}
                        </div>


                        <div className="col-md-3 mt-2 ">
                            <Checkbox
                                checked={subagencyform.opneAsOA}
                                onChange={(e) => {
                                    handleInputChange(e.target.checked, { id: 'opneAsOA' });
                                }}
                            />
                            {"Open As OA"}
                        </div>

                    </div>

                </div>
            </div >
            <div className="hr-form-box">
                {"Bank Details"}
                <div className="row">

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'beneficiaryName',
                                    label: formconstant.beneficiaryName,
                                    type: 'text',
                                    value: subagencyform.beneficiaryName,
                                    required: true,
                                    error: subagencyform.beneficiaryNameerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'bankName',
                                    label: formconstant.bankName,
                                    type: 'text',
                                    value: subagencyform.bankName,
                                    required: true,
                                    error: subagencyform.bankNameerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'accountNumber',
                                    label: formconstant.accNumber,
                                    type: 'text',
                                    value: subagencyform.accountNumber,
                                    required: true,
                                    error: subagencyform.accountNumbererror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'bankCode',
                                    label: formconstant.bankCode,
                                    type: 'text',
                                    value: subagencyform.bankCode,
                                    required: true,
                                    error: subagencyform.bankCodeerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'swiftCode',
                                    label: formconstant.swiftCode,
                                    type: 'text',
                                    value: subagencyform.swiftCode,
                                    required: true,
                                    error: subagencyform.swiftCodeerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3 mt-3">
                        <TextAreafield
                            field={
                                {
                                    id: 'bankAddess',
                                    label: formconstant.bankAddress,
                                    value: subagencyform.bankAddess,
                                    required: true,
                                    rows: 3,
                                    error: subagencyform.bankAddesserror,
                                    onChange: handleInputChange
                                }
                            } />
                    </div>



                </div>
            </div>
            <div className="cmd">
                <div></div>
                <Button
                    sx={{ m: 2, borderRadius: "10px" }}
                    onClick={handleFormSubmit}
                    variant="contained"
                >
                    Submit
                </Button>
            </div>
        </>
    );

}



export const notfound = '*';
export const technicalerror = '/technicalerror';
export const failedtoconnect = '/failedtoconnect';
export const login = '/';
export const home = '/';


// Master Pages 
export const areamaster = '/areamaster';
export const sectormaster = '/sectormaster';
export const country = '/country';
export const city = '/city';
export const state = '/state';
export const branch = '/branch';
export const division = '/division';
export const department = '/department';
export const subdepartment = '/sub-department';
export const designation = '/designation';
export const subgroup = '/subgroup';
export const acgroup = '/acgroup';
export const primarygroup = '/primarygroup';
export const gststatus = '/gststatus';
export const createvendor = '/vendor-form';
export const createairline = '/createairline';
export const createcustomer = '/createcustomer'
export const createthirdcountrymisccustomer = '/create-thirdcountry-misccustomer'
export const hremployelist = '/hr_employelist';
export const hremployelistForm = '/hr_employelist-form';
export const newlead = '/newlead';
export const materializelead = '/materializelead';
export const deadlead = '/deadlead';
export const bizInstituteType = '/bizInstituteType';
export const vendortype = '/vendortype';
export const accountledger = '/accountledger';
export const sploperation = '/sploperation';
export const networktype = '/networktype';
export const tdsmaster = '/tdsmaster';
export const createtdsmaster = '/createtdsmaster';
export const sacmaster = '/sacmaster';
export const reasontype = '/reasontype';
export const assetmaster = '/assetmaster';
export const assetcategory = '/assetcategory';
export const createtaxmaster = '/createtaxmaster';
export const taxratemaster = '/taxratemaster';
export const gsamaster = '/gsa';
export const creategsamaster = '/gsamaster-form';
export const vendormaster = '/vendormaster';
export const airline = '/airline';
export const airport = '/airport';
export const unitmaster = '/unitmaster';
export const packagemaster = '/packagemaster';
export const customerlist = '/customerlist';
export const thirdcountrymisccustomerlist = '/thirdcountry-customerlist';
export const checkcustomer = '/checkcustomer';
export const checkthirdcountrymisccustomer = '/check-thirdcountry-misccustomer';
export const customerbranch = '/customerbranch';
export const vendormanagebalance = '/vendormanagebalance';
export const vendoropeningbalance = '/vendoropeningbalance';
export const chargeheadform = '/createchargehead';
export const createenquiry = '/createenquiry'
export const createairimport = '/create-air-import'
export const createchargehead = '/createchargehead';
export const chargehead = '/chargehead';
export const createnotify = '/notify-form';
export const notifymaster = '/notifymaster';
export const subagencyshipperform = '/subagencyshipperform';
export const subagencyshippeplist = '/subagencyshipperlist';
export const createconsigneesubagency = '/createconsigneesubagency';
export const consigneesubagencyaddress = '/consigneesubagencyaddress';

export const consigneesubagencylist = '/consigneesubagencylist';
export const airenquirylist = '/air-export-enquiry-list';
export const airimportenquirylist = '/air-import-enquiry-list';
export const employeedetails = '/employeedetails';

export const goodsinregisterlist = '/goods-in-register-list';
export const goodsinregisterform = '/goods-in-register-form';
export const expectedgoodofexports = '/expected-good-of-exports'
export const creategoodinregister = '/good-in-register-form'
export const goodsoutregisterlist = "/good-out-register-list";
export const goodsoutregisterform = '/goods-out-register-form';

export const stockRegister = "/stock-register";


export const vehicletype = '/vehicletype';
export const chalanform = '/chalanform';
export const airportlist = "/airportlist";
export const airportchecklist = "/airportchecklist";
export const airexportjoblist = "/air-export-joblist"
export const jobregister = "/jobregister"
export const createinitialquote = "/initialquote"
export const createfinalquote = "/finalquote"
export const pdfaircustomer = "/pdfaircustomer"
export const inlinepdf = "/inlinepdf"
export const enquiryvendorpdf = '/enquiryvendorpdf'
export const jobplanner = '/jobplanner'
export const preview = '/preview'
export const createjob = '/create-job'
export const hawb = '/hawb'
export const createhawbform = '/createhawbform'
export const cargotype = '/cargotype'
export const mawb = '/mawb'
export const createmawbform = '/createmawbform'
export const createiatastock = '/createiatastock'
export const createawbstock = '/createawbstock'
export const awbstock = '/awbstock'
export const createcourierawb = '/createcarrierawb'
export const courierstock = '/courierstock'
export const iatastock = '/iatastock'
export const createholdawb = '/createholdawb'
export const createiatahold = '/createiatahold'
export const flightlist = '/flightlist'
export const flightRegister = '/flightRegister';





















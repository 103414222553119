import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { GetUnitMasterByPage, PostUnitMaster, UpdateUnitMaster, UpdateUnitMasterStatus } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';
const UnitMaster = () => {
    const dispatch = useDispatch()
    const [SplOperationReqData, setSplOperationReqData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)


    const fatch_MasterDataBYPagination = (item) => {
        debugger
        MasterCallBypagination({
            API: GetUnitMasterByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({

            sn: index + 1,
            id: res.id,
            unit: res.name,
            status: res.status
        }));
        setSplOperationReqData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
    
        {
            id: 'unit',
            label: 'Unit Name',
            numeric: false,
        },

        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateUnitMasterStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <SplOperationReqDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           
             <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }

    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <SplOperationReqDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>
            <MultiTable
                data={SplOperationReqData}
                columns={columns}
                // filterItems={filterItems}
                title={'Unit Master'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default UnitMaster


const SplOperationReqDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()

    const [closeValidator, setcloseValidator] = useState(false)
    const [unitMaster, setUnitMaster] = useState(row?.unit || '')
    const [unitMasterError, setUnitMasterError] = useState(false)

    const drawerOpenCallbackHandler = () => {
        setUnitMaster(row?.unit || '')
        setUnitMasterError(false)

    }

    const handleSubmit = () => {
        if (!unitMaster) {
            setUnitMasterError(!unitMaster)
        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
            name: unitMaster,
            status: row.status
              } : {
                name: unitMaster,    
              }             
              editor ?
                PostCaller({
                  API: UpdateUnitMaster,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
        
                :
        
                PostCaller({
                  API: PostUnitMaster,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }

    useEffect(() => {
        setcloseValidator(false)

    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'unit':
                setUnitMaster(value)
                setUnitMasterError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Unit Master",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Unit Master',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Unit Master",
            tooltitle: 'Add Unit Master',
            defaultbtnfalse: true
        }





    const FieldData = [


        {
            id: 'unit',
            label: 'Unit Name',
            type: 'text',
            value: unitMaster,
            required: true,
            error: unitMasterError,
            helperText: 'Unit Name required',
            onChange: handleInputChange,

        },




    ];


   
    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}


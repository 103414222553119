import { Checkbox , IconButton} from "@mui/material"
import Dropdown, { DateField, InputField, TextAreafield } from "../../component/controls"
import { useEffect, useState } from "react"
import { GetConsigneeSubAgencyPage, GetCustomerByPage, PostAirportMaster, PostConsigneeSubAgency, PostMasterCity, PostMasterCountry, PostNotifyList, PostUnitMaster } from "../../../services/constant"
import GetCaller from "../../../services/apiServices"
import { useDispatch } from "react-redux"
import { Rateclass, applicableFormat } from "../../../services/modalData"
import { Add} from "@mui/icons-material";




export const Maintab = ({ handleBasicInputChange, HawbForm,consigneeItem,shipperItem,salesPerson }) => {

    const dispatch = useDispatch();
    const [notifyItem, setNofifyItem] = useState([])
    const [airportiteam, setAirportItem] = useState([])
   

    useEffect(() => {
        set_NotifyItem()
        set_AirportItem()
    }, [])

 


    const set_NotifyItem = () => {
        GetCaller({
            API: PostNotifyList,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newnotifyiteam = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setNofifyItem(newnotifyiteam)
            }

        })
    }


    const set_AirportItem = () => {
        GetCaller({
            API: PostAirportMaster,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newairportiteam = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setAirportItem(newairportiteam)
            }

        })
    }
    return (
        <>
            <div className="">
                <div className="row">
                    <div className="col-md-6">
                        <Dropdown
                            field={
                                {
                                    id: 'shipperId',
                                    label: 'Shipper',
                                    type: 'select',
                                    value: HawbForm.shipperId,
                                    required: true,
                                    error: HawbForm.shipperIderror,
                                    item: shipperItem,
                                    disable: true,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

               

                    <div className="col-md-6">
                        <Dropdown
                            field={

                                {
                                    id: 'consigneeId',
                                    label: 'Consignee',
                                    type: 'select',
                                    value: HawbForm.consigneeId,
                                    required: true,
                                    error: HawbForm.consigneeIderror,
                                    item: consigneeItem,
                                    disable: true,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-6 mt-3">
                        <TextAreafield
                            field={
                                {
                                    id: 'shipperAddress',
                                    label: " Shipper Address",
                                    value: HawbForm.shipperAddress,
                                    rows: 3,
                                    error: HawbForm.shipperAddresserror,
                                    onChange: handleBasicInputChange
                                }
                            } />
                    </div>
                    <div className="col-md-6 mt-3">
                        <TextAreafield
                            field={
                                {
                                    id: 'consigneeAddress',
                                    label: "Consignee Address",
                                    value: HawbForm.consigneeAddress,
                                    rows: 3,
                                    error: HawbForm.consigneeAddresserror,
                                    onChange: handleBasicInputChange
                                }
                            } />
                    </div>
                    <div className="col-md-4">
                        <Dropdown
                            field={
                                {
                                    id: 'notifyId',
                                    label: 'Notify',
                                    type: "select",
                                    value: HawbForm.notifyId,
                                    required: false,
                                    error: HawbForm.notifyIderror,
                                    item: notifyItem,
                                    disable: false,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />

                    </div>
                    <div className="col-md-4">
                        <Dropdown
                            field={
                                {
                                    id: 'destinationId',
                                    label: 'Destination',
                                    type: "select",
                                    value: HawbForm.destinationId,
                                    required: false,                              
                                    error: HawbForm.destinationIderror,
                                    onChange: handleBasicInputChange,
                                    disable: false,
                                    item: airportiteam,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    {/* <div className="col-md-4">
                        <InputField
                            field={
                                {
                                    id: 'packages',
                                    label: 'Packages',
                                    type: "number",
                                    value: HawbForm.packages,
                                    disable: true,
                                    error: HawbForm.packageserror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div> */}
                    <div className="col-md-4">
                        <InputField
                            field={
                                {
                                    id: 'grossWeight',
                                    label: 'Gross WT',
                                    type: "number",
                                    required: true,
                                    value: HawbForm.grossWeight,
                                    error: HawbForm.grossWeighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-4">
                        <InputField
                            field={
                                {
                                    id: 'chargeWeight',
                                    label: 'Charge WT',
                                    type: "number",
                                    required: true,
                                    value: HawbForm.chargeWeight,
                                    error: HawbForm.chargeWeighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-4">
                        <Dropdown
                            field={
                                {
                                    id: 'airportOfDeparture',
                                    label: 'Airport Of Departure. ',
                                    type: "select",
                                    required: true,
                                    value: HawbForm.airportOfDeparture,
                                    disable: false,
                                    error: HawbForm.airportOfDepartureerror,
                                    item: airportiteam,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-4">
                        <Dropdown
                            field={
                                {
                                    id: 'airportOfDestination',
                                    label: 'Airport Of Destination ',
                                    type: "select",
                                    required: true,
                                    value: HawbForm.airportOfDestination,
                                    disable: false,
                                    error: HawbForm.airportOfDestinationerror,
                                    item: airportiteam,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-4">
                        <InputField
                            field={
                                {
                                    id: 'freight',
                                    label: 'Air Freight Rate',
                                    type: "number",
                                    required: true,
                                    value: HawbForm.freight,
                                    error: HawbForm.freighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-4">
                        <Dropdown
                            field={
                                {
                                    id: 'salesPerson',
                                    label: 'Sales Person ',
                                    type: "select",
                                    required: true,
                                    value: HawbForm.salesPerson,
                                    disable: false,
                                    error: HawbForm.salesPersonerror,
                                    item: salesPerson,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-4">
                        <InputField
                            field={
                                {
                                    id: 'hawbRate',
                                    label: 'HAWB Rate',
                                    type: "number",
                                    value: HawbForm.hawbRate,
                                    error: HawbForm.hawbRateerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="row">
                        {/* <div className="col-md-3 mt-2">
                            <Checkbox
                                checked={HawbForm.printIATA}
                                onChange={() => { handleBasicInputChange(!HawbForm.printIATA, { id: 'printIATA' }) }}
                            />
                            {"Print IATA"}
                        </div>
                        <div className="col-md-3 mt-2">
                            <Checkbox
                                checked={HawbForm.asAgreed}
                                onChange={() => { handleBasicInputChange(!HawbForm.asAgreed, { id: "asAgreed" }) }}
                            />
                            {"As Agreed"}
                        </div> */}
                        <div className="col-md-3 mt-2">
                            <Checkbox
                                checked={HawbForm.printHAWBNumber}
                                onChange={() => { handleBasicInputChange(!HawbForm.printHAWBNumber, { id: "printHAWBNumber" }) }}
                            />
                            {"Print Hawb No."}
                        </div>
                    </div>
                    <h6 className="mt-4">Dimensions</h6>
                    <div className="dimensions_table mt-4">
                        <table>
                            <tr>
                                <th>Length</th>
                                <th>Width</th>
                                <th>Height</th>
                                <th>Box</th>
                                <th>Volumn</th>
                            </tr>
                            <tr>
                                <td>
                                    <InputField
                                        className='mt-0'
                                        field={
                                            {
                                                id: 'length',
                                                label: '',
                                                type: "number",
                                                value: HawbForm.length,
                                                disable: 'true',
                                                error: HawbForm.lengtherror,
                                                item: airportiteam,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </td>
                                <td>
                                    <InputField
                                        className='mt-0'
                                        field={

                                            {
                                                id: 'width',
                                                label: '',
                                                type: "number",
                                                value: HawbForm.width,
                                                disable: 'true',
                                                error: HawbForm.widtherror,
                                                item: airportiteam,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    /></td>
                                <td> <InputField
                                    className='mt-0'
                                    field={
                                        {
                                            id: 'height',
                                            label: '',
                                            type: "number",
                                            value: HawbForm.height,
                                            disable: 'true',
                                            error: HawbForm.heighterror,
                                            item: airportiteam,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                /></td>
                                <td>  <InputField
                                    className='mt-0'
                                    field={
                                        {
                                            id: 'box',
                                            label: '',
                                            type: "number",
                                            value: HawbForm.box,
                                            disable: 'true',
                                            error: HawbForm.boxerror,
                                            item: airportiteam,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                /></td>
                                <td>  <InputField
                                    className='mt-0'
                                    field={
                                        {
                                            id: 'volume',
                                            label: '',
                                            type: "number",
                                            value: HawbForm.volume,
                                            disable: 'true',
                                            error: HawbForm.volumeerror,
                                            item: airportiteam,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                /></td>
                            </tr>
                            <tr>
                                <td colspan="4"></td>
                                <td>
                                    <InputField
                                        className='mt-0'
                                        field={
                                            {
                                                id: 'totalVolumnWeight',
                                                label: 'Total Volumn Wt.',
                                                type: "number",
                                                value: HawbForm.totalVolumnWeight,
                                                disable: true,
                                                error: HawbForm.totalVolumnWeighterror,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </td>
                            </tr>

                        </table>
                    </div>

                </div>
            </div>
        </>
    )

}



export const OtherDetails = ({ handleBasicInputChange,currencyItem, HawbForm,setDeliveryDetailsError, deliveryDetailsError }) => {
    const dispatch = useDispatch();

    const [currenctItem, setCurrenctItem] = useState([]);
    const [unitItem, setUnitItem] = useState([]);

    useEffect(() => {
        set_CountryItemData();
        set_UnitmasterItem()
    }, [])
    const set_CountryItemData = () => {
        GetCaller({
            API: PostMasterCountry,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.currencyCode
                }))
                setCurrenctItem(newCityItemData)
            }

        })
    }

    const set_UnitmasterItem = () => {
        GetCaller({
            API: PostUnitMaster,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newUnitItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setUnitItem(newUnitItemData)
            }

        })
    }

    return (
        <>
            <div className="">
                <div className="row">
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'currency',
                                    label: 'Currency ',
                                    type: "select",
                                    value: HawbForm.currency,
                                    required: true,
                                    error: HawbForm.currencyerror,
                                    item: currencyItem,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'kgOrLb',
                                    label: 'KG/LB ',
                                    value: HawbForm.kgOrLb,
                                    error: HawbForm.kgOrLberror,
                                    required: true,
                                    item: unitItem,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'rateClass',
                                    label: 'Rate Class',
                                    type: "select",
                                    required: false,
                                    value: HawbForm.rateClass,
                                    error: HawbForm.rateClasserror,
                                    item: Rateclass,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3" >
                        <InputField
                            field={{
                                id: 'commodityItemNumber',
                                label: 'Item No.',
                                type: "number",
                                required: false,
                                value: HawbForm.commodityItemNumber,
                                error: HawbForm.commodityItemNumbererror,
                                onChange: handleBasicInputChange
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'declaredValueForInputCarriage',
                                    label: 'Declared Value Of Carriage',
                                    type: "text",
                                    value: HawbForm.declaredValueForInputCarriage,
                                    required: false,
                                    error: HawbForm.declaredValueForInputCarriageerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'declaredValueForCustom',
                                    label: 'Declared Value For Custom',
                                    type: "text",
                                    required: false,
                                    value: HawbForm.declaredValueForCustom,
                                    error: HawbForm.declaredValueForCustomerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'ammountOfInsurance',
                                    label: 'Amount Of Insurance',
                                    type: "text",
                                    required: false,
                                    value: HawbForm.ammountOfInsurance,
                                    error: HawbForm.ammountOfInsuranceerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'byFirstDueCarrier',
                                    label: 'By First Carrier',
                                    type: "text",
                                    required: true,
                                    value: HawbForm.byFirstDueCarrier,
                                    error: HawbForm.byFirstDueCarriererror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'routeOneTo',
                                    label: 'To',
                                    type: "text",
                                    required: false,
                                    value: HawbForm.routeOneTo,
                                    error: HawbForm.routeOneToerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'routeOneBy',
                                    label: 'By',
                                    type: "text",
                                    required: false,
                                    value: HawbForm.routeOneBy,
                                    error: HawbForm.routeOneByerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'routeTwoTo',
                                    label: 'To',
                                    type: "text",
                                    value: HawbForm.routeTwoTo,
                                    required: false,
                                    error: HawbForm.routeTwoToerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'routeTwoBy',
                                    label: 'By',
                                    type: "text",
                                    required: false,
                                    value: HawbForm.routeTwoBy,
                                    error: HawbForm.routeTwoByerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'requestedFlight',
                                    label: 'Requested Flight',
                                    type: "text",
                                    required: true,
                                    value: HawbForm.requestedFlight,
                                    error: HawbForm.requestedFlighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <DateField
                            format='YYYY-MM-DD'
                            field={{

                                id: "flightDate",
                                label: "Flight Date ",
                                required: true,
                                value: HawbForm?.flightDate,
                                error: HawbForm?.flightDateerror,
                                onChange: handleBasicInputChange
                            }}
                        />
                    </div>

                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'tariffRateType',
                                    label: 'Tariff Rate Type',
                                    required: false,
                                    value: HawbForm.tariffRateType,
                                    error: HawbForm.tariffRateTypeerror,
                                    item: applicableFormat,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'tariffRate',
                                    label: 'Tariff Rate',
                                    type: "number",
                                    required: false,
                                    value: HawbForm.tariffRate,
                                    error: HawbForm.tariffRateerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'issuingDueCarrierAgentName',
                                    label: 'Issuing DueCarrier Agent Name',
                                    type: "text",
                                    required: true,
                                    value: HawbForm.issuingDueCarrierAgentName,
                                    error: HawbForm.issuingDueCarrierAgentNameerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'agentIataCode',
                                    label: 'Agent Iata Code',
                                    type: "text",
                                    required: true,
                                    value: HawbForm.agentIataCode,
                                    error: HawbForm.agentIataCodeerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'accountNumber',
                                    label: 'Account Number',
                                    type: "number",
                                    required: false,
                                    value: HawbForm.accountNumber,
                                    error: HawbForm.accountNumbererror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'accountingInformation',
                                    label: 'Accounting Information',
                                    type: "number",
                                    required: true,
                                    value: HawbForm.accountingInformation,
                                    error: HawbForm.accountingInformationerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'styleNumber',
                                    label: 'Style Number',
                                    type: "number",
                                    required: false,
                                    value: HawbForm.styleNumber,
                                    error: HawbForm.styleNumbererror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'publishRate',
                                    label: 'Publish Rate',
                                    type: "number",
                                    required: false,
                                    value: HawbForm.publishRate,
                                    error: HawbForm.publishRateerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                       
                    </div>


                    <div className="col-md-4">
                        <TextAreafield
                            className={'mt-5'}
                            field={
                                {
                                    id: 'handlingInformation',
                                    label: "Handling Information",
                                    required: false,
                                    value: HawbForm.handlingInformation,
                                    rows: 4,
                                    error: HawbForm.handlingInformationerror,
                                    onChange: handleBasicInputChange
                                }
                            } />
                    </div>

                    <div className="col-md-4">
                        <TextAreafield
                            className={'mt-5'}
                            field={
                                {
                                    id: 'natureAndQuantityOfGoods',
                                    label: "Nature And Quantity Of Goods",
                                    required: false,
                                    value: HawbForm.natureAndQuantityOfGoods,
                                    rows: 4,
                                    error: HawbForm.natureAndQuantityOfGoodserror,
                                    onChange: handleBasicInputChange
                                }
                            } />
                    </div>

                    <div className="col-md-4">
                        <TextAreafield
                            className={'mt-5'}
                            field={
                                {
                                    id: 'descriptionOfGoods',
                                    label: "Description Of Goods",
                                    required: false,
                                    value: HawbForm.descriptionOfGoods,
                                    rows: 4,
                                    error: HawbForm.descriptionOfGoodserror,
                                    onChange: handleBasicInputChange
                                }
                            } />
                    </div>

                    {MultiDeliveryAddress(handleBasicInputChange,setDeliveryDetailsError, deliveryDetailsError)}

             </div>
            </div>
        </>
    )


}



const MultiDeliveryAddress = (handleBasicInputChange,setDeliveryDetailsError,deliveryDetailsError) => {

    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [deliveryTotalPackages, setDeliveryTotalPackages] = useState(0);
    const [deliveryTotalGrossWeight, setDeliveryTotalGrossWeight] = useState(0);
    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            address: '',
            packages: '',
            grossWeight: '',
            airVolumeWeight: '',
            dimensions: ''
        },
    ]);

    const calculateTotalVolumeWeight = () => {
        const obj = {
            totalWeight: 0,
            totalPackages: 0
        }
        inputFields.forEach((res, key) => {
            const { packages, grossWeight } = res;
            obj.totalWeight += Number(grossWeight)
            obj.totalPackages += Number(packages)
        });

        return obj;
    };


    useEffect(() => {
        const obj = calculateTotalVolumeWeight();
        setDeliveryTotalGrossWeight(obj.totalWeight)
        setDeliveryTotalPackages(obj.totalPackages)
    }, [inputFields]);



    const addInputField = () => {

        setInputFields([...inputFields, { key: generateUniqueKey(), address: '', packages: '', grossWeight: '', airVolumeWeight: '', dimensions: '' }]);

        setDeliveryDetailsError({})
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
        if (fieldToUpdate) {
            setDeliveryDetailsError({
                ...deliveryDetailsError,
                [field + index]: !value
            })
            fieldToUpdate[field] = value;
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        address: res.address,
                        packages: res.packages,
                        grossWeight: res.grossWeight,
                        airVolumeWeight: res.airVolumeWeight,
                        dimensions: res.dimensions
                    }
                )

            })
            handleBasicInputChange(data, { id: 'DeliveryAddressList' });
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    address: res.address,
                    packages: res.packages,
                    grossWeight: res.grossWeight,
                    airVolumeWeight: res.airVolumeWeight,
                    dimensions: res.dimensions
                }
            )

        })
        handleBasicInputChange(data, { id: 'DeliveryAddressList' });
    };


    return <>{
        <div className='mt-4 mb-4 '>
            <hr />
            {
                inputFields.map((field, key) => (
                    <div className='row m-0 ' style={{ border: '1px solid #dcdcdc', }} key={field.key}>
                        <div className="col-md-1 cmd" style={{ justifyContent: "left" }}>
                            {key === 0 ?
                                <IconButton onClick={addInputField} style={{ borderRadius: '4px', border: '1px solid #dcdcdc', padding: '4px' }}>
                                    <Add />
                                </IconButton>
                                : (
                                    <IconButton onClick={() => removeInputField(field.key)}>
                                        <i className="las la-trash " style={{ fontSize: '20px' }}></i>
                                    </IconButton>
                                )}
                        </div>
                        <div className="col-md-11">
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "address",
                                                label: "Delivery Address",
                                                type: 'text',
                                                value: field.address,
                                                required: true,
                                                error: deliveryDetailsError['address' + key],
                                                helperText: 'Delivery Address Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'address', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "packages",
                                                label: "Packages",
                                                type: 'number',
                                                value: field.packages,
                                                required: true,
                                                error: deliveryDetailsError['packages' + key],
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'packages', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>

                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "grossWeight",
                                                label: "Gross Weight",
                                                type: 'number',
                                                value: field.grossWeight,
                                                required: true,
                                                error: deliveryDetailsError['grossWeight' + key],
                                                helperText: 'Mobile No Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'grossWeight', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "airVolumeWeight",
                                                label: "Air Volme Weight",
                                                type: 'number',
                                                value: field.airVolumeWeight,
                                                required: false,
                                                error: deliveryDetailsError['airVolumeWeight' + key],
                                                helperText: 'Land Line Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'airVolumeWeight', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div> <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "dimensions",
                                                label: "Dimensions",
                                                type: 'text',
                                                value: field.dimensions,
                                                required: false,
                                                error: deliveryDetailsError['dimensions' + key],
                                                helperText: 'Land Line Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'dimensions', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
        </div >
    }


    </>
}
import { useDispatch } from "react-redux"
import { GetHawbByPage, GetMawbByPage } from "../../../services/constant"
import { useEffect, useState } from "react"
import MasterCallBypagination from "../../../services/masterpaginationConfig"
import GetCaller from "../../../services/apiServices"
import { MultiTable } from "../../component/multiTable"
import { createhawbform, createmawbform } from "../../../navigation/constant"
import { IconButton, Tooltip } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom"

const Mawb = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [hawbMasterData, setHawbMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMawbByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item

        })
    }



    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);
    const setTableDataHandler = ({ response }) => {
        debugger
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            jobId: res.jobOrConsoleNumber,
            mawbNumber: res.mawbNumber,
            mawbDate: res.mawbDate,
            hawbNumber: "786590",
            airline: res.airline,
            Financialdate: '23-24'


        }));
        setHawbMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false
        },

        {
            id: 'Financialdate',
            label: 'Financial Year',
            numeric: false
        },

        {
            id: 'airline',
            label: 'Airline',
            numeric: false
        },

        {
            id: 'jobId',
            label: 'Job id',
            numeric: false
        },

        {
            id: 'mawbNumber',
            label: 'Mawb Number',
            numeric: false
        },
        {
            id: 'hawbNumber',
            label: 'Hawb Number',
            numeric: false
        },

        {
            id: 'mawbDate',
            label: 'Mawb Date',
            numeric: false
        },

    ];
    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            // GetCaller({
            //     Api: UpadateMasterNetworkTypeStatus,
            //     dispatch: (e) => {
            //         dispatch({type:e.type , payload: e.payload})
            //     },
            //     item:data,
            //     callback:({response}) => {
            //         fatch_MasterDataBYPagination();
            //     }
            // });
        }
    }
    const TableTool = ({ }) => {
        return <>
            <Tooltip title={"MAWB List"}>
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${createmawbform}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }

    return (
        <>
            <MultiTable
                data={hawbMasterData}
                columns={columns}
                title={"MAWB"}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )

}
export default Mawb

const MawbDrawer = ({ callAPI, editor, row }) => {


}
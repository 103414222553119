import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PostAirExportBuyingingCharges, PostAirExportSellingCharges } from "../../../../services/APIConstant/enquiryConstant";
import { PostCaller } from "../../../../services/apiServices";
import Dropdown, { InputField, InputFileField, TextAreafield } from "../../../component/controls";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { AirExportCustomerTypeFrightOptions, ApplicableOn, IntialStockProvider } from "../../../../services/modalData";
import { Add, Close } from "@mui/icons-material";
import Transition from "../../../../services/utilities/transition";
import { AddAirExportPlannerJob, AddSplitJobByEnquiryID } from "../../../../services/APIConstant/jobConstant";
import { columnConstant } from "../../../constant/formconstant";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const AddNewJobForm = ({ togglenewJobForm, callBack, shipperItem, airportItem }) => {
    const dispatch = useDispatch();
    const [freightItem, setFreightItems] = useState([]);
    const [addjobFormData, setAddJobFormData] = useState({
        customerType: '',
        customer: '',
        shipperId: '',
        packages: '',
        grossWeight: '',
        airVolumeWeight: '',
        airportOfLoading: '',
        airportOfDestination: '',
        remarks: "",
        bizGenerator: "",
        freightServiceType: ""
    })

    useEffect(() => {
        console.log('togglenewJobForm', togglenewJobForm)
    }, [togglenewJobForm])

    const handleClose = () => {
        callBack()
        setAddJobFormData({})

    }

    const handleSubmit = () => {

        const requestData = {
            customerType: addjobFormData?.customerType,
            customer: addjobFormData?.customer,
            // shipperId: addjobFormData?.shipperId,
            packages: addjobFormData?.packages,
            grossWeight: addjobFormData?.grossWeight,
            airVolumeWeight: addjobFormData?.airVolumeWeight,
            airportOfLoading: addjobFormData?.airportOfLoading,
            airportOfDestination: addjobFormData?.airportOfDestination,
            bizGenerator: addjobFormData?.bizGenerator,
            remarks: addjobFormData?.remarks,
            freightServiceType: addjobFormData?.freightServiceType
        }

        const jobDatahasUndefindValues = (obj) => {


            let changes = {};

            Object.entries(obj).forEach(([key, value]) => {
                if (
                    !value && (
                        // key === 'shipperId' ||
                        key === 'packages' ||
                        key === 'grossWeight' ||
                        key === 'airVolumeWeight' ||
                        key === 'airportOfDestination' ||
                        key === 'airportOfLoading' ||
                        key === 'customerType' ||
                        key === 'customer' ||
                        key === 'freightServiceType' ||
                        key === 'remarks' ||
                        key === 'bizGenerator'

                    )) {
                    changes[key + 'error'] = !value;
                }
            });

            console.log('changes', changes)

            return changes;
        };
        const addjobformchanges = jobDatahasUndefindValues(requestData)
        debugger
        if (Object.keys(addjobformchanges).length > 0) {
            setAddJobFormData({
                ...addjobFormData,
                ...addjobformchanges
            })
        } else {
            PostCaller({
                API: AddAirExportPlannerJob,
                callback: () => {
                    callBack()
                    setAddJobFormData({})
                },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: requestData
            })
        }
    }

    const handleBasicInputChange = (value, field) => {
        setAddJobFormData({
            ...addjobFormData,
            [field.id]: value,
            [field.id + 'error']: !value
        })
    }

    const airportItemFilterData = (id) => {
        let newFilterItem = airportItem.filter(item => item.value !== id)
        return newFilterItem
    }

    return <>



        <Dialog
            open={togglenewJobForm}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                sx: {
                    width: "90%",
                    maxWidth: "90% !important",
                },
            }}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: "start"
                }
            }
            }


        >
            <div className=''>
                <DialogTitle className="mb-0">
                    <div className="cmd">

                        <h6 className="m-0 p-0">Add Job</h6>
                        <Tooltip title='Close'>
                            <IconButton onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <hr />
                </DialogTitle>
                <DialogContent>

                    <div className=''>
                        <div className="row mb-3">
                            <div className="col-md-3">
                                <Dropdown
                                    field={
                                        {
                                            id: "customerType",
                                            label: "Customer Type",
                                            value: addjobFormData?.customerType,
                                            required: true,
                                            error: addjobFormData?.customerTypeerror,
                                            item: AirExportCustomerTypeFrightOptions,
                                            onChange: (value, field) => {
                                                let filterFreightItem = AirExportCustomerTypeFrightOptions?.find(item => item?.value === value)
                                                setFreightItems(filterFreightItem?.FrightServiceType)

                                                setAddJobFormData({
                                                    ...addjobFormData,
                                                    [field.id]: value,
                                                    [field.id + 'error']: !value,
                                                    ['freightServiceType']: '',
                                                    ['freightServiceTypeerror']: false
                                                })
                                            }
                                        }
                                    }
                                />
                            </div>
                            {
                                freightItem.length !== 0 &&
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "freightServiceType",
                                                label: "Freight/Service Type ",
                                                value: addjobFormData?.freightServiceType,
                                                required: true,
                                                error: addjobFormData?.freightServiceTypeerror,
                                                item: freightItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                            }

                            <div className="col-md-3">
                                <Dropdown
                                    field={
                                        {
                                            id: "customer",
                                            label: "Customer Name ",
                                            value: addjobFormData?.customer,
                                            required: true,
                                            error: addjobFormData?.customererror,
                                            item: shipperItem,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: "bizGenerator",
                                            label: "Biz. Genrator/Reference",
                                            type: 'text',
                                            value: addjobFormData?.bizGenerator,
                                            required: true,
                                            error: addjobFormData?.bizGeneratorerror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-3">
                                <Dropdown
                                    field={
                                        {
                                            id: "airportOfLoading",
                                            label: "APOL ",
                                            value: addjobFormData?.airportOfLoading,
                                            required: true,
                                            error: addjobFormData?.airportOfLoadingerror,
                                            item: addjobFormData.airportOfDestination ? airportItemFilterData(addjobFormData.airportOfDestination) : airportItem,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <Dropdown
                                    field={
                                        {
                                            id: "airportOfDestination",
                                            label: "APOD ",
                                            value: addjobFormData?.airportOfDestination,
                                            required: true,
                                            error: addjobFormData?.airportOfDestinationerror,
                                            item: addjobFormData.airportOfLoading ? airportItemFilterData(addjobFormData.airportOfLoading) : airportItem,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: "packages",
                                            label: "Packages",
                                            type: 'number',
                                            value: addjobFormData?.packages,
                                            required: true,
                                            error: addjobFormData?.packageserror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: "grossWeight",
                                            label: "Gross Weight",
                                            type: 'number',
                                            value: addjobFormData?.grossWeight,
                                            required: true,
                                            error: addjobFormData?.grossWeighterror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: "airVolumeWeight",
                                            label: "Volume Weight",
                                            type: 'number',
                                            value: addjobFormData?.airVolumeWeight,
                                            required: true,
                                            error: addjobFormData?.airVolumeWeighterror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3 mt-3">
                                <TextAreafield
                                    field={{
                                        id: "remarks",
                                        label: "Special Requirements/ Remarks",
                                        value: addjobFormData.remarks,
                                        required: true,
                                        error: addjobFormData.remarkserror,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>


                        </div>
                    </div >
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                    <Button onClick={handleSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
                </DialogActions>
            </div>
        </Dialog>



    </>
}
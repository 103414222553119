import { TextField } from "@mui/material";
import { useState } from "react";
import axios from 'axios';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button } from '@mui/material';
import { HrDynamicForm } from "./hrEmployeeLIstForm";
import { bool } from "prop-types";
import { PostMasterTaxRate, PostVendorMaster } from "../../services/constant";
import { useDispatch, useSelector } from "react-redux";

export const TaxMasterForm = () => {
    const branchId = useSelector(state => state.enquirybranch)
    const dispatch = useDispatch()

    const [vendorForm, setVendorForm] = useState({
        name: "",
        branchId: branchId,
        cgst: "",
        sgst: "",
        igst: "",
        cgstReceivableLedgerId: "8414DD96-CCD4-406A-8179-7F86BF1701C7",
        sgstReceivableLedgerId: "FF7C72AC-D351-4E05-BB34-0D9BD05D590B",
        igstReceivableLedgerId: "4F3553D6-CA66-43DA-A59D-A68363494FCA",
        cgstPayableLedgerId: "5D95E6D7-F186-42BD-AF72-31FFC29AE89D",
        sgstPayableLedgerId: "A20F09FA-EEFF-4886-9DF8-EFE6B2C22982",
        igstPayableLedgerId: "432788DC-E957-4956-9C24-A06E041CAD7F",

    });

    const handleFormSubmit = () => {
        const changes = handleSubmit(requestdata);
        if (Object.keys(changes).length > 0) {
            setVendorForm({ ...vendorForm, ...changes });
        }
        else {
            AddAreaMasterSubmit(requestdata)

        }
    };


    const handleSubmit = (obj) => {
        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if ((key !== 'id' || key !== 'status') && !value) {
                changes[key] = value;
                changes[key + 'error'] = !value;
            }
        });

        return changes;
    };




    const handleInputChange = (field, value) => {

        setVendorForm({
            ...vendorForm,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    };


    const requestdata = {
        name: vendorForm.name,
        cgst: vendorForm.cgst,
        sgst: vendorForm.sgst,
        igst: vendorForm.igst,
        cgstReceivableLedgerId: vendorForm.cgstReceivableLedgerId,
        sgstReceivableLedgerId: vendorForm.sgstReceivableLedgerId,
        igstReceivableLedgerId: vendorForm.igstReceivableLedgerId,
        cgstPayableLedgerId: vendorForm.cgstPayableLedgerId,
        sgstPayableLedgerId: vendorForm.sgstPayableLedgerId,
        igstPayableLedgerId: vendorForm.igstPayableLedgerId,
        branchId: vendorForm.branchId
    }

    const fieldData = [
        {

            title: "Add Tax Slab",
            data:
                [
                    {
                        id: 'name',
                        label: 'Name',
                        type: 'text',
                        value: requestdata.name,
                        required: true,
                        error: requestdata.nameerror,
                        helperText: 'Name',
                    },
                    {
                        id: 'cgst',
                        label: 'CGST',
                        type: 'number',
                        value: requestdata.cgst,
                        required: true,
                        error: requestdata.cgsterror,
                        helperText: 'CGST required',

                    },
                    {


                        id: 'sgst',
                        label: 'SGST',
                        type: 'number',
                        value: vendorForm.sgst,
                        required: true,
                        error: vendorForm.sgsterror,
                        helperText: 'Sub-Department required',
                    },
                    {
                        id: 'igst',
                        label: 'IGST',
                        type: 'number',
                        value: vendorForm.igst,
                        required: true,
                        error: vendorForm.igsterror,
                        helperText: 'IGST required',

                    }




                ]
        }

    ];
    const AddAreaMasterSubmit = (requestdata) => {
        debugger
        axios.post(PostMasterTaxRate(), requestdata)
            .then((response) => {
                const friendlymess = response?.data?.metaData?.friendlyMessage;
                const resultmess = response?.data?.metaData?.resultMessage

                if (response?.data?.metaData?.resultCode === "R00") {

                    dispatch({
                        type: "SNACKBARALERT", payload: {
                            active: true,
                            message: friendlymess || resultmess,
                            type: 'success'
                        }
                    });
                }
                else {
                    dispatch({
                        type: "SNACKBARALERT", payload: {
                            active: true,
                            message: friendlymess || resultmess,
                            type: 'error'
                        }
                    });
                }
            })
    }

    return <>
        <HrDynamicForm fields={fieldData} onChange={handleInputChange} formData={vendorForm} />
        <div className="">
            <CustomerDetail onChange={handleInputChange} />
        </div>
        <div className='cmd'>
            <div></div>
            <Button sx={{ m: 2, borderRadius: "10px" }} onClick={handleFormSubmit} variant="contained">Submit</Button>
        </div>
    </>
}

const CustomerDetail = ({ onChange }) => {
    const handleFieldChange = (fieldName, value) => {
        onChange(fieldName, value);
    };


    const [isSameAsPermanent, setIsSameAsPermanent] = useState(false);

    const handleCheckboxChange = () => {
        setIsSameAsPermanent(!isSameAsPermanent);
    };

    const EmploymentDetailsData = [

        {
            id: 'contactperson',
            label: "Contact Person Name",
            type: "text",
            value: '',
            required: true,
            error: true,
            helperText: 'Contact Person Name required',
        },
        {
            id: 'mobileno',
            label: "Mobile No",
            type: "text",
            value: '',
            required: true,
            error: true,
            helperText: 'Mobile No required',
        },

        {
            id: 'landline',
            label: "Landline",
            type: "text",
            value: '',
            required: true,
            error: true,
            helperText: 'Landline required',
        },

        {
            id: 'email',
            label: "Email",
            type: "input",
            value: '',
            required: true,
            error: true,
            helperText: 'Email required',
            inputtype: "text"
        },
    ]



    return (
        <>
            <div className="hr-form-box">
                <span className='ms-3'>
                    <input className='mb-3'
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        checked={isSameAsPermanent}
                    /> Do you want to create as Customer</span>

                {isSameAsPermanent ? (

                    <div className="row ">
                        <h6>Create as a Customer</h6>

                        {
                            EmploymentDetailsData.map((field, index) => {
                                return <div className={`col-md-3 my-2}`} key={index}>
                                    <TextField
                                        fullWidth
                                        label={field.label}
                                        id="fullWidth"
                                        required={field?.required}
                                        sx={{ m: 0, width: '100%', fontSize: '13px' }}
                                        size="small"
                                        type={field.inputtype}
                                        onChange={(e) => handleFieldChange(field.label, e.target.value)}
                                    />

                                </div>
                            })
                        }

                    </div>
                ) : null}
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import { GetMasterDepartmentByPage, GetMasterDivisionByPage, PostMasterDepartment, PostMasterDivision, PostMasterSubDepartment, UpdateMasterDepartment, UpdateMasterDepartmentStatus } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import DivisonDrawer from '../../component/divisionDrawer';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';
const Department = () => {

    const dispatch = useDispatch()
 
    const [departmentMasterData, setDepartmentMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterDepartmentByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);

    const setTableDataHandler = ({ response }) => {
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            department: res.name,
            division: res.division.value + " ( " + res.branch.value + " )",
            divisionid: res.division.key,
            status: res.status
        }));
     
        setDepartmentMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
        {
            id: 'division',
            label: 'Division (Branch)',
            numeric: false,
        },
        {
            id: 'department',
            label: 'Department',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];




    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateMasterDepartmentStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <DepartmentDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <DepartmentDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }




    return (
        <>
            <MultiTable
                data={departmentMasterData}
                title={'Department'}
                columns={columns}
                // filterItems={filterItems}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default Department



const DepartmentDrawer = ({ callAPI, editor, row }) => {
  
    const [divisionBranchItem, setDivisionbranchItem] = useState([])
    const dispatch = useDispatch()
    const [closeValidator, setcloseValidator] = useState(false)
    const [divisionType, setDivisionType] = useState(row?.divisionid || '')
    const [divisionTypeError, setDivisionTypeError] = useState(false)
    const [departmentName, setDepartmentName] = useState(row?.department || '')
    const [departmentNameError, setDepartmentNameError] = useState(false)



    const drawerOpenCallbackHandler = () => {
        debugger
        setDivisionType(row?.divisionid || '')
        setDepartmentName(row?.department || '')
        setDivisionTypeError(false)
        setDepartmentNameError(false)
    }

    const handleSubmit = () => {
        if (!departmentName || !divisionType) {
            setDivisionTypeError(!divisionType)
            setDepartmentNameError(!departmentName)
        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: departmentName,
                branchId: divisionType,
                status: row.status 
            } : {
                name: departmentName,
                divisionId: divisionType,
            }     
            editor ?
              PostCaller({
                API: UpdateMasterDepartment,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })     
              :    
              PostCaller({
                API: PostMasterDepartment,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })
            setcloseValidator(true)
      
          }


    }
   
    const set_DivisionbranchData = () => {
        GetCaller({
            API: PostMasterDivision,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newDivisionbranchItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name + " ( " + item.branch.value + " ) "
                }))
                setDivisionbranchItem(newDivisionbranchItemData)
            }
        })

    }
    useEffect(() => {
        set_DivisionbranchData()
    }, []);

    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'division':
                setDivisionType(value)
                setDivisionTypeError(!value);
                break;
            case 'department':
                setDepartmentName(value)
                setDepartmentNameError(!value);
                break;

            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Department",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Department',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Department",
            tooltitle: 'Add Department',
            defaultbtnfalse: true
        }





    const FieldData = [
        {
            id: 'division',
            label: 'Division (Branch)',
            type: 'select',
            value: divisionType,
            required: true,
            error: divisionTypeError,
            helperText: 'Division (Branch) required',
            onChange: handleInputChange,
            item: divisionBranchItem



        },
        {
            id: 'department',
            label: 'Department Name',
            type: 'text',
            value: departmentName,
            required: true,
            error: departmentNameError,
            helperText: 'Department Name required',
            onChange: handleInputChange,
        },


    ];




    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}





import { Close } from '@mui/icons-material';
import { IconButton, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react'
import userThumbnail from '../assets/img/user-thumbnail.png';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AssetCategory from '../pages/master/assetCategory';
import { useDispatch, useSelector } from 'react-redux';
import { MultiTable } from './multiTable';
import { GetAssetCategoryByPage, UpdateAssetCategoryStatus } from '../../services/constant';
import MasterCallBypagination from '../../services/masterpaginationConfig';
import { AssetCateType } from '../../services/modalData';
import GetCaller from '../../services/apiServices';
import { SmallSwitch } from './controls';


const EmployeeDetails = ({ row, closeCallback }) => { 
    const [profiledetails, setProfileSDetails] = useState([]);
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        debugger;
        setProfileSDetails(row);
    }, []);

    return (
        <>
            <div className='emp_Detail_mainbody'>
                <div className="empDetailheader">
                    <div className="cmd mb-4">
                    <div className="profileSection ">
                        <div className="profileImage"><img src={profiledetails.image || userThumbnail} alt="Profile" />
                      
                      </div>
                        <div className=''>
                            <h5>{row.name}</h5>
                            <p>{row.email}</p>
                            <p>{row.mobile}</p>
                        </div>
                    </div>
                        <IconButton
                            sx={{ background: "#fcfcfc" }}
                            onClick={() => { closeCallback && closeCallback() }}
                        >
                            <Close />
                        </IconButton>

                    </div>
               
                </div>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Profile" value="1" />
                                <Tab label="Assets" value="2" />
                                <Tab label="Change Password" value="3" />
                                <Tab label="Documents" value="4" />
                                <Tab label="Performance" value="5" />
                                <Tab label="Attendance" value="6" />
                                <Tab label="Security" value="7" />
                                <Tab label="Account Activity" value="8" />
                                <Tab label="Task" value="9" />
                            </TabList>
                        </Box>
                        <TabPanel value="1"><Employee_details profiledetails={row} /></TabPanel>
                        <TabPanel value="2"> <AssetTable />
                        </TabPanel>
                        <TabPanel value="3">Item Three</TabPanel>
                    </TabContext>
                </Box>

            </div>
        </>
    )
}



const AssetTable = () => {
    const enquirybranch = useSelector(state => state.enquirybranch)

    const dispatch = useDispatch();
    const [assetcategorydata, setAssetcategorydata] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetAssetCategoryByPage,
            callback: setTableDataHandler,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item,
            enquirybranch: enquirybranch
        })
    }
    useEffect(() => {
        fatch_MasterDataBYPagination()
    }, []);


    const setTableDataHandler = ({ response }) => {
        debugger
        const newMasterAreaData = response?.data?.data?.records?.map((res, index) => ({

            sn: 'd',
            id: res.id,
            assetcate: res.name,
            assetMasterType: AssetCateType[res.typeId - 1].name,
            typeIdkey: res.typeId,
            status: res.status
        }));
        setAssetcategorydata(newMasterAreaData);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'assetcate',
            label: 'Asset Category Name',
            numeric: false,
        },
        {
            id: 'assetMasterType',
            label: 'Asset Type',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
    ];




    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateAssetCategoryStatus,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({ response }) => {
                    fatch_MasterDataBYPagination();
                }
            });
        }

        return <>
            <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }

    //table head section tool
    const TableTool = ({ }) => {
        // return <>
        //     <AssetCatDrawer callAPI={fatch_MasterDataBYPagination} />
        // </>
    }

    return (
        <>

            <MultiTable
                data={assetcategorydata}
                columns={columns}
                // drawerFormFields={drawerFormFields}
                title={'Asset Category'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />

        </>
    )
}


export default EmployeeDetails;



const Employee_details = ({ profiledetails }) => {
    debugger;
    return <>
        <div className="employee_details">
            <h4>Contact</h4>
            <ul className="contact-info">
                <li><span className="label">Name:</span><p>{profiledetails.name}</p> </li>
                <li><span className="label">Email:</span> <p>{profiledetails.email}</p></li>
                <li><span className="label">Mobile:</span> <p>{profiledetails.mobile}</p></li>
                <li><span className="label">EMP.Code:</span> <p>{profiledetails.empid}</p></li>
                <li><span className="label">Designation:</span> <p>{profiledetails.designation}</p></li>
                <li><span className="label">Department:</span> <p>{profiledetails.department}</p></li>
                <li><span className="label">Status:</span> <p>{profiledetails.empstatus
}</p></li>

            </ul>
            {/* <h5>Work</h5>
            <ul className="contact-info">
                <li><span className="label">Designation:</span> <p>-</p></li>
                <li><span className="label">Department:</span> <p>WXYZ</p></li>
                <li><span className="label">Reporting To:</span> <p>-</p></li>
                <li><span className="label">Seating Location:</span> <p>-</p></li>
                <li><span className="label">Employee ID:</span> <p>-</p></li>
                <li><span className="label">Status:</span> <p>Available for Collaboration</p></li>

            </ul> */}
        </div></>

}











import React, { useState, useRef } from 'react';
import MicOutlinedIcon from '@mui/icons-material/MicOutlined';
import MicOffIcon from '@mui/icons-material/MicOff';
import { IconButton, Tooltip } from '@mui/material';
function RecordingComponent() {
    const [recording, setRecording] = useState(false);
    const [audioChunks, setAudioChunks] = useState([]);
    const mediaRecorderRef = useRef(null);
    const audioRef = useRef(null);

    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        const newAudioChunks = [];

        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                newAudioChunks.push(event.data);
            }
        };

        mediaRecorder.onstop = () => {
            const audioBlob = new Blob(newAudioChunks, { type: "audio/wav" });
            audioRef.current.src = URL.createObjectURL(audioBlob);
        };

        mediaRecorderRef.current = mediaRecorder;
        setAudioChunks(newAudioChunks);

        mediaRecorder.start();
        setRecording(true);
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.stop();
            setRecording(false);
        }
    };

    return (
        <div>
            <div className='audio-record-box'>
                <div className='d-flex'>

                    <Tooltip title='Start'>  <IconButton disabled={recording} onClick={startRecording}><MicOutlinedIcon /></IconButton> </Tooltip>
                    <Tooltip title='Stop'>  <IconButton disabled={!recording} onClick={stopRecording}><MicOffIcon /></IconButton> </Tooltip>

                </div>

                <audio ref={audioRef} controls></audio>

            </div>

        </div>
    );
}

export default RecordingComponent;

import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import { GetAssetCategoryByPage, GetMasterArea, GetMasterAreaByPage, GetMasterSectorByPage, PostAssetCategory, PostMasterArea, UpdateAssetCategory, UpdateAssetCategoryStatus, UpdateMasterArea, UpdateMasterAreaStatus } from '../../../services/constant';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch, useSelector } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import { AssetCateType } from '../../../services/modalData';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';


const AssetCategory = () => {
    const enquirybranch = useSelector(state => state.enquirybranch)

    const dispatch = useDispatch();
    const [assetcategorydata, setAssetcategorydata] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetAssetCategoryByPage,
            callback: setTableDataHandler, 
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item,
            enquirybranch: enquirybranch
        })
    }
    useEffect(() => {
        fatch_MasterDataBYPagination()
    }, []);
    
  
    const setTableDataHandler = ({ response }) => {
debugger
        const newMasterAreaData = response?.data?.data?.records?.map((res, index) => ({
          
            sn: 'd',
            id: res.id,
            assetcate: res.name,
            assetMasterType: AssetCateType[res.typeId - 1].name,         
            typeIdkey: res.typeId,
            status: res.status
        }));
        setAssetcategorydata(newMasterAreaData);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'assetcate',
            label: 'Asset Category Name',
            numeric: false,
        },
        {
            id: 'assetMasterType',
            label: 'Asset Type',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
    ];




    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateAssetCategoryStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <AssetCatDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }

    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <AssetCatDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }

    return (
        <>

            <MultiTable
                data={assetcategorydata}
                columns={columns}
                // drawerFormFields={drawerFormFields}
                title={'Asset Category'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />

        </>
    )
}



export default AssetCategory;


const AssetCatDrawer = ({ callAPI, editor, row }) => {
    const enquirybranch = useSelector(state => state.enquirybranch)
  const [closeValidator, setcloseValidator] = useState(false)
  const [assetname, setAssetName] = useState(row?.assetcate || '')
    const [assetnameError, setAssetNameError] = useState(false)
    const [assetCate, setAssetCate] = useState(row?.typeIdkey || '')
    const [assetCateError, setAssetCateError] = useState(false)
    const dispatch = useDispatch()

    const drawerOpenCallbackHandler = () => {
        debugger
        setAssetName(row?.assetcate || '')
        setAssetCate(row?.typeIdkey || '')
        setAssetNameError(false)
        setAssetCateError(false)
    }

    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);



    const handleSubmit = () => {
        if (!assetname || !assetCate) {
            setAssetNameError(!assetname)
            setAssetCateError(!assetCate)
        }
        else {
            const postmasterareaData = editor ? {
            id: row?.id,
            branchId: enquirybranch,
            name: assetname,
            typeId: assetCate,
            status: row?.status
              } : {
                branchId: enquirybranch,
            name: assetname,
            typeId: assetCate,
              }
        
        
              editor ?
                PostCaller({
                  API: UpdateAssetCategory,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  
                  },
                  item: postmasterareaData
                })
        
                :
        
                PostCaller({
                  API: PostAssetCategory,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }



    const handleInputChange = (value, field) => {
    
        switch (field.id) {
            case 'assetcate':
                setAssetName(value)
                setAssetNameError(!value);
                break;
            case 'assetMasterType':
                setAssetCate(value)
                setAssetCateError(!value);
                break;

            default:
                break;
        }
    };




    const drawerFormFields = editor ?
        {
            title: "Edit Asset Category",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Asset Category',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Asset Category",
            tooltitle: 'Add Asset Category',
            defaultbtnfalse: true
        }





    const FieldData = [
        {
            id: 'assetMasterType',
            label: 'Asset Category',
            type: 'select',
            value: assetCate,
            required: true,
            error: assetCateError,
            helperText: 'Asset Category required',
            onChange: handleInputChange,
            item: AssetCateType
        },

        {
            id: 'assetcate',
            label: 'Asset Category Type',
            type: 'text',
            value: assetname, // Corrected from leadLogState.sectortype
            required: true,
            error: assetnameError,
            helperText: 'Asset Category Type required',
            onChange: handleInputChange,
        },
    ];


    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }

    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            divisiondrawerbody={divisionDrawerody}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}

        />

    </>

}


import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { GetPackageMasterByPage, GetSplOperationReqTypeByPage, GetUnitMasterByPage, PostMasterDepartment, PostMasterDesignation, PostPackageMaster, PostSplOperationReqType, PostUnitMaster, UpdateMasterDesignation, UpdateMasterDesignationStatus, UpdateMasterNetworkType, UpdateMasterNetworkTypeStatus, UpdatePackageMaster, UpdatePackageMasterStatus, UpdateSplOperationReqType, UpdateSplOperationReqTypeStatus, UpdateUnitMaster, UpdateUnitMasterStatus } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';
import { trimInputvalue } from '../../../services/utilities/unittrim';

const authToken = localStorage.getItem('authToken')
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
  };
const PackageMaster = () => {
    const dispatch = useDispatch()
    const [SplOperationReqData, setSplOperationReqData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)


    const fatch_MasterDataBYPagination = (item) => {
        debugger
        MasterCallBypagination({
            API: GetPackageMasterByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({

            sn: index + 1,
            id: res.id,
            package: res.name,
            status: res.status
        }));
        setSplOperationReqData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
   
        {
            id: 'package',
            label: 'Package Name',
            numeric: false,
        },

        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API:   UpdatePackageMasterStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }
        
        return <>
            <PackageDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }

    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <PackageDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>
            <MultiTable
                data={SplOperationReqData}
                columns={columns}
                // filterItems={filterItems}
                title={'Package Master'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default PackageMaster


const PackageDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()

    const [closeValidator, setcloseValidator] = useState(false)
    const [packageMaster, setPackageMaster] = useState(row?.unit || '')
    const [packageMasterError, setPackageMasterError] = useState(false)

    const drawerOpenCallbackHandler = () => {
        setPackageMaster(row?.package || '')
        setPackageMasterError(false)
    }

    const handleSubmit = () => {
const trimpack = trimInputvalue(packageMaster)

        if (!trimpack) {
            setPackageMasterError(!trimpack)
        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: trimpack,
                status: row.status
              } : {
                name: trimpack,
              }             
              editor ?
                PostCaller({
                  API: UpdatePackageMaster,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
        
                :
        
                PostCaller({
                  API: PostPackageMaster,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }

    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'package':
                setPackageMaster(value)
                setPackageMasterError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Package Master",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Package Master',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Package Master",
            tooltitle: 'Add Package Master',
            defaultbtnfalse: true
        }





    const FieldData = [


        {
            id: 'package',
            label: 'Package Master',
            type: 'text',
            value: packageMaster,
            required: true,
            error: packageMasterError,
            helperText: 'Package Master required',
            onChange: handleInputChange,

        },




    ];



  


    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody} />

    </>

}


import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { GetMasterBizInstituteTypeByPage, GetMasterDepartmentByPage, GetMasterDesignationByPage, GetMasterDivisionByPage, GetMasterNetworkTypeByPage, GetMasterSubGroupByPage, GetMasterVendorTypeByPage, PostMasterBizInstituteType, PostMasterDepartment, PostMasterDesignation, PostMasterNetworkType, PostMasterVendorType, UpdateMasterBizInstituteType, UpdateMasterBizInstituteTypeStatus, UpdateMasterDesignation, UpdateMasterDesignationStatus, UpdateMasterNetworkType, UpdateMasterNetworkTypeStatus, UpdateMasterVendorType, UpdateMasterVendorTypeStatus } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';
import { trimInputvalue } from '../../../services/utilities/unittrim';


const BizInstituteType = () => {
    const dispatch = useDispatch()
    const [networkNameMasterData, setNetworkNameMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)


    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterBizInstituteTypeByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({

            sn: index + 1,
            id: res.id,
            bizinstitute: res.name,
            status: res.status
        }));
        setNetworkNameMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
       
        {
            id: 'bizinstitute',
            label: 'Biz. Institute Type',
            numeric: false,
        },


        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];


    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateMasterBizInstituteTypeStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }
                 
                  });       
                }
        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>
            <MultiTable
                data={networkNameMasterData}
                columns={columns}
                // filterItems={filterItems}
                title={'Biz. Institute Type'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default BizInstituteType




const DesignationDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()
    const [closeValidator, setcloseValidator] = useState(false)
    const [bizInstitute, setBizInstitute] = useState(row?.bizinstitute || '')
    const [bizInstituteError, setBizInstituteError] = useState(false)


    const drawerOpenCallbackHandler = () => {
        setBizInstitute(row?.bizinstitute || '')
        setBizInstituteError(false)
    }

    const handleSubmit = () => {

        const trimbiz = trimInputvalue(bizInstitute)
        if (!trimbiz) {
            setBizInstituteError(!trimbiz)
        }

        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: trimbiz,
                status: row.status 
            } : {
                name: trimbiz,
            }     
            editor ?
              PostCaller({
                API:UpdateMasterBizInstituteType,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })     
              :    
              PostCaller({
                API:  PostMasterBizInstituteType,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })
            setcloseValidator(true)
      
          }


      
    }

    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'bizinstitute':
                setBizInstitute(value)
                setBizInstituteError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Biz. Institute Type",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Biz. Institute Type',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Biz. Institute Type",
            tooltitle: 'Add Biz. Institute Type',
            defaultbtnfalse: true
        }





    const FieldData = [
        {
            id: 'bizinstitute',
            label: 'Biz. Institute Type',
            type: 'text',
            value: bizInstitute,
            required: true,
            error: bizInstituteError,
            helperText: 'Biz. Institute Type required',
            onChange: handleInputChange,

        },
    ];

  
    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}


import { useDispatch } from "react-redux"
import { GetHawbByPage } from "../../../../services/constant"
import { useEffect, useState } from "react"
import MasterCallBypagination from "../../../../services/masterpaginationConfig"
import GetCaller from "../../../../services/apiServices"
import { MultiTable } from "../../../component/multiTable"
import { awbstore, createawbstock, createhawbform, createholdawb } from "../../../../navigation/constant"
import { IconButton, Tooltip } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom"
import { GetAwbStorebypage } from "../../../../services/APIConstant/jobConstant"
const AwbStock = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [AwsStoreData, setAwsStoreData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    sessionStorage.removeItem('moveawb')
    sessionStorage.removeItem('holdawb')
    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetAwbStorebypage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item

        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);
    const setTableDataHandler = ({ response }) => {
        debugger
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            airlineId: res?.airlineId,
            airlineName: res?.airlineName,
            airlineGSAId: res?.airlineGSAId,
            airlineGSAName: res?.airlineGSAName,
            awbNumber: res?.awbNumber,
            stockDate: res?.stockDate,
            numberOfAwb: res?.numberOfAwb,
            issueTo: res?.issueTo,
            issueTOName: res?.issueTOName,
            issueDate: res?.issueDate,
            receivedPerson: res?.receivedPerson,
            receivedPersonName: res?.receivedPersonName,
            receivedDate: res?.receivedDate,
            assignedBy: res?.assignedBy,
            assignedByName: res?.assignedByName,
            salesPerson: res?.salesPerson,
            salesPersonName: res?.salesPersonName,
            isLocked: res?.isLocked ? 'Yes' : 'No',
            isExecuted: res?.isExecuted ? 'Yes' : 'No',
            isIssue: res?.isIssue,
            isRevoke: res?.isRevoke,
            holdawb: HoldCell(res),
            moveawb: MoveCell(res)

        }));
        setAwsStoreData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false
        },
        {
            id: 'awbNumber',
            label: 'AWB No',
            numeric: false
        },

        {
            id: 'airlineName',
            label: 'Carrier',
            numeric: false
        },
        {
            id: 'airlineGSAName',
            label: 'GSA',
            numeric: false
        },
        {
            id: 'stockDate',
            label: 'Stock Date',
            numeric: false
        },
        {
            id: 'issueTo',
            label: 'Issue To',
            numeric: false
        },

        {
            id: 'issueDate',
            label: 'Issue Date',
            numeric: false
        },
        {
            id: 'receivedPerson',
            label: 'Recieved Person',
            numeric: false
        },
        {
            id: 'receivedDate',
            label: 'Recieved Date',
            numeric: false
        },
        {
            id: 'assignedBy',
            label: 'Assigned By',
            numeric: false
        },
        {
            id: 'salesPerson',
            label: 'Sales Person',
            numeric: false
        },
        {
            id: 'isLocked',
            label: 'Locked',
            numeric: false
        },

        {
            id: 'isExecuted',
            label: 'Executed',
            numeric: false
        },
        {
            id: 'isIssue',
            label: 'Issue',
            numeric: false
        },
        {
            id: 'isRevoke',
            label: 'Revoke',
            numeric: false
        },
        // {
        //     id:'issueto',
        //     label:'Revoke Details ',
        //     numeric:false
        // },
        // {
        //     id:'issueto',
        //     label:'Carrier Update',
        //     numeric:false
        // },
        {
            id: 'holdawb',
            label: 'Hold Awb',
            numeric: false
        },
        {
            id: 'moveawb',
            label: 'Move Stock',
            numeric: false
        },

        {
            id: 'action',
            label: 'Action',
            numeric: false
        },

    ];

    const HoldCell = (res) => {
        const onclick = () => {
            debugger
            sessionStorage.setItem('holdawb', JSON.stringify(res));
            navigate(`${createholdawb}`)
        }
        return <>
            <Tooltip title="Create Hold Awb">
                <IconButton
                    className='tablebtn1'
                    onClick={onclick}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >
        </>
    }

    const MoveCell = (res) => {
        const onclick = () => {
            debugger
            sessionStorage.setItem('moveawb', JSON.stringify(res));
            navigate(`${createholdawb}`)
        }
        return <>
            <Tooltip title="Create Hold Awb">
                <IconButton
                    className='tablebtn1'
                    onClick={onclick}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >
        </>
    }

    const ActionCell = ({ row }) => {
        const onclick = () => {
            debugger
            sessionStorage.setItem('awbstock', JSON.stringify(row));
            navigate(`${createawbstock}`)
        }
        return <>
            <Tooltip title="Edit AWB Stock">
                <IconButton
                    className='tablebtn1'
                    onClick={onclick}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >
        </>
    }


    const TableTool = ({ }) => {

        return <>
            <Tooltip title={"Add AWB Stock"}>
                <IconButton
                    className='table-toggle'
                    onClick={() => {
                        navigate(`${createawbstock}`)
                    }
                    }
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }

    return (
        <>
            <MultiTable
                data={AwsStoreData}
                columns={columns}
                title={"AWB Stock"}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )

}
export default AwbStock;

import React from 'react'
import loginimage from '../../assets/img/loginimg.jpg'
import loginlogo from '../../assets/img/avatars/logonew.jpg'
import { Button, Checkbox, ListItem, TextField } from '@mui/material'
import { CheckBox, Home } from '@mui/icons-material'
import { home } from '../../../navigation/constant'
import { Link } from 'react-router-dom'
const PageNotFound = () => {
    return (
        <>
            <div className="login-body cmc">
                <div className="row">
                    <div className=" d-none d-lg-block col-lg-6">
                        <div className="cmd">
                            <div className="login-image">
                                <img src={loginimage} alt="" />
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 ">
                        <div className='tech_error_field'>
                            <div className='technical_error'>
                                <h1>404 </h1>
                                <h3> Page Not Found</h3>
                                <p>Oops! Looks like you've encountered a missing page. <br /> This page does not exist. </p>
                                <div className="cmc">
                                    <Link to={home}>
                                        <Button className='mt-3' sx={{ borderRadius: "18px", backgroundColor: "var( --color-main)", padding: "0", paddingRight: '1rem', height: "40px" }} type='submit' variant="contained">
                                            <Button className='' sx={{ borderRadius: "50%", backgroundColor: "var( --color-main)", padding: '1rem', marginLeft: "-10px",zIndex:"1" }} type='submit' variant="contained"><Home style={{ fontSize: "2rem" }} /> </Button>
                                            Go To Home Page
                                        </Button>
                                    </Link>
                                </div>


                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PageNotFound


import { useEffect, useState } from "react";

const ChalanForm = () => {

  return (
    <>
      <div className="chalan_form">
        <div className="chalan_header  d-flex cmd">
          <p></p>
          <b>CHALLAN</b>
          <p>phone: +914567878</p>
        </div>
        <div className="cmd">
          <div >
            <h3>Agrastha</h3>
            <p><b>Regd Off.</b>: Place in Highway Near Tata Telicome Pincode 110037, india</p>
            <b>Phone : +91-11-456788</b>
          </div>
          <div className="cmd">
            <div >
              <h3>Agrastha</h3>
              <p>hagd Off : Place in Highway Near Tata Telicome Pincode 110037, india</p>
              <p>Phone : +91-11-456788</p>
            </div>
            <div className="" >
              <div><p >No. 11801</p>
                <div><span>Dated:</span><p className="mb-0 pb-0" style={{ borderBottom: "dotted", width: '100px', display: "inline-block" }}></p></div>
              </div>
            </div>
          </div>
          <div className="chalan_emails" >
            <div className="" style={{ width: "45%" }}>
              <div className="mb-2" ><span>From:</span><p className="mb-0 pb-0" style={{ borderBottom: "dotted", width: 'calc(100% - 100px)', display: "inline-block" }}></p></div>
              <div><span>To:</span><p className="mb-0 pb-0" style={{ borderBottom: "dotted", width: 'calc(100% - 100px)', display: "inline-block" }}></p></div>
            </div>

          </div>
          <p>Dear Sir,</p>
          <p>Please receive the undernoted consignment along with necessary documents and arrange airlifting immediately</p>
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Shipper</th>
                <th>AWB No.</th>
                <th>No. of Pkgs.</th>
                <th>Weight</th>
                <th>Destination</th>
                <th>Seal No.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>

              </tr>
              <tr>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
              </tr>
              <tr>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
              </tr>
              <tr>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
              </tr>
              <tr>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
                <td>{ }</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="7">Vehicle No.</td>
              </tr>
            </tfoot>
          </table>
          <div className="cmd mt-2">
            <span> Signature of Agent</span>
            <span>Signature</span>
          </div>

        </div>
      </div>
    </>
  )
}

export default ChalanForm




export const GetKeyValuePairDataByIds = (dataArray, idString) => {
    if (dataArray && dataArray?.length !== 0) {

        if (!idString) {
            return [];
        }

        const ids = idString?.split(',')?.map(Number);
        return dataArray?.filter(item => ids.includes(item.value));

    } else {
        return [];
    }

}

export const GetIdsByKeyValuePair = (dataArray) => {
    if (dataArray && dataArray?.length !== 0) {
        return dataArray?.map(item => String(item.value)).join(',');
    } else {
        return ''
    }
}


export const GetObjectDataById = (dataArray, value) => {
    if (dataArray && dataArray?.length !== 0) {
        return dataArray?.find(item => item?.value === value)
    } else {
        return {}
    }

}
import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { MultiTable } from '../../component/multiTable';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { columnConstant, formconstant } from '../../constant/formconstant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { GetWarehouseStockRegisterByPage, PostWarehouseStockRegister, UpdateWarehouseStockRegister, UpdateWarehouseStockRegisterStatus } from '../../../services/APIConstant/warehouseConstant';
import axios from 'axios';
import { StockStatus } from '../../../services/modalData';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { DateField, InputField, SmallSwitch } from '../../component/controls';
import { Close } from '@mui/icons-material';

const StockRegister = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sectorMasterData, setSectorMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetWarehouseStockRegisterByPage,
            callback: setTableDataHandler,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);

    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            stockNumber: res.stockNumber,
            stockStatusName: StockStatus[res.stockStatus - 1].name,
            stockStatus: res.stockStatus,
            from: res.from,
            to: res.to,
            number: res.number,
            date: res.date,
            status: res.status
        }));

        setSectorMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
        {
            id: 'stockNumber',
            label: columnConstant.stockno,
            numeric: false,
        },
        {
            id: 'stockStatusName',
            label: columnConstant.status,
            numeric: false,
        },
        {
            id: 'date',
            label: columnConstant.date,
            numeric: false,
        },

        {
            id: 'action',
            label: columnConstant.action,
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const updatedStatus = !row?.status;
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateWarehouseStockRegisterStatus,
                callback: () => {
                    fatch_MasterDataBYPagination()
                },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: data
            })
        }
        const editBtnClicked = () => {
            sessionStorage.setItem('gsa', JSON.stringify(row));
            sessionStorage.setItem('isPan', "Yes");
            sessionStorage.setItem('isTds', "Yes");
            // navigate(creategsamaster);
        }
        return <>
            <Tooltip title={columnConstant.updatestockregister}>
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >


            <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>

    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            {/* <Tooltip title={columnConstant.addstockregister}>
                <IconButton
                    className='table-toggle'
                // onClick={() => navigate(`${creategsamaster}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip > */}
            {CreateStockRegister({})}

        </>
    }


















    const CreateStockRegister = ({ row, edit, APIDATA }) => {

        const dispatch = useDispatch()
        const navigate = useNavigate();
        const [open, setOpen] = React.useState(false);


        const [stockregisterForm, setStockRegisterForm] = useState({
            from: "",
            to: "",
            number: "",
            date: "",
            stockStatus: 1,
        })


        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setStockRegisterForm({
                from: "",
                to: "",
                number: "",
                date: "",
                stockStatus: 1,

            })
            setOpen(false);
        };


        const requestdata = (() => {
            const data = {
                from: Number(stockregisterForm.from),
                stockStatus: stockregisterForm.stockStatus,
                date: stockregisterForm.date
            }
            if (!stockregisterForm.to) data.number = Number(stockregisterForm.number)
            if (!stockregisterForm.number) data.to = Number(stockregisterForm.to)

            return data;
        })()

        const handleInputChange = (value, field) => {
            setStockRegisterForm({
                ...stockregisterForm,
                [field.id]: value,
                [field.id + 'error']: !value,
            });
        };

        const handleFormSubmit = () => {
            const changes = handleSubmit(requestdata);
            if (Object.keys(changes).length > 0) {
                setStockRegisterForm({ ...stockregisterForm, ...changes });
            }
            else if (edit) {
                EditConsigneeSubAgencyAddress(requestdata);
            }
            else {
                AddConsigneeSubAgencyAddress(requestdata);
            }
        };

        const handleSubmit = (obj) => {
            debugger
            let changes = {};
            Object.entries(obj).forEach(([key, value]) => {
                if (!value) {
                    changes[key] = value;
                    changes[key + "error"] = !value;
                }
            });
            return changes;
        };

        const AddConsigneeSubAgencyAddress = (requestdata) => {
            debugger
            if (stockregisterForm.to) requestdata.number = Number(0)
            if (stockregisterForm.number) requestdata.to = Number(0)
            PostCaller({
                API: PostWarehouseStockRegister,
                callback: () => {
                    handleClose();
                    fatch_MasterDataBYPagination()
                },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: requestdata
            })

        }

        const EditConsigneeSubAgencyAddress = (requestdata) => {
            debugger
            PostCaller({
                API: UpdateWarehouseStockRegister,
                callback: () => {
                    handleClose();
                },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: requestdata
            })
        }


        return (
            <>
                {edit ? <i className="las la-edit"></i> : <IconButton
                    className='table-toggle'
                    onClick={handleClickOpen}
                >
                    <AddIcon />
                </IconButton>}

                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{
                        sx: {
                            maxWidth: "500px",
                        },
                    }}
                    sx={{
                        '& .MuiDialog-container': {
                            alignItems: "start"
                        }
                    }
                    }


                >
                    <div className=''>
                        <DialogTitle>
                            <div className="cmd">
                                <h5 className='mb-0'>   {edit ? "Edit Stock Register Form" : "Stock Register Form"}</h5>

                                <Tooltip title='Close'>
                                    <IconButton onClick={handleClose}>
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <hr />
                        </DialogTitle>
                        <DialogContent>

                            <div className="">
                                <div className="row">
                                    <div className="col-md-6">
                                        <InputField
                                            field={
                                                {
                                                    id: 'from',
                                                    label: formconstant.from,
                                                    type: 'number',
                                                    value: stockregisterForm.from,
                                                    required: true,
                                                    error: stockregisterForm.fromerror,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            field={
                                                {
                                                    id: 'to',
                                                    label: formconstant.to,
                                                    type: 'number',
                                                    value: stockregisterForm.to,
                                                    required: true,
                                                    error: stockregisterForm.toerror,
                                                    disable: stockregisterForm.number ? true : false,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            field={
                                                {
                                                    id: 'number',
                                                    label: formconstant.number,
                                                    type: 'number',
                                                    value: stockregisterForm.number,
                                                    required: true,
                                                    error: stockregisterForm.numbererror,
                                                    disable: stockregisterForm.to ? true : false,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <DateField
                                            format={'YYYY-MM-DD'}
                                            field={{
                                                id: "date",
                                                label: formconstant.date,
                                                value: stockregisterForm.date,
                                                required: true,
                                                error: stockregisterForm.dateerror,
                                                onChange: handleInputChange
                                            }}
                                        />
                                    </div>
                                </div>
                            </div >

                        </DialogContent>
                        <DialogActions className=''>
                            <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                            <Button style={{ background: 'var(--color-main)', color: '#fff' }} onClick={handleFormSubmit}>Submit</Button>
                        </DialogActions>
                    </div>
                </Dialog>

            </>
        );

    }


    return (
        <>
            {/* filterItems={filterItems} */}
            <MultiTable
                title={columnConstant.stockregister}
                columns={columns}
                data={sectorMasterData}
                // drawerFormFields={drawerFormFields}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )

}

export default StockRegister;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});



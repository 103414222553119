import { useEffect, useState } from "react";
import { MultiTable } from "../../component/multiTable";
import { GetMasterBranchByPage, GetMasterCityByPage, GetMasterCountryByPage, GetMasterStateByPage, PostMasterBranch, PostMasterCity, PostMasterCountry, PostMasterState, UpdateMasterBranch, UpdateMasterBranchStatus } from "../../../services/constant";
import MasterCallBypagination from "../../../services/masterpaginationConfig";
import DivisonDrawer from "../../component/divisionDrawer";
import { useDispatch } from "react-redux";
import ControlDynamicForm from "../../component/controls/controlDynamicForm";
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from "../../component/controls";

const Branch = () => {
    const dispatch = useDispatch();
    const [branchMasterData, setBranchMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)


    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterBranchByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res?.id,
            state: res?.state?.value,
            stateid: res?.state?.key,
            city: res?.city?.value,
            cityid: res?.city?.key,
            country: res?.country?.value,
            countryid: res?.country?.key,
            branch: res?.name,
            addressline1: res?.addressLine1,
            addressline2: res?.addressLine2,
            pinCode:res?.pinCode,
            gst: res?.gstNumber,
       
            status: res?.status
        }));
        setBranchMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'branch',
            label: 'Branch',
            numeric: false,
        },
        {
            id: 'state',
            label: 'State',
            numeric: false,
        },
        {
            id: 'city',
            label: 'City',
            numeric: false,
        },

        {
            id: 'gst',
            label: 'GST No',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];


    const ActionCell = ({ row }) => {   
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateMasterBranchStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <BranchDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>
    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <BranchDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }

    return (
        <>
            <MultiTable
                data={branchMasterData}
                columns={columns}
                // filterItems={filterItems}
                // drawerFormFields={drawerFormFields}
                tabletool={TableTool}
                actioncell={ActionCell}
                title={'Branch'}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default Branch;



const BranchDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()
    const [cityItem, setCityItem] = useState([])
 
    const [closeValidator, setcloseValidator] = useState(false)

    const [branchName, setBranchName] = useState(row?.branch || '')
    const [branchNameError, setBranchNameError] = useState(false)

    const [cityType, setCityType] = useState(row?.cityid || '')
    const [cityTypeError, setCityTypeError] = useState(false)

    const [addressLine1, setAddressLine1] = useState(row?.addressline1 || '')
    const [addressLine1Error, setAddressLine1Error] = useState(false)

    const [addressLine2, setAddressLine2] = useState(row?.addressline2 || '')
    const [addressLine2Error, setAddressLine2Error] = useState(false)

    const [pincode, setPincode] = useState(row?.pincode || '')
    const [pincodeError, setPincodeError] = useState(false)

    const [gstCode, setGstCode] = useState(row?.gst || '')
    const [gstCodeError, setGstCodeError] = useState(false)



    const drawerOpenCallbackHandler = () => {
        debugger
        setBranchName(row?.branch || '')
        setAddressLine1(row?.addressline1 || '')
        setAddressLine2(row?.addressline2 || '')
        setPincode(row?.pinCode || '')
        setCityType(row?.cityid || '')
        setGstCode(row?.gst || '')
        setBranchNameError(false)
        setCityTypeError(false)
        setPincodeError(false)
        setGstCodeError(false)
        setAddressLine1Error(false)
        setAddressLine2Error(false)
    }

    const set_CityItemData = () => {
        GetCaller({
            API: PostMasterCity,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setCityItem(newCityItemData)
            }

        })

    } 
    useEffect(() => {
        set_CityItemData();
      
    }, []);


    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);

    const handleSubmit = () => {
        debugger
        if (!branchName || !cityType || !gstCode || !addressLine1 || !addressLine2 || !pincode) {
            setCityTypeError(!cityType)
            setAddressLine1Error(!addressLine1)
            setAddressLine2Error(!addressLine2)
            setBranchNameError(!branchName)
            setPincodeError(!pincode)
            setGstCodeError(!gstCode)
        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: branchName,
                cityId: cityType,       
                gstNumber: gstCode,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                pinCode:pincode,
                status: row.status,              
            } : {
                name: branchName,
              addressLine1: addressLine1,
             addressLine2: addressLine2,
                    cityId: cityType,
                    pinCode: pincode,
            gstNumber: gstCode,
            }
      
            editor ?
              PostCaller({
                API: UpdateMasterBranch,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })      
              :     
              PostCaller({
                API: PostMasterBranch,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })
            setcloseValidator(true)
      
          }

    }


    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'branch':
                setBranchName(value)
                setBranchNameError(!value);
                break;        
            case 'city':
                setCityType(value)
                setCityTypeError(!value);
                break;
            case 'gst':
                setGstCode(value)
                setGstCodeError(!value);
                break;
            case 'addressline1':
                setAddressLine1(value)
                setAddressLine1Error(!value);
                break;   
            case 'addressline2':
                setAddressLine2(value)
                setAddressLine2Error(!value);
                break;  
            case 'pincode':
                setPincode(value)
                setPincodeError(!value);
                break; 
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Branch",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Branch',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Branch",
            tooltitle: 'Add Branch',
            defaultbtnfalse: true
        }





    const FieldData = [
      
        {
            id: 'branch',
            label: 'Branch',
            type: "text",
            value: branchName,
            error: branchNameError,
            required: true,
            helperText: "Branch required",
            onChange: handleInputChange,

        },
        {
            id: 'city',
            label: 'City',
            type: "select",
            value: cityType,
            error: cityTypeError,
            required: true,
            helperText: "City required",
            item: cityItem,
            onChange: handleInputChange,
        },
        {
            id: 'gst',
            label: 'Gst Code',
            type: "text",
            value: gstCode,
            error: gstCodeError,
            required: true,
            helperText: "Gst Code required",
            onChange: handleInputChange,
        },

        {
            id: 'addressline1',
            label: 'Address Line1',
            type: "text",
            value: addressLine1,
            error: addressLine1Error,
            required: true,
            helperText: "Address Line1 required",
            onChange: handleInputChange,
        },
        {
            id: 'addressline2',
            label: 'Address Line2',
            type: "text",
            value: addressLine2,
            error: addressLine2Error,
            required: true,
            helperText: "Address Line2 required",
            onChange: handleInputChange,
        },

        {
            id: 'pincode',
            label: 'Pin Code',
            type: "text",
            value: pincode,
            error: pincodeError,
            required: true,
            helperText: "Pin Code required",
            onChange: handleInputChange,
        },

    ];

    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}


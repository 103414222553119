import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PostAirExportBuyingingCharges, PostAirExportSellingCharges } from "../../../../services/APIConstant/enquiryConstant";
import { PostCaller } from "../../../../services/apiServices";
import Dropdown, { InputField, InputFileField, TextAreafield } from "../../../component/controls";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { AirExportCustomerTypeFrightOptions, ApplicableOn, IntialStockProvider } from "../../../../services/modalData";
import { Add, Close } from "@mui/icons-material";
import Transition from "../../../../services/utilities/transition";
import { AddSplitJobByEnquiryID, updateSplitJobByEnquiryId } from "../../../../services/APIConstant/jobConstant";
import { columnConstant } from "../../../constant/formconstant";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import getFrieghtServices from "../../../../services/Frieght Services/AirExportFreightServices/getFrieghtServices";
import GetSelectedFreightDetails from "../../../../services/Frieght Services/AirExportFreightServices/GetSelectedFreightDetails";

export const SplitJobForm = ({ splitSelectedValue, splitEditJobData, callBack, shipperItem }) => {
    const dispatch = useDispatch();
    const [openTable, setOpenTable] = useState(true)

    const [splitFormdata, setSplitFormData] = useState([])
    const [splitFormDataError, setSplitFormDataError] = useState({});



    const handleClose = () => {
        callBack()
        setSplitFormData([])
        setInputFields([
            {
                shipperId: "",
                packages: "",
                grossWeight: "",
                airVolumeWeight: "",
                qtyChargeWeight: '',
                customerType: "",
                freightServiceType: "",
                bizCategoryType: "",
                bizSubType: "",
                bizType: "",
                freightServiceSubType: ""
            }
        ])
    }

    const handleSubmit = () => {

        const splitDatahasUndefindValues = (arraydata) => {
            let array = arraydata.length !== 0 ? arraydata : [
                {
                    shipperId: "",
                    packages: "",
                    grossWeight: "",
                    airVolumeWeight: "",
                    qtyChargeWeight: '',
                    customerType: "",
                    freightServiceType: "",
                    bizCategoryType: "",
                    bizSubType: "",
                    bizType: "",
                    freightServiceSubType: ""

                }]

            let changes = {};
            array?.map((obj, index) => {
                debugger
                Object.entries(obj).forEach(([key, value]) => {
                    if (
                        !value && (
                            key === 'shipperId' ||
                            key === 'packages' ||
                            key === 'grossWeight' ||
                            key === 'airVolumeWeight' ||
                            key === 'qtyChargeWeight' ||
                            key === 'customerType' ||
                            key === 'freightServiceType' ||
                            key === 'bizCategoryType' ||
                            key === 'bizSubType' ||
                            key === 'bizType' ||
                            (obj?.freightServiceSubTypeData && obj?.freightServiceSubTypeData.length !== 0 && key === 'freightServiceSubType')

                        )) {
                        changes[key + index] = !value;
                    }
                });
            })
            console.log('changes', changes)

            return changes;
        };
        const splitErrorChanges = splitDatahasUndefindValues(splitFormdata)


        debugger
        if (Object.keys(splitErrorChanges).length > 0) {
            setSplitFormDataError(splitErrorChanges)
        } else {
            PostCaller({
                API: splitEditJobData.length === 0 ? AddSplitJobByEnquiryID : updateSplitJobByEnquiryId,
                callback: () => {
                    callBack()
                    setSplitFormData([])
                    setInputFields([
                        {
                            shipperId: "",
                            packages: "",
                            grossWeight: "",
                            airVolumeWeight: "",
                            qtyChargeWeight: '',
                            customerType: "",
                            freightServiceType: "",
                            bizCategoryType: "",
                            bizSubType: "",
                            bizType: "",
                            freightServiceSubType: ""
                        }
                    ])

                },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: {
                    consoleId: splitSelectedValue.consoleJobs.length !== 0 ? splitSelectedValue?.id : null,
                    enquiryId: splitSelectedValue?.enquiryId,
                    splitData: splitFormdata
                }
            })
        }
    }
    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            shipperId: "",
            packages: "",
            grossWeight: "",
            airVolumeWeight: "",
            qtyChargeWeight: '',
            customerType: "",
            freightServiceType: "",
            bizCategoryType: "",
            bizSubType: "",
            bizType: "",
            freightServiceSubType: "",
            bizCategoryTypeData: [],
            bizTypeData: [],
            bizSubTypeData: []
        },
    ]);



    useEffect(() => {
        console.log('splitSelectedValue', splitSelectedValue)
        console.log('splitEditJobData', splitEditJobData)
        if (splitEditJobData && splitEditJobData.length !== 0) {
            const editData = splitEditJobData.map(res => ({
                ...res,
                key: generateUniqueKey(),
                packages: res.finalPackages,
                grossWeight: res.finalGrossWeight,
                airVolumeWeight: res.finalAirVolumeWeight,
                qtyChargeWeight: res.finalQtyChargeWeight,
                freightServiceSubTypeData: GetSelectedFreightDetails(splitSelectedValue?.customerType, res.freightServiceType).FreightServiceSubType,
                bizCategoryTypeData: GetSelectedFreightDetails(splitSelectedValue?.customerType, res.freightServiceType).BizCategoryType,
                bizTypeData: GetSelectedFreightDetails(splitSelectedValue?.customerType, res.freightServiceType, res.bizCategoryType).BizType,
                bizSubTypeData: GetSelectedFreightDetails(splitSelectedValue?.customerType, res.freightServiceType, res.bizCategoryType, res.bizType).BizSubType
            }))

            setInputFields(editData)
            setSplitFormData(editData)

        }
    }, [splitEditJobData])





    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            shipperId: "",
            packages: "",
            grossWeight: "",
            airVolumeWeight: "",
            qtyChargeWeight: '',
            customerType: "",
            freightServiceType: "",
            bizCategoryType: "",
            bizSubType: "",
            bizType: "",
            freightServiceSubType: ""
        }]);
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

        if (fieldToUpdate) {
            fieldToUpdate[field] = value;

            if (field === 'freightServiceType') {
                fieldToUpdate['freightServiceSubType'] = ''
                fieldToUpdate['bizCategoryType'] = ''
                fieldToUpdate['bizType'] = ''
                fieldToUpdate['bizSubType'] = ''

                fieldToUpdate['freightServiceSubTypeData'] = []
                fieldToUpdate['bizCategoryTypeData'] = []
                fieldToUpdate['bizTypeData'] = []
                fieldToUpdate['bizSubTypeData'] = []


                const frieghtDetails = GetSelectedFreightDetails(splitSelectedValue?.customerType, value)
                fieldToUpdate['freightServiceSubTypeData'] = frieghtDetails?.FreightServiceSubType

                fieldToUpdate['bizCategoryTypeData'] = frieghtDetails?.BizCategoryType
                if (frieghtDetails?.BizCategoryType.length === 1) {
                    fieldToUpdate['bizCategoryType'] = frieghtDetails?.BizCategoryType[0]?.name
                }

                fieldToUpdate['bizTypeData'] = frieghtDetails?.BizType
                if (frieghtDetails?.BizType?.length === 1) {
                    fieldToUpdate['bizType'] = frieghtDetails?.BizType[0]?.name
                }

                fieldToUpdate['bizSubTypeData'] = frieghtDetails?.BizSubType
                if (frieghtDetails?.BizSubType?.length === 1) {
                    fieldToUpdate['bizSubType'] = frieghtDetails?.BizSubType[0]?.name

                }

            }

            if (field === 'bizCategoryType') {
                fieldToUpdate['bizType'] = ''
                fieldToUpdate['bizSubType'] = ''
                fieldToUpdate['bizTypeData'] = []
                fieldToUpdate['bizSubTypeData'] = []


                const frieghtDetails = GetSelectedFreightDetails(splitSelectedValue?.customerType, fieldToUpdate?.freightServiceType, value)

                fieldToUpdate['bizTypeData'] = frieghtDetails?.BizType
                if (frieghtDetails?.BizType?.length === 1) {
                    fieldToUpdate['bizType'] = frieghtDetails?.BizType[0]?.name
                }

                fieldToUpdate['bizSubTypeData'] = frieghtDetails?.BizSubType
                if (frieghtDetails?.BizSubType?.length === 1) {
                    fieldToUpdate['bizSubType'] = frieghtDetails?.BizSubType[0]?.name
                }

            }

            if (field === 'bizType') {
                fieldToUpdate['bizSubType'] = ''
                fieldToUpdate['bizSubTypeData'] = []


                const frieghtDetails = GetSelectedFreightDetails(splitSelectedValue?.customerType, fieldToUpdate?.freightServiceType, fieldToUpdate?.bizCategoryType, value)

                fieldToUpdate['bizSubTypeData'] = frieghtDetails?.BizSubType
                if (frieghtDetails?.BizSubType?.length === 1) {
                    fieldToUpdate['bizSubType'] = frieghtDetails?.BizSubType[0]?.name
                }

            }


            setSplitFormDataError({
                ...splitFormDataError,
                [field + index]: !value
            })

            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields?.map((res) => {
                return data.push(
                    {
                        id: res?.id || null,
                        shipperId: res.shipperId,
                        packages: res.packages,
                        grossWeight: res.grossWeight,
                        airVolumeWeight: res?.airVolumeWeight,
                        qtyChargeWeight: res?.qtyChargeWeight,
                        customerType: splitSelectedValue.customerType,
                        freightServiceType: res.freightServiceType,
                        bizCategoryType: res.bizCategoryType,
                        bizSubType: res.bizSubType,
                        bizType: res.bizType,
                        freightServiceSubType: res.freightServiceSubType,
                        freightServiceSubTypeData: res.freightServiceSubTypeData,


                    }
                )

            })
            setSplitFormData(data)
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields?.map((res) => {
            return data.push(
                {
                    id: res?.id || null,
                    shipperId: res.shipperId,
                    packages: res.packages,
                    grossWeight: res.grossWeight,
                    airVolumeWeight: res?.airVolumeWeight,
                    qtyChargeWeight: res?.qtyChargeWeight,
                    customerType: splitSelectedValue.customerType,
                    freightServiceType: res.freightServiceType,
                    bizCategoryType: res.bizCategoryType,
                    bizSubType: res.bizSubType,
                    bizType: res.bizType,
                    freightServiceSubType: res.freightServiceSubType,
                    freightServiceSubTypeData: res.freightServiceSubTypeData,



                }
            )

        })
        setSplitFormData(data)
    };


    const filterChargeheadItems = (currentID) => {
        let filterData = shipperItem?.filter(res => !inputFields.some(item => (currentID !== res.value && item.shipperId === res.value)));
        return filterData;
    }

    return <>



        <Dialog
            open={splitSelectedValue}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                sx: {
                    width: "90%",
                    maxWidth: "90% !important",
                },
            }}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: "start"
                }
            }
            }


        >
            <div className=''>
                <DialogTitle className="mb-0">
                    <div className="cmd">

                        <h6 className="m-0 p-0">Split Job</h6>
                        <Tooltip title='Close'>
                            <IconButton onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <hr />
                </DialogTitle>
                <DialogContent>

                    <div className=''>

                        <div className="hr-form-box">

                            <div className='Initial_Quote_head cmd'>
                                <div><span>Enquiry Id</span> - {splitSelectedValue.enquiryId}</div>
                                <div><span>Shipper Name</span>- {splitSelectedValue.shipperName}</div>
                                <div><span>Organization Name/Biz. Generator</span>- {splitSelectedValue.bizGenerator}</div>
                                <div>
                                    <KeyboardArrowUpIcon onClick={() => setOpenTable(!openTable)} className={openTable ? 'rotate_up' : 'rotate_down'} />
                                </div>
                            </div>

                            {
                                openTable &&
                                <div className='check-customer-table' style={{ textAlign: "left" }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>{columnConstant.packages}</th>
                                                <th>{splitSelectedValue?.packages}</th>
                                                <th>{columnConstant.grosswt}</th>
                                                <th>{splitSelectedValue?.grossWeight}</th>
                                                <th>{columnConstant.airvolumeweightcbm}</th>
                                                <th>{splitSelectedValue?.airVolumeWeight}</th>
                                                <th>{"Freight Services"}</th>
                                                <th>{splitSelectedValue?.freightServiceType}</th>
                                            </tr>
                                            <tr>
                                                <th>{columnConstant.airportofloading}</th>
                                                <th>{splitSelectedValue?.airportOfLoadingName}</th>
                                                <th>{columnConstant.airportofdestination}</th>
                                                <th>{splitSelectedValue?.airportOfDestinationName}</th>
                                                <th>{columnConstant.chargeableweight}</th>
                                                <th>{splitSelectedValue?.qtyChargeWeight}</th>
                                                <th>{columnConstant.tentativedateofship}</th>
                                                <th>{splitSelectedValue?.tentativeDateOfShipment}</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            }

                        </div>






                        {inputFields?.map((field, key) => (
                            <div className='row' key={field.key}>
                                <div className="col-md-1 cmd" style={{ justifyContent: "left", alignItems: "flex-start" }}>
                                    {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                        <Add />
                                    </Button> : (
                                        <Button
                                            sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                            onClick={() => removeInputField(field.key)}
                                            variant='contained'
                                        >
                                            <Close />
                                        </Button>
                                    )}
                                </div>
                                <div className="col-md-11 ">

                                    <div className="row mb-3">
                                        <div className="col-md-3">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: "shipperId",
                                                        label: "Shipper Name ",
                                                        value: field.shipperId,
                                                        required: true,
                                                        error: splitFormDataError['shipperId' + key],
                                                        item: filterChargeheadItems(field.shipperId),
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>

                                        <div className="col-md-2">
                                            <InputField
                                                field={
                                                    {
                                                        id: "packages",
                                                        label: "Packages",
                                                        type: 'number',
                                                        value: field.packages,
                                                        required: true,
                                                        error: splitFormDataError['packages' + key],
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <InputField
                                                field={
                                                    {
                                                        id: "grossWeight",
                                                        label: "Gross Weight",
                                                        type: 'number',
                                                        value: field.grossWeight,
                                                        required: true,
                                                        error: splitFormDataError['grossWeight' + key],
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <InputField
                                                field={
                                                    {
                                                        id: "airVolumeWeight",
                                                        label: "Volume Weight",
                                                        type: 'number',
                                                        value: field.airVolumeWeight,
                                                        required: true,
                                                        error: splitFormDataError['airVolumeWeight' + key],
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <InputField
                                                field={
                                                    {
                                                        id: "qtyChargeWeight",
                                                        label: "Chargeable Weight",
                                                        type: 'number',
                                                        value: field.qtyChargeWeight,
                                                        required: true,
                                                        error: splitFormDataError['qtyChargeWeight' + key],
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        {/* <div className="col-md-3">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: "customerType",
                                                        label: "Customer Type ",
                                                        value: field.customerType,
                                                        required: true,
                                                        error: splitFormDataError['customerType' + key],
                                                        item: AirExportCustomerTypeFrightOptions,
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div> */}
                                        {
                                            splitSelectedValue.customerType && getFrieghtServices(splitSelectedValue.customerType).length !== 0 &&
                                            <div className="col-md-3">
                                                <Dropdown
                                                    field={
                                                        {
                                                            id: "freightServiceType",
                                                            label: "Frieght/Service Type ",
                                                            value: field.freightServiceType,
                                                            required: true,
                                                            error: splitFormDataError['freightServiceType' + key],
                                                            item: getFrieghtServices(splitSelectedValue.customerType),
                                                            onChange: (value, fieldChanges) => {
                                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        }
                                        {
                                            field?.freightServiceSubTypeData && field?.freightServiceSubTypeData?.length !== 0 &&
                                            <div className="col-md-3">
                                                <Dropdown
                                                    field={
                                                        {
                                                            id: "freightServiceSubType",
                                                            label: "Frieght/Service Sub Type ",
                                                            value: field.freightServiceSubType,
                                                            required: true,
                                                            error: splitFormDataError['freightServiceSubType' + key],
                                                            disable: field?.freightServiceSubTypeData?.length === 1,
                                                            item: field?.freightServiceSubTypeData,
                                                            onChange: (value, fieldChanges) => {
                                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        }

                                        {
                                            field?.bizCategoryTypeData && field?.bizCategoryTypeData?.length !== 0 && field.bizCategoryType !== 'N/A' &&
                                            <div className="col-md-3">
                                                <Dropdown
                                                    field={
                                                        {
                                                            id: "bizCategoryType",
                                                            label: "Biz Category Type ",
                                                            value: field.bizCategoryType,
                                                            required: true,
                                                            error: splitFormDataError['bizCategoryType' + key],
                                                            disable: field?.bizCategoryTypeData?.length === 1,
                                                            item: field.bizCategoryTypeData,
                                                            onChange: (value, fieldChanges) => {
                                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        }

                                        {

                                            field.bizTypeData && field.bizTypeData?.length !== 0 && field.bizType !== 'N/A' &&

                                            <div className="col-md-3">
                                                <Dropdown
                                                    field={
                                                        {
                                                            id: "bizType",
                                                            label: "Biz Type ",
                                                            value: field.bizType,
                                                            required: true,
                                                            error: splitFormDataError['bizType' + key],
                                                            item: field.bizTypeData,
                                                            disable: field?.bizTypeData?.length === 1,
                                                            onChange: (value, fieldChanges) => {
                                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        }

                                        {
                                            field.bizSubTypeData && field.bizSubTypeData?.length !== 0 && field.bizSubType !== 'N/A' &&
                                            <div className="col-md-3">
                                                <Dropdown
                                                    field={
                                                        {
                                                            id: "bizSubType",
                                                            label: "Biz Sub Type ",
                                                            value: field.bizSubType,
                                                            required: true,
                                                            error: splitFormDataError['bizSubType' + key],
                                                            item: field.bizSubTypeData,
                                                            disable: field?.bizSubTypeData?.length === 1,
                                                            onChange: (value, fieldChanges) => {
                                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>

                                        }

                                    </div>


                                </div>
                            </div>


                        ))}
                    </div >
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                    <Button onClick={handleSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
                </DialogActions>
            </div>
        </Dialog>



    </>
}
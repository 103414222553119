import { Button } from "@mui/material";
import { useState } from "react";
import { GetMasterBizInstituteTypeByPage, GetMasterBranchByPage, GetTDSMasterBizInstituteDetailsById, PostTDSMaster, UpdateTDSMaster } from "../../services/constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tdsmaster } from "../../navigation/constant";
import { useEffect } from "react";
import GetCaller, { PostCaller } from "../../services/apiServices";
import { Add, Close } from "@mui/icons-material";
import Dropdown, {  InputField, StackDropdown } from "../component/controls";
import { applicableFormat } from "../../services/modalData";



export const TdsMasterForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();


    const [isEdit, setIsEdit] = useState(false);

    const [bizInstituteItem, setbizInstituteItem] = useState([]);
    const [subgroupDetailsError, setSubGroupDetailsError] = useState({})

    const [branchitem, setBranchTypeItem] = useState([]);

    // const [vendorForm.isTransportalCategory, setVendorForm] = useState({

    const [vendorForm, setVendorForm] = useState({
        id: "",
        name: "",
        branchId: 1,
        tdsSection: "",
        paymentCode: "",
        TdsPayableLedgerId: "AE5F46C6-FF42-49BB-B36E-001963F35B33",
        isTransportalCategory: '',
        valueLimitForSingleInvoice: "",
        totalThresholdLimit: "",
        bizInstituteTypeWithPan: "1",
        bizInstituteTypeWithoutPan: "1",

    })
    useEffect(() => {
        set_BranchItemData()
        const tds = JSON.parse(sessionStorage.getItem("tds"));
        // Biz institute items
        GetCaller({
            API: GetMasterBizInstituteTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                debugger
                const bizInstituteItem = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                }))
                setbizInstituteItem(bizInstituteItem)
            }

        })


        if (tds) {
            tds.isTransportalCategory = (tds?.isTransportalCategory === true) ? 1 : 2;

            debugger
            setVendorForm(tds);
            setIsEdit(true);
        }

    }, []);
    const handleFormSubmit = () => {
        debugger

        const changes = handleSubmit(requestdata);
        const tdsBizInstituteListChanges = tdsBizInstituteListUndefinedValues(requestdata.tdsBizInstituteList || [])
        if (Object.keys(changes).length > 0 ||
            Object.keys(tdsBizInstituteListChanges).length > 0
        ) {
            setSubGroupDetailsError(tdsBizInstituteListChanges)
            setVendorForm({ ...vendorForm, ...changes });
        }
        if (isEdit) {

            EditAreaMasterSubmit(requestdata);
            setIsEdit(false);
        }
        else {
            AddAreaMasterSubmit(requestdata)
        }
    };



    const handleSubmit = (obj) => {
        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if ((key !== 'id' || key !== 'status') && !value) {
                if (key == 'isTransportalCategory') {
                    changes[key] = value == 'No' ? false : true;
                }
                else {
                    changes[key] = value;
                }
                changes[key + 'error'] = !value;
            }

        });

        return changes;
    };

    const tdsBizInstituteListUndefinedValues = (array) => {
        let data = array.length !== 0 ? array : [
            {
                bizInstituteType: '',
                bizInstituteTypeWithPan: '',
                bizInstituteTypeWithoutPan: '',
            }
        ]
        debugger
        let changes = {};
        data.map((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                debugger
                if (
                    key === 'id' ||
                    key === 'tdsId'
                ) {

                } else if (!value) {
                    changes[key + index] = !value;

                }
            });
        })
        console.log('changes', changes)

        return changes;
    }





    const handleInputChange = (value, field) => {
        setVendorForm({
            ...vendorForm,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    };

    const requestdata = {
        id: vendorForm.id,
        name: vendorForm.name,
        branchId:vendorForm.branchId,
        tdsSection: vendorForm.tdsSection,
        paymentCode: vendorForm.paymentCode,
        TdsPayableLedgerId: vendorForm.TdsPayableLedgerId,
        isTransportalCategory: vendorForm.isTransportalCategory,
        valueLimitForSingleInvoice: vendorForm.valueLimitForSingleInvoice,
        totalThresholdLimit: vendorForm.totalThresholdLimit,
        tdsBizInstituteList: vendorForm.tdsBizInstituteList

    }


    const set_BranchItemData = () => {

        GetCaller({
            API: GetMasterBranchByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, paylode: e.paylode })
            },
            item: {},
            callback: ({ response }) => {
                const newBranchItemData = response?.data?.data?.records.map((item) => ({

                    value: item.id,
                    name: item.name,
                }))
                setBranchTypeItem(newBranchItemData)
                return newBranchItemData
            }
        })
    }



    const EditAreaMasterSubmit = (requestdata) => {
        requestdata.isTransportalCategory = requestdata.isTransportalCategory == 'No' ? false : true;
        const branchString = requestdata.branchId?.map(item => item.value).join(',');
        requestdata.branchId = branchString
        requestdata.TdsPayableLedgerId = "AE5F46C6-FF42-49BB-B36E-001963F35B33";
        debugger
        PostCaller({
            API: UpdateTDSMaster,
            callback: () => { navigate(tdsmaster) },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })

    }
    const AddAreaMasterSubmit = (requestdata) => {
        requestdata.isTransportalCategory = requestdata.isTransportalCategory == 'No' ? false : true;
        const branchString = requestdata.branchId?.map(item => item.value).join(',');
        requestdata.branchId = branchString
        requestdata.TdsPayableLedgerId = "AE5F46C6-FF42-49BB-B36E-001963F35B33";
        debugger
        console.log("Add the TDS ", requestdata);

        PostCaller({
            API: PostTDSMaster,
            callback: () => { navigate(tdsmaster) },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })
    }





    const MultipleTDSBizInstitute = () => {


        useEffect(() => {
            const tds = JSON.parse(sessionStorage.getItem("tds"));

            if (tds) {
                GetCaller({
                    API: GetTDSMasterBizInstituteDetailsById,
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: { id: tds.id },
                    callback: ({ response }) => {
                        debugger
                        const bizInstituteItem = response?.data?.data?.map((res) => ({
                            key: generateUniqueKey(), // Add a unique key for each input field
                            ...res
                        }))

                        setInputFields(bizInstituteItem)
                    }

                })
            }
        }, [])

        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };
        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                bizInstituteType: '',
                bizInstituteTypeWithPan: '',
                bizInstituteTypeWithoutPan: '',
            },
        ]);



        const addInputField = () => {
            setInputFields([...inputFields, {
                key: generateUniqueKey(),
                bizInstituteType: '',
                bizInstituteTypeWithPan: '',
                bizInstituteTypeWithoutPan: '',
            }]);
            setSubGroupDetailsError([])
        };


        const handleMultiInputChange = (key, field, value, index) => {
            debugger;
            const updatedInputFields = [...inputFields];
            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
            if (fieldToUpdate) {
                setSubGroupDetailsError({
                    ...subgroupDetailsError,
                    [field + index]: !value
                })
                fieldToUpdate[field] = value;
                setInputFields(updatedInputFields);

                const data = []
                updatedInputFields && updatedInputFields.map((res) => {
                    return data.push(
                        {
                            id: res?.id || 0,
                            bizInstituteType: res.bizInstituteType,
                            bizInstituteTypeWithPan: res.bizInstituteTypeWithPan,
                            bizInstituteTypeWithoutPan: res.bizInstituteTypeWithoutPan,
                            tdsId: vendorForm.id || null
                        }
                    )
                })


                handleInputChange(data, { id: 'tdsBizInstituteList' });
            }
        };

        const removeInputField = (key) => {
            setSubGroupDetailsError([])

            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res?.id || 0,
                        bizInstituteType: res.bizInstituteType,
                        bizInstituteTypeWithPan: res.bizInstituteTypeWithPan,
                        bizInstituteTypeWithoutPan: res.bizInstituteTypeWithoutPan,
                        tdsId: vendorForm.id || null
                    }
                )

            })
            handleInputChange(data, { id: 'tdsBizInstituteList' });
        };

        const filterSubgroupItems = (currentID) => {
            let filterData = bizInstituteItem?.filter(res => !inputFields.some(item => (currentID !== res.value && item.bizInstituteType === res.value)));
            return filterData;
        }


        return <>
            <div className=''>






                <div className="d-flex align-items-center">
                    <h4 className="m-0"></h4>
                </div>


                {inputFields.map((field, key) => (
                    <div className='row' key={field.key}>
                        <div className="col-md-1 cmd" style={{ justifyContent: "left" }}>
                            {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                <Add />
                            </Button> : (
                                <Button
                                    sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                    onClick={() => removeInputField(field.key)}
                                    variant='contained'
                                >
                                    <Close />
                                </Button>
                            )}
                        </div>
                        <div className="col-md-11">
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <Dropdown
                                        field={
                                            {
                                                id: "bizInstituteType",
                                                label: "Biz Institute Type ",
                                                value: field.bizInstituteType,
                                                required: true,
                                                error: subgroupDetailsError["bizInstituteType" + key],
                                                helperText: 'Contact Person Name Required',
                                                item: filterSubgroupItems(field.bizInstituteType),
                                                onChange: (value) => {
                                                    handleMultiInputChange(field.key, 'bizInstituteType', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        field={
                                            {
                                                id: "bizInstituteTypeWithPan",
                                                label: "Biz Institute Type/HUF(with PAN) %",
                                                type: 'number',
                                                value: field.bizInstituteTypeWithPan,
                                                required: true,
                                                error: subgroupDetailsError["bizInstituteTypeWithPan" + key],
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleMultiInputChange(field.key, 'bizInstituteTypeWithPan', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-4">
                                    <InputField
                                        field={{
                                            id: 'bizInstituteTypeWithoutPan',
                                            label: 'Biz Institute Type/HUF(without PAN) %',
                                            value: field.bizInstituteTypeWithoutPan,
                                            required: true,
                                            error: subgroupDetailsError["bizInstituteTypeWithoutPan" + key],
                                            onChange: (value) => {
                                                handleMultiInputChange(field.key, 'bizInstituteTypeWithoutPan', value, key);
                                            },



                                        }}
                                    />

                                </div>

                            </div>
                        </div>

                    </div>
                ))}
            </div>

        </>
    }




    return <>

        <div className="hr-form-box">
            {isEdit ? <h6>Edit TDS Master</h6> : <h6>Create TDS Master</h6>}
            <div className="row">
                <div className="col-md-3">

                    <InputField
                        field={
                            {
                                id: 'name',
                                label: 'Name',
                                type: 'text',
                                value: vendorForm.name,
                                required: true,
                                error: vendorForm.nameerror,
                                onChange: handleInputChange

                            }
                        }
                    />
                </div>



                <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: 'tdsSection',
                                label: 'TDS Section',
                                type: "text",
                                value: vendorForm.tdsSection,
                                required: true,
                                error: vendorForm.tdsSectionerror,
                                onChange: handleInputChange
                            }
                        }
                    />
                </div>

                <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: 'paymentCode',
                                label: 'Payment Code',
                                type: 'text',
                                value: vendorForm.paymentCode,
                                required: true,
                                error: vendorForm.paymentCodeerror,
                                onChange: handleInputChange
                            }
                        }
                    />
                </div>

                <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: 'valueLimitForSingleInvoice',
                                label: 'Value Limit For Single Invoice',
                                type: 'number',
                                value: vendorForm.valueLimitForSingleInvoice,
                                required: true,
                                error: vendorForm.valueLimitForSingleInvoiceerror,
                                onChange: handleInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: 'totalThresholdLimit',
                                label: 'Total Threshhold/Exemption Limit',
                                type: 'number',
                                value: vendorForm.totalThresholdLimit,
                                required: true,
                                error: vendorForm.totalThresholdLimiterror,
                                onChange: handleInputChange
                            }
                        }
                    />
                </div>




                <div className="col-md-3">
                    <Dropdown
                        field={
                            {
                                id: 'isTransportalCategory',
                                label: 'Select Transporter Category',
                                type: 'select',
                                value: vendorForm.isTransportalCategory,
                                required: true,
                                error: vendorForm.isTransportalCategoryerror,
                                item: applicableFormat,
                                onChange: handleInputChange
                            }
                        }
                    />
                </div>




                <div className="col-md-3">

                    <StackDropdown field={{
                        id: "branchId",
                        label: 'Branch',
                       
                        value: vendorForm.branchId,
                        required: true,
                        error: vendorForm.branchIderror,
                        item: branchitem,
                        onChange: handleInputChange
                    }}
                    />
                </div>




            </div>

            {/* {MultipleSubGroup()} */}
        </div>




        <div className="hr-form-box">
            {MultipleTDSBizInstitute()}
        </div>
        <div className='cmd'>
            <div></div>
            <Button sx={{ m: 2, borderRadius: "10px", }} onClick={handleFormSubmit} variant="contained">Submit</Button>
        </div>
    </>
}

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { PostAirExportBuyingingCharges, PostAirExportSellingCharges } from "../../../../services/APIConstant/enquiryConstant";
import GetCaller, { PostCaller } from "../../../../services/apiServices";
import Dropdown, { InputField, InputFileField, TextAreafield, TimeField } from "../../../component/controls";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Step, StepLabel, Stepper, Tooltip } from "@mui/material";
import { ApplicableOn, IntialStockProvider } from "../../../../services/modalData";
import { Add, Close, RemoveRedEyeTwoTone } from "@mui/icons-material";
import Transition from "../../../../services/utilities/transition";
import { GetAirExportJobStatusByJobId } from "../../../../services/APIConstant/jobConstant";
export const View_JOb_Status = ({ callBack, data, chargeHeadItem, fieldData }) => {
    const [open, setOpen] = React.useState(false);
    const [activeStep, setActiveStep] = useState(-1)
    const [statusData, setStatusData] = useState([])

    const handleClickOpen = () => {
        setOpen(true);

        if (data) {
            GetCaller({
                API: GetAirExportJobStatusByJobId,
                dispatch: (e) => {
                    // dispatch({ type: e.type, payload: e.payload })
                },
                item: {
                    id: data?.id
                },
                callback: ({ response }) => {

                    let data = response?.data?.data?.jobStatus
                    setStatusData(data)



                }
            })
        }


    };

    const handleClose = () => {
        setOpen(false);
        callBack()
    }


    const steps = [
        'Booking',
        'MAWB',
        'HAWB',
        'TC NO',
        'REC AT W/H',
        'SLOT',
        'REC AT AIRPORT',
        'REPORTED BY',
        'LEO',
        "X-RAY",
        'NOC',
        'H/O',
        'CHECKLIST AIRPORT'
    ];

    return <>
        <div className="" onClick={handleClickOpen}>
            <Tooltip title='View Status'>
                <IconButton>
                    <RemoveRedEyeTwoTone />
                </IconButton>
            </Tooltip>
        </div>


        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                sx: {
                    width: "90%",
                    maxWidth: "90% !important",
                },
            }}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: "start"
                }
            }
            }


        >
            <div className=''>
                <DialogTitle>
                    <div className="cmd">

                        <h6 className="m-0 p-0">Status</h6>
                        <Tooltip title='Close'>
                            <IconButton onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <hr />
                </DialogTitle>
                <DialogContent>

                    <div className='mt-4'>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps?.map((res, index) => (
                                <Step key={index}>
                                    <StepLabel>{res?.key || res}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div >
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={} variant='outlined'>Cancel</Button>
                    <Button onClick={} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button> */}
                </DialogActions>
            </div>
        </Dialog>



    </>
}
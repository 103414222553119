import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetAirExportFinalSellingChargesById, PostAirExportFinalBuyingingCharges, PostAirExportFinalSellingCharges, PostAirExportSellingCharges, UpdateAirExportBuyingingCharges, UpdateAirExportFinalBuyingingCharges, UpdateAirExportFinalSellingCharges } from "../../../../services/APIConstant/enquiryConstant";
import GetCaller, { PostCaller } from "../../../../services/apiServices";
import Dropdown, { DateField, InputField, InputFileField, TextAreafield, TimeField } from "../../../component/controls";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { IntialStockProvider } from "../../../../services/modalData";
import { Add, Close } from "@mui/icons-material";

import Transition from "../../../../services/utilities/transition";
import { airenquirylist } from "../../../../navigation/constant";
import { useNavigate } from "react-router-dom";
import { Create_DueCarrier } from "../intialQuote/createDueCarrier";
import { type } from "@testing-library/user-event/dist/type";
export const FinalSellingQuotes = ({
    finalQuoteData,
    airportItem,
    countrycurrency,
    unitItem,
    vendorIATAItem,
    chargeHeadItem
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [airFreightDataById, setAirFreightDataById] = useState([]);
    const [otherChargesDataById, setOtherChargesDataById] = useState([]);
    const [isbuyingUpDate, setIsBuyingUpdate] = useState(false);

    const [airfrightDetailsError, setAirfrightDetailsError] = useState([]);
    const [otherChargesDetailsError, setOtherChargesDetailsError] = useState([]);



    const [exportbuyingdata, setExportBuyingdata] = useState({
        enquiryId: "",
        bookingDate: "",
        currency1: "",
        currency2: "",
        currency3: "",
        exchangeRate1: "",
        exchangeRate2: "",
        exchangeRate3: "",
        totalBuyingChargeOther: "",
        remarks: "",
        document: "",

        airlineId: '',
        stockProvider: '',
        vendorId: '',
        currencyId: '',
        exchangeRate: '',
        afRate: '',
        applicableOn: '',
        unitId: '',
        qtyChargeWeight: '',
        amount: '',
        dueCarrier: '',
        routing: '',
        transitTime: '',
        validity: '',
        selectForSelling: false,
        totalAmount: '',
        dueCarrierList: [],

        otherChargeList: [
            {
                chargeHeadId: '',
                vendorId: '',
                currencyId: '',
                exchangeRate: '',
                selectForSelling: false,
                rate: '',
                applicableOn: '',
                unitId: '',
                qtyChargeWeight: '',
                amount: ''
            }
        ]
    })

    const [airFreightAmount, setAirfreightAmount] = useState('');
    const [airFreightTotalDueCarriers, setAirfreightTotalDueCarriers] = useState('');
    const [airFreighttotalAmount, setAirfreightTotalAmount] = useState('');

    useEffect(() => {
        let amount = 0
        if (exportbuyingdata?.applicableOn === 2) {
            amount = Number(exportbuyingdata?.afRate * Number(exportbuyingdata?.qtyChargeWeight))
        } else {
            amount = Number(exportbuyingdata?.afRate)
        }
        setAirfreightAmount(amount === 0 ? '' : amount)

        let dueCarrier = 0
        exportbuyingdata?.dueCarrierList?.map(res => (
            dueCarrier += Number(res.amount)
        ))
        setAirfreightTotalDueCarriers(dueCarrier)

        let totalAmount = 0
        totalAmount = amount + dueCarrier
        setAirfreightTotalAmount(totalAmount)
    }, [exportbuyingdata])



    useEffect(() => {
        if (finalQuoteData.id) {
            GetCaller({
                API: GetAirExportFinalSellingChargesById,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: finalQuoteData.id,
                callback: ({ response }) => {
                    const IDdata = response?.data?.data
                    if (IDdata.id) {
                        setExportBuyingdata({
                            ...IDdata.airFreightList[0],

                            finalBuyingID: IDdata.id,
                            buyingApproved: IDdata.buyingApproved,
                            currency1: IDdata.currency1,
                            currency2: IDdata.currency2,
                            currency3: IDdata.currency3,
                            exchangeRate1: IDdata.exchangeRate1,
                            exchangeRate2: IDdata.exchangeRate2,
                            exchangeRate3: IDdata.exchangeRate3,
                            remarks: IDdata.remarks,
                            enquiryId: IDdata.enquiryId,
                            document: IDdata.filePath,
                            otherChargeList: IDdata.otherChargeList,
                            bookingDate: IDdata.bookingDate
                        })
                        // setAirFreightDataById(IDdata.airFreightList)
                        setOtherChargesDataById(IDdata.otherChargeList)
                        setIsBuyingUpdate(true)
                    }
                    else {
                        setIsBuyingUpdate(false)
                    }
                }
            });
        }
    }, [finalQuoteData])


    const requestdata = (() => {
        const data = {
            currency1: exportbuyingdata.currency1,
            exchangeRate1: exportbuyingdata.exchangeRate1,
            remarks: exportbuyingdata.remarks,
            document: exportbuyingdata.document,
            totalBuyingChargeOther: '0',
            airFreightList: [{
                id: exportbuyingdata?.id,
                aeFinalQuoteSellingChargeId: exportbuyingdata?.aeFinalQuoteSellingChargeId || null,
                airlineId: exportbuyingdata?.airlineId,
                stockProvider: exportbuyingdata?.stockProvider,
                vendorId: exportbuyingdata?.vendorId,
                currencyId: exportbuyingdata?.currencyId,
                exchangeRate: exportbuyingdata?.exchangeRate,
                afRate: exportbuyingdata?.afRate,
                applicableOn: exportbuyingdata?.applicableOn,
                unitId: exportbuyingdata?.unitId,
                qtyChargeWeight: exportbuyingdata?.qtyChargeWeight,
                amount: airFreightAmount,
                dueCarrier: airFreightTotalDueCarriers,
                routing: exportbuyingdata?.routing,
                transitTime: exportbuyingdata?.transitTime,
                validity: exportbuyingdata?.validity,
                selectForSelling: false,
                totalAmount: airFreighttotalAmount,
                // dueCarrierList: exportbuyingdata?.dueCarrierList
                dueCarrierList: exportbuyingdata?.dueCarrierList?.map(({ ...rest }) => ({
                    ...rest,
                    aeFinalSellingChargeAirFreightId: exportbuyingdata?.id
                }))
            }],
        }
        if (exportbuyingdata.bookingDate) data.bookingDate = exportbuyingdata.bookingDate
        if (exportbuyingdata.finalBuyingID) data.id = exportbuyingdata.finalBuyingID
        if (finalQuoteData.id) data.enquiryId = finalQuoteData.id
        if (exportbuyingdata.currency2) data.currency2 = exportbuyingdata.currency2
        if (exportbuyingdata.currency3) data.currency3 = exportbuyingdata.currency3
        if (exportbuyingdata.exchangeRate2) data.exchangeRate2 = exportbuyingdata.exchangeRate2
        if (exportbuyingdata.exchangeRate3) data.exchangeRate3 = exportbuyingdata.exchangeRate3
        if (exportbuyingdata.otherChargeList) data.otherChargeList = exportbuyingdata.otherChargeList

        return data;
    })();




    const OtherChargeshasUndefinedValueIndex = (value, field, index) => {
        setOtherChargesDetailsError({
            ...airfrightDetailsError,
            [field + index]: !value
        })
    }

    const hasUndefinedValue = (obj) => {
        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (key === 'id' || key === 'currency3' || key === 'selectForSelling' || key === 'aeFinalQuoteSellingChargeId' || key === 'dueCarrier') {
                // handle 'Remark' validation if needed
            } else if (!value) {
                changes[key + "error"] = !value;
            }
        });
        return changes;
    };

    const AirFreighthasUndefinedValue = (array) => {

        let changes = {};
        array?.map((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                if (!value && (
                    key === 'chargeHeadId' ||
                    key === 'currencyId' ||
                    key === 'exchangeRate' ||
                    key === 'rate' ||
                    key === 'unitId' ||
                    key === 'qtyChargeWeight'
                )) {
                    changes[key + index] = !value;
                }
            });
        })
        console.log('changes', changes)

        return changes;
    };



    const handleFormSubmit = () => {


        const changes = hasUndefinedValue(requestdata);
        const airfreightdetailchange = hasUndefinedValue(requestdata.airFreightList[0]);
        const otherChargesDetails = AirFreighthasUndefinedValue(requestdata.otherChargeList);
        debugger

        if (Object.keys(changes).length > 0 ||
            Object.keys(airfreightdetailchange).length > 0 ||
            Object.keys(otherChargesDetails).length > 0
        ) {
            setExportBuyingdata({
                ...exportbuyingdata,
                ...changes,
                ...airfreightdetailchange
            });

            setOtherChargesDetailsError(otherChargesDetails)
            console.log(airfreightdetailchange)
        }
        else {
            AddAirExportBuyingCharges(requestdata);
        }
    };


    const AddAirExportBuyingCharges = (requestdata) => {


        PostCaller({
            API: isbuyingUpDate ? UpdateAirExportFinalSellingCharges : PostAirExportFinalSellingCharges,
            callback: () => {
                navigate(airenquirylist)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            contenttype: true,
            item: requestdata
        })
    }

    const handleBasicInputChange = (value, field) => {


        setExportBuyingdata({
            ...exportbuyingdata,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    }


    const filterCountryItems = (one, two) => {
        let updatedCountryCurrency = countrycurrency?.filter(item => item.value !== one && item.value !== two);
        return updatedCountryCurrency;
    }




    return <>
        <div className="row">
            <div className="col-md-2">
                <Dropdown
                    field={
                        {
                            id: "currency1",
                            label: "Currency 1",
                            value: exportbuyingdata.currency1,
                            required: true,
                            error: exportbuyingdata.currency1error,
                            item: filterCountryItems(exportbuyingdata.currency2, exportbuyingdata.currency3),
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
            <div className="col-md-2">
                <InputField
                    field={
                        {
                            id: "exchangeRate1",
                            label: "Exchange Rate",
                            type: "number",
                            value: exportbuyingdata.exchangeRate1,
                            required: true,
                            error: exportbuyingdata.exchangeRate1error,
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
            <div className="col-md-2">
                <Dropdown
                    field={
                        {
                            id: "currency2",
                            label: "Currency 2",
                            value: exportbuyingdata.currency2,
                            required: false,
                            error: exportbuyingdata.currency2error,
                            item: filterCountryItems(exportbuyingdata.currency1, exportbuyingdata.currency3),
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
            <div className="col-md-2">
                <InputField
                    field={
                        {
                            id: "exchangeRate2",
                            label: "Exchange Rate",
                            type: "number",
                            value: exportbuyingdata.exchangeRate2,
                            required: false,
                            error: exportbuyingdata.exchangeRate2error,
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
            <div className="col-md-2">
                <Dropdown
                    field={
                        {
                            id: "currency3",
                            label: "Currency 3",
                            value: exportbuyingdata.currency3,
                            required: false,
                            error: exportbuyingdata.currency3error,
                            item: filterCountryItems(exportbuyingdata.currency2, exportbuyingdata.currency1),
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
            <div className="col-md-2">
                <InputField
                    field={
                        {
                            id: "exchangeRate3",
                            label: "Exchange Rate",
                            type: "number",
                            value: exportbuyingdata.exchangeRate3,
                            required: false,
                            error: exportbuyingdata.exchangeRate3error,
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
        </div>

        <div>
            <h6 className="mt-3">AIR FREIGHT</h6>
            <div className="row">
                <div className="col-md-2">
                    <Dropdown
                        field={
                            {
                                id: "airlineId",
                                label: "Airline ",
                                value: exportbuyingdata.airlineId,
                                required: true,
                                disable: true,
                                error: exportbuyingdata?.airlineIderror,
                                item: airportItem,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">

                    <Dropdown

                        field={
                            {
                                id: "stockProvider",
                                label: "Stock Provider ",
                                value: exportbuyingdata.stockProvider,
                                disable: false,
                                required: true,
                                error: exportbuyingdata.stockProvidererror,
                                item: IntialStockProvider,
                                onChange: handleBasicInputChange
                            }
                        }
                    />

                </div>

                {/* <div className="col-md-2">

                    <Dropdown

                        field={
                            {
                                id: "vendorId",
                                label: "Vendor/IATA ",
                                value: exportbuyingdata.vendorId,
                                disable: false,
                                required: true,
                                error: exportbuyingdata.vendorIderror,
                                item: vendorIATAItem,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div> */}
                <div className="col-md-2">

                    <Dropdown

                        field={
                            {
                                id: "currencyId",
                                label: "Currency ",
                                value: exportbuyingdata.currencyId,
                                disable: false,
                                required: true,
                                error: exportbuyingdata.currencyIderror,
                                item: countrycurrency,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <InputField

                        field={
                            {
                                id: "exchangeRate",
                                label: "Ex. Rate",
                                type: 'number',
                                value: exportbuyingdata.exchangeRate,
                                disable: false,
                                required: true,
                                error: exportbuyingdata.exchangeRateerror,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <div className="d-flex gstWrapper">
                        <InputField

                            field={
                                {
                                    id: "buyingAFRate",
                                    label: "Buying",
                                    type: 'number',
                                    value: exportbuyingdata.buyingAFRate,
                                    disable: true,
                                    required: true,
                                    error: exportbuyingdata.buyingAFRateerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                        <InputField

                            field={
                                {
                                    id: "afRate",
                                    label: "Rate",
                                    type: 'number',
                                    value: exportbuyingdata.afRate,
                                    disable: true,
                                    required: true,
                                    disable: false,
                                    error: exportbuyingdata.afRateerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />

                    </div>



                </div>
                <div className="col-md-2">
                    <Dropdown
                        className={'mt-3'}
                        field={
                            {
                                id: "applicableOn",
                                label: "Applicable On ",
                                type: 'number',
                                value: exportbuyingdata.applicableOn,
                                disable: false,
                                required: true,
                                error: exportbuyingdata.applicableOnerror,
                                item: [
                                    { value: 1, name: 'Minimum' },
                                    { value: 2, name: 'Chargeable weight' },
                                ],
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <Dropdown
                        className={'mt-4'}
                        field={
                            {
                                id: "unitId",
                                label: "Unit",
                                value: exportbuyingdata.unitId,
                                disable: false,
                                required: true,
                                error: exportbuyingdata.unitIderror,
                                item: [
                                    { value: 1, name: 'KG' },
                                    { value: 2, name: 'LBS' },
                                ],
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>

                <div className="col-md-2">
                    <InputField
                        className={'mt-4'}
                        field={
                            {
                                id: "qtyChargeWeight",
                                label: "Chargeable weight",
                                type: 'number',
                                value: exportbuyingdata.qtyChargeWeight,
                                disable: true,
                                required: true,
                                error: exportbuyingdata.qtyChargeWeighterror,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>

                <div className="col-md-2">
                    <InputField
                        className={'mt-4'}
                        field={
                            {
                                id: "amount",
                                label: "Amount",
                                type: 'number',
                                value: airFreightAmount,
                                disable: true,
                                required: true,
                                error: exportbuyingdata.amounterror,
                                disable: true,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <React.Fragment>
                        <div className="cmd mt-2"  >
                            <div></div>

                            <Create_DueCarrier
                                countrycurrency={countrycurrency}
                                unitItem={unitItem}
                                chargeHeadItem={chargeHeadItem}
                                data={exportbuyingdata?.dueCarrierList}
                                callBack={(value, id) => handleBasicInputChange(value, { id: id === 'dueCarriers' ? 'dueCarrierList' : id })}
                                fieldData={exportbuyingdata}
                            />

                        </div>




                    </React.Fragment >
                    <InputField
                        className={'mt-0'}
                        field={
                            {
                                id: "dueCarrier",
                                label: "Due Carrier",
                                type: 'number',
                                value: airFreightTotalDueCarriers,
                                disable: true,
                                required: true,
                                error: exportbuyingdata.dueCarriererror,
                                disable: true,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <InputField
                        className={'mt-4'}
                        field={
                            {
                                id: "routing",
                                label: "Routing",
                                type: 'text',
                                value: exportbuyingdata.routing,
                                disable: false,
                                required: true,
                                error: exportbuyingdata.routingerror,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <InputField
                        className={'mt-4'}
                        field={{
                            id: "transitTime",
                            label: "Transit Time",
                            type: 'text',
                            value: exportbuyingdata.transitTime,
                            disable: false,
                            required: true,
                            error: exportbuyingdata.transitTimeerror,
                            onChange: handleBasicInputChange
                        }}
                    />
                </div>
                <div className="col-md-2">
                    <InputField
                        field={
                            {
                                id: "validity",
                                label: "Validity (In Days)",
                                type: 'number',
                                value: exportbuyingdata.validity,
                                disable: false,
                                required: true,
                                error: exportbuyingdata.validityerror,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <DateField
                        field={
                            {
                                id: "bookingDate",
                                label: "Booking Date",
                                value: exportbuyingdata.bookingDate,
                                required: true,
                                error: exportbuyingdata?.bookingDateerror,
                                disable: false,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-3">
                    <h6 className={`mt-3 ${exportbuyingdata.buyingApproved ? 'text-success' : 'text-danger'} `}>
                        {exportbuyingdata.buyingApproved ? "Approved" : "Pending For Approval !"}
                    </h6>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-2">

                    <InputField
                        field={
                            {
                                id: "totalAmount",
                                label: "Total Buying",
                                type: 'number',
                                value: airFreighttotalAmount,
                                required: true,
                                error: exportbuyingdata?.totalAmounterror,
                                disable: true,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
            </div>
        </div>

        {Create_OtherCharges({
            otherChargesDetailsError,
            countrycurrency,
            unitItem, vendorIATAItem,
            chargeHeadItem,
            handleBasicInputChange,
            otherChargesDataById,
            finalQuoteData,
            exportbuyingdata,
            OtherChargeshasUndefinedValueIndex
        })}




        <div className="row">
            <div className="col-md-3">
                <InputFileField
                    field={
                        {
                            id: 'document',
                            label: "Uploads (IF Any)",
                            type: "file",
                            value: exportbuyingdata.document,
                            required: true,
                            error: exportbuyingdata.documenterror,
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>

            <div className="col-md-6">
                <TextAreafield
                    field={
                        {
                            id: "remarks",
                            label: "Special Requirements/ Remarks",
                            type: 'text',
                            value: exportbuyingdata.remarks,
                            required: true,
                            error: exportbuyingdata.remarkserror,
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
        </div>

        <div className="cmd mt-5">
            <div></div>
            <Button variant='contained' onClick={handleFormSubmit} >
                Submit
            </Button>
        </div>


    </>

}

const Create_OtherCharges = ({ countrycurrency,
    unitItem,
    vendorIATAItem,
    chargeHeadItem,
    handleBasicInputChange,
    otherChargesDataById,
    otherChargesDetailsError,
    finalQuoteData,
    exportbuyingdata,
    OtherChargeshasUndefinedValueIndex
}) => {

    const [selectAllFreight, setSelectAllFreight] = useState(false);

    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            chargeHeadId: '',
            vendorId: '',
            currencyId: '',
            exchangeRate: '',
            selectForSelling: false,
            rate: '',
            applicableOn: '',
            unitId: '',
            qtyChargeWeight: '',
            amount: ''

        },
    ]);
    useEffect(() => {
        if (otherChargesDataById?.length !== 0) {
            let freight = otherChargesDataById?.map(item => ({
                ...item,
                key: generateUniqueKey()
            }));

            setInputFields(freight);

        }
    }, [otherChargesDataById]);




    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            chargeHeadId: '',
            vendorId: '',
            currencyId: '',
            exchangeRate: '',
            selectForSelling: false,
            rate: '',
            applicableOn: '',
            unitId: '',
            qtyChargeWeight: '',
            amount: ''
        }]);
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

        if (fieldToUpdate) {
            fieldToUpdate[field] = value;
            OtherChargeshasUndefinedValueIndex(value, field, index)


            if (fieldToUpdate['applicableOn'] === 2) {
                fieldToUpdate['amount'] = Number(fieldToUpdate.rate) * Number(fieldToUpdate.qtyChargeWeight)
            } else {
                fieldToUpdate['amount'] = Number(fieldToUpdate.rate)
            }


            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields?.map((res) => {
                return data.push(
                    {
                        id: res.id || null,
                        aeFinalQuoteSellingChargeId: exportbuyingdata?.id,
                        chargeHeadId: res.chargeHeadId,
                        vendorId: res.vendorId || null,
                        currencyId: res.currencyId,
                        exchangeRate: res.exchangeRate,
                        selectForSelling: res.selectForSelling,
                        rate: res.rate,
                        applicableOn: res.applicableOn,
                        unitId: res.unitId,
                        qtyChargeWeight: res.qtyChargeWeight,
                        amount: res.amount
                    }
                )

            })
            handleBasicInputChange(data, { id: 'otherChargeList' });
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields?.map((res) => {
            return data.push(
                {
                    id: res.id || null,
                    aeFinalQuoteSellingChargeId: exportbuyingdata?.id,
                    chargeHeadId: res.chargeHeadId,
                    vendorId: res.vendorId || null,
                    currencyId: res.currencyId,
                    exchangeRate: res.exchangeRate,
                    selectForSelling: res.selectForSelling,
                    rate: res.rate,
                    applicableOn: res.applicableOn,
                    unitId: res.unitId,
                    qtyChargeWeight: res.qtyChargeWeight,
                    amount: res.amount
                }
            )

        })
        handleBasicInputChange(data, { id: 'otherChargeList' });
    };


    return <>
        <div className='my-4'>
            <div className="d-flex align-items-center">
                {/* <input type="checkbox" className='me-3'
                    checked={selectAllFreight}
                    onChange={() => {
                        setSelectAllFreight(!selectAllFreight);
                        if (selectAllFreight) {
                            const updatedArray = inputFields.map(item => ({ ...item, selectForSelling: false }));
                            setInputFields(updatedArray)
                        }
                        else {
                            const updatedArray = inputFields.map(item => ({ ...item, selectForSelling: true }));
                            setInputFields(updatedArray)
                        }

                    }}
                /> */}
                <h6 className="m-0">OTHER CHARGES</h6>


            </div>


            {inputFields?.map((field, key) => (
                <div className='row' key={field.key}>

                    <div className="col-md-11 d-flex">
                        {/* <div>
                            <input type='checkbox' className='mt-4 me-3'
                                checked={field.selectForSelling}
                                onChange={() => handleInputChange(field.key, 'selectForSelling', !field.selectForSelling, key)}
                            />
                        </div> */}
                        <div className="row mb-3">
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "chargeHeadId",
                                            label: "Charge Heads ",
                                            value: field.chargeHeadId,
                                            required: true,
                                            error: otherChargesDetailsError['chargeHeadId' + key],
                                            item: chargeHeadItem,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            {/* <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "vendorId",
                                            label: "Vendor Name ",
                                            value: field.vendorId,
                                            required: true,
                                            error: otherChargesDetailsError['vendorId' + key],
                                            item: vendorIATAItem,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div> */}
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "currencyId",
                                            label: "Currency",
                                            value: field.currencyId,
                                            required: true,
                                            error: otherChargesDetailsError['currencyId' + key],
                                            item: countrycurrency,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: "exchangeRate",
                                            label: "Ex. Rate",
                                            type: 'number',
                                            value: field.exchangeRate,
                                            required: true,
                                            error: otherChargesDetailsError['exchangeRate' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <div className="d-flex gstWrapper">
                                    <InputField
                                        field={
                                            {
                                                id: "buyingRate",
                                                label: "Buying Rate",
                                                type: 'number',
                                                value: field.buyingRate,
                                                required: true,
                                                disable: true,
                                                error: otherChargesDetailsError['buyingRate' + key],
                                                onChange: (value, fieldChanges) => {
                                                    handleInputChange(field.key, fieldChanges.id, value, key);
                                                }
                                            }
                                        }
                                    />
                                    <InputField
                                        field={
                                            {
                                                id: "rate",
                                                label: "Rate",
                                                type: 'number',
                                                value: field.rate,
                                                required: true,
                                                error: otherChargesDetailsError['rate' + key],
                                                onChange: (value, fieldChanges) => {
                                                    handleInputChange(field.key, fieldChanges.id, value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            {/* <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "applicableOn",
                                            label: "Applicable On ",
                                            type: 'number',
                                            value: field.applicableOn,
                                            required: true,
                                            error: otherChargesDetailsError['applicableOn' + key],
                                            item: [
                                                { value: 1, name: 'Minimum' },
                                                { value: 2, name: 'Chargeable weight' },
                                            ],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div> */}
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "unitId",
                                            label: "Unit ",
                                            value: field.unitId,
                                            required: true,
                                            error: otherChargesDetailsError['unitId' + key],
                                            item: unitItem,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: "qtyChargeWeight",
                                            label: "Qty",
                                            type: 'number',
                                            value: field.qtyChargeWeight,
                                            required: true,
                                            error: otherChargesDetailsError['qtyChargeWeight' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: "amount",
                                            label: "Amount",
                                            type: 'number',
                                            value: field.amount,
                                            required: true,
                                            error: otherChargesDetailsError['amount' + key],
                                            disable: true,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            {/* <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: "",
                                            label: "Total Buying",
                                            type: 'number',
                                            value: field.length,
                                            required: true,
                                            error: otherChargesDetailsError['totalNumber' + key],
                                            disable: true,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div> */}


                        </div>
                    </div>
                    <div className="col-md-1 cmd" style={{ justifyContent: "right", alignItems: "flex-start" }}>
                        {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                            <Add />
                        </Button> : (
                            <Button
                                sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                onClick={() => removeInputField(field.key)}
                                variant='contained'
                            >
                                <Close />
                            </Button>
                        )}
                    </div>
                </div>
            ))}
        </div>

    </>
}


import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GetMasterDepartmentByPage, GetMasterDesignationByPage, GetMasterDivisionByPage, GetMasterNetworkTypeByPage, GetMasterSubGroupByPage, GetMasterVendorTypeByPage, GetVendorMasterByPage, PostMasterDepartment, PostMasterDesignation, PostMasterNetworkType, PostMasterVendorType, UpdateMasterDesignation, UpdateMasterDesignationStatus, UpdateMasterNetworkType, UpdateMasterNetworkTypeStatus, UpdateMasterVendorType, UpdateMasterVendorTypeStatus, UpdateVendorMasterStatus } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { createvendor } from '../../../navigation/constant';
import { useNavigate } from 'react-router-dom';
import { SmallSwitch } from '../../component/controls';


const VendorMaster = () => {
    const dispatch = useDispatch()
    const [vendorMasterData, setVendorMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const navigate = useNavigate()

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetVendorMasterByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);

    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            ...res,
            sn: index + 1,
            vid: 'VND-' + res.id,
        }));
        setVendorMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
        {
            id: 'vid',
            label: 'Id',
            numeric: false,
        },

        {
            id: 'legalName',
            label: 'Name',
            numeric: false,
        },
        {
            id: 'accountLedgerName',
            label: 'A/c Ledger Name',
            numeric: false,
        },
        {
            id: 'venderTypeNames',
            label: 'Vendor Type',
            numeric: false,
        },
        {
            id: 'email',
            label: 'Contact Detail',
            numeric: false,
        },


        {
            id: 'gstNo',
            label: 'Gst No.',
            numeric: false,
        },

        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];

    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateVendorMasterStatus,
                dispatch: (e) => {
                    // dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({ response }) => {
                    row.status = !row.status
                    setVendorMasterData(prevData =>
                        prevData.map(item =>
                            item.id === row.id ? { ...row } : item
                        ))
                }

            });
        }
        const editBtnClicked = () => {
            debugger
            sessionStorage.setItem('vendor', row.id);
            navigate(createvendor);
        }

        return <>
            <Tooltip title="Edit Vendor">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >

            <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>

    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <Tooltip title="Add Vendor">
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${createvendor}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }

    return (
        <>
            <MultiTable
                data={vendorMasterData}
                columns={columns}
                // filterItems={filterItems}
                title={'Vendor'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default VendorMaster


import { useEffect, useState } from "react";
import Dropdown, { AutoSelect, DateField, InputField, InputFileField } from "../../../component/controls";
import { Button } from "@mui/material";
import { GetAirlineMasterByPage, GetMasterAccountLedgerByPage } from "../../../../services/constant";
import axios from "axios";
import { AddCourierAwbStock, UpdateCourierAwbStock } from "../../../../services/APIConstant/jobConstant";
import GetCaller, { PostCaller } from "../../../../services/apiServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { courierstock } from "../../../../navigation/constant";


const CreateCarrierStock =() =>{
    const navigate =  useNavigate()
    const dispatch = useDispatch()
    const [airlineiteam ,setAirlineItem] = useState([])
 const [iatagent, setIataagent] =useState({})
 const [isedit , setEdit] = useState(false)


const [iatastockForm, setIataStockForm] = useState({
AirlineId: '',
StockDate:'',
FilePath:''
})



const handleInputChange = (value, field) => {


        
    setIataStockForm({
                ...iatastockForm,
                [field.id]: value,
                [field.id + 'error']: !value,
            });
        };
    

        const handleFormSubmit = () => {

            const hasUndefinedValue = (obj) => {
                let changes = {};
                Object.entries(obj).forEach(([key, value]) => {
                  if (
                    key === 'id' ||
                    key === "FilePath"     
                  ) {
          
                  }
                  else if (!value) {
                    changes[key + "error"] = !value;
                  }
                });
                return changes;
              };
    
            const changes = hasUndefinedValue(requestdata);

            if (Object.keys(changes).length > 0 ) {
                debugger;
                setIataStockForm({ ...iatastockForm, ...changes,
          
                });
            }
            else if (isedit) {
              
                 EditCourierStock(requestdata);
            }
            else {
                PostCourierStock(requestdata);
    
            }
    
        };
    
        const PostCourierStock = (requestdata) => {
debugger
            PostCaller({
                API: AddCourierAwbStock,
                contenttype: "multipart/form-data",
                callback: () => {
                 navigate(`${courierstock}`)
                },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: requestdata
            })
        };
    
    
    
        const EditCourierStock = (requestdata) => {
            debugger
            requestdata.id = isedit
            PostCaller({
                API: UpdateCourierAwbStock,
                contenttype: "multipart/form-data",
                callback: () => {
                 navigate(`${courierstock}`)
                },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: requestdata
            })
        };
        const set_AirlineItem = () => {
            GetCaller({
                API: GetAirlineMasterByPage,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: {
                    status: true
                },
                callback: ({ response }) => {
                    console.log('GetConsigneeSubAgencyPage', response?.data?.data?.records)
                    let data = response?.data?.data?.records
                    const item = data?.map((res) => ({
                        name: res?.legalName,
                        value: res?.id
                    }))
                    setAirlineItem(item)
                },
            })
        }


useEffect (() =>{
    debugger
        const courierstock = JSON.parse(sessionStorage.getItem("courierstock"));
        if (courierstock) {
            debugger
            setEdit(courierstock.id)
            setIataStockForm(courierstock)
            setIataStockForm({
                ...iatastockForm,
                'AirlineId':courierstock?.airlineId,
                'StockDate':courierstock?.stockDate,
                'FilePath': courierstock?.document,
                'AwbNumber': courierstock?.awbNumber,
                'VendorId': courierstock?.vendorId

            })
            sessionStorage.removeItem('courierstock')
        }
        set_AirlineItem()
},[])
const requestdata =  {
    AirlineId: iatastockForm.AirlineId,
    StockDate: iatastockForm.StockDate,
    FilePath:iatastockForm.FilePath,

}

    return(
        <>
        <div className="hr-form-box">
        {isedit ?  <h6>Edit Courier Awb Stock</h6> :  <h6>Create Courier Awb Stock</h6>}
            <div className="row">
              
                <div className=" col-md-3">
                <Dropdown
                            field={
                                {
                                    id: "AirlineId",
                                    label: ' Carrier ',
                                    type: "select",
                                    value: iatastockForm.AirlineId,
                                    required: true,
                                    error: iatastockForm.AirlineIderror,
                                    item: airlineiteam,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">

                        <DateField
                            field={
                                {
                                    id: "StockDate",
                                    label: 'Stock Date',
                                    type: "date",
                                    value: iatastockForm.StockDate,
                                    required: true,
                                    error: iatastockForm.StockDateerror,
                          
                                    onChange: handleInputChange
                                }
                            }
                        />
                        </div>
                        <div className="col-md-3">
                        <InputFileField
                                        className='mt-3'
                                        field={{
                                            id: "iatafile",
                                            label: "Upload File",
                                            type: 'file',
                                            value: iatastockForm.FilePath,
                                            required: false,
                                            error: iatastockForm.FilePatherror,                                    
                                            onChange: handleInputChange
                                        }}
                                    />
                    </div>
                </div>
            </div>
            <div className="cmd">
                <div></div>
                <Button
                    sx={{ m: 2, borderRadius: "10px" }}
                    onClick={handleFormSubmit}
                    variant="contained"
                >
                    Submit
                </Button>
            </div>
        </>
    );
    
    

}
export default CreateCarrierStock;
import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetGSAMasterByPage, GetMasterSectorByPage, PostMasterSector, UpdateMasterSector, UpdateGSAMasterStatus, UpdateSubAgentShipperStatus, GetMasterVendorTypeByPage, GetConsigneeSubAgencyPage,  PostMasterCountry, PostMasterState, PostMasterCity, UpdateConsigneeSubAgencyStatus, } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';
import {  createconsigneesubagency, creategsamaster, createtdsmaster, subagencyshipperform } from '../../../navigation/constant';

import { useDispatch } from 'react-redux';
import { CustomerTable } from '../customer/customerTable';
import CustomerContactDetail from '../customer/customerContactDetail';
import CustomerBranch from '../customer/customerBranchForm';
import CircularWithValueLabel from '../../component/field/circleProgressBar';
import { ConsigneeSubAgencyAddressForm } from './consigneeAgencyAddressform';
import GetCaller from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';

const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};

const ConsigneeAgencyList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sectorMasterData, setSectorMasterData] = useState([])
    const [countryItem, setCountryItem] = useState([]);
    const [stateData, setstateData] = useState([])
    const [cityData, setcityData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetConsigneeSubAgencyPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }



    useEffect(() => {
        fatch_MasterDataBYPagination();

        GetCaller({
            API: PostMasterCountry,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newcountrydata = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }));
                setCountryItem(newcountrydata)
            }
 
        });

        // State Data
        GetCaller({
            API: PostMasterState,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                setstateData(response?.data?.data?.records)
            }
        });

        //CityData
        GetCaller({
            API: PostMasterCity,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                setcityData(response?.data?.data?.records)
            }
        });

    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        const newmasterdata = response?.data?.data?.records.map((res, index) => ({

            sn: index + 1,
            id: res?.id,
            type: res?.type,
            name: res?.name,
            customerId: res?.customerId,
            customerName: res?.customerName,
            address1: res?.address1,
            address2: res?.address2,
            address3: res?.address3,
            countryId: res?.countryId,
            stateId: res?.stateId,
            cityId: res?.cityId,
            pinCode: res?.pinCode,
            telephone: res?.telephone,
            email: res?.email,
            eoriNo: res?.eoriNo,
            contactPerson: res?.contactPerson,
            linkedConsignee: res?.linkedConsignee,
            openAsNotify: res?.openAsNotify,
            opneAsOA: res?.opneAsOA,
            bankDetails: res?.bankDetails,
            customerBranchList: res?.consigneeSubAgentAddress,
            status: res?.status

        }));

        setSectorMasterData(newmasterdata);
        debugger
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'name',
            label: 'Name',
            numeric: false,
        },
        {
            id: 'customerName',
            label: 'Sub Agent',
            numeric: false,
        },
        {
            id: 'pinCode',
            label: 'Code',
            numeric: false,
        },
        {
            id: 'eoriNo',
            label: 'IEC No.',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        {
            id: 'subtable',
            label: '',
            numeric: false,
        },
    ];

    const subColumns = [
        {
            id: 'sn',
            label: '#',
            numeric: false,
        },
        {
            id: 'label',
            label: 'label',
            numeric: false,
        },
        {
            id: 'address1',
            label: 'Address1',
            numeric: false,
        },
        {
            id: 'email',
            label: 'Email',
            numeric: false,
        },
        {
            id: 'pinCode',
            label: 'Bank Name.',
            numeric: false,
        },
        {
            id: 'action',
            label: '',
            numeric: false,
        },

    ];

    const editAddressBtnClicked = () => {
        <ConsigneeSubAgencyAddressForm callback={fatch_MasterDataBYPagination} />
    }

    const subActionCell = ({ row }) => {
        debugger;
        sessionStorage.setItem('consigneeId', JSON.stringify(row));
        return <>
            <ConsigneeSubAgencyAddressForm 
            row={row}
            edit={true} 
            callback={fatch_MasterDataBYPagination} 
            APIDATA={{    
                stateData:stateData,
                cityData:cityData,
                countryItem:countryItem
            }}
            />
            <IconButton>
                <i className='las fa-trash'></i>
            </IconButton>

        </>
    }


    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API:   UpdateConsigneeSubAgencyStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }
        const editBtnClicked = () => {
            sessionStorage.setItem('sunAgencyShipperlist', JSON.stringify(row));
            navigate(createconsigneesubagency);
        }
        return <>
            <Tooltip title="Edit Consignee Sub Agency">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />

            <ConsigneeSubAgencyAddressForm row={row} 
            callback={fatch_MasterDataBYPagination} 
                        APIDATA={{    
                            stateData:stateData,
                            cityData:cityData,
                            countryItem:countryItem
                        }}
            />

        </>

    }

    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <Tooltip title="Add
             Consignee Sub Agency">
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${createconsigneesubagency}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }
    const conditions = {
        subColumns: subColumns,
        subActionCell: subActionCell
    }


    return (
        <>

            <CustomerTable
                data={sectorMasterData}
                conditions={conditions}
                columns={columns}
                title={'Consignee Sub Agency'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default ConsigneeAgencyList;

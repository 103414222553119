import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GetNotifyMasterByPage, UpdateNotifyMasterStatus } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { createnotify } from '../../../navigation/constant';
import { useNavigate } from 'react-router-dom';
import {columnConstant} from '../../constant/formconstant';
import { SmallSwitch } from '../../component/controls';

const Notify = () => {
    const dispatch = useDispatch()
    const [notifyData, setNotifyData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const navigate = useNavigate()

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetNotifyMasterByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id, 
            branchId: res.branchId,
            name: res.name,    
            status: res.status,      
            countryId: res.countryId,
            stateId: res.stateId,
            cityId: res.cityId,
            address1: res.address1,
            addressLabel:res.addressLabel,
            address2: res.address2,
            address3: res.address3,
            address4: res.address4,
            pinCode: res.pinCode,
            mobileNumber: res.mobileNumber,
            telephone: res.telephone,
            fax: res.fax,
            email: res.email,
            contactPerson: res.contactPerson,
            gstNumber: res.gstNumber,
            pan: res.pan,
            iec: res.iec,
            employeeId: res.employeeId,
            remarks: res.remarks
        }));
        setNotifyData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
         
        {
            id: 'name',
            label: "Name",
            numeric: false,
        },
        {
            id: 'email',
            label: columnConstant.email,
            numeric: false,
        },
      

        {
            id: 'address1',
            label: columnConstant.address1,
            numeric: false,
        },
        {
            id: 'contactPerson',
            label: columnConstant.contactPerson,
            numeric: false,
        },
        {
            id: 'action',
            label: columnConstant.action,
            numeric: false,
        },
        // You can add more column definitions as needed
    ];

    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
              id: row?.id,   
            }
            GetCaller({
              API: UpdateNotifyMasterStatus,
              dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
              },
              item: data,
              callback: ({response}) => {
                fatch_MasterDataBYPagination();
                  }
               
                });       
              }
              const editBtnClicked = () =>{ 
                debugger   
                sessionStorage.setItem('notify',row.id);
                navigate(createnotify);
            }
 
        return <>
            <Tooltip title="Update Notify List">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >

           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>

    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <Tooltip title="Add Notify List">
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${createnotify}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }

    return (
        <>
            <MultiTable
                data={notifyData}
                columns={columns}
                // filterItems={filterItems}
                title={'Notify List'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default Notify;


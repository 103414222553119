import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Button, Checkbox, Tab } from "@mui/material"
import React, { useEffect, useState } from "react"
import Dropdown, { DateField, InputField } from "../../component/controls"
import { Maintab, OtherDetails } from "./hawbtabs"
import { GetAllEmployee, GetChargeHeadByPage, GetConsigneeSubAgencyPage, GetCustomerByPage, PostHawb, PostMasterCountry, UpdateHawb } from "../../../services/constant"
import { GetAirExportPlannerDetailsJobbyId, GetConsoleAndSingleJob } from "../../../services/APIConstant/jobConstant"
import GetCaller, { PostCaller } from "../../../services/apiServices"
import { useDispatch, useSelector } from "react-redux"
import { hawb } from "../../../navigation/constant"
import { Navigate, useNavigate } from "react-router-dom"
import { AwsCharge } from "../mawbforms/mawbtabs"
import { GetAirExportPermanentJobDetailsById } from "../../../services/APIConstant/AirExport.jsx/finalJob"


const CreateHawbForm = () => {
  const branchId = useSelector(state => state.enquirybranch)
  const enquiryfinancialyear = useSelector(state => state.enquiryfinancialyear)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState('1');
  const [jobitem, setJobItem] = useState([])
  const [printsave, setPrintsave] = useState(false)
  const [removedraft, setRemovedraft] = useState(false)
  const [currencyItem, setcurrencyItem] = useState([]);
  const [employeeItem, setEmployeeItem] = useState([])
  const [chargeheadItem, setChargehead] = useState([]);
  const [awbChargesDetailsError, SetAwbChargesDetailsError] = useState({})
  const [deliveryDetailsError, setDeliveryDetailsError] = useState({});
  const [consigneeItem, setConsigneeItem] = useState([]);
  const [shipperItem, setShipperItem] = useState([])

  const [HawbForm, setHawbForm] = useState({
    jobId: "",
    airlineId: 1,
    hawbNumber: "",
    hawbDate: "",
    mawbNumber: "",
    shipperId: '',
    consigneeId: '',
    consigneeAddress: "",
    shipperAddress: "",
    mawbDate: "",
    printAfterSave: false,
    removeFromDrafts: false,
    printHawbNumber: false,
    airportOfDestination: "",
    airportOfDeparture: '',
    chargeWeight: '',
    grossWeight: '',
    hawbRate:'',
    freight:''
  })



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const handleBasicInputChange = (value, field) => {
    setHawbForm({
      ...HawbForm,
      [field.id]: value,
      [field.id + 'error']: !value
    })
  }







  const requestdata = (() => {
    const data = {
      hawb: {
        branchId: branchId,
        financialYear: enquiryfinancialyear,
        jobId: HawbForm.jobId,
        shipperId: HawbForm.shipperId,
        consigneeId: HawbForm.consigneeId,
        shipperAddress: HawbForm.shipperAddress,
        consigneeAddress: HawbForm.consigneeAddress,
        hawbNumber: HawbForm.hawbNumber,
        hawbDate: HawbForm.hawbDate,
        grossWeight: HawbForm.grossWeight,
        chargeWeight: HawbForm.chargeWeight,
        airportOfDeparture: HawbForm.airportOfDeparture,
        airportOfDestination: HawbForm.airportOfDestination,
        salesPerson: HawbForm.salesPerson,
        freight: HawbForm.freight,
        currency: HawbForm.currency,
        kgOrLb: HawbForm.kgOrLb,
        byFirstDueCarrier: HawbForm.byFirstDueCarrier,
        requestedFlight: HawbForm.requestedFlight,
        flightDate: HawbForm.flightDate,
        issuingDueCarrierAgentName: HawbForm.issuingDueCarrierAgentName,
        agentIataCode: HawbForm.agentIataCode,
        accountingInformation: HawbForm.accountingInformation,



      },

      awbCharges: HawbForm.awbCharges,
      deliveryAddresses: HawbForm.DeliveryAddressList,

      dimensionList: [
        {
          length: 0,
          width: 0,
          height: 0,
          box: 0,
          volume: 0
        }
      ],
      // deliveryAddresses: [
      //   {
      //     address: "string",
      //     packages: 0,
      //     grossWeight: 0,
      //     airVolumeWeight: 0,
      //     dimensions: 0
      //   }
      // ],

    };
    // .......
    if (HawbForm.mawbDate) data.hawb.mawbDate = HawbForm.mawbDate
    if (HawbForm.mawbNumber) data.hawb.mawbNumber = HawbForm.mawbNumber
    if (HawbForm.notifyId) data.hawb.notifyId = HawbForm.notifyId
    if (HawbForm.destinationId) data.hawb.destinationId = HawbForm.destinationId
    if (HawbForm.destination) data.hawb.destination = HawbForm.destination
    if (HawbForm.styleNumber) data.hawb.styleNumber = HawbForm.styleNumber
    if (HawbForm.publishRate) data.hawb.publishRate = HawbForm.publishRate
    if (HawbForm.rateClass) data.hawb.rateClass = HawbForm.rateClass
    if(HawbForm.hawbRate) data.hawb.hawbRate = HawbForm.hawbRate
    if (HawbForm.commodityItemNumber) data.hawb.commodityItemNumber = HawbForm.commodityItemNumber
    if (HawbForm.declaredValueForInputCarriage) data.hawb.declaredValueForInputCarriage = HawbForm.declaredValueForInputCarriage
    if (HawbForm.declaredValueForCustom) data.hawb.declaredValueForCustom = HawbForm.declaredValueForCustom
    if (HawbForm.ammountOfInsurance) data.hawb.ammountOfInsurance = HawbForm.ammountOfInsurance

    if (HawbForm.routeOneTo) data.hawb.routeOneTo = HawbForm.routeOneTo
    if (HawbForm.routeOneBy) data.hawb.routeOneBy = HawbForm.routeOneBy
    if (HawbForm.routeTwoTo) data.hawb.routeTwoTo = HawbForm.routeTwoTo
    if (HawbForm.routeTwoBy) data.hawb.routeTwoBy = HawbForm.routeTwoBy

    if (HawbForm.tariffRateType) data.hawb.tariffRateType = HawbForm.tariffRateType
    if (HawbForm.tariffRate) data.hawb.tariffRate = HawbForm.tariffRate

    if (HawbForm.accountNumber) data.hawb.accountNumber = HawbForm.accountNumber

    if (HawbForm.handlingInformation) data.hawb.handlingInformation = HawbForm.handlingInformation
    if (HawbForm.natureAndQuantityOfGoods) data.hawb.natureAndQuantityOfGoods = HawbForm.natureAndQuantityOfGoods
    if (HawbForm.descriptionOfGoods) data.hawb.descriptionOfGoods = HawbForm.descriptionOfGoods


    return data;
  })();
  const isEdit = false



  const handleFormSubmit = () => {

    const awbChargesUndefinedValues = (array) => {
      let data = array.length !== 0 ? array : [
        {
          chargeId: '',
          printLabel: '',
          amount: '',
          gst: '',
          due: '',
        }
      ]

      let changes = {};
      data.map((obj, index) => {
        Object.entries(obj).forEach(([key, value]) => {

          if (
            key === 'id' ||
            key === 'gst' ||
            key === 'due'
          ) {

          } else if (!value) {
            changes[key + index] = !value;

          }
        });
      })
      console.log('changes', changes)

      return changes;
    }

    const deliveryAddChargesUndefinedValues = (array) => {
      let data = array.length !== 0 ? array : [
        {
          address: "",
          packages: "",
          grossWeight: "",
          airVolumeWeight: "",
          dimensions: ""
        }
      ]

      let changes = {};
      data.map((obj, index) => {
        Object.entries(obj).forEach(([key, value]) => {

          if (
            key === 'airVolumeWeight' ||
            key === 'dimensions'

          ) {

          } else if (!value) {
            changes[key + index] = !value;

          }
        });
      })
      console.log('changes', changes)

      return changes;
    }




    debugger;




    const changes = handleSubmit(requestdata.hawb);
    const awbChargesChanges = awbChargesUndefinedValues(requestdata.awbCharges || [])
    const deliveryAddChargeChanges = deliveryAddChargesUndefinedValues(requestdata.deliveryAddresses || [])
    if (Object.keys(changes).length > 0 ||
      Object.keys(deliveryAddChargeChanges).length > 0 ||
      Object.keys(awbChargesChanges).length > 0) {
      SetAwbChargesDetailsError(awbChargesChanges)
      setDeliveryDetailsError(deliveryAddChargeChanges)
      setHawbForm({ ...HawbForm, ...changes });
    }

    else if (isEdit) {
      // EditUpdateHawbSubmit(requestdata);
    }
    else {
      AddPostHawbSubmit(requestdata)
    }
  };

  const AddPostHawbSubmit = (requestdata) => {

    PostCaller({
      API: PostHawb,
      callback: () => {
        navigate(`${hawb}`)
      },
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: requestdata
    })
  };


  useEffect(() => {
    set_JobItem();
    set_Chargehead();
    set_CountryItemData();
    set_consigneeItem();
    set_customerData();

    GetCaller({
      API: GetAllEmployee,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {

        console.log('Sales Person', response?.data?.data)
        const data = response?.data?.data?.records

        const employeelist = data?.map(res => ({
          value: res.id,
          name: res.name
        }))
        setEmployeeItem(employeelist)
        console.log("employeeList", employeelist)
      }

    });
  }, [])

  const set_JobItem = () => {
    GetCaller({
      API: GetConsoleAndSingleJob,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        debugger
        const data = response?.data?.data?.singleJobs.map((item) => ({
          value: item,
          name: item
        }))
        setJobItem(data)
      }
    })
  }


  const set_Chargehead = () => {
    GetCaller({
      API: GetChargeHeadByPage,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        const newchargeheaditeam = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.chargeName,
          taxSlab: item.taxSlab,
          due: item.due,

        }))
        console.log(newchargeheaditeam)
        setChargehead(newchargeheaditeam)
      }

    })
  }



  const set_CountryItemData = () => {
    GetCaller({
      API: PostMasterCountry,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        const newCityItemData = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.currencyCode
        }))
        setcurrencyItem(newCityItemData)
      }

    })
  }

  const set_consigneeItem = () => {
    GetCaller({
      API: GetConsigneeSubAgencyPage,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        const newConsigneeItemData = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.name,
          address1: item.address1,
          address2: item.address2,
          address3: item.address3,
        }))
        setConsigneeItem(newConsigneeItemData)
      }

    })
  }


  const set_customerData = () => {
    debugger;
    GetCaller({
      API: GetCustomerByPage,
      dispatch: (e) => {
        dispatch({ type: e.type, paylode: e.paylode })
      },
      item: {},
      callback: ({ response }) => {
        const newSalesperson = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.legalName
        }))

        setShipperItem(newSalesperson)
      }
    })
  }

  const set_JobItembyID = (id) => {
    if (id) {
      GetCaller({
        API: GetAirExportPermanentJobDetailsById,
        dispatch: (e) => {
          dispatch({ type: e.type, payload: e.payload })
        },
        item: { id: id },
        callback: ({ response }) => {
          debugger
          const data = response?.data?.data

          console.log('Permanent Job Detail By Id', data)

          setHawbForm({
            ...HawbForm,
            'jobId': id,
            'jobIderror': !id,

            'shipperId': data?.shipperId || data?.subAgent,
            "shipperName": data?.shipperName,

            "consigneeId": data?.consignee,
            "consigneeName": data?.consigneeName,

            "notifyId": data?.notifyParty,
            "notifyName": data?.notifyName,

            "grossWeight": data?.grossWeight,
            'chargeWeight': data?.qtyChargeWeight,

            "airportOfDeparture": data?.airportOfLoading,
            "airportOfDestination": data?.airportOfDestination,


          })

        }
      })
    }
  }



  const handleSubmit = (obj) => {
    debugger
    let changes = {};
    Object.entries(obj).forEach(([key, value]) => {
      if ((key !== 'jobId' || key !== 'status' || key !== "consigneeId" || key === 'branchId' || key === 'financialYear' ||
        key === 'status') && !value) {
        changes[key] = value;
        changes[key + 'error'] = !value;
      }
    });
    return changes;
  };



  return (
    <>
      <div className="hr-form-box">
        <h6>Create HAWB</h6>
        <div className="row">

          <div className="col-md-3">
            <Dropdown
              field={

                {
                  id: 'jobId',
                  label: 'Job No. ',
                  type: 'select',
                  value: HawbForm.jobId,
                  required: true,
                  error: HawbForm.jobIderror,
                  item: jobitem,
                  onChange: (value, field) => {
                    set_JobItembyID(value)
                  }

                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'hawbNumber',
                  label: 'HAWB No.',
                  type: "number",
                  value: HawbForm.hawbNumber,
                  error: HawbForm.hawbNumbererror,
                  required: true,
                  onChange: handleBasicInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <DateField
              format='YYYY-MM-DD'
              field={{
                id: "hawbDate",
                label: "HAWB DATE ",
                value: HawbForm?.hawbDate,
                error: HawbForm?.hawbDateerror,
                onChange: handleBasicInputChange
              }}
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'mawbNumber',
                  label: 'MAWB No.',
                  type: "number",
                  value: HawbForm.mawbNumber,
                  required: false,
                  disable: true,
                  error: HawbForm.mawbNumbererror,
                  onChange: handleBasicInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <DateField
              format='YYYY-MM-DD'
              field={{
                id: "mawbDate",
                label: "MAWB DATE ",
                value: HawbForm?.mawbDate,
                disable: true,
                error: HawbForm?.mawbDateerror,
                onChange: handleBasicInputChange
              }}
            />
          </div>



          <div className="row">
            <div className="col-md-3 mt-2">
              <Checkbox
                checked={HawbForm.printAfterSave}
                onChange={() => { handleBasicInputChange(!HawbForm.printAfterSave, { id: "printAfterSave" }) }}

              />
              {"Print After Save"}
            </div>
            <div className="col-md-3 mt-2">
              <Checkbox
                checked={HawbForm.removeFromDrafts}
                onChange={() => { handleBasicInputChange(!HawbForm.removeFromDrafts, { id: "removeFromDrafts" }) }}
              />
              {"Remove From Draft"}s
            </div>
          </div>
        </div>


      </div>
      <div className="hr-form-box">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="MAIN DETAILS" value="1" />
                <Tab label="OTHER DETAILS" value="2" />
                <Tab label="AWB CHARGES" value="3" />


              </TabList>
            </Box>
            <TabPanel value="1">
              <Maintab
                HawbForm={HawbForm}
                handleBasicInputChange={handleBasicInputChange}
                consigneeItem={consigneeItem}
                shipperItem={shipperItem}
                salesPerson={employeeItem}
              />
            </TabPanel>
            <TabPanel value="2"><OtherDetails
              HawbForm={HawbForm}
              currencyItem={currencyItem}
              handleBasicInputChange={handleBasicInputChange}
              setDeliveryDetailsError={setDeliveryDetailsError}
              deliveryDetailsError={deliveryDetailsError}
            />
            </TabPanel>
            <TabPanel value="3">
              {/* <AwsCharge
                MawbForm={HawbForm}
                handleBasicInputChange={handleBasicInputChange}
                chargeheadItem={[]}
                awbChargesDetailsError={{}}
      

              /> */}

              {AwsCharge({
                chargeheadItem, HawbForm, handleBasicInputChange, awbChargesDetailsError, SetAwbChargesDetailsError
              })}



            </TabPanel>

          </TabContext>


        </Box>
        <div className="cmd">
          <div></div>
          <Button className='mx-3' variant='contained' onClick={handleFormSubmit}>submit</Button>
        </div>
      </div>

    </>
  )
}

export default CreateHawbForm;



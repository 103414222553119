


import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { columnConstant } from "../../constant/formconstant";
import MapMenu, { MultiTable } from '../../component/multiTable';
import CircularWithValueLabel from '../../component/field/circleProgressBar';
import { GetNewLeadsByPage, UpdateGSAMasterStatus } from '../../../services/constant';
import axios from 'axios';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { createenquiry, createfinalquote, createinitialquote, enquiryvendorpdf, pdfaircustomer } from '../../../navigation/constant';
import { useNavigate } from 'react-router-dom';
import { GetAirExportEnquiryDataByPage, UpdateEnquiryExportStatus } from '../../../services/APIConstant/enquiryConstant';
import { EnquiryStatus } from '../../../services/modalData';
import Transition from '../../../services/utilities/transition';
import { Close, Hail, Wallet } from '@mui/icons-material';
import { PostCaller } from '../../../services/apiServices';
import Dropdown from '../../component/controls';
import { CustomerTable } from '../customer/customerTable';
import destination from '../../assets/img/icons/destination.png';
const AirExportEnquiryList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [MarketingLeadData, setMarketingLeadData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const [closeValidator, setcloseValidator] = useState(false)
    const [isEnquiryStatus, setIsEnquiryStatus] = useState(false);
    const [CurrentEnqId, setCurrentEnqId] = useState(false);
    const fatchAirEnquiryDataByPagination = (item) => {
        MasterCallBypagination({
            API: GetAirExportEnquiryDataByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatchAirEnquiryDataByPagination();
        sessionStorage.removeItem('enquiryInitialQuoteData');
        sessionStorage.removeItem('exportEnquiryviewquotedata');
        sessionStorage.removeItem('viewExportEnquiry');
        sessionStorage.removeItem('airExportEnquiryData');
        sessionStorage.removeItem('enquiryFinalQuoteData');

    }, []);




    const SplitActionHandler = (row) => {
        return <>
            <Tooltip title="Pricing">
                <IconButton
                    className='tablebtn1'
                    onClick={() => {
                        sessionStorage.setItem('enquiryFinalQuoteData', JSON.stringify(row));
                        navigate(createfinalquote)
                    }}
                >
                    <i className="las la-wallet"></i>
                </IconButton>
            </Tooltip >

        </>
    }




    // checking same values 
    function areAllSame(subTable, key) {
        if (subTable.length !== 0) {
            const firstValue = subTable[0][key];
            return subTable.every(item => item[key] === firstValue);
        } else {
            return false
        }

    }




    const setTableDataHandler = ({ response }) => {

        const intialValueHandler = (actual, final) => {
            if (actual == final || !final) {
                return actual || '0'
            } else {
                return `${actual || 0} / ${final || 0}`
            }
        }

        let newMarketingLeadData = response?.data?.data?.aeEnquiry?.map((res, index) => ({
            ...res,
            className: res?.isRepricing ? 'rePricingDiff' : res?.isPlanForToday ? 'planforToday' : res?.splitAEEnquiry?.length !== 0 ? 'splitStyle' : res?.consoleEnquiry && res?.consoleEnquiry?.length !== 0 && 'consoleStyle',
            sn: 'd',
            id: res?.id,
            eid: 'AE-' + res?.id,
            customerType: res?.customerType,
            freightServiceType: res?.freightServiceType,
            freightServiceSubType: res?.freightServiceSubType,
            bizCategoryType: res?.bizCategoryType,
            bizType: res?.bizType,
            bizSubType: res?.bizSubType,
            marketedBy: res?.marketedBy,
            marketedByName: res?.marketedByName,
            customer: res?.customer,
            customerName: res?.customerName,
            bizGenerator: res?.bizGenerator,
            accountLedger: res?.accountLedger,
            accountLedgerName: res?.accountLedgerName,
            packages: res?.packages,


            initialPackages: intialValueHandler(res?.packages, res?.finalPackages),
            initialGrossWeight: intialValueHandler(res?.grossWeight, res?.finalGrossWeight),
            initialQtyChargeWeight: intialValueHandler(res?.qtyChargeWeight, res?.finalQtyChargeWeight),
            initialAirVolumeWeight: intialValueHandler(res?.airVolumeWeight, res?.finalAirVolumeWeight),


            typeOfPackages: res?.typeOfPackages,
            typeOfPackagesOther: res?.typeOfPackagesOther,
            grossWeight: res?.grossWeight,
            dimention: res?.dimention,
            airVolumeWeight: res?.airVolumeWeight,
            commodity: res?.commodity,
            cbm: res?.cbm,
            airportOfLoading: res?.airportOfLoading,
            airportOfLoadingName: res?.airportOfLoadingName,
            airportOfDestinationName: res?.airportOfDestinationName,
            airportOfDestination: res?.airportOfDestination,
            tentativeDateOfShipment: res?.tentativeDateOfShipment,
            deliveryAddressList: res?.deliveryAddressList,
            pickupAddressList: res?.pickupAddressList,
            dimentionFile: res?.dimentionFile,
            dimensionsList: res?.dimensionsList,
            remarks: res?.remarks,
            document: res?.document,
            hiddenDangerousGood: res?.hiddenDangerousGood,
            msdsApproval: res?.msdsApproval,
            unNumber: res?.unNumber,
            msdsDocument: res?.msdsDocument,
            oddDimension: res?.oddDimension,
            routingAndTransitRequirement: res?.routingAndTransitRequirement,
            enquiryStatus: res?.enquiryStatus,
            branch: res?.branch,
            division: res?.division,
            financialYear: res?.financialYear,
            createdBy: res?.createdBy,
            createdDate: res?.createdDate,
            updatedBy: res?.updatedBy,
            updatedDate: res?.updatedDate,
            enquiryDate: res?.enquiryDate,
            status: res?.status,
            cargoList: res?.cargoList,
            splitAEEnquiry: res?.splitAEEnquiry,
            consoleEnquiry: res?.consoleEnquiry,
            splitAction: SplitActionHandler(res),
            customerBranchList: res?.splitAEEnquiry.length !== 0 ?
                res?.splitAEEnquiry?.map(item => ({
                    ...item,
                    splitAction: SplitActionHandler(res, item),
                    apoltoapod: (
                        <div className="apol_apod">
                            {item?.airportOfLoadingName} <img style={{ width: "20px", padding: "0 2px" }} src={destination} /> {item?.airportOfDestinationName}
                        </div>
                    ),
                    initialPackages: intialValueHandler(item?.packages, item?.finalPackages),
                    initialGrossWeight: intialValueHandler(item?.grossWeight, item?.finalGrossWeight),
                    initialQtyChargeWeight: intialValueHandler(item?.qtyChargeWeight, item?.finalQtyChargeWeight),
                    initialAirVolumeWeight: intialValueHandler(item?.airVolumeWeight, item?.finalAirVolumeWeight),


                })) :
                res?.consoleEnquiry?.map(item => ({
                    ...item,
                    splitAction: SplitActionHandler(res, item),
                    apoltoapod: (
                        <div className="apol_apod">
                            {item?.airportOfLoadingName} <img style={{ width: "20px", padding: "0 2px" }} src={destination} /> {item?.airportOfDestinationName}
                        </div>
                    ),
                    initialPackages: intialValueHandler(item?.packages, item?.finalPackages),
                    initialGrossWeight: intialValueHandler(item?.grossWeight, item?.finalGrossWeight),
                    initialQtyChargeWeight: intialValueHandler(item?.qtyChargeWeight, item?.finalQtyChargeWeight),
                    initialAirVolumeWeight: intialValueHandler(item?.airVolumeWeight, item?.finalAirVolumeWeight),


                }))
            ,
            courierVolumeWeight: res?.courierVolumeWeight,
            enquiryStatus: <span className={`quote-title`} style={
                { borderColor: res?.enquiryStatus === 5 ? "green" : '', color: res?.enquiryStatus === 5 ? "green" : '' }
            } onClick={() => { setIsEnquiryStatus(res?.enquiryStatus); setCurrentEnqId(res?.id); }}> {EnquiryStatus[res?.enquiryStatus - 1]?.name}</span>,
            apoltoapod: (
                <div className="apol_apod">
                    {res?.airportOfLoadingName} <img style={{ width: "20px", padding: "0 2px" }} src={destination} /> {res?.airportOfDestinationName}
                </div>
            ),

        }));

        newMarketingLeadData?.forEach(obj => {
            const subTable = obj.splitAEEnquiry.length !== 0 ? obj.splitAEEnquiry : obj.consoleEnquiry;
            obj.allCustomerNameSame = areAllSame(subTable, 'customerName');
            obj.airportOfLoadingNameSame = areAllSame(subTable, 'airportOfLoadingName');
            obj.airportOfDestinationNameSame = areAllSame(subTable, 'airportOfDestinationName');
            obj.bizSubTypeSame = areAllSame(subTable, 'bizSubType');
            obj.enquiryIdSame = areAllSame(subTable, 'enquiryId')


        });


        setMarketingLeadData(newMarketingLeadData);
        setTotalDataLength(response?.data?.data?.totalLength)
    }


    const handleClose = () => {
        setIsEnquiryStatus(false)
    }

    const handleSubmit = () => {
        PostCaller({
            API: UpdateEnquiryExportStatus,
            callback: () => {
                setIsEnquiryStatus(false)
                fatchAirEnquiryDataByPagination();
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            contenttype: false,
            item: {
                aeEnquiryId: CurrentEnqId,
                enquiryStatus: isEnquiryStatus,
            }
        })
    }

    const columns = [

        {
            id: 'eid',
            label: columnConstant.id,
            numeric: false,
        },
        {
            id: 'customerName',
            label: columnConstant.LegalName,
            numeric: false,
        },
        {
            id: 'accountLedgerName',
            label: 'Ledger',
            numeric: false,
        },
        {
            id: 'apoltoapod',
            label: 'APOL - APOD',
            numeric: false,
        },
        {
            id: 'initialGrossWeight',
            label: columnConstant.grosswt,
            numeric: false,
        },
        {
            id: 'initialPackages',
            label: columnConstant.pkgs,
            numeric: false,
        },
        {
            id: 'enquiryDate',
            label: columnConstant.enquirydate,
            numeric: false,
        },
        {
            id: 'tentativeDateOfShipment',
            label: 'T. Date',
            numeric: false,
        },
        {
            id: 'marketedByName',
            label: columnConstant.marketedby,
            numeric: false,
        },
        {
            id: 'bizSubType',
            label: columnConstant.bizsubtype,
            numeric: false,
        },
        {
            id: 'enquiryStatus',
            label: columnConstant.status,
            numeric: false,
        },
        {
            id: 'splitAction',
            label: '',
            numeric: false,
        },
        {
            id: 'action',
            label: columnConstant.action,
            numeric: false,
            rendor: (row) => ActionCell({ row })


        },
        // You can add more column definitions as needed
    ];


    const drawerFormFields = {
        title: "Add Lead",
        tooltitle: "Add Lead",
        defaultbtnfalse: true,
        width: 450,
        leadform: true,
    }




    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const updatedStatus = !row?.status;
            const data = {
                id: row?.id,
                status: updatedStatus
            }
        }
        const editBtnClicked = () => {

        }
        return <>
            <div className='cmd'>
                {/* <div className='me-4'>
                    <CircularWithValueLabel />
                </div> */}

                <Tooltip title="Edit Enquiry">
                    <IconButton
                        className='tablebtn1'
                        onClick={() => {
                            sessionStorage.setItem('airExportEnquiryData', JSON.stringify(row));
                            navigate(createenquiry)
                        }}
                    >
                        <i className="las la-edit"></i>
                    </IconButton>
                </Tooltip >

                <Tooltip title="View Enquiry">
                    <IconButton
                        className='tablebtn1'
                        onClick={() => {
                            sessionStorage.setItem('viewExportEnquiry', JSON.stringify(row));
                            navigate(enquiryvendorpdf)
                        }}
                    >
                        <i className="las la-file-alt"></i>
                    </IconButton>
                </Tooltip >
                <Tooltip title="Initial Quote">
                    <IconButton
                        className='tablebtn1'
                        onClick={() => {
                            sessionStorage.setItem('enquiryInitialQuoteData', JSON.stringify(row));
                            navigate(createinitialquote)
                        }}
                    >
                        <i className="las la-rupee-sign"></i>
                    </IconButton>
                </Tooltip >
                <Tooltip title="View Quote">
                    <IconButton
                        className='tablebtn1'
                        onClick={() => {
                            sessionStorage.setItem('exportEnquiryviewquotedata', JSON.stringify(row));
                            navigate(pdfaircustomer)
                        }}
                    >
                        <i className="las la-directions"></i>
                    </IconButton>
                </Tooltip >


                <Tooltip title="Final Quote">
                    <IconButton
                        className='tablebtn1'
                        onClick={() => {
                            sessionStorage.setItem('enquiryFinalQuoteData', JSON.stringify(row));
                            navigate(createfinalquote)
                        }}
                    >
                        <i className="las la-wallet"></i>
                    </IconButton>
                </Tooltip >

                {/*             
            <Tooltip title="Edit">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >
            <Tooltip title="Initial GP">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-bookmark"></i>
                </IconButton>
            </Tooltip >
            <Tooltip title="Chat">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las chat la-comment"></i>
                </IconButton>
            </Tooltip >
            <Tooltip title="Follow-Up">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-phone-square"></i>
                </IconButton>
            </Tooltip > */}
            </div>
        </>

    }


    const TableTool = ({ filterCallback }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [anchorE2, setAnchorE2] = useState(null);

        return <>
            {
                columns ? <Tooltip title="Filter">
                    <IconButton
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        aria-controls={anchorEl ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? 'true' : undefined}
                    >
                        <FilterListIcon />
                    </IconButton>
                </Tooltip> : false
            }
            {/* new leadfilter */}
            {
                conditions?.shipmentfilter ? <Tooltip title="Shipment Filter">
                    <IconButton
                        onClick={(e) => setAnchorE2(e.currentTarget)}
                        aria-controls={anchorE2 ? 'Shipment-Filter' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorE2 ? 'true' : undefined}
                    >
                        <i className='las la-filter '></i>
                    </IconButton>
                </Tooltip> : false
            }

            <MapMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuItems={columns} filterCallback={filterCallback} />
            <MapMenu anchorEl={anchorE2} setAnchorEl={setAnchorE2} menuItems={conditions?.shipmentfilter} />
            <Tooltip title="Create Enquiry">
                <IconButton
                    className='table-toggle'
                    onClick={() => {
                        navigate(createenquiry)
                    }}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >
        </>
    }



    const TableBody = (row, index) => {
        return <>
            {
                index === 0 && <tr>
                    <th></th>
                </tr>
            }


            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                sx={{ cursor: 'pointer', background: "#fff" }}
                // job-tr-style
                className={`${row?.className ? row?.className : ''} jobTable `}
            >
                {
                    row?.customerBranchList.length !== 0 ? (

                        columns.map((column) => (
                            column.rendor ?
                                <TableCell className={`${column.id === 'action' ? 'modifyRightBorder' : ''}`} key={row.id} component="td" scope="row" align={'left'}>
                                    {column.rendor(row)}
                                </TableCell>
                                : column.id === 'eid' ?
                                    <TableCell className={`${column.id === 'eid' ? 'modifyBorder' : ""}`} key={row.id} component="td" scope="row" align={'left'}>
                                        {row?.eid}
                                    </TableCell>
                                    : column.id === 'customerName' && row?.allCustomerNameSame ?
                                        <TableCell key={row.id} component="td" scope="row" align={'left'}>
                                            {row?.customerName}
                                        </TableCell>

                                        : column.id === 'apoltoapod' && row?.airportOfDestinationNameSame && row?.airportOfLoadingNameSame ?
                                            <TableCell key={row.id} component="td" scope="row" align={'left'}>
                                                {row?.apoltoapod}
                                            </TableCell>
                                            : column.id === 'enquiryStatus' ?
                                                <TableCell key={row.id} component="td" scope="row" align={'left'}>
                                                    {row?.enquiryStatus}
                                                </TableCell>
                                                : column.id === 'stockProviderName' ?
                                                    <TableCell key={row.id} component="td" scope="row" align={'left'}>
                                                        {row?.stockProviderName}
                                                    </TableCell>
                                                    : column.id === 'vendorName' ?
                                                        <TableCell key={row.id} component="td" scope="row" align={'left'}>
                                                            {row?.vendorName}
                                                        </TableCell> :
                                                        column.id === 'airlineName' ?
                                                            <TableCell key={row.id} component="td" scope="row" align={'left'}>
                                                                {row?.airlineName}
                                                            </TableCell> :
                                                            column.id === 'bookingWithALModify' ?
                                                                <TableCell key={row.id} component="td" scope="row" align={'left'}>
                                                                    {row?.bookingWithALModify}
                                                                </TableCell>
                                                                : column.id === 'enquiryModifyId' && row.enquiryIdSame ?
                                                                    <TableCell key={row.id} component="td" scope="row" align={'left'}>
                                                                        {row?.enquiryModifyId}
                                                                    </TableCell> :
                                                                    column.id === 'quatationId' ?
                                                                        <TableCell key={row.id} component="td" scope="row" align={'left'}>
                                                                            {row?.quatationId}
                                                                        </TableCell> :

                                                                        <TableCell className={`${column.id === 'eid' ? 'modifyBorder' : ""}`} sx={{ padding: "0" }} key={row.id} component="td" scope="row" align={row.numeric ? 'right' : 'left'}>
                                                                            {
                                                                                row.customerBranchList?.map((res) => (

                                                                                    column.rendor ?
                                                                                        <tr className='jobTable_tr'>
                                                                                            {column.rendor(res)}
                                                                                        </tr>
                                                                                        :
                                                                                        res[column.id] ?
                                                                                            <tr className='jobTable_tr'>
                                                                                                {res[column.id] || 'N/A'}
                                                                                            </tr>
                                                                                            : <tr className='jobTable_tr'>
                                                                                                N/A
                                                                                            </tr>
                                                                                ))
                                                                            }
                                                                        </TableCell>

                        ))) :

                        columns.map((column) => (


                            column?.rendor ?
                                <TableCell align='left' className={`${column.id === 'action' ? 'modifyRightBorder' : ''}`}>
                                    {column.rendor(row)}
                                </TableCell> :
                                row[column.id] ?
                                    <TableCell
                                        key={column.id}
                                        component="td"
                                        scope="row"
                                        align={column.numeric ? 'right' : 'left'}
                                        className={`${column.id === 'eid' ? 'modifyBorder' : ''}`}
                                    >
                                        {
                                            row[column.id] || 'N/A'
                                        }

                                    </TableCell>
                                    : <TableCell>
                                        N/A
                                    </TableCell>
                        ))
                }




            </TableRow>


            <div className={` job-card_tr-bar ${row?.className ? row?.className : ''} `}>
                <ul>
                    <li>PKGS: {row?.initialPackages}</li>
                    <li>GRWT:  {row?.initialGrossWeight}</li>
                    <li>CHWT:{row?.initialQtyChargeWeight}</li>
                    <li>VOLWT:{row?.initialAirVolumeWeight}</li>
                </ul>
            </div>
        </>
    }

    const conditions = {
        multiselect: false,
        tableBody: TableBody,
        mainBodyStyle: { boxShadow: "none", background: "transparent" },
        isdesignEditable: true,
        tablebarclass: 'leadtablebar',

        // subColumns: subColumns,
        // subActionCell: subActionCell,
        // shipmentfilter: [
        //     { id: 1, label: 'Air Import' },
        //     { id: 2, label: 'Air Export' },
        // ],
        // sendleadfilter: [
        //     { id: 1, label: 'Mayank' },
        //     { id: 2, label: 'Sachin' },
        //     { id: 3, label: 'Aditi' },
        //     { id: 4, label: 'Akash' },
        // ],
        // followup: {
        //     title: "Add Lead",
        //     tooltitle: "Follow Up",
        //     iconclass: "tablebtn1",
        //     width: 470,
        //     icon: <i className='las  la-phone-square '></i>,
        //     leadtab: true,
        //     defaultbtnfalse: true
        // },
        // modifylead: {
        //     title: "Modify Lead",
        //     defaultbtnfalse: true,
        //     tooltitle: "Modify Lead",
        //     width: 450,
        //     leadform: true,
        //     icon: <i className="las la-edit"></i>,
        //     iconclass: "tablebtn1",
        // }

    }

    return (
        <>
            <div className='cmd'>
                <div className='planner-table' style={{ width: 'calc(100%)' }}>
                    {/* <CustomerTable
                        title={'Air Enquiry'}
                        data={MarketingLeadData}
                        columns={columns}
                        drawerFormFields={drawerFormFields}
                        conditions={conditions}
                        tabletool={TableTool}
                        actioncell={ActionCell}
                        totaldatalength={totaldatalength}
                        paginationhandler={fatchAirEnquiryDataByPagination}


                    /> */}

                    <MultiTable
                        data={MarketingLeadData}
                        conditions={conditions}
                        columns={columns}
                        title={'Air Enquiry'}
                        tabletool={TableTool}
                        totaldatalength={totaldatalength}
                        paginationhandler={fatchAirEnquiryDataByPagination}
                    />
                </div>
                {/* <div style={{ width: '370px' }}><LeadTab></LeadTab></div> */}
            </div>



            {
                isEnquiryStatus &&
                <Dialog
                    open={isEnquiryStatus}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{
                        sx: {
                            width: "450px",
                            maxWidth: "450px!important",
                        },
                    }}
                    sx={{
                        '& .MuiDialog-container': {
                            alignItems: "center"
                        }
                    }
                    }


                >
                    <div className=''>
                        <DialogTitle>
                            <div className="cmd">

                                <div className='quote_pop_title'>
                                    <h5>{'Status'}</h5>
                                </div>
                                <Tooltip title='Close'>
                                    <IconButton onClick={handleClose}>
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <hr />
                        </DialogTitle>
                        <DialogContent>

                            <div className=''>
                                <Dropdown
                                    className='mt-3'
                                    field={
                                        {
                                            id: "status",
                                            label: "Status",
                                            value: isEnquiryStatus,
                                            required: true,
                                            error: false,
                                            item: EnquiryStatus,
                                            onChange: (value) => {
                                                setIsEnquiryStatus(value)
                                            }
                                        }
                                    }
                                />
                            </div >
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                            <Button onClick={handleSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
                        </DialogActions>
                    </div>
                </Dialog>
            }
        </>
    )
}

export default AirExportEnquiryList;







import { useEffect, useState } from "react";
import Dropdown, { AutoSelect, DateField, InputField, InputFileField, TextAreafield } from "../../../component/controls";
import { Button } from "@mui/material";
import { GetAirlineMasterByPage, GetMasterAccountLedgerByPage, GetVendorMasterByPage, PostVendorMaster } from "../../../../services/constant";
import axios from "axios";
import { AddHoldAwb, AddIataStock, AddMoveAwb, UpdateIataStock } from "../../../../services/APIConstant/jobConstant";
import { awbstock, iatastock } from "../../../../navigation/constant";
import GetCaller, { PostCaller } from "../../../../services/apiServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { currentStockType, listofHoldReasons } from "../../../../services/modalData";


const CreateHoldAwb = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
debugger
    
    const holdawb = JSON.parse(sessionStorage.getItem("holdawb"));
    const moveawb = JSON.parse(sessionStorage.getItem("moveawb"));

    const [airlineItem, setAirlineItem] = useState([])
    const [vendorItem, setVendorItem] = useState([])

    const [holdawbForm, setHoldawbForm] = useState({
        awbNumber: holdawb?.awbNumber ||"",
        holdReason:  "",
        remarks: "",
        currentStockType: 1,
        moveStockTo:"",
        stockNumber: moveawb?.awbNumber || ''


    })




    const requestData = (() => {
        const data = {    
        }
        if (holdawb) data.awbNumber = holdawbForm.awbNumber
        if (holdawb) data.holdReason = holdawbForm.holdReason
        if (holdawb) data.remarks = holdawbForm.remarks

        if (moveawb) data.currentStockType = holdawbForm.currentStockType
        if (moveawb) data.moveStockTo = holdawbForm.moveStockTo
        if (moveawb) data.stockNumber = holdawbForm.stockNumber

        return data

    })();





    const isEdit = false;
    const handleInputChange = (value, field) => {

        setHoldawbForm({
            ...holdawbForm,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    };


    const handleFormSubmit = () => {

        const hasUndefinedValue = (obj) => {
            let changes = {};
            Object.entries(obj).forEach(([key, value]) => {
                if (
                    key === 'AwbNumber'

                ) {

                }
                else if (!value) {
                    changes[key + "error"] = !value;
                }
            });
            return changes;
        };

        const changes = hasUndefinedValue(requestData);

        if (Object.keys(changes).length > 0) {
            debugger;
            setHoldawbForm({
                ...holdawbForm, ...changes,

            });
        }
        else if (moveawb) {
            PostmoveStock(requestData);
        }
        else {
            PostHoldAwb(requestData);

        }

    };


    const PostHoldAwb = (requestData) => {

        PostCaller({
            API: AddHoldAwb,
            // contenttype: "multipart/form-data",
            callback: () => {
                navigate(`${awbstock}`)
            },

            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestData
        })
    };



    const PostmoveStock = (requestData) => {
        debugger

        PostCaller({
            API: AddMoveAwb,
            // contenttype: "multipart/form-data",
            callback: () => {
                navigate(`${awbstock}`)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestData
        })
    };


    const set_AirlineItem = () => {
        GetCaller({
            API: GetAirlineMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                console.log('GetConsigneeSubAgencyPage', response?.data?.data?.records)
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.legalName,
                    value: res?.id
                }))

                setAirlineItem(item)

            },
        })
    }



    useEffect(() => {
        if(sessionStorage.iatastock){
            const iatastock = JSON.parse(sessionStorage.getItem("iatastock"));
            debugger
            setHoldawbForm(iatastock)
            sessionStorage.removeItem('iatastock')
        }

        set_AirlineItem()

        GetCaller({
            API: PostVendorMaster,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                console.log('GetConsigneeSubAgencyPage', response?.data?.data?.records)
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.legalName,
                    value: res?.id
                }))
                setVendorItem(item)

            },
        })

    }, [])


    return (
        <>
        { 
        moveawb ?    

        <div className="hr-form-box">
        <h6>Move Awb</h6>
        <div className="row">
      
                <div className="col-md-3">
                    <Dropdown
                        field={
                            {
                                id: "moveStockTo",
                                label: 'Move Stock TO',
                                type: "text",
                                value: holdawbForm?.moveStockTo,
                                required: true,
                                error: holdawbForm?.moveStockToerror,   
                                item: currentStockType,                      
                                onChange: handleInputChange
                            }
                        }
                    />
                    </div>
                    <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: "stockNumber",
                                label: 'Stock Number',
                                type: "text",
                                value: holdawbForm?.stockNumber,
                                required: true,
                                disable: true,
                                error: holdawbForm?.stockNumbererror,                         
                                onChange: handleInputChange
                            }
                        }
                    />
                                </div>


            </div>
        </div> 
        :
            <div className="hr-form-box">
                <h6>Hold AWB</h6>
                <div className="row">

                    <div className=" col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "holdReason",
                                    label: 'Hold Reason ',
                                    type: "select",
                                    value: holdawbForm?.holdReason,
                                    required: true,
                                    error: holdawbForm?.holdReasonerror,
                                    item: listofHoldReasons,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: "awbNumber",
                                    label: 'Awb Number',
                                    type: "text",
                                    value: holdawbForm?.awbNumber,
                                    required: true,
                                    disable: true,
                                    error: holdawbForm?.awbNumbererror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3 mt-3">
                        <TextAreafield
                            field={
                                {
                                    id: 'remarks',
                                    label: "Remark",
                                    value: holdawbForm?.remarks,
                                    required: true,
                                    rows: 3,
                                    error: holdawbForm?.remarkserror,
                                    onChange: handleInputChange
                                }
                            } />
                    </div>


                </div>
            </div>
}
            <div className="cmd">
                <div></div>
                <Button
                    sx={{ m: 2, borderRadius: "10px" }}
                    onClick={handleFormSubmit}
                    variant="contained"
                >
                    Submit
                </Button>
            </div>
        </>
    );



}
export default CreateHoldAwb;
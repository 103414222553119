import { useEffect, useState } from "react";
import Dropdown, { AutoSelect, DateField, InputField, InputFileField } from "../../../component/controls";
import { Button } from "@mui/material";
import { GetAirlineMasterByPage, GetMasterAccountLedgerByPage, GetVendorMasterByPage, PostVendorMaster } from "../../../../services/constant";
import axios from "axios";
import { AddIataStock, UpdateIataStock } from "../../../../services/APIConstant/jobConstant";
import { iatastock } from "../../../../navigation/constant";
import GetCaller, { PostCaller } from "../../../../services/apiServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";


const CreateIataStock = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [airlineItem, setAirlineItem] = useState([])
    const [vendorItem, setVendorItem] = useState([])
    const [isedit, setisEdit] = useState(false)

    const [iatastockForm, setIataStockForm] = useState({
        AirlineId: '',
        StockDate: '',
        FilePath: '',
        AwbNumber: "",
        VendorId: ""
    })


    const requestData = {
        AirlineId: iatastockForm.AirlineId,
        StockDate: iatastockForm.StockDate,
        FilePath: iatastockForm.FilePath,
        AwbNumber: iatastockForm.AwbNumber,
        VendorId: iatastockForm.VendorId
    }


    const handleInputChange = (value, field) => {



        setIataStockForm({
            ...iatastockForm,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    };


    const handleFormSubmit = () => {

        const hasUndefinedValue = (obj) => {
            let changes = {};
            Object.entries(obj).forEach(([key, value]) => {
                if (
                    key === 'FilePath' ||
                    key === 'StockDate'

                ) {

                }
                else if (!value) {
                    changes[key + "error"] = !value;
                }
            });
            return changes;
        };

        const changes = hasUndefinedValue(requestData);

        if (Object.keys(changes).length > 0) {
            debugger;
            setIataStockForm({
                ...iatastockForm, ...changes,

            });
        }
        else if (isedit) {
            EditIataStock(requestData);
        }
        else {
            PostIataStock(requestData);

        }

    };



    const PostIataStock = (requestData) => {
        debugger

        PostCaller({
            API: AddIataStock,
            contenttype: "multipart/form-data",
            callback: () => {
                navigate(`${iatastock}`)
            },

            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestData
        })
    };



    const EditIataStock = (requestData) => {
        debugger
        requestData.id = isedit;
        PostCaller({
            API: UpdateIataStock,
            contenttype: "multipart/form-data",
            callback: () => {
                navigate(`${iatastock}`)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestData
        })
    };


    const set_AirlineItem = () => {
        GetCaller({
            API: GetAirlineMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                console.log('GetConsigneeSubAgencyPage', response?.data?.data?.records)
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.legalName,
                    value: res?.id
                }))
                setAirlineItem(item)
            },
        })
    }



    useEffect(() => {
        debugger
    

        if (sessionStorage.iatastock) {
            const iatastock = JSON.parse(sessionStorage.getItem("iatastock"));
            setisEdit(iatastock.id)
            setIataStockForm(iatastock)
            setIataStockForm({
                ...iatastockForm,
                'AirlineId':iatastock?.airlineId,
                'StockDate':iatastock?.stockDate,
                'FilePath': iatastock?.document,
                'AwbNumber': iatastock?.awbNumber,
                'VendorId': iatastock?.vendorId
            })
            sessionStorage.removeItem('iatastock')
        }

        set_AirlineItem()

        GetCaller({
            API: PostVendorMaster,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                console.log('GetConsigneeSubAgencyPage', response?.data?.data?.records)
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.legalName,
                    value: res?.id
                }))

                setVendorItem(item)

            },
        })

    }, [])


    return (
        <>
            <div className="hr-form-box">
                {isedit ? <h6>Edit IATA Stock</h6> : <h6>Create IATA Stock</h6>}

                <div className="row">
                    <div className="col-md-3">
                        <AutoSelect
                            field={{
                                id: 'VendorId',
                                label: 'IATA Agent',
                                value: iatastockForm?.VendorId,
                                required: true,
                                error: iatastockForm?.VendorIderror,
                                item: vendorItem,
                                onChange: handleInputChange

                            }}
                        />
                    </div>
                    <div className=" col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "AirlineId",
                                    label: ' Carrier ',
                                    type: "select",
                                    value: iatastockForm?.AirlineId,
                                    required: true,
                                    error: iatastockForm?.AirlineIderror,
                                    item: airlineItem,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">

                        <DateField
                            field={
                                {
                                    id: "StockDate",
                                    label: 'Stock Date',
                                    type: "date",
                                    value: iatastockForm?.StockDate,
                                    required: true,
                                    error: iatastockForm?.StockDateerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            className='mt-3'
                            field={{
                                id: "AwbNumber",
                                label: "Awb Number",
                                type: 'text',
                                value: iatastockForm?.AwbNumber,
                                required: true,
                                error: iatastockForm?.AwbNumbererror,
                                onChange: handleInputChange
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <InputFileField
                            className='mt-3'
                            field={{
                                id: "FilePath",
                                label: "Upload File",
                                type: 'file',
                                value: iatastockForm?.FilePath,
                                required: false,
                                error: iatastockForm?.FilePatherror,
                                onChange: handleInputChange
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="cmd">
                <div></div>
                <Button
                    sx={{ m: 2, borderRadius: "10px" }}
                    onClick={handleFormSubmit}
                    variant="contained"
                >
                    Submit
                </Button>
            </div>
        </>
    );



}
export default CreateIataStock;
// alert bar
export const reducerFnShowSnackbar = (state = { active: false, message: '', type: '' }, action) => {
    if (action.type === "SNACKBARALERT" && action.payload !== undefined) {
        return (state = action.payload);
    } else {
        return state;
    }
};

// loader 
export const reducerFnShowLoader = (state = false, action) => {
    if (action.type === "LOADER" && action.payload !== undefined) {
        return (state = action.payload);
    } else {
        return state;
    }
};

// loader 
const ENQUIRYBRANCH = localStorage.getItem('ENQUIRYBRANCH')
export const reducerEnquiryBranch = (state = ENQUIRYBRANCH || false, action) => {
    if (action.type === "ENQUIRYBRANCH" && action.payload !== undefined) {
        return (state = action.payload);
    } else {
        return state;
    }
};

// loader 
export const reducerFnEnquiryFinancialYear = (state = false, action) => {
    if (action.type === "ENQUIRYFINANCIALYEAR" && action.payload !== undefined) {
        return (state = action.payload);
    } else {
        return state;
    }
};

// loader 
export const reducerFnEnquiryDivision = (state = false, action) => {
    if (action.type === "ENQUIRYDIVISION" && action.payload !== undefined) {
        return (state = action.payload);
    } else {
        return state;
    }
};
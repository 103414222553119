export const ArrayObjectHasUndefinedValue = (array, staticArray, keys) => {
    let newArray = array && array?.length !== 0 ? array : staticArray
    let changes = {};

    newArray?.map((obj, index) => {
        Object.entries(obj).forEach(([key, value]) => {
            if (!value && (keys ? (keys(key, obj)) : true)) {
                changes[key + index] = !value;
            }
        });
    })

    return changes;

}



export const ObjectHasUndefinedValue = () => {

}
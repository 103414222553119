import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Textarea } from '@mui/joy';
import CustomizedAccordions from './accordian';
import { LeadLogAction, LeadLogPriority, LeadLogResponse } from '../../../services/modalData';
import axios from 'axios';
import { GetMarketingLeadLogs, PostLeadLog } from '../../../services/constant';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


export default function LeadTab({ ID, callback }) {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState('1');
    const [leadLogsData, setLeadLogsData] = useState([])
    const navigate = useNavigate()
    const authToken = localStorage.getItem('authToken')
    const headers = {
        Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
    };
    const fatchMarketingLeadLogs = () => {


        axios.get(GetMarketingLeadLogs(ID), { headers })
            .then((response) => {
                console.log(response.data.data)
                setLeadLogsData(response.data.data)
            })
    }

    useEffect(() => {
        fatchMarketingLeadLogs()
    }, [])



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [leadLogState, setLeadLogState] = useState({
        action: '',
        response: '',
        priority: '',
        nextFollowUp: '',
        comments: ''
    })

    const LeadLogHandleChange = (id, value) => {
        setLeadLogState(
            {
                ...leadLogState,
                [id]: value,
                [id + 'error']: !value,

            }
        )
    }

    const sumbitValidHandler = (obj) => {
        let changes = {}; // Temporary object to accumulate changes
        // Check if any value in the object is undefined
        Object.entries(obj).forEach(([key, value]) => {
            if (!value && (key !== 'id' && key !== 'nextFollowUp' && key !== 'priority')) {
                changes[key] = value;
                changes[key + 'error'] = !value;
            }
        });
        return changes;
    }



    const SubmitLeadLog = () => {
        dispatch({ type: "LOADER", payload: true });

        const requestLeadPost = {
            leadLog: {
                id: 0,
                leadId: ID,
                action: leadLogState.action,
                response: leadLogState.response,
                priority: leadLogState?.priority || null,
                nextFollowUp: leadLogState?.nextFollowUp || null,
                comments: leadLogState.comments
            }
        }
        const leadLogValiator = sumbitValidHandler(requestLeadPost.leadLog)
        if (!ID || Object.keys(leadLogValiator).length > 0) {
            setLeadLogState({
                ...leadLogState,
                ...leadLogValiator
            })
            dispatch({ type: "LOADER", payload: false })

        }

        else {


            axios.post(PostLeadLog(), requestLeadPost, { headers })
                .then(() => {
                    setLeadLogState({
                        action: '',
                        response: '',
                        priority: '',
                        nextFollowUp: '',
                        comments: ''
                    })
                    fatchMarketingLeadLogs()
                    callback()
                })
                .catch(() => {
                    fatchMarketingLeadLogs()
                    callback()
                })
        }

    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                    <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                        <Tab label="Log" value="1" />
                        <Tab label="Email" value="2" />
                        <Tab label="Follow Up" value="3" />
                    </TabList>
                </Box>

                <TabPanel value="1" sx={{ padding: "0" }}>
                    <div className="row">
                        <div className="col-md-6 mt-4">
                            <FormControl sx={{ width: '100%', fontSize: '13px' }} size="small" error={leadLogState?.actionerror}>
                                <InputLabel id={`label-action`}>{'Action*'}</InputLabel>
                                <Select
                                    // className='select'
                                    labelId={`label-action`}
                                    id={`select-label-action`}
                                    name={'Action*'}
                                    label={'Action*'}
                                    value={leadLogState?.action}
                                    onChange={(e) => LeadLogHandleChange('action', e.target.value)}
                                >
                                    {
                                        LeadLogAction.map((item, key) => {
                                            return <MenuItem key={key} value={item.value}>{item.name}</MenuItem>
                                        })
                                    }

                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 mt-4">
                            <FormControl sx={{ width: '100%', fontSize: '13px' }} size="small" error={leadLogState?.responseerror}>
                                <InputLabel id={`label-action`}>{'Response*'}</InputLabel>
                                <Select
                                    // className='select'
                                    labelId={`label-Response`}
                                    id={`select-label-Response`}
                                    name={'Response*'}
                                    label={'Response*'}
                                    value={leadLogState?.response}
                                    onChange={(e) => LeadLogHandleChange('response', e.target.value)}

                                >
                                    {
                                        LeadLogResponse.map((item, key) => {
                                            return <MenuItem key={key} value={item.value}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 mt-4">
                            <FormControl sx={{ width: '100%', fontSize: '13px' }} size="small" error={leadLogState?.priorityerror}>
                                <InputLabel id={`label-action`}>{'Priority'}</InputLabel>
                                <Select
                                    // className='select'
                                    labelId={`label-Priority`}
                                    id={`select-label-Priority`}
                                    name={'Priority'}
                                    label={'Priority'}
                                    value={leadLogState?.priority}
                                    onChange={(e) => LeadLogHandleChange('priority', e.target.value)}

                                >

                                    {
                                        LeadLogPriority.map((item, key) => {
                                            return <MenuItem key={key} value={item.value}>{item.name}</MenuItem>
                                        })
                                    }



                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 mt-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DemoItem>
                                        <DateTimePicker className='date-input' label={'Next Follow Up'}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: leadLogState?.nextFollowUperror ? 'red' : '',
                                                    }
                                                }
                                            }}
                                            value={leadLogState?.nextFollowUp || null}
                                            onChange={(e) => {
                                                const formattedDate = e.format('YYYY-MM-DDTHH:mm:ss.000000');
                                                LeadLogHandleChange('nextFollowUp', formattedDate);
                                            }}
                                        />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <div className="col-12 mt-4">
                            <p style={{ fontSize: "14px", margin: "0", color: "gray" }}> comment*</p>
                            <Textarea minRows={4} error={leadLogState?.commentserror} value={leadLogState?.comments} onChange={(e) => LeadLogHandleChange('comments', e.target.value)} />
                        </div>
                    </div>
                    <Button sx={{ marginTop: "1rem", width: "100%" }} variant='contained' onClick={SubmitLeadLog}> Save</Button>
                </TabPanel>

                <TabPanel value="2" sx={{ padding: "0" }}>
                    <div className="row">
                        <div className="col-12">
                            <TextField
                                fullWidth
                                label={'From*'}
                                id="fullWidth"
                                sx={{ mt: 5, width: '100%', fontSize: '13px' }}
                                size="small"
                            // onChange={(e) => handleFieldChange(field.label, e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label={'To*'}
                                id="fullWidth"
                                sx={{ mt: 3, width: '100%', fontSize: '13px' }}
                                size="small"
                            // onChange={(e) => handleFieldChange(field.label, e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label={'Bcc*'}
                                id="fullWidth"
                                sx={{ mt: 3, width: '100%', fontSize: '13px' }}
                                size="small"
                            // onChange={(e) => handleFieldChange(field.label, e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label={'Subject*'}
                                id="fullWidth"
                                sx={{ mt: 3, width: '100%', fontSize: '13px' }}
                                size="small"
                            // onChange={(e) => handleFieldChange(field.label, e.target.value)}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <p style={{ fontSize: "14px", margin: "0", color: "gray" }}> comment*</p>
                            <Textarea minRows={4} />
                        </div>
                    </div>
                    <Button sx={{ marginTop: "1rem", width: "100%" }} variant='contained'> Send</Button>

                </TabPanel>

                <TabPanel value="3" sx={{ padding: "0" }}>
                    <CustomizedAccordions leadLogsData={leadLogsData} />
                </TabPanel>

            </TabContext>
        </Box>
    );
}
import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { GetMasterDepartmentByPage, GetMasterDesignationByPage, GetMasterDivisionByPage, GetMasterNetworkTypeByPage, GetMasterSubGroupByPage, GetSACMasterByPage, PostMasterDepartment, PostMasterDesignation, PostMasterNetworkType, PostSACMaster, UpdateMasterDesignation, UpdateMasterDesignationStatus, UpdateMasterNetworkType, UpdateMasterNetworkTypeStatus, UpdateSACMaster, UpdateSACMasterStatus } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';

const SacCodeMaster = () => {
    const dispatch = useDispatch()
    const [networkNameMasterData, setNetworkNameMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)


    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetSACMasterByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
    
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({

            sn: index + 1,
            id: res.id,
            sacname: res.name,
            status: res.status
        }));
        setNetworkNameMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'sacname',
            label: 'SAC Code',
            numeric: false,
        },

        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];


    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateSACMasterStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>
            <MultiTable
                data={networkNameMasterData}
                columns={columns}
                // filterItems={filterItems}
                title={'SAC Master'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default SacCodeMaster




const DesignationDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()

    const [closeValidator, setcloseValidator] = useState(false)
    const [sacName, setSacName] = useState(row?.sacname || 0)
    const [sacNameError, setSacNameError] = useState(false)
  

    const drawerOpenCallbackHandler = () => {
        setSacName(row?.sacname || '')   
        setSacNameError(false)
    }

    const handleSubmit = () => {
        
        if (sacName == 0 ) {
            setSacNameError(!sacName)
        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                SACCodeName: sacName,
                status: row.status
              } : {
                SACCodeName: sacName,     
              }             
              editor ?
                PostCaller({
                  API: UpdateSACMaster,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
        
                :
        
                PostCaller({
                  API: PostSACMaster,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }


    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'sacname':
                setSacName(value)
                setSacNameError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit SAC Code",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit SAC Code',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Sac Code",
            tooltitle: 'Add SAC Code',
            defaultbtnfalse: true
        }





    const FieldData = [
        {
            id: 'sacname',
            label: 'SAC Code',
            type: 'number',
            value: sacName,
            required: true,
            error: sacNameError,
            helperText: 'SAC Code required',
            onChange: handleInputChange,
        },
    ];

  



    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }

    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}


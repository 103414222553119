import React, { useEffect, useState } from 'react'
import Dropdown, { InputField, InputFileField } from '../../component/controls';
import { columnConstant, formconstant } from '../../constant/formconstant';
import { ChargeExportImport, EnquiryDimension, GoodsConditions, JobTypeForGoodsInRegister, PickUpType, WareHouseGoodsStatus } from '../../../services/modalData';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import Transition from '../../../services/utilities/transition';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { GetAirportMasterByPage, GetCustomerBySearch, GetVehicalTypeByPage, GetVendorMasterByPage, PostVendorMaster } from '../../../services/constant';
import { useDispatch } from 'react-redux';
import { GetWarehouseGoodsInRegisterById, PostWerehouseGoodsInRegister, UpdateGoodsInVehicleReceived, UpdateWerehouseGoodsInRegister } from '../../../services/APIConstant/warehouseConstant';
import { goodsinregisterlist, goodsoutregisterlist } from '../../../navigation/constant';
import { useNavigate } from 'react-router-dom';
import { formatDataToFormData } from '../../../services/utilities/formUtilities';
import { GetAirExportPermanentJoblistByPage } from '../../../services/APIConstant/AirExport.jsx/finalJob';

const CreateGoodsInRegisterForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [jobIdOrData, setJobIdorData] = useState([]);

    const [actualShipperItem, setActualShipperItem] = useState([]);
    const [vehicalTypeItem, setVehicalTypeItem] = useState([]);
    const [transportNameItem, setTransPortNameItem] = useState([]);
    const [airportItem, setAirportItem] = useState([])

    const [transporterDetailsError, setTransporterDetailsError] = useState({});
    const [dimentionDetailsError, setDimentionDetailsError] = useState({});

    const [formDetailsData, setFormDetailsData] = useState({})
    const [formData, setFormData] = useState({
        partyName: '',
        jobType: '',
        packages: '',
        grossWeight: '',
        dimension: '',
        airportOfLoading: '',
        airportOfDestination: '',
        consoleNumber: '',
        jobNumber: '',
        totalCost: '',
        dimensions: '',
        actualShipper: "",
        shippeingBillNumber: "",
        transporters: [],


    })

    const get_JobData = () => {
        GetCaller({
            API: GetAirExportPermanentJoblistByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const details = data?.map(res => ({
                    value: res?.id,
                    name: res?.id
                }))
                setJobIdorData(details)
            }
        })
    }

    const get_AirportItem = () => {
        GetCaller({
            API: GetAirportMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const airportItem = data?.map(res => ({
                    value: res?.id,
                    name: res?.name
                }))
                setAirportItem(airportItem)
            }
        })
    }

    const get_ShipperItem = () => {
        GetCaller({
            API: GetCustomerBySearch,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    ?.map(item => ({
                        value: item.id,
                        name: item.legalName
                    }));

                setActualShipperItem(newCountryItemData);

            }

        });
    }

    const get_TransporterName = () => {
        GetCaller({
            API: GetVendorMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    ?.map(item => ({
                        value: item.id,
                        name: item.legalName
                    }));

                setTransPortNameItem(newCountryItemData);

            }

        });
    }

    const get_VehicleType = () => {
        GetCaller({
            API: GetVehicalTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.name
                    }));

                setVehicalTypeItem(newCountryItemData);

            }

        });
    }

    const set_GoodInRegisterDataById = (id) => {

        GetCaller({
            API: GetWarehouseGoodsInRegisterById,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { id: id },
            callback: ({ response }) => {
                debugger

                const data = response?.data?.data
                data.dimensions = revertData(data?.dimensions || '')


                setFormDetailsData(data);
                setFormData({
                    ...formData,
                    ...data
                })

            }

        });
    }



    useEffect(() => {
        const goodsInRegisterDetails = sessionStorage.getItem('goodsInRegisterDetails')
        const goodsInVehicleReceivedData = JSON.parse(sessionStorage.getItem('goodsInVehicleReceivedData'))
        get_ShipperItem()
        get_TransporterName()
        get_VehicleType()
        get_AirportItem()
        get_JobData()

        if (goodsInRegisterDetails) {
            set_GoodInRegisterDataById(goodsInRegisterDetails)
        }

        if (goodsInVehicleReceivedData) {
            debugger
            setFormData({
                ...formData,
                ...goodsInVehicleReceivedData
            })

            setFormDetailsData(goodsInVehicleReceivedData)
        }

    }, [])



    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState(false);

    const handleFileOpen = () => {
        setOpen(true);
    };

    const handleFileClose = () => {
        setOpen(false);
        handleInputChange(null, { id: 'dimensionFile' })
    }

    const handleFileSubmit = () => {
        handleInputChange(file, { id: 'dimensionFile' })
        setOpen(false);
    }

    const convertDimensions = (data) => {
        if (data && data.length !== 0) {
            return data?.map(item => {
                return `${item.length || 0}*${item.width || 0}*${item.height || 0}*${item.boxes || 0}`;
            }).join(',');
        } else {
            return ''
        }
    };

    const revertData = (dataString) => {
        if (dataString) {
            return dataString.split(',')?.map(item => {
                const [length, width, height, boxes] = item.split('*')?.map(Number);
                return { length, width, height, boxes };
            });
        } else {
            return []
        }

    };









    const requestData = (() => {
        const data = {
            partyName: formData.partyName,
            jobType: formData.jobType,
            packages: formData.packages,
            grossWeight: formData.grossWeight,
            dimension: formData.dimension,
            transporters: formData.transporters,

        }
        if (formData.id) data.id = formData.id
        if (formData.goodsStatus) data.goodsStatus = formData.goodsStatus
        if (formData.expectedGoodsId) data.expectedGoodsId = formData.expectedGoodsId
        if (formData.shippingBillFile) data.shippingBillFile = formData.shippingBillFile
        if (formData.airportOfDestination) data.airportOfDestination = formData.airportOfDestination
        if (formData.airportOfLoading) data.airportOfLoading = formData.airportOfLoading
        if (formData.shippeingBillNumber) data.shippeingBillNumber = formData.shippeingBillNumber
        if (formData.dimensions) data.dimensions = convertDimensions(formData.dimensions)
        if (formData.consoleNumber) data.consoleNumber = formData.consoleNumber
        if (formData.dimensionFile) data.dimensionFile = formData.dimensionFile
        if (formData.actualShipper) data.actualShipper = formData.actualShipper
        if (formData.mawbNumber) data.mawbNumber = formData.mawbNumber
        if (formData.hawbNumber) data.hawbNumber = formData.hawbNumber
        if (formData.jobNumber) data.jobNumber = formData.jobNumber
        if (formData.totalCost) data.totalCost = formData.totalCost
        if (formData.pickupStatus) data.pickupStatus = formData.pickupStatus

        return data;
    })();




    const hasUndefinedValue = (obj) => {
        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if ((key === 'dimensions')) {

            }
            else if (!value) {
                changes[key + "error"] = !value;
            }
        });
        return changes;
    };

    const transporterDetailshasUndefinedValue = (data) => {
        let array = data && data.length !== 0 ? data : [{
            pickup: '',
            packages: '',
            grossWeight: '',
            goodsCondition: '',
            uploadReceivingFile: ""
        }]

        let changes = {};
        array?.map((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                if (
                    (obj.pickup !== 2 && key === 'uploadReceivingFile') ||
                    (obj.pickup !== 1 && key === 'vendorId') ||
                    (obj.pickup !== 1 && key === 'cost') ||
                    (obj.goodsCondition !== 4 && obj.goodsCondition !== 5 && key === "number") ||
                    (obj.goodsCondition === 1 && key === 'comment') ||
                    (obj.goodsCondition === 1 && key === 'uploadFile')
                ) {

                }
                else if (!value && (
                    key === 'pickup' ||
                    key === 'vendorId' ||
                    // key === 'pickupVehicleTypeId' ||
                    // key === 'vehicleNumber' ||
                    // key === 'driverName' ||
                    // key === 'driverNumber' ||
                    key === 'packages' ||
                    key === 'grossWeight' ||
                    key === 'comment' ||
                    key === 'goodsCondition' ||
                    key === 'cost' ||
                    key === 'number' ||
                    key === 'uploadFile' ||
                    key === 'uploadReceivingFile'
                )) {
                    changes[key + index] = !value;
                }
            });
        })
        console.log('transporterDetailshasUndefinedValue changes', changes)

        return changes;
    };

    const dimentionDetailshasUndifinedValues = (array) => {
        debugger
        let changes = {};
        if (array && array.length !== 0) {
            array?.map((obj, index) => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (key === 'id') {

                    }
                    else if (!value) {
                        changes[key + index] = !value;
                    }
                });
            })

        }

        return changes;
    };

    const handleSubmit = () => {
        debugger

        const dimentionChanges = dimentionDetailshasUndifinedValues(formData.dimensions)
        const transporterDetailsChanges = transporterDetailshasUndefinedValue(requestData.transporters);
        const changes = hasUndefinedValue(requestData);

        if (
            (Object.keys(changes).length > 0 && !formDetailsData?.isRceivedQuery) ||
            (Object.keys(dimentionChanges).length > 0 && !formDetailsData?.isRceivedQuery) ||
            Object.keys(transporterDetailsChanges).length > 0
        ) {

            if (!formDetailsData?.isRceivedQuery) {
                setFormData({
                    ...formData,
                    ...changes
                })


                setDimentionDetailsError({
                    ...dimentionDetailsError,
                    ...dimentionChanges
                })
            }


            setTransporterDetailsError({
                ...transporterDetailsError,
                ...transporterDetailsChanges
            })



        } else {

            const formatedData = formatDataToFormData(requestData)

            const receivedTransporterPayload = {
                GoodsInTransporterMaster: requestData.transporters
            }
            const formatedTransportData = formatDataToFormData(receivedTransporterPayload)

            PostCaller({
                API: formDetailsData?.isRceivedQuery ? UpdateGoodsInVehicleReceived : formData.id ? UpdateWerehouseGoodsInRegister : PostWerehouseGoodsInRegister,
                callback: () => { navigate(goodsinregisterlist) },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                contenttype: true,
                item: formDetailsData?.isRceivedQuery ? formatedTransportData : formatedData
            })
        }
    }


    const handleInputChange = (value, field) => {
        setFormData({
            ...formData,
            [field.id]: value,
            [field.id + 'error']: !value,
        })
    }

    const airportItemFilterData = (id) => {
        let newFilterItem = airportItem.filter(item => item.value !== id)
        return newFilterItem
    }

    return (
        <>
            <div className='hr-form-box'>
                <h4>Goods In Register</h4>

                <div className="row">
                    <div className="col-md-2">
                        <InputField
                            field={{
                                id: "partyName",
                                label: formconstant.partyname,
                                type: "text",
                                value: formData.partyName,
                                disable: formDetailsData?.isRceivedQuery,
                                required: true,
                                error: formData.partyNameerror,
                                onChange: handleInputChange
                            }}
                        />
                    </div>
                    <div className="col-md-2">
                        <Dropdown
                            field={{
                                id: "jobType",
                                label: formconstant.jobtype,
                                type: "text",
                                value: formData.jobType,
                                disable: formDetailsData?.isRceivedQuery,
                                required: true,
                                error: formData.jobTypeerror,
                                onChange: handleInputChange,
                                item: JobTypeForGoodsInRegister
                            }}
                        />
                    </div>
                    <div className="col-md-2">
                        <div className={`d-flex ${formDetailsData.id && 'gstWrapper'} `}>
                            {
                                formDetailsData.id && <InputField
                                    field={{
                                        id: "expectedTotalPackages",
                                        label: formconstant.expectedpackages,
                                        type: "number",
                                        value: formData.expectedTotalPackages,
                                        required: true,
                                        disable: true,
                                        error: formData.expectedTotalPackageserror,
                                        onChange: handleInputChange,
                                    }}
                                />
                            }

                            <InputField
                                field={{
                                    id: "packages",
                                    label: formconstant.packages,
                                    type: "number",
                                    value: formData.packages,
                                    required: true,
                                    disable: true,
                                    error: formData.packageserror,
                                    onChange: handleInputChange,
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className={`d-flex ${formDetailsData.id && 'gstWrapper'} `}>
                            {
                                formDetailsData.id && <InputField
                                    field={{
                                        id: "expectedGrossWeight",
                                        label: formconstant.expectedgrossweight,
                                        type: "number",
                                        value: formData.expectedGrossWeight,
                                        required: true,
                                        disable: true,
                                        error: formData.expectedGrossWeight,
                                        onChange: handleInputChange,
                                    }}
                                />
                            }
                            <InputField
                                field={{
                                    id: "grossWeight",
                                    label: formconstant.grossweight,
                                    type: "number",
                                    value: formData.grossWeight,
                                    required: true,
                                    disable: true,
                                    error: formData.grossWeighterror,
                                    onChange: handleInputChange,
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="cmd">
                            <Dropdown
                                field={{
                                    id: "dimension",
                                    label: formconstant.dimension,
                                    type: "text",
                                    value: formData.dimension,
                                    required: true,
                                    disable: formDetailsData?.isRceivedQuery,

                                    error: formData.dimensionerror,
                                    onChange: handleInputChange,
                                    item: EnquiryDimension
                                }}
                            />
                            <React.Fragment>
                                <div onClick={handleFileOpen}>
                                    <Tooltip title="Upload sheet">
                                        <Button className='mt-3 p-1 ms-2' variant='contained' style={{ minWidth: "30px" }} >
                                            <Add />
                                        </Button>
                                    </Tooltip>
                                </div>


                                <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleFileClose}
                                    aria-describedby="alert-dialog-slide-description"
                                    PaperProps={{
                                        sx: {
                                            width: "100%",
                                            maxWidth: "430px!important",
                                        },
                                    }}
                                    sx={{
                                        '& .MuiDialog-container': {
                                            alignItems: "start"
                                        }
                                    }
                                    }


                                >
                                    <div className=''>
                                        <DialogTitle sx={{ pb: "0" }}>
                                            <div className="cmd">
                                                <div>{columnConstant.dimensionsheet}</div>
                                                <Tooltip title='Close'>
                                                    <IconButton onClick={handleFileClose}>
                                                        <Close />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <hr />
                                        </DialogTitle>
                                        <DialogContent>
                                            <InputFileField
                                                className='mt-3'
                                                field={{
                                                    id: "",
                                                    label: "Upload Sheet",
                                                    type: 'file',
                                                    required: true,
                                                    value: formData.dimensionFile,
                                                    error: !formData.dimensionFile && formData.dimensionFileerror,
                                                    helperText: 'Upload Pan Card Required',
                                                    onChange: (value, field) => {
                                                        setFile(value)
                                                    }
                                                }}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleFileClose} variant='outlined'>Cancel</Button>
                                            <Button onClick={handleFileSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
                                        </DialogActions>
                                    </div>
                                </Dialog>
                            </React.Fragment >
                        </div>
                    </div>

                    <div className="col-md-2">
                        <Dropdown
                            field={{
                                id: "actualShipper",
                                label: formconstant.actualshipper,
                                type: "text",
                                value: formData.actualShipper,
                                disable: formDetailsData?.isRceivedQuery,

                                required: false,
                                error: formData.actualShippererror,
                                onChange: handleInputChange,
                                item: actualShipperItem
                            }}
                        />
                    </div>



                    <div className="col-md-2">
                        <Dropdown
                            field={{
                                id: "airportOfLoading",
                                label: 'APOL',
                                value: formData.airportOfLoading,
                                required: false,
                                disable: formDetailsData?.isRceivedQuery,

                                error: formData.airportOfLoadingerror,
                                onChange: handleInputChange,
                                item: airportItemFilterData(formData.airportOfDestination),

                            }}
                        />
                    </div>

                    <div className="col-md-2">
                        <Dropdown
                            field={{
                                id: "airportOfDestination",
                                label: 'APOD',
                                value: formData.airportOfDestination,
                                required: false,
                                disable: formDetailsData?.isRceivedQuery,

                                error: formData.airportOfDestinationerror,
                                onChange: handleInputChange,
                                item: airportItemFilterData(formData.airportOfLoading),


                            }}
                        />
                    </div>

                    {/* <div className="col-md-2">
                        <InputField
                            field={{
                                id: "consoleNumber",
                                label: formconstant.consoleno,
                                type: "number",
                                value: formData.consoleNumber,
                                required: false,
                                error: formData.consoleNumbererror,
                                onChange: handleInputChange
                            }}
                        />
                    </div> */}

                    <div className="col-md-2">
                        <Dropdown
                            field={{
                                id: "jobNumber",
                                label: formconstant.jobno,
                                type: "number",
                                disable: formDetailsData?.isRceivedQuery,
                                value: formData.jobNumber || '',
                                required: false,
                                error: formData.jobNumbererror,
                                item: jobIdOrData,
                                onChange: handleInputChange
                            }}
                        />
                    </div>

                    <div className="col-md-2">
                        <InputField
                            field={{
                                id: "shippeingBillNumber",
                                label: 'Shipper Invoices No.',
                                type: "text",
                                disable: formDetailsData?.isRceivedQuery,
                                value: formData.shippeingBillNumber,
                                required: false,
                                error: formData.shippeingBillNumbererror,
                                onChange: handleInputChange
                            }}
                        />
                    </div>

                    <div className="col-md-2">
                        <InputField
                            field={{
                                id: "totalCost",
                                label: formconstant.cost,
                                type: "number",
                                disable: formDetailsData?.isRceivedQuery,
                                value: formData.totalCost,
                                required: false,
                                disable: true,
                                error: formData.totalCosterror,
                                onChange: handleInputChange
                            }}
                        />
                    </div>

                </div>
            </div>

            {
                !formDetailsData?.isRceivedQuery &&
                <div className="hr-form-box">
                    <Dimension
                        handleBasicInputChange={handleInputChange}
                        dimentionDetailsError={dimentionDetailsError}
                        setDimentionDetailsError={setDimentionDetailsError}
                        formDetailsData={formDetailsData}
                    />
                </div>

            }

            <div className="hr-form-box">
                <TransportMultiDetails
                    transporterDetailsError={transporterDetailsError}
                    setTransporterDetailsError={setTransporterDetailsError}
                    handleInputChange={handleInputChange}
                    vehicalTypeItem={vehicalTypeItem}
                    transportNameItem={transportNameItem}
                    formDetailsData={formDetailsData}
                    setFormData={setFormData}
                    formData={formData}
                />
            </div>



            <div className="cmd">
                <div></div>

                {
                    formDetailsData?.isRceivedQuery ?
                        <Button variant='contained' onClick={handleSubmit}>Received</Button>
                        :
                        <Button variant='contained' onClick={handleSubmit}>Submit</Button>

                }
            </div>
        </>
    )
}

export default CreateGoodsInRegisterForm;





function TransportMultiDetails({
    transporterDetailsError,
    setTransporterDetailsError,
    handleInputChange,
    vehicalTypeItem,
    transportNameItem,
    formDetailsData,
    formData, setFormData
}) {

    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    useEffect(() => {
        if (formDetailsData.transporters && formDetailsData.transporters.length !== 0) {
            let item = formDetailsData.transporters?.map(res => (
                {
                    ...res,
                    key: generateUniqueKey(),
                }
            ));
            setInputFields(item);
        }

    }, [formDetailsData])

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            id: "",
            vendorId: "",
            pickup: "",
            pickupVehicleTypeId: "",
            vehicleNumber: "",
            driverName: "",
            driverNumber: "",
            packages: "",
            number: "",
            comment: "",
            cost: "",
            grossWeight: "",
            goodsCondition: "",
            uploadFile: "",
            cost: "",
            goodsOutRegisterId: 1,
            sealNumber: 1,
            dimensionFilePath: "",
            uploadReceivingFile: "",
            goodsStatus: ''
        },
    ]);


    const totalValueCounter = () => {
        let totalPackages = 0
        let totalGrossWeight = 0
        let totalCost = 0

        inputFields?.map(res => {
            const cost = res.cost !== "" ? Number(res.cost) : 0;
            const grossWeight = res.grossWeight !== "" ? Number(res.grossWeight) : 0;
            const packages = res.packages !== "" ? Number(res.packages) : 0;

            totalCost = Number(totalCost + cost);
            totalGrossWeight = Number(totalGrossWeight + grossWeight);
            totalPackages = Number(totalPackages + packages);
        })

        setFormData({
            ...formData,
            ["totalCost"]: totalCost,
            ["grossWeight"]: totalGrossWeight,
            ["packages"]: totalPackages,
        })

    }

    useEffect(() => {
        totalValueCounter()
    }, [inputFields])

    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            id: "",
            vendorId: "",
            pickup: "",
            pickupVehicleTypeId: "",
            vehicleNumber: "",
            driverName: "",
            driverNumber: "",
            packages: "",
            number: "",
            comment: "",
            cost: "",
            grossWeight: "",
            goodsCondition: "",
            uploadFile: "",
            cost: "",
            goodsOutRegisterId: 1,
            sealNumber: 1,
            dimensionFilePath: "",
            uploadReceivingFile: "",
            goodsStatus: ""
        }]);
    };

    const handleDuplicateInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];

        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
        if (fieldToUpdate) {

            setTransporterDetailsError({
                ...transporterDetailsError,
                [field + index]: !value
            })

            if (field === 'pickup') {
                fieldToUpdate['cost'] = 0;
            }
            fieldToUpdate[field] = value;

            setInputFields(updatedInputFields);

            const data = []
            updatedInputFields && updatedInputFields?.map((res) => {
                return data.push(
                    {
                        id: res.id || null,
                        goodsExpectedAt: res.goodsExpectedAt || 2,  // because its an warehouse
                        vendorId: res.vendorId,
                        pickup: res.pickup,
                        pickupVehicleTypeId: res.pickupVehicleTypeId,
                        vehicleNumber: res.vehicleNumber,
                        driverName: res.driverName,
                        driverNumber: res.driverNumber,
                        number: res.number,
                        comment: res.comment,
                        goodsCondition: res.goodsCondition,
                        uploadFile: res.uploadFile,
                        cost: Number(res.cost),
                        packages: Number(res.packages),
                        grossWeight: Number(res.grossWeight),
                        goodsOutRegisterId: 1,
                        sealNumber: 1,
                        dimensionFilePath: "",
                        uploadReceivingFile: res.uploadReceivingFile,
                        goodsStatus: res.goodsStatus,
                        expectedTotalPackages: res.expectedTotalPackages || 0,
                        expectedGrossWeight: res.expectedGrossWeight || 0
                    }
                )
            })

            handleInputChange(data, { id: 'transporters' });

        }
    };

    const removeInputField = (key) => {

        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields?.map((res) => {
            return data.push(
                {
                    id: res.id || null,
                    goodsExpectedAt: res.goodsExpectedAt || 2, // because its an warehouse
                    vendorId: res.vendorId,
                    pickup: res.pickup,
                    pickupVehicleTypeId: res.pickupVehicleTypeId,
                    vehicleNumber: res.vehicleNumber,
                    driverName: res.driverName,
                    driverNumber: res.driverNumber,
                    number: res.number,
                    comment: res.comment,
                    goodsCondition: res.goodsCondition,
                    uploadFile: res.uploadFile,
                    cost: Number(res.cost),
                    packages: Number(res.packages),
                    grossWeight: Number(res.grossWeight),
                    goodsOutRegisterId: 1,
                    sealNumber: 1,
                    dimensionFilePath: "",
                    uploadReceivingFile: res.uploadReceivingFile,
                    goodsStatus: res.goodsStatus,
                    expectedTotalPackages: res.expectedTotalPackages || 0,
                    expectedGrossWeight: res.expectedGrossWeight || 0

                }
            )

        })
        handleInputChange(data, { id: 'transporters' });
    };

    return (
        <div className=''>

            <div className="cmd">
                <h5 className="m-0 p-0">Add New Transporter</h5>

            </div>

            {inputFields?.map((field, key) => (
                <div className='d-flex' key={field.key}>
                    {
                        !formDetailsData?.isRceivedQuery &&
                        <div className="mt-4 me-2" >
                            {
                                key !== 0 ? (
                                    <Button
                                        sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                        onClick={() => removeInputField(field.key)}
                                        variant='outlined'
                                    >
                                        <Close />
                                    </Button>
                                )
                                    :
                                    <Button sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                        <Add />
                                    </Button>
                            }
                        </div>
                    }

                    <div className="col-md-12">
                        <div className="row my-2">
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: 'pickup',
                                            label: formconstant.pickuptype,
                                            type: "select",
                                            value: field.pickup,
                                            required: true,
                                            disable: formDetailsData?.isRceivedQuery || (field.goodsStatus === 3),

                                            error: transporterDetailsError['pickup' + key],
                                            item: PickUpType,
                                            onChange: (value) => {
                                                handleDuplicateInputChange(field.key, 'pickup', value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            {
                                field.pickup === 1 &&
                                <>
                                    <div className="col-md-2">
                                        <Dropdown
                                            field={
                                                {
                                                    id: 'vendorId',
                                                    label: formconstant.transportname,
                                                    type: "select",
                                                    value: field.vendorId,
                                                    required: true,
                                                    disable: formDetailsData?.isRceivedQuery || (field.goodsStatus === 3),

                                                    error: transporterDetailsError['vendorId' + key],
                                                    item: transportNameItem,
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'vendorId', value, key);
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                </>
                            }

                            {
                                field.pickup !== 2 &&
                                <>
                                    <div className="col-md-2">
                                        <Dropdown
                                            field={
                                                {
                                                    id: 'pickupVehicleTypeId',
                                                    label: formconstant.pickupvehicaltype,
                                                    type: "select",
                                                    value: field.pickupVehicleTypeId,
                                                    required: true,
                                                    disable: formDetailsData?.isRceivedQuery || (field.goodsStatus === 3),

                                                    error: transporterDetailsError['pickupVehicleTypeId' + key],
                                                    item: vehicalTypeItem,
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'pickupVehicleTypeId', value, key);
                                                    }
                                                }
                                            }
                                        />
                                    </div>

                                    <div className="col-md-2">
                                        <InputField
                                            field={
                                                {
                                                    id: 'vehicleNumber',
                                                    label: formconstant.vehicalno,
                                                    type: "text",
                                                    value: field.vehicleNumber,
                                                    required: true,
                                                    disable: formDetailsData?.isRceivedQuery || (field.goodsStatus === 3),

                                                    error: transporterDetailsError['vehicleNumber' + key],
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'vehicleNumber', value, key);
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <InputField
                                            field={
                                                {
                                                    id: 'driverName',
                                                    label: formconstant.drivername,
                                                    type: "text",
                                                    value: field.driverName,
                                                    disable: formDetailsData?.isRceivedQuery || (field.goodsStatus === 3),
                                                    required: true,
                                                    error: transporterDetailsError['driverName' + key],
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'driverName', value, key);
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <InputField
                                            field={
                                                {
                                                    id: 'driverNumber',
                                                    label: formconstant.driverno,
                                                    type: "number",
                                                    value: field.driverNumber,
                                                    disable: formDetailsData?.isRceivedQuery || (field.goodsStatus === 3),
                                                    required: true,
                                                    error: transporterDetailsError['driverNumber' + key],
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'driverNumber', value, key);
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                </>
                            }


                            <div className="col-md-2">
                                <div className={`d-flex ${formDetailsData.id && 'gstWrapper'} `}>
                                    {
                                        formDetailsData.id && <InputField
                                            field={
                                                {
                                                    id: 'expectedTotalPackages',
                                                    label: formconstant.expectedpackages,
                                                    type: "number",
                                                    value: field.expectedTotalPackages,
                                                    required: true,
                                                    disable: true,
                                                    error: transporterDetailsError['expectedTotalPackages' + key],
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'expectedTotalPackages', value, key);
                                                    }
                                                }
                                            }
                                        />
                                    }
                                    <InputField
                                        field={
                                            {
                                                id: 'packages',
                                                label: formconstant.packages,
                                                type: "number",
                                                value: field.packages,
                                                required: true,
                                                disable: (field.goodsStatus === 3),
                                                error: transporterDetailsError['packages' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'packages', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className={`d-flex ${formDetailsData.id && 'gstWrapper'} `}>
                                    {
                                        formDetailsData.id && <InputField
                                            field={
                                                {
                                                    id: 'expectedGrossWeight',
                                                    label: formconstant.expectedgrossweight,
                                                    type: "number",
                                                    value: field.expectedGrossWeight,
                                                    required: true,
                                                    disable: true,
                                                    error: transporterDetailsError['expectedGrossWeight' + key],
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'expectedGrossWeight', value, key);
                                                    }
                                                }
                                            }
                                        />
                                    }
                                    <InputField
                                        field={
                                            {
                                                id: 'grossWeight',
                                                label: formconstant.grossweight,
                                                type: "number",
                                                value: field.grossWeight,
                                                required: true,
                                                disable: (field.goodsStatus === 3),
                                                error: transporterDetailsError['grossWeight' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'grossWeight', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: 'goodsCondition',
                                            label: formconstant.goodsconditions,
                                            type: "select",
                                            value: field.goodsCondition,
                                            required: true,
                                            disable: (field.goodsStatus === 3),
                                            error: transporterDetailsError['goodsCondition' + key],
                                            item: GoodsConditions,
                                            onChange: (value) => {
                                                handleDuplicateInputChange(field.key, 'goodsCondition', value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            {
                                (field.goodsCondition === 4 || field.goodsCondition === 5) &&
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: 'number',
                                                label: formconstant.number,
                                                type: "number",
                                                value: field.number,
                                                required: true,
                                                disable: (field.goodsStatus === 3),
                                                error: transporterDetailsError['number' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'number', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            }
                            {
                                field.goodsCondition && field.goodsCondition !== 1 && <>
                                    <div className="col-md-2">
                                        <InputField
                                            field={
                                                {
                                                    id: 'comment',
                                                    label: formconstant.comments,
                                                    type: "comment",
                                                    value: field.comment,
                                                    required: true,
                                                    disable: (field.goodsStatus === 3),
                                                    error: transporterDetailsError['comment' + key],
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'comment', value, key);
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <InputFileField
                                            className='mt-3'
                                            field={{
                                                id: "uploadFile",
                                                label: formconstant.uploadpic,
                                                type: 'file',
                                                required: true,
                                                disable: (field.goodsStatus === 3),
                                                error: transporterDetailsError['uploadFile' + key],
                                                helperText: 'Upload Pan Card Required',
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'uploadFile', value, key);
                                                }
                                            }}
                                        />
                                    </div>

                                </>
                            }

                            {
                                field.pickup === 2 &&
                                <div className="col-md-2">
                                    <InputFileField
                                        className='mt-3'
                                        field={{
                                            id: "uploadReceivingFile",
                                            label: 'Upload Receiving File',
                                            type: 'file',
                                            required: true,
                                            disable: (field.goodsStatus === 3),
                                            error: transporterDetailsError['uploadReceivingFile' + key],
                                            helperText: 'Upload Pan Card Required',
                                            onChange: (value) => {
                                                handleDuplicateInputChange(field.key, 'uploadReceivingFile', value, key);
                                            }
                                        }}
                                    />
                                </div>
                            }

                            {
                                field.pickup === 1 &&
                                <>
                                    <div className="col-md-2">
                                        <InputField
                                            field={
                                                {
                                                    id: 'cost',
                                                    label: formconstant.cost,
                                                    type: "number",
                                                    value: field.cost,
                                                    disable: formDetailsData?.isRceivedQuery || (field.goodsStatus === 3),

                                                    required: true,
                                                    error: transporterDetailsError['cost' + key],
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'cost', value, key);
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                </>
                            }


                            {
                                (formDetailsData.id && !formDetailsData.isRceivedQuery) &&
                                <div className="col-md-2">
                                    <Dropdown
                                        field={
                                            {
                                                id: 'goodsStatus',
                                                label: "Goods Status",
                                                type: "select",
                                                value: field.goodsStatus,
                                                required: true,
                                                error: transporterDetailsError['goodsStatus' + key],
                                                item: WareHouseGoodsStatus.filter(object => [2, 3].includes(object.value)),
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'goodsStatus', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            }



                        </div>
                    </div>

                </div>
            ))}

        </div>
    );
}


const Dimension = ({
    handleBasicInputChange,
    dimentionDetailsError,
    setDimentionDetailsError,
    formDetailsData

}) => {

    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            length: '',
            width: '',
            height: '',
            boxes: '',
            airVolumeWeight: ''
        },
    ]);

    useEffect(() => {
        if (formDetailsData?.dimensions && formDetailsData?.dimensions?.length !== 0) {
            let item = formDetailsData.dimensions?.map(res => (
                {
                    ...res,
                    key: generateUniqueKey(),
                }
            ));
            setInputFields(item);
        }
    }, [formDetailsData])


    const addInputField = () => {
        setInputFields([...inputFields, { key: generateUniqueKey(), length: '', width: '', height: '', boxes: '', airVolumeWeight: '' }]);
    };



    const handleInputChange = (key, field, value, index) => {

        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

        if (fieldToUpdate) {
            setDimentionDetailsError({
                ...dimentionDetailsError,
                [field + index]: !value
            })

            fieldToUpdate[field] = value;
            setInputFields(updatedInputFields);

            const data = []
            updatedInputFields && updatedInputFields?.map((res) => {
                return data.push(
                    {
                        id: res?.id,
                        length: res.length,
                        width: res.width,
                        height: res.height,
                        boxes: res.boxes,
                        // airVolumeWeight: res.airVolumeWeight
                    }
                )
            })

            handleBasicInputChange(data, { id: 'dimensions' })
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields?.map((res) => {
            return data.push(
                {
                    id: res?.id,
                    length: res.length,
                    width: res.width,
                    height: res.height,
                    boxes: res.boxes,
                    // airVolumeWeight: res.airVolumeWeight
                }
            )

        })
        handleBasicInputChange(data, { id: 'dimensions' })

    };


    return <>
        {

            <div className=''>
                <div className="d-flex align-items-center">
                    <h4 className="m-0">Dimensions</h4>
                </div>


                {inputFields?.map((field, key) => (
                    <div className='cmd' key={field.key}>

                        <div className='me-2' >
                            {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                <Add />
                            </Button> : (
                                <Button
                                    sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                    onClick={() => removeInputField(field.key)}
                                    variant='contained'
                                >
                                    <Close />
                                </Button>
                            )}
                        </div>
                        <div className="w-100">
                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "length",
                                                label: "Length",
                                                type: 'number',
                                                value: field.length,
                                                required: true,
                                                error: dimentionDetailsError["length" + key],
                                                helperText: 'Contact Person Name Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'length', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "width",
                                                label: "Width",
                                                type: 'number',
                                                value: field.width,
                                                required: true,
                                                error: dimentionDetailsError["width" + key],
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'width', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        // className="mt-0"
                                        field={
                                            {
                                                id: "height",
                                                label: "Height",
                                                type: 'number',
                                                value: field.height,
                                                required: true,
                                                error: dimentionDetailsError["height" + key],
                                                helperText: 'Department Type Required',

                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'height', value, key);
                                                }
                                            }
                                        }

                                    />

                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "boxes",
                                                label: "Boxes",
                                                type: 'number',
                                                value: field.boxes,
                                                required: true,
                                                error: dimentionDetailsError["boxes" + key],
                                                helperText: 'Mobile No Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'boxes', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                {/* <div className="col-md-2">
                    <InputField
                        field={
                            {
                                id: "airVolumeWeight",
                                label: "Air Volume Weight",
                                type: 'number',
                                value: field.airVolumeWeight,
                                required: false,
                                error: dimentionDetailsError["airVolumeWeight" + key],
                                disable: true,
                                helperText: 'Land Line Required',
                                // onChange: (value) => {
                                //     handleInputChange(field.key, 'airVolumeWeight', value);
                                // }
                            }
                        }
                    />
                </div> */}
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        }


    </>
}

import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { GetMasterAccountGroupByPage, PostMasterAccountGroup, PostMasterDepartment, UpdateMasterAccountGroup, UpdateMasterAccountGroupStatus } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import {columnConstant} from '../../constant/formconstant';
import { SmallSwitch } from '../../component/controls';

const authToken = localStorage.getItem('authToken')
const headers = {
  Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};

const Acgroup = () => {
  const dispatch = useDispatch()
  const [accountgroupMasterData, setAccountgroupMasterData] = useState([])
  const [totaldatalength, setTotalDataLength] = useState(0)

  const fatch_MasterDataBYPagination = (item) => {
    MasterCallBypagination({
      API: GetMasterAccountGroupByPage,
      callback: setTableDataHandler, dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: item
    })
  }

  useEffect(() => {
    fatch_MasterDataBYPagination();

  }, []);

  const setTableDataHandler = ({ response },) => {
    const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
      sn: index + 1,
      id: res.id,
      acgroupcode: res.accountGroupCode,
      acgroup: res.name,
      status: res.status
    }));
    setAccountgroupMasterData(newmasterdata);
    setTotalDataLength(response?.data?.data?.totalRecords)
  }


  const columns = [
    {
      id: 'sn',
      label: columnConstant.sno,
      numeric: false,
    },
  
    {
      id: 'acgroupcode',
      label: columnConstant.acgroupcode,
      numeric: false,
    },
    {
      id: 'acgroup',
      label: columnConstant.acgroup,
      numeric: false,
    },

    {
      id: 'action',
      label: columnConstant.action,
      numeric: false,
    },
    // You can add more column definitions as needed
  ];


  const ActionCell = ({ row }) => {
    const handleSwitchChange = () => {       
      const data = {
          id: row?.id,   
        }
        GetCaller({
          API: UpdateMasterAccountGroupStatus,
          dispatch: (e) => {
            dispatch({ type: e.type, payload: e.payload })
          },
          item: data,
          callback: ({response}) => {
            fatch_MasterDataBYPagination();
              }               
            });       
          }

    return <>
      {/* <AcGroupDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} /> */}
      <SmallSwitch
        onChange={handleSwitchChange}
        checked={row?.status}
      />
    </>



  }




  const TableTool = ({ }) => {
    return <>
    </>
  }



  return (
    <>
      <MultiTable
        data={accountgroupMasterData}
        columns={columns}
        title={columnConstant.accountype}
        tabletool={TableTool}
        actioncell={ActionCell}
        totaldatalength={totaldatalength}
        paginationhandler={fatch_MasterDataBYPagination}
      />
    </>
  )
}

export default Acgroup



const AcGroupDrawer = ({ callAPI, editor, row }) => {
  const [closeValidator, setcloseValidator] = useState(false)

  const [acGroupName, setAcGroupName] = useState(row?.acgroup || '')
  const [acGroupNameError, setAcGroupNameError] = useState(false)
  const [acGroupCode, setAcGroupCode] = useState(row?.acgroupcode || '')
  const [acGroupCodeError, setAcGroupCodeError] = useState(false)
  const dispatch = useDispatch()

  const drawerOpenCallbackHandler = () => {
    setAcGroupName(row?.acgroup || '')
    setAcGroupCode(row?.acgroupcode || '')
    setAcGroupNameError(false)
    setAcGroupCodeError(false)

  }
  setcloseValidator(true)

  useEffect(() => {
    setcloseValidator(false)
    if (editor) {
      setAcGroupName(row?.acgroup)
      setAcGroupCode(row?.acgroupcode)

    } else {
      setAcGroupName('')
      setAcGroupCode('')
    }
  }, [closeValidator]);

  const handleSubmit = () => {
    if (!acGroupName || !acGroupCode) {
      setAcGroupNameError(!acGroupName)
      setAcGroupCodeError(!acGroupCode)

    }
    else {
      const postmasterareaData = editor ? {
        id: row.id,
        name: acGroupName,
        accountGroupCode: acGroupCode,
        status: row.status
        } : {
          name: acGroupName,
          accountGroupCode: acGroupCode,
        }             
        editor ?
          PostCaller({
            API: UpdateMasterAccountGroup,
            callback: () => callAPI(),
            dispatch: (e) => {
              dispatch({ type: e.type, payload: e.payload })
            },
            item: postmasterareaData
          })
  
          :    
          PostCaller({
            API: PostMasterAccountGroup,
            callback: () => callAPI(),
            dispatch: (e) => {
              dispatch({ type: e.type, payload: e.payload })
            },
            item: postmasterareaData
          })
      setcloseValidator(true)
  }

  }

  const handleInputChange = (value, field) => {
    switch (field.id) {
      case 'acgroup':
        setAcGroupName(value)
        setAcGroupNameError(!value);
        break;
      case 'acgroupcode':
        setAcGroupCode(value)
        setAcGroupCodeError(!value);
        break;


      default:
        break;
    }
  };



  const drawerFormFields = editor ?
    {
      title: columnConstant.updateacgroup,
      icon: <i className="las la-edit"></i>,
      tooltitle: columnConstant.editacgroup,
      iconclass: 'noClass',
      defaultbtnfalse: true

    }
    : {
      title: columnConstant.addacgroup,
      tooltitle: columnConstant.addacgroup,
      defaultbtnfalse: true
    }





  const FieldData = [
    {
      id: 'acgroup',
      label: columnConstant.acgroup,
      type: 'text',
      value: acGroupName,
      required: true,
      error: acGroupNameError,
      onChange: handleInputChange,
    },

    {
      id: 'acgroupcode',
      label: columnConstant.acgroupcode,
      type: 'text',
      value: acGroupCode,
      required: true,
      error: acGroupCodeError,
      onChange: handleInputChange,
    },


  ];

  const divisionDrawerody = () => {
    return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
  }
  return <>
    <DivisonDrawer
      drawerFormFields={drawerFormFields}
      closeValidator={closeValidator}
      openCallBack={drawerOpenCallbackHandler}
      divisiondrawerbody={divisionDrawerody}
    />

  </>

}


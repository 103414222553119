import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PostAirExportBuyingingCharges, PostAirExportSellingCharges } from "../../../../services/APIConstant/enquiryConstant";
import GetCaller, { PostCaller } from "../../../../services/apiServices";
import Dropdown, { InputField, InputFileField, TextAreafield } from "../../../component/controls";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { AirExportCustomerTypeFrightOptions, ApplicableOn, IntialStockProvider, applicableFormat } from "../../../../services/modalData";
import { Add, CardGiftcard, Close } from "@mui/icons-material";
import Transition from "../../../../services/utilities/transition";
import { AddAirExportPlannerJob, AddSplitJobByEnquiryID, UpdateAirExportCDDJob } from "../../../../services/APIConstant/jobConstant";
import { columnConstant } from "../../../constant/formconstant";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Job_MawbRate } from "./mawbRate";
import { Job_HawbRate } from "./hawbRate";
import { ShipperInvoiceNumber } from "./shipperInvoicenum";
import { TcNumber } from "./tcNumbers";
import { SlotNumber } from "./slotNumber.";
import { Shipping_Bill } from "./shippingBill";
import job from '../../../assets/img/icons/job.png';
import { GetEmployeeByPage } from "../../../../services/constant";
import { useNavigate } from "react-router-dom";
import { airexportjoblist } from "../../../../navigation/constant";

export const CreateCddForm = ({ row, callBack, employeeItem }) => {
    const navigate = useNavigate()
    const [employeeitem, setEmployeeItem] = useState([])
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        checkListByEmployeeId: row?.checkListByEmployeeId || '',
        checkListByEmployeeFile: '',
        checkListApprovedByShipper: '',
        appraisalSuprident: '',
        appraisalDC: '',
        shippingBillNo: [],
        tcNumbers: [],
        slotTime: []
    })


    useEffect(() => {
        setFormData({
            ...formData,
            ...row,
            ['appraisalSuprident']: row?.appraisalSuprident === true ? 1 : 2,
            ['appraisalDC']: row?.appraisalDC === true ? 1 : 2
        })
    }, [row])


    const handleClose = () => {
        setOpen(false)
        setFormData({})
    }

    const handleSubmit = () => {

        const requestData = (() => {
            const data = {
                id: row?.id,
                enquiryId: row?.enquiryId,
                checkListByEmployeeId: formData?.checkListByEmployeeId,
                checkListByEmployeeFile: formData?.checkListByEmployeeFile,
                checkListApprovedByShipper: formData?.checkListApprovedByShipper === 1 ? true : false,
                tcNumbers: formData?.tcNumbers,
                slotTime: formData?.slotTime,
                // checkListByEmployeeDocument:formData?.checkListByEmployeeDocument

            }

            if (row.bizSubType !== 'FF Only') data.shippingBillNo = formData?.shippingBillNo
            if (row.bizSubType !== 'FF Only') data.appraisalSuprident = formData?.appraisalSuprident === 1 ? true : false
            if (row.bizSubType !== 'FF Only') data.appraisalDC = formData?.appraisalDC === 1 ? true : false

            return data;
        })()

        const jobDatahasUndefindValues = (obj) => {


            let changes = {};

            Object.entries(obj).forEach(([key, value]) => {
                if (
                    !value && (
                        key === 'checkListByEmployeeId' ||
                        key === 'checkListByEmployeeFile' ||
                        key === 'shippingBillNo' ||
                        key === 'tcNumbers' ||
                        key === 'slotTime'
                    )) {
                    changes[key + 'error'] = !value;
                }
            });

            console.log('changes', changes)

            return changes;
        };
        const addjobformchanges = jobDatahasUndefindValues(requestData)
        debugger
        if (Object.keys(addjobformchanges).length > 0) {
            setFormData({
                ...formData,
                ...addjobformchanges
            })
        } else {

            PostCaller({
                API: UpdateAirExportCDDJob,
                callback: () => {
                    navigate(airexportjoblist)
                },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                contenttype: true,
                item: requestData
            })
        }
    }

    const handleBasicInputChange = (value, field) => {
        setFormData({
            ...formData,
            [field.id]: value,
            [field.id + 'error']: !value
        })
    }

    useEffect(() => {
        GetCaller({
            API: GetEmployeeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const employeeItem = data?.map(res => ({
                    value: res?.id,
                    name: res?.name
                }))
                setEmployeeItem(employeeItem)
            }
        })

    }, [])

    return <>




        <div className='hr-form-box'>

            <DialogContent>

                <div className=''>
                    <div className="row mb-3">

                        <div className="col-md-3">
                            <Dropdown
                                field={
                                    {
                                        id: "checkListByEmployeeId",
                                        label: "Checklist Approved By ",
                                        value: formData?.checkListByEmployeeId,
                                        required: true,
                                        error: formData?.checkListByEmployeeIderror,
                                        item: employeeitem,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                        <div className="col-md-3">
                            <InputFileField
                                className='mt-3'
                                field={
                                    {
                                        id: "checkListByEmployeeFile",
                                        label: "Checklist File",
                                        type: 'file',
                                        value: '',
                                        required: true,
                                        error: formData?.checkListByEmployeeFileerror,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                        <div className="col-md-3">
                            <Dropdown
                                field={
                                    {
                                        id: "checkListApprovedByShipper",
                                        label: "Checklist Approved By Shipper",
                                        value: formData?.checkListApprovedByShipper,
                                        required: true,
                                        error: formData?.checkListApprovedByShippererror,
                                        item: applicableFormat,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>

                        {
                            row.bizSubType !== 'FF Only' && <>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "appraisalSuprident",
                                                label: "Appraisal Suprident ",
                                                value: formData?.appraisalSuprident,
                                                required: true,
                                                error: formData?.appraisalSupridenterror,
                                                item: applicableFormat,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown

                                        className={'mt-4'}
                                        field={
                                            {
                                                id: "appraisalDC",
                                                label: "Appraisal DC ",
                                                value: formData?.appraisalDC,
                                                required: true,
                                                error: formData?.appraisalDCerror,
                                                item: applicableFormat,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                            </>
                        }

                        {
                            row.bizSubType !== 'FF Only' &&
                            <div className="col-md-3">
                                < Shipping_Bill
                                    data={formData.shippingBillNo}
                                    callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                    fieldData={formData}
                                />
                            </div>
                        }



                        <div className="col-md-3">
                            < TcNumber

                                data={formData.tcNumbers}
                                callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                fieldData={formData}
                            />
                        </div>
                        <div className="col-md-3">
                            < SlotNumber

                                data={formData.slotTime}
                                callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                fieldData={formData}
                            />
                        </div>


                    </div>
                </div >
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                <Button onClick={handleSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
            </DialogActions>
        </div>




    </>
}
import { BaseURL } from "../constant";
// Air Export
export const PostAirExportEnquiry = () => `${BaseURL.URL}Enquiry/AirExportEnquiry/Add`;
export const UpdateAirExportEnquiry = () => `${BaseURL.URL}Enquiry/AirExportEnquiry/Update`;
export const GetAirExportEnquiryDataByPage = (item) => `${BaseURL.URL}Enquiry/AirExportEnquiry/Search?searchKey=${item.searchKey || ''}&sortKey=${item.sortKey || ''}&sortDirection=${item.sortDirection || ''}&itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item.status || false}`


// Enquiry Air Import 
export const GetAirImportEnquiryDataByPage = (item) => `${BaseURL.URL}Enquiry/AirImportEnquiry/Search?searchKey=${item.searchKey || ''}&sortKey=${item.sortKey || ''}&sortDirection=${item.sortDirection || ''}&itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item.status || false}`
export const PostAirImportEnquiry = () => `${BaseURL.URL}Enquiry/AirImportEnquiry/Add`;

// Inital Quote APIs
export const PostIntialPricingSheet = () => `${BaseURL.URL}Enquiry/AirExportPricingSheet/Add`;
export const GetInitailPricingSheetById = (id) => `${BaseURL.URL}Enquiry/AirExportPricingSheet/${id}`;

export const GetBuyingChargesById = (id) => `${BaseURL.URL}Enquiry/AirExportBuyingCharges/${id}`;
export const PostAirExportBuyingingCharges = () => `${BaseURL.URL}Enquiry/AirExportBuyingCharges/Add`;
export const UpdateAirExportBuyingingCharges = () => `${BaseURL.URL}Enquiry/AirExportBuyingCharges/Update`;

export const GetSellingChargesById = (id) => `${BaseURL.URL}Enquiry/AirExportSellingCharges/${id}`;
export const PostAirExportSellingCharges = () => `${BaseURL.URL}Enquiry/AirExportSellingCharges/Add`;
export const UpdateAirExportSellingingCharges = () => `${BaseURL.URL}Enquiry/AirExportSellingCharges/Update`;
export const UpdateAirExportFinalSellingingCharges = () => `${BaseURL.URL}Enquiry/AirExportFinalSellingCharges/Submit`;

export const UpdateEnquiryExportStatus = () => `${BaseURL.URL}Enquiry/AirExportEnquiryStatus/Add`;
export const GetAirExportSellingQuotesData = (item) => `${BaseURL.URL}Enquiry/AirExportSellingToCustomerCharges?enquiryId=${item.id}`;

// final Quote API 

export const ApproveAirExportFinalBuying = (item) => `${BaseURL.URL}Enquiry/ApproveAirExportFinalBuying?enquiryId=${item.id}&status=${item.status || false}`;

export const GetAirExportFinalBuyingChargesById = (id) => `${BaseURL.URL}Enquiry/AirExportFinalBuyingCharges/${id}`;
export const PostAirExportFinalBuyingingCharges = () => `${BaseURL.URL}Enquiry/AirExportFinalBuyingCharges/Add`;
export const UpdateAirExportFinalBuyingingCharges = () => `${BaseURL.URL}Enquiry/AirExportFinalBuyingCharges/Update`;

export const GetAirExportFinalSellingChargesById = (id) => `${BaseURL.URL}Enquiry/AirExportFinalSellingCharges/${id}`;
export const PostAirExportFinalSellingCharges = () => `${BaseURL.URL}Enquiry/AirExportFinalSellingCharges/Add`;
export const UpdateAirExportFinalSellingCharges = () => `${BaseURL.URL}Enquiry/AirExportFinalSellingCharges/Update`;


// Flight Register 

export const AddFlightRegister = () => `${BaseURL.URL}Enquiry/AirExportFlightRegister/Add`;


import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetMasterAreaByPage, GetMasterSectorByPage, PostMasterSector, UpdateMasterSector, UpdateMasterSectorStatus } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import DivisonDrawer from '../../component/divisionDrawer';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm'
import { SectorTypeItem } from '../../../services/modalData';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';
import { trimInputvalue } from '../../../services/utilities/unittrim';


const Sectormaster = () => {
    const dispatch = useDispatch();
    const [sectorMasterData, setSectorMasterData] = useState([])
    const [areaItem, setAreaItem] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterSectorByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item,
        })
    }

    const set_AreaMasterItem = () => {
        debugger
        GetCaller({
            API: GetMasterAreaByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                const areaitem = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setAreaItem(areaitem)
            },

        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
        set_AreaMasterItem()
    }, []);

    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            sectortype: SectorTypeItem[res.sectorType - 1].name,
            sectortypeKey: res.sectorType,
            sectorname: res.name,
            areaName: res.areaName,
            areaId: res.areaId,
            status: res.status
        }));
        setSectorMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'sectorname',
            label: 'Sector Name',
            numeric: false,
        },
        {
            id: 'sectortype',
            label: 'Sector Type',
            numeric: false,
        },
        {
            id: 'areaName',
            label: 'Area',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];


    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateMasterSectorStatus,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({ response }) => {
                    fatch_MasterDataBYPagination();
                }

            });
        }

        return <>
            <SectorDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} areaItem={areaItem} />
          
            <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>

    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <SectorDrawer callAPI={fatch_MasterDataBYPagination} areaItem={areaItem} />
        </>
    }

    return (
        <>
            {/* filterItems={filterItems} */}
            <MultiTable
                title={'Sector'}
                columns={columns}
                data={sectorMasterData}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default Sectormaster;



const SectorDrawer = ({ callAPI, editor, row, areaItem }) => {
    const [closeValidator, setcloseValidator] = useState(false);
    const [sectorType, setSectorType] = useState(row?.sectortypeKey || '');
    const [sectorTypeError, setSectorTypeError] = useState(false);
    const [sectorName, setSectorName] = useState(row?.sectorname || '')
    const [sectorNameError, setSectorNameError] = useState(false)
    const [area, setArea] = useState((row?.sectortypeKey === 1 ? row?.areaId : '') || '')
    const [areaerror, setAreaError] = useState(false)
    const dispatch = useDispatch()

    console.log(areaItem)

    const drawerOpenCallbackHandler = () => {
        setSectorType(row?.sectortypeKey || '')
        setSectorName(row?.sectorname || '')
        setArea(row?.areaId || '')
        setSectorNameError(false)
        setSectorTypeError(false)
        setAreaError(false)
    }

    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);



    const handleSubmit = () => {
const trimsectorname = trimInputvalue(sectorName)

        if (!sectorType || !trimsectorname || (!area && sectorType === 1)) {
            setSectorNameError(!trimsectorname)
            setSectorTypeError(!sectorType)
            setAreaError(!area)
        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: trimsectorname,
                sectorType: sectorType,
                areaId: sectorType === 1 ? area : null,
                status: row.status
            } : {
                name: trimsectorname,
                sectorType: sectorType,
                areaId: sectorType === 1 ? area : null
            }

            PostCaller({
                API: editor ? UpdateMasterSector : PostMasterSector,
                callback: () => callAPI(),
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
            })

            setcloseValidator(true)
        }

    }



    const handleInputChange = (value, field) => {

        switch (field.id) {
            case 'sectortype':
                setSectorType(value)
                setSectorTypeError(!value);
                if (value !== 1) {
                    setArea('')
                    setAreaError(false)
                }
                break;
            case 'sectorname':
                setSectorName(value)
                setSectorNameError(!value);
                break;
            case 'area':
                setArea(value)
                setAreaError(!value);
                break;

            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Sector Master",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Sector',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Sector Master",
            tooltitle: 'Add Sector',
            defaultbtnfalse: true
        }

    const FieldData = [
        {
            id: 'sectortype',
            label: 'Sector Type ',
            type: 'select',
            value: sectorType,
            required: true,
            error: sectorTypeError,
            helperText: 'Sector Type required',
            onChange: handleInputChange,
            item: SectorTypeItem

        },
        {
            id: 'sectorname',
            label: 'Sector Name',
            type: 'text',
            value: sectorName,
            required: true,
            error: sectorNameError,
            helperText: 'Sector Name required',
            onChange: handleInputChange,
        },
        {
            id: 'area',
            label: 'Select Area',
            type: 'select',
            value: area,
            required: true,
            disable: sectorType === 1 ? false : true,
            error: areaerror,
            helperText: 'Sector Name required',
            onChange: handleInputChange,
            item: areaItem,
            isHide:sectorType === 1 ? false : true,
        },
    ];

    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }

    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            divisiondrawerbody={divisionDrawerody}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}

        />

    </>

}






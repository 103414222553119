import React, { useEffect, useState } from "react";
import Dropdown from "../../component/controls";
import EnquiryAirExport from "./airexport";
import { AirExportCustomerTypeFrightOptions, AirImportCustomerTypeFrightOtions, FreightTypeDirectType, FreightTypeFPC, FreightTypeODC, FreightTypeONP, FreightTypeSubAgent, FreightTypeSubType, TypeOfCustomer, TypeofShipment } from "../../../services/modalData";
import EnquiryAirImport from "./airImport";

const AirImportEnquiryLayout = () => {
    const [FreightItems, setFreightItems] = useState([])
    const [FreightServiceSubType, setFreightServiceSubType] = useState([])
    const [BizType, setBizType] = useState([])
    const [BizSubType, setBizSubType] = useState([])
    const [BizCategoryType, setBizCategoryType] = useState([])

    const [MultiPickUp, setMultiPickUp] = useState(false)
    const [MultiDelivery, setMultiDelivery] = useState(false)

    const [EnquiryFormData, setEnquiryFormData] = useState({
        enquiryType: 2,
        CustomerType: "",
        FreightServiceType: "",
        FreightServiceSubType: "",
        BizCategoryType: "",
        BizType: "",
        BizSubType: ""
    })

    const handleBasicInputChange = (value, field) => {
        debugger
        setEnquiryFormData({
            ...EnquiryFormData,
            [field.id]: value,
            [field.id + 'error']: !value,
        })
        console.log(field.id, value)
    }

    function hasUndefinedValues(obj) {
        let changes = {};

        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                changes[key + 'error'] = !value;
            }
        });

        console.log(changes)
        return changes;
    }
    const handleFormValidation = () => {
        const EnquiryFormChanges = hasUndefinedValues(EnquiryFormData)
        setEnquiryFormData({
            ...EnquiryFormData,
            ...EnquiryFormChanges
        })

    }



    useEffect(() => {
        console.log("enquiry common data", EnquiryFormData)
    }, [EnquiryFormData])


    return <>
        <div className="hr-form-box">
            <h5>
                Create Enquiry
            </h5>
            <div className="row">
                <div className="col-md-2">
                    <Dropdown
                        field={
                            {
                                id: "enquiryType",
                                label: "Type Of Shipment ",
                                value: EnquiryFormData.enquiryType,
                                required: true,
                                error: EnquiryFormData.enquiryTypeerror,
                                helperText: 'Inco Terms Required',
                                item: [{
                                    name: 'Air Import', value: 2
                                }],
                                onChange: handleBasicInputChange
                            }
                        }
                    />

                </div>
                {
                    EnquiryFormData.enquiryType &&
                    <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "CustomerType",
                                    label: "Customer Type ",
                                    value: EnquiryFormData.CustomerType,
                                    required: true,
                                    error: EnquiryFormData.CustomerTypeerror,
                                    helperText: 'Customer Type Required',
                                    item: AirImportCustomerTypeFrightOtions,
                                    onChange: (value, field) => {
                                        let option = {
                                            [field.id]: value,
                                            [field.id + 'error']: !value,
                                            ['FreightServiceType']: "",
                                            ['FreightServiceSubType']: "",
                                            ['BizCategoryType']: "",
                                            ['BizType']: "",
                                            ['BizSubTypeerror']: false,
                                            ['FreightServiceTypeerror']: false,
                                            ['FreightServiceSubTypeerror']: false,
                                            ['BizCategoryTypeerror']: false,
                                            ['BizTypeerror']: false,
                                            ['BizSubTypeerror']: false
                                        }

                                        setEnquiryFormData({
                                            ...EnquiryFormData,
                                            ...option
                                        })


                                        let freightItem = []
                                        freightItem = AirImportCustomerTypeFrightOtions?.find(item => item.value === value)
                                        // console.log('FreightItems', freightItem.FrightServiceType)
                                        setFreightItems(freightItem.FrightServiceType);

                                        if (freightItem.FrightServiceType.length === 0) {
                                            setFreightServiceSubType([]);
                                            setBizType([])
                                            setBizSubType([])
                                            setBizCategoryType([])
                                        }
                                    }
                                }
                            }
                        />

                    </div>
                }


                {
                    EnquiryFormData.CustomerType && FreightItems.length !== 0 && < div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "FreightServiceType",
                                    label: "Freight/Service Type ",
                                    value: EnquiryFormData.FreightServiceType,
                                    required: true,
                                    error: EnquiryFormData.FreightServiceTypeerror,
                                    helperText: 'Freight/Service Type  Required',
                                    item: FreightItems,
                                    onChange: (value, field) => {


                                        const option = {
                                            [field.id]: value,
                                            [field.id + 'error']: !value
                                        }

                                        AirImportCustomerTypeFrightOtions?.map(item => {
                                            if (item.value === EnquiryFormData.CustomerType) {
                                                item?.FrightServiceType?.map(res => {
                                                    if (res.name === value) {
                                                        debugger;
                                                        setFreightServiceSubType(res.FreightServiceSubType);
                                                        setBizType(res.BizType)
                                                        setBizSubType(res.BizSubType)
                                                        setBizCategoryType(res.BizCategoryType)
                                                        setMultiPickUp(res?.MultiPickUp)
                                                        setMultiDelivery(res?.MultiDelivery)


                                                        if (res.BizType.length === 1) {
                                                            option['BizType'] = res.BizType[0].name;
                                                            option['BizTypeerror'] = false;
                                                        } else {
                                                            option['BizType'] = '';
                                                        }

                                                        if (res.BizSubType.length === 1) {
                                                            option['BizSubType'] = res.BizSubType[0].name;
                                                            option['BizSubTypeerror'] = false;
                                                        } else {
                                                            option['BizSubType'] = '';
                                                        }

                                                        if (res.BizCategoryType.length === 1) {
                                                            option['BizCategoryType'] = res.BizCategoryType[0].name;
                                                            option['BizCategoryTypeerror'] = false;
                                                        } else {
                                                            option['BizCategoryType'] = '';
                                                        }





                                                    }
                                                })
                                            }
                                        })

                                        setEnquiryFormData({
                                            ...EnquiryFormData,
                                            ...option
                                        })
                                    }
                                }
                            }
                        />

                    </div>
                }

                {

                    FreightServiceSubType.length !== 0 && <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "FreightServiceSubType",
                                    label: "Freight/Service Sub Type ",
                                    value: EnquiryFormData.FreightServiceSubType,
                                    required: true,
                                    error: EnquiryFormData.FreightServiceSubTypeerror,
                                    helperText: 'Freight ServiceSubType Required',
                                    item: FreightServiceSubType,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />

                    </div>

                }


                {

                    BizCategoryType.length !== 0 && <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "BizCategoryType",
                                    label: "Biz Category Type ",
                                    value: EnquiryFormData.BizCategoryType,
                                    required: true,
                                    error: EnquiryFormData.BizCategoryTypeerror,
                                    helperText: 'Inco Terms Required',
                                    item: BizCategoryType,
                                    disable: BizCategoryType.length === 1 ? true : false,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                }

                {
                    BizType.length !== 0 && <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "BizType",
                                    label: "Biz Type ",
                                    value: EnquiryFormData.BizType,
                                    required: true,
                                    error: EnquiryFormData.BizTypeerror,
                                    helperText: 'Inco Terms Required',
                                    disable: BizType.length === 1 ? true : false,
                                    item: BizType,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />

                    </div>
                }

                {
                    BizSubType.length !== 0 && <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "BizSubType",
                                    label: "Biz Sub Type ",
                                    value: EnquiryFormData.BizSubType,
                                    required: true,
                                    error: EnquiryFormData.BizSubTypeerror,
                                    helperText: 'Inco Terms Required',
                                    item: BizSubType,
                                    disable: BizSubType.length === 1 ? true : false,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />

                    </div>
                }

            </div>
        </div >

        <div>

            <EnquiryAirImport
                MultiPickUp={MultiPickUp}
                MultiDelivery={MultiDelivery}
                CommonFormData={EnquiryFormData}
                BizCategoryTypeItem={BizCategoryType}
                BizSubTypeItem={BizSubType}
                BizTypeItem={BizType}
                FreightServiceSubTypeItem={FreightServiceSubType}
                FreightItemsItem={FreightItems}
                callBack={handleFormValidation}


            />
        </div>

    </>

}

export default AirImportEnquiryLayout;
import React, { useEffect, useState } from 'react'
import CustomizedSteppers from '../../component/tabs/tabStepper';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Dropdown, { InputField, InputFileField } from '../../component/controls';
import { BizInstituteType, DepartmentType, TypeOfCustomer, TypeOfFPC } from '../../../services/modalData';
import { Button, Checkbox, Divider, TextField } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { GetAllEmployee, GetCustomerByPage, GetCustomerBySearch, GetCustomerMasterData, PostCreateCustomer, PostGSTStatus, PostMasterCity } from '../../../services/constant';
import { useDispatch } from 'react-redux';
import { customerlist, state } from '../../../navigation/constant';
import { json, useNavigate } from 'react-router-dom';
import { formatDataToFormData } from '../../../services/utilities/formUtilities';
import CheckMail from '../../../services/utilities/checkEmailFormat';

const CreateCustomer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [ActiveStep, setActiveStep] = useState(0)
    const [employeeItem, setEmployeeItem] = useState([])
    const [customerMasterDataItem, setCustomerMasterDataItem] = useState([])
    const [customerListItem, setCustomerListItem] = useState([])
    const [bizInstituteItem, setbizInstituteItem] = useState([])
    const [networkTypeItem, setnetworkTypeItem] = useState([])
    const [accountLedgerItem, setaccountLedgerItem] = useState([])
    const [cityItem, setCityItem] = useState([])
    const [GSTStatus, setGSTStatus] = useState([])
    const [gstcode, setGstCode] = useState([])
    const [TemporaryId, setTemporaryId] = useState(false)
    const [stateCode, setStateCode] = useState('');
    const [advancePayment, setAdvancePayment] = useState(false);
    const [allLimit, setallLimit] = useState(false);
    const [AirImport, setAirImport] = useState(false);
    const [AirExport, setAirExport] = useState(false);
    const [OceanImport, setOceanImport] = useState(false);
    const [OceanExport, setOceanExport] = useState(false);



    const [contactDetailsError, setContactDetailsError] = useState({});
    const [promoterDetailsError, setPromoterDetailsError] = useState({});

    const [createCustomerForm, setCreateCustomerForm] = useState({
        name: '',
        bizType: '',
        typeOfCompanyOther: "",
        cusomerType: '',
        typeOfFPC: null,
        network: '',
        identificationDetails: "",
        parentCompany: '',
        accountLedger: '',
        ieCode: "",
        salesPerson: '',
        panNumber: "",
        cinNumber: "",
        airImport: false,
        airExport: false,
        oceanImport: false,
        oceanExport: false,
        advancePayment: false,
        allLimit: false,
        overAllLimit: '',
        receiptPeriodNoOfDaysAI: '',
        receiptPeriodLimitAI: '',
        receiptPeriodNoOfDaysAE: '',
        receiptPeriodLimitAE: '',
        receiptPeriodNoOfDaysOILCL: '',
        receiptPeriodLimitOILCL: '',
        receiptPeriodNoOfDaysOIFCL: '',
        receiptPeriodLimitOIFCL: '',
        receiptPeriodNoOfDaysOELCL: '',
        receiptPeriodLimitOELCL: '',
        receiptPeriodNoOfDaysOEFCL: '',
        receiptPeriodLimitOEFCL: '',
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        country: '',
        state: '',
        city: '',
        zipCode: '',
        gstStatus: '',
        gstNumber: "",
        tanNumber: "",
        beneficiaryName: "",
        accountNumber: "",
        confirmaccountNumber: '',
        bankCode: "",
        swiftCode: "",
        bankAddess: "",
        bankName: "",
        contactList: [
            {
                name: "",
                email: "",
                departmentType: "",
                mobileNumber: "",
                landline: ""
            }
        ],
        customerPramoterList: [{
            promoter: {
                name: "",
                mobileNumber: "",
                email: "",
                position: "",
                addressProof: "",
                image: ""
            },
            name: "",
            mobileNumber: "",
            email: "",
            position: "",
            addressProof: "",
            image: "",
            addressProofFile: "",
            imageFile: ""
        }]
    })

    useEffect(() => {
        let data = JSON.parse(sessionStorage.getItem('checkCustomerData'))
        setCreateCustomerForm({
            ...createCustomerForm,
            ['name']: data?.searchKey,
            ['cusomerType']: data?.customerType,
            ['gstNumber']: data?.gstNumber
        })

        sessionStorage.removeItem('checkCustomerData')
    }, [])

    const requestdata = {
        customer: (() => {

            const removeundefinedParem = {
                name: createCustomerForm.name,
                bizType: createCustomerForm.bizType,
                cusomerType: createCustomerForm.cusomerType,
                identificationDetails: createCustomerForm.identificationDetails,
                salesPerson: createCustomerForm.salesPerson,
                airImport: AirExport,
                airExport: AirExport,
                oceanImport: OceanImport,
                oceanExport: OceanExport,
                advancePayment: advancePayment,
                allLimit: allLimit,
                overAllLimit: createCustomerForm.overAllLimit,

                customerBranchList: [
                    (() => {
                        const data = {
                            name: createCustomerForm.name,
                            addressLine1: createCustomerForm.addressLine1,
                            country: createCustomerForm.country,
                            state: createCustomerForm.state,
                            city: createCustomerForm.city,
                            zipCode: createCustomerForm.zipCode,
                            contactList: createCustomerForm.contactList
                        }

                        if (createCustomerForm.cusomerType !== 5) data.bankDetail = {
                            beneficiaryName: createCustomerForm.beneficiaryName,
                            name: createCustomerForm.bankName,
                            accountNumber: createCustomerForm.accountNumber,
                            bankCode: createCustomerForm.bankCode,
                            swiftCode: createCustomerForm.swiftCode,
                            bankAddress: createCustomerForm.bankAddess
                        }
                        if (createCustomerForm.temporary) data.temporary = TemporaryId
                        if (createCustomerForm.addressLine2) data.addressLine2 = createCustomerForm.addressLine2
                        if (createCustomerForm.addressLine3) data.addressLine3 = createCustomerForm.addressLine3
                        if (createCustomerForm.cusomerType !== 5) data.gstStatus = createCustomerForm.gstStatus
                        if (createCustomerForm.cusomerType !== 5) data.accountLedger = createCustomerForm.accountLedger
                        if (createCustomerForm.cusomerType !== 5) data.gstNumber = createCustomerForm.gstNumber
                        if (createCustomerForm.cusomerType !== 5) data.tanNumber = createCustomerForm.tanNumber
                        return data;
                    })()
                ],
            };


            // if (TemporaryId) removeundefinedParem.temporary = TemporaryId
            if (bizInstituteItem[createCustomerForm?.bizType - 1]?.name.toLowerCase() === 'other') removeundefinedParem.typeOfCompanyOther = createCustomerForm.typeOfCompanyOther
            if (createCustomerForm.parentCompany) removeundefinedParem.parentCompany = createCustomerForm.parentCompany;
            if (createCustomerForm.gstStatus === 2) removeundefinedParem.customerBranchList[0].gstNumber = 'Unregistered';
            if (createCustomerForm.cusomerType === 4) removeundefinedParem.network = createCustomerForm.network
            if (createCustomerForm.cusomerType === 5) removeundefinedParem.typeOfFPC = createCustomerForm.typeOfFPC
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.ieCode = createCustomerForm.ieCode
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.panNumber = createCustomerForm.panNumber
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.cinNumber = createCustomerForm.cinNumber
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodNoOfDaysAI = createCustomerForm.receiptPeriodNoOfDaysAI
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodLimitAI = createCustomerForm.receiptPeriodLimitAI
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodNoOfDaysAE = createCustomerForm.receiptPeriodNoOfDaysAE
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodLimitAE = createCustomerForm.receiptPeriodLimitAE
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodNoOfDaysOILCL = createCustomerForm.receiptPeriodNoOfDaysOILCL
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodLimitOILCL = createCustomerForm.receiptPeriodLimitOILCL
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodNoOfDaysOIFCL = createCustomerForm.receiptPeriodNoOfDaysOIFCL
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodLimitOIFCL = createCustomerForm.receiptPeriodLimitOIFCL
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodNoOfDaysOELCL = createCustomerForm.receiptPeriodNoOfDaysOELCL
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodLimitOELCL = createCustomerForm.receiptPeriodLimitOELCL
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodNoOfDaysOEFCL = createCustomerForm.receiptPeriodNoOfDaysOEFCL
            if (createCustomerForm.cusomerType !== 5) removeundefinedParem.receiptPeriodLimitOEFCL = createCustomerForm.receiptPeriodLimitOEFCL


            return removeundefinedParem;

        }
        )(),
        customerPramoterList: createCustomerForm.cusomerType !== 5 ? createCustomerForm.customerPramoterList : []
    }

    const set_CityItemData = () => {
        GetCaller({
            API: PostMasterCity
            ,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                debugger
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                    gstCode: item.gstCode
                }))
                setCityItem(newCityItemData)
            }

        })

    }


    useEffect(() => {

        set_CityItemData();

        GetCaller({
            API: GetCustomerMasterData,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {

                console.log('customerMasterdataItems', response?.data?.data)
                const data = response?.data?.data
                setCustomerMasterDataItem(response?.data?.data)

                const bizTypeItem = data?.bizTypeList?.map((res) => ({
                    value: res.key,
                    name: res.value
                }))

                setbizInstituteItem(bizTypeItem);

                const accountLedgerList = data?.accountLedgerList?.map((res) => ({
                    value: res.key,
                    name: res.value
                }))

                setaccountLedgerItem(accountLedgerList)

                const networkList = data?.networkList?.map((res) => ({
                    value: res.key,
                    name: res.value
                }))

                setnetworkTypeItem(networkList);

                const gstStatusList = data?.gstStatusList?.map((res) => ({
                    value: res.key,
                    name: res.value
                }))

                setGSTStatus(gstStatusList);

            }

        });

        GetCaller({
            API: GetAllEmployee,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {

                console.log('Sales Person', response?.data?.data)
                const data = response?.data?.data?.records

                const employeelist = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setEmployeeItem(employeelist)
                console.log("employeeList", employeelist)
            }

        });

        GetCaller({
            API: GetCustomerBySearch,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {

                console.log('Sales Person', response?.data?.data)
                const data = response?.data?.data?.records

                const employeelist = data?.map(res => ({
                    value: res.id,
                    name: res.legalName
                }))

                setCustomerListItem(employeelist)

            }

        });

    }, [])



    function hasUndefinedValues(obj) {
        debugger
        let changes = {}; // Temporary object to accumulate changes

        // Check if any value in the object is undefined
        Object.entries(obj).forEach(([key, value]) => {
            if (


                key === "addressLine2" ||
                key === "addressLine3" ||
                key === 'parentCompany' ||
                key === 'advancePayment' ||
                key === 'airExport' ||
                key === 'airImport' ||
                key === 'country' ||
                key === 'state' ||
                key === 'allLimit' ||
                key === 'oceanExport' ||
                key === 'oceanImport' ||
                (key === "typeOfCompanyOther" && bizInstituteItem[createCustomerForm?.bizType - 1]?.name.toLowerCase() !== 'other') ||
                (key === "network" && createCustomerForm.cusomerType !== 4) ||
                (key === "typeOfFPC" && createCustomerForm.cusomerType !== 5) ||
                (key === "receiptPeriodLimitAI" && allLimit || advancePayment) ||
                (key === "receiptPeriodLimitAE" && allLimit || advancePayment) ||
                (key === "receiptPeriodLimitOILCL" && allLimit || advancePayment) ||
                (key === "receiptPeriodLimitOIFCL" && allLimit || advancePayment) ||
                (key === "receiptPeriodLimitOELCL" && allLimit || advancePayment) ||
                (key === "receiptPeriodLimitOEFCL" && allLimit || advancePayment) ||
                (key === "overAllLimit" && !allLimit) ||
                (key === 'Temporary')
            ) {
                return false
            }
            else if (!value) {
                changes[key] = value;
                changes[key + 'error'] = !value;
            }
        });
        console.log(changes)
        return changes;
    }

    const contactDetailshasUndifinedValues = (array) => {
        let changes = {};
        debugger
        array.map((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                if (!value && (key === 'name' || key === "email" || key === "departmentType" || key === "mobileNumber")) {
                    changes[key + index] = !value;
                }
            });
        })
        console.log('contactDetailshasUndifinedValues changes', changes)

        return changes;
    };

    const promoterDetailshasUndifinedValues = (array) => {

        let changes = {};
        debugger
        array.map((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                if (!value) {
                    changes[key + index] = !value;
                }
            });
        })
        console.log('contactDetailshasUndifinedValues changes', changes)

        return changes;
    };

    const handleBasicInputChange = (value, field) => {
        // if (field.id === 'country') {


        //     let stateItem = [];
        //     customerMasterDataItem?.countryList?.map((res) => {
        //         if (res.id === value) {

        //             res.stateCityList.map(res => {
        //                 stateItem.push({
        //                     value: res.id,
        //                     name: res.name
        //                 })
        //             })

        //         }
        //     })
        //     setStateItem(stateItem)

        // }
        // if (field.id === 'state') {


        //     let cityItem = [];

        //     customerMasterDataItem?.countryList?.map((res) => {
        //         if (res.id === createCustomerForm.country) {
        //             res.stateCityList.map(res => {
        //                 if (res.id === value) {
        //                     res.cityList.map(res => {
        //                         cityItem.push({
        //                             value: res.key,
        //                             name: res.value
        //                         })
        //                     })
        //                 }

        //             })

        //         }
        //     })
        //     setCityItem(cityItem)

        //     StateItem.map(res => {
        //         if (res.value === value) {
        //             setStateCode(res.code)
        //         }
        //     })



        // }

        if (field.id === 'city') {
            debugger
            set_CityItemData(value);

            cityItem?.map((res) => {
                if (res.value === value) {

                    setGstCode(res.gstCode)
                }
            })
        }

        setCreateCustomerForm({
            ...createCustomerForm,
            [field.id]: value,
            [field.id + 'error']: !value,
        })
    }


    const submitCustomer = () => {

        const isMail = CheckMail(requestdata.customer)

        const customerrequestDataChanges = hasUndefinedValues(requestdata.customer)
        const customerrequestDataChangescustomerBranchList = hasUndefinedValues(requestdata.customer?.customerBranchList[0])
        const customerrequestDataChangescustomerBranchListbankDetail = createCustomerForm.cusomerType !== 5 ? hasUndefinedValues(requestdata.customer?.customerBranchList[0]?.bankDetail) : 0
        const contactDetailsChanges = contactDetailshasUndifinedValues(requestdata.customer?.customerBranchList[0]?.contactList)
        // const customerPramoterListChanges = hasUndefinedValues(requestdata.customer.customerPramoterList[0])
        const promoterDetailsChanges = promoterDetailshasUndifinedValues(requestdata?.customerPramoterList)
        if (
            Object.keys(customerrequestDataChanges).length > 0 ||
            Object.keys(customerrequestDataChangescustomerBranchList).length > 0 ||
            Object.keys(customerrequestDataChangescustomerBranchListbankDetail).length > 0 ||
            Object.keys(contactDetailsChanges).length > 0 ||
            Object.keys(promoterDetailsChanges).length > 0
            // Object.keys(customerPramoterListChanges).length > 0

        ) {
            setContactDetailsError(contactDetailsChanges);
            setPromoterDetailsError(promoterDetailsChanges);
            setCreateCustomerForm(prevFormData => ({
                ...prevFormData,
                ...customerrequestDataChanges,
                ...customerrequestDataChangescustomerBranchList,
                ...customerrequestDataChangescustomerBranchListbankDetail,
                // ...customerPramoterListChanges
            }));
        }
        else {



            // Example usage:
            const formData = formatDataToFormData(requestdata);

            // Now you can use formData to send a POST request
            // using the Fetch API or any other HTTP library in React.


            PostCaller({
                API: PostCreateCustomer,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: formData,
                contenttype: true,
                callback: () => {
                    navigate(customerlist)
                },

            })
        }

    }


    const stepHandler = (e) => {

        const verifyFields = e === 1 ? {
            name: createCustomerForm.name,
            bizType: createCustomerForm.bizType,
            typeOfCompanyOther: createCustomerForm.typeOfCompanyOther,
            cusomerType: createCustomerForm.cusomerType,
            typeOfFPC: createCustomerForm.typeOfFPC,
            network: createCustomerForm.network,
            identificationDetails: createCustomerForm.identificationDetails,
            parentCompany: createCustomerForm.parentCompany,
            accountLedger: createCustomerForm.accountLedger,
            ieCode: createCustomerForm.ieCode,
            salesPerson: createCustomerForm.salesPerson,
            panNumber: createCustomerForm.panNumber,
            cinNumber: createCustomerForm.cinNumber,
            addressLine1: createCustomerForm.addressLine1,
            addressLine2: createCustomerForm.addressLine2,
            addressLine3: createCustomerForm.addressLine3,
            country: createCustomerForm.country,
            state: createCustomerForm.state,
            city: createCustomerForm.city,
            zipCode: createCustomerForm.zipCode,
            gstStatus: createCustomerForm.gstStatus,
            gstNumber: createCustomerForm.gstStatus === 2 ? "Unregistered" : createCustomerForm.gstNumber,
            tanNumber: createCustomerForm.tanNumber,
            beneficiaryName: createCustomerForm.beneficiaryName,
            accountNumber: createCustomerForm.accountNumber,
            confirmaccountNumber: createCustomerForm.confirmaccountNumber,
            bankCode: createCustomerForm.bankCode,
            swiftCode: createCustomerForm.swiftCode,
            bankAddess: createCustomerForm.bankAddess,
            bankName: createCustomerForm.bankName,

        } : {
            overAllLimit: createCustomerForm.overAllLimit,
            receiptPeriodNoOfDaysAI: createCustomerForm.receiptPeriodNoOfDaysAI,
            receiptPeriodLimitAI: createCustomerForm.receiptPeriodLimitAI,
            receiptPeriodNoOfDaysAE: createCustomerForm.receiptPeriodNoOfDaysAE,
            receiptPeriodLimitAE: createCustomerForm.receiptPeriodLimitAE,
            receiptPeriodNoOfDaysOILCL: createCustomerForm.receiptPeriodNoOfDaysOILCL,
            receiptPeriodLimitOILCL: createCustomerForm.receiptPeriodLimitOILCL,
            receiptPeriodNoOfDaysOIFCL: createCustomerForm.receiptPeriodNoOfDaysOIFCL,
            receiptPeriodLimitOIFCL: createCustomerForm.receiptPeriodLimitOIFCL,
            receiptPeriodNoOfDaysOELCL: createCustomerForm.receiptPeriodNoOfDaysOELCL,
            receiptPeriodLimitOELCL: createCustomerForm.receiptPeriodLimitOELCL,
            receiptPeriodNoOfDaysOEFCL: createCustomerForm.receiptPeriodNoOfDaysOEFCL,
            receiptPeriodLimitOEFCL: createCustomerForm.receiptPeriodLimitOEFCL,
        }

        const customerrequestDataChanges = hasUndefinedValues(verifyFields)
        const contactDetailsChanges = contactDetailshasUndifinedValues(createCustomerForm.contactList)
        if (
            Object.keys(customerrequestDataChanges).length > 0 ||
            Object.keys(contactDetailsChanges).length > 0
        ) {
            setContactDetailsError(contactDetailsChanges)
            setCreateCustomerForm(prevFormData => ({
                ...prevFormData,
                ...customerrequestDataChanges,
            }));
        }
        else {
            setActiveStep(e)
            if (e === 2) {
                setTemporaryId(true)
            }
        }
    }

    function PramoterForm() {
        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };

        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                name: '',
                email: '',
                position: '',
                mobileNumber: '',
                addressProof: '',
                image: '',
            },
        ]);

        const addInputField = () => {
            if (inputFields.length !== 2) {
                setInputFields([...inputFields, { key: generateUniqueKey(), name: '', email: '', position: '', mobileNumber: '', addressProof: '', image: '' }]);
            }
        };

        const handleInputChange = (key, field, value, index) => {
            const updatedInputFields = [...inputFields];
            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
            if (fieldToUpdate) {
                setPromoterDetailsError({
                    ...promoterDetailsError,
                    [field + index]: !value
                })
                fieldToUpdate[field] = value;
                setInputFields(updatedInputFields);
                const data = []
                updatedInputFields && updatedInputFields.map((res) => {
                    return data.push(
                        {
                            promoter: {
                                name: res.name,
                                mobileNumber: res.mobileNumber,
                                email: res.email,
                                position: res.position,
                                addressProof: res.addressProof,
                                image: res.image
                            },
                            name: res.name,
                            mobileNumber: res.mobileNumber,
                            email: res.email,
                            position: res.position,
                            addressProof: res.addressProof,
                            image: res.image,
                            addressProofFile: res.addressProof,
                            imageFile: res.image
                        }

                    )

                })
                console.log('pramoterdata', data)
                handleBasicInputChange(data, { id: 'customerPramoterList' });
            }
        };

        const removeInputField = (key) => {
            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        promoter: {
                            name: res.name,
                            mobileNumber: res.mobileNumber,
                            email: res.email,
                            position: res.position,
                            addressProof: res.addressProof,
                            image: res.image
                        },
                        name: res.name,
                        mobileNumber: res.mobileNumber,
                        email: res.email,
                        position: res.position,
                        addressProof: res.addressProof,
                        image: res.image,
                        addressProofFile: res.addressProof,
                        imageFile: res.image
                    }
                )

            })
            console.log('pramoterdata', data)

            handleBasicInputChange(data, { id: 'customerPramoterList' });
        };

        return (
            <div className=''>

                <h6 className='mt-5 p-0 m-0'>Add Pramoter</h6>

                {inputFields.map((field, key) => (
                    <div className='row' key={field.key}>
                        <div className="col-md-11">
                            <div className="row mb-2">
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "name",
                                                label: "Name",
                                                type: 'text',
                                                value: field.name,
                                                required: true,
                                                error: promoterDetailsError['name' + key],
                                                helperText: 'Contact Person Name Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'name', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "email",
                                                label: "Email",
                                                type: 'email',
                                                value: field.email,
                                                required: true,
                                                error: promoterDetailsError['email' + key],
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'email', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "mobileNumber",
                                                label: "Mobile",
                                                type: 'number',
                                                value: field.mobileNumber,
                                                required: true,
                                                error: promoterDetailsError['mobileNumber' + key],
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'mobileNumber', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "position",
                                                label: "Position",
                                                type: 'text',
                                                value: field.position,
                                                required: true,
                                                error: promoterDetailsError['position' + key],
                                                helperText: 'position Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'position', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">

                                    <InputFileField
                                        field={
                                            {
                                                id: "addressProof",
                                                label: "Address Proof",
                                                type: 'file',
                                                value: '',
                                                required: true,
                                                error: promoterDetailsError['addressProof' + key],
                                                helperText: 'Limit in INR Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'addressProof', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">

                                    <InputFileField
                                        field={
                                            {
                                                id: "image",
                                                label: "Image",
                                                type: 'file',
                                                value: '',
                                                required: true,
                                                error: promoterDetailsError['image' + key],
                                                helperText: 'Limit in INR Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'image', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="col-md-1 cmd" style={{ justifyContent: "right" }}>
                            {
                                key === 0 ? <Button sx={{ borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                    <Add />
                                </Button>
                                    : <Button
                                        sx={{ borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                        onClick={() => removeInputField(field.key)}
                                        variant='outlined'
                                    >
                                        <Close />
                                    </Button>
                            }
                        </div>
                    </div>
                ))}

            </div>
        );
    }

    const ContactForm = () => {

        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };
        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                name: '',
                email: '',
                departmentType: '',
                mobileNumber: '',
                landline: ''
            },
        ]);


        const addInputField = () => {
            // if (inputFields.length !== 2) {
            //     setInputFields([...inputFields, { key: generateUniqueKey(), name: '', email: '', departmentType: '', mobileNumber: '', landline: '' }]);
            // }
            setInputFields([...inputFields, { key: generateUniqueKey(), name: '', email: '', departmentType: '', mobileNumber: '', landline: '' }]);
        };

        const handleInputChange = (key, field, value) => {
            const updatedInputFields = [...inputFields];
            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
            if (fieldToUpdate) {
                fieldToUpdate[field] = value;
                setInputFields(updatedInputFields);
                const data = []
                updatedInputFields && updatedInputFields.map((res) => {
                    return data.push(
                        {
                            name: res.name,
                            email: res.email,
                            departmentType: res.departmentType,
                            mobileNumber: res.mobileNumber,
                            landline: res.landline
                        }
                    )

                })
                handleBasicInputChange(data, { id: 'contactList' });
            }
        };

        const removeInputField = (key) => {
            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        name: res.name,
                        email: res.email,
                        departmentType: res.departmentType,
                        mobileNumber: res.mobileNumber,
                        landline: res.landline
                    }
                )

            })
            handleBasicInputChange(data, { id: 'contactList' });
        };


        return <>
            <div className=''>
                <h5>Contact From</h5>

                {inputFields.map((field, key) => (
                    <div className='row' key={field.key}>
                        <div className="col-md-11">
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "name",
                                                label: "Contact Person Name",
                                                type: 'text',
                                                value: field.name,
                                                required: true,
                                                error: contactDetailsError["name" + key] && !field.name,
                                                helperText: 'Contact Person Name Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'name', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "email",
                                                label: "Email",
                                                type: 'email',
                                                value: field.email,
                                                required: true,
                                                error: contactDetailsError["email" + key] && !field.email,
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'email', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <Dropdown
                                        // className="mt-0"
                                        field={
                                            {
                                                id: "departmentType",
                                                label: "Department Type ",
                                                value: field.departmentType,
                                                required: true,
                                                error: contactDetailsError["name" + key] && !field.departmentType,
                                                helperText: 'Department Type Required',
                                                item: DepartmentType,
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'departmentType', value);
                                                }
                                            }
                                        }

                                    />

                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "mobileNumber",
                                                label: "Mobile No",
                                                type: 'number',
                                                value: field.mobileNumber,
                                                required: true,
                                                error: contactDetailsError["mobileNumber" + key] && !field.mobileNumber,
                                                helperText: 'Mobile No Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'mobileNumber', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "landline",
                                                label: "Land Line",
                                                type: 'number',
                                                value: field.landline,
                                                required: false,
                                                error: !field.landline,
                                                helperText: 'Land Line Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'landline', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 cmd" style={{ justifyContent: "right" }}>
                            {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                <Add />
                            </Button> : (
                                <Button
                                    sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                    onClick={() => removeInputField(field.key)}
                                    variant='contained'
                                >
                                    <Close />
                                </Button>
                            )}
                        </div>
                    </div>
                ))}
            </div>

        </>
    }

    return (
        <div>
            <div className="hr-form-box" style={{ position: "relative" }}>
                <h4 style={{
                    position: "absolute",
                    top: "30px",
                    left: "10px"
                }}>Create Customer</h4>

                <div className="cmc">
                    <CustomizedSteppers callBack={stepHandler} ActiveStep={ActiveStep} />
                </div>


                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={ActiveStep + 1}>

                        <TabPanel value={1} sx={{ padding: "0" }}>
                            <div className="row">
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "bizType",
                                                label: "Biz. Institute Type ",
                                                value: createCustomerForm.bizType,
                                                required: true,
                                                error: createCustomerForm.bizTypeerror,
                                                helperText: 'Biz. Institute Type Required',
                                                item: bizInstituteItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                {
                                    bizInstituteItem && bizInstituteItem[createCustomerForm.bizType - 1]?.name?.toLowerCase() === 'other' &&
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "typeOfCompanyOther",
                                                    label: "Specify Other Type Of Biz Institute ",
                                                    type: 'text',
                                                    value: createCustomerForm.typeOfCompanyOther,
                                                    required: true,
                                                    error: createCustomerForm.typeOfCompanyOthererror,
                                                    helperText: 'Type Of Company Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                }
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "cusomerType",
                                                label: "Type Of Customer ",
                                                value: createCustomerForm.cusomerType,
                                                required: true,
                                                error: createCustomerForm.cusomerTypeerror,
                                                helperText: 'Type Of Customer Required',
                                                item: TypeOfCustomer,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>

                                {
                                    createCustomerForm.cusomerType === 5 &&
                                    <div className="col-md-3">
                                        <Dropdown
                                            field={
                                                {
                                                    id: "typeOfFPC",
                                                    label: "Type Of FPC ",
                                                    value: createCustomerForm.typeOfFPC,
                                                    required: true,
                                                    error: createCustomerForm.typeOfFPCerror,
                                                    helperText: 'Type Of FPC Required',
                                                    item: TypeOfFPC,
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                }
                                {
                                    createCustomerForm.cusomerType === 4 &&
                                    <div className="col-md-3">
                                        <Dropdown
                                            field={
                                                {
                                                    id: "network",
                                                    label: "Global Allowance Network ",
                                                    value: createCustomerForm.network,
                                                    required: true,
                                                    error: createCustomerForm.networkerror,
                                                    helperText: 'Network Required',
                                                    item: networkTypeItem,
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />

                                    </div>
                                }

                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "name",
                                                label: "Legal Name",
                                                type: 'text',
                                                value: createCustomerForm.name,
                                                required: true,
                                                error: createCustomerForm.nameerror,
                                                helperText: 'Legal Name Required',
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>

                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "identificationDetails",
                                                label: "Identification Label",
                                                type: 'text',
                                                value: createCustomerForm.identificationDetails,
                                                required: true,
                                                error: createCustomerForm.identificationDetailserror,
                                                helperText: 'Identification Required',
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "salesPerson",
                                                label: "Sales Person ",
                                                value: createCustomerForm.salesPerson,
                                                required: true,
                                                error: createCustomerForm.salesPersonerror,
                                                helperText: 'Sales Person Required',
                                                item: employeeItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "parentCompany",
                                                label: "Parent Company",
                                                value: createCustomerForm.parentCompany,
                                                required: false,
                                                error: createCustomerForm.parentCompanyerror,
                                                helperText: 'Parent Company Required',
                                                item: customerListItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />

                                </div>
                                {
                                    createCustomerForm.cusomerType !== 5 &&
                                    <div className="col-md-3">
                                        <Dropdown
                                            field={
                                                {
                                                    id: "accountLedger",
                                                    label: "A/C Ledger Name ",
                                                    value: createCustomerForm.accountLedger,
                                                    required: true,
                                                    error: createCustomerForm.accountLedgererror,
                                                    helperText: 'A/C Ledger Name Required',
                                                    item: accountLedgerItem,
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />

                                    </div>
                                }
                                {
                                    createCustomerForm.cusomerType !== 5 &&
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "ieCode",
                                                    label: "IE Code",
                                                    type: 'text',
                                                    value: createCustomerForm.ieCode,
                                                    required: true,
                                                    error: createCustomerForm.ieCodeerror,
                                                    helperText: 'IE Code Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                }
                                {/* <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "",
                                                label: "Introducer",
                                                value: createCustomerForm.introduce,
                                                required: true,
                                                error: createCustomerForm.introduceerror,
                                                item: employeeItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div> */}
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "introduce",
                                                label: "Introducer",
                                                value: createCustomerForm.introduce,
                                                required: true,
                                                error: createCustomerForm.introduceerror,
                                                helperText: 'Sales Person Required',
                                                item: employeeItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "assignedsales",
                                                label: "Assigned To Sales Person",
                                                value: createCustomerForm.assignedsales,
                                                required: true,
                                                error: createCustomerForm.assignedsaleserror,

                                                item: employeeItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "recoveryagent",
                                                label: "Recovery Agent",
                                                value: createCustomerForm.recoveryagent,
                                                required: true,
                                                error: createCustomerForm.recoveryagenterror,
                                                item: employeeItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "salessupport",
                                                label: "Sales Support",
                                                value: createCustomerForm.salessupport,
                                                required: true,
                                                error: createCustomerForm.salessupporterror,
                                                helperText: 'Sales Person Required',
                                                item: employeeItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                            </div>


                            <hr className='mt-4' />

                            <h5>Address</h5>
                            <div className="row">
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "addressLine1",
                                                label: "Line 1",
                                                type: 'text',
                                                value: createCustomerForm.addressLine1,
                                                required: true,
                                                error: createCustomerForm?.addressLine1error,
                                                helperText: 'Line 1 Required',
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "addressLine2",
                                                label: "Line 2",
                                                type: 'text',
                                                value: createCustomerForm.addressLine2,
                                                required: false,
                                                error: createCustomerForm?.addressLine2error,
                                                helperText: 'Line 2 Required',
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "addressLine3",
                                                label: "Line 3",
                                                type: 'text',
                                                value: createCustomerForm.addressLine3,
                                                required: false,
                                                error: createCustomerForm?.addressLine3error,
                                                helperText: 'Line 3 Required',
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>

                                {/* <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "country",
                                                label: "Country ",
                                                value: createCustomerForm.country,
                                                required: true,
                                                error: createCustomerForm.countryerror,
                                                helperText: 'Country Required',
                                                item: CountryItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />

                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "state",
                                                label: "State ",
                                                value: createCustomerForm.state,
                                                required: true,
                                                error: createCustomerForm.stateerror,
                                                helperText: 'State Required',
                                                item: StateItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />

                                </div> */}
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "city",
                                                label: "City ",
                                                value: createCustomerForm.city,
                                                required: true,
                                                error: createCustomerForm.cityerror,
                                                helperText: 'City Required',
                                                item: cityItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />

                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "zipCode",
                                                label: "ZIP/POSTAL CODE",
                                                type: 'number',
                                                value: createCustomerForm.zipCode,
                                                required: true,
                                                error: createCustomerForm.zipCodeerror,
                                                helperText: 'ZIP/POSTAl CODE Required',
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                {
                                    createCustomerForm.cusomerType !== 5 &&
                                    <div className="col-md-3">
                                        <Dropdown
                                            field={
                                                {
                                                    id: "gstStatus",
                                                    label: "GST STATUS ",
                                                    value: createCustomerForm.gstStatus,
                                                    required: true,
                                                    error: createCustomerForm.gstStatuserror,
                                                    helperText: 'GST STATUS Required',
                                                    item: GSTStatus,
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />

                                    </div>
                                }

                                {
                                    createCustomerForm.cusomerType !== 5 &&
                                    <div className="col-md-3">
                                        <div className="d-flex gstWrapper">
                                            <InputField
                                                field={
                                                    {
                                                        id: "stateCode",
                                                        label: "GST Code",
                                                        type: 'text',
                                                        value: gstcode,
                                                        required: false,
                                                        error: false,
                                                        helperText: 'GST Code Required',
                                                        disable: true,
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                            <InputField
                                                field={
                                                    {
                                                        id: "gstNumber",
                                                        label: "GST NO.",
                                                        type: GSTStatus && GSTStatus[createCustomerForm.gstStatus - 1]?.name.toLowerCase() === 'unregistered' ? 'text' : 'number',
                                                        value: GSTStatus && GSTStatus[createCustomerForm.gstStatus - 1]?.name.toLowerCase() === 'unregistered' ? 'Unregistered' : createCustomerForm.gstNumber,
                                                        required: GSTStatus && GSTStatus[createCustomerForm.gstStatus - 1]?.name.toLowerCase() === 'unregistered' ? false : true,
                                                        error: createCustomerForm.gstNumbererror,
                                                        disable: GSTStatus && GSTStatus[createCustomerForm.gstStatus - 1]?.name.toLowerCase() === 'unregistered' ? true : false,
                                                        helperText: 'GST NO. Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>

                                    </div>
                                }
                                {
                                    createCustomerForm.cusomerType !== 5 &&
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "panNumber",
                                                    label: "PAN NO",
                                                    type: 'text',
                                                    value: createCustomerForm.panNumber,
                                                    required: true,
                                                    error: createCustomerForm.panNumbererror,
                                                    helperText: 'PAN NO Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                }
                                {
                                    createCustomerForm.cusomerType !== 5 &&
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "tanNumber",
                                                    label: "TAN NO",
                                                    type: 'text',
                                                    value: createCustomerForm.tanNumber,
                                                    required: true,
                                                    error: createCustomerForm.tanNumbererror,
                                                    helperText: 'TAN NO Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                }
                                {
                                    createCustomerForm.cusomerType !== 5 &&
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "cinNumber",
                                                    label: "CIN NO",
                                                    type: 'text',
                                                    value: createCustomerForm.cinNumber,
                                                    required: true,
                                                    error: createCustomerForm.cinNumbererror,
                                                    helperText: 'CIN NO Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                }
                            </div>
                            {
                                createCustomerForm.cusomerType !== 5 &&
                                <>
                                    <hr className='mt-4' />
                                    <h5>Bank Details</h5>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <InputField
                                                field={
                                                    {
                                                        id: "beneficiaryName",
                                                        label: "Beneficiary Name",
                                                        type: 'text',
                                                        value: createCustomerForm.beneficiaryName,
                                                        required: true,
                                                        error: createCustomerForm.beneficiaryNameerror,
                                                        helperText: 'Beneficiary Name Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <InputField
                                                field={
                                                    {
                                                        id: "bankName",
                                                        label: "Bank Name",
                                                        type: 'text',
                                                        value: createCustomerForm.bankName,
                                                        required: true,
                                                        error: createCustomerForm.bankNameerror,
                                                        helperText: 'Bank Name Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <InputField
                                                field={
                                                    {
                                                        id: "accountNumber",
                                                        label: "A/C No",
                                                        type: 'number',
                                                        value: createCustomerForm.accountNumber,
                                                        required: true,
                                                        error: createCustomerForm.accountNumbererror,
                                                        helperText: 'A/C No Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <InputField
                                                field={
                                                    {
                                                        id: "confirmaccountNumber",
                                                        label: "Confirm A/C No",
                                                        type: 'number',
                                                        value: createCustomerForm.confirmaccountNumber,
                                                        required: true,
                                                        error: createCustomerForm.confirmaccountNumbererror || (createCustomerForm.confirmaccountNumber && createCustomerForm.accountNumber !== createCustomerForm?.confirmaccountNumber),
                                                        helperText: 'confirm A/C not matched',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <InputField
                                                field={
                                                    {
                                                        id: "bankCode",
                                                        label: "Bank Code",
                                                        type: 'text',
                                                        value: createCustomerForm.bankCode,
                                                        required: true,
                                                        error: createCustomerForm.bankCodeerror,
                                                        helperText: 'Bank Code Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <InputField
                                                field={
                                                    {
                                                        id: "swiftCode",
                                                        label: "Swift Code",
                                                        type: 'text',
                                                        value: createCustomerForm.swiftCode,
                                                        required: true,
                                                        error: createCustomerForm.swiftCodeerror,
                                                        helperText: 'Swift Code Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <InputField
                                                field={
                                                    {
                                                        id: "bankAddess",
                                                        label: "Bank Address",
                                                        type: 'text',
                                                        value: createCustomerForm.bankAddess,
                                                        required: true,
                                                        error: createCustomerForm.bankAddesserror,
                                                        helperText: 'Bank Address Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>

                                </>
                            }


                            <hr className='mt-4' />
                            {ContactForm()}
                            <hr className='mt-5' />

                            <div className="row mt-5">
                                <div className="col-md-2">

                                    <Checkbox
                                        checked={AirExport}
                                        onChange={() => setAirExport(!AirExport)}
                                    /> Air Export


                                </div>

                                <div className="col-md-2">

                                    <Checkbox
                                        checked={AirImport}
                                        onChange={() => setAirImport(!AirImport)}
                                    /> Air Import

                                </div>
                                <div className="col-md-2">
                                    <Checkbox
                                        checked={OceanExport}
                                        onChange={() => setOceanExport(!OceanExport)}
                                    />   Ocean Export
                                </div>
                                <div className="col-md-2">
                                    <Checkbox
                                        checked={OceanImport}
                                        onChange={() => setOceanImport(!OceanImport)}
                                    />   Ocean Import
                                </div>

                            </div>


                            <div className="cmd mt-5">
                                <div></div>
                                <div>
                                    {
                                        createCustomerForm.cusomerType === 5 ? <Button variant='contained' className='ms-3' onClick={submitCustomer}>Submit</Button> :
                                            <Button variant='contained' className='ms-3' onClick={() => { stepHandler(1); }}>Save & Next</Button>
                                    }
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel value={2} sx={{ padding: "0" }}>
                            <div>
                                <div className="row mt-5">
                                    <div className="col-md-2">
                                        <div className='mt-3'>

                                            <Checkbox
                                                checked={advancePayment}
                                                onChange={() => { setAdvancePayment(!advancePayment); setallLimit(false) }}
                                            />   Advance Payment
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className='mt-3'>
                                            <Checkbox
                                                checked={allLimit}
                                                onChange={() => { setallLimit(!allLimit); setAdvancePayment(false) }}
                                            />  All Limit
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "overAllLimit",
                                                    label: "Limit in INR",
                                                    type: 'number',
                                                    value: createCustomerForm.overAllLimit,
                                                    required: allLimit ? true : false,
                                                    error: createCustomerForm.overAllLimiterror,
                                                    helperText: 'Limit in INR Required',
                                                    disable: !allLimit,
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>

                                </div>

                                <hr className='my-4' />

                                <h5>Credit Period (No. of Days)</h5>

                                <div className="row">

                                    <div className="col-md-3">
                                        <div className="d-flex">
                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodNoOfDaysAE",
                                                        label: "AE ",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodNoOfDaysAE,
                                                        required: true,
                                                        error: createCustomerForm.receiptPeriodNoOfDaysAEerror,
                                                        helperText: 'AE Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />

                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodLimitAE",
                                                        label: "AE Limit",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodLimitAE,
                                                        required: allLimit || advancePayment ? false : true,
                                                        error: createCustomerForm.receiptPeriodLimitAEerror,
                                                        disable: allLimit || advancePayment,
                                                        helperText: 'AE Limit Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="d-flex">
                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodNoOfDaysAI",
                                                        label: "AI ",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodNoOfDaysAI,
                                                        required: true,
                                                        error: createCustomerForm.receiptPeriodNoOfDaysAIerror,
                                                        helperText: 'AI Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />

                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodLimitAI",
                                                        label: "AI Limit",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodLimitAI,
                                                        required: allLimit || advancePayment ? false : true,
                                                        error: createCustomerForm.receiptPeriodLimitAIerror,
                                                        disable: allLimit || advancePayment,
                                                        helperText: 'AI Limit Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="d-flex">
                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodNoOfDaysOELCL",
                                                        label: "OE-LCL ",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodNoOfDaysOELCL,
                                                        required: true,
                                                        error: createCustomerForm.receiptPeriodNoOfDaysOELCLerror,
                                                        helperText: 'OE-LCL Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />

                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodLimitOELCL",
                                                        label: "OE-LCL Limit",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodLimitOELCL,
                                                        required: allLimit || advancePayment ? false : true,
                                                        error: createCustomerForm.receiptPeriodLimitOELCLerror,
                                                        disable: allLimit || advancePayment,
                                                        helperText: 'OE-LCL Limit Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="d-flex">
                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodNoOfDaysOILCL",
                                                        label: "Ol-LCL ",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodNoOfDaysOILCL,
                                                        required: true,
                                                        error: createCustomerForm.receiptPeriodNoOfDaysOILCLerror,
                                                        helperText: 'Ol-LCL Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />

                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodLimitOILCL",
                                                        label: "Ol-LCL Limit",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodLimitOILCL,
                                                        required: allLimit || advancePayment ? false : true,
                                                        error: createCustomerForm.receiptPeriodLimitOILCLerror,
                                                        disable: allLimit || advancePayment,
                                                        helperText: 'Ol-LCL Limit Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="d-flex">
                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodNoOfDaysOEFCL",
                                                        label: "OE-FCL ",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodNoOfDaysOEFCL,
                                                        required: true,
                                                        error: createCustomerForm.receiptPeriodNoOfDaysOEFCLerror,
                                                        helperText: '"OE-FCL Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />

                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodLimitOEFCL",
                                                        label: "OE-FCL Limit",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodLimitOEFCL,
                                                        required: allLimit || advancePayment ? false : true,
                                                        error: createCustomerForm.receiptPeriodLimitOEFCLerror,
                                                        disable: allLimit || advancePayment,
                                                        helperText: 'OE-FCL Limit Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="d-flex">
                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodNoOfDaysOIFCL",
                                                        label: "Ol-FCL ",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodNoOfDaysOIFCL,
                                                        required: true,
                                                        error: createCustomerForm.receiptPeriodNoOfDaysOIFCLerror,
                                                        helperText: 'Ol-FCL Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />

                                            <InputField
                                                field={
                                                    {
                                                        id: "receiptPeriodLimitOIFCL",
                                                        label: "Ol-FCL Limit",
                                                        type: 'number',
                                                        value: createCustomerForm.receiptPeriodLimitOIFCL,
                                                        required: allLimit || advancePayment ? false : true,
                                                        error: createCustomerForm.receiptPeriodLimitOIFCLerror,
                                                        disable: allLimit || advancePayment,
                                                        helperText: 'Ol-FCL Limit Required',
                                                        onChange: handleBasicInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>

                                </div>

                                <hr className='my-4' />

                                <div className="cmd mt-5">
                                    <div></div>
                                    <div>
                                        <Button variant='outlined' onClick={() => { setActiveStep(ActiveStep - 1) }}>Previous</Button>
                                        {/* <Button variant='contained' className='ms-3' onClick={submitCustomer}>Submit</Button> */}
                                        <Button variant='contained' className='ms-3' onClick={() => { stepHandler(2); }}>Next</Button>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel value={3} sx={{ padding: "0" }}>
                            <div>

                                {PramoterForm()}

                                <div className="row mt-5">
                                    <div className="col-md-3">
                                        <div className='mt-3'>
                                            <Checkbox />  KYC Form
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='mt-3'>
                                            <Checkbox />  Credit Application Form
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <div className='mt-3'>
                                            <Checkbox /> Customer Authority Form
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='mt-3'>
                                            <Checkbox />  IEC Copy
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <div className='mt-3'>
                                            <Checkbox />  PAN Copy
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <InputFileField
                                            field={
                                                {
                                                    id: "",
                                                    label: "",
                                                    type: 'file',
                                                    value: '',
                                                    required: true,
                                                    error: false,
                                                    helperText: 'Limit in INR Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>

                                </div>

                                <div className="cmd mt-5">
                                    <div></div>
                                    <div>
                                        <Button variant='outlined' onClick={() => { setActiveStep(ActiveStep - 1) }}>Previous</Button>
                                        <Button variant='contained' className='ms-3' onClick={submitCustomer}>Submit</Button>
                                        {/* <Button variant='contained' className='ms-3' onClick={() => { setActiveStep(ActiveStep + 1); setTemporaryId(true) }}>Next</Button> */}
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                    </TabContext>
                </Box>
            </div>
        </div>
    )
}

export default CreateCustomer;


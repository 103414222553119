import { combineReducers } from "redux";
import { reducerEnquiryBranch, reducerFnEnquiryDivision, reducerFnEnquiryFinancialYear, reducerFnShowLoader, reducerFnShowSnackbar } from "./callReducers";

const RootReducer = combineReducers({
    snackbaralert: reducerFnShowSnackbar, 
    loader: reducerFnShowLoader,
    enquiryfinancialyear:reducerFnEnquiryFinancialYear,
    enuirydivision:reducerFnEnquiryDivision,
    enquirybranch:reducerEnquiryBranch
})
export default RootReducer;
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { PostAirExportBuyingingCharges, PostAirExportSellingCharges } from "../../../../services/APIConstant/enquiryConstant";
import { PostCaller } from "../../../../services/apiServices";
import Dropdown, { InputField, InputFileField, TextAreafield } from "../../../component/controls";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { ApplicableOn, DueCarriersApplicableOn, IntialStockProvider } from "../../../../services/modalData";
import { Add, Close } from "@mui/icons-material";
import Transition from "../../../../services/utilities/transition";
export const Create_DueCarrier = ({ callBack, data, unitItem, countrycurrency, chargeHeadItem, fieldData }) => {
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState(false);
    const [dueCarrierData, setDueCarrierData] = useState(data)
    const [dueCarrierDetailsError, setDueCarrierDetailsError] = useState({});

    const handleClickOpen = () => {
        setOpen(true);

        if (data && data?.length !== 0) {
            let recieveData = data?.map((res) => (
                {
                    key: generateUniqueKey(),
                    id: res.id,
                    chargeHeadId: res.chargeHeadId,
                    currencyId: res.currencyId,
                    exchangeRate: res.exchangeRate,
                    applicableOn: res.applicableOn,
                    rate: res.rate,
                    unitId: res.unitId,
                    quantity: res.quantity,
                    amount: res.amount,
                    buyingRate: res.buyingRate || 0,
                }
            ))

            setInputFields(recieveData)
            setDueCarrierData(recieveData)
        }
        else {
            setInputFields([
                {
                    key: generateUniqueKey(),
                    chargeHeadId: "",
                    currencyId: "",
                    exchangeRate: "",
                    applicableOn: "",
                    rate: "",
                    unitId: "",
                    quantity: fieldData?.qtyChargeWeight,
                    amount: ""
                }
            ])
        }
    };

    const handleClose = () => {
        setOpen(false);
        callBack(data, 'dueCarriers')
        setDueCarrierData([])
        // handleBasicInputChange(null, { id: 'DimentionFile' })

    }
    const handleSubmit = () => {

        const DueCarrierhasUndefinedValue = (arraydata) => {

            let array = arraydata && arraydata?.length !== 0 ? arraydata : [
                {
                    chargeHeadId: "",
                    currencyId: "",
                    exchangeRate: "",
                    applicableOn: "",
                    rate: "",
                    unitId: "",
                    quantity: "",
                    amount: ""
                }]

            let changes = {};
            array?.map((obj, index) => {
                debugger
                Object.entries(obj).forEach(([key, value]) => {
                    if (
                        !value && (
                            key === 'chargeHeadId' ||
                            key === 'currencyId' ||
                            key === 'exchangeRate' ||
                            key === 'applicableOn' ||
                            key === 'rate' ||
                            key === 'unitId' ||
                            key === 'quantity' ||
                            key === 'amount'
                        )) {
                        changes[key + index] = !value;
                    }
                });
            })

            return changes;
        };

        const DueCarrierChanges = DueCarrierhasUndefinedValue(dueCarrierData)
        debugger
        if (Object.keys(DueCarrierChanges).length > 0) {
            setDueCarrierDetailsError(DueCarrierChanges)
        } else {
            callBack(dueCarrierData, 'dueCarriers')
            setOpen(false);
        }
        // handleBasicInputChange(file, { id: 'DimentionFile' })

    }
    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            chargeHeadId: "",
            currencyId: "",
            exchangeRate: "",
            applicableOn: "",
            rate: "",
            unitId: "",
            quantity: fieldData?.qtyChargeWeight,
            amount: ""
        },
    ]);

    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            chargeHeadId: "",
            currencyId: "",
            exchangeRate: "",
            applicableOn: "",
            rate: "",
            unitId: "",
            quantity: fieldData?.qtyChargeWeight,
            amount: ""
        }]);
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

        if (fieldToUpdate) {
            fieldToUpdate[field] = value;

            setDueCarrierDetailsError({
                ...dueCarrierDetailsError,
                [field + index]: !value
            })

            if (fieldToUpdate['applicableOn'] === 1) {
                fieldToUpdate['amount'] = Number(fieldToUpdate.rate)

            } else {
                fieldToUpdate['amount'] = Number(fieldToUpdate.rate) * Number(fieldToUpdate.quantity)
            }

            setInputFields(updatedInputFields);

            const data = []
            updatedInputFields && updatedInputFields?.map((res) => {
                return data.push(
                    {
                        id: res?.id,
                        chargeHeadId: res.chargeHeadId,
                        currencyId: res.currencyId,
                        exchangeRate: res.exchangeRate,
                        applicableOn: res.applicableOn,
                        rate: res.rate,
                        unitId: res.unitId,
                        quantity: res.quantity,
                        amount: res.amount
                    }
                )

            })
            setDueCarrierData(data)
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields?.map((res) => {
            return data.push(
                {
                    id: res?.id,
                    chargeHeadId: res.chargeHeadId,
                    currencyId: res.currencyId,
                    exchangeRate: res.exchangeRate,
                    applicableOn: res.applicableOn,
                    rate: res.rate,
                    unitId: res.unitId,
                    quantity: res.quantity,
                    amount: res.amount
                }
            )

        })
        setDueCarrierData(data)
    };


    const filterChargeheadItems = (currentID) => {
        let filterData = chargeHeadItem?.filter(res => !inputFields.some(item => (currentID !== res.value && item.chargeHeadId === res.value)));
        return filterData;
    }

    return <>
        <Tooltip title='Add Due Carriers'>
            <i className="ms-3 las la-plus-circle" onClick={handleClickOpen}></i>
        </Tooltip>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                sx: {
                    width: "90vw",
                    maxWidth: "90vw!important",
                },
            }}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: "start"
                }
            }
            }


        >
            <div className=''>
                <DialogTitle>
                    <div className="cmd">

                        <div className='quote_pop_title'>
                            <span>{'Due Carrier'}</span>

                            {/* <input type="checkbox" checked={fieldData?.allDueCarrier} onChange={() => {
                                callBack(!fieldData?.allDueCarrier, 'allDueCarrier')
                            }} /> All in

                            <span></span>

                            <input type="checkbox" /> Do you want to send to customer? */}

                        </div>
                        <Tooltip title='Close'>
                            <IconButton onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <hr />
                </DialogTitle>
                <DialogContent>

                    <div className='my-4'>

                        {inputFields?.map((field, key) => (
                            <div className='row' key={field.key}>
                                <div className="col-md-1 cmd" style={{ justifyContent: "left", alignItems: "flex-start" }}>
                                    {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                        <Add />
                                    </Button> : (
                                        <Button
                                            sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                            onClick={() => removeInputField(field.key)}
                                            variant='contained'
                                        >
                                            <Close />
                                        </Button>
                                    )}
                                </div>
                                <div className="col-md-11 ">

                                    <div className="row mb-3">
                                        <div className="col-md-2">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: "chargeHeadId",
                                                        label: "Charge Head ",
                                                        value: field.chargeHeadId,
                                                        required: true,
                                                        error: dueCarrierDetailsError['chargeHeadId' + key],
                                                        item: filterChargeheadItems(field.chargeHeadId),
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>

                                        <div className="col-md-2">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: "currencyId",
                                                        label: "Currency ",
                                                        value: field.currencyId,
                                                        required: true,
                                                        error: dueCarrierDetailsError['currencyId' + key],
                                                        item: countrycurrency,
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-1">
                                            <InputField
                                                field={
                                                    {
                                                        id: "exchangeRate",
                                                        label: "Ex. Rate",
                                                        type: 'number',
                                                        value: field.exchangeRate,
                                                        required: true,
                                                        error: dueCarrierDetailsError['exchangeRate' + key],
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: "applicableOn",
                                                        label: "Applicable On ",
                                                        value: field.applicableOn,
                                                        required: true,
                                                        error: dueCarrierDetailsError['applicableOn' + key],
                                                        item: DueCarriersApplicableOn,
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            {
                                                !field?.buyingRate ?
                                                    <InputField
                                                        field={
                                                            {
                                                                id: "rate",
                                                                label: "Rate",
                                                                type: 'number',
                                                                value: field.rate,
                                                                required: true,
                                                                error: dueCarrierDetailsError['rate' + key],
                                                                onChange: (value, fieldChanges) => {
                                                                    handleInputChange(field.key, fieldChanges.id, value, key);
                                                                }
                                                            }
                                                        }
                                                    />
                                                    :
                                                    <div className="d-flex gstWrapper">
                                                        <InputField
                                                            field={
                                                                {
                                                                    id: "buyingRate",
                                                                    label: "",
                                                                    disable: true,
                                                                    type: 'number',
                                                                    value: field.buyingRate,
                                                                    required: true,
                                                                    error: dueCarrierDetailsError['buyingRate' + key],
                                                                    onChange: (value, fieldChanges) => {
                                                                        handleInputChange(field.key, fieldChanges.id, value, key);
                                                                    }
                                                                }
                                                            }
                                                        />

                                                        <InputField
                                                            field={
                                                                {
                                                                    id: "rate",
                                                                    label: "Rate",
                                                                    type: 'number',
                                                                    value: field.rate,
                                                                    required: true,
                                                                    error: dueCarrierDetailsError['rate' + key],
                                                                    onChange: (value, fieldChanges) => {
                                                                        handleInputChange(field.key, fieldChanges.id, value, key);
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>
                                            }

                                        </div>
                                        <div className="col-md-1">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: "unitId",
                                                        label: "Unit ",
                                                        value: field.unitId,
                                                        required: true,
                                                        error: dueCarrierDetailsError['unitId' + key],
                                                        item: [
                                                            { value: 1, name: 'KG' },
                                                            { value: 2, name: 'LBS' },
                                                            { value: 3, name: 'MAWB' },
                                                        ],
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-1">
                                            <InputField
                                                field={
                                                    {
                                                        id: "quantity",
                                                        label: "Qty (chg wt.)",
                                                        type: 'number',
                                                        value: field.quantity,
                                                        required: true,
                                                        disable: true,
                                                        error: dueCarrierDetailsError['quantity' + key],
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-1">
                                            <InputField
                                                field={
                                                    {
                                                        id: "amount",
                                                        label: "Amount",
                                                        type: 'number',
                                                        value: field.amount,
                                                        required: true,
                                                        disable: true,
                                                        error: dueCarrierDetailsError['amount' + key],
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>

                                    </div>


                                </div>
                            </div>


                        ))}
                    </div >
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                    <Button onClick={handleSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
                </DialogActions>
            </div>
        </Dialog>



    </>
}
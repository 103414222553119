import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { GetMasterDepartmentByPage, GetMasterDesignationByPage, GetMasterDivisionByPage, GetMasterNetworkTypeByPage, GetMasterSubGroupByPage, GetSplOperationReqTypeByPage, PostMasterDepartment, PostMasterDesignation, PostSplOperationReqType, UpdateMasterDesignation, UpdateMasterDesignationStatus, UpdateMasterNetworkType, UpdateMasterNetworkTypeStatus, UpdateSplOperationReqType, UpdateSplOperationReqTypeStatus } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';

const SplOperationReq = () => {
    const dispatch = useDispatch()
    const [SplOperationReqData, setSplOperationReqData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)


    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetSplOperationReqTypeByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({

            sn: index + 1,
            id: res.id,
            sploperation: res.name,
            status: res.status
        }));
        setSplOperationReqData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'sploperation',
            label: 'Special Requirement',
            numeric: false,
        },


        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateSplOperationReqTypeStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <SplOperationReqDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <SplOperationReqDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>
            <MultiTable
                data={SplOperationReqData}
                columns={columns}
                // filterItems={filterItems}
                title={'Special Requirement'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default SplOperationReq



const SplOperationReqDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()
    const [closeValidator, setcloseValidator] = useState(false)
    const [splOperationName, setSplOperationName] = useState(row?.sploperation || '')
    const [splOperationNameError, setSplOperationNameError] = useState(false)

    const drawerOpenCallbackHandler = () => {
        setSplOperationName(row?.sploperation || '')
        setSplOperationNameError(false)
    }

    const handleSubmit = () => {
        if (!splOperationName) {
            setSplOperationNameError(!splOperationName)

        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: splOperationName,
                status: row.status
              } : {
                name: splOperationName,     
              }             
              editor ?
                PostCaller({
                  API: UpdateSplOperationReqType,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
        
                :
        
                PostCaller({
                  API: PostSplOperationReqType,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }



    useEffect(() => {
        setcloseValidator(false)

    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'sploperation':
                setSplOperationName(value)
                setSplOperationNameError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Special Requirement",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Special Requirement',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Spl OperationReq",
            tooltitle: 'Add Special Requirement',
            defaultbtnfalse: true
        }





    const FieldData = [


        {
            id: 'sploperation',
            label: 'Spl OperationReq',
            type: 'text',
            value: splOperationName,
            required: true,
            error: splOperationNameError,
            helperText: 'SplOperation Req required',
            onChange: handleInputChange,

        },




    ];






    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}


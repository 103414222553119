import { Button, Checkbox, IconButton } from "@mui/material"
import Dropdown, { DateField, InputField, TextAreafield } from "../../component/controls"
import { useEffect, useState } from "react"
import { GetConsigneeSubAgencyPage, GetCustomerByPage, GetNotifyListByPage, PostAirportMaster, PostChargeHead, PostConsigneeSubAgency, PostMasterCity, PostMasterCountry, PostNotifyList, PostUnitMaster } from "../../../services/constant"
import GetCaller from "../../../services/apiServices"
import { useDispatch } from "react-redux"
import { Rateclass, TypeofCsr, applicableFormat, frightpp } from "../../../services/modalData"
import { Add, Close } from "@mui/icons-material"





export const Maintab = ({ handleBasicInputChange, MawbForm, notifyItem }) => {

    const dispatch = useDispatch();
    const [shippperItem, setShipperItem] = useState([])
    const [cityiteam, setCityItem] = useState([])

    const [consigneeItem, setConsigneeItem] = useState([])
    const [airportiteam, setAirportItem] = useState([])


    useEffect(() => {
        set_customerData()
        set_AirportItem()
        set_consigneeItem()
        set_cityItem()
    }, [])

    const set_customerData = () => {
        debugger;
        GetCaller({
            API: GetCustomerByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, paylode: e.paylode })
            },
            item: {},
            callback: ({ response }) => {
                const newSalesperson = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.legalName
                }))

                setShipperItem(newSalesperson)
            }
        })
    }

    const set_consigneeItem = () => {
        GetCaller({
            API: GetConsigneeSubAgencyPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newConsigneeItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setConsigneeItem(newConsigneeItemData)
            }

        })
    }

    const set_cityItem = () => {
        GetCaller({
            API: PostMasterCity,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setCityItem(newCityItemData)
            }

        })
    }

    const set_AirportItem = () => {
        GetCaller({
            API: PostAirportMaster,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newairportiteam = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setAirportItem(newairportiteam)
            }

        })
    }
    return (
        <>
            <div className="">
                <div className="row">

                    <div className="col-md-3">
                        <div className="row">

                            <div className="col-12">
                                <Dropdown
                                    field={
                                        {
                                            id: 'shipperId',
                                            label: 'Shipper. ',
                                            type: 'select',
                                            value: MawbForm.shipperId,
                                            required: true,
                                            error: MawbForm.shipperIderror,
                                            item: shippperItem,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-12">
                                <TextAreafield
                                    className={'mt-3'}
                                    field={
                                        {
                                            id: 'shipperAddress',
                                            label: " Shipper Address",
                                            required: true,
                                            value: MawbForm.shipperAddress,
                                            rows: 3,
                                            error: MawbForm.shipperAddresserror,
                                            onChange: handleBasicInputChange
                                        }
                                    } />
                            </div>

                            <div className="col-12">
                                <Dropdown
                                    field={

                                        {
                                            id: 'consigneeId',
                                            label: 'Consignee ',
                                            type: 'select',
                                            value: MawbForm.consigneeId,
                                            required: true,
                                            error: MawbForm.consigneeIderror,
                                            item: consigneeItem,

                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-12">
                                <TextAreafield
                                    className={'mt-3'}
                                    field={
                                        {
                                            id: 'consigneeAddress',
                                            label: "Consignee Address",
                                            value: MawbForm.consigneeAddress,
                                            required: true,
                                            rows: 3,
                                            error: MawbForm.consigneeAddresserror,
                                            onChange: handleBasicInputChange
                                        }
                                    } />
                            </div>


                        </div>
                    </div>

                    <div className="col-md-9">
                        <div className="row">


                            <div className="col-md-3">
                                <Dropdown
                                    field={
                                        {
                                            id: 'notify',
                                            label: 'Notify',
                                            required: false,
                                            value: MawbForm.notify,
                                            error: MawbForm.notifyerror,
                                            item: notifyItem,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>


                            <div className="col-md-3">
                                <Dropdown
                                    field={
                                        {
                                            id: 'freight',
                                            label: 'Freight ',
                                            value: MawbForm.freight,
                                            error: MawbForm.freighterror,
                                            required: true,
                                            item: frightpp,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>


                            <div className="col-md-3">
                                <Dropdown
                                    field={
                                        {
                                            id: 'dueAgent',
                                            label: 'Due Agent ',
                                            value: MawbForm.dueAgent,
                                            required: true,
                                            error: MawbForm.dueAgenterror,
                                            item: frightpp,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-3">
                                <Dropdown
                                    field={
                                        {
                                            id: 'dueCarrier',
                                            label: 'Due Carrier ',
                                            required: true,
                                            value: MawbForm.dueCarrier,
                                            error: MawbForm.dueCarriererror,
                                            item: frightpp,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <TextAreafield
                                    className={'mt-3'}
                                    field={
                                        {
                                            id: 'notifyAddress',
                                            label: "Notify Address",
                                            value: MawbForm.notifyAddress,
                                            required: false,
                                            rows: 3,
                                            error: MawbForm.notifyAddresserror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: 'printSubAgentNameLabel',
                                            label: 'Print SubAgent Name',
                                            type: "text",
                                            required: false,
                                            value: MawbForm.printSubAgentNameLabel,
                                            error: MawbForm.printSubAgentNameLabelerror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: 'printIataOnAWB',
                                            label: 'Print IATA On AWB',
                                            type: "text",
                                            required: false,
                                            value: MawbForm.printIataOnAWB,
                                            error: MawbForm.printIataOnAWBerror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: 'sci',
                                            label: 'SCI',
                                            type: "text",
                                            required: false,
                                            value: MawbForm.sci,
                                            error: MawbForm.scierror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: 'publishRate',
                                            label: 'Publish Rate',
                                            type: "number",
                                            required: false,
                                            value: MawbForm.publishRate,
                                            error: MawbForm.publishRateerror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: 'freightRate',
                                            label: 'Freight Rate',
                                            type: "number",
                                            required: true,
                                            value: MawbForm.freightRate,
                                            error: MawbForm.freightRateerror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: 'agreedRate',
                                            label: 'Agreed Rate',
                                            type: "number",
                                            required: true,
                                            value: MawbForm.agreedRate,
                                            error: MawbForm.agreedRateerror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-3">
                                <Dropdown
                                    field={
                                        {
                                            id: 'destination',
                                            label: 'Destination ',
                                            required: true,
                                            value: MawbForm.destination,
                                            error: MawbForm.destinationerror,
                                            item: cityiteam,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: 'ghgsCode',
                                            label: 'CHGS Code',
                                            type: "number",
                                            required: false,
                                            value: MawbForm.ghgsCode,
                                            error: MawbForm.ghgsCodeerror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-3">
                                <Dropdown
                                    field={
                                        {
                                            id: 'wtOrVal',
                                            label: 'WT/VAL',
                                            required: false,
                                            value: MawbForm.wtOrVal,
                                            error: MawbForm.wtOrValerror,
                                            item: [
                                                { name: "PPD", value: 1 },
                                                { name: "COLL", value: 2 }
                                            ],
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-3">
                                <Dropdown
                                    field={
                                        {
                                            id: 'other',
                                            label: 'Other',
                                            required: false,
                                            value: MawbForm.other,
                                            error: MawbForm.othererror,
                                            item: [
                                                { name: "PPD", value: 1 },
                                                { name: "COLL", value: 2 }
                                            ],
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: 'tcrNumber',
                                            label: 'TCR Number',
                                            type: "number",
                                            required: false,
                                            value: MawbForm.tcrNumber,
                                            error: MawbForm.tcrNumbererror,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-4 mt-2">
                                <Checkbox
                                    checked={MawbForm.minimumRateApplicable}
                                    onChange={() => { handleBasicInputChange(!MawbForm.minimumRateApplicable, { id: 'minimumRateApplicable' }) }}
                                />
                                {"Minimum Rate Applicable"}
                            </div>
                            <div className="col-md-4 mt-2">
                                <Checkbox
                                    checked={MawbForm.printNeutralAWB}
                                    onChange={() => { handleBasicInputChange(!MawbForm.printNeutralAWB, { id: 'printNeutralAWB' }) }}
                                />
                                {"Print Neutral AWB"}
                            </div>
                            <div className="col-md-4 mt-2">
                                <Checkbox
                                    checked={MawbForm.printSubAgentName}
                                    onChange={() => { handleBasicInputChange(!MawbForm.printSubAgentName, { id: 'printSubAgentName' }) }}
                                />
                                {"Print SubAgent Name"}
                            </div>
                            <div className="col-md-4 mt-2">
                                <Checkbox
                                    checked={MawbForm.showIataOnAWB}
                                    onChange={() => { handleBasicInputChange(!MawbForm.showIataOnAWB, { id: 'showIataOnAWB' }) }}
                                />
                                {"Show Iata On AWB"}
                            </div>
                            <div className="col-md-4 mt-2">
                                <Checkbox
                                    checked={MawbForm.asAgreed}
                                    onChange={() => { handleBasicInputChange(!MawbForm.asAgreed, { id: 'asAgreed' }) }}
                                />
                                {"As Agreed"}
                            </div>


                        </div>
                    </div>










                    {/* <div className="row">
                        <h6 className="mt-4">Dimensions</h6>
                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: 'length',
                                        label: 'Length.',
                                        type: "number",
                                        value: MawbForm.length,
                                        disable: 'true',
                                        error: MawbForm.lengtherror,
                                        item: airportiteam,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: 'width',
                                        label: 'width.',
                                        type: "number",
                                        value: MawbForm.width,
                                        disable: 'true',
                                        error: MawbForm.widtherror,
                                        item: airportiteam,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: 'height',
                                        label: 'Height.',
                                        type: "number",
                                        value: MawbForm.height,
                                        disable: 'true',
                                        error: MawbForm.heighterror,
                                        item: airportiteam,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: 'box',
                                        label: 'Box.',
                                        type: "number",
                                        value: MawbForm.box,
                                        disable: 'true',
                                        error: MawbForm.boxerror,
                                        item: airportiteam,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: 'volume',
                                        label: 'Volumn.',
                                        type: "number",
                                        value: MawbForm.volume,
                                        disable: 'true',
                                        error: MawbForm.volumeerror,
                                        item: airportiteam,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>
                    </div> */}
                    <h6 className="mt-4">Dimensions</h6>
                    <div className="dimensions_table mt-4">
                        <table>
                            <tr>
                                <th>Length</th>
                                <th>Width</th>
                                <th>Height</th>
                                <th>Box</th>
                                <th>Volumn</th>
                            </tr>
                            <tr>
                                <td>
                                    <InputField
                                        className='mt-0'
                                        field={
                                            {
                                                id: 'length',
                                                label: '',
                                                type: "number",
                                                value: MawbForm.length,
                                                disable: 'true',
                                                error: MawbForm.lengtherror,
                                                item: airportiteam,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </td>
                                <td>
                                    <InputField
                                        className='mt-0'
                                        field={

                                            {
                                                id: 'width',
                                                label: '',
                                                type: "number",
                                                value: MawbForm.width,
                                                disable: 'true',
                                                error: MawbForm.widtherror,
                                                item: airportiteam,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    /></td>
                                <td> <InputField
                                    className='mt-0'
                                    field={
                                        {
                                            id: 'height',
                                            label: '',
                                            type: "number",
                                            value: MawbForm.height,
                                            disable: 'true',
                                            error: MawbForm.heighterror,
                                            item: airportiteam,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                /></td>
                                <td>  <InputField
                                    className='mt-0'
                                    field={
                                        {
                                            id: 'box',
                                            label: '',
                                            type: "number",
                                            value: MawbForm.box,
                                            disable: 'true',
                                            error: MawbForm.boxerror,
                                            item: airportiteam,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                /></td>
                                <td>  <InputField
                                    className='mt-0'
                                    field={
                                        {
                                            id: 'volume',
                                            label: '',
                                            type: "number",
                                            value: MawbForm.volume,
                                            disable: 'true',
                                            error: MawbForm.volumeerror,
                                            item: airportiteam,
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                /></td>
                            </tr>
                            <tr>
                                <td colspan="4"></td>
                                <td>
                                    <InputField
                                        className='mt-0'
                                        field={
                                            {
                                                id: 'totalVolumnWeight',
                                                label: 'Total Volumn Wt.',
                                                type: "number",
                                                value: MawbForm.totalVolumnWeight,
                                                disable: true,
                                                error: MawbForm.totalVolumnWeighterror,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </td>
                            </tr>

                        </table>
                    </div>









                </div>
            </div>
        </>
    )

}

export const OtherDetails = ({ handleBasicInputChange, MawbForm, currencyItem }) => {
    const dispatch = useDispatch();


    const [unitItem, setUnitItem] = useState([]);
    const [consigneeItem, setConsigneeItem] = useState([]);
    const [shippperItem, setShipperItem] = useState([])


    useEffect(() => {

        set_UnitmasterItem();
    }, [])

    const set_UnitmasterItem = () => {
        GetCaller({
            API: PostUnitMaster,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newUnitItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setUnitItem(newUnitItemData)
            }

        })
    }



    return (
        <>
            <div className="">
                <div className="row">
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'currency',
                                    label: 'Currency ',
                                    type: "select",
                                    value: MawbForm.currency,
                                    required: true,
                                    error: MawbForm.currencyerror,
                                    item: currencyItem,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'kgOrLb',
                                    label: 'KG/LB ',
                                    value: MawbForm.kgOrLb,
                                    error: MawbForm.kgOrLberror,
                                    required: true,
                                    item: unitItem,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'rateClass',
                                    label: 'Rate Class',
                                    type: "select",
                                    required: false,
                                    value: MawbForm.rateClass,
                                    error: MawbForm.rateClasserror,
                                    item: Rateclass,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3" >
                        <InputField
                            field={{
                                id: 'commodityItemNumber',
                                label: 'Item No.',
                                type: "number",
                                required: false,
                                value: MawbForm.commodityItemNumber,
                                error: MawbForm.commodityItemNumbererror,
                                onChange: handleBasicInputChange
                            }}
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'declaredValueForInputCarriage',
                                    label: 'Declared Value Of Carriage',
                                    type: "text",
                                    value: MawbForm.declaredValueForInputCarriage,
                                    required: false,
                                    error: MawbForm.declaredValueForInputCarriageerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'declaredValueForCustom',
                                    label: 'Declared Value For Custom',
                                    type: "text",
                                    required: false,
                                    value: MawbForm.declaredValueForCustom,
                                    error: MawbForm.declaredValueForCustomerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'ammountOfInsurance',
                                    label: 'Amount Of Insurance',
                                    type: "text",
                                    required: false,
                                    value: MawbForm.ammountOfInsurance,
                                    error: MawbForm.ammountOfInsuranceerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'byFirstDueCarrier',
                                    label: 'By First Carrier',
                                    type: "text",
                                    required: true,
                                    value: MawbForm.byFirstDueCarrier,
                                    error: MawbForm.byFirstDueCarriererror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'routeOneTo',
                                    label: 'To',
                                    type: "text",
                                    required: false,
                                    value: MawbForm.routeOneTo,
                                    error: MawbForm.routeOneToerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'routeOneBy',
                                    label: 'By',
                                    type: "text",
                                    required: false,
                                    value: MawbForm.routeOneBy,
                                    error: MawbForm.routeOneByerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'routeTwoTo',
                                    label: 'To',
                                    type: "text",
                                    value: MawbForm.routeTwoTo,
                                    required: false,
                                    error: MawbForm.routeTwoToerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'routeTwoBy',
                                    label: 'By',
                                    type: "text",
                                    required: false,
                                    value: MawbForm.routeTwoBy,
                                    error: MawbForm.routeTwoByerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'requestedFlight',
                                    label: 'Requested Flight',
                                    type: "text",
                                    required: true,
                                    value: MawbForm.requestedFlight,
                                    error: MawbForm.requestedFlighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <DateField
                            format='YYYY-MM-DD'
                            field={{

                                id: "flightDate",
                                label: "Flight Date ",
                                required: true,
                                value: MawbForm?.flightDate,
                                error: MawbForm?.flightDateerror,
                                onChange: handleBasicInputChange
                            }}
                        />
                    </div>

                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'tariffRateType',
                                    label: 'Tariff Rate Type',
                                    required: false,
                                    value: MawbForm.tariffRateType,
                                    error: MawbForm.tariffRateTypeerror,
                                    item: applicableFormat,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'tariffRate',
                                    label: 'Tariff Rate',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.tariffRate,
                                    error: MawbForm.tariffRateerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'issuingDueCarrierAgentName',
                                    label: 'Issuing DueCarrier Agent Name',
                                    type: "text",
                                    required: true,
                                    value: MawbForm.issuingDueCarrierAgentName,
                                    error: MawbForm.issuingDueCarrierAgentNameerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'agentIataCode',
                                    label: 'Agent Iata Code',
                                    type: "text",
                                    required: true,
                                    value: MawbForm.agentIataCode,
                                    error: MawbForm.agentIataCodeerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'accountNumber',
                                    label: 'Account Number',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.accountNumber,
                                    error: MawbForm.accountNumbererror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'accountingInformation',
                                    label: 'Accounting Information',
                                    type: "number",
                                    required: true,
                                    value: MawbForm.accountingInformation,
                                    error: MawbForm.accountingInformationerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-4">
                        <TextAreafield
                            className={'mt-5'}
                            field={
                                {
                                    id: 'handlingInformation',
                                    label: "Handling Information",
                                    required: false,
                                    value: MawbForm.handlingInformation,
                                    rows: 4,
                                    error: MawbForm.handlingInformationerror,
                                    onChange: handleBasicInputChange
                                }
                            } />
                    </div>

                    <div className="col-md-4">
                        <TextAreafield
                            className={'mt-5'}
                            field={
                                {
                                    id: 'natureAndQuantityOfGoods',
                                    label: "Nature And Quantity Of Goods",
                                    required: false,
                                    value: MawbForm.natureAndQuantityOfGoods,
                                    rows: 4,
                                    error: MawbForm.natureAndQuantityOfGoodserror,
                                    onChange: handleBasicInputChange
                                }
                            } />
                    </div>

                    <div className="col-md-4">
                        <TextAreafield
                            className={'mt-5'}
                            field={
                                {
                                    id: 'descriptionOfGoods',
                                    label: "Description Of Goods",
                                    required: false,
                                    value: MawbForm.descriptionOfGoods,
                                    rows: 4,
                                    error: MawbForm.descriptionOfGoodserror,
                                    onChange: handleBasicInputChange
                                }
                            } />
                    </div>







                </div>
            </div>
        </>
    )

}

export const AwsCharge = ({ handleBasicInputChange, MawbForm, chargeheadItem, awbChargesDetailsError, SetAwbChargesDetailsError }) => {

    const dispatch = useDispatch();

    const [chargehead, setChargeHeadItem] = useState({})
    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };
    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            chargeId: '',
            printLabel: '',
            quantity: '',
            rate: '',
            amount: '',
            gst: '',
            due: '',
        },
    ]);



    useEffect(() => {
        console.log(chargeheadItem)

    }, [])

    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            chargeId: '',
            printLabel: '',
            quantity: '',
            rate: '',
            amount: '',
            gst: '',
            due: '',
        }]);
        SetAwbChargesDetailsError({})
    };


    const handleMultiInputChange = (key, field, value, index) => {
        debugger;
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
        if (fieldToUpdate) {
            SetAwbChargesDetailsError({
                ...awbChargesDetailsError,
                [field + index]: !value
            })
            fieldToUpdate[field] = value;

            if (field === 'chargeId') {

                let chargeHeadFilteredData = chargeheadItem?.find(item => item.value === value)

                fieldToUpdate['gst'] = chargeHeadFilteredData.taxSlab
                fieldToUpdate['due'] = chargeHeadFilteredData.due
            }

            fieldToUpdate['amount'] = Number(fieldToUpdate.quantity) * Number(fieldToUpdate.rate)

            setInputFields(updatedInputFields);

            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res?.id || 0,
                        chargeId: res.chargeId,
                        printLabel: res.printLabel,
                        quantity: res?.quantity,
                        rate: res?.rate,
                        amount: res.amount,
                        // gst: res.gst,
                        gst: true,
                        // due: res.due,
                        due: 1,
                    }
                )
            })


            handleBasicInputChange(data, { id: 'awbCharges' });
        }
    };

    const removeInputField = (key) => {
        SetAwbChargesDetailsError({})

        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    id: res?.id || 0,
                    chargeId: res.chargeId,
                    printLabel: res.printLabel,
                    quantity: res?.quantity,
                    rate: res?.rate,
                    amount: res.amount,
                    // gst: res.gst,
                    gst: true,
                    // due: res.due,
                    due: 1,
                }
            )

        })
        handleBasicInputChange(data, { id: 'awbCharges' });
    };

    const filterSubgroupItems = (currentID) => {
        let filterData = chargeheadItem?.filter(res => !inputFields.some(item => (currentID !== res.value && item.chargeId === res.value)));
        return filterData;
    }


    return <>
        <div className=''>


            <div className="dimensions_table mt-4" >
                <table style={{ tableLayout: 'fixed' }}>
                    <tr>
                        <th style={{ width: '55px' }}>
                            <IconButton onClick={addInputField} variant="contained">
                                <Add />
                            </IconButton>
                        </th>
                        <th>
                            CHARGES NAME
                        </th>
                        <th>Print Label</th>
                        <th>QUANTITY</th>
                        <th>RATE</th>
                        <th>AMOUNT</th>
                        <th>GST</th>
                        <th>DUE</th>
                    </tr>


                    {
                        inputFields.map((field, key) => (
                            <tr>
                                <td>
                                    <IconButton
                                        onClick={() => removeInputField(field.key)}
                                        variant='contained'
                                    >
                                        <Close />
                                    </IconButton>
                                </td>
                                <td>
                                    <Dropdown
                                        className={'mt-0'}
                                        field={
                                            {
                                                id: "chargeId",
                                                label: "",
                                                value: field.chargeId,
                                                required: true,
                                                error: awbChargesDetailsError["chargeId" + key],
                                                helperText: 'Contact Person Name Required',
                                                item: filterSubgroupItems(field.chargeId),
                                                onChange: (value) => {
                                                    handleMultiInputChange(field.key, 'chargeId', value, key);
                                                }
                                            }
                                        }
                                    />
                                </td>
                                <td>
                                    <InputField
                                        className={'mt-0'}
                                        field={
                                            {
                                                id: "printLabel",
                                                label: "",
                                                type: 'text',
                                                value: field.printLabel,
                                                required: true,
                                                error: awbChargesDetailsError["printLabel" + key],
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleMultiInputChange(field.key, 'printLabel', value, key);
                                                }
                                            }
                                        }
                                    />
                                </td>
                                <td>
                                    <InputField
                                        className={'mt-0'}
                                        field={{
                                            id: 'quantity',
                                            label: '',
                                            type: 'number',
                                            value: field.quantity,
                                            required: true,
                                            error: awbChargesDetailsError["quantity" + key],
                                            onChange: (value) => {
                                                handleMultiInputChange(field.key, 'quantity', value, key);
                                            },



                                        }}
                                    />
                                </td>

                                <td>
                                    <InputField
                                        className={'mt-0'}
                                        field={{
                                            id: 'rate',
                                            label: '',
                                            type: 'number',
                                            value: field.rate,
                                            required: true,
                                            error: awbChargesDetailsError["rate" + key],
                                            onChange: (value) => {
                                                handleMultiInputChange(field.key, 'rate', value, key);
                                            },



                                        }}
                                    />
                                </td>

                                <td>
                                    <InputField
                                        className={'mt-0'}
                                        field={{
                                            id: 'amount',
                                            label: '',
                                            type: 'number',
                                            value: field.amount,
                                            disable: true,
                                            required: true,
                                            error: awbChargesDetailsError["amount" + key],
                                            onChange: (value) => {
                                                handleMultiInputChange(field.key, 'amount', value, key);
                                            },



                                        }}
                                    />
                                </td>

                                <td>
                                    <InputField
                                        className={'mt-0'}
                                        field={
                                            {
                                                id: "gst",
                                                label: "",
                                                value: field.gst,
                                                required: false,
                                                disable: true,
                                                error: awbChargesDetailsError["gst" + key],
                                                helperText: 'Contact Person Name Required',
                                                onChange: (value) => {
                                                    handleMultiInputChange(field.key, 'gst', value, key);
                                                }
                                            }
                                        }
                                    />
                                </td>
                                <td>
                                    <InputField
                                        className={'mt-0'}
                                        field={
                                            {
                                                id: "due",
                                                label: "",
                                                value: field.due,
                                                required: false,
                                                error: awbChargesDetailsError["due" + key],
                                                helperText: 'Contact Person Name Required',
                                                disable: true,
                                                onChange: (value) => {
                                                    handleMultiInputChange(field.key, 'due', value, key);
                                                }
                                            }
                                        }
                                    />
                                </td>
                            </tr>

                        ))
                    }

                </table>
            </div>

        </div>

    </>
}


export const AirLineBuying = ({ handleBasicInputChange, MawbForm, currencyItem }) => {

    return <>
        <div className="row">
            <div className="col-md-3">
                <div className="row">
                    <div className="col-12">
                        <InputField
                            field={
                                {
                                    id: 'chargeWeight',
                                    label: 'Chargeable Wt.',
                                    type: 'number',
                                    value: MawbForm.chargeWeight,
                                    required: false,
                                    disable: true,
                                    error: MawbForm.chargeWeighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-12">
                        <InputField
                            field={
                                {
                                    id: 'iataCommision',
                                    label: 'IATA Commission (%)',
                                    type: 'number',
                                    value: MawbForm.iataCommision,
                                    required: false,
                                    error: MawbForm.iataCommisionerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-12">
                        <Dropdown
                            field={
                                {
                                    id: 'AirlineBuyingCurrency',
                                    label: 'Currency',
                                    required: false,
                                    value: MawbForm.AirlineBuyingCurrency,
                                    error: MawbForm.AirlineBuyingCurrencyerror,
                                    item: currencyItem,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-12">
                        <InputField
                            field={
                                {
                                    id: 'exchangeRate',
                                    label: 'Exchange Rate',
                                    type: 'number',
                                    value: MawbForm.exchangeRate,
                                    required: false,
                                    error: MawbForm.exchangeRateerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-12">
                        <InputField
                            field={
                                {
                                    id: 'incentive',
                                    label: 'Incentive',
                                    type: 'number',
                                    value: MawbForm.incentive,
                                    required: false,
                                    error: MawbForm.incentiveerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>


                    <div className="col-12">
                        <InputField
                            field={
                                {
                                    id: 'freightCost',
                                    label: 'Freight Cost',
                                    type: 'number',
                                    value: MawbForm.freightCost,
                                    required: false,
                                    error: MawbForm.freightCosterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-12">
                        <InputField
                            field={
                                {
                                    id: 'airlineBuyingAgreedRateLc',
                                    label: 'Agreed Rate (Local Currency)',
                                    type: 'number',
                                    value: MawbForm.airlineBuyingAgreedRateLc,
                                    required: false,
                                    error: MawbForm.airlineBuyingAgreedRateLcerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-12">
                        <InputField
                            field={
                                {
                                    id: 'airlineBuyingDueDueCarrierLc',
                                    label: 'Due Carrier (Local Currency)',
                                    type: 'number',
                                    value: MawbForm.airlineBuyingDueDueCarrierLc,
                                    required: false,
                                    error: MawbForm.airlineBuyingDueDueCarrierLcerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-12">
                        <Dropdown
                            field={
                                {
                                    id: 'csrType',
                                    label: 'Type Of CSR',
                                    required: false,
                                    value: MawbForm.csrType,
                                    error: MawbForm.csrTypeerror,
                                    item: TypeofCsr,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'freightLc',
                                    label: 'Freight(LC)',
                                    type: "text",
                                    value: MawbForm.freightLc,
                                    error: MawbForm.freightLcerror,
                                    required: false,
                                    disable: true,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'freightFc',
                                    label: 'Freight(FC)',
                                    type: "text",
                                    value: MawbForm.freightFc,
                                    error: MawbForm.freightFcerror,
                                    onChange: handleBasicInputChange,
                                    required: false,
                                    disable: true,
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'commissionLc',
                                    label: 'COMM.(LC)',
                                    type: "text",
                                    required: false,
                                    disable: true,
                                    value: MawbForm.commissionLc,
                                    error: MawbForm.commissionLcerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'commissionFc',
                                    label: 'COMM.(FC)',
                                    type: "text",
                                    required: false,
                                    disable: true,
                                    value: MawbForm.commissionFc,
                                    error: MawbForm.commissionFcerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'discountLc',
                                    label: 'Discount(LC)',
                                    type: "text",
                                    required: false,
                                    disable: true,
                                    value: MawbForm.discountLc,
                                    error: MawbForm.discountLcerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'discountFc',
                                    label: 'Discount(FC)',
                                    type: "text",
                                    required: false,
                                    disable: true,
                                    value: MawbForm.discountFc,
                                    error: MawbForm.discountFcerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'dueDueCarrierLc',
                                    label: 'Due Car.(LC)',
                                    type: "text",
                                    required: false,
                                    disable: true,
                                    value: MawbForm.dueDueCarrierLc,
                                    error: MawbForm.dueDueCarrierLcerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'dueDueCarrierFc',
                                    label: 'Due Car.(FC)',
                                    type: "text",
                                    required: false,
                                    disable: true,
                                    value: MawbForm.dueDueCarrierFc,
                                    error: MawbForm.dueDueCarrierFcerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-6">
                        <TextAreafield
                            className={'mt-3'}
                            field={
                                {
                                    id: 'remarks',
                                    label: "Remarks",
                                    required: false,
                                    value: MawbForm.remarks,
                                    rows: 3,
                                    error: MawbForm.remarkserror,
                                    onChange: handleBasicInputChange
                                }
                            } />
                    </div>
                </div>
            </div>

        </div>
    </>
}


export const Discrepancy = ({ handleBasicInputChange, MawbForm }) => {

    return <>
        <div className="dimensions_table mt-4">
            <table>
                <tr>
                    <th>CHARGES</th>
                    <th colSpan={2}>PP AMOUNT</th>
                </tr>
                <tr>
                    <th></th>
                    <th >ORIGINAL/INCORRECT</th>
                    <th >RIVISED/CORRECT</th>
                </tr>


                <tr>
                    <td>
                        WEIGHT
                    </td>
                    <td>
                        <InputField
                            className='mt-0'
                            field={
                                {
                                    id: 'height',
                                    label: '',
                                    type: "number",
                                    value: MawbForm.height,
                                    disable: true,
                                    error: MawbForm.heighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </td>

                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'weightAmountRevisedOrCorrect',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.weightAmountRevisedOrCorrect,
                                    disable: true,
                                    error: MawbForm.weightAmountRevisedOrCorrecterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

                <tr>
                    <td>
                        FREIGHT
                    </td>
                    <td>
                        <InputField
                            className='mt-0'
                            field={
                                {
                                    id: 'height',
                                    label: '',
                                    type: "number",
                                    value: MawbForm.height,
                                    disable: true,
                                    error: MawbForm.heighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </td>

                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'freightAmountRevisedOrCorrect',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.freightAmountRevisedOrCorrect,
                                    disable: false,
                                    error: MawbForm.freightAmountRevisedOrCorrecterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

                <tr>
                    <td>
                        COMMISSION
                    </td>
                    <td>
                        <InputField
                            className='mt-0'
                            field={
                                {
                                    id: 'height',
                                    label: '',
                                    type: "number",
                                    value: MawbForm.height,
                                    disable: true,
                                    error: MawbForm.heighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </td>

                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'commissionAmountRevisedOrCorrect',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.commissionAmountRevisedOrCorrect,
                                    disable: false,
                                    error: MawbForm.commissionAmountRevisedOrCorrecterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

                <tr>
                    <td>
                        DISCOUNT
                    </td>
                    <td>
                        <InputField
                            className='mt-0'
                            field={
                                {
                                    id: 'height',
                                    label: '',
                                    type: "number",
                                    value: MawbForm.height,
                                    disable: true,
                                    error: MawbForm.heighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </td>

                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'discountAmountRevisedOrCorrect',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.discountAmountRevisedOrCorrect,
                                    disable: false,
                                    error: MawbForm.discountAmountRevisedOrCorrecterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

                <tr>
                    <td>
                        DUE CARRIER
                    </td>
                    <td>
                        <InputField
                            className='mt-0'
                            field={
                                {
                                    id: 'height',
                                    label: '',
                                    type: "number",
                                    value: MawbForm.height,
                                    disable: true,
                                    error: MawbForm.heighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </td>

                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'dueDueCarrierAmountRevisedOrCorrect',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.dueDueCarrierAmountRevisedOrCorrect,
                                    disable: false,
                                    error: MawbForm.dueDueCarrierAmountRevisedOrCorrecterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

                <tr>
                    <td>
                        DUE AGENT
                    </td>
                    <td>
                        <InputField
                            className='mt-0'
                            field={
                                {
                                    id: 'height',
                                    label: '',
                                    type: "number",
                                    value: MawbForm.height,
                                    disable: true,
                                    error: MawbForm.heighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </td>

                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'dueAgentAmountRevisedOrCorrect',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.dueAgentAmountRevisedOrCorrect,
                                    disable: false,
                                    error: MawbForm.dueAgentAmountRevisedOrCorrecterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

                <tr>
                    <td>
                        Total
                    </td>
                    <td>
                        <InputField
                            className='mt-0'
                            field={
                                {
                                    id: 'height',
                                    label: '',
                                    type: "number",
                                    value: MawbForm.height,
                                    disable: true,
                                    error: MawbForm.heighterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </td>

                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'totalAmountRevisedOrCorrect',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.totalAmountRevisedOrCorrect,
                                    disable: true,
                                    error: MawbForm.totalAmountRevisedOrCorrecterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

            </table>
        </div>
    </>
}


export const ChargesTotal = ({ handleBasicInputChange, MawbForm }) => {

    return <>
        <div className="dimensions_table mt-4">
            <table>
                <tr>
                    <th>CHARGES</th>
                    <th>PP AMOUNT</th>
                </tr>

                <tr>
                    <td>
                        WEIGHT CHARGES
                    </td>
                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'weightCharges',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.weightCharges,
                                    disable: true,
                                    error: MawbForm.weightChargeserror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

                <tr>
                    <td>
                        VALUATION CHARGES
                    </td>


                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'valuationCharges',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.valuationCharges,
                                    disable: true,
                                    error: MawbForm.valuationChargeserror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

                <tr>
                    <td>
                        DUE AGENT CHARGES
                    </td>


                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'dueAgentCharges',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.dueAgentCharges,
                                    disable: true,
                                    error: MawbForm.dueAgentChargeserror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

                <tr>
                    <td>
                        DUE CARRIER CHARGES
                    </td>


                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'dueCarrierCharges',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.dueCarrierCharges,
                                    disable: true,
                                    error: MawbForm.dueCarrierChargeserror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

                <tr>
                    <td>
                        GST
                    </td>


                    <td>
                        <InputField
                            className='mt-3'
                            field={

                                {
                                    id: 'cgstCharges',
                                    label: 'CGST',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.cgstCharges,
                                    disable: true,
                                    error: MawbForm.cgstChargeserror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />

                        <InputField
                            className='mt-3'
                            field={

                                {
                                    id: 'sgstCharges',
                                    label: 'SGST',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.sgstCharges,
                                    disable: true,
                                    error: MawbForm.sgstChargeserror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />

                        <InputField
                            className='mt-3'
                            field={

                                {
                                    id: 'igstCharges',
                                    label: 'IGST',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.igstCharges,
                                    disable: true,
                                    error: MawbForm.igstChargeserror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />




                    </td>

                </tr>

                <tr>
                    <td>
                        Total CHARGES
                    </td>


                    <td>
                        <InputField
                            className='mt-0'
                            field={

                                {
                                    id: 'totalCharges',
                                    label: '',
                                    type: "number",
                                    required: false,
                                    value: MawbForm.totalCharges,
                                    disable: true,
                                    error: MawbForm.totalChargeserror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        /></td>

                </tr>

            </table>
        </div>
    </>
}

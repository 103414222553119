

import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown, { InputField, TextAreafield } from "../../component/controls";
import { GetConsigneeSubAgencyById, PostConsigneeSubAgency, PostMasterBizInstituteType, PostMasterCity, PostMasterCountry, PostMasterState, PostSubAgentAddressShipper, PostSubAgentConsigneeAddress, UpdateConsigneeSubAgency, UpdateSubAgentConsigneeAddress, UpdateSubAgentShipperAddress } from "../../../services/constant";
import GetCaller, { PostCaller } from "../../../services/apiServices";
import { Add, Close } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { columnConstant, formconstant } from "../../constant/formconstant";
import { consigneesubagencylist } from "../../../navigation/constant";
import CheckMail from "../../../services/utilities/checkEmailFormat";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
export const SubAgencyAddressForm = ({ row, APIDATA, edit, callback }) => {
    debugger;
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [defaultBankaddress, setDefaultBankaddress] = useState(false);
    const [cityItem, setCityItem] = useState([]);
    const [consigneeId, setConsigneeId] = useState([]);
    const [stateItem, setStateItem] = useState([]);
    const [countryItem, setCountryItem] = useState([]);
    const [attachkyc, setAttachKyc] = useState('Attach Kyc')
    const [bizInstituteItem, setBizInstituteItem] = useState([]);
    const enquirybranch = useSelector(state => state.enquirybranch)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSubagencyForm(
            {

                consigneeSubAgencyId: "",
                label: "",
                address1: "",
                address2: "",
                address3: "",
                cityId: "",
                pinCode: "",
                telephone: "",
                email: "",
                bankDetails: [
                    {
                        id: "",
                        name: "",
                        status: true,
                        beneficiaryName: "",
                        accountNumber: "",
                        bankCode: "",
                        swiftCode: "",
                        bankAddess: ""
                    }
                ]
            }
        )
    };

    const [subagencyform, setSubagencyForm] = useState({
        consigneeSubAgencyId: "",
        label: "",
        address1: "",
        address2: "",
        address3: "",   
        cityId: "",
        pinCode: "",
        telephone: "",
        email: "",
        bankName: "",
        beneficiaryName: "",
        accountNumber: "",
        bankCode: "",
        swiftCode: "",
        bankAddess: ""

    })



    const handleInputChange = (value, field) => {
        debugger;
     

        setSubagencyForm({
            ...subagencyform,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    };

    const handleFormSubmit = () => {
        debugger
        console.log('subagencyform', requestdata)
        const changes = handleSubmit(requestdata);
        debugger
        const isMail = CheckMail(requestdata?.email)
        if (Object.keys(changes).length > 0 || isMail) {
            setSubagencyForm({ ...subagencyform, ...changes,
            ['emailerror']: isMail
            });
        }
        else if (edit) {

            EditConsigneeSubAgencyAddress(requestdata);
        }
        else {
            AddConsigneeSubAgencyAddress(requestdata);
        }
    };

    const handleSubmit = (obj) => {
        debugger
        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (
                key === "id" ||
                key === "address2" ||
                key === "address3" ||
                key === "countryId" ||
                key === "stateId" ||
                key === "status" ||
                key === "linkedConsignee" ||
                key === "consigneeSubAgencyId"


            ) {
            }
            else if (!value) {
                changes[key] = value;
                changes[key + "error"] = !value;
            }
        });
        return changes;
    };

    const set_CityItemData = () => {
        GetCaller({
            API: PostMasterCity
            ,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({response }) => {
                debugger
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                    gstCode: item.gstCode                                     
                }))
                setCityItem(newCityItemData)
            }
    
        })
    
    }



    const handleCheckboxChange = (e) => {
        const fieldName = ["bankName", "beneficiaryName", "accountNumber", "bankCode", "swiftCode", "bankAddess"];
        if (e.target.checked) {
            let fieldData = {};

            fieldName.forEach(element => {
                fieldData[element] = row.bankDetails[0][element];
            });
            setSubagencyForm({
                ...subagencyform,
                ...fieldData
            });
        }
        else {
            let fieldData = {};

            fieldName.forEach(element => {
                fieldData[element] = '';
            });
            setSubagencyForm({
                ...subagencyform,
                ...fieldData
            });
        }
    }

    useEffect(() => {
        debugger;
        if (edit) {
            console.log("consigneeSubAgentAddress", row)
            setSubagencyForm(row);
            // set_CityItemData(row?.stateId);
            sessionStorage.removeItem('consigneeId');


        } else {
      
            set_CityItemData();
        }
    }, []);

    const AddConsigneeSubAgencyAddress = (requestdata) => {
        debugger;
        requestdata.consigneeSubAgencyId = row.id;
        requestdata.branchId = enquirybranch
        PostCaller({
            API: PostSubAgentAddressShipper,
            callback: () => {
                handleClose();
                callback()
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })

    }

    const EditConsigneeSubAgencyAddress = (requestdata) => {
        debugger;
        requestdata.consigneeSubAgencyId = row.consigneeSubAgencyId;
        requestdata.id = row.id;
requestdata.branchId = enquirybranch
        PostCaller({
          
            API: UpdateSubAgentShipperAddress,
            callback: () => {
                handleClose();
                callback();
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })
    }



    // const set_StateItemData = (countryId) => {

    //     let newStateItemData;
    //     if (countryId) {
    //         debugger
    //         newStateItemData = APIDATA?.stateData
    //             .filter((item) => item.country.key === countryId)
    //             .map((item) => ({
    //                 value: item.id,
    //                 name: item.name,
    //             }));
    //     }
    //     setStateItem(newStateItemData);

    // }
    // const set_CityItemData = (stateId) => {
    //     let newCityItemData;
    //     if (stateId) {
    //         newCityItemData = APIDATA?.cityData
    //             .filter((item) => item.state.key === stateId)
    //             .map((item) => ({
    //                 value: item.id,
    //                 name: item.name,
    //                 key: item.country.key
    //             }));
    //     }
    //     setCityItem(newCityItemData);
    // };


    useEffect(() => {
    }, []);
    const requestdata = {
        label: subagencyform.label,
        address1: subagencyform.address1,
        address2: subagencyform.address2,
        address3: subagencyform.address3,
        countryId: subagencyform.countryId,
        stateId: subagencyform.stateId,
        cityId: subagencyform.cityId,
        pinCode: subagencyform.pinCode,
        telephone: subagencyform.telephone,
        email: subagencyform.email,
        bankName: subagencyform.bankName,
        beneficiaryName: subagencyform.beneficiaryName,
        accountNumber: subagencyform.accountNumber,
        bankCode: subagencyform.bankCode,
        swiftCode: subagencyform.swiftCode,
        bankAddess: subagencyform.bankAddess
    }
    return (
        <>
            <React.Fragment>

                <IconButton onClick={handleClickOpen} >
                    {edit ? <i className="las la-edit"></i> : <Add />}
                </IconButton>

                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{
                        sx: {
                            width: "100%",
                            maxWidth: "90% !important",
                        },
                    }}
                    sx={{
                        '& .MuiDialog-container': {
                            alignItems: "start"
                        }
                    }
                    }


                >
                    <div className=''>
                        <DialogTitle>
                            <div className="cmd">
                                {edit ? <h6>Edit More Address For {row.label}</h6> : <h6>Add More Address For {row.name}</h6>}

                                <Tooltip title='Close'>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <hr />
                        </DialogTitle>
                        <DialogContent>

                            <div className="">
                                <div className="row">
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'label',
                                                    label: columnConstant.label,
                                                    type: 'text',
                                                    value: subagencyform.label,
                                                    required: true,
                                                    error: subagencyform.labelerror,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'address1',
                                                    label: columnConstant.address1,
                                                    type: "text",
                                                    value: subagencyform.address1,
                                                    required: true,
                                                    error: subagencyform.address1error,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'address2',
                                                    label: columnConstant.address2,
                                                    type: "text",
                                                    value: subagencyform.address2,
                                                    required: false,
                                                    error: subagencyform.address2error,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'address3',
                                                    label: columnConstant.address3,
                                                    type: "text",
                                                    value: subagencyform.address3,
                                                    required: false,
                                                    error: subagencyform.address3error,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>

                                    {/* <div className="col-md-3">
                                        <Dropdown
                                            field={
                                                {
                                                    id: "countryId",
                                                    label: columnConstant.country,
                                                    type: "select",
                                                    value: subagencyform.countryId,
                                                    required: true,
                                                    error: subagencyform.countryIderror,
                                                    item: APIDATA?.countryItem,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <Dropdown
                                            field={
                                                {
                                                    id: "stateId",
                                                    label: columnConstant.state,
                                                    type: "select",
                                                    value: subagencyform.stateId,
                                                    required: true,
                                                    error: subagencyform.stateIderror,
                                                    item: stateItem,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div> */}
                                    <div className="col-md-3">
                                        <Dropdown
                                            field={
                                                {
                                                    id: "cityId",
                                                    label: columnConstant.city,
                                                    type: "select",
                                                    value: subagencyform.cityId,
                                                    required: true,
                                                    error: subagencyform.cityIderror,
                                                    item: cityItem,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'pinCode',
                                                    label: columnConstant.zipPostCode,
                                                    type: "text",
                                                    value: subagencyform.pinCode,
                                                    required: false,
                                                    error: subagencyform.pinCodeerror,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'telephone',
                                                    label: columnConstant.telephone,
                                                    type: "text",
                                                    value: subagencyform.telephone,
                                                    required: true,
                                                    error: subagencyform.telephoneerror,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'email',
                                                    label: columnConstant.email,
                                                    type: "text",
                                                    value: subagencyform.email,
                                                    required: true,
                                                    error: subagencyform.emailerror,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            </div >
                            <div className="mt-2">
                                {"Bank Details"}
                                <div className="row">
                                    <div className="row">
                                        <div className="col-md-3 mt-2 ">
                                            <Checkbox
                                                // opneAsOA
                                                onChange={handleCheckboxChange}
                                            />
                                            {"Use Default Add Bank"}
                                        </div>

                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'beneficiaryName',
                                                    label: columnConstant.beneficiaryName,
                                                    type: 'text',
                                                    value: subagencyform.beneficiaryName,
                                                    required: true,
                                                    error: subagencyform.beneficiaryNameerror,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'bankName',
                                                    label: columnConstant.banName,
                                                    type: 'text',
                                                    value: subagencyform.bankName,
                                                    required: true,
                                                    error: subagencyform.bankNameerror,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'accountNumber',
                                                    label: columnConstant.aCNo,
                                                    type: 'text',
                                                    value: subagencyform.accountNumber,
                                                    required: true,
                                                    error: subagencyform.accountNumbererror,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'bankCode',
                                                    label: columnConstant.bankCode,
                                                    type: 'text',
                                                    value: subagencyform.bankCode,
                                                    required: true,
                                                    error: subagencyform.bankcodeerror,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: 'swiftCode',
                                                    label: columnConstant.swiftCode,
                                                    type: 'text',
                                                    value: subagencyform.swiftCode,
                                                    required: true,
                                                    error: subagencyform.swiftcodeerror,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    </div>

                                    <div className="col-md-3 mt-3">
                                        <TextAreafield
                                            field={
                                                {
                                                    id: 'bankAddess',
                                                    label: formconstant.bankAddress,
                                                    value: subagencyform.bankAddess,
                                                    required: true,
                                                    rows: 3,
                                                    error: subagencyform.bankAddesserror,
                                                    onChange: handleInputChange
                                                }
                                            } />
                                    </div>


                                </div>
                            </div>

                        </DialogContent>
                        <DialogActions className='mb-5'>
                            <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                            <Button style={{ background: 'var(--color-main)', color: '#fff' }} onClick={handleFormSubmit}>Submit</Button>
                        </DialogActions>
                    </div>
                </Dialog>

            </React.Fragment >
        </>
    );

}


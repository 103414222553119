import React, { useState } from "react";
import { DateField, InputField } from "../../../component/controls";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import Transition from "../../../../services/utilities/transition";

export const Shipping_Bill = ({ callBack, data, chargeHeadItem }) => {
    const [open, setOpen] = React.useState(false);
    const [mawbRateData, setMAWBRateData] = useState()
    const [dueCarrierDetailsError, setDueCarrierDetailsError] = useState({});

    const handleClickOpen = () => {
        setOpen(true);
        if (data && data?.length !== 0) {
            setMAWBRateData(data)

            let recieveData = data?.map((res) => (
                {
                    key: generateUniqueKey(), // Add a unique key for each input field
                    id: res?.id || null,
                    shippingBillDate: res.shippingBillDate,
                    shippingBillNo: res.shippingBillNo,
                }
            ))

            setInputFields(recieveData)
        }
        else {
            setInputFields([
                {
                    key: generateUniqueKey(), // Add a unique key for each input field
                    shippingBillDate: '',
                    shippingBillNo: '',
                }
            ])
        }
    };

    const handleClose = () => {
        setOpen(false);
        callBack(data, 'shippingBillNo')
        setMAWBRateData([])
    }

    const handleSubmit = () => {

        const DueCarrierhasUndefinedValue = (arraydata) => {
            debugger
            let array = arraydata.length !== 0 ? arraydata : [
                {
                    shippingBillDate: "",
                    shippingBillNo: "",
                }]

            let changes = {};
            array?.map((obj, index) => {
                debugger
                Object.entries(obj).forEach(([key, value]) => {
                    if (
                        !value && (
                            key === 'shippingBillDate' ||
                            key === 'shippingBillNo'
                        )) {
                        changes[key + index] = !value;
                    }
                });
            })
            console.log('changes', changes)

            return changes;
        };

        const DueCarrierChanges = DueCarrierhasUndefinedValue(mawbRateData)
        if (Object.keys(DueCarrierChanges).length > 0) {
            setDueCarrierDetailsError(DueCarrierChanges)
        } else {
            callBack(mawbRateData, 'shippingBillNo')
            setOpen(false);
        }
    }
    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            shippingBillDate: "",
            shippingBillNo: "",
        },
    ]);



    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            shippingBillDate: "",
            shippingBillNo: "",
        }]);
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

        if (fieldToUpdate) {
            fieldToUpdate[field] = value;

            setDueCarrierDetailsError({
                ...dueCarrierDetailsError,
                [field + index]: !value
            })

            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields?.map((res) => {
                return data.push(
                    {
                        id: res?.id || null,
                        shippingBillDate: res.shippingBillDate,
                        shippingBillNo: res.shippingBillNo,
                    }
                )

            })
            setMAWBRateData(data)
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields?.map((res) => {
            return data.push(
                {
                    id: res?.id || null,
                    shippingBillDate: res.shippingBillDate,
                    shippingBillNo: res.shippingBillNo,
                }
            )

        })
        setMAWBRateData(data)
    };


    const filterChargeheadItems = (currentID) => {
        let filterData = chargeHeadItem?.filter(res => !inputFields.some(item => (currentID !== res.value && item.shippingBillDate === res.value)));
        return filterData;
    }

    return <>
        <div className="mt-2" onClick={handleClickOpen}>
            <div className="cmd">
                <div></div>
                <Tooltip title='Add Shipping Bill'>
                    <i className="ms-3 las la-plus-circle" ></i>
                </Tooltip>
            </div>

            <InputField
                className={'mt-0'}
                field={
                    {
                        id: "dueCarrier",
                        label: "Shipping Bill",
                        type: 'number',
                        value: '',
                        required: true,
                        error: false,
                        disable: true,
                        // onChange: handleBasicInputChange
                    }
                }
            />
        </div>


        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                sx: {
                    width: "600px",
                    maxWidth: "600px !important",
                },
            }}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: "start"
                }
            }
            }


        >
            <div className=''>
                <DialogTitle className="mb-0 pb-0">
                    <div className="cmd">

                        <h6 className="m-0 p-0">Shipping Bill</h6>
                        <Tooltip title='Close'>
                            <IconButton onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <hr />
                </DialogTitle>
                <DialogContent>

                    <div className=''>

                        {inputFields?.map((field, key) => (
                            <div className='row' key={field.key}>
                                <div className="col-md-1 cmd" style={{ justifyContent: "left", alignItems: "flex-start" }}>
                                    {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                        <Add />
                                    </Button> : (
                                        <Button
                                            sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                            onClick={() => removeInputField(field.key)}
                                            variant='contained'
                                        >
                                            <Close />
                                        </Button>
                                    )}
                                </div>
                                <div className="col-md-11 ">

                                    <div className="row mb-3">

                                        <div className="col-md-6">
                                            <InputField
                                                field={
                                                    {
                                                        id: "shippingBillNo",
                                                        label: "Bill No",
                                                        type: 'number',
                                                        value: field.shippingBillNo,
                                                        required: true,
                                                        error: dueCarrierDetailsError['shippingBillNo' + key],
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <DateField
                                                field={
                                                    {
                                                        id: "shippingBillDate",
                                                        label: "Bill Date ",
                                                        value: field.shippingBillDate,
                                                        required: true,
                                                        error: dueCarrierDetailsError['shippingBillDate' + key],
                                                        item: filterChargeheadItems(field.shippingBillDate),
                                                        onChange: (value, fieldChanges) => {
                                                            handleInputChange(field.key, fieldChanges.id, value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>

                                    </div>


                                </div>
                            </div>


                        ))}
                    </div >
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                    <Button onClick={handleSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
                </DialogActions>
            </div>
        </Dialog>



    </>
}
import React, { useEffect, useState } from 'react'
import Dropdown, { DateField, InputField, InputFileField, TextAreafield, TimeField } from '../../component/controls';
import { columnConstant, formconstant } from '../../constant/formconstant';
import { ChargeExportImport, DeliveryAt, EnquiryDimension, GoodsConditions, JobTypeForGoodsInRegister, PickUpType, applicableFormat } from '../../../services/modalData';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import Transition from '../../../services/utilities/transition';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { GetAirportMasterByPage, GetCustomerBySearch, GetVehicalTypeByPage, GetVendorMasterByPage, PostVendorMaster } from '../../../services/constant';
import { useDispatch } from 'react-redux';
import { GetWarehouseGoodsInRegisterById, GetWarehouseGoodsOutRegisterById, GetWarehouseStockRegisterByPage, GetWerehouseGoodsInRegisterByPage, PostWerehouseGoodsInRegister, PostWerehouseGoodsOutRegister, UpdateWerehouseGoodsInRegister, UpdateWerehouseGoodsOutRegister } from '../../../services/APIConstant/warehouseConstant';
import { goodsinregisterlist, goodsoutregisterlist } from '../../../navigation/constant';
import { json, useNavigate } from 'react-router-dom';
import { formatDataToFormData } from '../../../services/utilities/formUtilities';

const CreateGoodsOutRegisterForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [actualShipperItem, setActualShipperItem] = useState([]);
    const [vehicalTypeItem, setVehicalTypeItem] = useState([]);
    const [transportNameItem, setTransPortNameItem] = useState([]);
    const [airportItem, setAirportItem] = useState([])
    const [sealNumberItem, setSealNumberItem] = useState([])

    const [transporterDetailsError, setTransporterDetailsError] = useState({});
    const [dimentionDetailsError, setDimentionDetailsError] = useState({});
    const [multiPartyData, setMultiPartyData] = useState([])
    const [formDetailsData, setFormDetailsData] = useState({})
    const [formData, setFormData] = useState({
        pickup: '',
        vendorId: '',
        cost: '',
        pickupVehicleTypeId: '',
        vehicleNumber: '',
        driverName: '',
        driverNumber: '',
        packages: '',
        grossWeight: '',
        dimension: '',
        slotTime: '',
        lastHandOverTime: '',
        deliveryAt: '',
        isMiscCost: false,
        sealIntact: "",
        remarks: "",
        packingAmount: "",
        packingApprovedAmount: '',
        packingStatus: '',
        packingFile: '',
        fumigationAmount: '',
        fumigationApprovedAmount: '',
        fumigationStatus: '',
        fumigationFile: '',
        palletAmount: '',
        palletApprovedAmount: '',
        palletStatus: '',
        palletFile: '',
        loadingUnloadingAmount: "",
        loadingUnloadingApprovedAmount: '',
        loadingUnloadingStatus: '',
        loadingUnloadingFile: '',
        forkLiftAmount: "",
        forkLiftApprovedAmount: '',
        forkLiftStatus: '',
        forkLiftFile: '',
        miscAmount: '',
        miscApprovedAmount: '',
        miscStatus: '',
        miscFile: '',




        shippers: []

    })
    const get_SealNumberItem = () => {
        GetCaller({
            API: GetWarehouseStockRegisterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.stockNumber
                    }));

                setSealNumberItem(newCountryItemData);

            }

        });
    }

    const get_ShipperItem = () => {
        GetCaller({
            API: GetCustomerBySearch,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.legalName
                    }));

                setActualShipperItem(newCountryItemData);

            }

        });
    }

    const get_TransporterName = () => {
        GetCaller({
            API: GetVendorMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.legalName
                    }));

                setTransPortNameItem(newCountryItemData);

            }

        });
    }

    const get_VehicleType = () => {
        GetCaller({
            API: GetVehicalTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.name
                    }));

                setVehicalTypeItem(newCountryItemData);

            }

        });
    }

    const get_AirportItem = () => {
        GetCaller({
            API: GetAirportMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const airportItem = data?.map(res => ({
                    value: res?.id,
                    name: res?.name
                }))
                setAirportItem(airportItem)
            }
        })
    }

    const set_GoodOutRegisterDataById = (id) => {

        GetCaller({
            API: GetWarehouseGoodsOutRegisterById,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { id: id },
            callback: ({ response }) => {
                debugger

                const data = response?.data?.data
                data.dimensions = revertData(data?.dimensions || '')


                setFormDetailsData(data);
                setFormData({
                    ...formData,
                    ...data
                })
            }

        });
    }

    const set_GoodsoutMultiShipperdata = (multiIds) => {
        GetCaller({
            API: GetWerehouseGoodsInRegisterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {

                const data = response?.data?.data?.records
                const filteredData = data?.filter(res => multiIds.includes(res.id))
                setMultiPartyData(filteredData)

                // data.dimensions = revertData(data?.dimensions || '')


                // setFormDetailsData(data);
                // setFormData({
                //     ...formData,
                //     ...data
                // })
            }

        });
    }

    useEffect(() => {
        const goodsOutRegisterDetails = sessionStorage.getItem('goodsOutRegisterDetails')
        const selectedGoodsOutId = JSON.parse(sessionStorage.getItem('selectedGoodsOutId'))

        get_ShipperItem()
        get_TransporterName()
        get_VehicleType()
        get_AirportItem()
        get_SealNumberItem()

        if (goodsOutRegisterDetails) {
            set_GoodOutRegisterDataById(goodsOutRegisterDetails)
        }

        if (selectedGoodsOutId) {
            set_GoodsoutMultiShipperdata(selectedGoodsOutId)
        }

    }, [])



    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState(false);

    const handleFileOpen = () => {
        setOpen(true);
    };

    const handleFileClose = () => {
        setOpen(false);
        handleInputChange(null, { id: 'dimensionFile' })
    }

    const handleFileSubmit = () => {
        handleInputChange(file, { id: 'dimensionFile' })
        setOpen(false);
    }

    const convertDimensions = (data) => {
        if (data) {
            return data.map(item => {
                return `${item.length || 0}*${item.width || 0}*${item.height || 0}*${item.boxes || 0}`;
            }).join(',');
        } else {
            return ''
        }
    };

    const revertData = (dataString) => {
        return dataString.split(',').map(item => {
            const [length, width, height, boxes] = item.split('*').map(Number);
            return { length, width, height, boxes };
        });
    };



    const requestData = (() => {
        const data = {
            pickup: formData.pickup,
            pickupVehicleTypeId: formData.pickupVehicleTypeId,
            vehicleNumber: formData.vehicleNumber,
            driverName: formData.driverName,
            driverNumber: formData.driverNumber,
            packages: formData.packages,
            grossWeight: formData.grossWeight,
            dimension: formData.dimension,
            deliveryAt: formData.deliveryAt,
            sealIntact: formData.sealIntact,
            shippers: formData.shippers,


        }
        if (formData.id) data.id = formData.id
        if (formData.id) data.sealNumber = formData.sealNumber
        if (formData.challanNumber) data.challanNumber = formData.challanNumber
        if (formData.pickup === 1) data.vendorId = formData.vendorId
        if (formData.pickup === 1) data.cost = formData.cost
        if (formData.dimensionFile) data.dimensionFile = formData.dimensionFile
        if (formData.slotTime) data.slotTime = formData.slotTime
        if (formData.lastHandOverTime) data.lastHandOverTime = formData.lastHandOverTime
        if (formData.remarks) data.remarks = formData.remarks
        if (formData.packingAmount) data.packingAmount = formData.packingAmount
        if (formData.packingApprovedAmount) data.packingApprovedAmount = formData.packingApprovedAmount
        if (formData.packingStatus) data.packingStatus = formData.packingStatus
        if (formData.packingFile) data.packingFile = formData.packingFile
        if (formData.fumigationAmount) data.fumigationAmount = formData.fumigationAmount
        if (formData.fumigationApprovedAmount) data.fumigationApprovedAmount = formData.fumigationApprovedAmount
        if (formData.fumigationStatus) data.fumigationStatus = formData.fumigationStatus
        if (formData.fumigationFile) data.fumigationFile = formData.fumigationFile
        if (formData.palletAmount) data.palletAmount = formData.palletAmount
        if (formData.palletApprovedAmount) data.palletApprovedAmount = formData.palletApprovedAmount
        if (formData.palletStatus) data.palletStatus = formData.palletStatus
        if (formData.palletFile) data.palletFile = formData.palletFile
        if (formData.loadingUnloadingAmount) data.loadingUnloadingAmount = formData.loadingUnloadingAmount
        if (formData.loadingUnloadingApprovedAmount) data.loadingUnloadingApprovedAmount = formData.loadingUnloadingApprovedAmount
        if (formData.loadingUnloadingStatus) data.loadingUnloadingStatus = formData.loadingUnloadingStatus
        if (formData.loadingUnloadingFile) data.loadingUnloadingFile = formData.loadingUnloadingFile
        if (formData.forkLiftAmount) data.forkLiftAmount = formData.forkLiftAmount
        if (formData.forkLiftApprovedAmount) data.forkLiftApprovedAmount = formData.forkLiftApprovedAmount
        if (formData.forkLiftStatus) data.forkLiftStatus = formData.forkLiftStatus
        if (formData.forkLiftFile) data.forkLiftFile = formData.forkLiftFile
        if (formData.miscAmount) data.miscAmount = formData.miscAmount
        if (formData.miscApprovedAmount) data.miscApprovedAmount = formData.miscApprovedAmount
        if (formData.miscStatus) data.miscStatus = formData.miscStatus
        if (formData.miscFile) data.miscFile = formData.miscFile

        return data;
    })();




    const hasUndefinedValue = (obj) => {
        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                changes[key + "error"] = !value;
            }
        });
        return changes;
    };

    const transporterDetailshasUndefinedValue = (data) => {
        let array = data && data.length !== 0 ? data : [{
            pickup: '',
            packages: '',
            grossWeight: '',
            goodsCondition: '',
            cost: "",

        }]

        let changes = {};
        array.map((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                if (
                    (obj.goodsCondition !== 4 && obj.goodsCondition !== 5 && key === "number") ||
                    (obj.goodsCondition === 1 && key === 'comment') ||
                    (obj.goodsCondition === 1 && key === 'uploadFile') ||
                    (formData.pickup !== 1 && key === 'cost')
                ) {

                }
                else if (!value && (
                    key === 'number' ||
                    key === 'comment' ||
                    key === 'uploadFile' ||
                    key === 'goodsCondition' ||
                    key === 'packages' ||
                    key === 'grossWeight' ||
                    key === 'cost'
                )) {
                    changes[key + index] = !value;
                }
            });
        })
        console.log('transporterDetailshasUndefinedValue changes', changes)

        return changes;
    };


    const handleSubmit = () => {
        debugger

        const transporterDetailsChanges = transporterDetailshasUndefinedValue(requestData.shippers);
        const changes = hasUndefinedValue(requestData);

        if (
            Object.keys(changes).length > 0 ||
            Object.keys(transporterDetailsChanges).length > 0
        ) {

            setFormData({
                ...formData,
                ...changes
            })

            setTransporterDetailsError({
                ...transporterDetailsError,
                ...transporterDetailsChanges
            })



        } else {

            const formatedData = formatDataToFormData(requestData)
            PostCaller({
                API: formData.id ? UpdateWerehouseGoodsOutRegister : PostWerehouseGoodsOutRegister,
                callback: () => { navigate(goodsoutregisterlist) },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                contenttype: true,
                item: formatedData
            })
        }
    }


    const handleInputChange = (value, field) => {
        setFormData({
            ...formData,
            [field.id]: value,
            [field.id + 'error']: !value,
        })
    }

    return (
        <>
            <div className='hr-form-box'>
                <div className="cmd">
                    <h5>Goods Out Register</h5>
                    <p> <Checkbox checked={formData.isMiscCost} onChange={() => { handleInputChange(!formData.isMiscCost, { id: 'isMiscCost' }) }} /> Do You want to add misc cost ?</p>
                </div>

                <div className="row">

                    <div className="col-md-2">
                        <Dropdown
                            field={{
                                id: "pickup",
                                label: 'Dispatch ',
                                value: formData.pickup,
                                required: true,
                                error: formData.pickuperror,
                                item: PickUpType,
                                onChange: handleInputChange
                            }}
                        />
                    </div>

                    {
                        formData.pickup === 1 &&
                        <>
                            <div className="col-md-2">
                                <Dropdown
                                    field={{
                                        id: "vendorId",
                                        label: "Transporter Name ",
                                        type: "text",
                                        value: formData.vendorId,
                                        required: true,
                                        error: formData.vendorIderror,
                                        onChange: handleInputChange,
                                        item: transportNameItem
                                    }}
                                />
                            </div>

                            <div className="col-md-2">
                                <InputField
                                    field={{
                                        id: "cost",
                                        label: formconstant.cost,
                                        type: "number",
                                        value: formData.cost,
                                        required: true,
                                        disable: true,
                                        error: formData.costerror,
                                        onChange: handleInputChange,
                                    }}
                                />
                            </div>

                        </>
                    }

                    <div className="col-md-2">
                        <Dropdown
                            field={{
                                id: "pickupVehicleTypeId",
                                label: "Vehicle Type ",
                                type: "text",
                                value: formData.pickupVehicleTypeId,
                                required: true,
                                error: formData.pickupVehicleTypeIderror,
                                onChange: handleInputChange,
                                item: vehicalTypeItem
                            }}
                        />
                    </div>

                    <div className="col-md-2">
                        <InputField
                            field={{
                                id: "vehicleNumber",
                                label: formconstant.vehicalno,
                                type: "text",
                                value: formData.vehicleNumber,
                                required: true,
                                error: formData.vehicleNumbererror,
                                onChange: handleInputChange,
                            }}
                        />
                    </div>

                    <div className="col-md-2">
                        <InputField
                            field={{
                                id: "driverName",
                                label: formconstant.drivername,
                                type: "text",
                                value: formData.driverName,
                                required: true,
                                error: formData.driverNameerror,
                                onChange: handleInputChange,
                            }}
                        />
                    </div>

                    <div className="col-md-2">
                        <InputField
                            field={{
                                id: "driverNumber",
                                label: formconstant.driverno,
                                type: "number",
                                value: formData.driverNumber,
                                required: true,
                                error: formData.driverNumbererror,
                                onChange: handleInputChange,
                            }}
                        />
                    </div>


                    <div className="col-md-2">

                        <InputField
                            field={{
                                id: "packages",
                                label: 'Total Packages',
                                type: "number",
                                value: formData.packages,
                                required: true,
                                disable: "true",
                                error: formData.packageserror,
                                onChange: handleInputChange,
                            }}
                        />

                    </div>

                    <div className="col-md-2">

                        <InputField
                            field={{
                                id: "grossWeight",
                                label: 'Total Gross Weight',
                                type: "number",
                                value: formData.grossWeight,
                                required: true,
                                disable: true,
                                error: formData.grossWeighterror,
                                onChange: handleInputChange,
                            }}
                        />

                    </div>

                    <div className="col-md-2">
                        <div className="cmd">
                            <Dropdown
                                field={{
                                    id: "dimension",
                                    label: formconstant.dimension  ,
                                    type: "text",
                                    value: formData.dimension,
                                    required: true,
                                    error: formData.dimensionerror,
                                    onChange: handleInputChange,
                                    item: EnquiryDimension
                                }}
                            />
                            <React.Fragment>
                                <div onClick={handleFileOpen}>
                                    <Tooltip title="Upload sheet">
                                        <Button className='mt-3 p-1 ms-2' variant='contained' style={{ minWidth: "30px" }} >
                                            <Add />
                                        </Button>
                                    </Tooltip>
                                </div>


                                <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleFileClose}
                                    aria-describedby="alert-dialog-slide-description"
                                    PaperProps={{
                                        sx: {
                                            width: "100%",
                                            maxWidth: "430px!important",
                                        },
                                    }}
                                    sx={{
                                        '& .MuiDialog-container': {
                                            alignItems: "start"
                                        }
                                    }
                                    }


                                >
                                    <div className=''>
                                        <DialogTitle sx={{ pb: "0" }}>
                                            <div className="cmd">
                                                <div>{columnConstant.dimensionsheet}</div>
                                                <Tooltip title='Close'>
                                                    <IconButton onClick={handleFileClose}>
                                                        <Close />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <hr />
                                        </DialogTitle>
                                        <DialogContent>
                                            <InputFileField
                                                className='mt-3'
                                                field={{
                                                    id: "",
                                                    label: "Upload Sheet",
                                                    type: 'file',
                                                    required: true,
                                                    value: formData.dimensionFile,
                                                    error: !formData.dimensionFile && formData.dimensionFileerror,
                                                    helperText: 'Upload Pan Card Required',
                                                    onChange: (value, field) => {
                                                        setFile(value)
                                                    }
                                                }}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleFileClose} variant='outlined'>Cancel</Button>
                                            <Button onClick={handleFileSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
                                        </DialogActions>
                                    </div>
                                </Dialog>
                            </React.Fragment >
                        </div>
                    </div>

                    <div className="col-md-2">
                        <InputField
                            field={{
                                id: "slotTime",
                                label: 'Slot Time',
                                value: formData.slotTime,
                                required: false,
                                error: formData.slotTimeerror,
                                onChange: handleInputChange,
                            }}
                        />
                    </div>

                    {/* <div className="col-md-2">
                        <DateField
                            field={{
                                id: "lastHandOverTime",
                                label: 'LHT',
                                value: formData.lastHandOverTime,
                                required: false,
                                error: formData.lastHandOverTimeerror,
                                onChange: handleInputChange,
                            }}
                        />
                    </div> */}



                    <div className="col-md-2">
                        <Dropdown
                            field={{
                                id: "deliveryAt",
                                label: 'Delivery At ',
                                value: formData.deliveryAt,
                                required: true,
                                error: formData.deliveryAterror,
                                onChange: handleInputChange,
                                item: DeliveryAt
                            }}
                        />
                    </div>

                    <div className="col-md-2">
                        <Dropdown
                            field={{
                                id: "sealIntact",
                                label: 'Seal Assign ',
                                value: formData.sealIntact,
                                required: true,
                                error: formData.sealIntacterror,
                                onChange: handleInputChange,
                                item: applicableFormat
                            }}
                        />
                    </div>

                    {
                        formData.id &&
                        <div className="col-md-2">
                            <InputField
                                field={{
                                    id: "challanNumber",
                                    label: 'Challan Number',
                                    value: formData.challanNumber,
                                    required: true,
                                    disable: true,
                                    error: formData.challanNumbererror,
                                    onChange: handleInputChange,
                                }}
                            />
                        </div>

                    }
                    {
                        formData.id &&
                        <div className="col-md-2">
                            <Dropdown
                                field={{
                                    id: "sealNumber",
                                    label: 'Seal Number ',
                                    value: formData.sealNumber,
                                    required: true,
                                    error: formData.sealNumbererror,
                                    onChange: handleInputChange,
                                    item: sealNumberItem
                                }}
                            />
                        </div>

                    }




                    <div className="col-md-2">
                        <InputField
                            field={{
                                id: "remarks",
                                label: formconstant.remarks,
                                type: "text",
                                value: formData.remarks,
                                required: false,
                                error: formData.remarkserror,
                                onChange: handleInputChange
                            }}
                        />
                    </div>


                </div>

            </div>

            {
                formData?.isMiscCost &&

                <div className="hr-form-box">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="dimensions_table mt-4" >
                                <table style={{ tableLayout: 'fixed' }}>


                                    <tr>
                                        <th></th>
                                        <th>Ware House</th>
                                        <th>Final Cost</th>
                                        <th ></th>
                                        <th style={{ width: '55px' }}>
                                        </th>

                                    </tr>



                                    <tr>
                                        <td>
                                            PACKING
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}
                                                field={{
                                                    id: "packingAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.packingAmount,
                                                    required: true,
                                                    error: formData.packingAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}

                                                field={{
                                                    id: "packingApprovedAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.packingApprovedAmount,
                                                    required: true,
                                                    error: formData.packingApprovedAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputFileField
                                                className={'mt-0'}

                                                field={{
                                                    id: "packingFile",
                                                    label: '',
                                                    type: "file",
                                                    value: formData.packingFile,
                                                    required: true,
                                                    error: formData.packingFileerror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Tooltip title='Approved'>
                                                <Checkbox checked={formData?.packingStatus} onChange={() => { handleInputChange(!formData?.packingStatus, { id: 'packingStatus' }) }} />
                                            </Tooltip>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>
                                            FUMIGATION
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}
                                                field={{
                                                    id: "fumigationAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.fumigationAmount,
                                                    required: true,
                                                    error: formData.fumigationAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}

                                                field={{
                                                    id: "fumigationApprovedAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.fumigationApprovedAmount,
                                                    required: true,
                                                    error: formData.fumigationApprovedAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputFileField
                                                className={'mt-0'}

                                                field={{
                                                    id: "fumigationFile",
                                                    label: '',
                                                    type: "file",
                                                    value: formData.fumigationFile,
                                                    required: true,
                                                    error: formData.fumigationFileerror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Tooltip title='Approved'>
                                                <Checkbox checked={formData?.fumigationStatus} onChange={() => { handleInputChange(!formData?.fumigationStatus, { id: 'fumigationStatus' }) }} />
                                            </Tooltip>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>
                                            PALLET
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}
                                                field={{
                                                    id: "palletAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.palletAmount,
                                                    required: true,
                                                    error: formData.palletAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}

                                                field={{
                                                    id: "palletApprovedAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.palletApprovedAmount,
                                                    required: true,
                                                    error: formData.palletApprovedAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputFileField
                                                className={'mt-0'}

                                                field={{
                                                    id: "palletFile",
                                                    label: '',
                                                    type: "file",
                                                    value: formData.palletFile,
                                                    required: true,
                                                    error: formData.palletFileerror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Tooltip title='Approved'>
                                                <Checkbox checked={formData?.palletStatus} onChange={() => { handleInputChange(!formData?.palletStatus, { id: 'palletStatus' }) }} />
                                            </Tooltip>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>
                                            LOADING/UNLOADING
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}
                                                field={{
                                                    id: "loadingUnloadingAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.loadingUnloadingAmount,
                                                    required: true,
                                                    error: formData.loadingUnloadingAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}

                                                field={{
                                                    id: "loadingUnloadingApprovedAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.loadingUnloadingApprovedAmount,
                                                    required: true,
                                                    error: formData.loadingUnloadingApprovedAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputFileField
                                                className={'mt-0'}

                                                field={{
                                                    id: "loadingUnloadingFile",
                                                    label: '',
                                                    type: "file",
                                                    value: formData.loadingUnloadingFile,
                                                    required: true,
                                                    error: formData.loadingUnloadingFileerror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Tooltip title='Approved'>
                                                <Checkbox checked={formData?.loadingUnloadingStatus} onChange={() => { handleInputChange(!formData?.loadingUnloadingStatus, { id: 'loadingUnloadingStatus' }) }} />
                                            </Tooltip>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>
                                            FORK
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}
                                                field={{
                                                    id: "forkLiftAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.forkLiftAmount,
                                                    required: true,
                                                    error: formData.forkLiftAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}

                                                field={{
                                                    id: "forkLiftApprovedAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.forkLiftApprovedAmount,
                                                    required: true,
                                                    error: formData.forkLiftApprovedAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputFileField
                                                className={'mt-0'}

                                                field={{
                                                    id: "forkLiftFile",
                                                    label: '',
                                                    type: "file",
                                                    value: formData.forkLiftFile,
                                                    required: true,
                                                    error: formData.forkLiftFileerror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Tooltip title='Approved'>
                                                <Checkbox checked={formData?.forkLiftStatus} onChange={() => { handleInputChange(!formData?.forkLiftStatus, { id: 'forkLiftStatus' }) }} />
                                            </Tooltip>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>
                                            MISC
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}
                                                field={{
                                                    id: "miscAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.miscAmount,
                                                    required: true,
                                                    error: formData.miscAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputField
                                                className={'mt-0'}

                                                field={{
                                                    id: "miscApprovedAmount",
                                                    label: '',
                                                    type: "number",
                                                    value: formData.miscApprovedAmount,
                                                    required: true,
                                                    error: formData.miscApprovedAmounterror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <InputFileField
                                                className={'mt-0'}

                                                field={{
                                                    id: "miscFile",
                                                    label: '',
                                                    type: "file",
                                                    value: formData.miscFile,
                                                    required: true,
                                                    error: formData.miscFileerror,
                                                    onChange: handleInputChange,
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Tooltip title='Approved'>
                                                <Checkbox checked={formData?.miscStatus} onChange={() => { handleInputChange(!formData?.miscStatus, { id: 'miscStatus' }) }} />
                                            </Tooltip>
                                        </td>

                                    </tr>


                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            }




            <div className="hr-form-box">
                <ShippersDetails
                    airportItem={airportItem}
                    actualShipperItem={actualShipperItem}
                    transporterDetailsError={transporterDetailsError}
                    setTransporterDetailsError={setTransporterDetailsError}
                    handleInputChange={handleInputChange}
                    vehicalTypeItem={vehicalTypeItem}
                    transportNameItem={transportNameItem}
                    formDetailsData={formDetailsData}
                    formData={formData}
                    setFormData={setFormData}
                    multiPartyData={multiPartyData}
                />
            </div>



            <div className="cmd">
                <div></div>
                <Button variant='contained' onClick={handleSubmit}>Submit</Button>
            </div>
        </>
    )
}

export default CreateGoodsOutRegisterForm;





function ShippersDetails({
    airportItem,
    actualShipperItem,
    transporterDetailsError,
    setTransporterDetailsError,
    handleInputChange,
    vehicalTypeItem,
    transportNameItem,
    formDetailsData,
    formData,
    setFormData,
    multiPartyData
}) {

    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    useEffect(() => {
        if (formDetailsData.shippers && formDetailsData.shippers.length !== 0) {
            let item = formDetailsData.shippers?.map(res => (
                {
                    ...res,
                    key: generateUniqueKey(),
                    expectedGrossWeight: `${res.totalJobPackages}/${res.totalJobGrossWeight - res.totalJobReceivedGrossWeight}`,
                    expectedPackages: `${res.totalJobPackages}/${res.totalJobPackages - res.totalJobReceivedPackages}`,
                }
            ));
            setInputFields(item);
        }

        if (multiPartyData.length !== 0) {
            let item = multiPartyData?.map(res => (
                {
                    ...res,
                    key: generateUniqueKey(),
                    entryNumber: res.id,
                    packages: "",
                    grossWeight: '',
                    expectedGrossWeight: `${res.grossWeight}/${res.grossWeight - res.actualOutGrossWeight}`,
                    expectedPackages: `${res.packages}/${res.packages - res.actualOutPackages}`,
                    number: "",
                    cost: 0,


                }
            ));
            setInputFields(item);
        }

    }, [formDetailsData, multiPartyData])



    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            id: "",
            entryNumber: '',
            jobNumber: "",
            packages: "",
            grossWeight: '',
            cost: "",
            goodsCondition: '',
            number: "",
            comment: '',
            uploadFile: '',
            dimensionFile: '',
            slotTime: '',
            remarks: "",

            shipperAddress: [

            ]

        },
    ]);


    const totalValueCounter = () => {
        let totalPackages = 0
        let totalGrossWeight = 0
        let totalCost = 0

        inputFields?.map(res => {
            const cost = res.cost !== "" ? Number(res.cost) : 0;
            const grossWeight = res.grossWeight !== "" ? Number(res.grossWeight) : 0;
            const packages = res.packages !== "" ? Number(res.packages) : 0;

            totalCost = Number(totalCost + cost);
            totalGrossWeight = Number(totalGrossWeight + grossWeight);
            totalPackages = Number(totalPackages + packages);
        })

        setFormData({
            ...formData,
            ["cost"]: totalCost,
            ["grossWeight"]: totalGrossWeight,
            ["packages"]: totalPackages,
        })

    }

    useEffect(() => {
        totalValueCounter()
    }, [inputFields])

    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            id: "",
            entryNumber: '',
            jobNumber: "",
            packages: "",
            grossWeight: '',
            cost: "",
            goodsCondition: '',
            number: "",
            comment: '',
            uploadFile: '',
            dimensionFile: '',
            slotTime: '',
            remarks: "",

            shipperAddress: []
        }]);
    };

    const handleDuplicateInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];

        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
        if (fieldToUpdate) {

            setTransporterDetailsError({
                ...transporterDetailsError,
                [field + index]: !value
            })

            if (field === 'pickup') {
                fieldToUpdate['cost'] = 0;
            }
            fieldToUpdate[field] = value;

            setInputFields(updatedInputFields);

            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res.id || null,
                        entryNumber: res.entryNumber,
                        jobNumber: res.jobNumber,
                        packages: res.packages,
                        grossWeight: res.grossWeight,
                        cost: res.cost,
                        goodsCondition: res.goodsCondition,
                        number: res.number,
                        comment: res.comment,
                        uploadFile: res.uploadFile,
                        dimensionFile: res.dimensionFile,
                        // slotTime: res.slotTime,
                        remarks: res.remarks,
                        shipperAddress: res.shipperAddress,

                    }
                )
            })

            handleInputChange(data, { id: 'shippers' });

        }
    };

    const removeInputField = (key) => {

        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    id: res.id || null,
                    entryNumber: res.entryNumber,
                    jobNumber: res.jobNumber,
                    packages: res.packages,
                    grossWeight: res.grossWeight,
                    cost: res.cost,
                    goodsCondition: res.goodsCondition,
                    number: res.number,
                    comment: res.comment,
                    uploadFile: res.uploadFile,
                    dimensionFile: res.dimensionFile,
                    // slotTime: res.slotTime,
                    remarks: res.remarks,
                    shipperAddress: res.shipperAddress,


                }
            )

        })
        handleInputChange(data, { id: 'shippers' });
    };

    return (
        <div className=''>

            <div className="cmd">
                <h5 className="m-0 p-0">Shippers</h5>

            </div>

            {inputFields.map((field, key) => (
                <div className='row' key={field.key}>
                    <div className="col-md-1 cmd" style={{ justifyContent: "left" }}>
                        {
                            key !== 0 ? (
                                <Button
                                    sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                    onClick={() => removeInputField(field.key)}
                                    variant='outlined'
                                >
                                    <Close />
                                </Button>
                            )
                                :
                                <Button sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                    <Add />
                                </Button>
                        }
                    </div>
                    <div className="col-md-11">
                        <div className="row my-2">

                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: 'jobNumber',
                                            label: "Job No ",
                                            type: "select",
                                            value: field.jobNumber || '',
                                            required: true,
                                            error: transporterDetailsError['jobNumber' + key],
                                            item: [],
                                            onChange: (value) => {
                                                handleDuplicateInputChange(field.key, 'jobNumber', value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: 'partyName',
                                            label: "Party Name",
                                            type: "text",
                                            value: field.partyName,
                                            required: true,
                                            disable: true,
                                            error: transporterDetailsError['partyName' + key],
                                            onChange: (value) => {
                                                handleDuplicateInputChange(field.key, 'partyName', value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: 'actualShipper',
                                            label: "Shipper ",
                                            type: "select",
                                            value: field.actualShipper,
                                            required: true,
                                            disable: true,
                                            error: transporterDetailsError['actualShipper' + key],
                                            item: actualShipperItem,
                                            onChange: (value) => {
                                                handleDuplicateInputChange(field.key, 'actualShipper', value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: 'airportOfLoading',
                                            label: "APOL ",
                                            type: "select",
                                            value: field.airportOfLoading,
                                            required: true,
                                            disable: true,
                                            error: transporterDetailsError['airportOfLoading' + key],
                                            item: airportItem,
                                            onChange: (value) => {
                                                handleDuplicateInputChange(field.key, 'airportOfLoading', value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: 'airportOfDestination',
                                            label: "APOD ",
                                            type: "select",
                                            value: field.airportOfDestination,
                                            required: true,
                                            disable: true,
                                            error: transporterDetailsError['airportOfDestination' + key],
                                            item: airportItem,
                                            onChange: (value) => {
                                                handleDuplicateInputChange(field.key, 'airportOfDestination', value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: 'allotMawbNumber',
                                            label: formconstant.mawbno,
                                            type: "number",
                                            value: field.allotMawbNumber,
                                            required: true,
                                            disable: true,
                                            error: transporterDetailsError['allotMawbNumber' + key],
                                            onChange: (value) => {
                                                handleDuplicateInputChange(field.key, 'allotMawbNumber', value, key);
                                            }
                                        }
                                    }
                                />
                            </div>



                            <div className="col-md-2">
                                <div className='d-flex gstWrapper'>
                                    <InputField
                                        field={
                                            {
                                                id: 'expectedPackages',
                                                label: 'Total Packages',
                                                type: "text",
                                                value: field.expectedPackages,
                                                required: true,
                                                disable: true,
                                                error: transporterDetailsError['expectedPackages' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'expectedPackages', value, key);
                                                }
                                            }
                                        }
                                    />
                                    <InputField
                                        field={
                                            {
                                                id: 'packages',
                                                label: formconstant.packages,
                                                type: "number",
                                                value: field.packages,
                                                required: true,
                                                error: transporterDetailsError['packages' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'packages', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className='d-flex gstWrapper'>
                                    <InputField
                                        field={
                                            {
                                                id: 'expectedGrossWeight',
                                                label: 'Total Gross Wt.',
                                                type: "text",
                                                value: field.expectedGrossWeight,
                                                required: true,
                                                disable: true,
                                                error: transporterDetailsError['expectedGrossWeight' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'expectedGrossWeight', value, key);
                                                }
                                            }
                                        }
                                    />
                                    <InputField
                                        field={
                                            {
                                                id: 'grossWeight',
                                                label: formconstant.grossweight,
                                                type: "number",
                                                value: field.grossWeight,
                                                required: true,
                                                error: transporterDetailsError['grossWeight' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'grossWeight', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: 'goodsCondition',
                                            label: formconstant.goodsconditions  ,
                                            type: "select",
                                            value: field.goodsCondition || '',
                                            required: true,
                                            error: transporterDetailsError['goodsCondition' + key],
                                            item: GoodsConditions,
                                            onChange: (value) => {
                                                handleDuplicateInputChange(field.key, 'goodsCondition', value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            {
                                (field.goodsCondition === 4 || field.goodsCondition === 5) &&
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: 'number',
                                                label: formconstant.number,
                                                type: "number",
                                                value: field.number,
                                                required: true,
                                                error: transporterDetailsError['number' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'number', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            }
                            {
                                field?.goodsCondition && field?.goodsCondition !== 1 ? <>
                                    <div className="col-md-2">
                                        <InputField
                                            field={
                                                {
                                                    id: 'comment',
                                                    label: formconstant.comments,
                                                    type: "comment",
                                                    value: field.comment,
                                                    required: true,
                                                    error: transporterDetailsError['comment' + key],
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'comment', value, key);
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <InputFileField
                                            className='mt-3'
                                            field={{
                                                id: "uploadFile",
                                                label: formconstant.uploadpic,
                                                type: 'file',
                                                required: true,
                                                error: transporterDetailsError['uploadFile' + key],
                                                helperText: 'Upload Pan Card Required',
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'uploadFile', value, key);
                                                }
                                            }}
                                        />
                                    </div>
                                </> : ''
                            }


                            {
                                formData.pickup === 1 &&
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: 'cost',
                                                label: formconstant.cost,
                                                type: "number",
                                                value: field.cost,
                                                required: true,
                                                error: transporterDetailsError['cost' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'cost', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            }





                            {
                                formData.deliveryAt === 4 && <div className="col-12">
                                    <ShippersAddressDetails
                                        transporterDetailsError={transporterDetailsError}
                                        setTransporterDetailsError={setTransporterDetailsError}
                                        handleInputChange={(value, obj) => { handleDuplicateInputChange(field.key, obj.id, value, key); }}
                                        formDetailsData={formDetailsData}
                                        updateShipperAddressData={field.shipperAddress}
                                    />
                                </div>
                            }



                        </div>
                    </div>

                </div>
            ))}

        </div>
    );
}

function ShippersAddressDetails({
    transporterDetailsError,
    setTransporterDetailsError,
    handleInputChange,
    updateShipperAddressData,
    formDetailsData
}) {



    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            id: "",
            address: '',
            packages: '',
            grossWeight: '',
            uploadPodFilePath: ""

        },
    ]);

    useEffect(() => {
        debugger
        if (formDetailsData?.shippers && formDetailsData?.shippers?.length !== 0 && updateShipperAddressData?.length !== 0) {
            const ShipperUpdateAddressdata = updateShipperAddressData?.map((res) => ({
                ...res,
                key: generateUniqueKey(),
            }))

            setInputFields(ShipperUpdateAddressdata)
        }

    }, [formDetailsData])

    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            id: "",
            address: '',
            packages: '',
            grossWeight: '',
            uploadPodFilePath: ""
        }]);
    };

    const handleDuplicateInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];

        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
        if (fieldToUpdate) {

            setTransporterDetailsError({
                ...transporterDetailsError,
                [field + index]: !value
            })


            fieldToUpdate[field] = value;

            setInputFields(updatedInputFields);

            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res.id || null,
                        address: res.address,
                        packages: res.packages,
                        grossWeight: res.grossWeight,
                        uploadPodFilePath: res.uploadPodFilePath
                    }
                )
            })

            handleInputChange(data, { id: 'shipperAddress' });

        }
    };

    const removeInputField = (key) => {

        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    id: res.id || null,
                    address: res.address,
                    packages: res.packages,
                    grossWeight: res.grossWeight,
                    uploadPodFilePath: res.uploadPodFilePath
                }
            )

        })
        handleInputChange(data, { id: 'shipperAddress' });
    };

    return (
        <div className=''>

            {inputFields?.map((field, key) => (
                <div className='cmd' key={field.key}>
                    <div className=" mt-3 me-3" style={{ justifyContent: "left" }}>
                        {
                            key !== 0 ? (
                                <Button
                                    sx={{ borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                    onClick={() => removeInputField(field.key)}
                                    variant='outlined'
                                >
                                    <Close />
                                </Button>
                            )
                                :
                                <Button sx={{ borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                    <Add />
                                </Button>
                        }
                    </div>

                    <div className="row  w-100">



                        <div className="col-md-2">
                            <InputField
                                field={
                                    {
                                        id: 'address',
                                        label: "Address",
                                        type: "text",
                                        value: field.address,
                                        required: true,
                                        disable: false,
                                        error: transporterDetailsError['address' + key],
                                        onChange: (value) => {
                                            handleDuplicateInputChange(field.key, 'address', value, key);
                                        }
                                    }
                                }
                            />
                        </div>

                        <div className="col-md-2">

                            <InputField
                                field={
                                    {
                                        id: 'packages',
                                        label: formconstant.packages,
                                        type: "number",
                                        value: field.packages,
                                        required: true,
                                        disable: false,
                                        error: transporterDetailsError['packages' + key],
                                        onChange: (value) => {
                                            handleDuplicateInputChange(field.key, 'packages', value, key);
                                        }
                                    }
                                }
                            />
                        </div>

                        <div className="col-md-2">
                            <InputField
                                field={
                                    {
                                        id: 'grossWeight',
                                        label: formconstant.grossweight,
                                        type: "number",
                                        value: field.grossWeight,
                                        required: true,
                                        error: transporterDetailsError['grossWeight' + key],
                                        onChange: (value) => {
                                            handleDuplicateInputChange(field.key, 'grossWeight', value, key);
                                        }
                                    }
                                }
                            />
                        </div>

                        {
                            formDetailsData.id &&
                            <div className="col-md-2">
                                <InputFileField
                                    field={
                                        {
                                            id: 'uploadPodFilePath',
                                            label: 'Upload POD',
                                            type: "file",
                                            value: field.uploadPodFilePath,
                                            required: true,
                                            error: transporterDetailsError['uploadPodFilePath' + key],
                                            onChange: (value) => {
                                                handleDuplicateInputChange(field.key, 'uploadPodFilePath', value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                        }


                    </div>

                </div>
            ))}

        </div>
    );
}



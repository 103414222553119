import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import { GetMasterCityByPage, GetMasterCountryByPage, GetMasterStateByPage, PostMasterCity, PostMasterCountry, PostMasterState, UpdateMasterCity, UpdateMasterCityStatus, UpdateMasterState } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import DivisonDrawer from '../../component/divisionDrawer';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';

const City = () => {
 
    const dispatch = useDispatch();
    const [cityMasterData, setCityMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterCityByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);

    const setTableDataHandler = ({ response }) => {
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            cityname: res.name,
            citycode: res.cityCode,
            state: res.state?.value,
            stateid: res.state?.key,
            countryname: res.country?.value,
            countryid: res.country?.key,
            status: res.status

        }));
        setCityMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
        {
            id: 'cityname',
            label: 'City Name',
            numeric: false,
        },
        
        {
            id: 'state',
            label: 'State Name',
            numeric: false,
        },
        {
            id: 'countryname',
            label: 'Country Name',
            numeric: false,
        },
        {
            id: 'citycode',
            label: 'Code',
            numeric: false,
        },
        
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];

    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateMasterCityStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <CityDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>
    }
    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <CityDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }
    return (
        <>
            {/* filterItems={filterItems} */}
            <MultiTable
                data={cityMasterData}
                columns={columns}
                title={'City'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default City;




const CityDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()
   
    const [closeValidator, setcloseValidator] = useState(false)
    const [stateItem, setStateItem] = useState([])
    const [countryItem, setCountryItem] = useState(row?.countryid || '')
    const [cityName, setCityName] = useState(row?.cityname || '')
    const [cityNameError, setCityNameError] = useState(false)
    const [stateName, setStateName] = useState(row?.cityname || '')
    const [stateNameError, setStateNameError] = useState(false)
    const [cityCode, setCityCode] = useState(row?.citycode || '')
    const [cityCodeError, setCityCodeError] = useState(false)


    const drawerOpenCallbackHandler = () => {

        setCityName(row?.cityname || '');
        setCityCode(row?.citycode || '');
        setStateName(row?.stateid || '');
        setCityNameError(false);
        setCityCodeError(false);
        setStateNameError(false);
    }

    const set_StateDataItemData = () => {
        debugger;
        GetCaller({
            API: PostMasterState,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                debugger;
                const newCountryItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                    cntname: item.country.key,
                }))
                console.log(newCountryItemData)
                setStateItem(newCountryItemData)
            }
        })
    }

    //const set_CountryItemData = () => {
    //    GetCaller({
    //        API: PostMasterCountry,
    //        dispatch: (e) => {
    //            dispatch({ type: e.type, payload: e.payload })
    //        },
    //        item: {},
    //        callback: ({ response }) => {
    //            const newCountryItemData = response?.data?.data?.records.map((item) => ({
    //                value: item.id,
    //                name: item.name
    //            }))
    //            setCountryItem(newCountryItemData)
    //        }
    //    })
    //}

    //const set_StateItemData = (countryId) => {
    //    GetCaller({
    //        API: PostMasterState,
    //        dispatch: (e) => {
    //            dispatch({ type: e.type, payload: e.payload })
    //        },
    //        item: {},
    //        callback: ({ response }) => {
    //            let newStateItemData;

    //            if (countryId) {
    //                newStateItemData = response?.data?.data?.records
    //                    .filter((item) => item.country.key === countryId)
    //                    .map((item) => ({
    //                        value: item.id,
    //                        name: item.name,
    //                        key: item.country.key
    //                    }));
    //            } else {
    //                newStateItemData = response?.data?.data?.records
    //                    .map((item) => ({
    //                        value: item.id,
    //                        name: item.name,
    //                        key: item.country.key
    //                    }));
    //            }

    //            setStateItem(newStateItemData);
    //        }

    //    })
    //}

    useEffect(() => {     
        set_StateDataItemData()
    }, []);



    useEffect(() => {
        setcloseValidator(false)
        if (editor) {
            setCityName(row?.cityname)
            setCityCode(row?.citycode)
            setStateName(row?.stateid)
        } else {
            setCityName('')
            setCityCode('')
            setStateName('')
        }

    }, [closeValidator]);

    const handleSubmit = () => {
        if (!cityCode || !cityName || !stateName) {
            setCityNameError(!cityName)
            setCityCodeError(!cityCode)
            setStateNameError(!stateName)

        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: cityName,
                cityCode: cityCode,
                stateId: stateName,
                countryId: countryItem,
                status: row.status        
            } : {
                name: cityName,
                cityCode: cityCode,
                    countryId: countryItem,
                stateId: stateName,
            }     
            editor ?
              PostCaller({
                API: UpdateMasterCity,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })     
              :    
              PostCaller({
                API: PostMasterCity,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })
            setcloseValidator(true)
      
          }

    }
    const handleInputChange = (value, field) => {
        debugger;
        switch (field.id) {
            case 'city':
                setCityName(value)
                setCityNameError(!value);
                break;
            case 'citycode':
                setCityCode(value)
                setCityCodeError(!value);
                break;
            case 'state':
                setStateName(value)
                setStateNameError(!value);
                const setcontid = stateItem.find(obj => obj.value === value)
                if (setcontid) {
                    setCountryItem(setcontid.cntname)
                }
                break;   
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit City",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit City',
            iconclass: 'noClass',
            defaultbtnfalse: true
        }
        : {
            title: "Add City",
            tooltitle: 'Add City',
            defaultbtnfalse: true
        }

    const FieldData = [

        {
            id: 'city',
            label: 'City Name',
            type: "text",
            value: cityName,
            error: cityNameError,
            required: true,
            helperText: 'City Name required',
            onChange: handleInputChange,
        },
        {
            id: 'citycode',
            label: 'Code',
            type: "text",
            value: cityCode,
            error: cityCodeError,
            required: true,
            helperText: "Code required",
            onChange: handleInputChange,
        },
        {
            id: 'state',
            label: 'State Name',
            type: "select",
            value: stateName,
            error: stateNameError,
            item: stateItem,
            required: true,
            helperText: 'State Name required',
            onChange: handleInputChange,
        },
    ];


    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            divisiondrawerbody={divisionDrawerody}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
        />

    </>
}


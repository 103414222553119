import { useDispatch } from "react-redux"
import { GetHawbByPage } from "../../../../services/constant"
import { useEffect, useState } from "react"
import MasterCallBypagination from "../../../../services/masterpaginationConfig"
import GetCaller from "../../../../services/apiServices"
import { MultiTable } from "../../../component/multiTable"
import { awbstore, createawbstock, createcourierawb, createhawbform } from "../../../../navigation/constant"
import { IconButton, Tooltip } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom"
import { GetAwbStorebypage, GetCourierAwbbypage } from "../../../../services/APIConstant/jobConstant"
import CreateAwbStock from "../awb/createawbStock"
const CourierStore  =() =>{
const dispatch = useDispatch()
const navigate = useNavigate()
const [AwsStoreData , setAwsStoreData] = useState([])
const [totaldatalength ,setTotalDataLength] = useState(0)

const fatch_MasterDataBYPagination =(item) =>{
    MasterCallBypagination({
        API: GetCourierAwbbypage,
        callback: setTableDataHandler, dispatch:(e) => {
            dispatch({type: e.type, payload: e.payload})
        },
        item : item

    })
}

useEffect(() =>{
    fatch_MasterDataBYPagination();
},[]);
const setTableDataHandler = ({response}) =>{
    const newmasterdata = response?.data?.data?.records?.map((res,index) =>({
sn: index + 1,
id: res.id,
airlineId: res.airlineId,
airlineName: res.airlineName,
stockDate: res.stockDate,
document: res.document,

    }));
    setAwsStoreData(newmasterdata);
    setTotalDataLength(response?.data?.data?.totalRecords)
}

const columns =[
{
    id:'sn',
    label:'S.No',
    numeric:false
},
{
    id:'airlineName',
    label:'Carrier',
    numeric:false
},
{
    id:'stockDate',
    label:'Stock Date',
    numeric:false
},
{
    id:'action',
    label:'Action',
    numeric:false
},


];


const ActionCell =({row}) =>{
    const onclick =() =>{
        sessionStorage.setItem('courierstock', JSON.stringify(row));
    navigate(`${createcourierawb}`)
    
    } 
    return<>
    <Tooltip title="Edit AWB Store">
                <IconButton
                    className='tablebtn1'
                    onClick={onclick}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >
            </>
}


const TableTool = ({ }) => {
    return <>
        <Tooltip title={"Add Courier Stock"}>
            <IconButton
                className='table-toggle'
                onClick={() => navigate(`${createcourierawb}`)}
            >
                <AddIcon />
            </IconButton>
        </Tooltip >

    </>
}

return(
    <>
    <MultiTable
    data= {AwsStoreData}
    columns={columns}
    title={"Courier Stock"}
    tabletool={TableTool}
    actioncell={ActionCell}
totaldatalength={totaldatalength}
paginationhandler ={fatch_MasterDataBYPagination}
    />
    </>
)

}
export default CourierStore

const HawbDrawer =({callAPI, editor ,row}) =>{


}
import { ConvertValue } from "./valueConverter";

export const CalculateTotalAirVolumnWeight = (inputFields, dimentionType) => {

    const dimensionTypeVolumetricFactor = dimentionType === 1 ? 6000 : dimentionType === 2 ? 366 : 0;

    const obj = {
        totalWeight: 0,
        totalPackages: 0,
        CourierVolumeWeight: 0,
        CBM: 0
    }
    debugger

    inputFields?.forEach((res, key) => {

        const { length, width, height, boxes } = res;

        let currentRowTotalWeight = 0;

        if (length && width && height && boxes) {

            obj.totalPackages += Number(boxes)

            currentRowTotalWeight += (Number(length) * Number(width) * Number(height) * Number(boxes)) / dimensionTypeVolumetricFactor;

            if (dimentionType === 1) {
                obj.CourierVolumeWeight += (Number(length) * Number(width) * Number(height) * Number(boxes)) / 5000;
                obj.CBM += (Number(length) * Number(width) * Number(height) * Number(boxes)) / 1000000;
            }
            else if (dimentionType === 2) {
                obj.CourierVolumeWeight = 0;
                obj.CBM += ((Number(length) * 2.54) * (Number(width) * 2.54) * (Number(height) * 2.54) * Number(boxes)) / (1000000);
            }
            else {
                obj.CourierVolumeWeight = 0;
                obj.CBM = 0;
            }
        }

        res['airVolumeWeight'] = ConvertValue(currentRowTotalWeight);
        obj.totalWeight += currentRowTotalWeight

    });

    return obj;
};

import React, { useEffect, useState } from 'react';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Dropdown, { DateField, InputField, InputFileField } from '../../component/controls';
import { ConditionofEntry, ConditionofGoodsType } from '../../../services/modalData';
import DivisonDrawer from '../../component/divisionDrawer';
import Box from '@mui/material/Box';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { GetAiportStatusData, PostAirportchecklist, UpdateJobStatusByAirport } from '../../../services/APIConstant/warehouseConstant';
import { useDispatch } from 'react-redux';





export const AirportCheckListDrawer = ({ row }) => {


  const dispatch = useDispatch();
  const [closeValidator, setcloseValidator] = useState(false)
  const [activeStep, setActiveStep] = React.useState(15);
  const [goodcondition, setGoodcondition] = React.useState(1);

  const [airportcheck, setAirportcheck] = useState(
    {
      goodsReceived: '',
      appraisalAC: '',
      appraisalDC: '',
      noc: "",
      isGrossWeight: '',
      isAirVolumeWeight: '',
      grossWeight: '',
      airVolumeWeight: '',
      dimension: '',
      dimensionStatus: '',
      tdEntryStatus: '',
      customGoodsEntry: '',
      exam: '',
      examPackageNumber: '',
      inspect: '',
      inspectGoodsCondition: '',
      inspectPackageNumber: '',
      inspectReason: '',
      report: '',
      leo: '',
      xRay: '',
      location: '',
      handOverTime: '',
      actualHandOverTime: '',

    }
  );

  const drewarclosed = () => {
    setcloseValidator(true)
  }

  const ConvertValueIntoBoolean = (value) => {
    if (value === 1) {
      return true
    }
    else if (value === 2) {
      return false
    }
    else {
      return null
    }
  }


  const ConvertValueIntoInt = (value) => {
    if (value === true) {
      return 1
    }
    else if (value === false) {
      return 2
    }
    else {
      return null
    }
  }

  const Get_AirportStatusByJobId = () => {
    GetCaller({
      API: GetAiportStatusData,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: { id: row?.permanentJobId },
      callback: ({ response }) => {
        const data = response?.data?.data
        setAirportcheck({
          ...airportcheck,
          ...data,
          ['isGrossWeight']: ConvertValueIntoInt(data.isGrossWeight),
          ['isAirVolumeWeight']: ConvertValueIntoInt(data.isAirVolumeWeight),
          ['dimensionStatus']: ConvertValueIntoInt(data.dimensionStatus),
          ['exam']: ConvertValueIntoInt(data.exam),
          ['inspect']: ConvertValueIntoInt(data.inspect),

        })
      }
    })
  }




  const drawerOpenCallbackHandler = () => {
    Get_AirportStatusByJobId()
  }

  const steps = [
    {
      label: 'Appr(A)',
      id: "appraisalAC",
      value: airportcheck['appraisalAC'],

    },
    {
      label: 'Appr(D)',
      id: "appraisalDC",
      value: airportcheck['appraisalDC'],
    },
    {
      label: 'NOC',
      id: "noc",
      value: airportcheck['noc'],
    },
    {
      label: 'Gross Weight',
      id: "isGrossWeight",
      value: airportcheck['isGrossWeight'],
      hasRadio: true,
      hasBody: true
    },
    {
      label: 'Volume Weight',
      id: "isAirVolumeWeight",
      value: airportcheck['isAirVolumeWeight'],
      hasRadio: true,
      hasBody: true
    },
    {
      label: 'Dimension',
      id: "dimensionStatus",
      value: airportcheck['dimensionStatus'],
      hasRadio: true,
      hasBody: true
    },
    {
      label: 'TD Entry',
      id: "tdEntryStatus",
      value: airportcheck['tdEntryStatus'],
    },
    {
      label: 'Custom Goods Entry',
      id: "customGoodsEntry",
      value: airportcheck['customGoodsEntry'],
    },
    {
      label: 'Exam',
      id: "exam",
      value: airportcheck['exam'],
      hasRadio: true,
      hasBody: true
    },
    {
      label: 'Inspect',
      id: "inspect",
      value: airportcheck['inspect'],
      hasRadio: true,
      hasBody: true
    },
    {
      label: 'Report',
      id: "report",
      value: airportcheck['report'],
    },
    {
      label: 'L.E.O',
      id: "leo",
      value: airportcheck['leo'],
    },
    {
      label: 'X-Ray',
      id: "xRay",
      value: airportcheck['xRay'],
    },
    {
      label: 'Location',
      id: "location",
      value: airportcheck['location'],
    },
    {
      label: 'Handover Time (H/O)',
      id: "handOverTime",
      value: airportcheck['handOverTime'],
      hasInput: true,
    },
    {
      label: 'Actual Handover Time',
      id: "actualHandOverTime",
      value: airportcheck['actualHandOverTime'],
      hasInput: true,
    },
  ];



  const requestdata = (() => {

    const data = {

      jobNumber: row.permanentJobId,

      appraisalAC: airportcheck.appraisalAC,
      appraisalDC: airportcheck.appraisalDC,

      noc: airportcheck.noc,

      isGrossWeight: ConvertValueIntoBoolean(airportcheck.isGrossWeight),
      grossWeight: airportcheck.grossWeight,

      isAirVolumeWeight: ConvertValueIntoBoolean(airportcheck.isAirVolumeWeight),
      airVolumeWeight: airportcheck.airVolumeWeight,

      dimension: airportcheck.dimension,
      dimensionStatus: ConvertValueIntoBoolean(airportcheck.dimensionStatus),

      tdEntryStatus: airportcheck.tdEntryStatus,

      customGoodsEntry: airportcheck.customGoodsEntry,

      exam: ConvertValueIntoBoolean(airportcheck.exam),
      examPackageNumber: airportcheck.examPackageNumber,

      inspect: ConvertValueIntoBoolean(airportcheck.inspect),
      inspectGoodsCondition: airportcheck.inspectGoodsCondition,
      inspectPackageNumber: airportcheck.inspectPackageNumber,
      inspectReason: airportcheck.inspectReason,

      report: airportcheck.report,

      leo: airportcheck.leo,

      xRay: airportcheck.xRay,

      location: airportcheck.location,

      handOverTime: airportcheck.handOverTime,

      actualHandOverTime: airportcheck.actualHandOverTime



    }



    return data;

  })()





  const handleInputChange = (value, field) => {
    setAirportcheck({
      ...airportcheck,
      [field.id]: value
    })
  }

  const handleFormSubmit = () => {
    PostCaller({
      API: UpdateJobStatusByAirport,
      callback: () => {
        // handleClose();
        // callback()
      },
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      contenttype: true,
      item: requestdata
    })
  };






  const drawerFormFields = {
    title: "Airport Checklist",
    icon: <i className="las la-list-alt"></i>,
    tooltitle: "Airport Checklist",
    iconclass: 'noClass',
    defaultbtnfalse: true,
    width: 430
  }





  const divisionDrawerody = () => {

    return (
      <Box sx={{ maxWidth: 400 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label} expanded>
              <StepLabel>
                <div className='airport_clecklist_stepper'>
                  <span className='airport_checklist_label'>{step.label}</span>
                  {step.hasRadio ? (
                    <RadioGroup
                      row
                      sx={{ flexWrap: "nowrap", marginLeft: 0 }}
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={step.value}
                      onChange={(e) => {
                        handleInputChange(Number(e.target.value), { id: step.id })
                      }}
                    >
                      <FormControlLabel
                        sx={{ marginLeft: 0 }}
                        value={1}
                        control={<Radio color="primary"

                        />}
                        label="Yes"
                      />
                      <FormControlLabel
                        sx={{ marginLeft: 0 }}
                        value={2}
                        control={<Radio color="primary"

                        />}
                        label="No"
                      />
                    </RadioGroup>
                  ) : step.hasInput ? (
                    <div style={{ width: "200px" }}>
                      <DateField
                        className='mt-0'
                        field={
                          {
                            id: step.id,
                            label: step.label,
                            type: "date",
                            value: step.value,
                            required: false,
                            error: step?.error,
                            onChange: handleInputChange
                          }
                        }
                      />
                    </div>

                  ) : (
                    <Checkbox
                      checked={step.value}
                      onChange={(e) => {
                        handleInputChange(e.target.checked, { id: step.id });
                      }}
                    />
                  )}

                </div>
              </StepLabel>

              <StepContent >
                <Box sx={{ mb: 2 }}>


                  {
                    step.hasBody ?
                      <>
                        {(step.id === 'isGrossWeight' && airportcheck.isGrossWeight === 2) ?
                          <InputField
                            field={
                              {
                                id: 'grossWeight',
                                label: "Enter Gross Weight",
                                type: "number",
                                value: airportcheck.grossWeight,
                                required: true,
                                error: airportcheck.grossWeighterror,
                                onChange: handleInputChange
                              }
                            }
                          />

                          : (step.id === 'isAirVolumeWeight' && airportcheck.isAirVolumeWeight === 2) ?
                            <InputField
                              field={
                                {
                                  id: 'airVolumeWeight',
                                  label: "Enter Volume Weight",
                                  type: "number",
                                  value: airportcheck.airVolumeWeight,
                                  required: true,
                                  error: airportcheck.airVolumeWeighterror,
                                  onChange: handleInputChange
                                }
                              }
                            />

                            : (step.id === 'dimensionStatus' && airportcheck.dimensionStatus === 2) ?
                              <div>

                              </div>

                              : (step.id === 'exam' && airportcheck.exam === 1) ?
                                <InputField
                                  field={
                                    {
                                      id: 'examPackageNumber',
                                      label: "Enter Pkgs. No.",
                                      type: "number",
                                      value: airportcheck.examPackageNumber,
                                      required: false,
                                      error: airportcheck.examPackageNumbererror,
                                      onChange: handleInputChange
                                    }
                                  }
                                />

                                : (step.id === 'inspect' && airportcheck.inspect === 1) ?
                                  <div>
                                    <Dropdown
                                      field={
                                        {
                                          id: 'inspectGoodsCondition',
                                          label: 'Goods Conditions',
                                          type: 'select',
                                          value: airportcheck.inspectGoodsCondition,
                                          required: true,
                                          error: airportcheck.inspectGoodsConditionerror,
                                          item: ConditionofEntry,
                                          disable: false,
                                          onChange: handleInputChange
                                        }
                                      }
                                    />
                                    {
                                      airportcheck.inspectGoodsCondition &&
                                      <InputField
                                        field={
                                          {
                                            id: 'inspectPackageNumber',
                                            label: "Enter Pkgs. No.",
                                            type: "number",
                                            value: airportcheck.inspectPackageNumber,
                                            required: false,
                                            error: airportcheck.inspectPackageNumbererror,
                                            onChange: handleInputChange
                                          }
                                        }
                                      />
                                    }

                                    {
                                      airportcheck.inspectGoodsCondition === 2 &&
                                      <InputField
                                        field={
                                          {
                                            id: 'inspectReason',
                                            label: "Reason",
                                            type: "text",
                                            value: airportcheck.inspectReason,
                                            required: false,
                                            error: airportcheck.inspectReasonerror,
                                            onChange: handleInputChange
                                          }
                                        }
                                      />
                                    }
                                  </div>


                                  : ""
                        }
                      </>
                      : <></>
                  }
                </Box>
              </StepContent>

            </Step>
          ))}
        </Stepper>

        <Button sx={{ m: 2, borderRadius: "10px" }} variant="outlined" onClick={drewarclosed}>Cancel</Button>
        <Button sx={{ m: 2, borderRadius: "10px" }} type='submit' onClick={handleFormSubmit} variant="contained">Submit</Button>

      </Box>
    );
  }


  return <>
    <DivisonDrawer
      drawerFormFields={drawerFormFields}
      closeValidator={closeValidator}
      openCallBack={drawerOpenCallbackHandler}
      divisiondrawerbody={divisionDrawerody}
    />
  </>
}
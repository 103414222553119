import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetGSAMasterByPage, GetMasterSectorByPage, PostMasterSector, UpdateMasterSector, UpdateGSAMasterStatus } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { creategsamaster, createtdsmaster } from '../../../navigation/constant';

import { useDispatch } from 'react-redux';
import { SmallSwitch } from '../../component/controls';
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};

const GsaMaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sectorMasterData, setSectorMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetGSAMasterByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            legalName: res.legalName,
            contactPerson: res.contactPerson,
            mobileNumber: res.mobileNumber,
            email: res.email,
            gstNumber: res.gstNumber,
            status: res.status,
            name: res.legalName,
            companytypeId: res.companytypeId,
            nameOnCheck: res.nameOnCheck,
            isPanAvailable: res.isPanAvailable,
            isTDSApplicable: res.isTDSApplicable,
            accountLedgerId: res.accountLedgerId,
            accountLedgerName: res.accountLedger.value,
            countryId: res.countryId,
            stateId: res.stateId,
            cityId: res.cityId,
            address1: res.address1,
            address2: res.address2,
            address3: res.address2,
            address4: res.address4,
            pinCode: res.pinCode,
            mobileNumber: res.mobileNumber,
            panNumber: res.panNumber,
            tanNumber: res.tanNumber,
            cin: res.cin,
            gstStatus: res.gstStatus,
            tdsId: res.tdsId,
            telephone: res.telephone,
            code: res.code,
            otherBizinstitutetype: res.otherBizinstitutetype
        }));

        setSectorMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },


        {
            id: 'legalName',
            label: 'Legal Name',
            numeric: false,
        },
        {
            id: 'accountLedgerName',
            label: 'A/c Ledger Name',
            numeric: false,
        },
        {
            id: 'contactPerson',
            label: 'Contact Person',
            numeric: false,
        },
        {
            id: 'mobileNumber',
            label: 'Mobile No.',
            numeric: false,
        },

        {
            id: 'email',
            label: 'Email',
            numeric: false,
        },
        {
            id: 'gstNumber',
            label: 'GstNo',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const updatedStatus = !row?.status;
            const data = {
                id: row?.id,
                status: updatedStatus
            }
            axios.get(UpdateGSAMasterStatus(data), { headers })
                .then((response) => {
                    fatch_MasterDataBYPagination()
                })
        }
        const editBtnClicked = () => {
            sessionStorage.setItem('gsa', JSON.stringify(row));
            sessionStorage.setItem('isPan', "Yes");
            sessionStorage.setItem('isTds', "Yes");
            navigate(creategsamaster);
        }
        return <>
            <Tooltip title="Edit GSA ">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >

           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>

    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <Tooltip title="Add GSA">
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${creategsamaster}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }
    return (
        <>
            {/* filterItems={filterItems} */}
            <MultiTable
                title={'GSA List'}
                columns={columns}
                data={sectorMasterData}
                // drawerFormFields={drawerFormFields}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default GsaMaster;

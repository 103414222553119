import { InputField } from "../component/controls";
import { applicableFormat } from "../../services/modalData";
import { useState } from "react";
import Dropdown from '../component/controls';
import { notifymaster } from "../../navigation/constant";
import { Tooltip, Button } from "@mui/material";

import {
  GetMasterCityByPage,
  GetMasterCountryByPage,
  GetMasterStateByPage,
  PostNotifyMaster,
  UpdateNotifyMaster,
  GetNotifyMasterById,
  GetAllEmployee,
  PostMasterCity,
  GetCustomerByPage
} from "../../services/constant";
import { useSelector } from "react-redux";
import { PostCaller } from "../../services/apiServices";
import GetCaller from "../../services/apiServices";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CheckMail from "../../services/utilities/checkEmailFormat";
const authToken = localStorage.getItem("authToken");
const headers = {
  Authorization: `Bearer ${authToken}`, // Include the Bearer token in vendorFormthe Authorization header
};

export const CreateNotifyForm = () => { 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
    const [salesItem, setSalesItem] = useState([]);
    const [cityItem, setCityItem] = useState([]);
  const [employeeItem,setEmployeeItem] = useState([]);
  const branchId = useSelector(state => state.enquirybranch)

  const [notifyForm, setNotifyForm] = useState({
    id: 0, 
    branchId: "",
    name: "",    
    status: "",      
    cityId: "",
    addressLabel: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    pinCode: "",
    mobileNumber: "",
    telephone: "",
    fax: "",
    email: "",
    contactPerson: "",
    gstNumber: "",
    pan: "",
    iec: "",
    employeeId: "",
    remarks: ""
  });

    const set_salesPerson = () => {
        debugger;
        GetCaller({
            API: GetCustomerByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, paylode: e.paylode })
            },
            item: {},
            callback: ({ response }) => {
                const newSalesperson = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))

                setSalesItem(newSalesperson)
            }
        })
    }
 
    const set_CityItemData = () => {
        GetCaller({
            API: PostMasterCity,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setCityItem(newCityItemData)
            }

        })
    }


  const getNotifyData = (id) => {
    debugger
    const notifyObj = {
        API: GetNotifyMasterById,
        dispatch: (e) => {
            dispatch({ type: e.type, payload: e.payload })
        },
        item: { id: id },
        callback: ({ response }) => {
            let data = response?.data?.data

            data.mobileNumber = data.mobileNumber;
            data.telephone = data.telephone;
            data.fax = data.fax;
            data.email = data.email;
            data.contactPerson = data.contactPerson;
            data.gstNumber = data.gstNumber;
            data.pan = data.pan;
            data.iec = data.iec;
            data.id = data.id;
            data.branchId = data.branchId;
            data.name = data.name;
            data.status = data.status;
            data.countryId = data.countryId;
            data.stateId = data.stateId;
            data.cityId = data.cityId;
            data.address1 = data.address1;
            data.addressLabel = data.addressLabel;
            data.address2 = data.address2;
            data.address3 = data.address3;
            data.pinCode = data.pinCode;
            data.employeeId = data.employeeId;
            data.remarks = data.remarks;
            setNotifyForm(data)
          
            set_CityItemData();
          
        }

    }
    GetCaller(notifyObj);
  }

    useEffect(() => {
        set_salesPerson();
      GetCaller({

        API: GetAllEmployee,
        dispatch: (e) => {
            dispatch({ type: e.type, payload: e.payload })
        },
        item: {},
        callback: ({ response }) => {

            console.log('Sales Person', response?.data?.data)
            const data = response?.data?.data?.records

            const employeelist = data?.map(res => ({
                value: res.id,
                name: res.name
            }))
            debugger
            setEmployeeItem(employeelist)

        }

    });

    if (sessionStorage.notify) {
        debugger
      let notify = sessionStorage.getItem("notify");
      getNotifyData(notify);
      setIsEdit(true);

      sessionStorage.removeItem('notify');
      
    } else {
      set_CityItemData();
    }
  }, []);



   
    const handleFormSubmit = () => {
        debugger
        const changes = handleSubmit(requestdata);

        const isMail = CheckMail(requestdata?.email)
        debugger
        if (Object.keys(changes).length > 0 || isMail) {
            setNotifyForm({ ...notifyForm, ...changes,
            ["emailerror"]: isMail
            });
        }
        else if (isEdit) {

            EditNotifyMasterSubmit(requestdata);

        }
        else {
            AddNotifyMasterSubmit(requestdata)
        }
    };

    const handleSubmit = (obj) => {
        debugger
        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (key === 'id' || key === 'status' || key === "branchId" || key === "address2" || key === "address3"  ) {
            }
            else if ((key !== 'id' ||
                key !== 'status' ||
                key !== 'branchId' ||
                key !== 'address2' ||
                key !== 'address3' 

            ) && !value) {
                changes[key] = value;
                changes[key + 'error'] = !value;
            }
        });
        console.log("changes", changes)
        return changes;
    };
   





    //const handleFormSubmit = () => {

    //    const hasUndefinedValue = (obj) => {
    //        let changes = {};
    //        debugger;
    //        Object.entries(obj).forEach(([key, value]) => {
    //            debugger
    //            if ((key == 'id' ||
    //                key == 'status' ||
    //                key == 'address2' ||
    //                key == 'address3' ||
    //                key == 'countryId' ||
    //                key == 'stateId' ||
    //                key === 'nameOnCheck' ||
    //                key === 'parentCompanyId' ||
    //                (vendorForm.isPanAvailable !== 1 && key === 'panNumber') ||
    //                (vendorForm.isTDSApplicable !== 1 && key === 'tdsId') ||
    //                (bizInstituteItem[vendorForm.bizInstituteTypeId - 1]?.name.toLowerCase() !== 'other' && key === 'otherBizInstituteType') ||
    //                (vendorForm.gstStatus === 2 && key === 'gstNumber')

    //            )) {

    //            }
    //            else if (key === 'vendorTypeIds' && value.length === 0) {
    //                changes[key + 'error'] = true;
    //            }
    //            else if ((!value && typeof value !== 'boolean')) {
    //                changes[key + 'error'] = true;
    //            }
    //        });

    //        return changes;
    //    };

    //    const changes = hasUndefinedValue(requestdata.vendor);


    //    if (Object.keys(changes).length > 0) {
    //        debugger;
    //        setVendorForm({ ...vendorForm, ...changes });
    //    }
    //    else if (isEdit) {
    //        EditVenderSubmit(requestdata);
    //    }
    //    else {
    //        AddVendorSubmit(requestdata);

    //    }

    //};



  const handleInputChange = (value, field) => {

    setNotifyForm({
      ...notifyForm,
      [field.id]: value,
      [field.id + 'error']: !value,
    });
  };

  const requestdata = {
    id: notifyForm.id, 
    branchId: notifyForm.branchId,
    name: notifyForm.name,    
    status: notifyForm.status,      
    cityId: notifyForm.cityId,
    address1: notifyForm.address1,
    addressLabel: notifyForm.addressLabel,
    address2: notifyForm.address2,
    address3: notifyForm.address3,
    pinCode: notifyForm.pinCode,
    mobileNumber: notifyForm.mobileNumber,
    telephone: notifyForm.telephone,
    fax: notifyForm.fax,
    email: notifyForm.email,
    contactPerson: notifyForm.contactPerson,
    gstNumber: notifyForm.gstNumber,
    pan: notifyForm.pan,
    iec: notifyForm.iec,
    employeeId: notifyForm.employeeId,
    remarks: notifyForm.remarks
  };



  const EditNotifyMasterSubmit = (requestdata) => {
    requestdata.branchId = branchId;
    requestdata.employeeId = 1;
    PostCaller({
        API: UpdateNotifyMaster,
        callback: () => {
            navigate(`${notifymaster}`)
        },
        dispatch: (e) => {
            dispatch({ type: e.type, payload: e.payload })
        },
        item: requestdata
    })
  }
  const AddNotifyMasterSubmit = (requestdata) => {
    debugger
    requestdata.branchId = branchId;
    requestdata.employeeId = 1;
    PostCaller({
        API: PostNotifyMaster,
        callback: () => {
            navigate(`${notifymaster}`)
        },
        dispatch: (e) => {
            dispatch({ type: e.type, payload: e.payload })
        },
        item: requestdata
    })
  };

  return (
    <>
      <div className="hr-form-box">
        {isEdit ? <h6>Update Notify</h6> : <h6>Create Notify</h6>}
        <div className="row">
            <div className="col-md-3">
                <InputField
                    field={
                        {
                            id: 'name',
                            label: 'Name',
                            type: 'text',
                            value: notifyForm.name,
                            required: true,
                            error: notifyForm.nameerror,
                            onChange: handleInputChange
                        }
                    }
                />
            </div>
            <div className="col-md-3">
                <InputField
                    field={
                        {
                        id: 'email',
                        label: 'Email',
                        type: "text",
                        value: notifyForm.email,
                        required: true,
                        error: notifyForm.emailerror,
                        onChange: handleInputChange
                        }
                    }
                />
            </div>
          
          <div className="col-md-3">
                <InputField
                field={
                    {
                    id: 'addressLabel',
                    label: 'Address Label',
                    type: "text",
                    value: notifyForm.addressLabel,
                    required: true,
                    error: notifyForm.addressLabelerror,
                    onChange: handleInputChange
                    }
                }
            />
          </div>
       
          <div className="col-md-3">
            <Dropdown
              field={
                {
                  id: "cityId",
                  label: 'City ',
                  type: "select",
                  value: notifyForm.cityId,
                  required: true,
                  error: notifyForm.cityIderror,
                  item: cityItem,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'address1',
                  label: 'Address1',
                  type: "text",
                  value: notifyForm.address1,
                  required: true,
                  error: notifyForm.address1error,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'address2',
                  label: 'Address 2',
                  type: "text",
                  value: notifyForm.address2,
                  required: false,
                  error: notifyForm.address2error,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'address3',
                  label: 'Address 3',
                  type: "text",
                  value: notifyForm.address3,
                  required: false,
                  error: notifyForm.address3error,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'pinCode',
                  label: 'ZIP/Post Code',
                  type: "text",
                  value: notifyForm.pinCode,
                  required: true,
                  error: notifyForm.pinCodeerror,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'mobileNumber',
                  label: 'Mobile No',
                  type: "number",
                  value: notifyForm.mobileNumber,
                  required: true,
                  error: notifyForm.mobileNumbererror,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'telephone',
                  label: 'Telephone No',
                  type: "number",
                  value: notifyForm.telephone,
                  required: true,
                  error: notifyForm.telephoneerror,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'contactPerson',
                  label: 'Contact Person',
                  type: "text",
                  value: notifyForm.contactPerson,
                  required: true,
                  error: notifyForm.contactPersonerror,
                  onChange: handleInputChange

                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'fax',
                  label: 'FAX',
                  type: "text",
                  value: notifyForm.fax,
                  required: true,
                  error: notifyForm.faxerror,
                  onChange: handleInputChange

                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'iec',
                  label: 'IEC',
                  type: "text",
                  value: notifyForm.iec,
                  required: true,
                  error: notifyForm.iecerror,
                  onChange: handleInputChange

                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
                field={
                    {
                    id: 'pan',
                    label: 'Pan Number',
                    type: 'text',
                    value: notifyForm.pan,
                    required: true,
                    error: notifyForm.panerror,
                    onChange: handleInputChange
                    }
                }
            />
          </div>
          <div className="col-md-3">
            <InputField
                field={
                    {
                    id: 'gstNumber',
                    label: 'GST No',
                    type: 'text',
                    value: notifyForm.gstNumber,
                    required: true,
                    error: notifyForm.gstNumbererror,
                    onChange: handleInputChange
                    }
                }
            />
          </div>
          
          <div className="col-md-3">
            <Dropdown
              field={
                {
                  id: "employeeId",
                  label: 'Sales Person',
                  type: "select",
                  value: notifyForm.employeeId,
                  required: true,
                  error: notifyForm.employeeIderror,
                  item: employeeItem,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
                field={
                    {
                    id: 'remarks',
                    label: 'Remarks',
                    type: 'text',
                    value: notifyForm.remarks,
                    required: true,
                    error: notifyForm.remarkserror,
                    onChange: handleInputChange
                    }
                }
            />
          </div>
                    
        </div>
      </div>

      <div className="cmd">
        <div></div>
        <Button
          sx={{ m: 2, borderRadius: "10px" }}
          onClick={handleFormSubmit}
          variant="contained"
        >
          Submit
        </Button>
      </div>
    </>
  );
};


import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../navigation/constant";

const GetCaller = ({ API, callback, item, dispatch, enquirybranch }) => {

  const controller = new AbortController()

  const authToken = localStorage.getItem('authToken')
  const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
  };

  const query = item ? item : { searchKey: "", sortKey: "", sortDirection: "", itemsPerPage: 10, page: 1, };



  ; (async () => {

    try {

      dispatch({ type: "LOADER", payload: true });

      const response = await axios.get(API(query, enquirybranch), { headers, signal: controller.signal })

      callback({ response });

    } catch (error) {

      if (axios.isCancel(error)) {
        console.log('Request Canceled', error.message)
        return
      }

      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
          console.error("Unauthorized: Redirect to login page or refresh token.");
        } else {
          // Handle other error responses
          callback({ error: error?.response?.data });
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from the server.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up the request:", error.message);
      }

      // alert(API());

    } finally {

      dispatch({ type: "LOADER", payload: false });

    }





  })()



};
export default GetCaller;

export const PostCaller = ({ API, callback, item, dispatch, contenttype, type }) => {
  const authToken = localStorage.getItem('authToken')

  dispatch({ type: "LOADER", payload: true });
  const headers = {
    'Content-Type': contenttype ? 'multipart/form-data' : "application/json",
    "Authorization": `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
    // "FinancialYear": financialYear,
    // "Branch": `${enquirybranch}`
  };

  axios
    .post(API(), item && item, { headers })
    .then((response) => {

      dispatch({ type: "LOADER", payload: false });

      const { friendlyMessage, resultMessage } = response?.data?.metaData || {};

      if (response?.data?.metaData?.resultCode === "R00") {
        callback({ response });

        dispatch({
          type: "SNACKBARALERT", payload: {
            active: true,
            message: friendlyMessage || resultMessage,
            type: 'success'
          }
        });
      }
      else {
        dispatch({
          type: "SNACKBARALERT", payload: {
            active: true,
            message: friendlyMessage || resultMessage,
            type: 'error'
          }
        });
      }

    })
    .catch((error) => {
      dispatch({ type: "LOADER", payload: false });
      // callback({ error });
    });
};


import React, { useEffect, useState } from 'react'
import { GetChargeHeadByPage, GetUnitMasterByPage, PostMasterCountry } from '../../../../services/constant';
import GetCaller from '../../../../services/apiServices';
import Dropdown, { DateField, InputField } from '../../../component/controls';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { frightpp } from '../../../../services/modalData';

const FinalBuying = () => {
    const [countrycurrency, setCountrycurrency] = useState([]);
    const [chargeHeadItem, setChargeHeadItem] = useState([]);
    const [unitItem, setUnitItem] = useState([]);
    const [otherChargesDetailsError, setOtherChargesDetailsError] = useState({})

    useEffect(() => {

        // country Currency 
        GetCaller({
            API: PostMasterCountry,
            dispatch: (e) => {
                // dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.currencyCode
                }))
                setCountrycurrency(newCountryItemData)
            }
        });

        // Charge head Agent Items
        GetCaller({
            API: GetChargeHeadByPage,
            dispatch: (e) => {
                // dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const chargehead = data?.map(res => ({
                    value: res.id,
                    name: res.chargeName
                }))
                setChargeHeadItem(chargehead)
            }
        })

        // Unit Items
        GetCaller({
            API: GetUnitMasterByPage,
            dispatch: (e) => {
                // dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const unit = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setUnitItem(unit)
            }
        })


    }, [])


    const [formData, setFormData] = useState({})

    const handleBasicInputChange = (value, field) => {
        setFormData({
            ...formData,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    }





    const filterCountryItems = (one, two) => {
        let updatedCountryCurrency = countrycurrency?.filter(item => item.value !== one && item.value !== two);
        return updatedCountryCurrency;
    }

    return (
        <>
            <div className='hr-form-box'>

                <div className="row">
                    <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "Currency1",
                                    label: "Currency 1",
                                    value: formData.Currency1,
                                    required: true,
                                    error: formData.Currency1error,
                                    item: filterCountryItems(formData.Currency2, formData.Currency3),
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-2">
                        <InputField
                            field={
                                {
                                    id: "ExchangeRate1",
                                    label: "Exchange Rate",
                                    type: "number",
                                    value: formData.ExchangeRate1,
                                    required: true,
                                    error: formData.ExchangeRate1error,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "Currency2",
                                    label: "Currency 2",
                                    value: formData.Currency2,
                                    required: false,
                                    error: formData.Currency2error,
                                    item: filterCountryItems(formData.Currency1, formData.Currency3),
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-2">
                        <InputField
                            field={
                                {
                                    id: "ExchangeRate2",
                                    label: "Exchange Rate",
                                    type: "number",
                                    value: formData.ExchangeRate2,
                                    required: false,
                                    error: formData.ExchangeRate2error,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "Currency3",
                                    label: "Currency 3",
                                    value: formData.Currency3,
                                    required: false,
                                    error: formData.Currency3error,
                                    item: filterCountryItems(formData.Currency2, formData.Currency1),
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-2">
                        <InputField
                            field={
                                {
                                    id: "ExchangeRate3",
                                    label: "Exchange Rate",
                                    type: "number",
                                    value: formData.ExchangeRate3,
                                    required: false,
                                    error: formData.ExchangeRate3error,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                </div>

            </div>
            <div className='hr-form-box'>
                {FinalCharges({
                    handleBasicInputChange,
                    chargeHeadItem,
                    unitItem,
                    countrycurrency,
                    otherChargesDetailsError
                })}

                <div className="row">

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: "totalCGST",
                                    label: "Total CGST",
                                    type: "number",
                                    value: formData.totalCGST,
                                    disable: true,
                                    required: false,
                                    error: formData.totalCGSTerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: "totalSGST",
                                    label: "Total SGST",
                                    type: "number",
                                    value: formData.totalSGST,
                                    disable: true,
                                    required: false,
                                    error: formData.totalSGSTerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: "totalIGST",
                                    label: "Total IGST",
                                    type: "number",
                                    value: formData.totalIGST,
                                    disable: true,
                                    required: false,
                                    error: formData.totalIGSTerror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: "totalAmount",
                                    label: "Total Amount",
                                    type: "number",
                                    value: formData.totalAmount,
                                    required: false,
                                    disable: true,
                                    error: formData.totalAmounterror,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                </div>

            </div>
        </>


    )
}

export default FinalBuying


const FinalCharges = ({
    handleBasicInputChange,
    chargeHeadItem,
    otherChargesDetailsError,
    unitItem,
    countrycurrency
}) => {



    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            chargeHeadId: '',
            vendorId: '',
            currencyId: '',
            exchangeRate: '',
            selectForSelling: false,
            rate: '',
            applicableOn: '',
            unitId: '',
            qtyChargeWeight: '',
            amount: ''

        },
    ]);



    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            chargeHeadId: '',
            vendorId: '',
            currencyId: '',
            exchangeRate: '',
            selectForSelling: false,
            rate: '',
            applicableOn: '',
            unitId: '',
            qtyChargeWeight: '',
            amount: ''
        }]);
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

        if (fieldToUpdate) {
            fieldToUpdate[field] = value;
            // OtherChargeshasUndefinedValueIndex(value, field, index)


            if (fieldToUpdate['applicableOn'] === 2) {
                fieldToUpdate['amount'] = Number(fieldToUpdate.rate) * Number(fieldToUpdate.qtyChargeWeight)
            } else {
                fieldToUpdate['amount'] = Number(fieldToUpdate.rate)
            }


            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res.id || null,
                        // aeFinalQuoteBuyingChargeId: exportbuyingdata?.id,
                        chargeHeadId: res.chargeHeadId,
                        vendorId: res.vendorId,
                        currencyId: res.currencyId,
                        exchangeRate: res.exchangeRate,
                        selectForSelling: res.selectForSelling,
                        rate: res.rate,
                        applicableOn: res.applicableOn,
                        unitId: res.unitId,
                        qtyChargeWeight: res.qtyChargeWeight,
                        amount: res.amount
                    }
                )

            })
            handleBasicInputChange(data, { id: 'OtherCharges' });
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    id: res.id || null,
                    // aeFinalQuoteBuyingChargeId: exportbuyingdata?.id,
                    chargeHeadId: res.chargeHeadId,
                    vendorId: res.vendorId,
                    currencyId: res.currencyId,
                    exchangeRate: res.exchangeRate,
                    selectForSelling: res.selectForSelling,
                    rate: res.rate,
                    applicableOn: res.applicableOn,
                    unitId: res.unitId,
                    qtyChargeWeight: res.qtyChargeWeight,
                    amount: res.amount
                }
            )

        })
        handleBasicInputChange(data, { id: 'OtherCharges' });
    };
    return <>
        <div className='mb-5'>
            {inputFields.map((field, key) => (
                <div className='d-flex' key={field.key}>

                    <div className="w-100">
                        {/* <div>
                            <input type='checkbox' className='mt-4 me-3'
                                checked={field.selectForSelling}
                                onChange={() => handleInputChange(field.key, 'selectForSelling', !field.selectForSelling, key)}
                            />
                        </div> */}
                        <div className="row mb-3">
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "chargeHeadId",
                                            label: "Charge Heads ",
                                            value: field.chargeHeadId,
                                            required: true,
                                            error: otherChargesDetailsError['chargeHeadId' + key],
                                            item: chargeHeadItem,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-1">
                                <InputField
                                    field={
                                        {
                                            id: "vendorId",
                                            label: "SAC",
                                            value: field.vendorId,
                                            required: false,
                                            disable: true,
                                            error: otherChargesDetailsError['vendorId' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-1">
                                <Dropdown
                                    field={
                                        {
                                            id: "currencyId",
                                            label: "PP/CC",
                                            value: field.currencyId,
                                            required: false,
                                            error: otherChargesDetailsError['currencyId' + key],
                                            item: frightpp,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-1">
                                <Dropdown
                                    field={
                                        {
                                            id: "currencyId",
                                            label: "DR/CR",
                                            value: field.currencyId,
                                            required: false,
                                            error: otherChargesDetailsError['currencyId' + key],
                                            item: [{ name: 'DR', value: 1 }, { name: 'CR', value: 2 }],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-1">
                                <InputField
                                    field={
                                        {
                                            id: "rate",
                                            label: "Rate",
                                            type: 'number',
                                            value: field.rate,
                                            required: false,
                                            error: otherChargesDetailsError['rate' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-1">
                                <InputField
                                    field={
                                        {
                                            id: "rate",
                                            label: "WT./Unit",
                                            type: 'number',
                                            value: field.rate,
                                            required: false,
                                            error: otherChargesDetailsError['rate' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "currencyId",
                                            label: "Currency",
                                            value: field.currencyId,
                                            required: true,
                                            error: otherChargesDetailsError['currencyId' + key],
                                            item: countrycurrency,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-1">
                                <InputField
                                    field={
                                        {
                                            id: "exchangeRate",
                                            label: "Ex. Rate",
                                            type: 'number',
                                            value: field.exchangeRate,
                                            required: true,
                                            error: otherChargesDetailsError['exchangeRate' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: "applicableOn",
                                            label: "INR. AMT",
                                            type: 'number',
                                            value: field.applicableOn,
                                            required: true,
                                            error: otherChargesDetailsError['applicableOn' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "unitId",
                                            label: "Payable To",
                                            value: field.unitId,
                                            required: true,
                                            error: otherChargesDetailsError['unitId' + key],
                                            item: [],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "unitId",
                                            label: "Party",
                                            value: field.unitId,
                                            required: true,
                                            error: otherChargesDetailsError['unitId' + key],
                                            item: [],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-1">
                                <InputField
                                    field={
                                        {
                                            id: "qtyChargeWeight",
                                            label: "CGST",
                                            type: 'number',
                                            value: field.qtyChargeWeight,
                                            required: true,
                                            disable: true,
                                            error: otherChargesDetailsError['qtyChargeWeight' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-1">
                                <InputField
                                    field={
                                        {
                                            id: "qtyChargeWeight",
                                            label: "SGST",
                                            type: 'number',
                                            value: field.qtyChargeWeight,
                                            required: true,
                                            disable: true,
                                            error: otherChargesDetailsError['qtyChargeWeight' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-1">
                                <InputField
                                    field={
                                        {
                                            id: "qtyChargeWeight",
                                            label: "IGST",
                                            type: 'number',
                                            value: field.qtyChargeWeight,
                                            required: true,
                                            disable: true,
                                            error: otherChargesDetailsError['qtyChargeWeight' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: "qtyChargeWeight",
                                            label: "Invoice No",
                                            type: 'number',
                                            value: field.qtyChargeWeight,
                                            required: true,
                                            disable: true,
                                            error: otherChargesDetailsError['qtyChargeWeight' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <DateField
                                    field={
                                        {
                                            id: "amount",
                                            label: "Invoice Date",
                                            type: 'number',
                                            value: field.amount,
                                            required: true,
                                            disable: true,
                                            error: otherChargesDetailsError['amount' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>



                        </div>
                    </div>

                    <div className="ms-2" style={{ justifyContent: "right", alignItems: "flex-start" }}>
                        {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                            <Add />
                        </Button> : (
                            <Button
                                sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                onClick={() => removeInputField(field.key)}
                                variant='contained'
                            >
                                <Close />
                            </Button>
                        )}
                    </div>
                </div>
            ))}
        </div>

    </>
}
import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import { GetMasterBranchByPage, GetMasterDivisionByPage, GetMasterSubGroupByPage, PostMasterBranch, PostMasterDivision, UpdateMasterDivision, UpdateMasterDivisionStatus } from '../../../services/constant';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';

const Division = () => {

    const dispatch = useDispatch()
    const [divisionMasterData, setDivisionMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)






    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterDivisionByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();


    }, []);

    const setTableDataHandler = ({ response }) => {
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            division: res.name,
            address: res.address,
            branch: res.branch.value,
            branchid: res.branch.key,
            status: res.status
        }));
        setDivisionMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
        {
            id: 'branch',
            label: 'Branch',
            numeric: false,
        },
        {
            id: 'division',
            label: 'Division',
            numeric: false,
        },
        {
            id: 'address',
            label: 'Address',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];


    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateMasterDivisionStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <DivisionDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <DivisionDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }



    return (
        <>
            <MultiTable
                data={divisionMasterData}
                title={'Division'}
                columns={columns}
                // filterItems={filterItems}
                //drawerFormFields={drawerFormFields}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}


            />
        </>
    )
}

export default Division



const DivisionDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()
    const [branchItem, setBranchItem] = useState([])
    const [closeValidator, setcloseValidator] = useState(false)

    const [divisionName, setDivisionName] = useState(row?.division || '')
    const [divisionNameError, setDivisionNameError] = useState(false)

    const [address, setAddress] = useState(row?.address || '')
    const [addressError, setAddressError] = useState(false)

    const [branchType, setBranchType] = useState(row?.branchid || '')
    const [branchTypeError, setBranchTypeError] = useState(false)


    const drawerOpenCallbackHandler = () => {
        setDivisionName(row?.division || '')
        setAddress(row?.address || '')
        setBranchType(row?.branchid || '')
        setDivisionNameError(false)
        setAddressError(false)
        setBranchTypeError(false)
    }


    const set_BranchItemData = () => {
        GetCaller({
            API: PostMasterBranch,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newBranchItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setBranchItem(newBranchItemData)
            }
        })

    }

    useEffect(() => {
        set_BranchItemData()
    }, []);
    useEffect(() => {
        setcloseValidator(false)

    }, [closeValidator]);

    const handleSubmit = () => {
        if (!address || !divisionName || !branchType) {

            setAddressError(!address)
            setDivisionNameError(!divisionName)
            setBranchTypeError(!branchType)

        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: divisionName,
                address: address,
                branchId: branchType,
                status: row.status   
            } : {
                name: divisionName,
                address: address,
                branchId: branchType
            }     
            editor ?
              PostCaller({
                API: UpdateMasterDivision,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })     
              :    
              PostCaller({
                API: PostMasterDivision,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })
            setcloseValidator(true)
      
          }

    }


    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'division':
                setDivisionName(value)
                setDivisionNameError(!value);
                break;
            case 'address':
                setAddress(value)
                setAddressError(!value);
                break;
            case 'branch':
                setBranchType(value)
                setBranchTypeError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Division",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Division',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Division",
            tooltitle: 'Add Division',
            defaultbtnfalse: true
        }





    const FieldData = [

        {
            id: 'division',
            label: 'Division Name',
            type: "text",
            value: divisionName,
            error: divisionNameError,
            required: true,
            helperText: 'Division Name required',
            onChange: handleInputChange,
        },
        {
            id: 'branch',
            label: 'Branch',
            type: "select",
            value: branchType,
            error: branchTypeError,
            required: true,
            helperText: "Branch required",
            item: branchItem,
            onChange: handleInputChange,
        },
        {
            id: 'address',
            label: 'Address',
            type: "text",
            value: address,
            error: addressError,
            required: true,
            helperText: "Address required",
            onChange: handleInputChange,

        },



    ];




    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}



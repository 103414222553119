import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import { GetMasterArea, GetMasterAreaByPage, PostMasterTaxRate, UpdateMasterTaxRate, GetMasterSectorByPage, GetMasterTaxRateByPage, PostMasterArea, UpdateMasterArea, UpdateMasterAreaStatus, UpdateMasterTaxRateStatus } from '../../../services/constant';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import { useNavigate } from 'react-router-dom';
import { createtaxmaster, taxratemaster } from '../../../navigation/constant';
import AddIcon from '@mui/icons-material/Add';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';

const TaxRateMaster = () => {
    const dispatch = useDispatch();
    const [masterareadata, setMasterareadata] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterTaxRateByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);

    const setTableDataHandler = ({ response }) => {
        const newMasterAreaData = response?.data?.data?.records?.map((res, index) => ({
            sn: 'd',
            id: res.id,
            name: res.name,
            cgst: res.cgst,
            sgst: res.sgst,
            igst: res.igst,
            status: res.status
        }));
        setMasterareadata(newMasterAreaData);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
        {
            id: 'name',
            label: 'Name',
            numeric: false,
        },
        {
            id: 'cgst',
            label: 'CGST( % )',
            numeric: false,
        },
        {
            id: 'sgst',
            label: 'SGST( % )',
            numeric: false,
        },
        {
            id: 'igst',
            label: 'IGST( % )',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateMasterTaxRateStatus,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({ response }) => {
                    fatch_MasterDataBYPagination();
                }
            });
        }
        return <>

            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           
            <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>

    }
    const TableTool = ({ }) => {

        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }

    return (
        <>

            <MultiTable
                data={masterareadata}
                columns={columns}
                // drawerFormFields={drawerFormFields}
                title={'Tax Rate '}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />


        </>
    )
}

export default TaxRateMaster;


const DesignationDrawer = ({ callAPI, editor, row }) => {
    const [closeValidator, setcloseValidator] = useState(false)
    const [networkName, setNetworkName] = useState(row?.networkname || '')
    const [networkNameError, setNetworkNameError] = useState(false)
    const [taxRateName, setTaxRateName] = useState(row?.taxRateName || '');
    const [taxRateNameError, setTaxRateNameError] = useState(false);
    const [cgst, setCGST] = useState(0);
    const [cgstError, setCGSTError] = useState(false);
    const [igst, setIGST] = useState(0);
    const [igstError, setIGSTError] = useState(false);
    const [sgst, setSGST] = useState(0);
    const [sgstError, setSGSTError] = useState(false);

    const dispatch = useDispatch()


    const handleSubmit = () => {
        if (!taxRateName || !cgst || !igst || !sgst) {
            setTaxRateNameError(!taxRateName)
            setCGSTError(!cgst)
            setIGSTError(!igst)
            setSGSTError(!sgst)
        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: taxRateName,
                branchId: 1,
                cgst: cgst,
                igst: igst,
                sgst: sgst,
                cgstReceivableLedgerId: 1,
                sgstReceivableLedgerId: 1,
                igstReceivableLedgerId: 1,
                cgstPayableLedgerId: 1,
                sgstPayableLedgerId: 1,
                igstPayableLedgerId: 1,
                status: row.status
            } : {
                name: taxRateName,
                igst: igst,
                sgst: sgst,
                cgst: cgst,
                cgstReceivableLedgerId: 0,
                sgstReceivableLedgerId: 0,
                igstReceivableLedgerId: 0,
                cgstPayableLedgerId: 0,
                sgstPayableLedgerId: 0,
                igstPayableLedgerId: 0,
                branchId: 1,
            }
            editor ?
                PostCaller({
                    API: UpdateMasterTaxRate,
                    callback: () => callAPI(),
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: postmasterareaData
                })

                :
                PostCaller({
                    API: PostMasterTaxRate,
                    callback: () => callAPI(),
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }
    const drawerOpenCallbackHandler = () => {
        setTaxRateName(row?.name)
        setCGST(row?.cgst);
        setIGST(row?.igst);
        setSGST(row?.sgst);
        setTaxRateNameError(false)
        setCGSTError(false)
        setIGSTError(false)
        setSGSTError(false)
    }
    useEffect(() => {
        setcloseValidator(false)
        // if (editor) {
        //     setTaxRateName(row?.name)
        //     setCGST(row?.cgst);
        //     setIGST(row.igst);
        //     setSGST(row?.sgst);
        // } else {
        //     setNetworkName('');
        //     setCGST();
        //     setIGST();
        //     setSGST();
        // }
    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'taxratename':
                setTaxRateName(value)
                setTaxRateNameError(!value);
                break;
            case 'igstId':
                setIGST(value);
                setIGSTError(!value);
                break;
            case 'cgstId':
                setCGST(value);
                setCGSTError(!value);
                break;
            case 'sgstId':
                setSGST(value);
                setSGSTError(!value);
                break;
            default:
                break;
        }
    };
    const drawerFormFields = editor ?
        {
            title: "Update Tax Rate",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Update Tax Rate',
            iconclass: 'noClass',
            defaultbtnfalse: true
        }
        : {
            title: "Add Tax Rate",
            tooltitle: 'Add Tax Rate',
            defaultbtnfalse: true
        }

    const FieldData = [
        {
            id: 'taxratename',
            label: 'Tax Rate Name',
            type: 'text',
            value: taxRateName,
            required: true,
            error: taxRateNameError,
            helperText: 'Tax Rate Name required',
            onChange: handleInputChange,

        },
        {
            id: 'cgstId',
            label: 'CGST%',
            type: 'number',
            value: cgst,
            max: 3,
            required: true,
            error: cgstError,
            helperText: 'CGST% required',
            onChange: handleInputChange,
        },
        {
            id: 'sgstId',
            label: 'SGST%',
            type: 'number',
            value: sgst,
            required: true,
            error: sgstError,
            helperText: 'SGST% required',
            maxLength: 3,
            onChange: handleInputChange,
        },
        {
            id: 'igstId',
            label: 'IGST%',
            type: 'number',
            value: igst,
            required: true,
            error: igstError,
            helperText: 'IGST% required',
            onChange: handleInputChange,
        },
    ];

    const AddAreaMasterSubmit = () => {
        const postmasterareaData = {
            name: taxRateName,
            igst: igst,
            sgst: sgst,
            cgst: cgst,
            cgstReceivableLedgerId: 0,
            sgstReceivableLedgerId: 0,
            igstReceivableLedgerId: 0,
            cgstPayableLedgerId: 0,
            sgstPayableLedgerId: 0,
            igstPayableLedgerId: 0,
            branchId: 1,
        }
        debugger
        axios.post(PostMasterTaxRate(), postmasterareaData)
            .then((response) => {
                const friendlymess = response?.data?.metaData?.friendlyMessage;
                const resultmess = response?.data?.metaData?.resultMessage

                if (response?.data?.metaData?.resultCode === "R00") {
                    callAPI()
                    dispatch({
                        type: "SNACKBARALERT", payload: {
                            active: true,
                            message: friendlymess || resultmess,
                            type: 'success'
                        }
                    });
                }

                else {
                    dispatch({
                        type: "SNACKBARALERT", payload: {
                            active: true,
                            message: friendlymess || resultmess,
                            type: 'error'
                        }
                    });
                }
            })



    }

    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody} />

    </>

}



import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GetBuyingChargesById, GetSellingChargesById, PostAirExportBuyingingCharges, PostAirExportSellingCharges, UpdateAirExportBuyingingCharges, UpdateAirExportFinalSellingingCharges, UpdateAirExportSellingingCharges } from "../../../../services/APIConstant/enquiryConstant";
import GetCaller, { PostCaller } from "../../../../services/apiServices";
import Dropdown, { InputField, InputFileField, TextAreafield, TimeField } from "../../../component/controls";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { IntialStockProvider } from "../../../../services/modalData";
import { Add, Close } from "@mui/icons-material";
import { Create_DueCarrier } from "./createDueCarrier";
import Transition from "../../../../services/utilities/transition";
import { airenquirylist } from "../../../../navigation/constant";
import { useNavigate } from "react-router-dom";
import { GetObjectDataById } from "../../../../services/utilities/KeyValuePairDataByIds";
export const SellingQuotes = ({
    initialQuoteData,
    airportItem,
    countrycurrency,
    unitItem,
    vendorIATAItem,
    chargeHeadItem
}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [airFreightDataById, setAirFreightDataById] = useState([]);
    const [otherChargesDataById, setOtherChargesDataById] = useState([]);
    const [isbuyingUpDate, setIsBuyingUpdate] = useState(false);

    const [airfrightDetailsError, setAirfrightDetailsError] = useState([]);
    const [otherChargesDetailsError, setOtherChargesDetailsError] = useState([]);


    const [exportbuyingdata, setExportBuyingdata] = useState({
        readyForFinalSelling: false,
        currency1: 101,
        currency2: "",
        currency3: "",
        exchangeRate1: 1,
        exchangeRate2: "",
        exchangeRate3: "",
        asPerAgreement: false,
        asPerAttachment: false,
        totalOtherCharge: "",
        remarks: "",
        enquiryId: "",
        sellingDocument: "",
        airFreightList: [
            {
                airlineId: '',
                stockProvider: '',
                vendorId: '',
                currencyId: 101,
                exchangeRate: 1,
                buyingAFRate: 0,
                afRate: '',
                applicableOn: '',
                unitId: 1,
                qtyChargeWeight: '',
                amount: '',
                dueCarrier: '',
                routing: '',
                transitTime: '',
                validity: '',
                selectForInitialQuote: false,
                selectForFinalSelling: false,
                totalAmount: '',
                dueCarrierList: []
            }
        ],
        otherChargeList: [
            {
                chargeHeadId: '',
                vendorId: '',
                currencyId: 101,
                exchangeRate: 1,
                selectForSelling: false,
                rate: '',
                unitId: '',
                qtyChargeWeight: '',
                amount: ''
            }
        ]
    })

    useEffect(() => {
        if (initialQuoteData.id) {
            const mangle = initialQuoteData.id
            console.log(mangle)
            GetCaller({
                API: GetSellingChargesById,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: initialQuoteData.id || 0,
                callback: ({ response }) => {
                    const IDdata = response?.data?.data
                    if (IDdata.id) {

                        const airFreightList = IDdata.airFreightList.map((res) => ({
                            ...res,
                            airFreightId: res.id,
                            dueCarrier: res?.dueCarrierList?.reduce((sum, item) => res.allIn ? sum : sum + item.amount, 0) || 0,
                            intialQuoteDone: (res.selectForInitialQuote && initialQuoteData.initialQuoteDone) ? true : false

                        }))

                        setExportBuyingdata({
                            ...IDdata,
                            airFreightList: airFreightList
                        })
                        setAirFreightDataById(airFreightList)
                        setOtherChargesDataById(IDdata.otherChargeList)
                        setIsBuyingUpdate(true)
                    }
                    else {
                        setIsBuyingUpdate(false)
                    }
                }
            });
        }
    }, [initialQuoteData])


    const requestdata = (() => {
        const data = {
            quoteId: exportbuyingdata.id,
            currency1: exportbuyingdata.currency1,
            exchangeRate1: exportbuyingdata.exchangeRate1,
            totalOtherCharge: '0',
            asPerAgreement: exportbuyingdata.asPerAgreement,
            asPerAttachment: exportbuyingdata.asPerAttachment,
            airFreightList: exportbuyingdata.airFreightList,

        }

        if (exportbuyingdata.remarks) data.remarks = exportbuyingdata.remarks
        if (exportbuyingdata.asPerAgreement || exportbuyingdata.asPerAttachment) data.sellingDocument = exportbuyingdata.sellingDocument

        if (exportbuyingdata.id) data.id = exportbuyingdata.id
        if (initialQuoteData.id) data.enquiryId = initialQuoteData.id

        if (exportbuyingdata.currency2) data.currency2 = exportbuyingdata.currency2
        if (exportbuyingdata.currency3) data.currency3 = exportbuyingdata.currency3
        if (exportbuyingdata.exchangeRate2) data.exchangeRate2 = exportbuyingdata.exchangeRate2
        if (exportbuyingdata.exchangeRate3) data.exchangeRate3 = exportbuyingdata.exchangeRate3
        if (exportbuyingdata.otherChargeList) data.otherChargeList = exportbuyingdata.otherChargeList

        return data;
    })();






    const AirFreighthasUndefinedValueIndex = (value, field, index) => {
        setAirfrightDetailsError({
            ...airfrightDetailsError,
            [field + index]: !value
        })
    }
    const OtherChargeshasUndefinedValueIndex = (value, field, index) => {
        setOtherChargesDetailsError({
            ...airfrightDetailsError,
            [field + index]: !value
        })
    }

    const hasUndefinedValue = (obj) => {
        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (key === 'asPerAttachment' || key === 'asPerAgreement') {
                // handle 'Remark' validation if needed
            } else if (!value) {
                changes[key + "error"] = !value;
            }
        });
        return changes;
    };

    const AirFreighthasUndefinedValue = (array) => {
        let changes = {};
        array.map((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                if (
                    key === 'id' ||
                    key === 'aeQuoteSellingChargeId' ||
                    key === 'selectForInitialQuote' ||
                    key === 'selectForSelling' ||
                    key === 'selectForFinalSelling' ||
                    key === 'dueCarrier' ||
                    key === 'totalBuyingCharge' ||
                    key === 'stockProvider' ||
                    key === 'buyingAFRate' ||
                    key === 'allIn' ||
                    key === 'vendorId' ||
                    key === 'intialQuoteDone' ||
                    ((obj.stockProvider !== 1 || GetObjectDataById(airportItem, obj?.airlineId)?.gsaItem?.length === 0) && key === 'gsaId')
                ) {

                } else if (!value) {
                    changes[key + index] = !value;
                }
            });
        })
        console.log('changes', changes)

        return changes;
    };



    const handleFormSubmit = (finalQuote) => {


        debugger
        const airfreightdetailchange = AirFreighthasUndefinedValue(requestdata.airFreightList);
        const otherChargesDetails = AirFreighthasUndefinedValue(requestdata.otherChargeList);
        const changes = hasUndefinedValue(requestdata);

        if (Object.keys(changes).length > 0 ||
            Object.keys(airfreightdetailchange).length > 0 ||
            Object.keys(otherChargesDetails).length > 0
        ) {
            setExportBuyingdata({
                ...exportbuyingdata,
                ...changes
            });

            setAirfrightDetailsError(airfreightdetailchange)
            setOtherChargesDetailsError(otherChargesDetails)
            console.log(airfreightdetailchange)
        }
        else if (finalQuote) {

            const finalQuotePayload = {
                aeQuoteId: requestdata.quoteId,
                aeQuoteAirFrieghtId: requestdata.airFreightList.find(item => item?.selectForFinalSelling)?.airFreightId,
                otherChargeList: requestdata.otherChargeList
            }


            create_FinalQuoteCharges(finalQuotePayload)
        }
        else {
            AddAirExportBuyingCharges(requestdata);
        }
    };


    const AddAirExportBuyingCharges = (requestdata) => {


        PostCaller({
            API: isbuyingUpDate ? UpdateAirExportSellingingCharges : PostAirExportSellingCharges,
            callback: () => {
                navigate(airenquirylist)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            contenttype: true,
            item: requestdata
        })
    }

    const create_FinalQuoteCharges = (payload) => {
        PostCaller({
            API: UpdateAirExportFinalSellingingCharges,
            callback: () => {
                navigate(airenquirylist)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            // contenttype: true,
            item: payload
        })
    }

    const handleBasicInputChange = (value, field) => {

        setExportBuyingdata({
            ...exportbuyingdata,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    }


    const filterCountryItems = (one, two) => {
        let updatedCountryCurrency = countrycurrency?.filter(item => item.value !== one && item.value !== two);
        return updatedCountryCurrency;
    }




    return <>
        <div className="row">
            <div className="col-md-2">
                <Dropdown
                    field={
                        {
                            id: "currency1",
                            label: "Currency 1",
                            value: exportbuyingdata.currency1,
                            required: true,
                            error: exportbuyingdata.currency1error,
                            item: filterCountryItems(exportbuyingdata.currency2, exportbuyingdata.currency3),
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
            <div className="col-md-2">
                <InputField
                    field={
                        {
                            id: "exchangeRate1",
                            label: "Exchange Rate",
                            type: "number",
                            value: exportbuyingdata.exchangeRate1,
                            required: true,
                            error: exportbuyingdata.exchangeRate1error,
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
            <div className="col-md-2">
                <Dropdown
                    field={
                        {
                            id: "currency2",
                            label: "Currency 2",
                            value: exportbuyingdata.currency2,
                            required: false,
                            error: exportbuyingdata.currency2error,
                            item: filterCountryItems(exportbuyingdata.currency1, exportbuyingdata.currency3),
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
            <div className="col-md-2">
                <InputField
                    field={
                        {
                            id: "exchangeRate2",
                            label: "Exchange Rate",
                            type: "number",
                            value: exportbuyingdata.exchangeRate2,
                            required: false,
                            error: exportbuyingdata.exchangeRate2error,
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
            <div className="col-md-2">
                <Dropdown
                    field={
                        {
                            id: "currency3",
                            label: "Currency 3",
                            value: exportbuyingdata.currency3,
                            required: false,
                            error: exportbuyingdata.currency3error,
                            item: filterCountryItems(exportbuyingdata.currency2, exportbuyingdata.currency1),
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
            <div className="col-md-2">
                <InputField
                    field={
                        {
                            id: "exchangeRate3",
                            label: "Exchange Rate",
                            type: "number",
                            value: exportbuyingdata.exchangeRate3,
                            required: false,
                            error: exportbuyingdata.exchangeRate3error,
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
        </div>


        {Create_AIRFREIGHT({
            airfrightDetailsError,
            airportItem,
            countrycurrency,
            unitItem,
            vendorIATAItem,
            chargeHeadItem,
            handleBasicInputChange,
            airFreightDataById,
            initialQuoteData,
            exportbuyingdata,
            AirFreighthasUndefinedValueIndex
        })}

        {Create_OtherCharges({
            otherChargesDetailsError,
            countrycurrency,
            unitItem, vendorIATAItem,
            chargeHeadItem,
            handleBasicInputChange,
            otherChargesDataById,
            initialQuoteData,
            exportbuyingdata,
            OtherChargeshasUndefinedValueIndex
        })}




        <div className="row">
            <div className="col-md-3">
                <div className="cmd">
                    <div>
                        <input type="checkbox" className="me-3" checked={exportbuyingdata.asPerAgreement}
                            onChange={() => {
                                handleBasicInputChange(!exportbuyingdata.asPerAgreement, { id: 'asPerAgreement' })
                            }}
                        />
                        As Per Agreement
                    </div>

                    <div>
                        <input type="checkbox" className="me-3" checked={exportbuyingdata.asPerAttachment}
                            onChange={() => {
                                handleBasicInputChange(!exportbuyingdata.asPerAttachment, { id: 'asPerAttachment' })
                            }}
                        />
                        As Per Attachment
                    </div>
                </div>

            </div>
            <div className="col-md-3">
                <InputFileField
                    field={
                        {
                            id: 'sellingDocument',
                            label: "Uploads (IF Any)",
                            type: "file",
                            value: exportbuyingdata.sellingDocument,
                            required: true,
                            error: exportbuyingdata.sellingDocumenterror,
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>

            <div className="col-md-6">
                <TextAreafield
                    field={
                        {
                            id: "remarks",
                            label: "Special Requirements/ Remarks",
                            type: 'text',
                            value: exportbuyingdata.remarks,
                            required: true,
                            error: exportbuyingdata.remarkserror,
                            onChange: handleBasicInputChange
                        }
                    }
                />
            </div>
        </div>

        <div className="cmd mt-5">
            <div></div>
            <div className="d-flex">
                <Button variant='contained' onClick={() => handleFormSubmit(false)} >
                    Create Initial Quote
                </Button>
                {
                    exportbuyingdata?.readyForFinalSelling && requestdata.airFreightList.filter(item => item?.selectForFinalSelling).length !== 0 &&
                    <Button className="ms-3" variant='contained' onClick={() => handleFormSubmit(true)} >
                        Create Final Quote
                    </Button>
                }

            </div>

        </div>


    </>

}
const Create_AIRFREIGHT = ({ airfrightDetailsError, airportItem, countrycurrency, unitItem, vendorIATAItem, chargeHeadItem, handleBasicInputChange, airFreightDataById, initialQuoteData, exportbuyingdata, AirFreighthasUndefinedValueIndex }) => {

    const [open, setOpen] = React.useState(false);
    const [selectAllFreight, setSelectAllFreight] = useState(false);

    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };




    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(),
            airlineId: '',
            stockProvider: '',
            gsaId: '',
            vendorId: '',
            currencyId: 101,
            exchangeRate: 1,
            buyingAFRate: "",
            afRate: "",
            applicableOn: '',
            unitId: 1,
            qtyChargeWeight: '',
            amount: '',
            dueCarrier: '',
            allIn: false,
            routing: '',
            transitTime: '',
            validity: '',
            selectForInitialQuote: false,
            selectForFinalSelling: false,
            totalAmount: '',
            dueCarrierList: []
        },
    ]);

    useEffect(() => {
        if (airFreightDataById && airFreightDataById.length !== 0) {
            let freight = airFreightDataById.map(item => ({
                ...item,
                key: generateUniqueKey(),
            }));

            setInputFields(freight);

        }
    }, [airFreightDataById]);



    const addInputField = () => {
        setInputFields([...inputFields, { key: generateUniqueKey(), allIn: false, airlineId: '', stockProvider: '', vendorId: '', currencyId: 101, exchangeRate: 1, buyingAFRate: '', afRate: '', applicableOn: '', unitId: 1, qtyChargeWeight: '', amount: '', dueCarrier: '', routing: '', transitTime: '', validity: '', selectForInitialQuote: false, selectForFinalSelling: false, totalAmount: '', dueCarrierList: [] }]);
    };

    const countDurCarrierAmount = (obj) => {
        let totalAmount = 0
        if (!obj.allIn && obj?.dueCarrierList && obj?.dueCarrierList?.length !== 0) {
            obj?.dueCarrierList?.map(res => (
                totalAmount += Number(res.amount)
            ))
        }
        return totalAmount;
    }

    const handleInputChange = (key, field, value, index) => {
        let updatedInputFields = [...inputFields];

        if (field === 'selectForFinalSelling') {
            updatedInputFields = updatedInputFields.map(item => ({
                ...item,
                selectForFinalSelling: false
            }));
        }
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
        if (fieldToUpdate) {

            fieldToUpdate[field] = value;

            AirFreighthasUndefinedValueIndex(value, field, index)

            fieldToUpdate["dueCarrier"] = countDurCarrierAmount(fieldToUpdate)


            if (field === 'airlineId') {
                fieldToUpdate['gsaId'] = ""
            }

            if (fieldToUpdate['applicableOn'] === 2) {
                fieldToUpdate['amount'] = Number(fieldToUpdate.afRate) * Number(fieldToUpdate.qtyChargeWeight)
            } else {
                fieldToUpdate['amount'] = Number(fieldToUpdate.afRate)
            }

            fieldToUpdate['totalAmount'] = Number(fieldToUpdate.amount) + Number(fieldToUpdate.dueCarrier)

            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res.id,
                        airFreightId: res.id,
                        aeQuoteSellingChargeId: exportbuyingdata?.id,
                        airlineId: res.airlineId,
                        gsaId: res?.gsaId,
                        stockProvider: res.stockProvider,
                        vendorId: res.vendorId,
                        currencyId: res.currencyId,
                        exchangeRate: res.exchangeRate,
                        buyingAFRate: res.buyingAFRate || 0,
                        afRate: res.afRate,
                        applicableOn: res.applicableOn,
                        unitId: res.unitId,
                        qtyChargeWeight: res.qtyChargeWeight,
                        amount: res.amount,
                        dueCarrier: res.dueCarrier,
                        routing: res.routing,
                        transitTime: res.transitTime,
                        validity: res.validity,
                        selectForInitialQuote: res.selectForInitialQuote,
                        selectForFinalSelling: res.selectForFinalSelling,
                        totalAmount: res.totalAmount,
                        allIn: res.allIn,
                        dueCarrierList: res?.dueCarrierList?.map(({ ...rest }) => ({
                            ...rest,
                            aeSellingChargeAirFreightId: res.id
                        })) || [],
                    }
                )

            })
            handleBasicInputChange(data, { id: 'airFreightList' });
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    id: res.id,
                    airFreightId: res.id,
                    aeQuoteSellingChargeId: exportbuyingdata?.id,
                    airlineId: res.airlineId,
                    gsaId: res?.gsaId,
                    stockProvider: res.stockProvider,
                    vendorId: res.vendorId,
                    currencyId: res.currencyId,
                    exchangeRate: res.exchangeRate,
                    buyingAFRate: res.buyingAFRate || 0,
                    afRate: res.afRate,
                    applicableOn: res.applicableOn,
                    unitId: res.unitId,
                    qtyChargeWeight: res.qtyChargeWeight,
                    amount: res.amount,
                    dueCarrier: res.dueCarrier,
                    routing: res.routing,
                    transitTime: res.transitTime,
                    validity: res.validity,
                    selectForInitialQuote: res.selectForInitialQuote,
                    selectForFinalSelling: res.selectForFinalSelling,
                    totalAmount: res.totalAmount,
                    allIn: res.allIn,
                    dueCarrierList: res?.dueCarrierList?.map(({ ...rest }) => ({
                        ...rest,
                        aeSellingChargeAirFreightId: res.id
                    })) || [],
                }
            )

        })
        handleBasicInputChange(data, { id: 'airFreightList' });
    };


    return <>
        <div className='my-4'>
            <div className="d-flex align-items-center">
                {/* <input type="checkbox" className='me-3'
                    checked={selectAllFreight}
                    onChange={() => {
                        setSelectAllFreight(!selectAllFreight);
                        if (selectAllFreight) {
                            const updatedArray = inputFields.map(item => ({ ...item, selectForInitialQuote: false }));
                            setInputFields(updatedArray)
                        }
                        else {
                            const updatedArray = inputFields.map(item => ({ ...item, selectForInitialQuote: true }));
                            setInputFields(updatedArray)
                        }
                    }}
                /> */}
                <h6 className="m-0">AIR FREIGHT</h6>
            </div>


            {inputFields.map((field, key) => (
                <div className='row' key={field.key}>

                    <div className="col-md-12 d-flex">
                        <div>
                            <Tooltip title='Send To Customer'>
                                <input type='checkbox' className='mt-4 me-3'
                                    disabled={field.intialQuoteDone}
                                    checked={field.selectForInitialQuote}
                                    onChange={() => handleInputChange(field.key, 'selectForInitialQuote', !field.selectForInitialQuote, key)}
                                />
                            </Tooltip>
                            {
                                field?.intialQuoteDone &&

                                <Tooltip title='Push to Final Selling'>
                                    <input type='checkbox' className='mt-4 me-3'
                                        checked={field.selectForFinalSelling}
                                        onChange={() => handleInputChange(field.key, 'selectForFinalSelling', !field.selectForFinalSelling, key)}
                                    />
                                </Tooltip>

                            }

                        </div>
                        <div className="row mb-3">
                            <div className="col-md-2">
                                <Dropdown

                                    field={
                                        {
                                            id: "airlineId",
                                            label: "Airline ",
                                            value: field.airlineId,
                                            required: true,
                                            disable: true,
                                            error: airfrightDetailsError['airlineId' + key],
                                            item: airportItem,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            {(field.stockProvider === 1 && GetObjectDataById(airportItem, field?.airlineId)?.gsaItem?.length !== 0) &&
                                <div className="col-md-2">
                                    <Dropdown
                                        field={
                                            {
                                                id: "gsaId",
                                                label: "GSA ",
                                                value: field.gsaId,
                                                required: true,
                                                error: airfrightDetailsError['gsaId' + key],
                                                item: GetObjectDataById(airportItem, field?.airlineId)?.gsaItem?.map((res) => ({
                                                    name: res?.gsaName,
                                                    value: res?.gsaId
                                                })),
                                                onChange: (value, fieldChanges) => {
                                                    handleInputChange(field.key, fieldChanges.id, value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            }

                            {/* <div className="col-md-2">

                                <Dropdown

                                    field={
                                        {
                                            id: "stockProvider",
                                            label: "Stock Provider ",
                                            value: field.stockProvider,
                                            required: true,
                                            error: airfrightDetailsError['stockProvider' + key],
                                            item: IntialStockProvider,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div> */}

                            {/* <div className="col-md-2">

                                <Dropdown

                                    field={
                                        {
                                            id: "vendorId",
                                            label: "Vendor/IATA ",
                                            value: field.vendorId,
                                            disable: true,
                                            required: true,
                                            error: airfrightDetailsError['vendorId' + key],
                                            item: vendorIATAItem,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div> */}

                            <div className="col-md-2">

                                <Dropdown

                                    field={
                                        {
                                            id: "currencyId",
                                            label: "Currency ",
                                            value: field.currencyId,
                                            disable: false,
                                            required: true,
                                            error: airfrightDetailsError['currencyId' + key],
                                            item: countrycurrency,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <InputField

                                    field={
                                        {
                                            id: "exchangeRate",
                                            label: "Ex. Rate",
                                            disable: false,
                                            type: 'number',
                                            value: field.exchangeRate,
                                            required: true,
                                            error: airfrightDetailsError['exchangeRate' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <div className="d-flex">

                                    <div className="d-flex gstWrapper">
                                        <InputField

                                            field={
                                                {
                                                    id: "",
                                                    label: "Rate",
                                                    type: 'number',
                                                    value: field.buyingAFRate,
                                                    required: false,
                                                    disable: true,
                                                    // error: airfrightDetailsError['buyingAFRate' + key],
                                                    onChange: (value, fieldChanges) => {
                                                        // handleInputChange(field.key, fieldChanges.id, value, key);
                                                    }
                                                }
                                            }
                                        />

                                        <InputField

                                            field={
                                                {
                                                    id: "afRate",
                                                    label: "A/F Rate",
                                                    type: 'number',
                                                    value: field.afRate,
                                                    required: true,
                                                    error: airfrightDetailsError['afRate' + key],
                                                    onChange: (value, fieldChanges) => {
                                                        handleInputChange(field.key, fieldChanges.id, value, key);
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                    <Tooltip title='All In'>
                                        <input className="mt-3 ms-1" type="checkbox" checked={field.allIn} onChange={() => { handleInputChange(field.key, 'allIn', !field.allIn, key); }} />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "applicableOn",
                                            label: "Applicable On ",
                                            type: 'number',
                                            disable: false,
                                            value: field.applicableOn,
                                            required: true,
                                            error: airfrightDetailsError['applicableOn' + key],
                                            item: [
                                                { value: 1, name: 'Minimum' },
                                                { value: 2, name: 'Chargeable weight' },
                                            ],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <Dropdown
                                    className={'mt-4'}
                                    field={
                                        {
                                            id: "unitId",
                                            label: "Unit",
                                            disable: false,
                                            value: field.unitId,
                                            required: true,
                                            error: airfrightDetailsError['unitId' + key],
                                            item: [
                                                { value: 1, name: 'KG' },
                                                { value: 2, name: 'LBS' },
                                            ],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-2">
                                <InputField
                                    className={'mt-4'}
                                    field={
                                        {
                                            id: "qtyChargeWeight",
                                            label: "Qty",
                                            type: 'number',
                                            value: field.qtyChargeWeight,
                                            disable: true,
                                            required: true,
                                            error: airfrightDetailsError['qtyChargeWeight' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-2">
                                <InputField
                                    className={'mt-4'}
                                    field={
                                        {
                                            id: "amount",
                                            label: "Amount",
                                            type: 'number',
                                            value: field.amount,
                                            required: true,
                                            error: airfrightDetailsError['amount' + key],
                                            disable: false,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <React.Fragment>
                                    <div className="cmd mt-2"  >
                                        <div></div>

                                        <Create_DueCarrier
                                            countrycurrency={countrycurrency}
                                            unitItem={unitItem}
                                            chargeHeadItem={chargeHeadItem}
                                            data={field.dueCarrierList}
                                            callBack={(value, id) => handleInputChange(field.key, id === 'dueCarriers' ? 'dueCarrierList' : id, value, key)}
                                            fieldData={field}
                                        />

                                    </div>




                                </React.Fragment >
                                <InputField
                                    className={'mt-0'}
                                    field={
                                        {
                                            id: "dueCarrier",
                                            label: "Due Carrier",
                                            type: 'number',
                                            value: field.dueCarrier,
                                            required: true,
                                            error: airfrightDetailsError['dueCarrier' + key],
                                            disable: true,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <InputField
                                    className={'mt-4'}
                                    field={
                                        {
                                            id: "routing",
                                            label: "Routing",
                                            type: 'text',
                                            disable: false,
                                            value: field.routing,
                                            required: true,
                                            error: airfrightDetailsError['routing' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <InputField
                                    className={'mt-4'}

                                    field={
                                        {
                                            id: "transitTime",
                                            label: "Transit Time",
                                            value: field.transitTime,
                                            disable: false,
                                            required: true,
                                            error: airfrightDetailsError['transitTime' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <InputField
                                    className={'mt-4'}
                                    field={
                                        {
                                            id: "validity",
                                            label: "Validity (In Days)",
                                            type: 'number',
                                            value: field.validity,
                                            disable: false,
                                            required: true,
                                            error: airfrightDetailsError['validity' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>


                            <div className="col-md-8"></div>
                            <div className="col-md-2">

                                <InputField
                                    field={
                                        {
                                            id: "totalAmount",
                                            label: "Total Selling",
                                            type: 'number',
                                            value: field.totalAmount,
                                            required: true,
                                            error: false,
                                            disable: true,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                        </div>

                    </div>
                    {/* <div className="col-md-1 cmd" style={{ justifyContent: "right", alignItems: "flex-start" }}>
                        {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                            <Add />
                        </Button> : (
                            <Button
                                sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                onClick={() => removeInputField(field.key)}
                                variant='contained'
                            >
                                <Close />
                            </Button>
                        )}
                    </div> */}
                </div>
            ))}
        </div>

    </>
}


const Create_OtherCharges = ({ countrycurrency,
    unitItem,
    vendorIATAItem,
    chargeHeadItem,
    handleBasicInputChange,
    otherChargesDataById,
    otherChargesDetailsError,
    initialQuoteData,
    exportbuyingdata,
    OtherChargeshasUndefinedValueIndex
}) => {

    const [selectAllFreight, setSelectAllFreight] = useState(false);

    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            chargeHeadId: '',
            vendorId: '',
            currencyId: 101,
            exchangeRate: 1,
            selectForSelling: false,
            rate: '',
            unitId: '',
            qtyChargeWeight: '',
            amount: ''

        },
    ]);
    useEffect(() => {
        if (otherChargesDataById && otherChargesDataById?.length !== 0) {
            let freight = otherChargesDataById?.map(item => ({
                ...item,
                key: generateUniqueKey()
            }));

            setInputFields(freight);

        }
    }, [otherChargesDataById]);




    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            chargeHeadId: '',
            vendorId: '',
            currencyId: 101,
            exchangeRate: 1,
            selectForSelling: false,
            rate: '',
            unitId: '',
            qtyChargeWeight: '',
            amount: ''
        }]);
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

        if (fieldToUpdate) {
            fieldToUpdate[field] = value;
            OtherChargeshasUndefinedValueIndex(value, field, index)
            fieldToUpdate['amount'] = Number(fieldToUpdate.rate) * Number(fieldToUpdate.qtyChargeWeight)


            // if (fieldToUpdate['applicableOn'] === 2) {
            //     fieldToUpdate['amount'] = Number(fieldToUpdate.rate) * Number(fieldToUpdate.qtyChargeWeight)
            // } else {
            //     fieldToUpdate['amount'] = Number(fieldToUpdate.rate)
            // }


            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res.id || null,
                        aeQuoteSellingChargeId: exportbuyingdata?.id,
                        chargeHeadId: res.chargeHeadId,
                        vendorId: res.vendorId,
                        currencyId: res.currencyId,
                        exchangeRate: res.exchangeRate,
                        selectForSelling: res.selectForSelling,
                        rate: res.rate,
                        unitId: res.unitId,
                        qtyChargeWeight: res.qtyChargeWeight,
                        amount: res.amount
                    }
                )

            })
            handleBasicInputChange(data, { id: 'otherChargeList' });
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    id: res.id || null,
                    aeQuoteSellingChargeId: exportbuyingdata?.id,
                    chargeHeadId: res.chargeHeadId,
                    vendorId: res.vendorId,
                    currencyId: res.currencyId,
                    exchangeRate: res.exchangeRate,
                    selectForSelling: res.selectForSelling,
                    rate: res.rate,
                    unitId: res.unitId,
                    qtyChargeWeight: res.qtyChargeWeight,
                    amount: res.amount
                }
            )

        })
        handleBasicInputChange(data, { id: 'otherChargeList' });
    };


    return <>
        <div className='my-4'>
            <div className="d-flex align-items-center">
                {
                    exportbuyingdata?.readyForFinalSelling &&
                    <input type="checkbox" className='me-3'
                        checked={selectAllFreight}
                        onChange={() => {
                            setSelectAllFreight(!selectAllFreight);
                            if (selectAllFreight) {
                                const updatedArray = inputFields.map(item => ({ ...item, selectForSelling: false }));
                                setInputFields(updatedArray)
                            }
                            else {
                                const updatedArray = inputFields.map(item => ({ ...item, selectForSelling: true }));
                                setInputFields(updatedArray)
                            }

                        }}
                    />
                }

                <h6 className="m-0">OTHER CHARGES</h6>


            </div>


            {inputFields?.map((field, key) => (
                <div className='row' key={field.key}>

                    <div className="col-md-11 d-flex">
                        <div>
                            {
                                exportbuyingdata?.readyForFinalSelling &&
                                <input type='checkbox' className='mt-4 me-3'
                                    checked={field.selectForSelling}
                                    onChange={() => handleInputChange(field.key, 'selectForSelling', !field.selectForSelling, key)}
                                />
                            }

                        </div>
                        <div className="row mb-3">
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "chargeHeadId",
                                            label: "Charge Heads ",
                                            value: field.chargeHeadId,
                                            required: true,
                                            error: otherChargesDetailsError['chargeHeadId' + key],
                                            item: chargeHeadItem,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            {/* <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "vendorId",
                                            label: "Vendor Name ",
                                            value: field.vendorId,
                                            required: true,
                                            error: otherChargesDetailsError['vendorId' + key],
                                            item: vendorIATAItem,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div> */}
                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "currencyId",
                                            label: "Currency",
                                            value: field.currencyId,
                                            required: true,
                                            error: otherChargesDetailsError['currencyId' + key],
                                            item: countrycurrency,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: "exchangeRate",
                                            label: "Ex. Rate",
                                            type: 'number',
                                            value: field.exchangeRate,
                                            required: true,
                                            error: otherChargesDetailsError['exchangeRate' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <div className="d-flex gstWrapper">
                                    <InputField
                                        field={
                                            {
                                                id: "buyingRate",
                                                label: "Buying Rate",
                                                type: 'number',
                                                value: field.buyingRate,
                                                required: true,
                                                disable: true,
                                                error: otherChargesDetailsError['buyingRate' + key],
                                                onChange: (value, fieldChanges) => {
                                                    handleInputChange(field.key, fieldChanges.id, value, key);
                                                }
                                            }
                                        }
                                    />
                                    <InputField
                                        field={
                                            {
                                                id: "rate",
                                                label: "Selling Rate",
                                                type: 'number',
                                                value: field.rate,
                                                required: true,
                                                error: otherChargesDetailsError['rate' + key],
                                                onChange: (value, fieldChanges) => {
                                                    handleInputChange(field.key, fieldChanges.id, value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <Dropdown
                                    field={
                                        {
                                            id: "unitId",
                                            label: "Unit ",
                                            value: field.unitId,
                                            required: true,
                                            error: otherChargesDetailsError['unitId' + key],
                                            item: unitItem,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: "qtyChargeWeight",
                                            label: "Qty",
                                            type: 'number',
                                            value: field.qtyChargeWeight,
                                            required: true,
                                            error: otherChargesDetailsError['qtyChargeWeight' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: "amount",
                                            label: "Amount",
                                            type: 'number',
                                            value: field.amount,
                                            required: true,
                                            error: otherChargesDetailsError['amount' + key],
                                            disable: true,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-8"></div>
                            <div className="col-md-10"></div>
                            {/* <div className="col-md-2">
                                <InputField
                                    field={
                                        {
                                            id: "",
                                            label: "Total Selling",
                                            type: 'number',
                                            value: field.length,
                                            required: true,
                                            error: otherChargesDetailsError['totalNumber' + key],
                                            disable: true,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div> */}


                        </div>
                    </div>
                    <div className="col-md-1 cmd" style={{ justifyContent: "right", alignItems: "flex-start" }}>
                        {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                            <Add />
                        </Button> : (
                            <Button
                                sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                onClick={() => removeInputField(field.key)}
                                variant='contained'
                            >
                                <Close />
                            </Button>
                        )}
                    </div>
                </div>
            ))}
        </div>

    </>
}


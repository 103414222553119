import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Checkbox, IconButton, Switch, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dropdown, { InputField } from "../../component/controls";
import { Add, Close } from '@mui/icons-material';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { PostCustomerAddBranch, PostGSTStatus, PostMasterAccountLedger, PostMasterCity, PostMasterCountry, PostMasterState } from '../../../services/constant';
import { DepartmentType } from '../../../services/modalData';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CustomerBranch = ({ row, callback }) => {

    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [stateCode, setStateCode] = useState('');
    const [CountryItem, setCountryItem] = useState([])
    const [StateItem, setStateItem] = useState([])
    const [CityItem, setCityItem] = useState([])
    const [GSTStatus, setGSTStatus] = useState([])
    const [accountLedgerItem, setaccountLedgerItem] = useState([])
    const [useDefaultBankDetails, setUseDefaultBankDetails] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setcustomerBranchFormData(
            {
                name: "",
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                country: '',
                state: '',
                city: '',
                accountLedger: '',
                zipCode: '',
                gstStatus: '',
                gstNumber: "",
                tanNumber: "",
                beneficiaryName: "",
                bankName: "",
                accountNumber: "",
                bankCode: "",
                swiftCode: "",
                bankAddress: "",
                contactList: []
            }
        )
    };

    useEffect(() => {
        GetCaller({
            API: PostMasterAccountLedger,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const item = []
                response?.data?.data?.records?.map(res => {
                    item.push({
                        value: res.id,
                        name: res.name
                    })
                })
                setaccountLedgerItem(item)
            }

        });

        GetCaller({
            API: PostMasterCountry,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const item = []
                response?.data?.data?.records?.map(res => {
                    item.push({
                        value: res.id,
                        name: res.name
                    })
                })
                setCountryItem(item)
            }

        });



        GetCaller({
            API: PostGSTStatus,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const item = []
                response?.data?.data?.records?.map(res => {
                    item.push({
                        value: res.id,
                        name: res.gstStatus
                    })
                })
                setGSTStatus(item)
            }

        });

    }, [])


    const [customerBranchFormData, setcustomerBranchFormData] = useState(
        {
            name: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            country: '',
            state: '',
            city: '',
            accountLedger: '',
            zipCode: '',
            gstStatus: '',
            gstNumber: "",
            tanNumber: "",
            beneficiaryName: "",
            bankName: "",
            accountNumber: "",
            bankCode: "",
            swiftCode: "",
            bankAddress: "",
            contactList: []
        }
    )

    const requestData = {
        customerId: row?.id,
        defaultBankDetail: useDefaultBankDetails,
        branch: {
            name: customerBranchFormData.name,
            addressLine1: customerBranchFormData.addressLine1,
            addressLine2: customerBranchFormData.addressLine2,
            addressLine3: customerBranchFormData.addressLine3,
            country: customerBranchFormData.country,
            state: customerBranchFormData.state,
            city: customerBranchFormData.city,
            zipCode: customerBranchFormData.zipCode,
            gstStatus: customerBranchFormData.gstStatus,
            accountLedger: customerBranchFormData.accountLedger,
            gstNumber: customerBranchFormData.gstNumber,
            tanNumber: customerBranchFormData.tanNumber,
            bankDetail: {
                beneficiaryName: customerBranchFormData.beneficiaryName,
                name: customerBranchFormData.bankName,
                accountNumber: customerBranchFormData.accountNumber,
                confirmaccountNumber: customerBranchFormData.confirmaccountNumber,
                bankCode: customerBranchFormData.bankCode,
                swiftCode: customerBranchFormData.swiftCode,
                bankAddress: customerBranchFormData.bankAddress
            },
            contactList: customerBranchFormData.contactList
        }
    }

    const handleBasicInputChange = (value, field) => {
        if (field.id === 'country') {
            GetCaller({
                API: PostMasterState,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: {},
                callback: ({ response }) => {
                    const item = []
                    response?.data?.data?.records?.map(res => {
                        if (res.country.key === value) {
                            item.push({
                                value: res.id,
                                name: res.name,
                                code: res.stateCode
                            })
                        }
                    })
                    setStateItem(item)
                }

            });

        }
        if (field.id === 'state') {
            GetCaller({
                API: PostMasterCity,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: {},
                callback: ({ response }) => {
                    const item = []
                    response?.data?.data?.records?.map(res => {
                        if (res.state.key === value)
                            item.push({
                                value: res.id,
                                name: res.name
                            })
                    })
                    setCityItem(item)
                }

            });

            StateItem.map(res => {
                if (res.value === value) {
                    setStateCode(res.code)
                }
            })



        }


        setcustomerBranchFormData({
            ...customerBranchFormData,
            [field.id]: value,
            [field.id + 'error']: !value,
        })
    }


    const handleSubmit = () => {
        console.log('branchInfoRequestData', requestData)

        function hasUndefinedValues(obj) {
            let changes = {}; // Temporary object to accumulate changes

            // Check if any value in the object is undefined
            Object.entries(obj).forEach(([key, value]) => {
                if (
                    key === 'addressLine2' ||
                    key === 'addressLine3'
                ) {
                    return false
                }
                else if (!value) {
                    changes[key] = value;
                    changes[key + 'error'] = !value;
                }
            });
            console.log(changes)
            return changes;
        }
        function hasUndefinedValuesCommonName(obj) {
            let changes = {}; // Temporary object to accumulate changes

            // Check if any value in the object is undefined
            Object.entries(obj).forEach(([key, value]) => {
                if (
                    key === 'addressLine2' ||
                    key === 'addressLine3' ||
                    key === 'beneficiaryName' && useDefaultBankDetails ||
                    key === 'name' && useDefaultBankDetails ||
                    key === 'accountNumber' && useDefaultBankDetails ||
                    key === 'bankCode' && useDefaultBankDetails ||
                    key === 'swiftCode' && useDefaultBankDetails ||
                    key === 'bankAddress' && useDefaultBankDetails ||
                    key === 'confirmaccountNumber' && useDefaultBankDetails
                ) {
                    return false
                }
                else if (!value) {
                    if (key === 'name') {
                        changes['bankNameerror'] = !value;

                    } else {
                        changes[key + 'error'] = !value;

                    }
                }
            });
            console.log(changes)
            return changes;
        }
        const customerBranchCheck = hasUndefinedValues(requestData?.branch)
        const customerBranchBankdetailCheck = hasUndefinedValuesCommonName(requestData?.branch.bankDetail)


        if (
            Object.keys(customerBranchCheck).length > 0 ||
            Object.keys(customerBranchBankdetailCheck).length > 0 ||
            !useDefaultBankDetails && customerBranchFormData.confirmaccountNumber !== customerBranchFormData.accountNumber
        ) {
            setcustomerBranchFormData(prevFormData => ({
                ...prevFormData,
                ...customerBranchCheck,
                ...customerBranchBankdetailCheck
            }));
        }
        else {
            PostCaller({
                API: PostCustomerAddBranch,

                callback: () => { callback(); handleClose() },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: requestData
            })
        }
    }

    const ContactForm = () => {

        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };
        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                name: '',
                email: '',
                departmentType: '',
                mobileNumber: '',
                landline: ''
            },
        ]);


        const addInputField = () => {
            // if (inputFields.length !== 2) {
            //     setInputFields([...inputFields, { key: generateUniqueKey(), name: '', email: '', departmentType: '', mobileNumber: '', landline: '' }]);
            // }
            setInputFields([...inputFields, { key: generateUniqueKey(), name: '', email: '', departmentType: '', mobileNumber: '', landline: '' }]);
        };

        const handleInputChange = (key, field, value) => {
            const updatedInputFields = [...inputFields];
            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
            if (fieldToUpdate) {
                fieldToUpdate[field] = value;
                setInputFields(updatedInputFields);
                const data = []
                updatedInputFields && updatedInputFields.map((res) => {
                    return data.push(
                        {
                            name: res.name,
                            email: res.email,
                            departmentType: res.departmentType,
                            mobileNumber: res.mobileNumber,
                            landline: res.landline
                        }
                    )

                })
                handleBasicInputChange(data, { id: 'contactList' });
            }
        };

        const removeInputField = (key) => {
            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        name: res.name,
                        email: res.email,
                        departmentType: res.departmentType,
                        mobileNumber: res.mobileNumber,
                        landline: res.landline
                    }
                )

            })
            handleBasicInputChange(data, { id: 'contactList' });
        };


        return <>
            <div className='mt-4'>
                <h5>Contact From</h5>

                {inputFields.map((field, key) => (
                    <div className='row' key={field.key}>
                        <div className="col-md-11">
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "name",
                                                label: "Contact Person Name",
                                                type: 'text',
                                                value: field.name,
                                                required: true,
                                                error: false,
                                                helperText: 'Contact Person Name Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'name', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "email",
                                                label: "Email",
                                                type: 'email',
                                                value: field.email,
                                                required: true,
                                                error: false,
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'email', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <Dropdown
                                        // className="mt-0"
                                        field={
                                            {
                                                id: "departmentType",
                                                label: "Department Type ",
                                                value: field.departmentType,
                                                required: true,
                                                error: false,
                                                helperText: 'Department Type Required',
                                                item: DepartmentType,
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'departmentType', value);
                                                }
                                            }
                                        }

                                    />

                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "mobileNumber",
                                                label: "Mobile No",
                                                type: 'number',
                                                value: field.mobileNumber,
                                                required: true,
                                                error: false,
                                                helperText: 'Mobile No Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'mobileNumber', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "landline",
                                                label: "Land Line",
                                                type: 'number',
                                                value: field.landline,
                                                required: false,
                                                error: false,
                                                helperText: 'Land Line Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'landline', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 cmd" style={{ justifyContent: "right" }}>
                            {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                <Add />
                            </Button> : (
                                <Button
                                    sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                    onClick={() => removeInputField(field.key)}
                                    variant='contained'
                                >
                                    <Close />
                                </Button>
                            )}
                        </div>
                    </div>
                ))}
            </div>

        </>
    }


    return (
        <React.Fragment>

            <IconButton onClick={handleClickOpen} >
                <Add />
            </IconButton>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "90% !important",
                    },
                }}
                sx={{
                    '& .MuiDialog-container': {
                        alignItems: "start"
                    }
                }
                }


            >
                <div className=''>
                    <DialogTitle>
                        <div className="cmd">
                            <h2>Branch Information</h2>
                            <Tooltip title='Close'>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <hr />
                    </DialogTitle>
                    <DialogContent>

                        <div className="">
                            <div className="row ">
                                {/* <h4>Add Branch Address</h4> */}

                                {/* <div className='mt-3'>
                                    <Checkbox sx={{ p: 0 }} /> Use Existing ledger
                                </div> */}

                                {
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "name",
                                                    label: "Identification label",
                                                    type: 'text',
                                                    value: customerBranchFormData.name,
                                                    required: true,
                                                    error: customerBranchFormData.nameerror,
                                                    helperText: 'Identification label Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                }

                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "country",
                                                label: "Country",
                                                value: customerBranchFormData.country,
                                                required: true,
                                                error: customerBranchFormData.countryerror,
                                                helperText: 'Country Required',
                                                item: CountryItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "state",
                                                label: "State",
                                                value: customerBranchFormData.state,
                                                required: true,
                                                error: customerBranchFormData.stateerror,
                                                helperText: 'State Required',
                                                item: StateItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "city",
                                                label: "City",
                                                value: customerBranchFormData.city,
                                                required: true,
                                                error: customerBranchFormData.cityerror,
                                                helperText: 'City Required',
                                                item: CityItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "accountLedger",
                                                label: "A/C Ledger Name ",
                                                value: customerBranchFormData.accountLedger,
                                                required: true,
                                                error: customerBranchFormData.accountLedgererror,
                                                helperText: 'A/C Ledger Name Required',
                                                item: accountLedgerItem,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />

                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "zipCode",
                                                label: "Zip /Postal Code",
                                                type: 'number',
                                                value: customerBranchFormData.zipCode,
                                                required: true,
                                                error: customerBranchFormData.zipCodeerror,
                                                helperText: 'Zip /Postal Code Required',
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "tanNumber",
                                                label: "TAN No",
                                                type: 'text',
                                                value: customerBranchFormData.tanNumber,
                                                required: true,
                                                error: customerBranchFormData.tanNumbererror,
                                                helperText: 'TAN No Required',
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: "gstStatus",
                                                label: "GST STATUS ",
                                                value: customerBranchFormData.gstStatus,
                                                required: true,
                                                error: customerBranchFormData.gstStatuserror,
                                                helperText: 'GST STATUS Required',
                                                item: GSTStatus,
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />

                                </div>
                                <div className="col-md-3">
                                    <div className="d-flex gstWrapper">
                                        <InputField
                                            className='me-2 mt-3'
                                            field={
                                                {
                                                    id: "stateCode",
                                                    label: "GST Code",
                                                    type: 'text',
                                                    value: stateCode,
                                                    required: false,
                                                    error: false,
                                                    helperText: 'GST Code Required',
                                                    disable: true,
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                        <InputField
                                            field={
                                                {
                                                    id: "gstNumber",
                                                    label: "GST NO.",
                                                    type: GSTStatus[customerBranchFormData.gstStatus - 1]?.name.toLowerCase() === 'unregistered' ? 'text' : 'number',
                                                    value: GSTStatus[customerBranchFormData.gstStatus - 1]?.name.toLowerCase() === 'unregistered' ? 'Unregistered' : customerBranchFormData.gstNumber,
                                                    required: GSTStatus[customerBranchFormData.gstStatus - 1]?.name.toLowerCase() === 'unregistered' ? false : true,
                                                    error: customerBranchFormData.gstNumbererror,
                                                    disable: GSTStatus[customerBranchFormData.gstStatus - 1]?.name.toLowerCase() === 'unregistered' ? true : false,
                                                    helperText: 'GST NO. Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>

                                </div>


                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "addressLine1",
                                                label: "Address 1",
                                                type: 'text',
                                                value: customerBranchFormData.addressLine1,
                                                required: true,
                                                error: customerBranchFormData.addressLine1error,
                                                helperText: 'Address 1 Required',
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "addressLine2",
                                                label: "Address 2",
                                                type: 'text',
                                                value: customerBranchFormData.addressLine2,
                                                required: false,
                                                error: customerBranchFormData.addressLine2error,
                                                helperText: 'Address 2 Required',
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "addressLine3",
                                                label: "Address 3",
                                                type: 'text',
                                                value: customerBranchFormData.addressLine3,
                                                required: false,
                                                error: customerBranchFormData.addressLine3error,
                                                helperText: 'Address 3 Required',
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>


                            </div>

                            <hr className='my-4' />

                            <div className="cmd">
                                <h4>Bank Details</h4>
                                <div className='mt-3'>
                                    <Checkbox checked={useDefaultBankDetails} onChange={() => setUseDefaultBankDetails(!useDefaultBankDetails)} sx={{ p: 0 }} /> Use Default Bank Details
                                </div>
                            </div>


                            {
                                !useDefaultBankDetails &&
                                <div className="row">
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "beneficiaryName",
                                                    label: "Beneficiary Name",
                                                    type: 'text',
                                                    value: customerBranchFormData.beneficiaryName,
                                                    required: true,
                                                    error: customerBranchFormData.beneficiaryNameerror,
                                                    helperText: 'Beneficiary Name Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "bankName",
                                                    label: "Bank Name",
                                                    type: 'text',
                                                    value: customerBranchFormData.bankName,
                                                    required: true,
                                                    error: customerBranchFormData.bankNameerror,
                                                    helperText: 'Bank Name Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "accountNumber",
                                                    label: "A/C No",
                                                    type: 'text',
                                                    value: customerBranchFormData.accountNumber,
                                                    required: true,
                                                    error: customerBranchFormData.accountNumbererror,
                                                    helperText: 'A/C No Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "confirmaccountNumber",
                                                    label: "Confirm A/C No",
                                                    type: 'text',
                                                    value: customerBranchFormData.confirmaccountNumber,
                                                    required: true,
                                                    error: customerBranchFormData.confirmaccountNumbererror || (customerBranchFormData.confirmaccountNumber && customerBranchFormData.accountNumber !== customerBranchFormData?.confirmaccountNumber),
                                                    helperText: 'confirm A/C not matched',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "bankCode",
                                                    label: "Bank Code",
                                                    type: 'text',
                                                    value: customerBranchFormData.bankCode,
                                                    required: true,
                                                    error: customerBranchFormData.bankCodeerror,
                                                    helperText: 'Bank Code Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "swiftCode",
                                                    label: "Swift Code",
                                                    type: 'text',
                                                    value: customerBranchFormData.swiftCode,
                                                    required: true,
                                                    error: customerBranchFormData.swiftCodeerror,
                                                    helperText: 'Swift Code Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <InputField
                                            field={
                                                {
                                                    id: "bankAddress",
                                                    label: "Bank Address",
                                                    type: 'text',
                                                    value: customerBranchFormData.bankAddress,
                                                    required: true,
                                                    error: customerBranchFormData.bankAddresserror,
                                                    helperText: 'Bank Address Required',
                                                    onChange: handleBasicInputChange
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            }


                            {ContactForm()}
                        </div>

                    </DialogContent>
                    <DialogActions className='mb-5'>
                        <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                        <Button style={{ background: 'var(--color-main)', color: '#fff' }} onClick={handleSubmit}>Submit</Button>
                    </DialogActions>
                </div>
            </Dialog>

        </React.Fragment >
    );
}
export default CustomerBranch;

import { Button } from "@mui/material";
import { InputField } from "../component/controls";
import { applicableFormat } from "../../services/modalData";
import { useState } from "react";
import Dropdown from '../component/controls';

import {
  GetMasterCityByPage,
  GetMasterCountryByPage,
  GetMasterBizInstituteTypeByPage,
  GetMasterAccountLedgerByPage,
  GetTDSMasterByPage,
  GetMasterStateByPage,
  PostGSAMaster,
  UpdateGSAMaster,
  PostGSTStatus,
  PostMasterCity
} from "../../services/constant";
import GetCaller from "../../services/apiServices";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CheckMail from "../../services/utilities/checkEmailFormat";
const authToken = localStorage.getItem("authToken");
const headers = {
  Authorization: `Bearer ${authToken}`, // Include the Bearer token in vendorFormthe Authorization header
};

export const CreateGsaForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enquirybranch = useSelector(state => state.enquirybranch)

  const [isEdit, setIsEdit] = useState(false);
  const [cityItem, setCityItem] = useState([]);
  const [stateItem, setStateItem] = useState([]);
  const [cityItemAfterState, setCityAfterStateItem] = useState([]);
  const [stateAfterCountryItem, setStateAfterCountryItem] = useState([]);
  const [countryItem, setCountryItem] = useState([]);
  const [tdsItem, setTDSItem] = useState([]);
  const [accountLedgerItem, setAccountLedgerItem] = useState([]);
  const [bizInstituteItem, setBizInstituteItem] = useState([]);
  const [vendorItem, setVendorItem] = useState([]);
  const [gstNumber, setGSTNumber] = useState('');
  const [gstStatus, setGSTStatus] = useState([]);
  const [isvalid, setIsValid] = useState(false);
  const [gstCode, setGstCode] = useState('')

  const [vendorForm, setVendorForm] = useState({
    id: 0,
    name: "",
    otherBizinstitutetype: '',
    companytypeId: "",
    isPanAvailable: "",
    isTDSApplicable: "",
    legalName: "",
    accountLedgerId: '',
    cityId: '',
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    pinCode: "",
    mobileNumber: "",
    panNumber: "",
    tanNumber: "",
    cin: "",
    email: "",
    contactPerson: "",
    gstStatus: "",
    gstNumber: gstNumber,
    tdsId: "",
    telephone: "",
    code: "",
  });
  //   useEffect(() => {
  //     if(sessionStorage.gsa){
  //         const gsa = JSON.parse(sessionStorage.getItem("gsa"));
  //         setVendorForm(gsa);

  //         setIsEdit(true);
  //         sessionStorage.removeItem('gsa');
  //     }
  // },[]);
  const set_GstStatus = (e) => {
    GetCaller({
      API: PostGSTStatus,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        const item = []
        response?.data?.data?.records?.map(res => {
          item.push({
            value: res.id,
            name: res.gstStatus
          })
        })
        setGSTStatus(item)
      }

    });
  };

  const set_BizInstituteItemData = (e) => {
    const requestData = {
      searchKey: "",
      sortKey: "",
      sortDirection: "",
      itemsPerPage: 100,
      page: 1,
    };
    axios
      .get(GetMasterBizInstituteTypeByPage(requestData), { headers })
      .then((response) => {
        // console.log(e)

        const newVendorItemData = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.name,
        }));


        setBizInstituteItem(newVendorItemData);
      });
  };
  const set_AccountLedgerItemData = (e) => {
    const requestData = {
      searchKey: "",
      sortKey: "",
      sortDirection: "",
      itemsPerPage: 100,
      page: 1,
    };
    axios
      .get(GetMasterAccountLedgerByPage(requestData), { headers })
      .then((response) => {
        // console.log(e)

        const newVendorItemData = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.name,
        }));

        setAccountLedgerItem(newVendorItemData);
      });
  };
  const set_TDSItemData = (e) => {
    const requestData = {
      searchKey: "",
      sortKey: "",
      sortDirection: "",
      itemsPerPage: 100,
      page: 1,
    };
    axios.get(GetTDSMasterByPage(requestData), { headers }).then((response) => {
      // console.log(e)

      const newVendorItemData = response?.data?.data?.records.map((item) => ({
        value: item.id,
        name: item.name,
      }));

      setTDSItem(newVendorItemData);
    });
  };

  const set_CityItemData = () => {
    GetCaller({
      API: PostMasterCity
      ,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        debugger
        const newCityItemData = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.name,
          gstCode: item.gstCode
        }))
        setCityItem(newCityItemData)
      }

    })

  }


  useEffect(() => {

    set_CityItemData();
    set_GstStatus();
    set_BizInstituteItemData();
    set_AccountLedgerItemData();
    set_TDSItemData();
    set_GstStatus();

    if (sessionStorage.gsa) {
      let gsa = JSON.parse(sessionStorage.getItem("gsa"));
      gsa.isPanAvailable = gsa.isPanAvailable === false ? 2 : 1
      gsa.isTDSApplicable = gsa.isTDSApplicable === false ? 2 : 1
      gsa.gstNumber = gsa.gstStatus === 2 ? '' : gsa.gstNumber
      setVendorForm(gsa);
      setIsEdit(gsa.stateId);

      sessionStorage.removeItem('gsa');
      set_CityItemData();

    } else {
      set_CityItemData();
    }
  }, []);



  const handleFormSubmit = () => {

    const hasUndefinedValue = (obj) => {
      let changes = {};
      Object.entries(obj).forEach(([key, value]) => {
        if (
          key === 'id' ||
          key === 'address2' ||
          key === 'address3' ||
          key === 'countryId' ||
          key === 'stateId' ||
          (vendorForm.isPanAvailable !== 1 && key === 'panNumber') ||
          (vendorForm.isTDSApplicable !== 1 && key === 'tdsId') ||
          (bizInstituteItem[vendorForm.companytypeId - 1]?.name.toLowerCase() !== 'other' && key === 'otherBizinstitutetype') ||
          (vendorForm.gstStatus === 2 && key === 'gstNumber')
        ) {

        }
        else if (!value) {
          changes[key + "error"] = !value;
        }
      });
      return changes;
    };


    const changes = hasUndefinedValue(requestdata);
    const isMail = CheckMail(requestdata?.email)


    if (Object.keys(changes).length > 0 || isMail) {
      setVendorForm({
        ...vendorForm, ...changes,
        ['emailerror']: isMail

      });
    }
    else if (isEdit) {

      EditAreaMasterSubmit(requestdata);

    }
    else {
      AddAreaMasterSubmit(requestdata)
    }


  };




  const handleInputChange = (value, field) => {
    //if (field.id === 'countryId') {
    //  set_StateItemData(value)
    //}

    //if (field.id === 'stateId') {
    //  set_CityItemData(value);
    //  stateItem?.map((res) => {
    //    if (res.value === value) {

    //      setGstCode(res.gstCode)
    //    }
    //  })
    //}
    setVendorForm({
      ...vendorForm,
      [field.id]: value,
      [field.id + 'error']: !value,
    });
  };
  console.log(vendorForm)


  const requestdata = (() => {
    const data = {
      branchId: enquirybranch,
      id: vendorForm.id,
      companytypeId: vendorForm.companytypeId,
      otherBizinstitutetype: vendorForm.otherBizinstitutetype,
      isPanAvailable: vendorForm.isPanAvailable,
      isTDSApplicable: vendorForm.isTDSApplicable,
      legalName: vendorForm.legalName,
      accountLedgerId: vendorForm.accountLedgerId,
      cityId: vendorForm.cityId,
      address1: vendorForm.address1,
      address2: vendorForm.address2,
      address3: vendorForm.address3,
      pinCode: vendorForm.pinCode,
      mobileNumber: vendorForm.mobileNumber,
      tanNumber: vendorForm.tanNumber,
      cin: vendorForm.cin,
      email: vendorForm.email,
      contactPerson: vendorForm.contactPerson,
      gstStatus: vendorForm.gstStatus,
      telephone: vendorForm.telephone,
      code: vendorForm.code,
      gstNumber: vendorForm.gstNumber
    };

    if (vendorForm.gstStatus === 2) data.gstNumber = "Unregistered"
    if (vendorForm.isPanAvailable === 1) data.panNumber = vendorForm.panNumber
    if (vendorForm.isTDSApplicable === 1) data.tdsId = vendorForm.tdsId

    return data;
  })();


  const AddAreaMasterSubmit = (requestdata) => {

    requestdata.isPanAvailable = requestdata.isPanAvailable === 1 ? true : false;
    requestdata.isTDSApplicable = requestdata.isTDSApplicable === 1 ? true : false;


    axios.post(PostGSAMaster(), requestdata, { headers }).then((response) => {
      const friendlymess = response?.data?.metaData?.friendlyMessage;
      const resultmess = response?.data?.metaData?.resultMessage;

      if (response?.data?.metaData?.resultCode === "R00") {
        navigate("/gsa");
        dispatch({
          type: "SNACKBARALERT",
          payload: {
            active: true,
            message: friendlymess || resultmess,
            type: "success",
          },
        });
      } else {
        dispatch({
          type: "SNACKBARALERT",
          payload: {
            active: true,
            message: friendlymess || resultmess,
            type: "error",
          },
        });
      }
    });
  };


  const EditAreaMasterSubmit = (requestdata) => {

    requestdata.isPanAvailable = requestdata.isPanAvailable === 1 ? true : false;
    requestdata.isTDSApplicable = requestdata.isTDSApplicable === 1 ? true : false;

    axios.post(UpdateGSAMaster(), requestdata, { headers })
      .then((response) => {
        const friendlymess = response?.data?.metaData?.friendlyMessage;
        const resultmess = response?.data?.metaData?.resultMessage
        if (response?.data?.metaData?.resultCode === "R00") {
          navigate("/gsa");
          dispatch({
            type: "SNACKBARALERT", payload: {
              active: true,
              message: friendlymess || resultmess,
              type: 'success'
            }
          });
        }
        else {
          dispatch({
            type: "SNACKBARALERT", payload: {
              active: true,
              message: friendlymess || resultmess,
              type: 'error'
            }
          });
        }
      })
  }


  return (
    <>
      <div className="hr-form-box">
        {isEdit ? <h6>Update GSA</h6> : <h6>Create GSA</h6>}
        <div className="row">
          <div className="col-md-3">
            <Dropdown
              field={

                {
                  id: 'companytypeId',
                  label: 'Biz Institute Type ',
                  type: 'select',
                  value: vendorForm.companytypeId,
                  required: true,
                  error: vendorForm.companytypeIderror,
                  item: bizInstituteItem,
                  disable: isEdit ? true : false,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          {
            bizInstituteItem && bizInstituteItem[vendorForm.companytypeId - 1]?.name.toLowerCase() === 'other' &&
            <div className="col-md-3">
              <InputField
                field={
                  {
                    id: 'otherBizinstitutetype',
                    label: 'Type of company(Specify other type)',
                    disable: isEdit ? true : false,
                    type: 'text',
                    value: vendorForm.otherBizinstitutetype,
                    required: true,
                    error: vendorForm.otherBizinstitutetypeerror,
                    onChange: handleInputChange
                  }
                }
              />
            </div>
          }

          <div className="col-md-3">
            <Dropdown
              field={
                {
                  id: 'isPanAvailable',
                  label: 'PAN Available ',
                  type: 'select',
                  value: vendorForm.isPanAvailable,
                  required: true,
                  error: vendorForm.isPanAvailableerror,
                  item: applicableFormat,
                  onChange: handleInputChange
                }
              }
            />
          </div>

          {
            vendorForm.isPanAvailable === 1 && <div className="col-md-3">
              <InputField
                field={
                  {
                    id: 'panNumber',
                    label: 'Pan Number',
                    type: 'text',
                    value: vendorForm.panNumber,
                    required: true,
                    error: vendorForm.panNumbererror,
                    onChange: handleInputChange
                  }
                }
              />
            </div>
          }

          <div className="col-md-3">
            <Dropdown
              field={
                {
                  id: 'isTDSApplicable',
                  label: 'TDS Applicable ',
                  type: 'select',
                  value: vendorForm.isTDSApplicable,
                  required: true,
                  error: vendorForm.isTDSApplicableerror,
                  item: applicableFormat,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          {
            vendorForm.isTDSApplicable === 1 &&
            <div className="col-md-3">
              <Dropdown
                field={
                  {
                    id: 'tdsId',
                    label: 'TDS Section ',
                    type: 'select',
                    value: vendorForm.tdsId,
                    required: true,
                    error: vendorForm.tdsIderror,
                    item: tdsItem,
                    onChange: handleInputChange
                  }
                }
              />
            </div>
          }
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'code',
                  label: 'Code',
                  type: 'text',
                  value: vendorForm.code,
                  required: true,
                  error: vendorForm.codeerror,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'legalName',
                  label: 'Legal Name',
                  type: 'text',
                  value: vendorForm.legalName,
                  required: true,
                  error: vendorForm.legalNameerror,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">

            <Dropdown field={{
              id: 'accountLedgerId',
              label: 'A/c Ledger Name ',
              type: 'select',
              value: vendorForm.accountLedgerId,
              required: true,
              error: vendorForm.accountLedgerIderror,
              item: accountLedgerItem,
              onChange: handleInputChange


            }}
            />
          </div>

          <div className="col-md-3">
            <Dropdown
              field={
                {
                  id: "cityId",
                  label: 'City ',
                  type: "select",
                  value: vendorForm.cityId,
                  required: true,
                  error: vendorForm.cityIderror,
                  item: cityItem,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'address1',
                  label: 'Address1',
                  type: "text",
                  value: vendorForm.address1,
                  required: true,
                  error: vendorForm.address1error,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'address2',
                  label: 'Address 2',
                  type: "text",
                  value: vendorForm.address2,
                  required: false,
                  error: vendorForm.address2error,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'address3',
                  label: 'Address 3',
                  type: "text",
                  value: vendorForm.address3,
                  required: false,
                  error: vendorForm.address3error,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'pinCode',
                  label: 'PIN Code',
                  type: "text",
                  value: vendorForm.pinCode,
                  required: true,
                  error: vendorForm.pinCodeerror,
                  onChange: handleInputChange
                }
              }
            />
          </div>

          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'tanNumber',
                  label: 'TAN No',
                  type: "text",
                  value: vendorForm.tanNumber,
                  required: true,
                  error: vendorForm.tanNumbererror,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'cin',
                  label: 'CIN',
                  type: "text",
                  value: vendorForm.cin,
                  required: true,
                  error: vendorForm.cinerror,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'contactPerson',
                  label: 'Contact Person',
                  type: "text",
                  value: vendorForm.contactPerson,
                  required: true,
                  error: vendorForm.contactPersonerror,
                  onChange: handleInputChange

                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'mobileNumber',
                  label: 'Mobile No',
                  type: "number",
                  value: vendorForm.mobileNumber,
                  required: true,
                  error: vendorForm.mobileNumbererror,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'email',
                  label: 'Email',
                  type: "text",
                  value: vendorForm.email,
                  required: true,
                  error: vendorForm.emailerror,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <Dropdown
              field={
                {
                  id: 'gstStatus',
                  label: 'GST STATUS ',
                  type: "select",
                  value: vendorForm.gstStatus,
                  required: true,
                  error: vendorForm.gstStatuserror,
                  item: gstStatus,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <div className="d-flex gstWrapper">
              <InputField
                field={
                  {
                    id: "gstNumber",
                    label: "Gst Code",
                    type: "dualInput",
                    value: gstCode,

                    disable: true,
                    error: vendorForm.gstNumbererror,
                    onChange: handleInputChange
                  }
                }
              />

              <InputField
                field={
                  {
                    id: "gstNumber",
                    label: "Gst No",
                    type: "text",
                    disable: vendorForm.gstStatus === 2 ? true : false,
                    required: vendorForm.gstStatus === 2 ? false : true,
                    value: vendorForm.gstStatus === 2 ? 'Unregistered' : vendorForm.gstNumber,

                    error: vendorForm.gstNumbererror,
                    onChange: handleInputChange
                  }
                }
              />

            </div>
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'telephone',
                  label: 'Telephone No',
                  type: "number",
                  value: vendorForm.telephone,
                  required: true,
                  error: vendorForm.telephoneerror,
                  onChange: handleInputChange
                }
              }
            />
          </div>
        </div>
      </div>

      <div className="cmd">
        <div></div>
        <Button
          sx={{ m: 2, borderRadius: "10px" }}
          onClick={handleFormSubmit}
          variant="contained"
        >
          Submit
        </Button>
      </div>
    </>
  );
};

const FormOtherDetails = ({ onChange }) => {
  const [cityverify, setCityVerify] = useState(false);

  const handleFieldChange = (fieldName, value) => {
    onChange(fieldName, value);
  };

  return (
    <>
      <div className="hr-form-box">
        <h6>Other Branch</h6>

        <div className="row">
          <div className="col-md-3">
            <span className="ms-3">
              {" "}
              <input
                type="checkbox"
                onClick={() => {
                  setCityVerify(!"");
                }}
              />{" "}
              Delhi
            </span>
          </div>
          <div className="col-md-3">
            <span className="ms-3">
              {" "}
              <input
                type="checkbox"
                onClick={() => {
                  setCityVerify(!"");
                }}
              />{" "}
              Mumbai
            </span>
          </div>
          <div className="col-md-3">
            <span className="ms-3">
              {" "}
              <input
                type="checkbox"
                onClick={() => {
                  setCityVerify(!"");
                }}
              />{" "}
              Bangolore
            </span>
          </div>
        </div>
      </div>
      <div className="hr-form-box">
        <div className="row">
          <div className="col-md-3">
            <span className="ms-3">
              {" "}
              <input
                type="checkbox"
                onClick={() => {
                  setCityVerify(!"");
                }}
              />
              Create as Customer Also
            </span>
          </div>
        </div>
      </div>
    </>
  );
};


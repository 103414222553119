import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { PostAirExportBuyingingCharges, PostAirExportSellingCharges, PostIntialPricingSheet } from "../../../../services/APIConstant/enquiryConstant";
import { PostCaller } from "../../../../services/apiServices";
import Dropdown, { InputField, InputFileField, TextAreafield } from "../../../component/controls";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { IntialStockProvider } from "../../../../services/modalData";
import { Add, Close } from "@mui/icons-material";
import { type } from "@testing-library/user-event/dist/type";

export const PricingSheet = ({initialQuoteData, airportItem, countrycurrency }) => {


    const HandleSubmit = () => {
        PostCaller({
            API: PostIntialPricingSheet,
            callback: () => {
                // navigate(`${chargehead}`)
                // setIsEdit(false);

            },
            dispatch: (e) => {
                // dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                aeEnquiryId: 1,
                IsDDU: false,
                airFreight: [{
                    stockProvider: 1,
                    dueCarriers: [
                        {
                            selectForBuying: true,
                            airlineId: 1,
                            currency: 1,
                            buying: 1
                        }
                    ]
                }],
                otherCharges: [{
                    selectForBuying: true,
                    document: 'string',
                    vendorId: 1,
                    buying: 1
                }]
            },
            contenttype: true
        })
    }

    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            stockProvider: "",
            airFreight: []
        },
    ]);



    const addInputField = () => {
        setInputFields([...inputFields, { key: generateUniqueKey(), stockProvider: "", airFreight: [] }]);
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

        if (fieldToUpdate) {
            fieldToUpdate[field] = value;
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        stockProvider: res.stockProvider,
                        airFreight: res.airFreight

                    }
                )

            })
            // handleBasicInputChange(data, { id: 'AirFreightList' });
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    stockProvider: res.stockProvider,
                    airFreight: res.airFreight

                }
            )

        })
        // handleBasicInputChange(data, { id: 'AirFreightList' });
    };


    return <>
        <div className='my-4'>
            <div className="d-flex align-items-center">
                <input type="checkbox" className='me-3' />
                <h6 className="m-0">IN Case Of DDU</h6>
            </div>


            {inputFields.map((field, key) => (
                <div className="d-flex" key={key}>
                    <div >
                        {key === 0 ? <Button
                            sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px", marginRight: "10px" }} onClick={addInputField} variant="contained">
                            <Add />
                        </Button> : (
                            <Button
                                sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px", marginRight: "10px" }}
                                onClick={() => removeInputField(field.key)}
                                variant='contained'
                            >
                                <Close />
                            </Button>
                        )}
                    </div>
                    <div className='row w-100' key={field.key}>


                        <div className="col-md-12">
                            <div className="row ">

                                <div className="col-md-2">

                                    <Dropdown

                                        field={
                                            {
                                                id: "stockProvider",
                                                label: "IATA ",
                                                value: field.stockProvider,
                                                required: true,
                                                error: false,
                                                item: [
                                                    { name: "Self", value: 1 },
                                                    { name: "Other", value: 2 },
                                                ],
                                                onChange: (value, fieldChanges) => {
                                                    handleInputChange(field.key, fieldChanges.id, value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-6">
                                    <PricingDueCarriers
                                        airportItem={airportItem}
                                        countrycurrency={countrycurrency}
                                    />
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            ))}
        </div>


        <Button onClick={() => {
            HandleSubmit()
        }}>
            Submit
        </Button>

    </>

}





const PricingDueCarriers = ({ airportItem, countrycurrency }) => {
    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            stockProvider: ""
        },
    ]);



    const addInputField = () => {
        setInputFields([...inputFields, { key: generateUniqueKey(), stockProvider: "" }]);
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

        if (fieldToUpdate) {
            fieldToUpdate[field] = value;
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        stockProvider: res.stockProvider,

                    }
                )

            })
            // handleBasicInputChange(data, { id: 'AirFreightList' });
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    stockProvider: res.stockProvider,
                }
            )

        })
        // handleBasicInputChange(data, { id: 'AirFreightList' });
    };



    return <>


        {inputFields.map((field, key) => (
            <div className="d-flex" key={key}>
                <div >
                    {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px", marginRight: "10px" }} onClick={addInputField} variant="contained">
                        <Add />
                    </Button> : (
                        <Button
                            sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px", marginRight: "10px" }}
                            onClick={() => removeInputField(field.key)}
                            variant='contained'
                        >
                            <Close />
                        </Button>
                    )}
                </div>
                <div className='row w-100' key={field.key}>


                    <div className="col-md-12">
                        <div className="row ">

                            <div className="col-md-4">

                                <Dropdown

                                    field={
                                        {
                                            id: "stockProvider",
                                            label: "Airline ",
                                            value: field.stockProvider,
                                            required: true,
                                            error: false,
                                            item: airportItem,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-4">

                                <Dropdown

                                    field={
                                        {
                                            id: "stockProvider",
                                            label: "Currency ",
                                            value: field.stockProvider,
                                            required: true,
                                            error: false,
                                            item: countrycurrency,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className="col-md-4">

                                <InputField

                                    field={
                                        {
                                            id: "stockProvider",
                                            label: "Buying",
                                            value: field.stockProvider,
                                            type: "number",
                                            required: true,
                                            error: false,
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        ))}
    </>
}
import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { CustomerTable } from '../customer/customerTable';
import CustomerContactDetail from '../customer/customerContactDetail';
import CustomerBranch from '../customer/customerBranchForm';
import CircularWithValueLabel from '../../component/field/circleProgressBar';
import GetCaller from '../../../services/apiServices';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { GetConsigneeSubAgencyPage, PostMasterCity, PostMasterCountry, PostMasterState, UpdateConsigneeSubAgencyStatus } from '../../../services/constant';
import { GetAirExportPermanentJoblistByPage } from '../../../services/APIConstant/AirExport.jsx/finalJob';
import destination from '../../assets/img/icons/destination.png';
import { Edit } from '@mui/icons-material';
import { createjob } from '../../../navigation/constant';

const steps = [
    'Booking',
    'MAWB',
    'HAWB',
    'TC NO',
    'REC AT W/H',
    'SLOT',
    'REC AT AIRPORT',
    'REPORTE',
    'LEO',
    "X-RAY",
    'NOC',
    'H/O',
    'CHECKLIST AIRPORT'
];

const AirExportJob = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sectorMasterData, setSectorMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetAirExportPermanentJoblistByPage,
            callback: setTableDataHandler,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }



    useEffect(() => {
        fatch_MasterDataBYPagination();

        sessionStorage.removeItem('FinalJob');
    }, []);

    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            ...res,
            sn: index + 1,
            consoleNumber: res?.consoleNumber || 'N/A',
            apoltoapod: (
                <div className="apol_apod">
                    {res?.airportOfLoadingName} <img style={{ width: "20px", padding: "0 2px" }} src={destination} /> {res?.airportOfDestinationName}
                </div>
            ),
            shipperInvoiceNumber: '-',
            invoiceNumber: '-',
        }));

        setSectorMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords || 0)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
        {
            id: 'consoleNumber',
            label: 'Console No.',
            numeric: false,
        },
        {
            id: 'id',
            label: 'Job No.',
            numeric: false,
        },

        {
            id: 'shipperName',
            label: 'Shipper Name',
            numeric: false,
        },
        {
            id: 'apoltoapod',
            label: 'APOL/APOD',
            numeric: false,
        },
        {
            id: 'airlineName',
            label: 'Airline',
            numeric: false,
        },
        {
            id: 'allotMawbNumber',
            label: 'MAWB No.',
            numeric: false,
        },
        {
            id: 'allotHawbNumber',
            label: 'HAWB No.',
            numeric: false,
        },
        {
            id: 'packages',
            label: 'Package',
            numeric: false,
        },
        {
            id: 'grossWeight',
            label: 'Gross Wt.',
            numeric: false,
        },
        {
            id: 'airVolumeWeight',
            label: 'Vol Wt.',
            numeric: false,
        },


        {
            id: 'shipperInvoiceNumber',
            label: 'SHP. INV.',
            numeric: false,
        },
        {
            id: 'invoiceNumber',
            label: 'Invoice No.',
            numeric: false,
        },
        {
            id: 'createdDate',
            label: 'Job DT.',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        {
            id: 'subtable',
            label: 'Action',
            numeric: false,
        },
    ];

    const ActionCell = ({ row }) => {

        return <>
            <Tooltip title="Update Job">
                <IconButton
                    className='tablebtn1'
                    onClick={() => {
                        sessionStorage.setItem('FinalJob', row.id)
                        navigate(createjob);
                    }}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >
        </>

    }

    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <Tooltip title="Add Job">
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(createjob)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }
    const conditions = {

    }



    const SubTableCell = () => {
        return <>
            <Box sx={{ width: '100%', marginTop: "20px", marginBottom: '20px' }}>
                <Stepper activeStep={1} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </>
    }


    return (
        <>

            <CustomerTable
                data={sectorMasterData}
                conditions={conditions}
                columns={columns}
                title={'Export Job'}
                tabletool={TableTool}
                actioncell={ActionCell}
                subTableCell={SubTableCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default AirExportJob;








import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import { GetMasterAreaByPage, PostMasterArea, UpdateMasterArea, UpdateMasterAreaStatus } from '../../../services/constant';

import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { columnConstant } from '../../constant/formconstant';
import { SmallSwitch } from '../../component/controls';
import { trimInputvalue } from '../../../services/utilities/unittrim';

const Areamaster = () => {
  const dispatch = useDispatch();
  const [masterareadata, setMasterareadata] = useState([])
  const [totaldatalength, setTotalDataLength] = useState(0)

  const fatch_MasterDataBYPagination = (item) => {
    MasterCallBypagination({
      API: GetMasterAreaByPage,
      callback: setTableDataHandler,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: item
    })
  }

  useEffect(() => {
    fatch_MasterDataBYPagination();
  }, []);

  const setTableDataHandler = ({ response }) => {
    const newMasterAreaData = response?.data?.data?.records?.map((res, index) => ({
      sn: 'd',
      id: res.id,
      name: res.name,
      areacode: res.id,
      status: res.status
    }));
    setMasterareadata(newMasterAreaData);
    setTotalDataLength(response?.data?.data?.totalRecords || 0)
  }

  const columns = [
    {
      id: 'sn',
      label: columnConstant.sno,
      numeric: false,
    },
    {
      id: 'name',
      label: columnConstant.areaname,
      numeric: false,
    },

    {
      id: 'action',
      label: columnConstant.action,
      numeric: false,
    },
  ];

  const ActionCell = ({ row }) => {
    const handleSwitchChange = () => {
      const data = {
        id: row?.id,
      }
      GetCaller({
        API: UpdateMasterAreaStatus,
        dispatch: (e) => {
          dispatch({ type: e.type, payload: e.payload })
        },
        item: data,
        callback: ({ response }) => {
          fatch_MasterDataBYPagination();
        }

      });
    }
    return <>
      <AreaDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
      <SmallSwitch
        onChange={handleSwitchChange}
        checked={row?.status}
      />
    </>
  }

  const TableTool = ({ }) => {
    return <>
      <AreaDrawer callAPI={fatch_MasterDataBYPagination} />
    </>
  }
  return (
    <>

      <MultiTable
        data={masterareadata}
        columns={columns}
        //  drawerFormFields={drawerFormFields}
        title={columnConstant.area}
        tabletool={TableTool}
        actioncell={ActionCell}
        totaldatalength={totaldatalength}
        paginationhandler={fatch_MasterDataBYPagination}

      />


    </>
  )
}

export default Areamaster;

const AreaDrawer = ({ callAPI, editor, row }) => {
  const dispatch = useDispatch()

  const [closeValidator, setcloseValidator] = useState(false)
  const [areaName, setAreaName] = useState(row?.name || '')
  const [areaNameError, setAreaNameError] = useState(false)


  const drawerOpenCallbackHandler = () => {
    setAreaName(row?.name || '')
    setAreaNameError(false)
  }


  useEffect(() => {
    setcloseValidator(false)
    if (editor) {
      setAreaName(row?.name)
    } else {
      setAreaName('')
    }
  }, [closeValidator]);

  const handleSubmit = () => {
    const areavalue = trimInputvalue(areaName)

    if (!areavalue) {
      setAreaNameError(!areavalue)
    }
    else {
      console.log(areavalue)
      const postmasterareaData = editor ? {
        id: row.id,
        name: areavalue,
        status: row.status
      } : {
        name: areavalue,
      }



      PostCaller({
        API: editor ? UpdateMasterArea : PostMasterArea,
        callback: () => callAPI(),
        dispatch: (e) => {
          dispatch({ type: e.type, payload: e.payload })
        },
        item: postmasterareaData
      })

      setcloseValidator(true)

    }
  }

  const handleInputChange = (value, field) => {
    switch (field.id) {
      case 'name':
        setAreaName(value)
        setAreaNameError(!value);
        break;
      default:
        break;
    }
  };

  const drawerFormFields = editor ?
    {
      title: columnConstant.editareamaster,
      icon: <i className="las la-edit"></i>,
      tooltitle: columnConstant.editareamaster,

      iconclass: 'noClass',
      defaultbtnfalse: true

    }
    : {
      title: columnConstant.addareamaster,
      tooltitle: columnConstant.addareamaster,
      defaultbtnfalse: true
    }

  const FieldData = [
    // {
    //   id: 'sector',
    //   label: columnConstant.sectortype,
    //   item: sectorItem,
    //   type: 'select',
    //   value: sectorType,
    //   required: true,
    //   error: sectorTypeError,
    //   onChange: handleInputChange,
    // },
    {
      id: 'name',
      label: columnConstant.areaname,
      type: 'text',
      value: areaName,
      required: true,
      error: areaNameError,
      onChange: handleInputChange,
    },




  ];

  const divisionDrawerody = () => {
    return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
  }
  return <>
    <DivisonDrawer
      drawerFormFields={drawerFormFields}
      closeValidator={closeValidator}
      openCallBack={drawerOpenCallbackHandler}
      divisiondrawerbody={divisionDrawerody}
    />

  </>

}


import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { PostAirExportBuyingingCharges, PostAirExportSellingCharges } from "../../../../services/APIConstant/enquiryConstant";
import { PostCaller } from "../../../../services/apiServices";
import Dropdown, { InputField, InputFileField, TextAreafield } from "../../../component/controls";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { ApplicableOn, IntialStockProvider } from "../../../../services/modalData";
import { Add, Close } from "@mui/icons-material";
import Transition from "../../../../services/utilities/transition";

export const File_Upload = ({ callBack, data, fieldData }) => {
    const [open, setOpen] = React.useState(false);
    const [mawbRateData, setMAWBRateData] = useState(data)
    const [dueCarrierDetailsError, setDueCarrierDetailsError] = useState({});
    const handleClickOpen = () => {
        setOpen(true);
        debugger
        if (data?.length !== 0) {
            let recieveData = data?.map((res) => (
                {
                    key: generateUniqueKey(), // Add a unique key for each input field
                    id: res?.id || null,
                    file: res.file,
                }
            ))

            setInputFields(recieveData)
        }
        else {
            setInputFields([
                {
                    key: generateUniqueKey(), // Add a unique key for each input field
                    file: '',
                }
            ])
        }
    };

    const handleClose = () => {
        setOpen(false);
        callBack([], 'files')
        setMAWBRateData([])
    }
    const handleSubmit = () => {

        const DueCarrierhasUndefinedValue = (arraydata) => {
            debugger
            let array = arraydata.length !== 0 ? arraydata : [
                {
                    file: "",
                }]

            let changes = {};
            array?.map((obj, index) => {
                debugger
                Object.entries(obj).forEach(([key, value]) => {
                    if (
                        !value && (
                            key === 'file'
                        )) {
                        changes[key + index] = !value;
                    }
                });
            })
            console.log('changes', changes)

            return changes;
        };

        const DueCarrierChanges = DueCarrierhasUndefinedValue(mawbRateData)
        debugger
        if (Object.keys(DueCarrierChanges).length > 0) {
            setDueCarrierDetailsError(DueCarrierChanges)
        } else {
            callBack(mawbRateData, 'files')
            setOpen(false);
        }
    }
    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            file: ""
        },
    ]);



    const addInputField = () => {
        setInputFields([...inputFields, {
            key: generateUniqueKey(),
            file: ''
        }]);
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

        if (fieldToUpdate) {
            fieldToUpdate[field] = value;

            setDueCarrierDetailsError({
                ...dueCarrierDetailsError,
                [field + index]: !value
            })

            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields?.map((res) => {
                return data.push(
                    {
                        id: res?.id || null,
                        file: res.file,
                    }
                )

            })
            setMAWBRateData(data)
            callBack(data, 'files')
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields?.map((res) => {
            return data.push(
                {
                    id: res?.id || null,
                    file: res?.file
                }
            )

        })
        setMAWBRateData(data)
        callBack(data, 'files')

    };

    return <>

        <div className=''>

            {inputFields?.map((field, key) => (
                <div className='row' key={field.key}>
                    <div className="col-md-2 cmd" style={{ justifyContent: "left", alignItems: "flex-start" }}>
                        {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                            <Add />
                        </Button> : (
                            <Button
                                sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                onClick={() => removeInputField(field.key)}
                                variant='contained'
                            >
                                <Close />
                            </Button>
                        )}
                    </div>
                    <div className="col-md-10 ">

                        <div className="row mb-3">
                            <div className="col-12">
                                <InputFileField
                                    field={
                                        {
                                            id: "file",
                                            label: "File",
                                            type: 'file',
                                            value: field.file,
                                            required: true,
                                            error: dueCarrierDetailsError['rate' + key],
                                            onChange: (value, fieldChanges) => {
                                                handleInputChange(field.key, fieldChanges.id, value, key);
                                            }
                                        }
                                    }
                                />
                            </div>
                        </div>


                    </div>
                </div>


            ))}
        </div >

    </>
}
import React, { useEffect, useState } from "react";
import Dropdown from "../../component/controls";
import EnquiryAirExport from "./airexport";
import { AirExportCustomerTypeFrightOptions, FreightTypeDirectType, FreightTypeFPC, FreightTypeODC, FreightTypeONP, FreightTypeSubAgent, FreightTypeSubType, TypeOfCustomer, TypeOfFPC, TypeofShipment } from "../../../services/modalData";
import EnquiryAirImport from "./airImport";
import getFrieghtServices from "../../../services/Frieght Services/AirExportFreightServices/getFrieghtServices";
import GetSelectedFreightDetails from "../../../services/Frieght Services/AirExportFreightServices/GetSelectedFreightDetails";
import GetCaller from "../../../services/apiServices";
import { GetMasterBranchByPage } from "../../../services/constant";
import AlertDialogBox from "../../component/tabs/alertDialog";

const EnquiryLayout = () => {
    const [iscustomerAndFreightTypeAlert, setIscustomerAndFreightTypeAlert] = useState(false)

    const [FreightItems, setFreightItems] = useState([])
    const [FreightServiceSubType, setFreightServiceSubType] = useState([])
    const [BizType, setBizType] = useState([])
    const [BizSubType, setBizSubType] = useState([])
    const [BizCategoryType, setBizCategoryType] = useState([])

    const [MultiPickUp, setMultiPickUp] = useState(false)
    const [MultiDelivery, setMultiDelivery] = useState(false)

    const [EnquiryFormData, setEnquiryFormData] = useState({
        enquiryType: 1,
        CustomerType: "",
        FreightServiceType: "",
        FreightServiceSubType: "",
        BizCategoryType: "",
        BizType: "",
        BizSubType: ""
    })

    useEffect(() => {
        if (EnquiryFormData?.CustomerType === 1 && EnquiryFormData?.FreightServiceType === 'Port to Port -PP/CC & CC Only') {
            setIscustomerAndFreightTypeAlert(true)
        } else {
            setIscustomerAndFreightTypeAlert(false)
        }
    }, [EnquiryFormData])

    const handleBasicInputChange = (value, field) => {
        debugger
        setEnquiryFormData({
            ...EnquiryFormData,
            [field.id]: value,
            [field.id + 'error']: !value,
        })
        console.log(field.id, value)
    }

    function hasUndefinedValues(obj) {
        let changes = {};

        Object.entries(obj).forEach(([key, value]) => {
            if (!value && (
                key === 'CustomerType' ||
                key === 'FreightServiceType'
            )) {
                changes[key + 'error'] = !value;
            }
        });

        console.log(changes)
        return changes;
    }
    const handleFormValidation = () => {
        const EnquiryFormChanges = hasUndefinedValues(EnquiryFormData)
        setEnquiryFormData({
            ...EnquiryFormData,
            ...EnquiryFormChanges
        })

    }


    const [branchitem, setBranchTypeItem] = useState([]);

    const set_BranchItemData = () => {

        GetCaller({
            API: GetMasterBranchByPage,
            dispatch: (e) => {
                // dispatch({ type: e.type, paylode: e.paylode })
            },
            item: { status: true },
            callback: ({ response }) => {
                const newBranchItemData = response?.data?.data?.records.map((item) => ({

                    value: item.id,
                    name: item.name,
                }))

                setBranchTypeItem(newBranchItemData)
            }
        })

    }


    useEffect(() => {
        set_BranchItemData()
        const airExportEnquiryData = JSON.parse(sessionStorage.getItem('airExportEnquiryData'));
        if (airExportEnquiryData) {

            debugger
            let filteredfreightItem = []
            filteredfreightItem = AirExportCustomerTypeFrightOptions?.find(item => item.value === airExportEnquiryData?.customerType)
            setFreightItems(filteredfreightItem?.FrightServiceType);
            setFreightServiceSubType(filteredfreightItem?.FrightServiceType[0]?.FreightServiceSubType)
            setBizCategoryType(filteredfreightItem?.FrightServiceType[0]?.BizCategoryType)

            let filteredBizCategoryItem = filteredfreightItem?.FrightServiceType[0]?.BizCategoryType?.find(item => item?.name === airExportEnquiryData?.bizCategoryType)
            setBizType(filteredBizCategoryItem?.BizType)

            let filteredBizTypeItem = filteredBizCategoryItem?.BizType?.find(item => item?.name === (airExportEnquiryData?.bizType === null ? 'N/A' : airExportEnquiryData?.bizType))
            setBizSubType(filteredBizTypeItem?.BizSubType)

            setEnquiryFormData({
                ...EnquiryFormData,
                ['CustomerType']: airExportEnquiryData?.customerType,
                ['FreightServiceType']: airExportEnquiryData?.freightServiceType,
                ['FreightServiceSubType']: airExportEnquiryData?.freightServiceSubType,
                ['BizCategoryType']: airExportEnquiryData?.bizCategoryType,
                ['BizType']: airExportEnquiryData?.bizType,
                ['BizSubType']: airExportEnquiryData?.bizSubType,
            })

        }

    }, [])
    console.log("enquiry common data", EnquiryFormData)





    const HandleCustomerTypeChanges = (value, field) => {
        let option = {
            [field.id]: value,
            [field.id + 'error']: !value,
            ['FreightServiceType']: "",
            ['FreightServiceSubType']: "",
            ['BizCategoryType']: "",
            ['BizType']: "",
            ['BizSubType']: '',
            ['FreightServiceTypeerror']: false,
            ['FreightServiceSubTypeerror']: false,
            ['BizCategoryTypeerror']: false,
            ['BizTypeerror']: false,
            ['BizSubTypeerror']: false
        }

        setFreightItems(getFrieghtServices(value));
        setFreightServiceSubType([]);
        setMultiDelivery(false);
        setMultiPickUp(false);
        setBizCategoryType([]);
        setBizType([]);
        setBizSubType([]);

        return option;
    }

    const HandleFreightServiceTypeChanges = (value, field) => {

        let option = {
            [field.id]: value,
            [field.id + 'error']: !value,
            ['BizCategoryType']: '',
            ['BizCategoryTypeerror']: false,
            ['BizType']: '',
            ['BizTypeerror']: false,
            ['BizSubType']: '',
            ['BizSubTypeerror']: false,
        }

        const FreightDetails = GetSelectedFreightDetails(EnquiryFormData?.CustomerType, value)
        setMultiDelivery(FreightDetails?.MultiDelivery)
        setMultiPickUp(FreightDetails?.MultiPickUp)
        setFreightServiceSubType(FreightDetails?.FreightServiceSubType)


        setBizCategoryType(FreightDetails?.BizCategoryType)
        if (FreightDetails?.BizCategoryType?.length === 1) {
            option.BizCategoryType = FreightDetails?.BizCategoryType[0]?.name
        }

        setBizType(FreightDetails.BizType)
        if (FreightDetails?.BizType?.length === 1) {
            option.BizType = FreightDetails?.BizType[0]?.name
        }

        setBizSubType(FreightDetails.BizSubType)
        if (FreightDetails?.BizSubType?.length === 1) {
            option.BizSubType = FreightDetails?.BizSubType[0]?.name
        }

        return option;
    }


    const HandleBizCategoryChanges = (value, field) => {

        let option = {
            [field.id]: value,
            [field.id + 'error']: !value,
            ['BizType']: '',
            ['BizTypeerror']: false,
            ['BizSubType']: '',
            ['BizSubTypeerror']: false,
        }

        const FreightDetails = GetSelectedFreightDetails(EnquiryFormData?.CustomerType, EnquiryFormData?.FreightServiceType, value)

        setBizType(FreightDetails.BizType)
        if (FreightDetails?.BizType?.length === 1) {
            option.BizType = FreightDetails?.BizType[0]?.name
        }

        setBizSubType(FreightDetails.BizSubType)
        if (FreightDetails?.BizSubType?.length === 1) {
            option.BizSubType = FreightDetails?.BizSubType[0]?.name
        }

        return option;
    }

    const HandleBizTypeChanges = (value, field) => {

        let option = {
            [field.id]: value,
            [field.id + 'error']: !value,
            ['BizSubType']: '',
            ['BizSubTypeerror']: false,
        }

        const FreightDetails = GetSelectedFreightDetails(EnquiryFormData?.CustomerType, EnquiryFormData?.FreightServiceType, EnquiryFormData?.BizCategoryType, value)

        setBizSubType(FreightDetails.BizSubType)
        if (FreightDetails?.BizSubType?.length === 1) {
            option.BizSubType = FreightDetails?.BizSubType[0]?.name
        }

        return option;
    }


    return <>
        <div className="hr-form-box">
            <h5>
                Air Export Enquiry
            </h5>
            <div className="row">

                {/* <div className="col-md-2">
                    <Dropdown
                        field={
                            {
                                id: "enquiryType",
                                label: "Type Of Shipment ",
                                value: EnquiryFormData.enquiryType,
                                required: true,
                                error: EnquiryFormData.enquiryTypeerror,
                                helperText: 'Inco Terms Required',
                                // item: TypeofShipment,
                                item: [{
                                    name: 'Air Export', value: 1
                                }],
                                onChange: handleBasicInputChange
                            }
                        }
                    />

                </div> */}
                {
                    EnquiryFormData.enquiryType &&
                    <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "CustomerType",
                                    label: "Customer Type ",
                                    value: EnquiryFormData.CustomerType,
                                    required: true,
                                    error: EnquiryFormData.CustomerTypeerror,
                                    helperText: 'Customer Type Required',
                                    item: AirExportCustomerTypeFrightOptions,
                                    onChange: (value, field) => {
                                        const data = HandleCustomerTypeChanges(value, field)
                                        setEnquiryFormData({
                                            ...EnquiryFormData,
                                            ...data
                                        })
                                    }
                                }
                            }
                        />

                    </div>
                }

                {
                    EnquiryFormData.CustomerType === 5 &&
                    <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "FPCCustomerType",
                                    label: "FPC Customer Type ",
                                    value: EnquiryFormData.FPCCustomerType,
                                    required: true,
                                    error: EnquiryFormData.FPCCustomerTypeerror,
                                    helperText: 'Customer Type Required',
                                    item: TypeOfFPC,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />

                    </div>
                }


                {
                    EnquiryFormData.CustomerType && FreightItems?.length !== 0 && < div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "FreightServiceType",
                                    label: "Freight/Service Type ",
                                    value: EnquiryFormData.FreightServiceType,
                                    required: true,
                                    error: EnquiryFormData.FreightServiceTypeerror,
                                    helperText: 'Freight/Service Type  Required',
                                    item: FreightItems,
                                    onChange: (value, field) => {
                                        const data = HandleFreightServiceTypeChanges(value, field)
                                        setEnquiryFormData({
                                            ...EnquiryFormData,
                                            ...data
                                        })
                                    }
                                }
                            }
                        />

                    </div>
                }

                {

                    FreightServiceSubType?.length !== 0 && <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "FreightServiceSubType",
                                    label: "Freight/Service Sub Type ",
                                    value: EnquiryFormData.FreightServiceSubType,
                                    required: true,
                                    error: EnquiryFormData.FreightServiceSubTypeerror,
                                    helperText: 'Freight ServiceSubType Required',
                                    item: FreightServiceSubType,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />

                    </div>

                }


                {

                    BizCategoryType?.length !== 0 && <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "BizCategoryType",
                                    label: "Biz Category Type ",
                                    value: EnquiryFormData.BizCategoryType,
                                    required: true,
                                    error: EnquiryFormData.BizCategoryTypeerror,
                                    helperText: 'Inco Terms Required',
                                    item: BizCategoryType,
                                    disable: BizCategoryType?.length === 1 ? true : false,
                                    onChange: (value, field) => {

                                        const data = HandleBizCategoryChanges(value, field)
                                        setEnquiryFormData({
                                            ...EnquiryFormData,
                                            ...data
                                        })
                                    }
                                }
                            }
                        />
                    </div>
                }

                {
                    BizType?.length !== 0 && (EnquiryFormData.BizType !== 'N/A') && <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "BizType",
                                    label: "Biz Type ",
                                    value: EnquiryFormData.BizType,
                                    required: true,
                                    error: EnquiryFormData.BizTypeerror,
                                    helperText: 'Inco Terms Required',
                                    disable: BizType?.length === 1 ? true : false,
                                    item: BizType,
                                    onChange: (value, field) => {

                                        const data = HandleBizTypeChanges(value, field)
                                        setEnquiryFormData({
                                            ...EnquiryFormData,
                                            ...data
                                        })
                                    }
                                }
                            }
                        />

                    </div>
                }

                {
                    BizSubType?.length !== 0 && <div className="col-md-2">
                        <Dropdown
                            field={
                                {
                                    id: "BizSubType",
                                    label: "Biz Sub Type ",
                                    value: EnquiryFormData.BizSubType,
                                    required: true,
                                    error: EnquiryFormData.BizSubTypeerror,
                                    helperText: 'Inco Terms Required',
                                    item: BizSubType,
                                    disable: BizSubType?.length === 1 ? true : false,
                                    onChange: handleBasicInputChange
                                }
                            }
                        />

                    </div>
                }

            </div>
        </div >

        <div>
            {
                EnquiryFormData.enquiryType === 1 && <EnquiryAirExport
                    MultiPickUp={MultiPickUp}
                    MultiDelivery={MultiDelivery}
                    CommonFormData={EnquiryFormData}
                    BizCategoryTypeItem={BizCategoryType}
                    BizSubTypeItem={BizSubType}
                    BizTypeItem={BizType}
                    FreightServiceSubTypeItem={FreightServiceSubType}
                    FreightItemsItem={FreightItems}
                    callBack={handleFormValidation}
                />
            }
            {EnquiryFormData.enquiryType === 2 && <EnquiryAirImport
                MultiPickUp={MultiPickUp}
                MultiDelivery={MultiDelivery}
                CommonFormData={EnquiryFormData}
                BizCategoryTypeItem={BizCategoryType}
                BizSubTypeItem={BizSubType}
                BizTypeItem={BizType}
                FreightServiceSubTypeItem={FreightServiceSubType}
                FreightItemsItem={FreightItems}
                callBack={handleFormValidation}


            />}






            <AlertDialogBox
                style={{ textAlign: "center", display: "flex", justifyContent: "center" }}
                open={iscustomerAndFreightTypeAlert}
                title={`Please change customer type`}
                description={
                    <div style={{ width: "400px", textAlign: "left", }}>
                        <Dropdown
                            field={
                                {
                                    id: "CustomerType",
                                    label: "Customer Type ",
                                    value: EnquiryFormData.CustomerType,
                                    required: true,
                                    error: EnquiryFormData.CustomerTypeerror,
                                    helperText: 'Customer Type Required',
                                    item: [
                                        { name: "Overseas Network Partner", value: 3 },
                                        { name: "Overseas Direct Customer", value: 4 }
                                    ],
                                    onChange: (value, field) => {
                                        const data = HandleCustomerTypeChanges(value, field)
                                        setEnquiryFormData({
                                            ...EnquiryFormData,
                                            ...data
                                        })
                                    }
                                }
                            }
                        />
                    </div>
                }
            // handleClose={() => {
            //     // setOpenDeleteDialogBox(false);

            // }}
            // handleSubmit={() => {

            // }}
            />
        </div>

    </>

}

export default EnquiryLayout;
import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { GetMasterCountryByPage, GetMasterStateByPage, PostMasterCountry, PostMasterState, UpdateMasterCountryStatus, UpdateMasterState, UpdateMasterStateStatus } from '../../../services/constant';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import DivisonDrawer from '../../component/divisionDrawer';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';
import { trimInputvalue } from '../../../services/utilities/unittrim';

const State = () => {
    const dispatch = useDispatch()
    const [stateMasterData, setStateMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const [countryItem, setCountryItem] = useState([])

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterStateByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    const set_CountryItemData = () => {
        GetCaller({
            API: GetMasterCountryByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                let filteredItems = [];
                const newCountryItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setCountryItem(newCountryItemData)
            },

        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
        set_CountryItemData()
    }, []);

    const setTableDataHandler = ({ response }) => {
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            state: res.name,
            statecode: res.stateCode,
            country: res.country?.value,
            countryid: res.country?.key,
            gstcode: res.gstCode,
            status: res.status
        }));
        setStateMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'state',
            label: 'State Name',
            numeric: false,
        },
        {
            id: 'statecode',
            label: 'State Code',
            numeric: false,
        },
        {
            id: 'gstcode',
            label: 'Gst Code',
            numeric: false,
        },

        {
            id: 'country',
            label: 'Country Name',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },

        // You can add more column definitions as needed
    ];


    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateMasterStateStatus,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({ response }) => {
                    fatch_MasterDataBYPagination();
                }

            });
        }
        return <>
            <StateDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} countryItem={countryItem} />
            <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>

    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <StateDrawer callAPI={fatch_MasterDataBYPagination} countryItem={countryItem} />
        </>
    }

    return (
        <>
            {/* filterItems={filterItems} */}
            <MultiTable

                data={stateMasterData}
                columns={columns}
                title={'State'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default State;




const StateDrawer = ({ callAPI, editor, row, countryItem }) => {
    const [closeValidator, setcloseValidator] = useState(false)
    const [stateName, setStateName] = useState(row?.state || '')
    const [stateNameError, setStateNameError] = useState(false)
    const [stateCode, setStateCode] = useState(row?.statecode || '')
    const [stateCodeError, setStateCodeError] = useState(false)
    const [countryType, setCountryType] = useState(row?.countryid || '')
    const [countryTypeError, setCountryTypeError] = useState(false)
    const [gstCode, setGstCode] = useState(row?.gstcode || '')
    const [gstCodeError, setGstCodeError] = useState(false)
    const dispatch = useDispatch()
    const drawerOpenCallbackHandler = () => {
        setStateName(row?.state || '')
        setStateCode(row?.statecode || '')
        setCountryType(row?.countryid || '')
        setGstCode(row?.gstcode || '')
        setStateNameError(false)
        setCountryTypeError(false)
        setGstCodeError(false)
        setStateCodeError(false)
    }



    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);


    const handleSubmit = () => {
const trimStateCode = trimInputvalue(stateCode)
const trimStateName = trimInputvalue(stateName)
const trimGstCode = trimInputvalue(gstCode)


        if (!trimStateCode || !trimStateName || !countryType || !trimGstCode) {
            setStateNameError(!trimStateName)
            setStateCodeError(!trimStateCode)
            setCountryTypeError(!countryType)
            setGstCodeError(!trimGstCode)
        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: trimStateName,
                gstCode: trimGstCode,
                stateCode: trimStateCode,
                countryId: countryType,
                status: row.status
            }:{
                name: trimStateName,
                gstCode: trimGstCode,
                stateCode: trimStateCode,
                countryId: countryType
            }

            editor ?
                PostCaller({
                    API: UpdateMasterState,
                    callback: () => callAPI(),
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: postmasterareaData
                })

                :

                PostCaller({
                    API: PostMasterState,
                    callback: () => callAPI(),
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: postmasterareaData
                })
            setcloseValidator(true)

        }

    }


    const handleInputChange = (value, field) => {

        switch (field.id) {
            case 'state':
                setStateName(value)
                setStateNameError(!value);
                break;
            case 'statecode':
                setStateCode(value)
                setStateCodeError(!value);
                break;
            case 'gstcode':
                setGstCode(value)
                setGstCodeError(!value);
                break;
            case 'country':
                setCountryType(value)
                setCountryTypeError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit State",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit State',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add State",
            tooltitle: 'Add State',
            defaultbtnfalse: true
        }





    const FieldData = [


        {
            id: 'state',
            label: 'State Name',
            type: "text",
            value: stateName,
            error: stateNameError,
            required: true,
            helperText: 'State Name required',
            onChange: handleInputChange,
        },
        {
            id: 'statecode',
            label: 'State Code',
            type: "text",
            value: stateCode,
            error: stateCodeError,
            required: true,
            helperText: "State Code required",
            onChange: handleInputChange,
        },

        {
            id: 'gstcode',
            label: 'Gst Code',
            type: "text",
            value: gstCode,
            error: gstCodeError,
            required: true,
            helperText: "Gst Code required",
            onChange: handleInputChange,
        },
        {
            id: 'country',
            label: 'Country',
            type: "select",
            value: countryType,
            error: countryTypeError,
            required: true,
            helperText: "Country required",
            item: countryItem,
            onChange: handleInputChange,

        },

    ];



    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            divisiondrawerbody={divisionDrawerody}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
        />

    </>

}














import React from "react";
import { columnConstant } from "../../constant/formconstant";
import { MultiTable } from "../../component/multiTable";
import { useState } from "react";
import companylogo from '../../assets/img/avatars/logonew.jpg'

export const InlinePdfAir = () => {

 
    return <>

        <div className="hr-form-box">

            <div style={{ border: '2px solid #ddd', borderRadius: "8px" }} >
                <div className=" " style={{ justifyContent: 'space-between', display: 'flex', padding: '10px', marginTop:'8px' }}>
                    <div>
                        <h3 style={{
                             color: "#A3A0A0", fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', fontSize:'28px'
                        }}><span style={{ fontWeight: 'bold', color: '#7352C7' }} >Agrastha</span>Freight Pvt Ltd.</h3>
                        <p style={{ fontSize: '13px', fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', padding:'0px' }} ><span><i className="las la-map-marker-alt" style={{ marginRight: '5px' }} ></i>Floor, 6 Club Drive Road,Ghitorni, New Delhi-110030, India</span></p>
                        <p style={{ fontSize: '13px', fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', padding: '0px' }}><span style={{ color: '#7352C7' }}><i className="las la-phone" ></i></span> +91-9772299018, 874399018</p>
                        <div className="" style={{ display: 'flex' }} >
                            <p className="" style={{ fontSize: '13px', fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', padding: '0px' }} ><span style={{ color: '#7352C7' }}><i className="las la-envelope" ></i></span>info@agrastha.in</p>
                            <p style={{ fontSize: '13px', fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', marginLeft: '3px' }}><span style={{ color: '#7352C7', marginLeft:'2px' }} ><i className="fas fa-globe" ></i></span>www.agrastha.in</p>
                        </div>
                    </div>
                    <div className="">
                        <img style={{ height: '94px' }} src={companylogo} />
                        <div style={{ color:'#7352C7'}} > <i>"We buy problems and sell solutions"</i></div>
                        <p style={{ fontSize: '13px', fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',color:'#555' }}> An ISO 9001: 2016 Company<br />
                            CIN#U60231DL2004PTC124368<br />
                            GSTIN: 07AAHCS9096G1ZT
                        </p>
                    </div>
                </div>

                <div className="">
                    <h6 className="" style={{ fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', color: '#212529', fontSize: '16px', marginTop: '5px', marginBottom: '20px', textAlign: 'center' }}><b>AIR IMPORT COST OFFER</b></h6>
                </div>
                <div className='' style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', justifyContent: 'space-between' }}>
                    <p><span style={{ fontSize: '16px', fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', color: '#212529' }}><b>Enquiry Id/Enquiry Date</b></span><span style={{ fontSize: '16px',color:'#808080'}}> -AIDEL00001/20-May-2021 </span></p>
                    <h6 style={{ fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', color: '#212529', fontSize: '16px' }}><b>SHIPMENT DETAILS</b></h6>
                    <p><span style={{ fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', color: '#212529', fontSize: '16px' }}><b>Quotation No.</b></span><span style={{ fontSize: '16px', color: '#808080' }}> -QTDEL00001</span></p>

                </div>
                <div style={{display: "flex", width: "100%", margin: "0px"}}>
    <div style={{width: "25%", padding: "0px"}}>
        <div style={{textAlign: "left"}}>
            <table style={{borderCollapse: "collapse", width:"100%", border: "1px solid #ddd"}}>
                <tr>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Packages</td>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>10</td>
                </tr>
                <tr>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Chargeable Weight</td>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>20</td>
                </tr>
                <tr>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Commodity</td>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Product A</td>
                </tr>
            </table>
        </div>
    </div>

    <div style={{width: "25%", padding: "0px"}}>
        <div style={{textAlign: "left"}}>
            <table style={{borderCollapse: "collapse", width:"100%"}}>
                <tr>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Gross Weight</td>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>30</td>
                </tr>
                <tr>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Airport of Loading</td>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Airport A</td>
                </tr>
                <tr>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Pickup Address</td>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>123 Main St.</td>
                </tr>
            </table>
        </div>
    </div>

    <div style={{width: "25%", padding: "0px"}}>
        <div style={{textAlign: "left"}}>
            <table style={{borderCollapse: "collapse", width:"100%"}}>
                <tr>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Air Volume Weight (CBM)</td>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>40</td>
                </tr>
                <tr>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Airport of Destination</td>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Airport B</td>
                </tr>
                <tr>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Delivery Address</td>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>456 Elm St.</td>
                </tr>
            </table>
        </div>
    </div>
    <div style={{width: "25%", padding: "0px"}}>
        <div style={{textAlign: "left"}}>
            <table style={{borderCollapse: "collapse", width:"100%"}}>
                <tr>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Incoterms</td>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>CIF</td>
                </tr>
                <tr>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>Exchange Rate</td>
                    <td style={{border: '1px solid #ddd', padding: '5px',fontSize:'13px'}}>1.2</td>
                </tr>
            </table>
        </div>
    </div>
</div>



                <div className="">
                    <div className="" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', marginTop:'15px' }}>
                        <h6 className="" style={{ fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', color: '#212529', fontSize: '16px' }}><b>AIR FREIGHT</b></h6>
                    </div>
                    <div className=''>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.airlines}</th>
                                    <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.chargehead}</th>
                                    <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.currency}</th>
                                <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.rate}</th>
                            <th  style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.unit}</th>
                        <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.quantity}</th>
                    <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.amount}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>{<input type="checkbox" className='' />}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>Air Freight</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>Due Carrier</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>Air Freught</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>Due Carrier</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>100</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>10</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="">
                    <div className="" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                        <h6 className="" style={{ fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', color: '#212529', fontSize: '16px', marginTop: '15px' }}><b>OTHER LOCAL CHARGES</b></h6>
                    </div>
                    <div className='' style={{ overflow: 'hidden', maxHeight: '500px', transition: 'max-height 0.3s ease' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>

                                    <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.sno}</th>
                                    <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.chargehead}</th>
                                    <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.currency}</th>
                                     <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.rate}</th>
                                     <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.unit}</th>
                                    <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.quantity}</th>
                                     <th style={{ border: '1px solid #ddd', padding: '15px 8px', textAlign: 'center', fontSize: '12px' }}>{columnConstant.amount}</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>

                                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>1.</td>
                                   <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>Acmes charges</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>usd</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>128.00</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>packages</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>90</td>
                                    <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center', fontSize: 'small', }}>5322240.00</td>
                                </tr>



                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="" style={{ paddingLeft: '20px', paddingTop: '20px', paddingBottom:'20px' }} >
                    <p style={{ fontSize: '13px', color: '#555555', fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif' }} ><b>TERMS & CONDITION </b></p>
                    <div className="" style={{ paddingLeft: "28px", }} >
                        <ul style={{ listStyle: 'none', paddingLeft:'25px' }} >
                        <li className="" style={{ fontSize: '13px', color: '#555555', padding:'0px', fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', color: '#212529', }} ><b>1. Other Charges as per Agreement</b></li>
                            <li style={{ fontSize: '13px', color: '#555555', fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif', color: '#212529' }} >2. GST as per Government rules will be additional</li>
                        </ul>
                    </div>
                </div>
                <div className="" style={{ backgroundColor: '#a3a0a0', alignItems: 'center', height: '50px', display: 'flex', justifyContent: 'center', color:'#FFFFFF',alignItems:'flex-end' }} >
                    <p className="">International Freight Forwarders, Consolidators and Customers Broke</p>
                </div>
            </div>
        </div>

    </>

}


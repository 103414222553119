


import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { columnConstant } from "../../constant/formconstant";
import MapMenu, { MultiTable } from '../../component/multiTable';
import CircularWithValueLabel from '../../component/field/circleProgressBar';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { createairimport, createenquiry, createinitialquote, enquiryvendorpdf } from '../../../navigation/constant';
import { useNavigate } from 'react-router-dom';
import { GetAirImportEnquiryDataByPage, UpdateEnquiryExportStatus } from '../../../services/APIConstant/enquiryConstant';
import { EnquiryStatus } from '../../../services/modalData';
import { PostCaller } from '../../../services/apiServices';
import Transition from '../../../services/utilities/transition';
import { Close } from '@mui/icons-material';
import Dropdown from '../../component/controls';

const AirImportEnquiryList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [MarketingLeadData, setMarketingLeadData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const [closeValidator, setcloseValidator] = useState(false)
    const [isEnquiryStatus, setIsEnquiryStatus] = useState(false);
    const [CurrentEnqId, setCurrentEnqId] = useState(false);
    const fatchAirEnquiryDataByPagination = (item) => {
        MasterCallBypagination({
            API: GetAirImportEnquiryDataByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        sessionStorage.removeItem('viewImportEnquiry')
        sessionStorage.removeItem('enquiryInitialQuoteData')
        fatchAirEnquiryDataByPagination();
    }, []);

    const setTableDataHandler = ({ response }) => {
        const newMarketingLeadData = response?.data?.data?.aiEnquiry?.map((res, index) => ({
            sn: 'd',
            id: res?.id,
            eid: 'AI-' + res?.id,
            customerType: res?.customerType,
            freightServiceType: res?.freightServiceType,
            freightServiceSubType: res?.freightServiceSubType,
            bizCategoryType: res?.bizCategoryType,
            bizType: res?.bizType,
            bizSubType: res?.bizSubType,
            marketedBy: res?.marketedBy,
            marketedByName: res?.marketedByName,
            customer: res?.customer,
            customerName: res?.customerName,
            bizGenerator: res?.bizGenerator,
            accountLedger: res?.accountLedger,
            accountLedgerName: res?.accountLedgerName,
            packages: res?.packages,
            typeOfPackages: res?.typeOfPackages,
            typeOfPackagesOther: res?.typeOfPackagesOther,
            grossWeight: res?.grossWeight,
            dimention: res?.dimention,
            airVolumeWeight: res?.airVolumeWeight,
            commodity: res?.commodity,
            cbm: res?.cbm,
            airportOfLoading: res?.airportOfLoading,
            airportOfLoadingName: res?.airportOfLoadingName,
            airportOfDestinationName: res?.airportOfDestinationName,
            airportOfDestination: res?.airportOfDestination,
            tentativeDateOfShipment: res?.tentativeDateOfShipment,
            deliveryAddressList: res?.deliveryAddressList,
            pickupAddressList: res?.pickupAddressList,
            dimentionFile: res?.dimentionFile,
            dimensionsList: res?.dimensionsList,
            remarks: res?.remarks,
            document: res?.document,
            hiddenDangerousGood: res?.hiddenDangerousGood,
            msdsApproval: res?.msdsApproval,
            unNumber: res?.unNumber,
            msdsDocument: res?.msdsDocument,
            oddDimension: res?.oddDimension,
            routingAndTransitRequirement: res?.routingAndTransitRequirement,
            enquiryStatus: res?.enquiryStatus,
            branch: res?.branch,
            division: res?.division,
            financialYear: res?.financialYear,
            createdBy: res?.createdBy,
            createdDate: res?.createdDate,
            updatedBy: res?.updatedBy,
            updatedDate: res?.updatedDate,
            enquiryDate: res?.enquiryDate,
            status: res?.status,
            enquiryStatus: <span className={`quote-title`} onClick={() => { setIsEnquiryStatus(res?.enquiryStatus); setCurrentEnqId(res?.id); }}> {EnquiryStatus[res?.enquiryStatus - 1]?.name}</span>

        }));
        setMarketingLeadData(newMarketingLeadData);
        setTotalDataLength(newMarketingLeadData?.length)
    }


    const handleClose = () => {
        setIsEnquiryStatus(false)
    }

    const handleSubmit = () => {
        PostCaller({
            API: UpdateEnquiryExportStatus,
            callback: () => {
                setIsEnquiryStatus(false)
                fatchAirEnquiryDataByPagination();
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            contenttype: false,
            item: {
                aeEnquiryId: CurrentEnqId,
                enquiryStatus: isEnquiryStatus,
            }
        })
    }

    const columns = [
        {
            id: 'sn',
            label: columnConstant.sno,
            numeric: false,
        },


        {
            id: 'eid',
            label: columnConstant.enqid,
            numeric: false,
        },
        {
            id: 'customerName',
            label: columnConstant.LegalName,
            numeric: false,
        },
        {
            id: 'accountLedgerName',
            label: 'Ledger',
            numeric: false,
        },
        {
            id: 'airportOfLoadingName',
            label: columnConstant.apol,
            numeric: false,
        },

        {
            id: 'airportOfDestinationName',
            label: columnConstant.apod,
            numeric: false,
        },
        {
            id: 'grossWeight',
            label: columnConstant.grosswt,
            numeric: false,
        },
        {
            id: 'packages',
            label: columnConstant.pkgs,
            numeric: false,
        },
        {
            id: 'enquiryDate',
            label: columnConstant.enquirydate,
            numeric: false,
        },
        {
            id: 'tentativeDateOfShipment',
            label: 'T. Date',
            numeric: false,
        },
        {
            id: 'marketedByName',
            label: columnConstant.marketedby,
            numeric: false,
        },
        {
            id: 'bizSubType',
            label: columnConstant.bizsubtype,
            numeric: false,
        },
        {
            id: 'enquiryStatus',
            label: columnConstant.status,
            numeric: false,
        },
        {
            id: 'action',
            label: columnConstant.action,
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const drawerFormFields = {
        title: "Add Lead",
        tooltitle: "Add Lead",
        defaultbtnfalse: true,
        width: 450,
        leadform: true,
    }

    const conditions = {
        multiselect: false,
        tablebarclass: 'leadtablebar',
        shipmentfilter: [
            { id: 1, label: 'Air Import' },
            { id: 2, label: 'Air Export' },
        ],
        sendleadfilter: [
            { id: 1, label: 'Mayank' },
            { id: 2, label: 'Sachin' },
            { id: 3, label: 'Aditi' },
            { id: 4, label: 'Akash' },
        ],
        followup: {
            title: "Add Lead",
            tooltitle: "Follow Up",
            iconclass: "tablebtn1",
            width: 470,
            icon: <i className='las  la-phone-square '></i>,
            leadtab: true,
            defaultbtnfalse: true
        },
        modifylead: {
            title: "Modify Lead",
            defaultbtnfalse: true,
            tooltitle: "Modify Lead",
            width: 450,
            leadform: true,
            icon: <i className="las la-edit"></i>,
            iconclass: "tablebtn1",
        }

    }

    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const updatedStatus = !row?.status;
            const data = {
                id: row?.id,
                status: updatedStatus
            }
        }
        const editBtnClicked = () => {

        }
        return <>
            <div className='me-4'>
                <CircularWithValueLabel />
            </div>

            <Tooltip title="View Enquiry">
                <IconButton
                    className='tablebtn1'
                    onClick={() => {
                        sessionStorage.setItem('viewImportEnquiry', JSON.stringify(row));
                        navigate(enquiryvendorpdf)
                    }}
                >
                    <i className="las la-file-alt"></i>
                </IconButton>
            </Tooltip >
            <Tooltip title="Initial Quote">
                <IconButton
                    className='tablebtn1'
                    onClick={() => {
                        sessionStorage.setItem('enquiryInitialQuoteData', JSON.stringify(row));
                        navigate(createinitialquote)
                    }
                    }
                >
                    <i className="las la-rupee-sign"></i>
                </IconButton>
            </Tooltip >
            <Tooltip title="View Quote">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-directions"></i>
                </IconButton>
            </Tooltip >


            <Tooltip title="Final Quote">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-wallet"></i>
                </IconButton>
            </Tooltip >

            {/*             
            <Tooltip title="Edit">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >
            <Tooltip title="Initial GP">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-bookmark"></i>
                </IconButton>
            </Tooltip >
            <Tooltip title="Chat">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las chat la-comment"></i>
                </IconButton>
            </Tooltip >
            <Tooltip title="Follow-Up">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-phone-square"></i>
                </IconButton>
            </Tooltip > */}
        </>

    }


    const TableTool = ({ filterCallback }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [anchorE2, setAnchorE2] = useState(null);

        return <>
            {
                columns ? <Tooltip title="Filter">
                    <IconButton
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        aria-controls={anchorEl ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? 'true' : undefined}
                    >
                        <FilterListIcon />
                    </IconButton>
                </Tooltip> : false
            }
            {/* new leadfilter */}
            {
                conditions?.shipmentfilter ? <Tooltip title="Shipment Filter">
                    <IconButton
                        onClick={(e) => setAnchorE2(e.currentTarget)}
                        aria-controls={anchorE2 ? 'Shipment-Filter' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorE2 ? 'true' : undefined}
                    >
                        <i className='las la-filter '></i>
                    </IconButton>
                </Tooltip> : false
            }

            <MapMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuItems={columns} filterCallback={filterCallback} />
            <MapMenu anchorEl={anchorE2} setAnchorEl={setAnchorE2} menuItems={conditions?.shipmentfilter} />
            <Tooltip title="Create Enquiry">
                <IconButton
                    className='table-toggle'
                    onClick={() => {
                        navigate(createairimport)
                    }}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >
        </>
    }


    return (
        <>
            <div className='cmd'>
                <div style={{ width: 'calc(100%)' }}>
                    <MultiTable
                        title={'Air Enquiry'}
                        data={MarketingLeadData}
                        columns={columns}
                        drawerFormFields={drawerFormFields}
                        conditions={conditions}
                        tabletool={TableTool}
                        actioncell={ActionCell}
                        totaldatalength={totaldatalength}
                        paginationhandler={fatchAirEnquiryDataByPagination}


                    />
                </div>
                {/* <div style={{ width: '370px' }}><LeadTab></LeadTab></div> */}
            </div>


            {
                isEnquiryStatus &&
                <Dialog
                    open={isEnquiryStatus}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{
                        sx: {
                            width: "450px",
                            maxWidth: "450px!important",
                        },
                    }}
                    sx={{
                        '& .MuiDialog-container': {
                            alignItems: "center"
                        }
                    }
                    }


                >
                    <div className=''>
                        <DialogTitle>
                            <div className="cmd">

                                <div className='quote_pop_title'>
                                    <h5>{'Status'}</h5>
                                </div>
                                <Tooltip title='Close'>
                                    <IconButton onClick={handleClose}>
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <hr />
                        </DialogTitle>
                        <DialogContent>

                            <div className=''>
                                <Dropdown
                                    className='mt-3'
                                    field={
                                        {
                                            id: "status",
                                            label: "Status",
                                            value: isEnquiryStatus,
                                            required: true,
                                            error: false,
                                            item: EnquiryStatus,
                                            onChange: (value) => {
                                                setIsEnquiryStatus(value)
                                            }
                                        }
                                    }
                                />
                            </div >
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                            <Button onClick={handleSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
                        </DialogActions>
                    </div>
                </Dialog>
            }
        </>
    )
}

export default AirImportEnquiryList;







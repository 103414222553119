import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import {  UpdateConsigneeSubAgencyStatus } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CustomerTable } from '../customer/customerTable';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { InputFileField, SmallSwitch } from '../../component/controls';
import { columnConstant } from '../../constant/formconstant';
import { ConsigneeSubAgencyAddressForm } from '../addConsignee/consigneeAgencyAddressform';
import { GetExpectedGoodofExportsByPage } from '../../../services/APIConstant/warehouseConstant';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dropdown, { InputField } from '../../component/controls';
import { ConditionofEntry, ConditionofGoodsType } from '../../../services/modalData';
import DivisonDrawer from '../../component/divisionDrawer';
import { CarCrash } from '@mui/icons-material';
import { creategoodinregister } from '../../../navigation/constant';
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};

const ExpectedGoodsByJob = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [airportMaster, airpostMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const [prevSlotNo, setPrevSlotNo] = useState(null);


    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetExpectedGoodofExportsByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);

    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({

            sn: index + 1,
            id: res?.id,
            jobId: res?.jobId,
            goodsInRegisterId: res.goodsInRegisterId,
            partyName: res.partyName,
            jobType: res.jobType,
            packages: res.packages,
            grossWeight: res.grossWeight,
            consoleNumber: res.consoleNumber,
            dimension: res.dimension,
            destinationType: res.destinationType,
            dimensionFilePath: res.dimensionFilePath,
            slotTime: res.slotTime,
            lastHandoverTime: res.lastHandoverTime,
            actualShipper: res.actualShipper,
            customerName: res.customerName,
            mawbNumber: res.mawbNumber,
            hawbNumber: res.hawbNumber,
            jobNumber: res.jobNumber,
            totalCost: res.totalCost,
            pickupStatus: res.pickupStatus,
            challanType: res.challanType,
            airportGrossWeight: res.airportGrossWeight,
            airportVolumeWeight: res.volumeWeight || '0',
            airportPackages: res.airportPackages,
            isAirportWeightDifference: res.isAirportWeightDifference,
            customerBranchList: res?.transporters.map((item) => ({
                ...item,
                // jobNumber: res.jobNumber,
                // hhhhhh: "800008"
                driverandPhoneNumber: <div>{item.driverName} <br /> {item.driverNumber}</div>

            })),

            uploadFilePath: res?.string,
            status: res?.status

        }));

        airpostMasterData(newmasterdata);
        setTotalDataLength(newmasterdata?.length)
    }

    const columns = [
        {
            id: 'sn',
            label: columnConstant.sno,
            numeric: false,
        },

        {
            id: 'jobId',
            label: columnConstant.jobno,
            numeric: false,
        },
        {
            id: 'partyName',
            label: columnConstant.shippername,
            numeric: false,
        },
        {
            id: 'pinCode',
            label: 'APOL/APOD',
            numeric: false,
        },
        {
            id: 'packages',
            label: columnConstant.packages,
            numeric: false,
        },

        {
            id: 'grossWeight',
            label: columnConstant.grosswt,
            numeric: false,
        },
        {
            id: 'airportVolumeWeight',
            label: columnConstant.volumnweight,
            numeric: false,
        },
        {
            id: 'mawbNumber',
            label: columnConstant.mawbno,
            numeric: false,
        },
        {
            id: 'eoriNo',
            label: columnConstant.freightsb,
            numeric: false,
        },
        {
            id: 'eoriNo',
            label: columnConstant.sbno,
            numeric: false,
        },
        {
            id: 'lastHandoverTime',
            label: columnConstant.lht,
            numeric: false,
        },
        // {
        //     id: 'action',
        //     label: 'Action',
        //     numeric: false,
        // },
        {
            id: 'subtable',
            label: '',
            numeric: false,
        },
    ];

    const subColumns = [
        {
            id: 'sn',
            label: '#',
            numeric: false,
        },
        {
            id: 'hhhhhh',
            label: columnConstant.slotno,
            numeric: false,
        },
        {
            id: 'vehicleNumber',
            label: columnConstant.vehicalno,
            numeric: false,
        },
        {
            id: 'driverandPhoneNumber',
            label: columnConstant.drivernameno,
            numeric: false,
        },
        {
            id: 'sealNumber',
            label: columnConstant.sealno,
            numeric: false,
        },
        {
            id: 'packages',
            label: columnConstant.packages,
            numeric: false,
        },
        {
            id: 'challanNumber',
            label: columnConstant.chalanno,
            numeric: false,
        },
        {
            id: 'comment',
            label: columnConstant.remark,
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
    ]

    const subActionCell = ({ row }) => {
        return <>
            <Tooltip title={columnConstant.goodsrecived}>
                <IconButton
                    onClick={() => {
                        sessionStorage.setItem('expectedGoodsInRegisterData', JSON.stringify(row))
                        navigate(creategoodinregister)
                    }}
                >
                    <i className='las la-truck' style={{ transform: 'scaleX(-1)' }}></i>
                </IconButton>
            </Tooltip >
        </>
    }

    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateConsigneeSubAgencyStatus,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({ response }) => {
                    fatch_MasterDataBYPagination();
                }
            });
        }

        return <>

        </>

    }

    //table head section tool
    const TableTool = ({ }) => {
        return <>

        </>
    }


    const conditions = {
        subColumns: subColumns,
        subActionCell: subActionCell
    }


    // const SubTableCell = (row) => {

    //     const getRowspanValue = (hhhhhh) => {
    //         const count = row.customerBranchList.filter((res) => res.hhhhhh === hhhhhh).length;
    //         return count > 1 ? count : 0;
    //     };
    //     return <>
    //         <div>
    //             {
    //                 <div className='check-customer-table'>
    //                     <table>
    //                         <thead>
    //                             <tr>
    //                                 <th>#</th>
    //                                 <th>{columnConstant.slotno}</th>
    //                                 <th>{columnConstant.vehicalno}</th>
    //                                 <th>{columnConstant.drivernameno}</th>
    //                                 <th>{columnConstant.sealno}</th>
    //                                 <th>{columnConstant.packages}</th>
    //                                 <th>{columnConstant.chalanno}</th>
    //                                 <th>{columnConstant.remark}</th>
    //                             </tr>
    //                         </thead>

    //                         <tbody>

    //                             {row.customerBranchList?.map((res, key) => {
    //                                 const shouldRenderSlotNo = res.hhhhhh !== prevSlotNo;
    //                                 const rowspanValue = shouldRenderSlotNo ? getRowspanValue(res.hhhhhh) : 0;

    //                                 // Update the previous slotno value
    //                                 setPrevSlotNo(res.hhhhhh);

    //                                 return (
    //                                     <tr key={key}>
    //                                         <td>{res.id || ''}</td>
    //                                         {shouldRenderSlotNo && <td rowSpan={rowspanValue}>{res.hhhhhh}</td>}
    //                                         {!shouldRenderSlotNo && <td></td>} {/* Empty cell for rowspan case */}
    //                                         <td>{res.vehicleNumber || ''}</td>
    //                                         <td>{res.driverNumber || ''}</td>
    //                                         <td>{res.sealNumber || ''}</td>
    //                                         <td>{res.packages || ''}</td>
    //                                         <td>{res.challanNumber || ''}</td>
    //                                         <td>{res.comment || ''}</td>
    //                                     </tr>
    //                                 );
    //                             })}


    //                         </tbody>
    //                     </table>
    //                 </div>

    //             }
    //         </div>
    //     </>
    // }

    return (
        <>

            <CustomerTable
                data={airportMaster}
                conditions={conditions}
                columns={columns}
                title={'Expected Goods'}
                tabletool={TableTool}
                // subTableCell={SubTableCell}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default ExpectedGoodsByJob;


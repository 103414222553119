import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Checkbox, IconButton, Switch, Tooltip } from '@mui/material';
import { InputField, InputFileField } from '../controls';
import CloseIcon from '@mui/icons-material/Close';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialog({ data }) {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    data.onChange(null)

  };

  const handleSubmit = () => {
    data.onChange(file)
    setOpen(false);

  }
  const handleCancel = () => {
    data.onChange(null)
    setOpen(false);

  }

  return (
    <React.Fragment>
        <div onClick={handleClickOpen}>
          <Checkbox
            checked={!data.checked}
          />
          {data?.title}
        </div>


      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "430px!important",
          },
        }}
        sx={{
          '& .MuiDialog-container': {
            alignItems: "start"
          }
        }
        }


      >
        <div className=''>
          <DialogTitle>
            <div className="cmd">
              <div>{data?.title}</div>
              <Tooltip title='Close'>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
            <hr />
          </DialogTitle>
          <DialogContent>
            <InputFileField
              className='mt-0'
              field={{
                id: "",
                label: "",
                type: 'file',
                required: false,
                error: false,
                helperText: 'Upload Pan Card Required',
                onChange: (value, field) => {
                  setFile(value)
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} variant='outlined'>Cancel</Button>
            <Button onClick={handleSubmit} style={{background:'var(--color-main)',color:'#fff'}}>Submit</Button>
          </DialogActions>
        </div>
      </Dialog>
    </React.Fragment >
  );
}


// export default function AlertMellStatus({ data }) {
//   const [open, setOpen] = React.useState(false);
//   const [file, setFile] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     data.onChange(null)

//   };

//   const handleSubmit = () => {
//     data.onChange(file)
//     setOpen(false);

//   }
//   const handleCancel = () => {
//     data.onChange(null)
//     setOpen(false);

//   }

//   return (
//     <React.Fragment>
//         <div onClick={handleClickOpen}>
//           <Checkbox
//             checked={!data.checked}
//           />
//           {data?.title}
//         </div>


//       <Dialog
//         open={open}
//         TransitionComponent={Transition}
//         keepMounted
//         onClose={handleClose}
//         aria-describedby="alert-dialog-slide-description"
//         PaperProps={{
//           sx: {
//             width: "100%",
//             maxWidth: "430px!important",
//           },
//         }}
//         sx={{
//           '& .MuiDialog-container': {
//             alignItems: "start"
//           }
//         }
//         }


//       >
//         <div className=''>
//           <DialogTitle>
//             <div className="cmd">
//               <div>{data?.title}</div>
//               <Tooltip title='Close'>
//                 <IconButton onClick={handleClose}>
//                   <CloseIcon />
//                 </IconButton>
//               </Tooltip>
//             </div>
//             <hr />
//           </DialogTitle>
//           <DialogContent>
//             <InputFileField
//               className='mt-0'
//               field={{
//                 id: "",
//                 label: "",
//                 type: 'file',
//                 required: false,
//                 error: false,
//                 helperText: 'Upload Pan Card Required',
//                 onChange: (value, field) => {
//                   setFile(value)
//                 }
//               }}
//             />
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleCancel} variant='outlined'>Cancel</Button>
//             <Button onClick={handleSubmit} style={{background:'var(--color-main)',color:'#fff'}}>Submit</Button>
//           </DialogActions>
//         </div>
//       </Dialog>
//     </React.Fragment >
//   );
// }

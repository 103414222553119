import React, { useEffect, useState } from "react";
import Dropdown, { DateField, InputField, InputFileField, TimeField } from "../../component/controls";
import { GetChargeHeadByPage, GetConsigneeSubAgencyPage, GetCustomerByPage, GetCustomerBySearch, GetEmployeeByPage, GetNotifyMasterByPage, GetVehicalTypeByPage, PostVendorMaster } from "../../../services/constant";
import GetCaller, { PostCaller } from "../../../services/apiServices";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AirExportCustomerTypeFrightOptions, ChallanType, DestinationType, GoodsConditions, PickUpType, TypeOfCustomer, applicableFormat, frightpp, goodsExpectedItem, pretransport } from "../../../services/modalData";
import { Job_MawbRate } from "./component/mawbRate";
import { Job_HawbRate } from "./component/hawbRate";
import { GetAirExportPlannerDetailsJobbyId, UpdateAirExportPermanentJob } from "../../../services/APIConstant/jobConstant";
import { Box, Button, Tab } from "@mui/material";
import { ShipperInvoiceNumber } from "./component/shipperInvoicenum";
import { SlotNumber } from "./component/slotNumber.";
import { TcNumber } from "./component/tcNumbers";
import { airexportjoblist } from "../../../navigation/constant";
import { Add, Close } from "@mui/icons-material";
import { columnConstant, formconstant } from "../../constant/formconstant";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CreateCddForm } from "./component/createCddForm";
import { GetAirExportPermanentJobDetailsById } from "../../../services/APIConstant/AirExport.jsx/finalJob";
import FinalBuying from "./component/finalBuying";
import FinalSelling from "./component/finalSelling";

const CreateJobForm = ({ row }) => {
    console.log(row);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [tabValue, setTabValue] = useState('1');
    const [openTable, setOpenTable] = useState(true);

    const [consigneeItem, setConsigneeItem] = useState([])
    const [consigneeAddressItem, setConsigneeAddressItem] = useState([])
    const [shipperAddressItem, setshipperAddressItem] = useState([])
    const [notifyPartyItem, setNotifyPartyItem] = useState([])
    const [chargeHeadItem, setChargeHeadItem] = useState([])
    const [costomerItem, setCostomerItem] = useState([])
    const [incoTermItem, setIncoTermItem] = useState([])
    const [totalVolumeWeight, setTotalVolumeWeight] = useState(0)
    const [totalPackages, setTotalPackages] = useState(0);
    const [dimentionDetailsError, setDimentionDetailsError] = useState({});


    const [transporterDetailsError, setTransporterDetailsError] = useState({});
    const [transportNameItem, setTransPortNameItem] = useState([]);
    const [vehicalTypeItem, setVehicalTypeItem] = useState([]);
    const [totalGrossWeight, setTotalGrossWeight] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [formData, setFormData] = useState({
        mawbRate: [],
        hawbRate: [],
        shipperInvNum: [],
        tcNumbers: [],
        slotTime: [],
        shipperInvoiceNumber: [],
        freightPP: "",
        shipperId: "",
        consignee: "",
        notifyParty: "",
        subAgent: "",
        onp1: "",
        onp2: "",
        onp3: "",
        docReceiveDate: "",
        shipperInvoiceDate: "",
        shipperInvoiceReceiveDate: "",
        shipperIncoTerms: "",
        freightPP: "",
        checkList: "",
        checkListApprovedByShipper: "",
        goodsExpectedAt: "",
        preTransportation: "",
        allotMawbNumber: "",
        bookingWithAL: "",
        inputClearenceDate: "",
        handOverTime: "",
        allotHawbNumber: "",
        shipperAddressId: 1,
        consigneeAddressId: 1,
        grossWeight: "",
        qtyChargeWeight: "",
        airVolumeWeight: "",
        packages: "",
        dimensionsList: []
    });


    const set_CustomerItemData = (e) => {
        GetCaller({
            API: GetCustomerBySearch,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const customerdata = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.legalName,
                    customerBranchList: item?.customerBranchList
                }));
                setCostomerItem(customerdata)

            }

        });
    };


    const GetAirExportPlanerJobbyId = (id) => {
        GetCaller({
            // API: GetAirExportPlannerDetailsJobbyId,
            API: GetAirExportPermanentJobDetailsById,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { id: id },

            callback: ({ response }) => {
                let newData = response?.data?.data

                newData.bookingWithAL = newData?.bookingWithAL === true ? 1 : 2
                newData.checkListApprovedByShipper = newData?.checkListApprovedByShipper === true ? 1 : 2
                newData.checkList = newData?.checkList === true ? 1 : 2
                debugger
                let customerType = AirExportCustomerTypeFrightOptions?.find((item) => item?.value === newData?.customerType)
                let freightType = customerType?.FrightServiceType?.find((item) => item?.name === newData?.freightServiceType)

                setIncoTermItem(freightType?.IncoTerm)

                setFormData({
                    ...formData,
                    ...newData
                })
                console.log('GetAirExportedeedPlannerDetailsJobbyId', newData)
            },

        })
    }

    useEffect(() => {
        const FinalJob = sessionStorage.getItem("FinalJob")
        if (FinalJob) {
            GetAirExportPlanerJobbyId(Number(FinalJob));
        }

        set_CustomerItemData();

        GetCaller({
            API: GetConsigneeSubAgencyPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                console.log('GetConsigneeSubAgencyPage', response?.data?.data?.records)
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.name,
                    value: res?.id,
                    consigneeSubAgentAddress: res?.consigneeSubAgentAddress
                }))

                setConsigneeItem(item)
            },

        })


        GetCaller({
            API: GetNotifyMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                console.log('GetConsigneeSubAgencyPage', response?.data?.data?.records)
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.name,
                    value: res?.id
                }))

                setNotifyPartyItem(item)

            },

        })

        GetCaller({
            API: GetChargeHeadByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                console.log('GetConsigneeSubAgencyPage', response?.data?.data?.records)
                let data = response?.data?.data?.records
                const item = data?.map((res) => ({
                    name: res?.chargeName,
                    value: res?.id
                }))

                setChargeHeadItem(item)

            },

        })

        GetCaller({
            API: PostVendorMaster,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.legalName
                    }));

                setTransPortNameItem(newCountryItemData);

            }

        });

        GetCaller({
            API: GetVehicalTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.name
                    }));

                setVehicalTypeItem(newCountryItemData);

            }

        });

    }, [])




    const requestData = (() => {

        const data = {
            enquiryId: formData?.enquiryId,
            id: formData?.id,
            mawbRate: formData?.mawbRate,
            hawbRate: formData?.hawbRate,
            shipperId: formData?.shipperId,
            consignee: formData?.consignee,
            consigneeAddressId: formData?.consigneeAddressId,
            shipperAddressId: formData?.shipperAddressId,
            notifyParty: formData?.notifyParty,
            docReceiveDate: formData?.docReceiveDate,
            shipperInvoiceDate: formData?.shipperInvoiceDate,
            shipperInvoiceReceiveDate: formData?.shipperInvoiceReceiveDate,
            shipperIncoTerms: formData?.shipperIncoTerms,
            // freightPP: formData?.freightPP,
            freightPP: 1,
            checkList: formData?.checkList,
            checkListApprovedByShipper: formData?.checkListApprovedByShipper,
            goodsExpectedAt: formData?.goodsExpectedAt,
            preTransportation: formData?.preTransportation,
            goodsReceived: 1, // temparray
            allotMawbNumber: formData?.allotMawbNumber,
            bookingWithAL: formData?.bookingWithAL,
            inputClearenceDate: formData?.inputClearenceDate,
            // tentativeBooking: [ // temprary
            //     {
            //         id: 0,
            //         flightNumber: 0,
            //         flightDate: "2024/05/12",
            //         transitAirport: 0
            //     }
            // ],
            handOverTime: formData?.handOverTime,
            allotHawbNumber: formData?.allotHawbNumber,
            shipperInvoiceNumber: formData?.shipperInvoiceNumber,
            tcNumbers: formData?.tcNumbers,
            slotTime: formData?.slotTime,
            packages: formData?.packages,
            grossWeight: formData?.grossWeight,
            qtyChargeWeight: formData?.qtyChargeWeight,
            airVolumeWeight: formData?.airVolumeWeight,
            dimensionsList: formData?.dimensionsList,
            transporters: formData?.transporters || []

        }

        if (formData?.preTransportation === 1 && (formData?.goodsExpectedAt === 1 || formData?.goodsExpectedAt === 3)) data.challanStatus = formData.challanStatus
        if (formData?.customerType !== 1) data.subAgent = formData?.subAgent
        if (formData?.customerType !== 1) data.onp1 = formData?.onp1
        if (formData?.customerType !== 1) data.onp2 = formData?.onp2
        if (formData?.customerType !== 1) data.onp3 = formData?.onp3

        return data;

    })();

    const UpdateAirExportPermanentJob = (requestData) => {

        requestData.bookingWithAL = requestData.bookingWithAL === 1 ? true : false
        requestData.checkListApprovedByShipper = requestData.checkListApprovedByShipper === 1 ? true : false
        requestData.checkList = requestData.checkList === 1 ? true : false


        PostCaller({
            API: UpdateAirExportPermanentJob,
            callback: () => {
                navigate(airexportjoblist)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            contenttype: true,
            item: requestData
        })
    }

    console.log('job form data', formData)



    const handleBasicInputChange = (value, field) => {
        setFormData({
            ...formData,
            [field.id]: value,
            [field.id + 'error']: !value
        })
    }
    const edit = true;
    const handleFormSubmit = () => {

        const transporterDetailshasUndefinedValue = (array) => {

            let newarray = array && array?.length !== 0 ? array : [
                {
                    id: "",
                    goodsExpectedAt: "",
                    vendorId: "",
                    pickup: "",
                    pickupVehicleTypeId: "",
                    vehicleNumber: "",
                    driverName: "",
                    driverNumber: "",
                    packages: "",
                    number: "",
                    comment: "",
                    cost: "",
                    grossWeight: "",
                    goodsCondition: "",
                    uploadFile: "",
                    goodsOutRegisterId: 1,
                    sealNumber: 1,
                    dimensionFilePath: ""
                }
            ]


            let changes = {};
            newarray?.map((obj, index) => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (
                        (formData?.goodsExpectedAt !== 3 && key === 'goodsExpectedAt') ||
                        key === 'sealNumber' ||
                        key === "dimensionFilePath" ||
                        key === 'vendorName' ||
                        key === 'uploadFilePath' ||
                        key === "id" ||
                        key === "challanNumber" ||
                        (obj.pickup !== 1 && key === 'vendorId') ||
                        (obj.pickup !== 1 && key === 'cost') ||
                        (obj.goodsCondition !== 4 && obj.goodsCondition !== 5 && key === "number") ||
                        (obj.goodsCondition === 1 && key === 'comment') ||
                        (obj.goodsCondition === 1 && key === 'uploadFile')
                    ) {

                    }
                    else if (!value) {
                        changes[key + index] = !value;
                    }
                });
            })
            console.log('transporterDetailshasUndefinedValue changes', changes)

            return changes;
        };



        const dimentionDetailshasUndifinedValues = (array) => {

            let newArray = array && array?.length !== 0 ? array : [{
                length: "",
                width: "",
                height: "",
                boxes: "",
                airVolumeWeight: ""
            }]
            let changes = {};

            newArray?.map((obj, index) => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (!value) {
                        changes[key + index] = !value;
                    }
                });
            })
            console.log('contactDetailshasUndifinedValues changes', changes)

            return changes;
        };

        debugger
        const changes = handleSubmit(requestData);
        const dimentionChanges = dimentionDetailshasUndifinedValues(requestData.dimensionsList)
        const transporterDetailsChanges = transporterDetailshasUndefinedValue(requestData?.transporters);

        if (Object.keys(changes).length > 0 ||
            Object.keys(dimentionChanges).length > 0 ||
            (Object.keys(transporterDetailsChanges).length > 0 && (formData?.goodsExpectedAt === 1 || formData?.goodsExpectedAt === 3))
        ) {
            setFormData({ ...formData, ...changes });
            setDimentionDetailsError(dimentionChanges)
            setTransporterDetailsError(transporterDetailsChanges)
        }
        else if (edit) {

            UpdateAirExportPermanentJob(requestData);
        }
        else {
            UpdateAirExportPermanentJob(requestData);
        }
    };

    const handleSubmit = (obj) => {

        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (
                key === "id"
            ) {
            }
            else if (!value) {
                changes[key] = value;
                changes[key + "error"] = !value;
            }
        });
        return changes;
    };

    useEffect(() => {
        FindShipperAddress(formData?.shipperId)
        FindConsigneeAddress(formData?.consignee)
    }, [costomerItem, consigneeItem, formData?.shipperAddressId, formData?.consigneeAddressId])

    const FindShipperAddress = (value) => {
        const filterList = costomerItem?.find((res) => res?.value === value)
        const AddressItem = filterList?.customerBranchList?.map(res => (
            {
                name: res?.addressLabel,
                value: res?.id
            }
        ))

        setshipperAddressItem(AddressItem)
    }

    const FindConsigneeAddress = (value) => {
        const filterList = consigneeItem?.find((res) => res?.value === value)
        const AddressItem = filterList?.consigneeSubAgentAddress?.map(res => (
            {
                name: res?.address1,
                value: res?.id
            }
        ))

        setConsigneeAddressItem(AddressItem)
    }




    const calculateTotalVolumeWeight = (inputFields) => {
        const dimensionType = formData.Dimention === 1 ? 6000 : 5000;
        const obj = {
            totalWeight: 0,
            totalPackages: 0,
            CourierVolumeWeight: 0,
            CBM: 0
        }
        inputFields.forEach((res, key) => {
            let currentRowTotalWeight = 0
            if (res.length && res.width && res.height && res.boxes) {
                const { length, width, height, boxes } = res;
                obj.totalPackages += Number(boxes)
                currentRowTotalWeight += (Number(length) * Number(width) * Number(height) * Number(boxes)) / dimensionType;
                if (formData.Dimention === 1) {
                    obj.CourierVolumeWeight += (Number(length) * Number(width) * Number(height) * Number(boxes)) / 3000;
                    obj.CBM += (Number(length) * Number(width) * Number(height) * Number(boxes)) / 1000000;
                }
                else {
                    obj.CourierVolumeWeight = 0;
                    obj.CBM += ((Number(length) * 2.54) * (Number(width) * 2.54) * (Number(height) * 2.54) * Number(boxes)) / (1000000);
                }
            }
            res['airVolumeWeight'] = currentRowTotalWeight;
            obj.totalWeight += currentRowTotalWeight
        });
        DimentionChangesHandler(obj, inputFields)

        return obj;
    };

    const DimentionChangesHandler = (obj, inputFields) => {
        setTotalVolumeWeight(obj.totalWeight);
        setTotalPackages(obj.totalPackages)
        setFormData({
            ...formData,
            ['airVolumeWeight']: obj.totalWeight % 1 === 0 ? obj.totalWeight.toFixed(0) : obj.totalWeight.toFixed(2),
            ["packages"]: obj.totalPackages,
            ['CourierVolumeWeight']: obj.CourierVolumeWeight % 1 === 0 ? obj.CourierVolumeWeight.toFixed(0) : obj.CourierVolumeWeight.toFixed(2),
            ['CBM']: obj.CBM % 1 === 0 ? obj.CBM.toFixed(0) : obj.CBM.toFixed(2),
            ['dimensionsList']: inputFields

        })


    }




    const Dimension = () => {
        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };

        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                length: '',
                width: '',
                height: '',
                boxes: '',
                airVolumeWeight: ''
            },
        ]);

        useEffect(() => {
            const airExportEnquiryData = JSON.parse(sessionStorage.getItem('airExportEnquiryData'));
            if (airExportEnquiryData) {
                let item = airExportEnquiryData?.dimensionsList?.map(res => (
                    {
                        ...res,
                        key: generateUniqueKey(),
                    }
                ));
                setInputFields(item);
            }
        }, [])


        const addInputField = () => {
            setInputFields([...inputFields, { key: generateUniqueKey(), length: '', width: '', height: '', boxes: '', airVolumeWeight: '' }]);
        };



        const handleInputChange = (key, field, value, index) => {

            const updatedInputFields = [...inputFields];
            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

            if (fieldToUpdate) {
                setDimentionDetailsError({
                    ...dimentionDetailsError,
                    [field + index]: !value
                })
                // fieldToUpdate[field] = value
                // const totalVolumeWeight = calculateTotalVolumeWeight([fieldToUpdate]);
                // fieldToUpdate['airVolumeWeight'] = totalVolumeWeight;

                fieldToUpdate[field] = value;
                setInputFields(updatedInputFields);


                const data = []
                updatedInputFields && updatedInputFields.map((res) => {

                    return data.push(
                        {
                            id: res?.id,
                            length: res.length,
                            width: res.width,
                            height: res.height,
                            boxes: res.boxes,
                            airVolumeWeight: res.airVolumeWeight
                        }
                    )

                })
                calculateTotalVolumeWeight(updatedInputFields);

                // handleBasicInputChange(data, { id: 'dimensionsList' });
            }
        };

        const removeInputField = (key) => {
            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res?.id,
                        length: res.length,
                        width: res.width,
                        height: res.height,
                        boxes: res.boxes,
                        airVolumeWeight: res.airVolumeWeight
                    }
                )

            })
            calculateTotalVolumeWeight(data);
            // handleBasicInputChange(data, { id: 'dimensionsList' });
        };


        return <>
            <div className=''>
                <div className="d-flex align-items-center">
                    <h4 className="m-0">Dimensions</h4>
                </div>


                {inputFields.map((field, key) => (
                    <div className='row' key={field.key}>
                        <div className="col-md-11">
                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "length",
                                                label: "Length",
                                                type: 'number',
                                                value: field.length,
                                                required: true,
                                                error: dimentionDetailsError["length" + key],
                                                helperText: 'Contact Person Name Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'length', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "width",
                                                label: "Width",
                                                type: 'number',
                                                value: field.width,
                                                required: true,
                                                error: dimentionDetailsError["width" + key],
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'width', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        // className="mt-0"
                                        field={
                                            {
                                                id: "height",
                                                label: "Height",
                                                type: 'number',
                                                value: field.height,
                                                required: true,
                                                error: dimentionDetailsError["height" + key],
                                                helperText: 'Department Type Required',

                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'height', value, key);
                                                }
                                            }
                                        }

                                    />

                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "boxes",
                                                label: "Boxes",
                                                type: 'number',
                                                value: field.boxes,
                                                required: true,
                                                error: dimentionDetailsError["boxes" + key],
                                                helperText: 'Mobile No Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'boxes', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "airVolumeWeight",
                                                label: "Air Volume Weight",
                                                type: 'number',
                                                value: field.airVolumeWeight,
                                                required: false,
                                                error: dimentionDetailsError["airVolumeWeight" + key],
                                                disable: true,
                                                helperText: 'Land Line Required',
                                                // onChange: (value) => {
                                                //     handleInputChange(field.key, 'airVolumeWeight', value);
                                                // }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 cmd" style={{ justifyContent: "right" }}>
                            {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                <Add />
                            </Button> : (
                                <Button
                                    sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                    onClick={() => removeInputField(field.key)}
                                    variant='contained'
                                >
                                    <Close />
                                </Button>
                            )}
                        </div>
                    </div>
                ))}
            </div>

        </>
    }

    function TransportMultiDetails() {

        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };

        useEffect(() => {
            const goodsInRegisterListData = JSON.parse(sessionStorage.getItem("goodsInRegisterListData"))
            // const goodsOutListData = JSON.parse(sessionStorage.getItem("goodsOutListData"))
            const goodsOutListData = false
            const updateGoodsOutListData = JSON.parse(sessionStorage.getItem("updateGoodsOutListData"))

            if (goodsInRegisterListData || goodsOutListData || updateGoodsOutListData) {

                let data = updateGoodsOutListData?.transporters ? updateGoodsOutListData?.transporters
                    : goodsInRegisterListData?.transporters ? goodsInRegisterListData?.transporters
                        : goodsOutListData?.transporters

                let updatedDetailsData = []

                data.map((res, key) => {
                    let obj = {
                        key: generateUniqueKey(), // Add a unique key for each input field
                        id: res.id,
                        vendorId: res.vendorId,
                        goodsExpectedAt: res?.goodsExpectedAt,
                        pickup: res.pickup,
                        pickupVehicleTypeId: res.pickupVehicleTypeId,
                        vehicleNumber: res.vehicleNumber,
                        driverName: res.driverName,
                        driverNumber: res.driverNumber,
                        packages: res.packages,
                        number: res.number,
                        comment: res.comment,
                        cost: res.cost,
                        goodsCondition: res.goodsCondition,
                        uploadFile: res.uploadFile,
                        cost: res.cost,

                        grossWeight: Number(res.grossWeight), goodsOutRegisterId: 1,
                        sealNumber: 1,
                        dimensionFilePath: ""
                    }
                    updatedDetailsData.push(obj)

                })
                setInputFields(updatedDetailsData)
                sessionStorage.removeItem("goodsInRegisterListData")
                // sessionStorage.removeItem("goodsOutListData")
                sessionStorage.removeItem("updateGoodsOutListData")
            }

        }, [])

        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                id: "",
                vendorId: "",
                goodsExpectedAt: '',
                pickup: "",
                pickupVehicleTypeId: "",
                vehicleNumber: "",
                driverName: "",
                driverNumber: "",
                packages: "",
                number: "",
                comment: "",
                cost: "",
                grossWeight: "",
                goodsCondition: "",
                uploadFile: "",
                cost: "",
                goodsOutRegisterId: 1,
                sealNumber: 1,
                dimensionFilePath: ""
            },
        ]);


        const totalValueCounter = () => {
            let totalPackages = 0
            let totalGrossWeight = 0
            let totalCost = 0

            inputFields.map(res => {
                const cost = res.cost !== "" ? Number(res.cost) : 0;
                const grossWeight = res.grossWeight !== "" ? Number(res.grossWeight) : 0;
                const packages = res.packages !== "" ? Number(res.packages) : 0;

                totalCost = Number(totalCost + cost);
                totalGrossWeight = Number(totalGrossWeight + grossWeight);
                totalPackages = Number(totalPackages + packages);
            })

            setTotalCost(totalCost)
            setTotalGrossWeight(totalGrossWeight)
            setTotalPackages(totalPackages)

            // if (edit !== "expectedGoodsInRegisterData" && (totalGrossWeight !== 0 || totalPackages !== 0)) {
            // setGoodInRegisterForm({
            //     ...GoodInRegisterForm,
            //     ["totalCost"]: totalCost,
            //     ["grossWeight"]: totalGrossWeight,
            //     ["packages"]: totalPackages,
            // })
            // }


            console.log(totalCost, totalGrossWeight, totalPackages);
        }

        useEffect(() => {
            totalValueCounter()
        }, [inputFields])

        const addInputField = () => {
            setInputFields([...inputFields, {
                key: generateUniqueKey(),
                id: "",
                vendorId: "",
                goodsExpectedAt: "",
                pickup: "",
                pickupVehicleTypeId: "",
                vehicleNumber: "",
                driverName: "",
                driverNumber: "",
                packages: "",
                number: "",
                comment: "",
                cost: "",
                grossWeight: "",
                goodsCondition: "",
                uploadFile: "",
                cost: "",
                goodsOutRegisterId: 1,
                sealNumber: 1,
                dimensionFilePath: ""
            }]);
        };

        const handleDuplicateInputChange = (key, field, value, index) => {
            const updatedInputFields = [...inputFields];

            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
            if (fieldToUpdate) {

                setTransporterDetailsError({
                    ...transporterDetailsError,
                    [field + index]: !value
                })

                if (field === 'pickup') {
                    fieldToUpdate['cost'] = 0;
                }
                fieldToUpdate[field] = value;

                if (formData?.goodsExpectedAt === 1) {
                    fieldToUpdate['goodsExpectedAt'] = 1;
                } else if (formData?.goodsExpectedAt === 2) {
                    fieldToUpdate['goodsExpectedAt'] = 2;
                }

                if (formData?.preTransportation === 1) {
                    fieldToUpdate['pickup'] = 1;
                } else if (formData?.preTransportation === 2) {
                    fieldToUpdate['pickup'] = 2;
                }

                setInputFields(updatedInputFields);

                const data = []
                updatedInputFields && updatedInputFields.map((res) => {
                    return data.push(
                        {
                            id: res.id || null,
                            vendorId: res.vendorId,
                            goodsExpectedAt: res?.goodsExpectedAt,
                            pickup: res.pickup,
                            pickupVehicleTypeId: res.pickupVehicleTypeId,
                            vehicleNumber: res.vehicleNumber,
                            driverName: res.driverName,
                            driverNumber: res.driverNumber,
                            number: res.number,
                            comment: res.comment,
                            goodsCondition: res.goodsCondition || 1,
                            uploadFile: res.uploadFile,
                            cost: Number(res.cost),
                            packages: Number(res.packages),
                            grossWeight: Number(res.grossWeight),
                            goodsOutRegisterId: 1,
                            sealNumber: 1,
                            dimensionFilePath: ""
                        }
                    )
                })

                handleBasicInputChange(data, { id: 'transporters' });

            }
        };

        const removeInputField = (key) => {

            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res.id || null,
                        vendorId: res.vendorId,
                        goodsExpectedAt: res?.goodsExpectedAt,
                        pickup: res.pickup,
                        pickupVehicleTypeId: res.pickupVehicleTypeId,
                        vehicleNumber: res.vehicleNumber,
                        driverName: res.driverName,
                        driverNumber: res.driverNumber,
                        number: res.number,
                        comment: res.comment,
                        goodsCondition: res.goodsCondition || 1,
                        uploadFile: res.uploadFile,
                        cost: Number(res.cost),
                        packages: Number(res.packages),
                        grossWeight: Number(res.grossWeight),
                        goodsOutRegisterId: 1,
                        sealNumber: 1,
                        dimensionFilePath: ""
                    }
                )

            })
            handleBasicInputChange(data, { id: 'transporters' });
        };

        return (

            <>

                {

                    <div className='hr-form-box'>

                        <div className="cmd">
                            <h5 className="m-0 p-0">Add Transporter</h5>

                        </div>

                        {inputFields.map((field, key) => (
                            <div className='row' key={field.key}>
                                <div className="col-md-1 cmd" style={{ justifyContent: "left" }}>
                                    {
                                        key !== 0 ? (
                                            <Button
                                                sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                                onClick={() => removeInputField(field.key)}
                                                variant='outlined'
                                            >
                                                <Close />
                                            </Button>
                                        )
                                            :
                                            <Button sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                                <Add />
                                            </Button>
                                    }
                                </div>
                                <div className="col-md-11">
                                    <div className="row my-2">
                                        {
                                            formData?.goodsExpectedAt === 3 &&
                                            <div className="col-md-2">
                                                <Dropdown
                                                    field={
                                                        {
                                                            id: 'goodsExpectedAt',
                                                            label: "Goods Expected ",
                                                            type: "select",
                                                            value: field.goodsExpectedAt,
                                                            required: true,
                                                            error: transporterDetailsError['goodsExpectedAt' + key],
                                                            item: DestinationType,
                                                            onChange: (value) => {
                                                                handleDuplicateInputChange(field.key, 'goodsExpectedAt', value, key);
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        }

                                        <div className="col-md-2">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: 'pickup',
                                                        label: formconstant.pickuptype  ,
                                                        type: "select",
                                                        value: formData?.preTransportation === 1 ? 1 : formData?.preTransportation === 2 ? 2 : field.pickup,
                                                        required: true,
                                                        disable: (formData?.preTransportation === 1 || formData?.preTransportation === 2) ? true : false,
                                                        error: transporterDetailsError['pickup' + key],
                                                        item: PickUpType,
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'pickup', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        {
                                            field.pickup === 1 &&
                                            <>
                                                <div className="col-md-2">
                                                    <Dropdown
                                                        field={
                                                            {
                                                                id: 'vendorId',
                                                                label: formconstant.transportname  ,
                                                                type: "select",
                                                                value: field.vendorId,
                                                                required: true,
                                                                error: transporterDetailsError['vendorId' + key],
                                                                item: transportNameItem,
                                                                onChange: (value) => {
                                                                    handleDuplicateInputChange(field.key, 'vendorId', value, key);
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </>
                                        }

                                        <div className="col-md-2">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: 'pickupVehicleTypeId',
                                                        label: formconstant.pickupvehicaltype  ,
                                                        type: "select",
                                                        value: field.pickupVehicleTypeId,
                                                        required: true,
                                                        error: transporterDetailsError['pickupVehicleTypeId' + key],
                                                        item: vehicalTypeItem,
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'pickupVehicleTypeId', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>

                                        <div className="col-md-2">
                                            <InputField
                                                field={
                                                    {
                                                        id: 'vehicleNumber',
                                                        label: formconstant.vehicalno,
                                                        type: "text",
                                                        value: field.vehicleNumber,
                                                        required: true,
                                                        error: transporterDetailsError['vehicleNumber' + key],
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'vehicleNumber', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <InputField
                                                field={
                                                    {
                                                        id: 'driverName',
                                                        label: formconstant.drivername,
                                                        type: "text",
                                                        value: field.driverName,
                                                        required: true,
                                                        error: transporterDetailsError['driverName' + key],
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'driverName', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <InputField
                                                field={
                                                    {
                                                        id: 'driverNumber',
                                                        label: formconstant.driverno,
                                                        type: "number",
                                                        value: field.driverNumber,
                                                        required: true,
                                                        error: transporterDetailsError['driverNumber' + key],
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'driverNumber', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <InputField
                                                field={
                                                    {
                                                        id: 'packages',
                                                        label: formconstant.packages,
                                                        type: "number",
                                                        value: field.packages,
                                                        required: true,
                                                        error: transporterDetailsError['packages' + key],
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'packages', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <InputField
                                                field={
                                                    {
                                                        id: 'grossWeight',
                                                        label: formconstant.grossweight,
                                                        type: "number",
                                                        value: field.grossWeight,
                                                        required: true,
                                                        error: transporterDetailsError['grossWeight' + key],
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'grossWeight', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        {/* <div className="col-md-2">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: 'goodsCondition',
                                                        label: formconstant.goodsconditions  ,
                                                        type: "select",
                                                        value: field.goodsCondition,
                                                        required: true,
                                                        error: transporterDetailsError['goodsCondition' + key],
                                                        item: GoodsConditions,
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'goodsCondition', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div> */}
                                        {
                                            (field.goodsCondition === 4 || field.goodsCondition === 5) &&
                                            <div className="col-md-2">
                                                <InputField
                                                    field={
                                                        {
                                                            id: 'number',
                                                            label: formconstant.number,
                                                            type: "number",
                                                            value: field.number,
                                                            required: true,
                                                            error: transporterDetailsError['number' + key],
                                                            onChange: (value) => {
                                                                handleDuplicateInputChange(field.key, 'number', value, key);
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        }
                                        {
                                            field.goodsCondition && field.goodsCondition !== 1 && <>
                                                <div className="col-md-3">
                                                    <InputField
                                                        field={
                                                            {
                                                                id: 'comment',
                                                                label: formconstant.comments,
                                                                type: "comment",
                                                                value: field.comment,
                                                                required: true,
                                                                error: transporterDetailsError['comment' + key],
                                                                onChange: (value) => {
                                                                    handleDuplicateInputChange(field.key, 'comment', value, key);
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <InputFileField
                                                        className='mt-3'
                                                        field={{
                                                            id: "uploadFile",
                                                            label: formconstant.uploadpic,
                                                            type: 'file',
                                                            required: true,
                                                            error: transporterDetailsError['uploadFile' + key],
                                                            helperText: 'Upload Pan Card Required',
                                                            onChange: (value) => {
                                                                handleDuplicateInputChange(field.key, 'uploadFile', value, key);
                                                            }
                                                        }}
                                                    />
                                                </div>

                                            </>
                                        }


                                        {
                                            field.pickup === 1 &&
                                            <>
                                                <div className="col-md-2">
                                                    <InputField
                                                        field={
                                                            {
                                                                id: 'cost',
                                                                label: formconstant.cost,
                                                                type: "number",
                                                                value: field.cost,
                                                                required: true,
                                                                error: transporterDetailsError['cost' + key],
                                                                onChange: (value) => {
                                                                    handleDuplicateInputChange(field.key, 'cost', value, key);
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </>
                                        }

                                    </div>
                                </div>

                            </div>
                        ))}

                    </div>
                }
            </>


        );
    }

    const CDDTabHandler = (row) => {
        return <>
            <CreateCddForm
                employeeItem={[]}
                row={row}
                callBack={() => { }}
            />
        </>
    }

    return <>
        <h5>
            Export JOB
        </h5>





        <div className="hr-form-box">

            <div className='Initial_Quote_head cmd'>
                <div><span>Job Id</span> - {formData?.enquiryId}</div>
                <div><span>Shipper Name</span>- {formData?.shipperName}</div>
                <div><span>Organization Name/Biz. Generator</span>- {formData?.bizGenerator}</div>
                <div>
                    <KeyboardArrowUpIcon onClick={() => setOpenTable(!openTable)} className={openTable ? 'rotate_up' : 'rotate_down'} />
                </div>
            </div>

            {
                openTable &&
                <div className='check-customer-table' style={{ textAlign: "left" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>{columnConstant.packages}</th>
                                <th>{formData?.packages}</th>
                                <th>{columnConstant.grosswt}</th>
                                <th>{formData?.grossWeight}</th>
                                <th>{columnConstant.airvolumeweightcbm}</th>
                                <th>{formData?.airVolumeWeight}</th>
                                <th>{"Freight Services"}</th>
                                <th>{formData?.freightServiceType}</th>
                            </tr>
                            <tr>
                                <th>{columnConstant.airportofloading}</th>
                                <th>{formData?.airportOfLoadingName}</th>
                                <th>{columnConstant.airportofdestination}</th>
                                <th>{formData?.airportOfDestinationName}</th>
                                <th>{columnConstant.chargeableweight}</th>
                                <th>{formData?.qtyChargeWeight}</th>
                                <th>{columnConstant.tentativedateofship}</th>
                                <th>{formData?.tentativeDateOfShipment}</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            }

        </div>

        <div>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, value) => { setTabValue(value) }}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Main Details" value="1" />
                        <Tab label="CDD" value="2" />
                        <Tab label="Final Buying" value="3" />
                        <Tab label="Final Selling" value="4" />
                        {/* <Tab label="Flight Tracking" value="5" />
                        <Tab label="Warehouse" value="6" />
                        <Tab label="Airport" value="7" /> */}


                    </TabList>
                </Box>

            </TabContext>
        </div>


        <TabContext value={tabValue} >

            <TabPanel className="p-0 mt-3" value="1">

                <div className="hr-form-box">
                    <div>
                        <div className="row">
                            <div className="col-md-3">
                                <Dropdown
                                    field={{
                                        id: "shipperId",
                                        label: "Shipper ",
                                        value: formData?.shipperId,
                                        required: true,
                                        error: formData?.shipperIderror,
                                        item: costomerItem,
                                        onChange: (value, field) => {
                                            handleBasicInputChange(value, field)
                                            FindShipperAddress(value)
                                        }

                                    }}
                                />
                            </div>
                            {
                                formData?.shipperId && <div className="col-md-3">
                                    <Dropdown
                                        field={{
                                            id: "shipperAddressId",
                                            label: "Shipper Address ",
                                            value: formData?.shipperAddressId,
                                            required: true,
                                            error: formData?.shipperAddressIderror,
                                            item: shipperAddressItem,
                                            onChange: handleBasicInputChange
                                        }}
                                    />
                                </div>
                            }

                            <div className="col-md-3">
                                <Dropdown
                                    field={{
                                        id: "consignee",
                                        label: "Consignee ",
                                        value: formData?.consignee,
                                        required: true,
                                        error: formData?.consigneeerror,
                                        item: consigneeItem,
                                        onChange: (value, field) => {
                                            handleBasicInputChange(value, field)
                                            FindConsigneeAddress(value)

                                        }
                                    }}
                                />
                            </div>
                            {
                                formData?.consignee && <div className="col-md-3">
                                    <Dropdown
                                        field={{
                                            id: "consigneeAddressId",
                                            label: "Consignee Address",
                                            value: formData?.consigneeAddressId,
                                            required: true,
                                            error: formData?.consigneeAddressIderror,
                                            item: consigneeAddressItem,
                                            onChange: handleBasicInputChange
                                        }}
                                    />
                                </div>
                            }

                            <div className="col-md-3">
                                <Dropdown
                                    field={{
                                        id: "notifyParty",
                                        label: "Notify Party ",
                                        value: formData?.notifyParty,
                                        required: true,
                                        error: formData?.notifyPartyerror,
                                        item: notifyPartyItem,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>

                            {formData?.customerType !== 1 && <>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={{
                                            id: "subAgent",
                                            label: "Sub Agent ",
                                            value: formData?.subAgent,
                                            required: true,
                                            error: formData?.subAgenterror,
                                            item: costomerItem,
                                            onChange: handleBasicInputChange
                                        }}
                                    />
                                </div>

                                <div className="col-md-3">
                                    <Dropdown
                                        field={{
                                            id: "onp1",
                                            label: "Onp1  ",
                                            value: formData?.onp1,
                                            required: true,
                                            error: formData?.onp1error,
                                            item: costomerItem,
                                            onChange: handleBasicInputChange
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={{
                                            id: "onp2",
                                            label: "Onp2  ",
                                            value: formData?.onp2,
                                            required: true,
                                            error: formData?.onp2error,
                                            item: costomerItem,
                                            onChange: handleBasicInputChange
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={{
                                            id: "onp3",
                                            label: "Onp3  ",
                                            value: formData?.onp3,
                                            required: true,
                                            error: formData?.onp3error,
                                            item: costomerItem,
                                            onChange: handleBasicInputChange
                                        }}
                                    />
                                </div>

                            </>
                            }



                            {/* <div className="col-md-3">
                        <Dropdown
                            field={{
                                id: "freightPP",
                                label: "Frieght PP ",
                                value: formData?.freightPP,
                                required: true,
                                error: formData?.freightPPerror,
                                item: frightpp,
                                onChange: handleBasicInputChange
                            }}
                        />
                    </div> */}
                            <div className="col-md-3">
                                <Dropdown
                                    field={{
                                        id: "checkList",
                                        label: "Check list ",
                                        value: formData?.checkList,
                                        required: true,
                                        error: formData?.checkListerror,
                                        item: applicableFormat,
                                        onChange: handleBasicInputChange
                                    }}
                                />

                            </div>

                            {formData.checkList === 1 &&
                                <div className="col-md-3">
                                    <InputFileField
                                        className='mt-3'
                                        field={
                                            {
                                                id: "Document",
                                                label: "Upload Document",
                                                type: 'file',
                                                value: '',
                                                required: true,
                                                error: "",
                                                onChange: handleBasicInputChange
                                            }
                                        }
                                    />
                                </div>
                            }


                            <div className="col-md-3">
                                <DateField
                                    field={{
                                        id: "docReceiveDate",
                                        label: "Doc Receive Date ",
                                        value: formData?.docReceiveDate,
                                        required: true,
                                        error: formData?.docReceiveDateerror,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                                <DateField
                                    field={{
                                        id: "shipperInvoiceReceiveDate",
                                        label: "Shipper In. Receive Date ",
                                        value: formData?.shipperInvoiceReceiveDate,
                                        required: true,
                                        error: formData?.shipperInvoiceReceiveDateerror,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                                <Dropdown
                                    field={{
                                        id: "shipperIncoTerms",
                                        label: "Inco Term  ",
                                        value: formData?.shipperIncoTerms,
                                        required: true,
                                        error: formData?.shipperIncoTermserror,
                                        item: incoTermItem,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>


                            <div className="col-md-3">
                                <Dropdown
                                    field={{
                                        id: "checkListApprovedByShipper",
                                        label: "Approved By Shipper ",
                                        value: formData?.checkListApprovedByShipper,
                                        required: true,
                                        error: formData?.checkListApprovedByShippererror,
                                        item: applicableFormat,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>

                            <div className="col-md-3">
                                <Dropdown
                                    field={{
                                        id: "bookingWithAL",
                                        label: "Booking With Airline  ",
                                        value: formData?.bookingWithAL,
                                        required: true,
                                        error: formData?.bookingWithALerror,
                                        item: applicableFormat,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    field={{
                                        id: "allotMawbNumber",
                                        label: "MAWB NO",
                                        type: 'number',
                                        value: formData?.allotMawbNumber,
                                        required: true,
                                        error: formData?.allotMawbNumbererror,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    field={{
                                        id: "allotHawbNumber",
                                        label: "HAWB NO",
                                        type: 'number',
                                        value: formData?.allotHawbNumber,
                                        required: true,
                                        error: formData?.allotHawbNumbererror,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                                <DateField
                                    field={{
                                        id: "inputClearenceDate",
                                        label: "Clearance Date ",
                                        value: formData?.inputClearenceDate,
                                        required: true,
                                        error: formData?.inputClearenceDateerror,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                                <TimeField
                                    field={{
                                        id: "handOverTime",
                                        label: "Handover Time ",
                                        value: formData?.handOverTime,
                                        required: true,
                                        error: formData?.handOverTimeerror,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>

                            <div className="col-md-3">
                                <Dropdown
                                    field={{
                                        id: "preTransportation",
                                        label: "Pre Transportation ",
                                        value: formData?.preTransportation,
                                        required: true,
                                        error: formData?.preTransportationerror,
                                        item: pretransport,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                                <Dropdown
                                    field={{
                                        id: "goodsExpectedAt",
                                        label: "Goods Expected At ",
                                        value: formData?.goodsExpectedAt,
                                        required: true,
                                        error: formData?.goodsExpectedAterror,
                                        item: goodsExpectedItem,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>

                            {
                                formData?.preTransportation === 1 && (formData?.goodsExpectedAt === 1 || formData?.goodsExpectedAt === 3) &&
                                <div className="col-md-3">
                                    <Dropdown
                                        field={{
                                            id: "challanStatus",
                                            label: "Challan Status ",
                                            value: formData?.challanStatus,
                                            required: true,
                                            error: formData?.challanStatuserror,
                                            item: ChallanType,
                                            onChange: handleBasicInputChange
                                        }}
                                    />
                                </div>
                            }


                            <div className="col-md-3">
                                <DateField
                                    field={{
                                        id: "shipperInvoiceDate",
                                        label: "Shipper Invoice Date ",
                                        value: formData?.shipperInvoiceDate,
                                        required: true,
                                        error: formData?.shipperInvoiceDateerror,
                                        onChange: handleBasicInputChange
                                    }}
                                />
                            </div>


                            <div className="col-md-3">

                                <Job_MawbRate
                                    chargeHeadItem={chargeHeadItem}
                                    data={formData.mawbRate}
                                    callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                    fieldData={formData}
                                />

                            </div>

                            <div className="col-md-3">
                                <Job_HawbRate
                                    chargeHeadItem={chargeHeadItem}
                                    data={formData.hawbRate}
                                    callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                    fieldData={formData}
                                />
                            </div>

                            <div className="col-md-3">
                                < ShipperInvoiceNumber
                                    data={formData.shipperInvoiceNumber}
                                    callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                    fieldData={formData}
                                />
                            </div>

                            <div className="col-md-3">
                                < TcNumber

                                    data={formData.tcNumbers}
                                    callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                    fieldData={formData}
                                />
                            </div>
                            <div className="col-md-3">
                                < SlotNumber

                                    data={formData.slotTime}
                                    callBack={(value, id) => handleBasicInputChange(value, { id: id })}
                                    fieldData={formData}
                                />
                            </div>


                        </div>


                        <div className="row mb-4">
                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: "grossWeight",
                                            label: "Gross Weight",
                                            type: 'number',
                                            value: formData.grossWeight,
                                            required: true,
                                            error: formData.grossWeighterror,
                                            // disable: totalPackages === 0 ? false : true,
                                            helperText: 'Packages Required',
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: "qtyChargeWeight",
                                            label: "Chargeable Weight",
                                            type: 'number',
                                            value: formData.qtyChargeWeight,
                                            required: true,
                                            error: formData.qtyChargeWeighterror,
                                            // disable: totalPackages === 0 ? false : true,
                                            helperText: 'Packages Required',
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>
                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: "packages",
                                            label: "Packages",
                                            type: 'number',
                                            value: formData.packages,
                                            required: true,
                                            error: formData.packageserror,
                                            // disable: totalPackages === 0 ? false : true,
                                            helperText: 'Packages Required',
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>



                            <div className="col-md-3">
                                <InputField
                                    field={
                                        {
                                            id: "airVolumeWeight",
                                            label: "Air Volume Weight",
                                            type: 'number',
                                            value: formData.airVolumeWeight,
                                            required: true,
                                            error: formData.airVolumeWeighterror,
                                            // disable: totalPackages === 0 ? false : true,
                                            helperText: 'Packages Required',
                                            onChange: handleBasicInputChange
                                        }
                                    }
                                />
                            </div>


                        </div>


                        {
                            Dimension()
                        }

                    </div>
                </div>

                {TransportMultiDetails()}

                <div className="cmd mt-4">
                    <div></div>
                    <Button variant="contained" onClick={handleFormSubmit}>Submit</Button>
                </div>

            </TabPanel>

            <TabPanel className="p-0 mt-3" value="2">
                {CDDTabHandler({})}
            </TabPanel>

            <TabPanel className="p-0 mt-3" value="3">
                <FinalBuying />
            </TabPanel>

            <TabPanel className="p-0 mt-3" value="4">
                <FinalSelling />
            </TabPanel>
        </TabContext>

    </>
}

export default CreateJobForm;







import { BaseURL } from "../constant";

export const GetAirExportPlannerByPage = (item) => `${BaseURL.URL}Job/GetAirExportPlanner/Get?startDate=${item?.startDate || ''}&endDate=${item?.endDate || ''}&sortKey=${item?.sortKey || ""}&sortDirection=${item?.sortDirection || ''}&itemsPerPage=${item?.itemsPerPage || ''}&page=${item?.page || ''}&status=${item?.status || false}`;
export const GetAirExportPlannerDetailsJobbyId = (item) => `${BaseURL.URL}Job/GetAirExportPlannerDetailsJob/${item.id}`;
export const AirExpoortJobConsolAdd = () => `${BaseURL.URL}Job/AirExportJobConsole/Add`
export const GetConsoleAndSingleJob = () => `${BaseURL.URL}Job/GetConsoleAndSingleJobs`
export const AddSplitJobByEnquiryID = () => `${BaseURL.URL}Job/SplitJob/Add`;
export const updateSplitJobByEnquiryId = () => `${BaseURL.URL}Job/AirExportSplitPlannerJob/Update`;
export const AddAirExportPlannerJob = () => `${BaseURL.URL}Job/AddAirExportPlannerJob/Add`;
export const UpdateAirExportPermanentJob = () => `${BaseURL.URL}Job/AirExportPermanentJob/Update`;
export const UpdateAirExportCDDJob = () => `${BaseURL.URL}Job/AirExportCDDJob/Update`;
export const DeleteJobs = () => `${BaseURL.URL}Job/DumpJobs/Post`;
export const AddPlanForTodayJobs = () => `${BaseURL.URL}Job/PlanForToday/Post`;
export const GetAirlineDetailsForMAWB = (item) => `${BaseURL.URL}Job/GetAirportListForMAWB/Get?${item.stockProvider ? `StockProvider=${item.stockProvider}` : ''}${item.airlineId ? `&AirlineId=${item.airlineId}` : ''}${item.vendorId ? `&VendorId=${item.vendorId}` : ''}`
export const UpdatePlannerSingle = () => `${BaseURL.URL}Job/AirExportSingleRecordJob/Update`;
export const GetAirExportJobStatusByJobId = (item) => `${BaseURL.URL}Job/GetAirExportJobStatus/Get?jobId=${item?.id}`
/// Awb Store 

export const AddAwbStock = () => `${BaseURL.URL}Job/AwbStock/Add`;
export const GetAwbStorebypage = (item) => `${BaseURL.URL}Job/AwbStock/Get?startDate=${item?.startDate || ''}&endDate=${item?.endDate || ''}&sortKey=${item?.sortKey || ""}&sortDirection=${item?.sortDirection || ''}&itemsPerPage=${item?.itemsPerPage || ''}&page=${item?.page || ''}&status=${item?.status || false}`;
export const UpdateAwbStock = () => `${BaseURL.URL}Job/AwbStock/Update`;


// Iata store 
export const AddIataStock = () => `${BaseURL.URL}Job/IATAStock/Add`;
export const GetIataStorebypage = (item) => `${BaseURL.URL}Job/IATAStock/Get?searchKey=${item?.searchKey || ''}&sortKey=${item?.sortKey || ""}&sortDirection=${item?.sortDirection || ''}&itemsPerPage=${item?.itemsPerPage || ''}&page=${item?.page || ''}&status=${item?.status || false}`;
export const UpdateIataStock = () => `${BaseURL.URL}Job/IATAStock/Update`;


// Courier store
export const AddCourierAwbStock = () => `${BaseURL.URL}Job/CourierAwbStock/Add`;
export const GetCourierAwbbypage = (item) => `${BaseURL.URL}Job/CourierAwbStock/Get?searchKey=${item?.searchKey || ''}&sortKey=${item?.sortKey || ""}&sortDirection=${item?.sortDirection || ''}&itemsPerPage=${item?.itemsPerPage || ''}&page=${item?.page || ''}&status=${item?.status || false}`;
export const UpdateCourierAwbStock = () => `${BaseURL.URL}Job/CourierAwbStock/Update`;

//// Hold Awb

export const AddHoldAwb = () => `${BaseURL.URL}Job/HoldAwb/Add`;
export const AddMoveAwb = () => `${BaseURL.URL}Job/AwbStock/Move`;






import React from 'react'
import getFrieghtServices from './getFrieghtServices'

const GetSelectedFreightDetails = (customerType, frieghtService, bizCategory, bizType) => {
    let option = {
        FreightServiceSubType: [],
        IncoTerm: [],
        MultiPickUp: false,
        MultiDelivery: false,
        BizCategoryType: [],
        BizType: [],
        BizSubType: [],
        ONP: {}

    }
    const FrieghtServices = getFrieghtServices(customerType)
    if (frieghtService) {
        const FrieghtServicesDetails = FrieghtServices.find(item => item.name === frieghtService)
        option.FreightServiceSubType = FrieghtServicesDetails.FreightServiceSubType
        option.MultiPickUp = FrieghtServicesDetails.MultiPickUp
        option.MultiDelivery = FrieghtServicesDetails.MultiDelivery
        option.BizCategoryType = FrieghtServicesDetails.BizCategoryType
        option.IncoTerm = FrieghtServicesDetails.IncoTerm
        option.ONP = FrieghtServicesDetails.ONP



        if (option.BizCategoryType.length === 1) {
            option.BizType = option.BizCategoryType[0].BizType
        } else if (bizCategory) {
            const BizCategoryDetails = option.BizCategoryType?.find(item => item.name === bizCategory)
            option.BizType = BizCategoryDetails.BizType
        }

        if (option?.BizType?.length === 1) {
            option.BizSubType = option?.BizType[0].BizSubType
        } else if (bizType) {
            const BizTypeDetails = option.BizType?.find(item => item.name === bizType)
            option.BizSubType = BizTypeDetails?.BizSubType
        }
    }


    return option;

}

export default GetSelectedFreightDetails;
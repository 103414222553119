

import React, { useEffect } from "react";
import { columnConstant } from "../../constant/formconstant";
import { MultiTable } from "../../component/multiTable";
import { useState } from "react";
import companylogo from '../../assets/img/avatars/logonew.jpg'
import GetCaller from "../../../services/apiServices";
import { GetAirExportSellingQuotesData } from "../../../services/APIConstant/enquiryConstant";
import { useDispatch } from "react-redux";
export const PdfAirCustomer = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({})
    const [SellingQuotesData, setSellingQuotesData] = useState({})
    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem('exportEnquiryviewquotedata'));
        if (data) {
            setData(data)

            if (data.id) {
                GetCaller({
                    API: GetAirExportSellingQuotesData,
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: {
                        id: data?.id
                    },
                    callback: ({ response }) => {
                        console.log('GetAirExportSellingQuotesData', response?.data?.data)
                        setSellingQuotesData(response?.data?.data)
                    },

                })
            }
            console.log('exportEnquiryviewquotedata', data)
        }
    }, [])

    return <>

        <div className="hr-form-box">
            <div className="pdfairborder" style={{ border: '2px solid #ddd', borderRadius: "8px" }} >
                <div className="cmd  aircustomerheader my-4 p-3">
                    <div>
                        <h3 style={{ fontWeight: "bold", color: "#A3A0A0" }}><span className="me-2">Agrastha</span>Freight Pvt Ltd.</h3>
                        <p><span><i className="las la-map-marker-alt" ></i>Floor, 6 Club Drive Road,Ghitorni, New Delhi-110030, India</span></p>
                        <p><span><i className="las la-phone" ></i></span> +91-9772299018, 874399018</p>
                        <div className="d-flex">
                            <p className="me-2"><span><i className="las la-envelope" ></i></span>info@agrastha.in</p>
                            <p><span><i className="fas fa-globe" ></i></span>www.agrastha.in</p>
                        </div>
                    </div>
                    <div className="aircustomerLogoside ">
                        <img style={{ height: '94px' }} src={companylogo} />
                        <div> <i>"We buy problems and sell solutions"</i></div>
                        <p> An ISO 9001: 2016 Company<br />
                            CIN#U60231DL2004PTC124368<br />
                            GSTIN: 07AAHCS9096G1ZT
                        </p>
                    </div>
                </div>

                <div className="cmc my-4  ">
                    <h6 className="m-0" style={{ fontWeight: 'bold' }}>AIR COST OFFER</h6>
                </div>


                <div className="row text-center">
                    <div className="col-md-4">
                        <p><span style={{ fontWeight: 'bold', }} >Enquiry Id/Enquiry Date -</span><span> {data?.eid} / {data?.enquiryDate} </span></p>
                    </div>
                    <div className="col-md-4">
                        <h6 style={{ fontWeight: 'bold', }}>SHIPMENT DETAILS</h6>
                    </div>
                    <div className="col-md-4">
                        <p><span style={{ fontWeight: 'bold', }}>Quotation No.</span><span> -QTDEL00001</span></p>
                    </div>
                </div>

                <div className="row w-100 m-0">
                    <div className="col-md-3 p-0">
                        <div className='check-customer-table  viewQuote_tables'>
                            <table>
                                <tr>
                                    <td>{columnConstant.packages}</td>
                                    <td>{data?.packages || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>{columnConstant.chargeableweight}</td>
                                    <td>{data?.is || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>{columnConstant.commodite}</td>
                                    <td>{data?.commodity || 'N/A'}</td>
                                </tr>

                            </table>
                        </div>
                    </div>

                    <div className="col-md-3 p-0">
                        <div className='check-customer-table viewQuote_tables' style={{ textAlign: "left", }}>
                            <table>
                                <tr>
                                    <td>{columnConstant.grosswt}</td>
                                    <td>{data?.grossWeight || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>{columnConstant.airportofloading}</td>
                                    <td>{data?.airportOfLoadingName || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>{columnConstant.pickupaddress}</td>
                                    <td>{data?.is || 'N/A'}</td>
                                </tr>

                            </table>
                        </div>
                    </div>

                    <div className="col-md-3 p-0">
                        <div className='check-customer-table viewQuote_tables' style={{ textAlign: "left", }}>
                            <table>
                                <tr>
                                    <td>{columnConstant.airvolumeweightcbm}</td>
                                    <td>{data?.cbm || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>{columnConstant.airportofdestination}</td>
                                    <td>{data?.airportOfDestinationName || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>{columnConstant.deliveryaddress}</td>
                                    <td>{data?.deliveryAddress || 'N/A'}</td>
                                </tr>

                            </table>
                        </div>
                    </div>
                    <div className="col-md-3 p-0">
                        <div className='check-customer-table viewQuote_tables' style={{ textAlign: "left", }}>
                            <table>
                                <tr>
                                    <td>{columnConstant.incoterms}</td>
                                    <td>{data?.is || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>{columnConstant.exchangerate}</td>
                                    <td>{data?.is || 'N/A'}</td>
                                </tr>


                            </table>
                        </div>
                    </div>
                </div>



                <div className="airfright my-4 ">
                    <div className="cmc my-2">
                        <h6 className="m-0" style={{ fontWeight: 'bold', }}>AIR FREIGHT</h6>
                    </div>
                    <div className='check-customer-table'>
                        <table>
                            <thead>
                                <tr>

                                    <th>{columnConstant.airlines}</th>
                                    <th>{columnConstant.chargehead}</th>
                                    <th>{columnConstant.currency}</th>
                                    <th>{columnConstant.rate}</th>
                                    <th>{columnConstant.unit}</th>
                                    <th>{columnConstant.quantity}</th>
                                    <th>{columnConstant.amount}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    SellingQuotesData?.airFreight?.length !== 0 ?
                                        SellingQuotesData?.airFreight?.map((res) => (
                                            <tr>
                                                <td>{res?.airlineName} {<input type="checkbox" className='mx-3' />}</td>
                                                <td>{res?.chargeHeadName}</td>
                                                <td>{res?.currencyCode}</td>
                                                <td>{res?.afRate}</td>
                                                <td>{res?.unitName}</td>
                                                <td>{res?.qtyChargeWeight}</td>
                                                <td>{res?.totalAmount}</td>
                                            </tr>
                                        ))
                                        : <tr>
                                            <td colSpan={7} className="text-center">No Data Found</td>

                                        </tr>
                                }
                               

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="localcharges mt-4">
                    <div className="cmc my-2">
                        <h6 className="m-0" style={{ fontWeight: 'bold', }}>OTHER LOCAL CHARGES</h6>
                    </div>
                    <div className='check-customer-table'>
                        <table>
                            <thead>

                                <tr>

                                    <th>{columnConstant.sno}</th>
                                    <th>{columnConstant.chargehead}</th>
                                    <th>{columnConstant.currency}</th>
                                    <th>{columnConstant.rate}</th>
                                    <th>{columnConstant.unit}</th>
                                    <th>{columnConstant.quantity}</th>
                                    <th>{columnConstant.amount}</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    SellingQuotesData?.otherCharges?.length !== 0 ?
                                        SellingQuotesData?.otherCharges?.map((res) => (
                                            <tr>
                                                <td>{res?.id}</td>
                                                <td>{res?.chargeHeadName}</td>
                                                <td>{res?.currencyCode}</td>
                                                <td>{res?.rate}</td>
                                                <td>{res?.unitName}</td>
                                                <td>{res?.qtyChargeWeight}</td>
                                                <td>{res?.amount}</td>
                                            </tr>
                                        ))
                                        : <tr>
                                            <td colSpan={7} className="text-center">No Data Found</td>

                                        </tr>
                                }




                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="my-4 ms-4">

                    <p>TERMS & CONDITION </p>
                    <div className="" style={{ paddingLeft: "27px" }} >
                        <p className="p-0 m-0" style={{ fontWeight: "bold", }} >1. Other Charges as per Agreement</p>
                        <p style={{ fontSize: "16px" }} >2. GST as per Government rules will be additional</p>
                    </div>
                </div>
                <div className="customer-pdf-base" >
                    <p className="">International Freight Forwarders, Consolidators and Customers Broke</p>
                </div>
            </div>
        </div>


    </>

}


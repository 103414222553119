// export const BaseURL = {
//     URL: `https://localhost:7110/`
// }

export const BaseURL = {
    URL: process.env.REACT_APP_api_base_url
}


//Login Credentials
export const LoginPost = () => `${BaseURL.URL}Account/SignIn`;


// Financial Year Master
export const GetFinancialYear = (item) => `${BaseURL.URL}Master/FinancialYear?status=${item?.status || false}`;







// Area Master 
export const GetMasterArea = (e) => `${BaseURL.URL}Master/Area/${e}`;
export const GetMasterAreaByPage = (item) => `${BaseURL.URL}Master/Area?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item.status || false}`
export const PostMasterArea = () => `${BaseURL.URL}Master/Area`;
export const UpdateMasterArea = () => `${BaseURL.URL}Master/UpdateArea`;
export const UpdateMasterAreaStatus = (item) => `${BaseURL.URL}Master/AreaStatus?id=${item.id}`;


// Sector Master
export const GetMasterSectorByPage = (item) => `${BaseURL.URL}Master/Sector?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostMasterSector = () => `${BaseURL.URL}Master/Sector`;
export const UpdateMasterSector = () => `${BaseURL.URL}Master/UpdateSector`;
export const UpdateMasterSectorStatus = (item) => `${BaseURL.URL}Master/SectorStatus?id=${item.id}`;

// Country Master
export const GetMasterCountryByPage = (item) => `${BaseURL.URL}Master/Country?itemsPerPage=${item.itemsPerPage || ""}&page=${item.page || ""}&status=${item.status || false}`;
export const PostMasterCountry = () => `${BaseURL.URL}Master/Country`;
export const UpdateMasterCountry = () => `${BaseURL.URL}Master/UpdateCountry`;
export const UpdateMasterCountryStatus = (item) => `${BaseURL.URL}Master/CountryStatus?id=${item.id}`



// State Master
export const GetMasterState = () => `${BaseURL.URL}Master/State/1`;
export const GetMasterStateByPage = (item) => `${BaseURL.URL}Master/State?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostMasterState = () => `${BaseURL.URL}Master/State`;
export const UpdateMasterState = () => `${BaseURL.URL}Master/UpdateState`;
export const UpdateMasterStateStatus = (item) => `${BaseURL.URL}Master/StateStatus?id=${item.id}`


// City Master
export const GetMasterCity = () => `${BaseURL.URL}Master/City/1`;
export const GetMasterCityByPage = (item) => `${BaseURL.URL}Master/City?itemsPerPage=${item.itemsPerPage || ""}&page=${item.page || ""}&status=${item.status || false}`;
export const PostMasterCity = () => `${BaseURL.URL}Master/City`;
export const UpdateMasterCity = () => `${BaseURL.URL}Master/UpdateCity`;
export const UpdateMasterCityStatus = (item) => `${BaseURL.URL}Master/CityStatus?id=${item.id}`;


// Division Master
export const GetMasterDivision = () => `${BaseURL.URL}Master/Division/1`;
export const GetMasterDivisionByPage = (item) => `${BaseURL.URL}Master/Division?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostMasterDivision = () => `${BaseURL.URL}Master/Division`;
export const UpdateMasterDivision = () => `${BaseURL.URL}Master/UpdateDivision`;
export const UpdateMasterDivisionStatus = (item) => `${BaseURL.URL}Master/DivisionStatus?id=${item.id}`;




// Department Master
export const GetMasterDepartment = () => `${BaseURL.URL}Master/Department/1`;
export const GetMasterDepartmentByPage = (item) => `${BaseURL.URL}Master/Department?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostMasterDepartment = () => `${BaseURL.URL}Master/Department`;
export const UpdateMasterDepartment = () => `${BaseURL.URL}Master/UpdateDepartment`;
export const UpdateMasterDepartmentStatus = (item) => `${BaseURL.URL}Master/DepartmentStatus?id=${item.id}`;




// SubDepartment Master
export const GetMasterSubDepartment = () => `${BaseURL.URL}Master/SubDepartment/1`;
export const GetMasterSubDepartmentByPage = (item) => `${BaseURL.URL}Master/SubDepartment?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostMasterSubDepartment = () => `${BaseURL.URL}Master/SubDepartment`;
export const UpdateMasterSubDepartment = () => `${BaseURL.URL}Master/UpdateSubDepartment`;
export const UpdateMasterSubDepartmentStatus = (item) => `${BaseURL.URL}Master/SubDepartmentStatus?id=${item.id}`;



// AccountGroup Master
export const GetMasterAccountGroup = () => `${BaseURL.URL}Master/AccountGroup/1`;
export const GetMasterAccountGroupByPage = (item) => `${BaseURL.URL}Master/AccountGroup?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostMasterAccountGroup = () => `${BaseURL.URL}Master/AccountGroup`;
export const UpdateMasterAccountGroup = () => `${BaseURL.URL}Master/UpdateAccountGroup`;
export const UpdateMasterAccountGroupStatus = (item) => `${BaseURL.URL}Master/AccountGroupStatus?id=${item.id}`;




// PrimaryGroup Master
export const GetMasterPrimaryGroup = () => `${BaseURL.URL}Master/PrimaryGroup/1`;
export const GetMasterPrimaryGroupByPage = (item) => `${BaseURL.URL}Master/PrimaryGroup?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostMasterPrimaryGroup = () => `${BaseURL.URL}Master/PrimaryGroup`;
export const UpdateMasterPrimaryGroup = () => `${BaseURL.URL}Master/UpdatePrimaryGroup`;
export const UpdateMasterPrimaryGroupStatus = (item) => `${BaseURL.URL}Master/PrimaryGroupStatus?id=${item.id}`;


// SubGroup Master
export const GetMasterSubGroup = () => `${BaseURL.URL}Master/SubGroup/1`;
export const GetMasterSubGroupByPage = (item) => `${BaseURL.URL}Master/SubGroup?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item.status || false}`;
export const PostMasterSubGroup = () => `${BaseURL.URL}Master/SubGroup`;
export const UpdateMasterSubGroup = () => `${BaseURL.URL}Master/UpdateSubGroup`;
export const UpdateMasterSubGroupStatus = (item) => `${BaseURL.URL}Master/SubGroupStatus?id=${item.id}`;



// Branch Master
export const GetMasterBranch = () => `${BaseURL.URL}Master/Branch/1`;
export const GetMasterBranchByPage = (item) => `${BaseURL.URL}Master/Branch?itemsPerPage=${item.itemsPerPage || ""}&page=${item.page || ""}&status=${item.status || false}`;
export const PostMasterBranch = () => `${BaseURL.URL}Master/Branch`;
export const UpdateMasterBranch = () => `${BaseURL.URL}Master/UpdateBranch`;
export const UpdateMasterBranchStatus = (item) => `${BaseURL.URL}Master/BranchStatus?id=${item.id}`;


// Designation Master
export const GetMasterDesignation = () => `${BaseURL.URL}Master/Designation/1`;
export const GetMasterDesignationByPage = (item) => `${BaseURL.URL}Master/Designation?itemsPerPage=${item.itemsPerPage || ""}&page=${item.page || ''}&status=${item.status || false}`;
export const PostMasterDesignation = () => `${BaseURL.URL}Master/Designation`;
export const UpdateMasterDesignation = () => `${BaseURL.URL}Master/UpdateDesignation`;
export const UpdateMasterDesignationStatus = (item) => `${BaseURL.URL}Master/DesignationStatus?id=${item.id}`;


// Vendor Master Type
export const GetMasterVendorType = (item) => `${BaseURL.URL}Master/VendorType/${item.id}`;
export const GetMasterVendorTypeByPage = (item) => `${BaseURL.URL}Master/VendorType?itemsPerPage=${item.itemsPerPage || ""}&page=${item.page || ''}&status=${item.status || false}`;
export const PostMasterVendorType = () => `${BaseURL.URL}Master/VendorType`;
export const UpdateMasterVendorType = () => `${BaseURL.URL}Master/UpdateVendorType`;
export const UpdateMasterVendorTypeStatus = (item) => `${BaseURL.URL}Master/VendorTypeStatus?id=${item.id}`;


// Master Account Ledger
export const GetMasterAccountLedger = () => `${BaseURL.URL}Master/AccountLedger/1`;
export const GetMasterAccountLedgerByPage = (item) => `${BaseURL.URL}Master/AccountLedger?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item?.status || false}`;
export const PostMasterAccountLedger = () => `${BaseURL.URL}Master/AccountLedger`;
export const UpdateMasterAccountLedger = () => `${BaseURL.URL}Master/UpdateAccountLedger`;
export const UpdateMasterAccountLedgerStatus = (item) => `${BaseURL.URL}Master/AccountLedgerStatus?id=${item.id}`;

// Income Expense Accounting ledger
export const GetIncomeExpenseAccountLedgerByPage = (item) => `${BaseURL.URL}Master/IncomeExpenseLedger?itemsPerPage=${item?.itemsPerPage || ''}&page=${item?.page || ""}&status=${item?.status || false}`

// Master  NetworkType
export const GetMasterNetworkType = () => `${BaseURL.URL}Master/NetworkType/1`;
export const GetMasterNetworkTypeByPage = (item) => `${BaseURL.URL}Master/NetworkType?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ""}&status=${item.status || false}`;
export const PostMasterNetworkType = () => `${BaseURL.URL}Master/NetworkType`;
export const UpdateMasterNetworkType = () => `${BaseURL.URL}Master/UpdateNetworkType`;
export const UpdateMasterNetworkTypeStatus = (item) => `${BaseURL.URL}Master/NetworkTypeStatus?id=${item.id}`;

// Master  BizInstituteType
export const GetMasterBizInstituteTypeDetails = (item) => `${BaseURL.URL}Master/BizInstituteType/${item.id}`;
export const GetMasterBizInstituteTypeByPage = (item) => `${BaseURL.URL}Master/BizInstituteType?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item?.status || false}`
export const PostMasterBizInstituteType = () => `${BaseURL.URL}Master/BizInstituteType`;
export const UpdateMasterBizInstituteType = () => `${BaseURL.URL}Master/UpdateBizInstituteType`;
export const UpdateMasterBizInstituteTypeStatus = (item) => `${BaseURL.URL}Master/BizInstituteTypeStatus?id=${item.id}`;


// Master SplOperationReqType
export const GetSplOperationReqType = () => `${BaseURL.URL}Master/SplOperationReqType/1`;
export const GetSplOperationReqTypeByPage = (item) => `${BaseURL.URL}Master/SplOperationReqType?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostSplOperationReqType = () => `${BaseURL.URL}Master/SplOperationReqType`;
export const UpdateSplOperationReqType = () => `${BaseURL.URL}Master/UpdateSplOperationReqType`;
export const UpdateSplOperationReqTypeStatus = (item) => `${BaseURL.URL}Master/SplOperationReqTypeStatus?id=${item.id}`;

//TDSMaster
export const GetTDSMasterDetailsById = (item) => `${BaseURL.URL}Master/TDSMaster/${item.id}`;
export const GetTDSMasterBizInstituteDetailsById = (item) => `${BaseURL.URL}Master/TDSBizIntituteList?tdsId=${item.id}`;
export const GetTDSMasterByPage = (item) => `${BaseURL.URL}Master/TDSMaster?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item?.status || false}`;
export const PostTDSMaster = () => `${BaseURL.URL}Master/TDSMaster`;
export const UpdateTDSMaster = () => `${BaseURL.URL}Master/UpdateTDSMaster`;
export const UpdateTDSMasterStatus = (item) => `${BaseURL.URL}Master/TDSMasterStatus?id=${item.id}`;

// Master / SACCode

export const GetSACMaster = () => `${BaseURL.URL}Master/SACCode/1`;
export const GetSACMasterByPage = (item) => `${BaseURL.URL}Master/SACCode?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}`;
export const PostSACMaster = () => `${BaseURL.URL}Master/SACCode`;
export const UpdateSACMaster = () => `${BaseURL.URL}Master/UpdateSACCode`;
export const UpdateSACMasterStatus = (item) => `${BaseURL.URL}Master/SACCodeStatus?id=${item.id}`;

//  Master / ReasonType

export const GetReasonType = () => `${BaseURL.URL}Master/ReasonType`;
export const GetReasonTypeByPage = (item) => `${BaseURL.URL}Master/ReasonType?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostReasonType = () => `${BaseURL.URL}Master/ReasonType`;
export const UpdateReasonType = () => `${BaseURL.URL}Master/UpdateReasonType`;
export const UpdateReasonTypeStatus = (item) => `${BaseURL.URL}Master/ReasonTypeStatus?id=${item.id}`;

//  VendorMaster

export const GetVendorMasterById = (item) => `${BaseURL.URL}Master/VendorMaster/${item.id}`;
export const GetVendorMasterByPage = (item) => `${BaseURL.URL}Master/VendorMaster?itemsPerPage=${item.itemsPerPage || ""}&page=${item.page || ''}&status=${item.status || false}`;
export const PostVendorMaster = () => `${BaseURL.URL}Master/VendorMaster`;
export const UpdateVendorMaster = () => `${BaseURL.URL}Master/UpdateVendorMaster`;
export const UpdateVendorMasterStatus = (item) => `${BaseURL.URL}Master/VendorMasterStatus?id=${item.id}`;

//  AssetMaster
export const GetAssetMaster = () => `${BaseURL.URL}Master/AssetMaster`;
export const GetAssetEmployee = (item) => `${BaseURL.URL}Master/AssetEmployee?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item?.status || false}`;
export const GetAssetMasterByPage = (item, enquirybranch) => `${BaseURL.URL}Master/AssetMaster?branchId=${enquirybranch}&itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostAssetMaster = () => `${BaseURL.URL}Master/AssetMaster`;
export const UpdateAssetMaster = () => `${BaseURL.URL}Master/UpdateAssetMaster`;
export const UpdateAssetMasterStatus = (item) => `${BaseURL.URL}Master/AssetMasterStatus?id=${item.id}`;

//  AssetCategory

export const GetAssetCategory = () => `${BaseURL.URL}Master/AssetCategory`;
export const GetAssetCategoryByPage = (item, enquirybranch) => `${BaseURL.URL}Master/AssetCategory?branchId=${enquirybranch}&itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostAssetCategory = () => `${BaseURL.URL}Master/AssetCategory`;
export const UpdateAssetCategory = () => `${BaseURL.URL}Master/UpdateAssetCategory`;
export const UpdateAssetCategoryStatus = (item) => `${BaseURL.URL}Master/AssetCategoryStatus?id=${item.id}`;

//  MasterTaxRate

export const GetMasterTaxRate = () => `${BaseURL.URL}Master/TaxRate`;
export const GetMasterTaxRateByPage = (item) => `${BaseURL.URL}Master/TaxRate?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}`;
export const PostMasterTaxRate = () => `${BaseURL.URL}Master/TaxRate`;
export const UpdateMasterTaxRate = () => `${BaseURL.URL}Master/UpdateTaxRate`;
export const UpdateMasterTaxRateStatus = (item) => `${BaseURL.URL}Master/TaxRateStatus?id=${item.id}`;

// GSA MASTER
export const GetGSAMaster = () => `${BaseURL.URL}Master/GSA`;
export const GetGSAMasterByPage = (item) => `${BaseURL.URL}Master/GSA?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item.status || false}`;
export const PostGSAMaster = () => `${BaseURL.URL}Master/GSA`;
export const UpdateGSAMaster = () => `${BaseURL.URL}Master/UpdateGSA`;
export const UpdateGSAMasterStatus = (item) => `${BaseURL.URL}Master/GSAStatus?id=${item.id}`;

// GST Status
export const GetGSTStatus = () => `${BaseURL.URL}Master/GSTStatus`;
export const GetGSTStatusByPage = (item) => `${BaseURL.URL}Master/GSTStatus?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostGSTStatus = () => `${BaseURL.URL}Master/GSTStatus`;
export const UpdateGSTStatus = () => `${BaseURL.URL}Master/UpdateGSTStatus`;
export const UpdateGSTStatusStatus = (item) => `${BaseURL.URL}Master/GSTStatusStatus?id=${item.id}`;

//AirportMaster
export const GetAirportMasterByPage = (item) => `${BaseURL.URL}Master/AirportMaster?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ""}&status=${item.status || false}`;
export const PostAirportMaster = () => `${BaseURL.URL}Master/AirportMaster`;
export const UpdateAirportMaster = () => `${BaseURL.URL}Master/UpdateAirportMaster`;
export const UpdateAirportMasterStatus = (item) => `${BaseURL.URL}Master/AirportMasterStatus?id=${item.id}`;

//AirlineMaster
export const GetAirlineMasterDetailsById = (item) => `${BaseURL.URL}Master/AirlineMaster/${item.id}`;
export const GetAirlineMasterByPage = (item) => `${BaseURL.URL}Master/AirlineMaster?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item?.status || false}`;
export const PostAirlineMaster = () => `${BaseURL.URL}Master/AirlineMaster`;
export const UpdateAirlineMaster = () => `${BaseURL.URL}Master/UpdateAirlineMaster`;
export const UpdateAirlineMasterStatus = (item) => `${BaseURL.URL}Master/AirlineMasterStatus?id=${item.id}`;

// UnitMaster
export const GetUnitMasterByPage = (item) => `${BaseURL.URL}Master/UnitMaster?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ""}&status=${item.status || false}`;
export const PostUnitMaster = () => `${BaseURL.URL}Master/UnitMaster`;
export const UpdateUnitMaster = () => `${BaseURL.URL}Master/UpdateUnitMaster`;
export const UpdateUnitMasterStatus = (item) => `${BaseURL.URL}Master/UnitMasterStatus?id=${item.id}`;

// PackageMaster
export const GetPackageMasterByPage = (item) => `${BaseURL.URL}Master/PackageMaster?itemsPerPage=${item.itemsPerPage || ""}&page=${item.page || ""}&status=${item.status || false}`;
export const PostPackageMaster = () => `${BaseURL.URL}Master/PackageMaster`;
export const UpdatePackageMaster = () => `${BaseURL.URL}Master/UpdatePackageMaster`;
export const UpdatePackageMasterStatus = (item) => `${BaseURL.URL}Master/PackageMasterStatus?id=${item.id}`;

// ChargeHead
export const GetChargeHeadById = (item) => `${BaseURL.URL}Master/ChargeHead/${item.id}`;
export const GetChargeHeadByPage = (item) => `${BaseURL.URL}Master/ChargeHead?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item.status || false}`;
export const PostChargeHead = () => `${BaseURL.URL}Master/ChargeHead`;
export const UpdateChargeHead = () => `${BaseURL.URL}Master/UpdateChargeHead`;
export const UpdateChargeHeadStatus = (item) => `${BaseURL.URL}Master/ChargeHeadStatus?id=${item.id}`;


// Notifylist
export const GetNotifyListById = (item) => `${BaseURL.URL}Master/NotifyList/${item.id}`;
export const GetNotifyListByPage = (item) => `${BaseURL.URL}Master/NotifyList?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item?.status || false}`;
export const PostNotifyList = () => `${BaseURL.URL}Master/NotifyList`;
export const UpdateNotifyList = () => `${BaseURL.URL}Master/UpdateNotifyList`;
export const UpdateNotifyListStatus = (item) => `${BaseURL.URL}Master/NotifyListStatus?id=${item.id}`;
// New Employee Branch 

export const GetEmployeeByPage = (item) => `${BaseURL.URL}Employee/Search?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item.status || false}`;
export const GetAllEmployee = () => `${BaseURL.URL}Employee/Search?status=${true}`;
export const PostEmployeeAdd = () => `${BaseURL.URL}Employee/Add`;
export const UpdateEmployeeStatus = (item) => `${BaseURL.URL}Employee/Status/${item.id}`;
export const GetEmployeeMasterData = () => `${BaseURL.URL}Employee/MasterData`;

// New Marketing Branch 
export const GetNewLeadsByPage = (item) => `${BaseURL.URL}Marketing/Search?state=1&itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const GetMaterializedLeadsByPage = (item) => `${BaseURL.URL}Marketing/Search?state=2&itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const GetDeadLeadsByPage = (item) => `${BaseURL.URL}Marketing/Search?state=3&itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostMarketingAdd = () => `${BaseURL.URL}Marketing/Add`;
export const UpdateMarketingAdd = () => `${BaseURL.URL}Marketing/Update`;
export const PostLeadLog = () => `${BaseURL.URL}Marketing/AddLeadLog`;
export const GetMarketingLeadLogs = (id) => `${BaseURL.URL}Marketing/GetLeadLogs/${id}`;


// Customer
export const GetCustomerMasterData = () => `${BaseURL.URL}Customer/MasterData`;
export const GetCustomerByPage = (item) => `${BaseURL.URL}Customer/List?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item.status || false}&searchKey=${item.searchKey || ''}`;
export const GetCustomerBySearch = (item) => `${BaseURL.URL}Customer/List`;
export const GetCheckCustomer = (item) => `${BaseURL.URL}Customer/Search?searchKey=${item.searchKey || ""}&sortKey=${item.sortKey || ""}&sortDirection=${item.sortDirection || ""}&gstNumber=${item.gstNumber || ""}&gloablAlliance=${item.gloablAlliance || ""}&itemsPerPage=${item.itemsPerPage || ""}&page=${item.page || ""}&customerType=${item.customerType || ""}&status=${item.status || false}`

export const GetCustomerContacts = (item) => `${BaseURL.URL}Customer/Contacts?branchId=${item.branchId}`;
export const GetCustomerContactsStatus = (id) => `${BaseURL.URL}Customer/ContactStatus/${id}`;
export const GetCustomerContactDelete = (id) => `${BaseURL.URL}Customer/DeleteContact/${id}`;

export const PostCreateCustomer = () => `${BaseURL.URL}Customer/Add`;
export const UpdateCustomerStatus = (item) => `${BaseURL.URL}Customer/Status/${item.id}`;
export const PostCustomerAddBranch = () => `${BaseURL.URL}Customer/AddBranch`;
export const PostCustomerAddContact = () => `${BaseURL.URL}Customer/AddContact`;

// Misc Customer For Third Country
export const GetThirdCountryMiscCustomerByPage = (item) => `${BaseURL.URL}Customer/ThirdCountryMiscellaneousCustomerList?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item.status || false}&searchKey=${item.searchKey || ''}`;
export const UpdateThirdCountryMiscCustomerStatus = (item) => `${BaseURL.URL}Customer/ThirdCountryMiscellaneousCustomerStatus/${item.id}`;
export const GetCheckThirdCountryMiscCustomer = (item) => `${BaseURL.URL}Customer/ThirdCountryMiscellaneousCustomerSearch?searchKey=${item.searchKey || ""}&sortKey=${item.sortKey || ""}&sortDirection=${item.sortDirection || ""}&gstNumber=${item.gstNumber || ""}&gloablAlliance=${item.gloablAlliance || ""}&itemsPerPage=${item.itemsPerPage || ""}&page=${item.page || ""}&ThirdCountryMiscellaneousCustomerType=${item.customerType || ""}&status=${item.status || false}`
export const PostThirdCountryCreateCustomer = () => `${BaseURL.URL}Customer/ThirdCountryMiscellaneousCustomerAdd`;
export const PostThirdCountryCustomerAddBranch = () => `${BaseURL.URL}Customer/AddThirdCountryMiscellaneousCustomerBranch`;
export const PostThirdCountryCustomerAddContact = () => `${BaseURL.URL}Customer/AddThirdCountryMiscellaneousCustomerContact`;
export const GetThirdCountryCustomerContacts = (item) => `${BaseURL.URL}Customer/ThirdCountryMiscellaneousCustomerContacts?branchId=${item.branchId}`;
export const GetThirdCountryCustomerContactDelete = (id) => `${BaseURL.URL}Customer/ThirdCountryMiscellaneousCustomerDeleteContact/${id}`;
export const GetThirdCountryCustomerContactsStatus = (id) => `${BaseURL.URL}Customer/ThirdCountryMiscellaneousCustomerContactStatus/${id}`;

// Notify
export const GetNotifyMasterByPage = (item) => `${BaseURL.URL}Master/NotifyList?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item.status || false}`;
export const PostNotifyMaster = () => `${BaseURL.URL}Master/NotifyList`;
export const UpdateNotifyMaster = () => `${BaseURL.URL}Master/UpdateNotifyList`;
export const UpdateNotifyMasterStatus = (item) => `${BaseURL.URL}Master/NotifyListStatus?id=${item.id}`;
export const GetNotifyMasterById = (item) => `${BaseURL.URL}Master/NotifyList/${item.id}`;

// SubAgency Shipper
// export const PostSubAgencyShipperMaster = () => `${BaseURL.URL}Shipper/SubAgentShipper`;
export const PostSubAgencyShipperMaster = () => `${BaseURL.URL}Shipper/SubAgentShipper`;
export const UpdateSubAgencyShipperMaster = () => `${BaseURL.URL}Shipper/UpdateSubAgentShipper`;
export const GetSubAgentShipperByPage = (item) => `${BaseURL.URL}Shipper/SubAgentShipper?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item?.status || false}`;
export const PostSubAgentShipper = () => `${BaseURL.URL}Shipper/SubAgentShipper`;
export const UpdateSubAgentShipper = () => `${BaseURL.URL}Shipper/UpdateSubAgentShipper`;
export const UpdateSubAgentShipperStatus = (item) => `${BaseURL.URL}Shipper/SubAgentShipperStatus?id=${item.id}`;
export const GetSubAgentShipperById = (item) => `${BaseURL.URL}Shipper/SubAgentShipper/${item.id}`;

//Consigneesubagency
export const GetConsigneeSubAgencyPage = (item) => `${BaseURL.URL}Customer/SearchConsigneeSubAgency?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item.status || false}`;
export const PostConsigneeSubAgency = () => `${BaseURL.URL}Customer/AddConsigneeSubAgency`;
export const UpdateConsigneeSubAgency = () => `${BaseURL.URL}Customer/UpdateConsigneeSubAgency`;
export const UpdateConsigneeSubAgencyStatus = (item) => `${BaseURL.URL}Customer/UpdateConsigneeSubAgencyStatus/${item.id}`;
export const GetConsigneeSubAgencyById = (item) => `${BaseURL.URL}Customer/AddConsigneeSubAgency/${item.id}`;

// SubAgency Shipper Address
export const GetSubAgentShipperAddressByPage = (item) => `${BaseURL.URL}Shipper/SubAgentAddress?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostSubAgentAddressShipper = () => `${BaseURL.URL}Shipper/SubAgentAddress`;
export const UpdateSubAgentShipperAddress = () => `${BaseURL.URL}Shipper/UpdateSubAgentAddress`;
export const UpdateSubAgentShipperAddressStatus = (item) => `${BaseURL.URL}Shipper/SubAgentShipperAddressStatus?id=${item.id}`;
export const GetSubAgentShipperAddressById = (item) => `${BaseURL.URL}Shipper/SubAgentShipperAddress/${item.id}`;

// SubAgency consignee Address
export const GetSubAgentConsigneeAddressByPage = (item) => `${BaseURL.URL}Customer/SearchConsigneeSubAgencyAddress?itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostSubAgentConsigneeAddress = () => `${BaseURL.URL}Customer/AddConsigneeSubAgencyAddress`;
export const UpdateSubAgentConsigneeAddress = () => `${BaseURL.URL}Customer/UpdateConsigneeSubAgencyAddress`;
export const UpdateSubAgentConsigneeAddressStatus = (item) => `${BaseURL.URL}Customer/ConsigneeSubAgencyAddressStatus?id=${item.id}`;
export const GetSubAgentConsigneeAddressById = (item) => `${BaseURL.URL}Customer/UpdateConsigneeSubAgencyAddressStatus/${item.id}`;

// vehicle Name Master

export const GetVehicalTypeByPage = (item) => `${BaseURL.URL}Master/VehicleType?status=${item.status || false}&date=${item.date || ""}&itemsPerPage=${item.itemsPerPage || ""}&page=${item.page || ""}`;
export const PostVehicalType = () => `${BaseURL.URL}Master/VehicleType`;
export const UpdateVehicalType = () => `${BaseURL.URL}Master/UpdateVehicleType`;
export const UpdateVehicalTypeStatus = (item) => `${BaseURL.URL}Master/VehicleTypeStatus?id=${item.id}`;

// hawb Master Network
export const GetHawbByPage = (item) => `${BaseURL.URL}Job/AirExportHawb`;
// export const GetHawbByPage = (item) => `${BaseURL.URL}Job/AirExportHawb?=${item.status || false}`;
export const PostHawb = () => `${BaseURL.URL}Job/AirExportHawb/Add`;
export const UpdateHawb = () => `${BaseURL.URL}Job/AirExportHawb/Update`;

// mawb Master Network

export const GetMawbByPage = (item) => `${BaseURL.URL}Job/AirExportMawb??itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=false&status=${item.status || false}`;
export const PostMawb = () => `${BaseURL.URL}Job/AirExportMawb/Add`;
export const UpdateMawb = () => `${BaseURL.URL}Job/AirExportMawb/Update`;

// CragoType

export const GetCargoTypebyId = (item) => `${BaseURL.URL}Master/CargoType/${item.id}`;
export const GetCragoTypeByPage = (item) => `${BaseURL.URL}Master/CargoType?itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}&status=${item?.status || false}`;
export const PostCragoType = () => `${BaseURL.URL}Master/CargoType`;
export const UpdateCragoType = () => `${BaseURL.URL}Master/UpdateCargoType`;
export const UpdateCargoTypeStatus = (item) => `${BaseURL.URL}Master/CargoTypeStatus?id=${item.id}`;


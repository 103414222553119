import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetSubAgentShipperAddressByPage, GetGSAMasterByPage, UpdateSubAgentShipperStatus, GetSubAgentShipperByPage, PostMasterState, PostMasterCity, PostMasterCountry } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { creategsamaster, createtdsmaster, subagencyshipperform } from '../../../navigation/constant';

import { useDispatch } from 'react-redux';
import { CustomerTable } from '../customer/customerTable';
import CustomerContactDetail from '../customer/customerContactDetail';
import CustomerBranch from '../customer/customerBranchForm';
import CircularWithValueLabel from '../../component/field/circleProgressBar';
import { ConsigneeSubAgencyAddressForm, SubAgencyAddressForm } from './SubAgencyAddressShipper';
import GetCaller from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};

const SubAgencyShipperList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sectorMasterData, setSectorMasterData] = useState([]);
    const [subAgencyAddress, setSubAgencyAddress] = useState([]);
    const [totalAddressdatalength, setTotalAddressDataLength] = useState(0);
    const [totaldatalength, setTotalDataLength] = useState(0);
    const [countryItem, setCountryItem] = useState([]);
    const [stateData, setstateData] = useState([])
    const [cityData, setcityData] = useState([])
    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetSubAgentShipperByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        });
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

        GetCaller({
            API: PostMasterCountry,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newcountrydata = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }));
                setCountryItem(newcountrydata)
            }
 
        });

        // State Data
        GetCaller({
            API: PostMasterState,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                setstateData(response?.data?.data?.records)
            }
        });

        //CityData
        GetCaller({
            API: PostMasterCity,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                setcityData(response?.data?.data?.records)
            }
        });

    }, []);

    const setTableDataHandler = ({ response }) => {

        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res?.id,
            name: res?.name,
            bizInstituteId: res?.bizInstituteId,
            code: res?.code,
            cin: res?.cin,
            subAgentId: res?.subAgentId,
            subAgentName: res?.subAgentName,
            countryId: res?.countryId,
            stateId: res?.stateId,
            cityId: res?.cityId,
            address1: res?.address1,
            address2: res?.address2,
            address3: res?.address3,
            pinCode: res?.pinCode,
            mobileNumber: res?.mobileNumber,
            telephone: res?.telephone,
            fax: res?.fax,
            telfax: res?.telfax,
            email: res?.email,
            contactPerson: res?.contactPerson,
            gstNumber: res?.gstNumber,
            pan: res?.pan,
            tan: res?.tan,
            iec: res?.iec,
            openAsNotify: true,
            documentPath: res?.documentPath,
            remarks: res?.remarks,
            status: true,
            branchId: res?.branchId,
            bankDetails: res.bankDetails,
            //customerBranchList : res.subAgentAddress,
            //customerBranchList : { ...res.subAgentAddress, bankName: res.bankDetails[0].name }
            customerBranchList: res.subAgentAddress.map((address) => {
                return { ...address, bankName: res.bankDetails[0].name }
            })
        }));
        debugger
        setSectorMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'name',
            label: 'Name',
            numeric: false,
        },
        {
            id: 'subAgentName',
            label: 'SubAgency',
            numeric: false,
        },
        {
            id: 'code',
            label: 'Code',
            numeric: false,
        },
        {
            id: 'mobileNumber',
            label: 'IEC No.',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        {
            id: 'subtable',
            label: '',
            numeric: false,
        },
    ];

    const subColumns = [
        {
            id: 'sn',
            label: '#',
            numeric: false,
        },

        {
            id: 'label',
            label: 'label',
            numeric: false,
        },
        {
            id: 'address1',
            label: 'Address1',
            numeric: false,
        },
        {
            id: 'email',
            label: 'Email',
            numeric: false,
        },
        {
            id: 'bankName',
            label: 'Bank Name.',
            numeric: false,
        },


        {
            id: 'action',
            label: '',
            numeric: false,
        },

    ];

    const subActionCell = ({ row }) => {
        return <>
            <SubAgencyAddressForm
                row={row}
                edit={true} 
                callback={() => { }}
                APIDATA={{
                    stateData: stateData,
                    cityData: cityData,
                    countryItem: countryItem
                }} />
        </>
    }


    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const updatedStatus = !row?.status;
            const data = {
                id: row?.id,
                status: updatedStatus
            }
            axios.get(UpdateSubAgentShipperStatus(data), { headers })
                .then((response) => {
                    fatch_MasterDataBYPagination()
                })
        }
        const editBtnClicked = () => {
            sessionStorage.setItem('shipper', JSON.stringify(row));
            navigate(subagencyshipperform);
        }

        return <>
            <Tooltip title="Edit Sub Agency Shipper">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >
            <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
            <SubAgencyAddressForm
                row={row}
                callback={() => { }}
                APIDATA={{
                    stateData: stateData,
                    cityData: cityData,
                    countryItem: countryItem
                }} />

        </>

    }

    //table head section tool

    const TableTool = ({}) => {
        return <>
            <Tooltip title="Add Sub Agency Shipper">
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${subagencyshipperform}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }
    const conditions = {
        subColumns: subColumns,
        subActionCell: subActionCell
    }


    return (
        <>
          <CustomerTable
                data={sectorMasterData}
                conditions={conditions}
                columns={columns}
                title={'Sub Agency Shipper'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default SubAgencyShipperList;

import React from 'react'
import loginimage from '../../assets/img/loginimg.jpg'
import loginlogo from '../../assets/img/avatars/logonew.jpg'
import { Button, Checkbox, Link, ListItem, TextField } from '@mui/material'
import { CheckBox } from '@mui/icons-material'
const TexhnicalError = () => {
    return (
        <>
            <div className="login-body cmc">
                <div className="row">
                    <div className=" d-none d-lg-block col-lg-6">
                        <div className="cmd">
                            <div className="login-image">
                                <img src={loginimage} alt="" />
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6 ">
                        <div className='tech_error_field'>
                            <div className='technical_error'>
                                <h1>404 </h1>
                                <h3> Page Not Found</h3>
                                <p>Oops! Looks like you've encountered a missing page. <br /> This page does not exist. </p>
                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TexhnicalError


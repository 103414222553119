import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetCustomerByPage, GetAirportMasterByPage, GetEmployeeByPage, } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import { Checkbox, TableCell } from '@mui/material';
import { useDispatch } from 'react-redux';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import Dropdown, { InputField } from '../../component/controls';
import { columnConstant } from '../../constant/formconstant';
import { Add, AddBox, Delete, Edit, List, ListAlt, MasksTwoTone } from '@mui/icons-material';
import { AddPlanForTodayJobs, DeleteJobs, GetAirExportPlannerByPage, GetAirlineDetailsForMAWB, UpdatePlannerSingle } from '../../../services/APIConstant/jobConstant';
import { AirExportCustomerTypeFrightOptions, IntialStockProvider, SingleShipmentType, applicableFormat } from '../../../services/modalData';
import destination from '../../assets/img/icons/destination.png';
import { SplitJobForm } from './component/Splitjob';
import { AddNewJobForm } from './component/addnewJob';
import { ConvertValue } from '../../../services/utilities/valueConverter';
import MyComparisionStrip from './component/comparisionStrip';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import chartImg from '../../assets/img/plannerboxchart.png'
import AlertDialogBox from '../../component/tabs/alertDialog';
import { Create_Booking } from './component/createBooking';
import { View_JOb_Status } from './component/viewJobStatus';
import PlannerList from './plannerList';
import { createhawbform, createmawbform } from '../../../navigation/constant';


const JobPlanner = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState('1')
    const [totalShipment, setTotalShipment] = useState(0);
    const [totalplanforToday, setTotalplanforToday] = useState(0);
    const [totalConsole, settotalConsole] = useState(0);
    const [totalHold, settotalHold] = useState(0);
    const [splitSelectedValue, setSplitSelectedValue] = useState(false)
    const [splitEditJobData, setSplitEditJobData] = useState([])

    const [togglenewJobForm, setToggleNewJobForm] = useState(false)
    const [shipperItem, setShipperItem] = useState([])
    const [airportItem, setAirportItem] = useState([])
    const [employeeItem, setEmployeeItem] = useState([])
    const [jobPlannerData, setJobPlannerData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState()
    const [filterdata, setFilterdata] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);
    const [openDeleteDialogBox, setOpenDeleteDialogBox] = useState(false);
    const [openPlanForTodayDialogBox, setOpenPlanForTodayDialogBox] = useState(false);
    const [selectedPlanForToday, setSelectedPlanForToday] = useState([]);
    const [selectedJobIdforDelete, setSelectedJobIdforDelete] = useState("");


    const fatch_MasterDataBYPagination = (item) => {
        // setSelectedItems([])
        MasterCallBypagination({
            API: GetAirExportPlannerByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

        // PlannerSingleUpdateHandler({
        //     id:11,
        //     enquiryId: 10,
        //     // AllotMawbNumber: 12345771
        //     BookingWithAL: true

        //     // awbNumbers: [
        //     //     {
        //     //         airlineId: 1,
        //     //         stockProvider: 1,
        //     //         awbNumber: 12345771,
        //     //     }
        //     // ]
        // })

        //get customer by page API
        GetCaller({
            API: GetCustomerByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, paylode: e.paylode })
            },
            item: {},
            callback: ({ response }) => {
                const newSalesperson = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.legalName
                }))

                setShipperItem(newSalesperson)
            }
        })

        // Get Airport Master by Page 
        GetCaller({
            API: GetAirportMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const airportItem = data?.map(res => ({
                    value: res?.id,
                    name: res?.name
                }))
                setAirportItem(airportItem)
            }
        })


        // Get Airport Master by Page 
        GetCaller({
            API: GetEmployeeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const employeeItem = data?.map(res => ({
                    value: res?.id,
                    name: res?.name
                }))
                setEmployeeItem(employeeItem)
            }
        })

    }, []);

    const handleCheckboxChange = (event, item) => {
        const isChecked = event;
        if (isChecked) {
            setSelectedItems([...selectedItems, item]);
        } else {
            setSelectedItems(selectedItems?.filter(selectedItem => selectedItem.id !== item.id));
        }
    };

    const CheckBoxHandler = (res, type) => {
        return <>
            <div className='d-flex'>
                {
                    type === 'console' ? <>
                        Console No.{res.id}
                    </> :

                        <>
                            <div className='cmd'>
                                <Checkbox
                                    disabled={res?.bizSubType === 'CC Only' ? true : false}
                                    onChange={event => handleCheckboxChange(event.target.checked, res)}
                                    checked={selectedItems.some(selectedItem => selectedItem.id === res.id)}>
                                </Checkbox>
                            </div>
                        </>
                }


            </div>
        </>
    }

    const handleViewStatusByJobId = (data) => {
        return <View_JOb_Status data={data} callBack={() => { }} />
    }

    const handleDeleteJob = (id) => {

        return <>
            <Tooltip title='Delete Job'>
                <IconButton
                    onClick={() => {
                        setOpenDeleteDialogBox(true)
                        setSelectedJobIdforDelete(id)
                    }}
                >
                    <Delete />
                </IconButton>
            </Tooltip>
        </>
    }
    const createHAWBForJob = (data) => {
        return <Tooltip title='Create HAWB'>
            <IconButton onClick={() => { navigate(createhawbform) }}>
                <AddBox></AddBox>
            </IconButton>
        </Tooltip>

    }

    const handleSplitAdd = (id, res) => {
        return <>
            <div className='cmd' style={{ whiteSpace: "nowrap" }}>
                {'AE-' + id}
                {
                    res?.bizSubType !== 'CC Only' &&
                    <Tooltip title='Split'>
                        <IconButton className='me-2' onClick={() => { setSplitSelectedValue(res); setSplitEditJobData([]) }}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                }

            </div>
        </>
    }

    const handleSplitEdit = (res, item) => {

        return <>
            <Tooltip title='Edit Job'>
                <IconButton
                    onClick={() => { setSplitSelectedValue(res); setSplitEditJobData([item]) }}
                >
                    <Edit />
                </IconButton>
            </Tooltip>
        </>
    }

    const [mawbIndex, setMawbIndex] = useState(false)
    const [mawbAirlineData, setmawbAirlineData] = useState([])

    // Single API UPDATE HANDLER
    const PlannerSingleUpdateHandler = (requestData) => {
        PostCaller({
            API: UpdatePlannerSingle,
            dispatch: (e) => {
                // dispatch({ type: e.type, payload: e.payload })
            },
            item: requestData,
            contenttype: true,
            callback: ({ response }) => {
                fatch_MasterDataBYPagination();
            }
        });
    }


    const mawbStockDataFatch = (res, index) => {
        setMawbIndex(index)

        // Get Airport Master by Page 
        GetCaller({
            API: GetAirlineDetailsForMAWB,
            dispatch: (e) => {
                // dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                stockProvider: res.stockProvider,
                airlineId: res.airlineId,
                vendorId: res.vendorId
            },
            callback: ({ response }) => {
                debugger
                let data = response?.data?.data
                if (data.length !== 0) {
                    setmawbAirlineData(data[0].mawbNumbers)
                }

            }
        })
    }


    const allotMawbNumberHandler = (res, index) => {
        return <div className='cmd' onMouseLeave={() => setMawbIndex(false)}>

            {
                mawbIndex === index ?
                    <div style={{ width: '80px' }}>
                        <Dropdown className={'mt-0'}
                            field={{
                                label: 'MAWB NO',
                                item: mawbAirlineData,
                                onChange: (value) => {
                                    if (value) {
                                        const requestData = {
                                            id: res.customerBranchList.length !== 0 ? res.customerBranchList[0].id : res.id,
                                            enquiryId: res.enquiryId,
                                            AllotMawbNumber: value
                                        }
                                        PlannerSingleUpdateHandler(requestData)
                                    }
                                }
                            }}
                        />
                    </div>
                    : <div onClick={() => mawbStockDataFatch(res, index)}> {res?.allotMawbNumber || 'N/A'} </div>
            }

            {
                !res.bookingWithAL &&
                <Create_Booking />
            }
        </div>
    }


    const [bookingIndex, setBookingIndex] = useState(false)
    const bookingHandler = (res, index) => {
        return <div className='cmd '
            onClick={() => setBookingIndex(index)}
            onMouseLeave={() => setBookingIndex(false)}
        >

            {
                bookingIndex === index ?
                    <div style={{ width: '80px' }}>
                        <Dropdown className={'mt-0'}
                            field={{
                                label: 'Booking',
                                item: applicableFormat,
                                onChange: (value) => {
                                    if (value) {
                                        const requestData = {
                                            id: res.customerBranchList.length !== 0 ? res.customerBranchList[0].id : res.id,
                                            enquiryId: res.enquiryId,
                                            BookingWithAL: value === 1 ? true : false
                                        }
                                        PlannerSingleUpdateHandler(requestData)
                                    }
                                }
                            }}
                        />
                    </div>
                    : <div > {res?.bookingWithALModify || 'N/A'} </div>
            }
        </div>
    }


    const [hawbIndex, setHAWBIndex] = useState(false)
    const hawbHanlder = (res, index) => {
        return <div className='cmd '
            onClick={() => setHAWBIndex(index)}
            onMouseLeave={() => setHAWBIndex(false)}
        >

            {
                hawbIndex === index ?
                    <div style={{ width: '80px' }}>
                        <InputField className={'mt-0'}
                            field={{
                                label: '',
                                // value: res.allotHawbNumber,
                                onChange: (value) => {
                                    if (value) {
                                        const requestData = {
                                            id: res.id,
                                            enquiryId: res.enquiryId,
                                            allotHawbNumber: value
                                        }
                                        PlannerSingleUpdateHandler(requestData)
                                    }
                                }
                            }}
                        />
                    </div>
                    : <div > {res?.allotHawbNumber || 'N/A'} </div>
            }
        </div>
    }



    const ConsoleShipmentTypehandler = (res) => {

        return <>
            <Dropdown
                className={'mt-0'}
                field={{
                    label: '',
                    value: res.consoleShipmentType,
                    item: SingleShipmentType,
                    onChange: (value) => {
                        if (value) {
                            const requestData = {
                                id: res.id,
                                enquiryId: res.enquiryId,
                                consoleShipmentType: value
                            }
                            PlannerSingleUpdateHandler(requestData)
                        }
                    }
                }}
            />
        </>
    }


    // checking same values 
    function areAllSame(subTable, key) {
        if (subTable.length !== 0) {
            const firstValue = subTable[0][key];
            return subTable.every(item => item[key] === firstValue);
        } else {
            return false
        }

    }

    const setTableDataHandler = ({ response }) => {

        const intialValueHandler = (actual, final) => {
            if (actual == final || !final) {
                return actual || '0'
            } else {
                return `${actual || 0} / ${final || 0}`
            }
        }

        let newmasterdata = response?.data?.data?.jobs?.map((res, index) => ({
            className: res?.isRepricing ? 'rePricingDiff' : res?.isPlanForToday ? 'planforToday' : res?.splitJobs?.length !== 0 ? 'splitStyle' : res?.consoleJobs?.length !== 0 && 'consoleStyle',


            consoleShipmentType: res?.consoleShipmentType,
            handleConsoleShipmentType: ConsoleShipmentTypehandler(res),
            isPlanForToday: res?.isPlanForToday,
            isRepricing: res?.isRepricing,
            isAirVolumeWeightDiff: res?.isAirVolumeWeightDiff,
            isBizSubTypeDiff: res?.isBizSubTypeDiff,
            isCustomerTypeDiff: res?.isCustomerTypeDiff,
            isFreightServiceTypeDiff: res?.isFreightServiceTypeDiff,
            isGrossWeightDiff: res?.isGrossWeightDiff,
            isPermanent: res?.isPermanent,
            isQtyChargeWeightDiff: res?.isQtyChargeWeightDiff,



            id: res?.id,
            enquiryModifyId: handleSplitAdd(res.enquiryId, res),
            enquiryId: res?.enquiryId,

            consoleType: res?.consoleType,
            quatationId: res?.quatationId,
            shipperId: res?.shipperId,
            shipperName: res?.shipperName,
            bizGenerator: res?.bizGenerator,

            airportOfLoading: res?.airportOfLoading,
            airportOfDestination: res?.airportOfDestination,
            airportOfLoadingName: res?.airportOfLoadingName,
            airportOfDestinationName: res?.airportOfDestinationName,

            customerType: res?.customerType,
            freightServiceType: res?.freightServiceType,
            freightServiceSubType: res?.freightServiceSubType,
            bizCategoryType: res?.bizCategoryType,
            bizType: res?.bizType,
            bizSubType: res?.bizSubType,
            shipperIncoTerms: res?.shipperIncoTerms,

            packages: res?.packages,
            grossWeight: res?.grossWeight,
            qtyChargeWeight: res?.qtyChargeWeight,
            airVolumeWeight: ConvertValue(Number(res?.airVolumeWeight)),


            finalGrossWeight: res?.finalGrossWeight,
            finalQtyChargeWeight: res?.finalQtyChargeWeight,
            finalAirVolumeWeight: res?.finalAirVolumeWeight,

            initialPackages: intialValueHandler(res?.packages, res?.finalPackages),
            initialGrossWeight: intialValueHandler(res?.grossWeight, res?.finalGrossWeight),
            initialQtyChargeWeight: intialValueHandler(res?.qtyChargeWeight, res?.finalQtyChargeWeight),
            initialAirVolumeWeight: intialValueHandler(res?.airVolumeWeight, res?.finalAirVolumeWeight),


            finalBizSubType: res?.finalBizSubType,
            finalCustomerType: res?.finalCustomerType,
            finalFreightServiceType: res?.finalFreightServiceType,


            enquiryStatus: res?.enquiryStatus,
            enquiryStatusTheme: <div className='cmd' style={{ justifyContent: "flex-start" }}>
                {handleViewStatusByJobId(res)}
                {createHAWBForJob(res)}
                {handleDeleteJob(res.id)}

            </div>,

            airlineId: res?.airlineId,
            airlineName: res?.bizSubType === 'CC Only' ? 'N/A' : res?.airlineName,
            vendorId: res?.vendorId,
            vendorName: res?.bizSubType === 'CC Only' ? 'N/A' : res?.vendorName,
            stockProvider: res?.stockProvider,
            stockProviderName: res?.bizSubType === 'CC Only' ? 'N/A' : IntialStockProvider[res.stockProvider - 1]?.name,
            allotMawbNumber: res?.allotMawbNumber,
            allotHawbNumber: res?.allotHawbNumber,
            bookingWithAL: res?.bookingWithAL,
            bookingWithALModify: res?.bookingWithAL === true ? 'Yes' : 'NO',

            assigningJobTo: res?.assigningJobTo,
            checkListByEmployeeId: res?.checkListByEmployeeId,
            checkListByEmployeeDocument: res?.checkListByEmployeeDocument,
            appraisalDC: res?.appraisalDC,
            appraisalSuprident: res?.appraisalSuprident,
            checkList: res?.checkList,
            checkListModify: res?.checkList === true ? 'Yes' : 'NO',


            jobTypeDetail: (
                <div style={{ whiteSpace: "nowrap" }}>
                    {AirExportCustomerTypeFrightOptions[res?.customerType - 1]?.name} <br />
                </div>
            ),

            apoltoapod: (
                <div className="apol_apod">
                    {res?.airportOfLoadingName} <img style={{ width: "20px", padding: "0 2px" }} src={destination} /> {res?.airportOfDestinationName}
                </div>
            ),


            consoleJobs: res?.consoleJobs,
            splitJobs: res?.splitJobs,
            customerBranchList: res?.splitJobs.length !== 0 ?

                // split data handling
                res?.splitJobs?.map(item => ({
                    ...item,
                    initialPackages: intialValueHandler(item?.packages, item?.finalPackages),
                    initialGrossWeight: intialValueHandler(item?.grossWeight, item?.finalGrossWeight),
                    initialQtyChargeWeight: intialValueHandler(item?.qtyChargeWeight, item?.finalQtyChargeWeight),
                    initialAirVolumeWeight: intialValueHandler(item?.airVolumeWeight, item?.finalAirVolumeWeight),


                    enquiryModifyId: handleSplitAdd(item.enquiryId, res),
                    airVolumeWeight: ConvertValue(Number(item?.airVolumeWeight)),
                    jobTypeDetail: (
                        <div style={{ whiteSpace: "nowrap" }}>
                            {AirExportCustomerTypeFrightOptions[item?.customerType - 1]?.name} <br />
                        </div>
                    ),
                    apoltoapod: (
                        <div className="apol_apod">
                            {item?.airportOfLoadingName} <img style={{ width: "20px", padding: "0 2px" }} src={destination} /> {item?.airportOfDestinationName}
                        </div>
                    ),
                    bookingWithALModify: item?.bookingWithAL === true ? 'Yes' : 'NO',
                    enquiryStatusTheme: <div className='cmd' style={{ justifyContent: "flex-start" }}>
                        {handleViewStatusByJobId(item)}
                        {createHAWBForJob(res)}
                        {handleDeleteJob(item.id)}
                        {handleSplitEdit(res, item)}
                    </div>,
                })) :

                // console data handling
                res?.consoleJobs?.map(item => ({
                    ...item,

                    initialPackages: intialValueHandler(item?.packages, item?.finalPackages),
                    initialGrossWeight: intialValueHandler(item?.grossWeight, item?.finalGrossWeight),
                    initialQtyChargeWeight: intialValueHandler(item?.qtyChargeWeight, item?.finalQtyChargeWeight),
                    initialAirVolumeWeight: intialValueHandler(item?.airVolumeWeight, item?.finalAirVolumeWeight),


                    enquiryModifyId: handleSplitAdd(item.enquiryId, res),
                    airVolumeWeight: ConvertValue(Number(item?.airVolumeWeight)),
                    jobTypeDetail: (
                        <div style={{ whiteSpace: "nowrap" }}>
                            {AirExportCustomerTypeFrightOptions[item?.customerType - 1]?.name} <br />
                        </div>
                    ),
                    apoltoapod: (
                        <div className="apol_apod">
                            {item?.airportOfLoadingName} <img style={{ width: "20px", padding: "0 2px" }} src={destination} /> {item?.airportOfDestinationName}
                        </div>
                    ),
                    bookingWithALModify: item?.bookingWithAL === true ? 'Yes' : 'NO',
                    enquiryStatusTheme: <div className='cmd' style={{ justifyContent: "flex-start" }}>
                        {handleViewStatusByJobId(item)}
                        {createHAWBForJob(res)}
                        {handleDeleteJob(item.id)}
                        {handleSplitEdit(res, item)}
                    </div>
                }))
        }));

        newmasterdata?.forEach(obj => {
            const subTable = obj.splitJobs.length !== 0 ? obj.splitJobs : obj.consoleJobs;
            obj.allBizGeneratorSame = areAllSame(subTable, 'bizGenerator');
            obj.allCustomerTypeSame = areAllSame(subTable, 'customerType');
            obj.allShipperNameSame = areAllSame(subTable, 'shipperName');
            obj.airportOfLoadingNameSame = areAllSame(subTable, 'airportOfLoadingName');
            obj.airportOfDestinationNameSame = areAllSame(subTable, 'airportOfDestinationName');
            obj.bizSubTypeSame = areAllSame(subTable, 'bizSubType');
            obj.enquiryIdSame = areAllSame(subTable, 'enquiryId')
            // obj.allShipperNameSame = areAllSame(subTable, 'shipperName');


        });


        setJobPlannerData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalLength || 0)
        setTotalplanforToday(response?.data?.data?.planForToday || 0)
        setTotalShipment(response?.data?.data?.totalShipment || 0)
        settotalConsole(response?.data?.data?.totalConsole || 0)
        settotalHold(response?.data?.data?.totalHold || 0)
    }

    const [jobWithConsoles, setJobWithConsoles] = useState([])

    useEffect(() => {
        const consoleJobsItems = jobPlannerData?.filter(item => item.consoleJobs.length !== 0);
        setJobWithConsoles(consoleJobsItems);
    }, [jobPlannerData])



    const columns = [


        {
            id: 'checkBox',
            label: '',
            numeric: false,
        },
        {
            id: 'enquiryModifyId',
            label: 'Enq No.',
            numeric: false,
        },
        // {
        //     id: 'quatationId',
        //     label: 'Qtn.',
        //     numeric: false,
        // },

        {
            id: 'id',
            label: 'Job no',
            numeric: false,
        },

        {
            id: 'initialPackages',
            label: columnConstant.pkgs,
            numeric: false,
        },
        {
            id: 'initialGrossWeight',
            label: columnConstant.grweight,
            numeric: false,
        },
        {
            id: 'initialAirVolumeWeight',
            label: columnConstant.vweight,
            numeric: false,
        },
        {
            id: 'initialQtyChargeWeight',
            label: 'CH. WT.',
            numeric: false,
        },
        {
            id: 'jobTypeDetail',
            label: "C. Type",
            numeric: false
        },

        {
            id: 'shipperName',
            label: "Shipper Name",
            numeric: false,
        },
        {
            id: 'bizGenerator',
            label: columnConstant.bizgenerator,
            numeric: false,
        },

        {
            id: 'apoltoapod',
            label: 'APOL - APOD',
            numeric: false,
        },

        {
            id: 'bizSubType',
            label: "Biz Sub Type",
            numeric: false,
        },
        {
            id: 'cargotype',
            label: columnConstant.cargotype,
            numeric: false,
        },

        {
            id: 'stockProviderName',
            label: 'Stock Provider',
            numeric: false,
        },
        {
            id: 'vendorName',
            label: 'Vendor',
            numeric: false,
        },
        {
            id: 'airlineName',
            label: 'Airline',
            numeric: false,
        },
        {
            id: 'allotMawbNumber',
            label: 'MAWB NO.',
            numeric: false,
        },
        {
            id: 'bookingWithALModify',
            label: 'Booking',
            numeric: false,
        },
        {
            id: 'handleConsoleShipmentType',
            label: 'Shipment Type',
            numeric: false,
        },
        {
            id: 'allotHawbNumber',
            label: 'HAWB NO.',
            numeric: false,
        },
        {
            id: 'assigningJobTo',
            label: 'Assign To',
            numeric: false,
        },
        {
            id: 'assigningJobTo',
            label: 'SPL REQ',
            numeric: false,
        },
        {
            id: 'slottime',
            label: 'Slot',
            numeric: false,
        },
        {
            id: 'lht',
            label: 'L.H.T',
            numeric: false,
        },


        {
            id: 'enquiryStatusTheme',
            // label: columnConstant.status,
            label: '',
            numeric: false,
        },
        {
            id: 'action',
            label: '',
            numeric: false,
            rendor: (res) => {
                return < >
                    <div className='d-flex'>
                        <Tooltip title={'Create MAWB'}>
                            <IconButton onClick={() => {
                                navigate(createmawbform)
                            }}>
                                <List />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={'Plan For Today'}>
                            <IconButton onClick={() => {
                                setOpenPlanForTodayDialogBox(true)
                                setSelectedPlanForToday(res)
                            }}>
                                <ListAlt />
                            </IconButton>
                        </Tooltip>

                    </div>
                </>
            }
        }
    ];


    //table head section tool
    const TableTool = ({ }) => {
        return <>

            <div className="cmd">
                <div>
                    <ul className='cmd'>
                        <li className='d-flex align-items-center'><span style={{ padding: "10px", background: "var(--sucess-color)", borderRadius: "50%", display: "inline-flex", marginRight: "0.3rem" }}></span> SPLIT</li>
                        <li className='d-flex align-items-center'><span style={{ padding: "10px", background: "var(--console-color)", borderRadius: "50%", display: "inline-flex", margin: "0 0.3rem 0 0.5rem" }}></span> CONSOLE</li>
                    </ul>
                </div>

                <Tooltip title="Add Job">
                    <IconButton
                        className='table-toggle'
                        onClick={() => setToggleNewJobForm(true)}
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip >
            </div>
        </>
    }


    const HandleDeleteJobs = () => {
        const requestData = {
            jobIds: [selectedJobIdforDelete]
        }

        PostCaller({
            API: DeleteJobs,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestData,
            callback: ({ response }) => {
                setOpenDeleteDialogBox(false);
                fatch_MasterDataBYPagination();
            }
        });
    }


    const SubmitPlanForTodayJobs = () => {
        const requestData = {
            jobIds: selectedPlanForToday.map(item => item.id)
        }

        PostCaller({
            API: AddPlanForTodayJobs,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestData,
            callback: ({ response }) => {
                setOpenPlanForTodayDialogBox(false);
                fatch_MasterDataBYPagination();
            }
        });
    }


    const TableBody = (row, index) => {
        return <>
            {
                index === 0 && <tr>
                    <th></th>
                </tr>
            }


            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                sx={{ cursor: 'pointer', background: "#fff" }}
                // job-tr-style
                className={`${row?.className ? row?.className : ''} jobTable `}
            >
                {
                    row?.customerBranchList.length !== 0 ? (

                        columns.map((column) => (
                            column.rendor ?
                                <TableCell className='modifyRightBorder' key={row.id} component="th" scope="row" align={'left'}>
                                    {column.rendor(row?.customerBranchList)}
                                </TableCell>

                                : column.id === 'bizGenerator' && row?.allBizGeneratorSame ?
                                    <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                        {row?.bizGenerator}
                                    </TableCell>
                                    : column.id === 'jobTypeDetail' && row?.allCustomerTypeSame ?
                                        <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                            {row?.jobTypeDetail}
                                        </TableCell>

                                        : column.id === 'shipperName' && row?.allShipperNameSame ?
                                            <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                                {row?.shipperName}
                                            </TableCell>

                                            : column.id === 'apoltoapod' && row?.airportOfDestinationNameSame && row?.airportOfLoadingNameSame ?
                                                <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                                    {row?.apoltoapod}
                                                </TableCell>
                                                : column.id === 'bizSubType' && row?.bizSubTypeSame ?
                                                    <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                                        {row?.bizSubType}
                                                    </TableCell>
                                                    : column.id === 'stockProviderName' ?
                                                        <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                                            {row?.stockProviderName}
                                                        </TableCell>
                                                        : column.id === 'vendorName' ?
                                                            <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                                                {row?.vendorName}
                                                            </TableCell> :
                                                            column.id === 'airlineName' ?
                                                                <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                                                    {row?.airlineName}
                                                                </TableCell> :
                                                                column.id === 'bookingWithALModify' ?
                                                                    <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                                                        {bookingHandler(row, index)}
                                                                    </TableCell>
                                                                    : column.id === 'enquiryModifyId' && row.enquiryIdSame ?
                                                                        <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                                                            {row?.enquiryModifyId}
                                                                        </TableCell> :
                                                                        column.id === 'quatationId' ?
                                                                            <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                                                                {row?.quatationId}
                                                                            </TableCell> :
                                                                            column.id === 'allotMawbNumber' ?
                                                                                <TableCell key={row.id} component="th" scope="row" align={'left'}>
                                                                                    {allotMawbNumberHandler(row, index)}
                                                                                </TableCell> :
                                                                                (column.id === 'checkBox' && row.consoleJobs?.length !== 0) ?
                                                                                    <TableCell className={`${column.id === 'checkBox' ? 'modifyBorder' : ""}`} key={row.id} component="th" scope="row" align={'left'}>
                                                                                        {CheckBoxHandler(row, "console")}
                                                                                    </TableCell>
                                                                                    :
                                                                                    <TableCell className={`${column.id === 'checkBox' ? 'modifyBorder' : ""}`} sx={{ padding: "0" }} key={row.id} component="th" scope="row" align={row.numeric ? 'right' : 'left'}>
                                                                                        {
                                                                                            row.customerBranchList?.map((res) => (
                                                                                                column.id === 'checkBox' ?
                                                                                                    <tr className='jobTable_tr '>
                                                                                                        {CheckBoxHandler(res, 'split')}
                                                                                                    </tr> :
                                                                                                    column.id === 'allotHawbNumber' ?
                                                                                                        <tr className='jobTable_tr '>
                                                                                                            {hawbHanlder(res, index)}
                                                                                                        </tr> :
                                                                                                        column.rendor ?
                                                                                                            <tr className='jobTable_tr'>
                                                                                                                {column.rendor(res)}
                                                                                                            </tr>
                                                                                                            :
                                                                                                            res[column.id] ?
                                                                                                                <tr className='jobTable_tr'>
                                                                                                                    {res[column.id] || 'N/A'}
                                                                                                                </tr>
                                                                                                                : <tr className='jobTable_tr'>
                                                                                                                    N/A
                                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </TableCell>

                        ))) :

                        columns.map((column) => (
                            column.id === 'checkBox' ?
                                <TableCell align='left' className={` ${column.id === 'checkBox' ? 'modifyBorder' : ''}`}>
                                    {CheckBoxHandler(row)}
                                </TableCell> :
                                column.id === 'allotMawbNumber' ?
                                    <TableCell align='left' >
                                        {allotMawbNumberHandler(row, index)}
                                    </TableCell> :
                                    column.id === 'allotHawbNumber' ?
                                        <TableCell align='left' >
                                            {hawbHanlder(row, index)}
                                        </TableCell> :
                                        column.id === 'bookingWithALModify' ?
                                            <TableCell align='left' >
                                                {bookingHandler(row, index)}
                                            </TableCell> :
                                            column.rendor ?
                                                <TableCell align='left' className='modifyRightBorder'>
                                                    {column.rendor([{ id: row?.id }])}
                                                </TableCell> :
                                                row[column.id] ?
                                                    <TableCell
                                                        key={column.id}
                                                        component="th"
                                                        scope="row"
                                                        align={column.numeric ? 'right' : 'left'}
                                                        className={`${column.id === 'checkBox' ? 'modifyBorder' : ''}`}
                                                    >
                                                        {
                                                            row[column.id] || 'N/A'
                                                        }

                                                    </TableCell>
                                                    : <TableCell>
                                                        N/A
                                                    </TableCell>
                        ))
                }




            </TableRow>


            <div className={` job-card_tr-bar ${row?.className ? row?.className : ''} `}>
                <ul>
                    <li>PKGS: {row?.initialPackages}</li>
                    <li>GRWT:  {row?.initialGrossWeight}</li>
                    <li>CHWT:{row?.initialQtyChargeWeight}</li>
                    <li>VOLWT:{row?.initialAirVolumeWeight}</li>
                </ul>
            </div>
        </>
    }

    const conditions = {
        multiselect: false,
        tableBody: TableBody,
        mainBodyStyle: { boxShadow: "none", background: "transparent" }
    }

    return (
        <>

            {/* Add New Job Form  */}
            <AddNewJobForm
                togglenewJobForm={togglenewJobForm}
                shipperItem={shipperItem}
                airportItem={airportItem}
                callBack={() => {
                    setToggleNewJobForm(false)
                    fatch_MasterDataBYPagination()
                }}
            />


            {/* Add Split Form And Update Form  */}
            <SplitJobForm
                splitSelectedValue={splitSelectedValue}
                splitEditJobData={splitEditJobData}
                shipperItem={shipperItem}
                callBack={() => {
                    setSplitSelectedValue(false)
                    fatch_MasterDataBYPagination()
                }}
            />

            <div className='planner-table'>

                {/* planner dash cards  */}
                <div className="row">
                    <div className="col-md-3">
                        <div className='planner-card-box'>
                            Plan for Today <br />
                            <b>{`${totalplanforToday > 9 ? totalplanforToday : `0${totalplanforToday}`}`}</b>

                            <div className='small-icon'>
                                <ContentPasteOutlinedIcon />

                            </div>
                            <div className='large-icon'>
                                <img src={chartImg} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className='planner-card-box' style={{ backgroundColor: "#5d4fb0" }}>
                            Total Shipment <br />
                            <b>{`${totalShipment > 9 ? totalShipment : `0${totalShipment}`}`}</b>

                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className='planner-card-box' style={{ backgroundColor: "#06c2fd" }}>
                            Console <br />
                            <b>{`${totalConsole > 9 ? totalConsole : `0${totalConsole}`}`}</b>

                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className='planner-card-box' style={{ backgroundColor: "#8e7af8" }}>
                            Hold <br />
                            <b>{`${totalHold > 9 ? totalHold : `0${totalHold}`}`}</b>

                        </div>
                    </div>
                </div>


                <MultiTable
                    data={jobPlannerData}
                    conditions={conditions}
                    columns={columns}
                    title={'Planner'}
                    tabletool={TableTool}
                    totaldatalength={totaldatalength}
                    paginationhandler={fatch_MasterDataBYPagination}
                />
            </div>



            {
                selectedItems.length !== 0 &&
                <MyComparisionStrip
                    jobWithConsoles={jobWithConsoles}
                    selectedItem={selectedItems}
                    cancelCallback={() => {
                        setSelectedItems([])
                        fatch_MasterDataBYPagination()
                    }}
                    removeSelectedRow={handleCheckboxChange}
                />
            }

            {/* // delete Dialog Box  */}
            <AlertDialogBox
                style={{ textAlign: "center", display: "flex", justifyContent: "center" }}
                open={openDeleteDialogBox}
                title={`Delete Job (${selectedJobIdforDelete})`}
                description={'Are you sure want to delete this Job ?'}
                handleClose={() => {
                    setOpenDeleteDialogBox(false);

                }}
                handleSubmit={HandleDeleteJobs}
            />



            {/* //  Plan For Today Dialog Box  */}
            <AlertDialogBox
                style={{ textAlign: "center", display: "flex", justifyContent: "space-around" }}
                open={openPlanForTodayDialogBox}
                title={'Plan For Today'}
                description={<div style={{ width: "350px" }}>Are you sure ?</div>}
                handleClose={() => {
                    setOpenPlanForTodayDialogBox(false);
                    setSelectedPlanForToday([]);
                }}
                handleSubmit={SubmitPlanForTodayJobs}
            />



            {/* <PlannerList /> */}

        </>
    )
}

export default JobPlanner;


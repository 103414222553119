import { Add, Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Tooltip } from '@mui/material';
import React, { useEffect } from 'react'
import { columnConstant } from '../../constant/formconstant';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const AlertShow = ({ callback, setFalse, message, submitCallBack, option }) => {
    const [open, setOpen] = React.useState(false);

    const handleFileOpen = () => {
        setOpen(true);
    };

    const handleFileClose = () => {
        setOpen(false);
        setFalse()

    }

    const handleFileSubmit = () => {
        submitCallBack()
        setOpen(false);
    }

    useEffect(() => {
        if (callback) {
            handleFileOpen()
        }
        else {
            setFalse()
        }
    }, [callback])

    return (
        <React.Fragment>

            {/* <div onClick={handleFileOpen}>
                {<Tooltip title="Upload sheet">
                    <Button className='mt-3 p-1 ms-2' variant='contained' style={{ minWidth: "30px" }} >
                        <Add />
                    </Button>
                </Tooltip>}
            </div> */}

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleFileClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "430px!important",
                    },
                }}
                sx={{
                    '& .MuiDialog-container': {
                        alignItems: "center"
                    }
                }
                }


            >
                <div className=''>
                    <DialogTitle >
                        {/* <div className="cmd">
                            <div></div>
                            <Tooltip title='Close'>
                                <IconButton onClick={handleFileClose}>
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <hr /> */}
                    </DialogTitle>
                    <DialogContent sx={{ textAlign: "center" }}>
                        {message}
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "space-evenly" }}>
                        <Button onClick={handleFileClose} variant='outlined'>Cancel</Button>
                        {
                            option.button ? <></> :
                                <Button onClick={handleFileSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
                        }
                    </DialogActions>
                </div>
            </Dialog>
        </React.Fragment >
    )
}

export default AlertShow




import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetGSAMasterByPage, GetMasterSectorByPage, PostMasterSector, UpdateMasterSector, UpdateGSAMasterStatus, GetCustomerBySearch } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { creategoodinregister, creategsamaster, createtdsmaster } from '../../../navigation/constant';

import { useDispatch } from 'react-redux';
import { columnConstant } from '../../constant/formconstant';
import DivisonDrawer from '../../component/divisionDrawer';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import { GetExpectedGoodofExportsByPage, PostExpectedGoodofExports, UpdateExpectedGoodofExports } from '../../../services/APIConstant/warehouseConstant';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { ChargeExportImport, DestinationType, PickUpType } from '../../../services/modalData';
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};

const ExpectedGoodOfExportsList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [goodofExportData, setGoodofExportData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0);
    const [shipperNameItem, setShipperNameItem] = useState([])

    useEffect(() => {
        GetCaller({
            API: GetCustomerBySearch,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.legalName
                    }));

                setShipperNameItem(newCountryItemData);

            }

        });
    }, [])

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetExpectedGoodofExportsByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res?.id,
            partyName: res?.partyName,
            jobType: res?.jobType,
            jobtypename: ChargeExportImport[res?.jobType - 1]?.name,
            jobNumber: res?.jobNumber,
            packages: res?.packages,
            grossWeight: res?.grossWeight,
            shipperId: res?.shipperId,
            customerName: res?.customerName,
            mawbNumber: res?.mawbNumber,
            hawbNumber: res?.hawbNumber,
            pickup: res?.pickup,
            pickupname: PickUpType[res?.pickup - 1]?.name,
            destinationType: res?.destinationType,
            destinationTypeName: DestinationType[res?.destinationType - 1]?.name
        }));

        setGoodofExportData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: columnConstant.sno,
            numeric: false,
        },
        {
            id: 'id',
            label: columnConstant.entryno,
            numeric: false,
        },
        {
            id: 'partyName',
            label: columnConstant.partyname,
            numeric: false,
        },
        {
            id: 'jobtypename',
            label: columnConstant.jobtype,
            numeric: false,
        },

        {
            id: 'jobNumber',
            label: columnConstant.jobno,
            numeric: false,
        },
        {
            id: 'packages',
            label: columnConstant.packages,
            numeric: false,
        },

        {
            id: 'grossWeight',
            label: columnConstant.grosswt,
            numeric: false,
        },
        {
            id: 'customerName',
            label: columnConstant.shippername,
            numeric: false,
        },
        {
            id: 'mawbNumber',
            label: columnConstant.mawbno,
            numeric: false,
        },
        {
            id: "hawbNumber",
            label: columnConstant.hawbno,
            numeric: false,
        },
        {
            id: 'pickupname',
            label: columnConstant.pickup,
            numeric: false,
        },
        {
            id: 'destinationTypeName',
            label: columnConstant.destinationtype,
            numeric: false,
        },
        {
            id: 'action',
            label: columnConstant.action,
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const updatedStatus = !row?.status;
            const data = {
                id: row?.id,
                status: updatedStatus
            }
            axios.get(UpdateGSAMasterStatus(data), { headers })
                .then((response) => {
                    fatch_MasterDataBYPagination()
                })
        }
        const editBtnClicked = () => {
            console.log("hhhhh")
        }
        return <>
            <ExpectedGoodOfExportsDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} shipperNameItem={shipperNameItem} />

            <Tooltip title={columnConstant.delete}>
                <IconButton>
                    <i className='las fa-trash'></i>
                </IconButton>
            </Tooltip >


            {
                row.destinationType === 1 ?
                    <Tooltip title={columnConstant.goodsout}>
                        <IconButton
                            onClick={() => {
                                sessionStorage.setItem('expectedGoodsOutListData', JSON.stringify(row))
                                navigate(creategoodinregister)
                            }}
                        >
                            <i className='las la-truck'></i>
                        </IconButton>
                    </Tooltip >
                    :
                    <Tooltip title={columnConstant.goodsrecived}>
                        <IconButton
                            onClick={() => {
                                sessionStorage.setItem('expectedGoodsInRegisterData', JSON.stringify(row))
                                navigate(creategoodinregister)
                            }}
                        >
                            <i className='las la-truck' style={{ transform: 'scaleX(-1)' }}></i>
                        </IconButton>
                    </Tooltip >

            }


        </>


    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <ExpectedGoodOfExportsDrawer callAPI={fatch_MasterDataBYPagination} shipperNameItem={shipperNameItem} />
        </>
    }
    return (
        <>
            {/* filterItems={filterItems} */}
            <MultiTable
                title={columnConstant.goodofexports}
                columns={columns}
                data={goodofExportData}
                // drawerFormFields={drawerFormFields}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default ExpectedGoodOfExportsList;





const ExpectedGoodOfExportsDrawer = ({ callAPI, editor, row, shipperNameItem }) => {
    const [closeValidator, setcloseValidator] = useState(false)
    const [expectedGoodForm, setExpectedGoodForm] = useState({

        partyName: row?.partyName || '',
        jobType: row?.jobType || null,
        packages: row?.packages || null,
        grossWeight: row?.grossWeight || null,
        pickup: row?.pickup || '',
        destinationType: row?.destinationType || '',
        shipperId: row?.shipperId || '',
        mawbNumber: row?.mawbNumber || '',
        hawbNumber: row?.hawbNumber || '',
        jobNumber: row?.jobNumber || '',
    });

    const requestdata = (() => {

        const data = {
            partyName: expectedGoodForm.partyName,
            jobType: expectedGoodForm.jobType,
            packages: expectedGoodForm.packages,
            grossWeight: expectedGoodForm.grossWeight,
            pickup: expectedGoodForm.pickup,
            destinationType: expectedGoodForm.destinationType
        }

        if (expectedGoodForm.shipperId) data.shipperId = expectedGoodForm.shipperId
        if (expectedGoodForm.mawbNumber) data.mawbNumber = expectedGoodForm.mawbNumber
        if (expectedGoodForm.hawbNumber) data.hawbNumber = expectedGoodForm.hawbNumber
        if (expectedGoodForm.jobNumber) data.jobNumber = expectedGoodForm.jobNumber

        return data;
    })();
    const dispatch = useDispatch()


    const drawerOpenCallbackHandler = () => {
        // Reset the expectedGoodForm state to its initial state
        setExpectedGoodForm({
            partyName: row?.partyName || '',
            jobType: row?.jobType || null,
            packages: row?.packages || null,
            grossWeight: row?.grossWeight || null,
            pickup: row?.pickup || '',
            destinationType: row?.destinationType || "",
            shipperId: row?.shipperId || '',
            mawbNumber: row?.mawbNumber || '',
            hawbNumber: row?.hawbNumber || '',
            jobNumber: row?.jobNumber || '',
        });
    }



    useEffect(() => {
        setcloseValidator(false)

    }, [closeValidator]);

    const handleInputChange = (value, field) => {

        let fieldData = {};

        setExpectedGoodForm({
            ...expectedGoodForm,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    };

    const handleFormSubmit = () => {

        console.log('subagencyform', requestdata)
        const changes = handleSubmit(requestdata);
        debugger
        if (Object.keys(changes).length > 0) {
            setExpectedGoodForm({ ...expectedGoodForm, ...changes });

        }
        else if (editor) {
            EditExpectedGoodofExports(requestdata);

        }
        else {
            AddExpectedGoodofExports(requestdata);
        }
    };

    const AddExpectedGoodofExports = (requestdata) => {



        PostCaller({
            API: PostExpectedGoodofExports,
            callback: () => callAPI(),
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })
        setcloseValidator(true)
    }

    const EditExpectedGoodofExports = (requestdata) => {

        requestdata.id = row?.id;
        PostCaller({
            API: UpdateExpectedGoodofExports
            ,
            callback: () => callAPI(),
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })
        setcloseValidator(true)
    }


    const handleSubmit = (obj, bankName) => {
        debugger
        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (
                key === "status" ||
                key === "shipperId" ||
                key === "mawbNumber" ||
                key === "hawbNumber"
            ) {
            }
            else if (!value) {
                changes[key] = value;
                changes[key + "error"] = !value;
            }
        });
        return changes;
    };


    const drawerFormFields = editor ?
        {
            title: columnConstant.editgoodofexport,
            icon: <i className="las la-edit"></i>,
            tooltitle: columnConstant.editgoodofexport,
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: columnConstant.addgoodofexport,
            tooltitle: columnConstant.addgoodofexport,
            defaultbtnfalse: true
        }

    const FieldData = [
        {
            id: 'partyName',
            label: columnConstant.partyname,
            type: 'text',
            value: expectedGoodForm.partyName,
            required: true,
            error: expectedGoodForm.partyNameerror,
            onChange: handleInputChange,
        },
        {
            id: 'jobType',
            label: columnConstant.jobtype  ,
            type: 'select',
            value: expectedGoodForm.jobType,
            required: true,
            error: expectedGoodForm.jobTypeerror,
            item: ChargeExportImport,
            onChange: handleInputChange,
        },
        {
            id: 'jobNumber',
            label: columnConstant.jobno,
            type: 'number',
            value: expectedGoodForm.jobNumber,
            required: false,
            error: expectedGoodForm.jobNumbererror,
            onChange: handleInputChange,
        },
        {
            id: 'packages',
            label: columnConstant.packages,
            type: 'number',
            value: expectedGoodForm.packages,
            required: true,
            error: expectedGoodForm.packageserror,
            onChange: handleInputChange,
        },
        {
            id: 'grossWeight',
            label: columnConstant.grosswt,
            type: 'number',
            value: expectedGoodForm.grossWeight,
            required: true,
            error: expectedGoodForm.grossWeighterror,
            onChange: handleInputChange,
        },
        {
            id: 'shipperId',
            label: columnConstant.shippername,
            type: 'select',
            value: expectedGoodForm.shipperId,
            required: false,
            error: expectedGoodForm.shipperIderror,
            onChange: handleInputChange,
            item: shipperNameItem
        },
        {
            id: 'mawbNumber',
            label: columnConstant.mawbno,
            type: 'number',
            value: expectedGoodForm.mawbNumber,
            required: false,
            error: expectedGoodForm.mawbNumbererror,
            onChange: handleInputChange,
        },
        {
            id: 'hawbNumber',
            label: columnConstant.hawbno,
            type: 'number',
            value: expectedGoodForm.hawbNumber,
            required: false,
            error: expectedGoodForm.hawbNumbererror,
            onChange: handleInputChange,
        },
        {
            id: 'pickup',
            label: columnConstant.pickup  ,
            type: 'select',
            value: expectedGoodForm.pickup,
            required: true,
            error: expectedGoodForm.pickuperror,
            onChange: handleInputChange,
            item: PickUpType
        },
        {
            id: 'destinationType',
            label: columnConstant.destinationtype  ,
            type: 'select',
            value: expectedGoodForm.destinationType,
            required: true,
            error: expectedGoodForm.destinationTypeerror,
            onChange: handleInputChange,
            item: DestinationType
        },
    ];

    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleFormSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}

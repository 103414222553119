import { useEffect, useState } from "react";
import { MultiTable } from "../../component/multiTable";
import { UpdateMasterPrimaryGroupStatus } from "../../../services/constant";
import { FormControlLabel, IconButton, Radio, RadioGroup, Switch, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import GetCaller, { PostCaller } from "../../../services/apiServices";
import Dropdown, { InputField, InputFileField, SmallSwitch } from "../../component/controls";
import { columnConstant } from "../../constant/formconstant";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AlertDialogBox from "../../component/tabs/alertDialog";
import CustomRadioGroup from "../../component/field/radiogroup";
import { applicableFormat, GoodsConditions } from "../../../services/modalData";
import { type } from "@testing-library/user-event/dist/type";
import { GetTransportDataByJob, UpdateAirportSealstatus } from "../../../services/APIConstant/warehouseConstant";

const TransportDetail = () => {
    const dispatch = useDispatch()
    const airportId = sessionStorage.getItem('airportId')


    const [vehicleData, setvehicleData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const [openStatus, setOpenStatus] = useState(false)

    const [statusValue, setStatusValue] = useState({
        sealImage: "",
        sealIntact: "",
        goodsConditionValue: "",
        goodsCondition: "",
        number: 0,
        comment: "",
        goodsConditionFile: ''
    })

    const handleBasicInputChange = (value, field) => {
        setStatusValue({
            ...statusValue,
            [field.id]: value
        })
    }



    const fatch_MasterDataBYPagination = (id) => {
        GetCaller({
            API: GetTransportDataByJob,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { id: id },
            callback: ({ response }) => {

                setTableDataHandler({ response })
            }

        });
    }

    useEffect(() => {
        if (airportId) {
            fatch_MasterDataBYPagination(airportId);
        }
    }, []);

    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.map((res, index) => ({
            ...res,
        }));
        setvehicleData(newmasterdata);
        setTotalDataLength(response?.data?.data?.length)
    }

    const columns = [

        {
            id: 'id',
            label: 'Id',
            numeric: false,
        },

        {
            id: 'vehicleNumber',
            label: 'Vehicle No.',
            numeric: false,
        },


        {
            id: 'driverName',
            label: 'Driver Name',
            numeric: false,
        },

        {
            id: 'driverNumber',
            label: 'Driver No.',
            numeric: false,
        },
        {
            id: 'dimensionName',
            label: columnConstant.dimension,
            numeric: false,
        },
        {
            id: 'packages',
            label: 'Packages',
            numeric: false,
        },
        {
            id: 'grossWeight',
            label: 'Gross Weight',
            numeric: false,
        },
        {
            id: '',
            label: 'Goods Condition',
            numeric: false,
        },
        {
            id: "sealNumber",
            label: 'Seal Number',
            numeric: false,
        },
        {
            id: 'challanNumber',
            label: 'Challan No',
            numeric: false,
        },

        {
            id: 'goodsOutDateTime',
            label: 'Date',
            numeric: false,
        },
        {
            id: 'slotTime',
            label: 'Slot',
            numeric: false,
        },


        {
            id: 'sealIntactStatus',
            label: 'Status',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },

        // You can add more column definitions as needed
    ];





    const ActionCell = ({ row }) => {

        return <>
            <Tooltip title='Update Status'>
                <IconButton onClick={() => {
                    setOpenStatus(row)
                }}>
                    <MoreHorizIcon />
                </IconButton>
            </Tooltip>
        </>
    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            {/* <PrimaryGroupDrawer callAPI={fatch_MasterDataBYPagination} /> */}
        </>
    }


    //table head section tool



    const requestData = (() => {
        const data = {
            jobNumber: airportId,
            goodsOutTransporterId: openStatus.id,
            // goodsConditionFile:statusValue.goodsConditionFile
        }

        if (statusValue.sealIntact) data.sealIntact = statusValue.sealIntact === 1 ? true : false
        if (statusValue.sealIntact === 2) data.sealImage = statusValue.sealImage
        if (statusValue.goodsConditionValue) data.goodsConditionValue = statusValue.goodsConditionValue === 1 ? true : false
        if (statusValue.goodsCondition) data.goodsCondition = statusValue.goodsCondition
        if (statusValue.goodsCondition !== 1) data.number = statusValue.number
        if (statusValue.goodsCondition !== 1) data.comment = statusValue.comment



        return data;
    })()


    const HandleSubmit = () => {

        PostCaller({
            API: UpdateAirportSealstatus,
            callback: () => {
                setOpenStatus(false)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            contenttype: true,
            item: requestData
        })

    }








    return (
        <>

            <MultiTable
                data={vehicleData}
                columns={columns}
                //  drawerFormFields={drawerFormFields}
                tabletool={TableTool}
                actioncell={ActionCell}
                title={'Vehicle Details'}
                totaldatalength={totaldatalength}
                paginationhandler={() => { }}

            />


            <AlertDialogBox
                style={{ textAlign: "center", display: "flex", justifyContent: "space-around" }}
                open={openStatus}
                title={`${'Status'} (${openStatus && openStatus?.vehicleNumber})`}
                description={
                    <div style={{ width: "400px", textAlign: 'left' }}>
                        <div className="row">
                            <div className="col-12">
                                <Dropdown
                                    field={{
                                        id: "sealIntact",
                                        label: 'Seal Intact',
                                        value: statusValue?.sealIntact,
                                        required: true,
                                        error: statusValue?.sealIntacterror,
                                        onChange: handleBasicInputChange,
                                        item: applicableFormat
                                    }}
                                />
                            </div>
                            {
                                statusValue?.sealIntact === 2 &&

                                <div className="col-12">
                                    <InputFileField
                                        field={{
                                            id: "sealImage",
                                            label: 'Upload Image',
                                            type: "file",
                                            value: statusValue?.sealImage,
                                            required: true,
                                            error: statusValue?.sealImageerror,
                                            onChange: handleBasicInputChange,
                                            item: applicableFormat
                                        }}
                                    />
                                </div>
                            }



                            {
                                // <div className="col-12">
                                //     <Dropdown
                                //         field={{
                                //             id: "goodsConditionValue",
                                //             label: 'Goods Condition (Yes/No)',
                                //             value: statusValue?.goodsConditionValue,
                                //             required: true,
                                //             error: statusValue?.goodsConditionValueerror,
                                //             onChange: handleBasicInputChange,
                                //             item: applicableFormat
                                //         }}
                                //     />
                                // </div>
                            }

                            <div className="col-12">
                                <Dropdown
                                    field={{
                                        id: "goodsCondition",
                                        label: 'Goods Condition',
                                        value: statusValue?.goodsCondition,
                                        required: true,
                                        error: statusValue?.goodsConditionerror,
                                        onChange: handleBasicInputChange,
                                        item: GoodsConditions
                                    }}
                                />
                            </div>
                            {
                                statusValue.goodsCondition !== 1 &&
                                <div className="col-md-6">
                                    <InputField
                                        field={{
                                            id: "number",
                                            label: 'Number',
                                            type: "number",
                                            value: statusValue?.number,
                                            required: true,
                                            error: statusValue?.numbererror,
                                            onChange: handleBasicInputChange,
                                            item: applicableFormat
                                        }}
                                    />
                                </div>
                            }
                            {
                                statusValue.goodsCondition !== 1 &&
                                <div className="col-md-6">
                                    <InputField
                                        field={{
                                            id: "comment",
                                            label: 'Comment',
                                            type: "text",
                                            value: statusValue?.comment,
                                            required: true,
                                            error: statusValue?.commenterror,
                                            onChange: handleBasicInputChange,
                                            item: applicableFormat
                                        }}
                                    />
                                </div>
                            }

                            {
                                statusValue.goodsCondition !== 1 &&
                                <div className="col-12">
                                    <InputFileField
                                        field={{
                                            id: "goodsConditionFile",
                                            label: 'Upload File',
                                            type: "file",
                                            value: statusValue?.goodsConditionFile,
                                            required: true,
                                            error: statusValue?.goodsConditionFileerror,
                                            onChange: handleBasicInputChange,
                                            item: applicableFormat
                                        }}
                                    />
                                </div>

                            }




                        </div>
                    </div>
                }
                handleClose={() => {
                    setOpenStatus(false)
                }}
                handleSubmit={HandleSubmit}
            />
        </>
    )
}

export default TransportDetail;







import { useEffect, useState } from "react";
import axios from 'axios';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button } from '@mui/material';

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import { GetCheckThirdCountryMiscCustomer, GetMasterNetworkTypeByPage } from "../../../services/constant";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Dropdown, { InputField } from "../../component/controls";
import { checkthirdcountrymisccustomer, createcustomer, createthirdcountrymisccustomer } from "../../../navigation/constant";
import { TypeOfCustomer } from "../../../services/modalData";
import GetCaller, { PostCaller } from "../../../services/apiServices";
import Close from "@mui/icons-material/Close";
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};



const CheckThirdCountryMiscCustomer = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [checkCustomerList, setCheckCustomerList] = useState(false)
    const [netwotkTypeItem, setNetwotkTypeItem] = useState([]);
    useEffect(() => {
        GetCaller({
            API: GetMasterNetworkTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true,
            },
            callback: ({ response }) => {
                const data = response?.data?.data?.records


                const item = data?.map((res) => ({
                    value: res.id,
                    name: res.name
                }))
                setNetwotkTypeItem(item)
            }
        })
    }, [])


    const [checkCustomerForm, setCheckCustomerForm] = useState({

        searchKey: "",
        customerType: "",
        gloablAlliance: "",
        gstNumber: "",

    })


    const requestdata = (() => {
        const data = {
            searchKey: checkCustomerForm.searchKey,
            customerType: checkCustomerForm.customerType,
        }

        if (checkCustomerForm.gloablAlliance) data.gloablAlliance = checkCustomerForm.gloablAlliance
        if (checkCustomerForm.gstNumber) data.gstNumber = checkCustomerForm.gstNumber
        return data;
    })()


    const hasUndefinedValue = (obj) => {
        let changes = {};

        Object.entries(obj).forEach(([key, value]) => {
            debugger
            if (!value) {
                changes[key] = value
                changes[key + 'error'] = !value;
            }
        });

        return changes;
    };


    const handleFormSubmit = () => {
        const changes = hasUndefinedValue(requestdata);
        if (Object.keys(changes).length > 0) {
            setCheckCustomerForm({ ...checkCustomerForm, ...changes });
        }
        else {

            GetCaller({
                API: GetCheckThirdCountryMiscCustomer,
                callback: ({ response }) => {
                    console.log('response checklist', response?.data?.data?.records)
                    // navigate(`${chargehead}`)
                    setCheckCustomerList(response?.data?.data?.records)
                },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: requestdata
            })

        }
    };


    const handleBasicInputChange = (value, field) => {
        setCheckCustomerForm({
            ...checkCustomerForm,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    };



    return <>
        <div className="hr-form-box">

            <div className="row mt-3">
                <h6>Create Third Country Misc. Customer</h6>
                {
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: "searchKey",
                                    label: "Customer Name",
                                    type: 'text',
                                    value: checkCustomerForm.searchKey,
                                    required: true,
                                    error: checkCustomerForm.searchKeyerror,
                                    helperText: 'Customer Name Required',
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                }

                <div className="col-md-3">
                    <Dropdown
                        field={
                            {
                                id: "customerType",
                                label: "Type Of Customer",
                                value: checkCustomerForm.customerType,
                                required: true,
                                error: checkCustomerForm.customerTypeerror,
                                helperText: 'Type Of Customer Required',
                                item: TypeOfCustomer,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: "gstNumber",
                                label: "GstNumber",
                                type: 'text',
                                value: checkCustomerForm.gstNumber,
                                required: true,
                                error: checkCustomerForm.gstNumbererror,
                                helperText: 'gstNumber Required',
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-3">
                    <Dropdown
                        field={
                            {
                                id: "gloablAlliance",
                                label: "Global Allowance Network",
                                type: 'text',
                                value: checkCustomerForm.gloablAlliance,
                                required: true,
                                error: checkCustomerForm.gloablAllianceerror,
                                helperText: 'Global Allowance Network Required',
                                item: netwotkTypeItem,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>

            </div>
        </div>
        <div className='cmd'>
            <div></div>
            <div>
                <Button sx={{ m: 2, borderRadius: "10px" }} onClick={handleFormSubmit} variant="contained">Check Customer</Button>
                {
                    checkCustomerList !== false && checkCustomerList?.length === 0 &&
                    < Button sx={{ m: 2, borderRadius: "10px" }}
                        variant="contained"
                        onClick={() => {
                            sessionStorage.setItem('checkCustomerData', JSON.stringify(checkCustomerForm))
                            navigate(createthirdcountrymisccustomer)
                        }}
                    >
                        Add Customer
                    </Button>

                }
            </div>
        </div >
        {
            checkCustomerList !== false &&
            <CheckCustomerTable data={checkCustomerList} />

        }

    </>
}
export default CheckThirdCountryMiscCustomer


export const CheckCustomerTable = ({ data }) => {
    const [isTableVisible, setIsTableVisible] = useState(true);

    const toggleTableVisibility = () => {
        setIsTableVisible(!isTableVisible);
    };
    return (
        <div>
            <div className="toggle-table-visibility" onClick={toggleTableVisibility}>
                Following Data is found
                <KeyboardArrowUpIcon className={isTableVisible ? 'rotate_up' : 'rotate_down'} />

            </div>
            {/* {
                data.length === 0 ? <h4>No Data Found</h4> */}
            {/* : */}
            {
                isTableVisible &&
                <div className='check-customer-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>C.Id</th>
                                <th>Legal Name</th>
                                <th>A/C Ledger</th>
                                <th>GST NO.</th>
                                <th>Branch</th>
                                <th>Contact</th>
                                <th>Vendor Type</th>
                                <th>Blacklist Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                data.length === 0 ? <td colSpan={8}>
                                    No data Found !
                                </td> :

                                    data && data?.map((res, key) => {
                                        return <tr>
                                            <td>{res.id}</td>
                                            <td>{res.legalName}</td>
                                            <td>{res.accountLedger}</td>
                                            <td>{res.gstNumber}</td>
                                            <td>{res.addressLine1} {res.branch} {res.state}</td>
                                            <td>
                                                {res?.contactList[0]?.name || "N/A"}
                                                <br />
                                                {res?.contactList[0]?.mobileNumber}
                                            </td>
                                            {/* <td>{TypeOfCustomer && TypeOfCustomer[res.customerType - 1]?.name}</td> */}
                                            <td>{res.vendorType || 'N/A'}</td>
                                            <td>{res.isBlackListed ? <CheckIcon /> : <Close />}</td>

                                        </tr>
                                    })

                            }


                            {/* Add more rows as needed */}
                        </tbody>
                    </table>
                </div>
            }




            {/* <EditableTable /> */}

        </div>
    );
};




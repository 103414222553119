import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import Header, { BranchOption } from './mycomponent/component/header';
import SideBar, { SubMenuSideBar } from './mycomponent/component/sideBar';
import SnackbarAlert from './mycomponent/component/snackbar/snackbar';
import Loader from './mycomponent/component/controls/loader';
import RightSideBar from './mycomponent/component/rightSideBar';
import LiveFeed from './mycomponent/component/liveFeed';
import { JobComparisonFooter } from './mycomponent/pages/job/jobplanner';

const Layout = () => {
    const [sideTrue, setSideTrue] = useState(true)
    const [submenuKey, setSubmenyKey] = useState(false);
    const [rightSideActive, setRightSideActive] = useState(true)
    const [remoteShow, setRemoteShow] = useState(true)
    const callbackHandler = (e) => {
        setSideTrue(() => !sideTrue);
    }

    const sideCallbackHandler = (e) => {
        setSubmenyKey(e)
    }

    const rightSideCallBackHandler = () => {
        setRightSideActive(!rightSideActive)
    }

    const remoteShowCallback = () => {
        setRemoteShow(!remoteShow)
    }

    return (
        <>
            <CommmonComponentProvider />
            <div className='d-flex' style={{ overflow: "hidden" }}>
                {sideTrue ? <SideBar sideTrue={sideTrue} callback={sideCallbackHandler} remoteShowCallback={remoteShowCallback} /> : false}



                <div style={{ height: "100vh", width: "100%", overflow: "hidden" }}>
                    <div>
                        <Header callback={callbackHandler} sideCallbackHandler={sideCallbackHandler} />
                    </div>

                    <div className='sub-sidebar-layout' >
                        <div onMouseLeave={() => setSubmenyKey(false)}>
                            <SubMenuSideBar submenuKey={submenuKey} onNavLinkClick={sideCallbackHandler} />

                        </div>

                        <div className="body-layout" style={{ marginRight: rightSideActive ? '45px' : '0', transition: 'all 0.5s ease-in-out' }} >
                            <LiveFeed remoteShow={remoteShow} />

                            <div style={{ padding: "30px" }}>
                                <Outlet>
                                </Outlet>
                            </div>

                        </div>
                        <RightSideBar callback={rightSideCallBackHandler} />
                    </div>

                </div>
            </div>

        </>
    )
}

export default Layout;


const CommmonComponentProvider = () => {
    return <>
        <SnackbarAlert />
    </>
}
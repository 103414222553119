import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Button, Checkbox, Tab } from "@mui/material"
import React, { useEffect, useState } from "react"
import Dropdown, { DateField, InputField, StackDropdown } from "../../component/controls"
import { AirLineBuying, AwsCharge, ChargesTotal, Discrepancy, Maintab, OtherDetails } from "./mawbtabs"
import { GetChargeHeadById, GetChargeHeadByPage, GetNotifyListByPage, PostAirportMaster, PostHawb, PostMasterCountry, PostMawb, UpdateHawb, UpdateMawb } from "../../../services/constant"
import { GetAirExportPlannerByPage, GetAirExportPlannerDetailsJobbyId, GetConsoleAndSingleJob } from "../../../services/APIConstant/jobConstant"
import GetCaller, { PostCaller } from "../../../services/apiServices"
import { useDispatch } from "react-redux"
import { SingleShipmentType, mawbShipmenttype, mawbshippers } from "../../../services/modalData"
import { useNavigate } from "react-router-dom"
import { mawb } from "../../../navigation/constant"

// jobid,airlineid,shipperid,consigneeid,notifyidairport of departure,  currency

const CreateMawbForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState('1');
  const [jobitem, setJobItem] = useState([])
  const [consolitem, setConsoleItem] = useState([])
  const [airportiteam, setAirportItem] = useState([])
  const [currencyItem, setcurrencyItem] = useState([]);
  const [notifyItem, setNofifyItem] = useState([])
  const [chargeheadItem, setChargehead] = useState([])

  const [awbChargesDetailsError, SetAwbChargesDetailsError] = useState({})


  const [MawbForm, setMawbForm] = useState({
    dgShipForXML: false,
    void: false,
    declaredValueForInputCarriage: 'NVD',
    declaredValueForCustom: "NVD",
    ammountOfInsurance: "NIL",
    notifyAddress: '',
    due: "",
    taxSlab: '',
    packages: '',
    grossWeight: '',
    volumnWeight: '',
    chargeWeight: "",
    airportOfDestination: '',
    airportOfDeparture: "",
    mawbNumber: ''

  })



  const handleChange = (event, newValue) => {

    setValue(newValue);
  };

  const handleBasicInputChange = (value, field) => {

    let condtionalChanges = {}
    if (field.id === 'notify') {
      const selectedItem = notifyItem.find(item => item.value === value);
      condtionalChanges.notifyAddress = `${selectedItem.address1}\n${selectedItem.address2}\n${selectedItem.address3}`
    }
    setMawbForm({
      ...MawbForm,
      ...condtionalChanges,
      [field.id]: value,
      [field.id + 'error']: !value
    })
    condtionalChanges = {}

  }



  const requestdata = (() => {
    const data = {

      mawb: {
        shipmentType: MawbForm.shipmentType,
        jobOrConsoleNumber: MawbForm.jobOrConsoleNumber,
        mawbNumber: MawbForm.mawbNumber,
        mawbDate: MawbForm.mawbDate,
        airlineId: MawbForm.airlineId,
        packages: MawbForm.packages,
        grossWeight: MawbForm.grossWeight,
        volumnWeight: MawbForm.volumnWeight,
        chargeWeight: MawbForm.chargeWeight,
        finalChargeWeight: MawbForm.finalChargeWeight,
        airportOfDeparture: MawbForm.airportOfDeparture,
        airportOfDestination: MawbForm.airportOfDestination,
        dgShipForXML: MawbForm.dgShipForXML,
        void: MawbForm.void || true,
        shipperId: MawbForm.shipperId,
        shipperAddress: MawbForm.shipperAddress,
        consigneeId: MawbForm.consigneeId,
        consigneeAddress: MawbForm.consigneeAddress,
        freightRate: MawbForm.freightRate,
        agreedRate: MawbForm.agreedRate,
        freight: MawbForm.freight,
        dueAgent: MawbForm.dueAgent,
        dueCarrier: MawbForm.dueCarrier,
        destination: MawbForm.destination,
        totalVolumnWeight: MawbForm.totalVolumnWeight || "1",
        currency: MawbForm.currency,
        kgOrLb: MawbForm.kgOrLb,
        byFirstDueCarrier: MawbForm.byFirstDueCarrier,
        requestedFlight: MawbForm.requestedFlight,
        flightDate: MawbForm.flightDate,
        issuingDueCarrierAgentName: MawbForm.issuingDueCarrierAgentName,
        agentIataCode: MawbForm.agentIataCode,
        accountingInformation: MawbForm.accountingInformation,

      },
      dimensionList: [
        {
          length: 0,
          width: 0,
          height: 0,
          box: 0,
          volume: 0
        }
      ],
      awbCharges: MawbForm.awbCharges

    }

    if (MawbForm.consolidation) data.mawb.consolidation = MawbForm.consolidation
    if (MawbForm.singleShipmentType) data.mawb.singleShipmentType = MawbForm.singleShipmentType
    if (MawbForm.consoleJobs) data.mawb.consoleJobs = MawbForm.consoleJobs
    if (MawbForm.invoiceNumber) data.mawb.invoiceNumber = MawbForm.invoiceNumber
    if (MawbForm.notify) data.mawb.notify = MawbForm.notify
    if (MawbForm.notifyAddress) data.mawb.notifyAddress = MawbForm.notifyAddress
    if (MawbForm.printSubAgentNameLabel) data.mawb.printSubAgentNameLabel = MawbForm.printSubAgentNameLabel
    if (MawbForm.printIataOnAWB) data.mawb.printIataOnAWB = MawbForm.printIataOnAWB
    if (MawbForm.sci) data.mawb.sci = MawbForm.sci
    if (MawbForm.minimumRateApplicable) data.mawb.minimumRateApplicable = MawbForm.minimumRateApplicable
    if (MawbForm.publishRate) data.mawb.publishRate = MawbForm.publishRate
    if (MawbForm.printNeutralAWB) data.mawb.printNeutralAWB = MawbForm.printNeutralAWB
    if (MawbForm.printSubAgentName) data.mawb.printSubAgentName = MawbForm.printSubAgentName
    if (MawbForm.showIataOnAWB) data.mawb.showIataOnAWB = MawbForm.showIataOnAWB
    if (MawbForm.asAgreed) data.mawb.asAgreed = MawbForm.asAgreed
    if (MawbForm.ghgsCode) data.mawb.ghgsCode = MawbForm.ghgsCode
    if (MawbForm.wtOrVal) data.mawb.wtOrVal = MawbForm.wtOrVal
    if (MawbForm.other) data.mawb.other = MawbForm.other
    if (MawbForm.tcrNumber) data.mawb.tcrNumber = MawbForm.tcrNumber
    if (MawbForm.rateClass) data.mawb.rateClass = MawbForm.rateClass
    if (MawbForm.commodityItemNumber) data.mawb.commodityItemNumber = MawbForm.commodityItemNumber
    if (MawbForm.declaredValueForInputCarriage) data.mawb.declaredValueForInputCarriage = MawbForm.declaredValueForInputCarriage
    if (MawbForm.declaredValueForCustom) data.mawb.declaredValueForCustom = MawbForm.declaredValueForCustom
    if (MawbForm.ammountOfInsurance) data.mawb.ammountOfInsurance = MawbForm.ammountOfInsurance
    if (MawbForm.routeOneTo) data.mawb.routeOneTo = MawbForm.routeOneTo
    if (MawbForm.routeOneBy) data.mawb.routeOneBy = MawbForm.routeOneBy
    if (MawbForm.routeTwoTo) data.mawb.routeTwoTo = MawbForm.routeTwoTo
    if (MawbForm.routeTwoBy) data.mawb.routeTwoBy = MawbForm.routeTwoBy
    if (MawbForm.tariffRateType) data.mawb.tariffRateType = MawbForm.tariffRateType
    if (MawbForm.tariffRate) data.mawb.tariffRate = MawbForm.tariffRate
    if (MawbForm.accountNumber) data.mawb.accountNumber = MawbForm.accountNumber
    if (MawbForm.handlingInformation) data.mawb.handlingInformation = MawbForm.handlingInformation
    if (MawbForm.natureAndQuantityOfGoods) data.mawb.natureAndQuantityOfGoods = MawbForm.natureAndQuantityOfGoods
    if (MawbForm.descriptionOfGoods) data.mawb.descriptionOfGoods = MawbForm.descriptionOfGoods
    if (MawbForm.iataCommision) data.mawb.iataCommision = MawbForm.iataCommision
    if (MawbForm.airlineBuyingCurrency) data.mawb.airlineBuyingCurrency = MawbForm.airlineBuyingCurrency
    if (MawbForm.exchangeRate) data.mawb.exchangeRate = MawbForm.exchangeRate
    if (MawbForm.incentive) data.mawb.incentive = MawbForm.incentive
    if (MawbForm.freightCost) data.mawb.freightCost = MawbForm.freightCost
    if (MawbForm.airlineBuyingAgreedRateLc) data.mawb.airlineBuyingAgreedRateLc = MawbForm.airlineBuyingAgreedRateLc
    if (MawbForm.airlineBuyingDueDueCarrierLc) data.mawb.airlineBuyingDueDueCarrierLc = MawbForm.airlineBuyingDueDueCarrierLc
    if (MawbForm.freightLc) data.mawb.freightLc = MawbForm.freightLc
    if (MawbForm.freightFc) data.mawb.freightFc = MawbForm.freightFc
    if (MawbForm.commissionLc) data.mawb.commissionLc = MawbForm.commissionLc
    if (MawbForm.commissionFc) data.mawb.commissionFc = MawbForm.commissionFc
    if (MawbForm.discountLc) data.mawb.discountLc = MawbForm.discountLc
    if (MawbForm.dueDueCarrierLc) data.mawb.dueDueCarrierLc = MawbForm.dueDueCarrierLc
    if (MawbForm.dueDueCarrierFc) data.mawb.dueDueCarrierFc = MawbForm.dueDueCarrierFc
    if (MawbForm.remarks) data.mawb.remarks = MawbForm.remarks
    if (MawbForm.csrType) data.mawb.csrType = MawbForm.csrType
    if (MawbForm.weightAmountRevisedOrCorrect) data.mawb.weightAmountRevisedOrCorrect = MawbForm.weightAmountRevisedOrCorrect
    if (MawbForm.freightAmountRevisedOrCorrect) data.mawb.freightAmountRevisedOrCorrect = MawbForm.freightAmountRevisedOrCorrect
    if (MawbForm.commissionAmountRevisedOrCorrect) data.mawb.commissionAmountRevisedOrCorrect = MawbForm.commissionAmountRevisedOrCorrect
    if (MawbForm.discountAmountRevisedOrCorrect) data.mawb.discountAmountRevisedOrCorrect = MawbForm.discountAmountRevisedOrCorrect
    if (MawbForm.dueDueCarrierAmountRevisedOrCorrect) data.mawb.dueDueCarrierAmountRevisedOrCorrect = MawbForm.dueDueCarrierAmountRevisedOrCorrect
    if (MawbForm.dueAgentAmountRevisedOrCorrect) data.mawb.dueAgentAmountRevisedOrCorrect = MawbForm.dueAgentAmountRevisedOrCorrect
    if (MawbForm.totalAmountRevisedOrCorrect) data.mawb.totalAmountRevisedOrCorrect = MawbForm.totalAmountRevisedOrCorrect
    if (MawbForm.weightCharges) data.mawb.weightCharges = MawbForm.weightCharges
    if (MawbForm.valuationCharges) data.mawb.valuationCharges = MawbForm.valuationCharges
    if (MawbForm.dueAgentCharges) data.mawb.dueAgentCharges = MawbForm.dueAgentCharges
    if (MawbForm.dueCarrierCharges) data.mawb.dueCarrierCharges = MawbForm.dueCarrierCharges
    if (MawbForm.cgstCharges) data.mawb.cgstCharges = MawbForm.cgstCharges
    if (MawbForm.sgstCharges) data.mawb.sgstCharges = MawbForm.sgstCharges
    if (MawbForm.igstCharges) data.mawb.igstCharges = MawbForm.igstCharges
    if (MawbForm.totalCharges) data.mawb.totalCharges = MawbForm.totalCharges
    if (MawbForm.ShippingInformation) data.mawb.ShippingInformation = MawbForm.ShippingInformation

    return data;
  })();
  const isEdit = false
  const handleFormSubmit = () => {


    const awbChargesUndefinedValues = (array) => {
      let data = array.length !== 0 ? array : [
        {
          chargeId: '',
          printLabel: '',
          amount: '',
          gst: '',
          due: '',
        }
      ]

      let changes = {};
      data.map((obj, index) => {
        Object.entries(obj).forEach(([key, value]) => {

          if (
            key === 'id' ||
            key === 'gst' ||
            key === 'due'
          ) {

          } else if (!value) {
            changes[key + index] = !value;

          }
        });
      })
      console.log('changes', changes)

      return changes;
    }


    debugger
    const changes = handleSubmit(requestdata.mawb);
    const awbChargesChanges = awbChargesUndefinedValues(requestdata.awbCharges || [])
    if (
      Object.keys(changes).length > 0 ||
      Object.keys(awbChargesChanges).length > 0) {
      setMawbForm({ ...MawbForm, ...changes });
      SetAwbChargesDetailsError(awbChargesChanges)
    }
    else if (isEdit) {
      EditUpdateMawbSubmit(requestdata);
    }
    else {
      AddPostMawbSubmit(requestdata);
    }
  };

  const AddPostMawbSubmit = (requestdata) => {
    PostCaller({
      API: PostMawb,
      callback: () => {
        navigate(mawb)
      },
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: requestdata
    })
  };

  const EditUpdateMawbSubmit = (requestdata) => {
    PostCaller({
      API: UpdateMawb,
      callback: () => {
      },
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: requestdata
    })
  };



  useEffect(() => {
    set_JobItem();
    set_AirportItem();
    set_CountryItemData();
    set_NotifyItem();
    set_Chargehead();
  }, [])


  const set_NotifyItem = () => {

    GetCaller({
      API: GetNotifyListByPage,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        const newnotifyiteam = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.name,
          address1: item.address1,
          address2: item.address2,
          address3: item.address3,
        }))

        console.log(newnotifyiteam)
        setNofifyItem(newnotifyiteam)
      }

    })
  }

  const set_Chargehead = () => {
    GetCaller({
      API: GetChargeHeadByPage,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        const newchargeheaditeam = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.chargeName,
          taxSlab: item.taxSlab,
          due: item.due,

        }))
        console.log(newchargeheaditeam)
        setChargehead(newchargeheaditeam)
      }

    })
  }







  const set_CountryItemData = () => {
    GetCaller({
      API: PostMasterCountry,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        const newCityItemData = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.currencyCode
        }))
        setcurrencyItem(newCityItemData)
      }

    })
  }



  const set_JobItem = () => {
    GetCaller({
      API: GetConsoleAndSingleJob,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {

        const newjobiteam = response?.data?.data?.singleJobs.map((item) => ({
          value: item,
          name: item
        }))
        setJobItem(newjobiteam || [])
        const newconsoliteam = response?.data?.data?.consoleJobs.map((item) => ({
          value: item,
          name: item
        }))
        setConsoleItem(newconsoliteam || [])
      }
    })
  }

  const set_AirportItem = () => {
    GetCaller({
      API: PostAirportMaster,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        const newairportiteam = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.name
        }))
        setAirportItem(newairportiteam)
      }

    })
  }


  const handleSubmit = (obj) => {

    let changes = {};
    Object.entries(obj).forEach(([key, value]) => {

      if (
        key === 'jobId' ||
        key === 'status' ||
        key === "consolidation" ||
        key === "singleShipmentType" ||
        key === "dgShipForXML") {
        return false
      }
      else if (!value) {
        changes[key + 'error'] = !value;
      }

    });
    return changes;
  };



  return (
    <>
      <div className="hr-form-box">
        <h6>Create MAWB</h6>
        <div className="row">


          <div className="col-md-2">
            <Dropdown
              field={
                {
                  id: 'shipmentType',
                  label: 'Shipment Type. ',
                  type: 'select',
                  value: MawbForm.shipmentType,
                  required: true,
                  error: MawbForm.shipmentTypeerror,
                  item: mawbShipmenttype,
                  onChange: (value, field) => {
                    handleBasicInputChange(value, field)
                  }

                }
              }
            />
          </div>

          {MawbForm.shipmentType === 1 &&

            <div className="col-md-2">
              <Dropdown
                field={

                  {
                    id: 'singleShipmentType',
                    label: 'Single Shipment Type *.',
                    type: 'select',
                    value: MawbForm.singleShipmentType,
                    required: true,
                    error: MawbForm.singleShipmentTypeerror,
                    item: SingleShipmentType,
                    onChange: (value, field) => {
                      handleBasicInputChange(value, field)
                    }

                  }
                }
              />
            </div>

          }
          {MawbForm.shipmentType === 2 &&

            <div className="col-md-2">
              <Dropdown
                field={

                  {
                    id: 'consolidation',
                    label: 'Consolidation',
                    type: 'select',
                    value: MawbForm.consolidation,
                    required: true,
                    error: MawbForm.consolidationerror,
                    item: mawbshippers,
                    onChange: (value, field) => {
                      handleBasicInputChange(value, field)
                    }

                  }
                }
              />
            </div>

          }
          {/* <div className="col-md-2">
            <StackDropdown
              field={{
                id: "jobOrConsoleNumber",
                label: 'Job No / Console No ',
               
                value: MawbForm.jobOrConsoleNumber,
                required: true,
                error: MawbForm.jobOrConsoleNumbererror,
                item: MawbForm.shipmentType === 1 ? jobitem : MawbForm.shipmentType === 2 ? consolitem : [],
                onChange: handleBasicInputChange
              }} />
          </div> */}

          <div className="col-md-2">

            <Dropdown
              field={{
                id: "jobOrConsoleNumber",
                label: 'Job No / Console No ',
                value: MawbForm.jobOrConsoleNumber,
                required: true,
                error: MawbForm.jobOrConsoleNumbererror,
                item: MawbForm.shipmentType === 1 ? jobitem : MawbForm.shipmentType === 2 ? consolitem : [],
                onChange: (value, field) => {

                  //get customer by page API
                  GetCaller({
                    API: GetAirExportPlannerByPage,
                    dispatch: (e) => {
                      // dispatch({ type: e.type, paylode: e.paylode })
                    },
                    item: {},
                    callback: ({ response }) => {
                      let data = {}
                      if (MawbForm.shipmentType === 2) {
                        let filterdata = []
                        response?.data?.data?.jobs.map(res => {
                          if (res.consoleJobs.length !== 0 && res.id === value) {
                            filterdata.push(res)
                          }
                        }
                        )
                        data = filterdata[0]

                      } else {
                        let filterdata = []
                        response?.data?.data?.jobs.map(res => {
                          if (res.consoleJobs.length === 0 && res.id === value) {
                            filterdata.push(res)
                          }
                        }
                        )
                        data = filterdata[0]

                      }

                      console.log('Console Data', data)

                      setMawbForm({
                        ...MawbForm,
                        ['jobOrConsoleNumber']: value,
                        ['packages']: data.packages,
                        ['grossWeight']: data.grossWeight,
                        ['volumnWeight']: data.airVolumeWeight,
                        ['chargeWeight']: data.qtyChargeWeight,
                        ['airportOfDeparture']: data.airportOfLoading,
                        ['airportOfDestination']: data.airportOfDestination,
                        ['mawbNumber']: data.allotMawbNumber
                        // ['packages']:data.packages,


                      })
                    }
                  })


                }
              }}
            />
          </div>







          <div className="col-md-2">
            <InputField
              field={
                {
                  id: 'mawbNumber',
                  label: 'MAWB No.',
                  type: "number",
                  required: true,
                  value: MawbForm.mawbNumber,
                  error: MawbForm.mawbNumbererror,
                  onChange: handleBasicInputChange
                }
              }
            />
          </div>

          <div className="col-md-2">
            <DateField
              format='YYYY-MM-DD'
              field={{
                id: "mawbDate",
                label: "MAWB DATE ",
                value: MawbForm?.mawbDate,
                error: MawbForm?.mawbDateerror,
                onChange: handleBasicInputChange
              }}
            />
          </div>

          <div className="col-md-2">
            <Dropdown
              field={
                {
                  id: 'airlineId',
                  label: 'Airline. ',
                  type: 'select',
                  value: MawbForm.airlineId,
                  required: true,
                  error: MawbForm.airlineIderror,
                  item: airportiteam,
                  onChange: (value, field) => {
                    handleBasicInputChange(value, field)
                  }

                }
              }
            />
          </div>

          <div className="col-md-2">
            <InputField
              field={
                {
                  id: 'invoiceNumber',
                  label: 'Invoice No.',
                  type: "number",
                  value: MawbForm.invoiceNumber,
                  error: MawbForm.invoiceNumbererror,
                  onChange: handleBasicInputChange
                }
              }
            />
          </div>

          <div className="col-md-2">
            <InputField
              field={
                {
                  id: 'packages',
                  label: 'Packages',
                  type: "number",
                  value: MawbForm.packages,
                  disable: true,

                  error: MawbForm.packageserror,
                  onChange: handleBasicInputChange
                }
              }
            />
          </div>

          <div className="col-md-2">
            <InputField
              field={
                {
                  id: 'grossWeight',
                  label: 'Gross wt.',
                  type: "number",
                  value: MawbForm.grossWeight,
                  disable: true,
                  error: MawbForm.grossWeighterror,
                  onChange: handleBasicInputChange
                }
              }
            />
          </div>

          <div className="col-md-2">
            <InputField
              field={
                {
                  id: 'volumnWeight',
                  label: 'Volumn Wt',
                  type: "number",
                  value: MawbForm.volumnWeight,
                  disable: true,
                  error: MawbForm.volumnWeighterror,
                  onChange: handleBasicInputChange
                }
              }
            />
          </div>

          <div className="col-md-2">
            <InputField
              field={
                {
                  id: 'chargeWeight',
                  label: 'Charge Wt.',
                  type: "number",
                  value: MawbForm.chargeWeight,
                  disable: true,
                  error: MawbForm.chargeWeighterror,
                  onChange: handleBasicInputChange
                }
              }
            />
          </div>

          <div className="col-md-2">
            <InputField
              field={
                {
                  id: 'finalChargeWeight',
                  label: 'Final Charge Wt.',
                  type: "number",
                  required: true,
                  value: MawbForm.finalChargeWeight,
                  error: MawbForm.finalChargeWeighterror,
                  onChange: handleBasicInputChange
                }
              }
            />
          </div>

          <div className="col-md-2">
            <Dropdown
              field={
                {
                  id: 'airportOfDeparture',
                  label: 'Airport Of Departure.',
                  type: "select",
                  value: MawbForm.airportOfDeparture,
                  disable: true,
                  error: MawbForm.airportOfDepartureerror,
                  item: airportiteam,
                  onChange: handleBasicInputChange
                }
              }
            />
          </div>

          <div className="col-md-2">
            <Dropdown
              field={
                {
                  id: 'airportOfDestination',
                  label: 'Airport Of Destination.',
                  type: "select",
                  value: MawbForm.airportOfDestination,
                  disable: true,
                  error: MawbForm.airportOfDestinationerror,
                  item: airportiteam,
                  onChange: handleBasicInputChange
                }
              }
            />
          </div>

          <div className="row">
            <div className="col-md-3 mt-2">
              <Checkbox
                checked={MawbForm.dgShipForXML}
                onChange={() => { handleBasicInputChange(!MawbForm.dgShipForXML, { id: 'dgShipForXML' }) }}
              />
              {"DG Ship for XML"}
            </div>
            <div className="col-md-3 mt-2">
              <Checkbox
                checked={MawbForm.void}
                onChange={() => { handleBasicInputChange(!MawbForm.void, { id: 'void' }) }}
              />
              {"VOID"}
            </div>
          </div>
        </div>





      </div >
      <div className="hr-form-box">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="MAIN DETAILS" value="1" />
                <Tab label="OTHER DETAILS" value="2" />
                <Tab label="AWB CHARGES" value="3" />
                <Tab label="Airline Buying" value="4" />
                <Tab label="Discrepancy" value="5" />
                <Tab label="Charges Total" value="6" />

              </TabList>
            </Box>
            <TabPanel value="1">
              <Maintab
                notifyItem={notifyItem}
                MawbForm={MawbForm}
                handleBasicInputChange={handleBasicInputChange}
              />
            </TabPanel>
            <TabPanel value="2">
              <OtherDetails
                currencyItem={currencyItem}
                MawbForm={MawbForm}
                handleBasicInputChange={handleBasicInputChange}
              />
            </TabPanel>
            <TabPanel value="3">
              {/* <AwsCharge
                chargeheadItem={chargeheadItem}
                MawbForm={MawbForm}
                handleBasicInputChange={handleBasicInputChange}
                awbChargesDetailsError={awbChargesDetailsError}
                SetAwbChargesDetailsError={SetAwbChargesDetailsError}

              /> */}

              {AwsCharge({
                chargeheadItem, MawbForm, handleBasicInputChange, awbChargesDetailsError, SetAwbChargesDetailsError
              })}

            </TabPanel>


            <TabPanel value="4">
              <AirLineBuying
                currencyItem={currencyItem}
                MawbForm={MawbForm}
                handleBasicInputChange={handleBasicInputChange}
              />
            </TabPanel>

            <TabPanel value="5">
              <Discrepancy
                MawbForm={MawbForm}
                handleBasicInputChange={handleBasicInputChange}
              />
            </TabPanel>

            <TabPanel value="6">
              <ChargesTotal
                MawbForm={MawbForm}
                handleBasicInputChange={handleBasicInputChange}
              />
            </TabPanel>
          </TabContext>






        </Box>
        <div className="cmd">
          <div></div>
          <Button className='mx-3' variant='contained' onClick={handleFormSubmit}>submit</Button>
        </div>
      </div>

    </>
  )
}

export default CreateMawbForm;


// "discrepancy": [
//   {
//     "weightRevised": 0,
//     "freightRevised": 0,
//     "commisionRevised": 0,
//     "discountRevised": 0,
//     "dueCarrierRevised": 0,
//     "dueAgentRevised": 0,
//     "total": 0
//   }
// ],


// "chargesTotal": [
//   {
//     "weightPP": 0,
//     "dueCarrierPP": 0,
//     "dueAgentPP": 0,
//     "valuationPP": 0,
//     "totalPP": 0,
//     "weightCC": 0,
//     "dueCarrierCC": 0,
//     "dueAgentCC": 0,
//     "valuationCC": 0,
//     "totalCC": 0
//   }
// ]


// "AirlineBuying": [
//   "The AirlineBuying field is required."
// ],
// "ShippingInformation": [
//   "The ShippingInformation field is required."
// ]




// onChange: (selectedValue) => {
//   const selectedItem = notifyItem.find(item => item.value === selectedValue);
//   if (selectedItem) {
//       // Update notifyAddress value based on selected name
//       const { address1 } = selectedItem;
//       handleBasicInputChange('notify', selectedValue); // Update notify value
//       handleBasicInputChange('notifyAddress', address1); // Update notifyAddress value
//   }
// }
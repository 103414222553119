

import { Avatar, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from "react";
import { useEffect, useState } from "react";
import axios from 'axios';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown, { InputField } from '../../component/controls';
import { formconstant } from '../../constant/formconstant';





export const JobRegister = () => {

    const enquirybranch = useSelector(state => state.enquirybranch)





    const handleFormSubmit = () => {
        
        console.log('vendorrequestData')

    };





    const handleInputChange = (value, field) => {
        debugger
        console.log(value)
        if (field.id === 'stateId') {
            // set_CityItemData(value);
        }

    };

    return (
        <>
            <div className="hr-form-box">
                 {<h6>Job Register</h6>}
                <div className="row">
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'enquiryno',
                                    label: formconstant.enquiryno,
                                    type: 'text',
                                    value: "",
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.quantitino,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.jobno,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.enqconstomername,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.bizgenerat,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'taxRateId',
                                    label: formconstant.customertype,
                                    type: 'select',
                                    value:"",
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    
                
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.frtservicetype,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.frservicesubtyp,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.bizcategorytype,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.biztype,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.bizsubtype,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.packages,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.grossweight,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.volumnweight,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.chargableweight,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.dimensions,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.apol ,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.apod,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.pickupaddress,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.deliveryaddress,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.gcr,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.gsp,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: 'DATE DATE DATE',
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.consoletype,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.shipper,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.consignee,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.notify,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.subAgent,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.onp1,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.onp2,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.onp3,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.mawbno,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.mawbprint,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.shipperinvoiceno,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: 'date date date date',
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.shipperincoterm,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.freight,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.checklist,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.checklist,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                    <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.checkAprvbyshipper,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.sbno,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: 'date date date',
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.appr,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.apprdc,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.goodsexpectat,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.ptransport,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.goodsreceived,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: 'date date date',
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.mawbno,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.hawb,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.bookwithairline,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.tetivfligtdetail,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.lasthandovertime,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: formconstant.actuaslhandtime,
                                    type: 'text',
                                    value: '',
                                    required: true,
                                    error: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.asignjobto,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.salespersion,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: formconstant.salessupppersion,
                                    type: "select",
                                    value: '',
                                    required: true,
                                    error: '',
                                    item: '',
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                </div>
                    <div className="cmd">
                <div></div>
                <Button
                    sx={{ m: 2, borderRadius: "10px" }}
                    onClick={handleFormSubmit}
                    variant="contained"
                >
                    Submit
                </Button>
            </div>
            </div>

        </>
    );

}


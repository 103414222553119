import { useEffect, useState } from "react";
import { MultiTable } from "../../component/multiTable";
import { GetMasterAccountGroupByPage, GetMasterCityByPage, GetMasterPrimaryGroupByPage, GetMasterStateByPage, GetMasterSubGroupByPage, PostMasterAccountGroup, PostMasterBranch, PostMasterPrimaryGroup, UpdateMasterBranchStatus, UpdateMasterPrimaryGroup, UpdateMasterPrimaryGroupStatus } from "../../../services/constant";
import MasterCallBypagination from "../../../services/masterpaginationConfig";
import axios from "axios";
import DivisonDrawer from "../../component/divisionDrawer";
import { IconButton, Switch, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import ControlDynamicForm from "../../component/controls/controlDynamicForm";
import GetCaller, { PostCaller } from "../../../services/apiServices";
import { SmallSwitch } from "../../component/controls";
const authToken = localStorage.getItem('authToken')
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
  };


const PrimaryGroup = () => {
    const dispatch = useDispatch()
    const [primaryGroupMasterData, setPrimaryGroupMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)





    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterPrimaryGroupByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();


    }, []);

    const setTableDataHandler = ({ response }) => {
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            pg: res.name,
            ac: res.accountGroup.value,
            acid: res.accountGroup.key,
            accountGroupcode: res.accountGroupName,
            status: res.status
        }));
        setPrimaryGroupMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'pg',
            label: 'Primary Group',
            numeric: false,
        },

        {
            id: 'ac',
            label: 'A/C Group',
            numeric: false,
        },
        {
            id: 'accountGroupcode',
            label: 'A/C Group Code ',
            numeric: false,
        },
        
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];




    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateMasterPrimaryGroupStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            {/* <PrimaryGroupDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} /> */}
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            {/* <PrimaryGroupDrawer callAPI={fatch_MasterDataBYPagination} /> */}
        </>
    }


    //table head section tool



    return (
        <>

            <MultiTable
                data={primaryGroupMasterData}
                columns={columns}
                //  drawerFormFields={drawerFormFields}
                tabletool={TableTool}
                actioncell={ActionCell}
                title={'Primary Group'}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default PrimaryGroup;





const PrimaryGroupDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()
    const [closeValidator, setcloseValidator] = useState(false)
    const [primaryName, setPrimaryGroupName] = useState(row?.pg || '')
    const [primaryNameError, setPrimaryGroupNameError] = useState(false)
    const [acGroup, setAcGroup] = useState(row?.acid || '')
    const [acGroupError, setAcGroupError] = useState(false)
    const [acgroupItem, setAcgroupItem] = useState('')

    const drawerOpenCallbackHandler = () => {
        setPrimaryGroupName(row?.pg || '')
        setAcGroup(row?.acid || '')
        setPrimaryGroupNameError(false)
        setAcGroupError(false)
    }

    const handleSubmit = () => {
        if (!primaryName || !acGroup) {
            setPrimaryGroupNameError(!primaryName)
            setAcGroupError(!acGroup)

        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: primaryName,
                accountGroupId: acGroup,
                status: row.status
              } : {
                name: primaryName,
                accountGroupId: acGroup,
              }             
              editor ?
                PostCaller({
                  API: UpdateMasterPrimaryGroup,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
        
                :    
                PostCaller({
                  API: PostMasterPrimaryGroup,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'pg':
                setPrimaryGroupName(value)
                setPrimaryGroupNameError(!value);
                break;
            case 'ac':
                setAcGroup(value)
                setAcGroupError(!value);
                break;


            default:
                break;
        }
    };
    const set_AcgroupItemData = () => {
        GetCaller({
            API: PostMasterAccountGroup,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newAcgroupItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setAcgroupItem(newAcgroupItemData)
            }
        })

    }

    useEffect(() => {
        set_AcgroupItemData();

    }, []);
    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);

    const drawerFormFields = editor ?
        {
            title: "Edit Primary Group",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Primary Group',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Primary Group",
            tooltitle: 'Add Primary Group',
            defaultbtnfalse: true
        }





    const FieldData = [

        {
            id: 'ac',
            label: 'A/c Group',
            type: 'select',
            value: acGroup,
            required: true,
            error: acGroupError,
            item: acgroupItem,
            helperText: 'A/c Group required',
            onChange: handleInputChange,
        },

        {
            id: 'pg',
            label: 'Primary Group ',
            type: 'text',
            value: primaryName,
            required: true,
            error: primaryNameError,
            helperText: 'Primary Group required',
            onChange: handleInputChange,
        },
    ];

    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}


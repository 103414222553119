

import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetGSAMasterByPage, GetMasterSectorByPage, PostMasterSector, UpdateMasterSector, UpdateGSAMasterStatus } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { Checkbox, IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { creategoodinregister, creategsamaster, createtdsmaster, goodsinregisterform, goodsoutregisterform } from '../../../navigation/constant';

import { useDispatch } from 'react-redux';
import { columnConstant } from '../../constant/formconstant';
import DivisonDrawer from '../../component/divisionDrawer';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import { CancelVehicleForGoodsIn, GetExpectedGoodofExportsByPage, GetWerehouseGoodsInRegisterByPage, PostExpectedGoodofExports, UpdateExpectedGoodofExports } from '../../../services/APIConstant/warehouseConstant';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { ChargeExportImport, EnquiryDimension, GoodsConditions, PickUpType, WareHouseGoodsStatus } from '../../../services/modalData';
import { Add, Cancel, Download, FireTruck } from '@mui/icons-material';
import { CustomerTable } from '../customer/customerTable';
import { ConsigneeSubAgencyAddressForm } from '../addConsignee/consigneeAgencyAddressform';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import AlertDialogBox from '../../component/tabs/alertDialog';
import Dropdown, { InputField } from '../../component/controls';
import RvHookupIcon from '@mui/icons-material/RvHookup';
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};


const GoodInRegisterList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [cancelledVehicleRemark, setCancelledVahicleRemark] = useState('')
    const [goodstransport, setGoodsTransport] = useState([])
    const [openDialogForCancelledVehicle, setOpenDialogForCancelledVehicle] = useState(false)

    const [goodofExportData, setGoodofExportData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const [selectedParty, setSelectedParty] = useState([]);
    const [selectedTransporter, setSelectedTransporter] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState({})

    useEffect(() => {
        console.log('selectedTransporter', selectedTransporter, selectedRowData)
    }, [selectedTransporter, selectedRowData])



    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetWerehouseGoodsInRegisterByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
        sessionStorage.removeItem('goodsInVehicleReceivedData')
        sessionStorage.removeItem('goodsInRegisterDetails')
        sessionStorage.removeItem('selectedGoodsOutId')
        sessionStorage.removeItem('goodsOutRegisterDetails')

    }, []);

    const handleSelectedPartyArray = (id) => {
        setSelectedParty(prevIds => {
            if (prevIds.includes(id)) {
                return prevIds.filter(existingId => existingId !== id);
            } else {
                return [...prevIds, id];
            }
        });
    };


    const handleSelectedTransportArray = (id, res) => {
        setSelectedRowData((prevSelectedRowData) => {
            if (prevSelectedRowData.id && prevSelectedRowData.id === res.id) {
                // Update selectedTransporter state
                setSelectedTransporter((prevTransporter) => {
                    if (prevTransporter.includes(id)) {
                        return prevTransporter.filter((existingId) => existingId !== id);
                    } else {
                        return [...prevTransporter, id];
                    }
                });
                return prevSelectedRowData; // No change in selectedRowData
            } else {
                // Update both selectedRowData and selectedTransporter states
                setSelectedTransporter([id]);
                return res;
            }


        });


    };






    const checkBoxHandler = (id, res) => {
        return <>
            <Tooltip title='Goods Out'>
                <Checkbox
                    disabled={res.goodsStatus === 1 ? true : false}
                    onChange={() => { handleSelectedPartyArray(id) }}
                />
            </Tooltip>
        </>
    }

    const transporterCheckBoxHandler = (res, row, transporterId, selectedTransporter) => {
        return (
            <Tooltip title='Goods Received'>
                <Checkbox
                    checked={selectedTransporter?.includes(transporterId)}
                    disabled={row?.goodsStatus === 1 ? false : true}
                    onChange={() => {
                        handleSelectedTransportArray(transporterId, res)
                    }}
                />
            </Tooltip>
        );
    }



    const CompareValuesSequence = (expected, actual) => {
        return expected ? `${expected} / ${actual}` : `${actual}`
    }

    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            ...res,
            sn: index + 1,
            checkBox: checkBoxHandler(res.id, res),
            jobtypename: ChargeExportImport[res.jobType - 1]?.name,
            expectedGrossWeight: CompareValuesSequence(res.expectedGrossWeight, res.grossWeight),
            expectedTotalPackages: CompareValuesSequence(res.expectedTotalPackages, res.packages),
            packagesOut: CompareValuesSequence(res.packages, res.actualOutPackages),
            grossWeightOut: CompareValuesSequence(res.grossWeight, res.actualOutGrossWeight),
            pickupname: PickUpType[res.pickupStatus - 1]?.name,
            dimensionName: EnquiryDimension[res.dimension - 1]?.name,
            goodsStatusName: WareHouseGoodsStatus[res.goodsStatus - 1]?.name,
            customerBranchList: res.transporters.map(({ goodsCondition, ...rest }) => ({
                ...rest,
                res: res,
                checkBox: transporterCheckBoxHandler(res, rest, rest.id, selectedTransporter), // this way component not re - render that why we passing that component from subcolumns render function
                entryId: res.id,
                goodsConditionName: GoodsConditions[goodsCondition - 1]?.name,
                goodsStatusName: WareHouseGoodsStatus[rest.goodsStatus - 1]?.name,
                expectedGrossWeight: CompareValuesSequence(rest.expectedGrossWeight, rest.packages),
                expectedTotalPackages: CompareValuesSequence(rest.expectedTotalPackages, rest.grossWeight),
                goodsConsitionWithImage: <div>
                    {GoodsConditions[goodsCondition - 1]?.name} {rest.uploadFilePath && <Download sx={{ fontSize: "1rem !important" }} />}
                    {/* uploadFilePath */}
                </div>

                // uploadFile:
            })),

        }))
        setGoodofExportData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'checkBox',
            label: '',
            numeric: false,
        },
        {
            id: 'id',
            label: columnConstant.entryno,
            numeric: false,
        },
        {
            id: 'partyName',
            label: columnConstant.partyname,
            numeric: false,
        },

        {
            id: 'jobNumber',
            label: columnConstant.jobno,
            numeric: false,
        },
        {
            id: 'actualShipperName',
            label: columnConstant.actualshipper,
            numeric: false,
        },
        {
            id: 'airportOfDestinationName',
            label: 'APOD',
            numeric: false,
        },

        // {
        //     id: 'jobtypename',
        //     label: columnConstant.jobtype,
        //     numeric: false,
        // },


        {
            id: 'expectedTotalPackages',
            label: 'Packages In',
            numeric: false,
        },

        {
            id: 'packagesOut',
            label: 'Packages Out',
            numeric: false,
        },

        {
            id: 'expectedGrossWeight',
            label: 'Gross Wt In',
            numeric: false,
        },
        {
            id: 'grossWeightOut',
            label: 'Gross Wt Out',
            numeric: false,
        },
        // {
        //     id: 'dimensionName',
        //     label: columnConstant.dimension,
        //     numeric: false,
        // },

        {
            id: 'mawbNumber',
            label: columnConstant.mawbno,
            numeric: false,
        },
        {
            id: "hawbNumber",
            label: columnConstant.hawbno,
            numeric: false,
        },
        {
            id: "tcNumber",
            label: 'Tc No.',
            numeric: false,
        },
        {
            id: "goodsInDateTime",
            label: "Received Date",
            numeric: false,
        },
        {
            id: "goodsStatusName",
            label: "Goods Status",
            numeric: false,
        },

        {
            id: 'action',
            label: columnConstant.action,
            numeric: false,
        },
        {
            id: 'subtable',
            label: '',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];

    const subColumns = [
        {
            id: 'checkBox',
            label: '#',
            numeric: false,
            rendor: (row) => transporterCheckBoxHandler(row.res, row, row.id, selectedTransporter)
        },

        {
            id: 'vendorName',
            label: 'Transporter Name',
            numeric: false,
        },
        {
            id: 'vehicleTypeName',
            label: 'Pickup Vehicle Type',
            numeric: false,
        },
        {
            id: 'vehicleNumber',
            label: 'Vehicle No.',
            numeric: false,
        },
        {
            id: 'driverName',
            label: 'Driver Name',
            numeric: false,
        },

        {
            id: 'driverNumber',
            label: 'Driver No.',
            numeric: false,
        },
        {
            id: 'expectedTotalPackages',
            label: 'Packages',
            numeric: false,
        },
        {
            id: 'expectedGrossWeight',
            label: 'Gross Weight',
            numeric: false,
        },
        {
            id: 'goodsConsitionWithImage',
            label: 'Goods Condition',
            numeric: false,
        }, {
            id: 'comment',
            label: 'Comments',
            numeric: false,
        },
        {
            id: "goodsStatusName",
            label: "Goods Status",
            numeric: false,
        },

        {
            id: 'action',
            label: '',
            numeric: false,
        },

    ];

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const downloadPDF = () => {
        const scale = 1.5; // Adjust this value to increase or decrease the resolution

        html2canvas(componentRef.current, { scale: scale }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png', 10.0); // Use a quality setting of 1.0
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);
            pdf.save('challan.pdf');
        });
    };


    const CancelTheVehicle = () => {

        GetCaller({
            API: CancelVehicleForGoodsIn,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                entryId: openDialogForCancelledVehicle.entryId,
                vehicleId: openDialogForCancelledVehicle.id,
                remark: cancelledVehicleRemark,
                GoodsStatus: goodstransport || ""
            },
            callback: ({ response }) => {
                setOpenDialogForCancelledVehicle(false)
                setCancelledVahicleRemark('')
                fatch_MasterDataBYPagination()
                setGoodsTransport("")
            }

        });
    }

    const subActionCell = ({ row }) => {
        debugger
        return <>

            {/* <button onClick={handlePrint}>Print</button> */}

            <Tooltip title='Download Receiving'>
                <IconButton onClick={downloadPDF} >
                    <Download />
                </IconButton>
            </Tooltip>

            {
                row.goodsStatus === 1 &&
                <Tooltip title={'Goods Received'}>
                    <IconButton onClick={() => {
                        debugger
                        let data = row.res;
                        data.isRceivedQuery = true;
                        if (row?.res?.transporters) {
                            data.transporters = row.res.transporters.filter(res => res.id === row.id);
                        }
                        sessionStorage.setItem("goodsInVehicleReceivedData", JSON.stringify(data));
                        navigate(goodsinregisterform);
                    }}>
                        <i className='las la-truck'></i>
                    </IconButton>
                </Tooltip >

            }


            <Tooltip title={row.goodsStatus !== 3 ? 'Cancel Vehicle' : 'Receive'}>
                <IconButton onClick={() => { setOpenDialogForCancelledVehicle(row) }} >
                    {row.goodsStatus !== 3 ?
                        <Cancel />
                        :
                        <RvHookupIcon />
                    }
                </IconButton>
            </Tooltip>


        </>
    }

    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const updatedStatus = !row?.status;
            const data = {
                id: row?.id,
                status: updatedStatus
            }
            axios.get(UpdateGSAMasterStatus(data), { headers })
                .then((response) => {
                    fatch_MasterDataBYPagination()
                })
        }
        const editBtnClicked = () => {
            console.log("hhhhh")
        }
        return <>
            <Tooltip title={columnConstant.editgoodinregister}>
                <IconButton onClick={() => {
                    // sessionStorage.setItem("goodsInRegisterListData", JSON.stringify(row))
                    sessionStorage.setItem("goodsInRegisterDetails", JSON.stringify(row.id))
                    navigate(goodsinregisterform)
                }}>
                    <i className='las fa-edit'></i>
                </IconButton>
            </Tooltip >

            <Tooltip title={columnConstant.delete}>
                <IconButton>
                    <i className='las fa-trash'></i>
                </IconButton>
            </Tooltip >

            <Tooltip title={columnConstant.goodsout}>
                <IconButton
                    onClick={() => {
                        sessionStorage.setItem("selectedGoodsOutId", JSON.stringify([row?.id]))
                        navigate(goodsoutregisterform)
                    }}
                >
                    <i className='las la-truck'></i>
                </IconButton>
            </Tooltip >

            {/* 
you have to remove this code got it */}
            {/* <ConsigneeSubAgencyAddressForm row={row}
                callback={() => { }}

            /> */}
        </>


    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            {
                selectedParty.length !== 0 && <Tooltip title={'Selected Goods Out'}>
                    <IconButton
                        className='table-toggle'
                        onClick={() => {
                            sessionStorage.setItem("selectedGoodsOutId", JSON.stringify(selectedParty))
                            navigate(goodsoutregisterform)
                        }}
                    >
                        <i className='las la-truck'></i>
                    </IconButton>
                </Tooltip >
            }

            {
                (selectedTransporter && selectedTransporter.length !== 0) && <Tooltip title={'Selected Goods Received'}>
                    <IconButton
                        className='table-toggle'
                        onClick={() => {

                            let data = selectedRowData;
                            data.isRceivedQuery = true;
                            if (selectedRowData.transporters) {
                                data.transporters = selectedRowData.transporters.filter(object => selectedTransporter.includes(object.id));

                            }
                            sessionStorage.setItem("goodsInVehicleReceivedData", JSON.stringify(data));
                            navigate(goodsinregisterform);
                        }}
                    >
                        <i className='las la-truck'></i>
                    </IconButton>
                </Tooltip >
            }

            <Tooltip title={columnConstant.addgoodinregister}>
                <IconButton
                    className='table-toggle'
                    onClick={() => { navigate(goodsinregisterform) }}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >




        </>
    }

    const conditions = {
        subColumns: subColumns,
        subActionCell: subActionCell,
        openAllSubTable: false
    }

    return (
        <>
            {
                selectedTransporter
            }

            <div ref={componentRef}>
                <CustomerTable
                    data={goodofExportData}
                    conditions={conditions}
                    columns={columns}
                    title={columnConstant.goodsinregister}
                    tabletool={TableTool}
                    actioncell={ActionCell}
                    totaldatalength={totaldatalength}
                    paginationhandler={fatch_MasterDataBYPagination}
                />
            </div>



            <AlertDialogBox
                style={{ textAlign: "center", display: "flex", justifyContent: "space-around" }}
                open={openDialogForCancelledVehicle}
                title={`${openDialogForCancelledVehicle.goodsStatus !== 3 ? 'Cancel Vehicle' : 'Receive Vehicle'} (${openDialogForCancelledVehicle.vehicleNumber || ''})`}
                description={<div style={{ width: "350px", textAlign: 'left' }}>
                    {openDialogForCancelledVehicle.goodsStatus !== 3 ?
                        <InputField field={{ label: "Remark", value: cancelledVehicleRemark, onChange: (value) => { setCancelledVahicleRemark(value) } }} /> :
                        <Dropdown field={{ label: "Receive", item: WareHouseGoodsStatus.filter(object => [2].includes(object.value)), value: goodstransport, onChange: (value) => { setGoodsTransport(value) } }} />}
                </div>}
                handleClose={() => {
                    setOpenDialogForCancelledVehicle(false);
                    setCancelledVahicleRemark('');
                }}
                handleSubmit={CancelTheVehicle}
            />
        </>
    )
}

export default GoodInRegisterList;





// Dropdown.js
import { Autocomplete, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, Switch, TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { ClockIcon, DatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/system';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 34,
    height: 16,
    padding: 0,
    // display: 'flex',

    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(15px)',
            color: "gray"

        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,

        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: 'gray',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? 'yellow' : 'var(--color-main)',
            },
            '& .MuiSwitch-thumb': {
                color: "#fff"
            }
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        color: "gray",
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),

    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#fff',
        boxSizing: 'border-box',
        border: "1px solid #dcdcdc",

    },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
    fontSize: '14px', // Adjust the font size as needed
    '& .MuiInputLabel-root': {
        fontSize: '14px', // Adjust label font size
    },
}));

const Dropdown = ({ field, className }) => {

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        field?.onChange(newValue, field);
    };


    return (
        <FormControl className={className ? className : 'mt-3'} sx={{ width: '100%', fontSize: '13px' }} size="small"
            error={field?.required && field?.error}
        >
            <InputLabel id={`label-${field?.label}`}>{`${field?.label}${field.required ? " *" : ''}`}</InputLabel>
            <Select
                // className='select'
                labelId={`label-${field?.label}`}
                id={`select-${field?.label}`}
                name={field?.label}
                value={field?.value || ''}
                label={field?.label}
                required={field?.required && !field?.disable}
                disabled={field?.disable}
                onChange={(e) =>
                    handleSelectChange(e)
                }

            >
                <MenuItem value="">
                    <em>Select</em>
                </MenuItem>
                {
                    field?.item && field?.item.map((item, index) => {
                        return <MenuItem
                            key={index}
                            disabled={item?.disable}
                            value={item?.value ? item?.value : item?.name ? item?.name : item}
                        >
                            {(item?.name || item.value) ? item?.name : item}
                        </MenuItem>
                    })
                }

            </Select>
            {/* <FormHelperText>{field.error && field.required && field.helperText}</FormHelperText> */}
        </FormControl>
    );
};
export default Dropdown;

export const InputField = ({ field, className, style }) => {



    const handleInputChange = (event) => {
        const newValue = event.target.value;

        if (field.type === 'number' && newValue < 0 || field.type === 'number' && field.value === ('' || 0 || null || undefined) && newValue === 0) {
            return false;
        } else {
            field?.onChange(newValue, field);

        }
    };

    return (
        <MyTextField
            className={className ? className : 'mt-3'}
            sx={{ width: '100%', fontSize: '13px' }} fullWidth size="small"

            id={field.id}
            label={field.label}
            value={field.value || ''}
            error={!field?.disable && field.required && field.error}
            // helperText={field.error && field.required && field.helperText}
            required={field.required}
            disabled={field?.disable}
            type={field.type}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e) => { field?.onBlur?.(e) }}

        />
    );
};

export const InputFileField = ({ field, className }) => {


    const handleInputChange = (event) => {
        const newValue = event.target.files[0];
        field?.onChange(newValue, field);
    };

    return (
        <TextField className={className ? className : 'mt-3'}
            InputLabelProps={{
                shrink: true,
            }}
            sx={{ width: '100%', fontSize: '13px' }} fullWidth size="small"
            id={field.id}
            label={field.label}
            // value={field.value}
            error={field.required && field.error}
            // helperText={field.error && field.required && field.helperText}
            required={field.required}
            disabled={field?.disable}
            type={field.type}
            onChange={(e) => handleInputChange(e)}

        />
    );
};


export const StackDropdown = ({ field, className }) => {

    const handleInputChange = (event, value) => {
        debugger;

        // Map selected values to their corresponding numeric values
        const selectedValues = value?.map(selectedValue => {
            const matchingOption = field?.item?.find(option => option?.name === selectedValue);
            return matchingOption ? matchingOption?.value : null;
        });

        const pairselect = []
        value?.map(selectedValue => {
            field?.item?.find(option => {
                if (option.name === selectedValue) {
                    pairselect.push({
                        name: selectedValue,
                        value: option.value
                    })
                }
            });

        });



        console.log('multidropdown', value, selectedValues, pairselect);
        // Uncomment the following line to update the state or dispatch an action
        field?.onChange(pairselect, field);
    };

    return (
        <Stack spacing={2} >
            <Autocomplete
                className={className ? className : 'mt-3'}
                multiple
                id="size-small-outlined-multi"
                size="small"
                value={Array.isArray(field?.value) ? field?.value.map((res) => res?.name ? res.name : res?.value ? res?.value : res) : []}

                // value={field?.value && field?.value?.map((res) => res?.value ? res?.value : res)}
                options={field.item?.map((option) => option?.name || option)}
                onChange={(event, value) => handleInputChange(event, value)}
                getOptionLabel={(option) => option?.value || option}
                renderInput={(params) => (
                    <TextField {...params}
                        label={`${field?.label}${field.required ? " *" : ''}`}
                        error={field.required && field.error}
                    />
                )}
            />
        </Stack>
    );
}



export const AutoSelect = ({ field, className }) => {


    const handleInputChange = (event, value) => {
        const selectedOption = field?.item?.find(option => (option.name === value));
        field?.onChange(selectedOption?.value, field);
        console.log(event, selectedOption, value)

    };

    const incudesValue = field?.item?.includes(field?.value)
    const filteredValue = field?.item?.find(res => res.value === field.value)?.name || (incudesValue ? field?.value : '');

    return (
        <Stack spacing={2}>
            <Autocomplete
                className={className || 'mt-3'}
                freeSolo
                // disableClearable
                id="combo-box-demo"
                size='small'
                value={filteredValue}
                options={field?.item?.map((option) => option?.name || option)}
                onChange={(event, value) => handleInputChange(event, value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={field.label}
                        required={field?.required}
                        onChange={(e) => {
                            if (field?.onInputProps) {
                                field?.onInputProps(e.target.value)
                            }
                        }}
                        error={field.required && field.error}
                    />
                )}
            />
        </Stack>
    );
};


export const DateField = ({ field, className, format }) => {


    const handleInputChange = (value) => {
        field?.onChange(value, field);
    };

    return (
        <div className={` ${className ? className : 'mt-2'} w-100`}>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DemoContainer components={['DatePicker']}  >
                    <DemoItem>
                        <DatePicker disabled={field?.disable || false} className='date-input' label={`${field?.label}${field.required ? " *" : ''}`}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: field.error ? 'red' : '',
                                        color: field.error ? 'red' : '',
                                    }
                                }
                            }}
                            value={field.value ? dayjs(field?.value) : null}
                            onChange={(date) => handleInputChange(format ? date?.format(format) : date?.format('YYYY/MM/DD'))}
                        />
                    </DemoItem>
                </DemoContainer>
            </LocalizationProvider>
        </div>

    );
};


export const TimeField = ({ field, className, format }) => {


    const handleInputChange = (value) => {
        debugger
        field?.onChange(value, field);
    };

    return (
        <div className={`${className || 'mt-2'} w-100`}>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DemoContainer components={['TimePicker']}  >
                    <DemoItem>
                        <TimePicker
                            className="time-input"
                            id={field.id}
                            label={field.label}
                            slotProps={{ textField: { size: 'small' } }}
                            sx={{
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: field.error ? 'red' : '',
                                        color: field.error ? 'red' : '',
                                    }
                                }
                            }}
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(time) => {
                                handleInputChange(format ? time?.format(format) : dayjs(time))
                            }}
                        />


                    </DemoItem>
                </DemoContainer>
            </LocalizationProvider>
        </div>

    );
};

export const TextAreafield = ({ field, className, style }) => {


    const handleInputChange = (event) => {
        const newValue = event.target.value;
        field?.onChange(newValue, field);
    };

    return (

        // <textarea
        //     id={field.id}
        //     value={field.value}
        //     error={field.required && field.error}
        //     
        //     placeholder={field.placeholder}
        //     style={{ borderRadius: "4px", width: '100%' }}
        //     rows={field.rows}
        // />

        <TextField
            className={className ? className : ''}
            fullWidth
            id={field.id}
            label={field.label}
            value={field.value || ''}
            multiline
            rows={4}
            onChange={(e) => handleInputChange(e)}
            required={field.required}
            error={!field?.disable && field.required && field.error}
        // defaultValue="Default Value"
        />
    );
};


export const SmallSwitch = ({ onChange, checked }) => {


    return <>
        <AntSwitch
            // defaultChecked
            checked={checked && checked}
            onChange={onChange && onChange}
            inputProps={{ 'aria-label': 'ant design' }}
        />
    </>
}
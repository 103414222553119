import { useDispatch } from "react-redux"
import { GetHawbByPage } from "../../../../services/constant"
import { useEffect, useState } from "react"
import MasterCallBypagination from "../../../../services/masterpaginationConfig"
import GetCaller from "../../../../services/apiServices"
import { MultiTable } from "../../../component/multiTable"
import { awbstore, createawbstock, createcourierawb, createhawbform, createholdawb, createiatahold, createiatastock } from "../../../../navigation/constant"
import { IconButton, Tooltip } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom"
import { GetAwbStorebypage, GetIataStorebypage } from "../../../../services/APIConstant/jobConstant"
import CreateAwbStock from "../awb/createawbStock"
const IataStock = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [AwsStoreData, setAwsStoreData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetIataStorebypage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item

        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);
    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            awbNumber: res.awbNumber,
            airlineId: res.airlineId,
            airlineName: res.airlineName,
            vendorId: res.vendorId,
            vendorName: res.vendorName,
            stockDate: res.stockDate,
            receivedDate: res.receivedDate,
            isLocked: res.isLocked ? 'Yes' : "No",
            isExecuted: res.isExecuted ? 'Yes' : "No",
            document: res.document,



            // holdawb: HoldCell(res),
            moveawb: MoveCell(res)

        }));
        setAwsStoreData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false
        },
        {
            id: 'awbNumber',
            label: 'Awb Number',
            numeric: false
        },
        {
            id: 'airlineName',
            label: 'Carrier',
            numeric: false
        },
        {
            id: 'vendorName',
            label: 'IATA Agent',
            numeric: false
        },

        {
            id: 'stockDate',
            label: 'Stock Date',
            numeric: false
        },
        {
            id: 'receivedDate',
            label: 'Received Date',
            numeric: false
        },

        {
            id: 'isLocked',
            label: 'Locked',
            numeric: false
        },
        {
            id: 'isExecuted',
            label: 'Executed',
            numeric: false
        },
        {
            id: '',
            label: 'Update IATA Agent',
            numeric: false
        },
        {
            id: '',
            label: 'Carrier Update',
            numeric: false
        },
        {
            id: '',
            label: 'Move Stock',
            numeric: false
        },
        {
            id: '',
            label: 'Move Branch',
            numeric: false
        },
        // {
        //     id:'holdawb',
        //     label:'Hold Awb',
        //     numeric:false
        // },

        {
            id: 'moveawb',
            label: 'Move Awb',
            numeric: false
        },

        {
            id: 'action',
            label: 'Action',
            numeric: false
        },

    ];

    // const HoldCell =(res) =>{
    //     const onclick =() =>{
    //   debugger

    //   sessionStorage.setItem('awbnumber', JSON.stringify(res));
    //     navigate(`${createiatahold}`)

    //     } 
    //     return<>
    //     <Tooltip title="Edit Iata Store">
    //                 <IconButton
    //                     className='tablebtn1'
    //                     onClick={onclick}
    //                 >
    //                     <i className="las la-edit"></i>
    //                 </IconButton>
    //             </Tooltip >
    //         </>
    // }


    const MoveCell = (res) => {
        const onclick = () => {
            debugger
            sessionStorage.setItem('moveawb', JSON.stringify(res));
            navigate(`${createholdawb}`)
        }
        return <>
            <Tooltip title="Create Hold Awb">
                <IconButton
                    className='tablebtn1'
                    onClick={onclick}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >
        </>
    }

    const ActionCell = ({ row }) => {
        const onclick = () => {
            debugger

            sessionStorage.setItem('iatastock', JSON.stringify(row));
            navigate(`${createiatastock}`)

        }
        return <>
            <Tooltip title="Edit Iata Store">
                <IconButton
                    className='tablebtn1'
                    onClick={onclick}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >
        </>
    }


    const TableTool = ({ }) => {
        return <>
            <Tooltip title={"Add Iata Stock"}>
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${createiatastock}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }

    return (
        <>
            <MultiTable
                data={AwsStoreData}
                columns={columns}
                title={"IATA Stock"}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )

}
export default IataStock

const HawbDrawer = ({ callAPI, editor, row }) => {


}
import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { GetMasterCountryByPage, PostMasterCountry, PostMasterSector, UpdateMasterCountry, UpdateMasterCountryStatus } from '../../../services/constant';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import DivisonDrawer from '../../component/divisionDrawer';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';
import { trimInputvalue } from '../../../services/utilities/unittrim';

const Country = () => {
    const authToken = localStorage.getItem('authToken')
    const headers = {
        Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
      };
    const dispatch = useDispatch()
    const [countryMasterData, setCountryMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterCountryByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);

    const setTableDataHandler = ({response}) => {
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            idcod: res.countryCode + res.id,
            id: res.id,
            countryname: res.name,
            countrycode: res.countryCode,
            currencycode: res.currencyCode,
            sector: res.sectorId,
            sectorName: res.sectorName,
            status: res.status
        }));
        setCountryMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }




    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
     
        {
            id: 'countryname',
            label: 'Country Name',
            numeric: false,
        },
        {
            id: 'countrycode',
            label: 'Country Code',
            numeric: false,
        },
        {
            id: 'currencycode',
            label: 'Currency Code',
            numeric: false,
        },
        {
            id: 'sectorName',
            label: 'Sector',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];


    const ActionCell = ({ row }) => {    
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateMasterCountryStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }
                 
                  });       
                }
        return <>
            <CountryDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>

    }

    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <CountryDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>
            {/* filterItems={filterItems} */}
            <MultiTable

                data={countryMasterData}
                columns={columns}
                // drawerFormFields={drawerFormFields}
                title={'Country'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default Country



const CountryDrawer = ({ callAPI, editor, row }) => {
    const authToken = localStorage.getItem('authToken')
    const headers = {
        Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
      };
    const [closeValidator, setcloseValidator] = useState(false)
    const [sectorItem, setSectorItem] = useState([])
    const [countryCode, setCountryCode] = useState(row?.countrycode || '')
    const [countyCodeError, setCountryCodeError] = useState(false)

    const [sectorname, setSectorname] = useState(row?.sector || '')
    const [sectornameError, setSectornameError] = useState(false)

    const [currencyCode, setCurrencyCode] = useState(row?.currencycode || '')
    const [currencyCodeError, setCurrencyCodeError] = useState(false)

    const [countryName, setCountryName] = useState(row?.countryname || '')
    const [countryNameError, setCountryNameError] = useState(false)
    const dispatch = useDispatch()

    const drawerOpenCallbackHandler = () => {
        setCountryCode(row?.countrycode || '')
        setCountryName(row?.countryname || '')
        setCurrencyCode(row?.currencycode || '')
        setSectorname(row?.sector || '')
        setSectornameError(false)
        setCountryCodeError(false)
        setCountryNameError(false)
        setCurrencyCodeError(false)
    }

    const set_SectorItem = () => {
        GetCaller({
            API: PostMasterSector,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newSectorItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setSectorItem(newSectorItemData)
            }

        })

    }

    useEffect(() => {
        set_SectorItem();
    }, []);

    const handleSubmit = () => {
const trimCountryCode = trimInputvalue(countryCode)
const trimCountryName = trimInputvalue(countryName)
const trimCurrency = trimInputvalue(currencyCode)


        if (!trimCountryCode || !trimCountryName || !trimCurrency || !sectorname) {
            setCountryNameError(!trimCountryName)
            setSectornameError(!sectorname)
            setCountryCodeError(!trimCountryCode)
            setCurrencyCodeError(!trimCurrency)
        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: trimCountryName,
                countryCode: trimCountryCode,
                currencyCode: trimCurrency,
                sectorId: sectorname,
                status: row.status
            } : {
                name: trimCountryName,
                    countryCode: trimCountryCode,
                    sectorId: sectorname,
            currencyCode: trimCurrency,
            }
      
            editor ?
              PostCaller({
                API: UpdateMasterCountry,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })    
              :     
              PostCaller({
                API: PostMasterCountry,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })
            setcloseValidator(true)
      
          }

    }

    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);


    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'countrycode':
                setCountryCode(value)
                setCountryCodeError(!value);
                break;
            case 'sector':
                setSectorname(value)
                setSectornameError(!value);
                break;
            case 'countryname':
                setCountryName(value)
                setCountryNameError(!value);
                break;
            case 'currencycode':
                setCurrencyCode(value)
                setCurrencyCodeError(!value);
                break;

            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Country",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Sector',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Country",
            tooltitle: 'Add Country',
            defaultbtnfalse: true
        }

    const FieldData = [
        {
            id: 'countryname',
            label: 'Country Name',
            type: 'text',
            value: countryName,
            required: true,
            error: countryNameError,
            helperText: 'Country required',
            onChange: handleInputChange,
        },
        {
            id: 'countrycode',
            label: 'Country Code',
            type: 'text',
            value: countryCode,
            required: true,
            error: countyCodeError,
            helperText: 'Country code required',
            onChange: handleInputChange,
        },
        {
            id: 'currencycode',
            label: 'Currency Code',
            type: 'text',
            value: currencyCode,
            required: true,
            error: currencyCodeError,
            helperText: 'Currency Code required',
            onChange: handleInputChange,
        },
        {
            id: 'sector',
            label: 'sector',
            type: "select",
            value: sectorname,
            error: sectornameError,
            required: true,
            helperText: "Sector required",
            item: sectorItem,
            onChange: handleInputChange,
        },

    ];

    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            divisiondrawerbody={divisionDrawerody}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
        />

    </>

}


import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { GetCragoTypeByPage, GetMasterDepartmentByPage, GetMasterDesignationByPage, GetMasterDivisionByPage, GetMasterNetworkTypeByPage, GetMasterSubGroupByPage, PostCragoType, PostMasterDepartment, PostMasterDesignation, PostMasterNetworkType, UpdateCargoTypeStatus, UpdateCragoType, UpdateMasterDesignation, UpdateMasterDesignationStatus, UpdateMasterNetworkType, UpdateMasterNetworkTypeStatus } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';
import { trimInputvalue } from '../../../services/utilities/unittrim';


const CargoType = () => {
    const dispatch = useDispatch()
    const [cargotypedata, setCargoTypeData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)


    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetCragoTypeByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({

            sn: index + 1,
            id: res.id,
            cargotype: res.name,
            status: res.status
        }));
        setCargoTypeData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
       
        {
            id: 'cargotype',
            label: 'Cargo Type',
            numeric: false,
        },


        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API:   UpdateCargoTypeStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <CargoTypeDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <CargoTypeDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>
            <MultiTable
                data={cargotypedata}
                columns={columns}
                // filterItems={filterItems}
                title={'Cargo Type'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default CargoType




const CargoTypeDrawer = ({ callAPI, editor, row }) => {
    const [closeValidator, setcloseValidator] = useState(false)
    const [cargoType, setCargoType] = useState(row?.cargotype || '')
    const [cargoTypeError, setCargoTypeError] = useState(false)

    const dispatch = useDispatch()


    const drawerOpenCallbackHandler = () => {
        debugger
        setCargoType(row?.cargotype || '')
        setCargoTypeError(false)
    }

    const handleSubmit = () => {
        const trimcargo = trimInputvalue(cargoType)
        if (!trimcargo) {
            setCargoTypeError(!trimcargo)


        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: trimcargo,           
                status: row.status
              } : {
                 name: trimcargo,        
              }             
              editor ?
                PostCaller({
                  API: UpdateCragoType,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
        
                :
                
        
                PostCaller({
                  API: PostCragoType,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }

    useEffect(() => {
        setcloseValidator(false)

    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'cargotype':
                setCargoType(value)
                setCargoTypeError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Cargo Type",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Cargo Type',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Cargo Type",
            tooltitle: 'Add Cargo Type ',
            defaultbtnfalse: true
        }





    const FieldData = [
        {
            id: 'cargotype',
            label: 'Cargo Type',
            type: 'text',
            value: cargoType,
            required: true,
            error: cargoTypeError,
            onChange: handleInputChange,

        },
    ];

    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody} />

    </>

}


import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetGSAMasterByPage, GetMasterSectorByPage, PostMasterSector, UpdateMasterSector, UpdateGSAMasterStatus, GetChargeHeadByPage, UpdateChargeHeadStatus } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { createchargehead, creategsamaster, createtdsmaster } from '../../../navigation/constant';

import { useDispatch } from 'react-redux';
import { SmallSwitch } from '../../component/controls';
const authToken = localStorage.getItem("authToken");
const headers = {
  Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};

const ChargeHeadMaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sectorMasterData, setSectorMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
  
    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetChargeHeadByPage,
           callback: setTableDataHandler,   dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
        sessionStorage.removeItem('chargehead');

    }, []);

    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            chargeName:res.chargeName,
            chargeCode: res.chargeCode,
            status: res.status,
        }));
       
        setSectorMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
    
        {
            id: 'chargeName',
            label: 'Charge Name',
            numeric: false,
        },
        {
            id: 'chargeCode',
            label: 'Charge Code',
            numeric: false,
        },
      
     
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const updatedStatus = !row?.status;
            const data = {
                id: row?.id,
                status: updatedStatus
            }
            axios.get(UpdateChargeHeadStatus(data),{ headers })
                .then((response) => {
                    fatch_MasterDataBYPagination()
                })
        }
        const editBtnClicked = () =>{    
            sessionStorage.setItem('chargehead',row.id);
            navigate(createchargehead);
        }
        return <>
            <Tooltip title="Edit Chargehead">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >

           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>
    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <Tooltip title="Add Chargehead">
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${createchargehead}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }
    return (
        <>
            {/* filterItems={filterItems} */}
            <MultiTable
                title={'Charge Head'}
                columns={columns}
                data={sectorMasterData}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default ChargeHeadMaster;

import { useEffect, useState } from "react";
import { MultiTable } from "../../component/multiTable";
import { GetMasterAccountGroupByPage, GetMasterCityByPage, GetMasterPrimaryGroupByPage, GetMasterStateByPage, GetMasterSubGroupByPage, PostMasterBranch, PostMasterPrimaryGroup, PostMasterSubGroup, UpdateMasterBranchStatus, UpdateMasterPrimaryGroup, UpdateMasterPrimaryGroupStatus, UpdateMasterSubDepartmentStatus, UpdateMasterSubGroup, UpdateMasterSubGroupStatus } from "../../../services/constant";
import MasterCallBypagination from "../../../services/masterpaginationConfig";
import axios from "axios";
import DivisonDrawer from "../../component/divisionDrawer";
import { IconButton, Switch, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import ControlDynamicForm from "../../component/controls/controlDynamicForm";
import GetCaller, { PostCaller } from "../../../services/apiServices";
import { SmallSwitch } from "../../component/controls";

const authToken = localStorage.getItem('authToken')
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};

const SubGroup = () => {
    const dispatch = useDispatch()
    const [primaryGroupMasterData, setPrimaryGroupMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)





    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterSubGroupByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();


    }, []);

    const setTableDataHandler = ({ response }) => {
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            subgroup: res.name,
            primarygroup: res.primaryGroup.value,
            primarygroupId: res.primaryGroup.key,
            accountgroup: res.accountGroup.value,
            status: res.status
        }));
        setPrimaryGroupMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'subgroup',
            label: 'Sub Group',
            numeric: false,
        },
        {
            id: 'primarygroup',
            label: 'Primary Group',
            numeric: false,
        },
        {
            id: 'accountgroup',
            label: 'Account Group',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateMasterSubGroupStatus,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({ response }) => {
                    fatch_MasterDataBYPagination();
                }
            });
        }
        return <>
            <PrimaryGroupDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
            <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <PrimaryGroupDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }










    //table head section tool



    return (
        <>

            <MultiTable
                data={primaryGroupMasterData}
                columns={columns}
                //  drawerFormFields={drawerFormFields}
                tabletool={TableTool}
                actioncell={ActionCell}
                title={'Sub Group'}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default SubGroup;





const PrimaryGroupDrawer = ({ callAPI, editor, row }) => {
    const [closeValidator, setcloseValidator] = useState(false)
    const [subGroupName, setsubGroupName] = useState(row?.subgroup || '')
    const [SubNameError, setSubGroupNameError] = useState(false)
    const [primaryGroup, setprimaryGroup] = useState(row?.primaryGrId || '')
    const [PrimaryGroupError, setPrimaryGroupError] = useState(false)
    const [primarygroupItem, setPrimarygroupItem] = useState('')
    const dispatch = useDispatch()

    const drawerOpenCallbackHandler = () => {
        setsubGroupName(row?.subgroup || '')
        setprimaryGroup(row?.primarygroupId || '')
        setSubGroupNameError(false)
        setPrimaryGroupError(false)

    }
    const handleSubmit = () => {
        if (!subGroupName || !primaryGroup) {
            setSubGroupNameError(!subGroupName)
            setPrimaryGroupError(!primaryGroup)

        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: subGroupName,
                primaryGroupId: primaryGroup,
                status: row.status
            } : {
                name: subGroupName,
                primaryGroupId: primaryGroup,
            }
            editor ?
                PostCaller({
                    API: UpdateMasterSubGroup,
                    callback: () => callAPI(),
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: postmasterareaData
                })

                :
                PostCaller({
                    API: PostMasterSubGroup,
                    callback: () => callAPI(),
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'subgroup':
                setsubGroupName(value)
                setSubGroupNameError(!value);
                break;
            case 'primaryGr':
                setprimaryGroup(value)
                setPrimaryGroupError(!value);
                break;


            default:
                break;
        }
    };

    const set_AcgroupItemData = () => {
        GetCaller({
            API: PostMasterPrimaryGroup,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newAcgroupItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setPrimarygroupItem(newAcgroupItemData)
            }
        })

    }


    useEffect(() => {
        set_AcgroupItemData();
    }, []);

    useEffect(() => {
        setcloseValidator(false)

    }, [closeValidator]);

    const drawerFormFields = editor ?
        {
            title: "Edit Subgroup ",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Sub Group',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Subgroup ",
            tooltitle: 'Add Sub Group',
            defaultbtnfalse: true
        }

    const FieldData = [
        {
            id: 'subgroup',
            label: 'Sub Group',
            type: 'text',
            value: subGroupName,
            required: true,
            error: SubNameError,
            helperText: 'Sub Group required',
            onChange: handleInputChange,
        },


        {
            id: 'primaryGr',
            label: 'Primary Group ',
            type: 'select',
            value: primaryGroup,
            item: primarygroupItem,
            required: true,
            error: PrimaryGroupError,
            helperText: 'Primary Group required',
            onChange: handleInputChange,
        },

    

    ];

    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}


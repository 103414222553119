import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetAirlineMasterByPage, UpdateAirlineMasterStatus, GetMasterSectorByPage, PostMasterSector, UpdateMasterSector } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { createairline, createtdsmaster } from '../../../navigation/constant';
import { useDispatch } from 'react-redux';
import { columnConstant } from '../../constant/formconstant';
import { SmallSwitch } from '../../component/controls';
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};




const AirlineMaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [airlineMasterData, setAirlineMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetAirlineMasterByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
        sessionStorage.removeItem('airline');

    }, []);

    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            status: res.status,
            legalName: res.legalName,
            contactPerson: res.contactPerson,
            mobileNumber: res.mobileNumber,
            email: res.email,
            gstNumber: res.gstNumber,
            name: res.legalName,
            accountLedgerId: res.accountLedger.value

        }));

        setAirlineMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: columnConstant.sno,
            numeric: false,
        },


        {
            id: 'legalName',
            label: columnConstant.LegalName,
            numeric: false,
        },
        {
            id: 'accountLedgerId',
            label: columnConstant.acledgername,
            numeric: false,
        },
        {
            id: 'contactPerson',
            label: columnConstant.constantperson,
            numeric: false,
        },
        {
            id: 'mobileNumber',
            label: columnConstant.mobilenumber,
            numeric: false,
        },

        {
            id: 'email',
            label: columnConstant.email,
            numeric: false,
        },
        {
            id: 'gstNumber',
            label: columnConstant.gstno,
            numeric: false,
        },
        {
            id: 'action',
            label: columnConstant.action,
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const updatedStatus = !row?.status;
            const data = {
                id: row?.id,
                status: updatedStatus
            }
            axios.get(UpdateAirlineMasterStatus(data), { headers })
                .then((response) => {
                    fatch_MasterDataBYPagination()
                })
        }
        const editBtnClicked = () => {
            debugger
            sessionStorage.setItem('airline', JSON.stringify(row));
            navigate(createairline);
        }
        return <>
            <Tooltip title={columnConstant.editairline}>
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >

           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>

    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <Tooltip title={columnConstant.editairline}>
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${createairline}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }
    return (
        <>
            {/* filterItems={filterItems} */}
            <MultiTable
                title={columnConstant.airlinelist}
                columns={columns}
                data={airlineMasterData}
                // drawerFormFields={drawerFormFields}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default AirlineMaster;

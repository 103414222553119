import React, { useEffect, useState } from 'react'
// import LoginCrausal from './loginCrasual'
import loginimage from '../../assets/img/loginimg.jpg'

import loginlogo1 from '../../assets/img/avatars/logonew1.png'
import loginlogo from '../../assets/img/avatars/logonew.jpg'

import facebook from '../../assets/img/avatars/facebook.png'
import instagram from '../../assets/img/avatars/instagram.png'
import linkedin from '../../assets/img/avatars/linkedin.png'
import twitter from '../../assets/img/avatars/twitter.png'

import { Button, Checkbox, Link, TextField } from '@mui/material'
import { InputField } from '../../component/controls'
import { home } from '../../../navigation/constant'
import { useNavigate } from 'react-router'
import axios from 'axios'
import { LoginPost } from '../../../services/constant'
import { useDispatch } from 'react-redux'

const UserLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [forgetToggle, setForgetToggle] = useState(false)
    const [loginFormData, setLoginFormData] = useState([]);
    const [ipAddress, setIpAddress] = useState('')
    useEffect(() => {

        const token = localStorage.getItem("authToken");
        if (token) {
            dispatch({ type: "LOADER", payload: true });
            navigate(home)
            dispatch({ type: "LOADER", payload: false });
        }

        axios.get('https://api.ipify.org?format=json')
            .then((response) => {
                console.log(response.data.ip)
                setIpAddress(response.data.ip)
            })
    }, [])
    const handleLoginOnChange = (value, field) => {
        setLoginFormData({
            ...loginFormData,
            [field.id]: value,
            [field.id + 'error']: !value
        })
    }

    const sumbitValidHandler = (obj) => {
        let changes = {}; // Temporary object to accumulate changes
        // Check if any value in the object is undefined
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                changes[key] = value;
                changes[key + 'error'] = !value;
            }
        });
        return changes;
    }

    const handleSubmit = () => {
        dispatch({ type: "LOADER", payload: true });

        const requestData = {
            userName: loginFormData.userName,
            password: loginFormData.password,
            location: 'Noida',
            ipAddress: ipAddress || "N/A",
            deviceType: 1,
            deviceToken: "DEVICETOKEN"

        }


        const requestDataValidator = sumbitValidHandler(requestData)
        if (Object.keys(requestDataValidator).length > 0) {
            setLoginFormData({
                ...loginFormData,
                ...requestDataValidator
            })
            dispatch({ type: "LOADER", payload: false });

        }
        else {
            axios.post(LoginPost(), requestData)
                .then((response) => {
                    dispatch({ type: "LOADER", payload: false });

                    if (response?.data.metaData?.resultCode === 'R400') {
                        console.log(response?.data.metaData?.resultCode)
                        console.log('login response', response?.data?.data?.authToken)
                        console.log('login response', response?.data?.data?.branchList)
                        console.log('login response', response?.data?.data?.financialYearList)
                    }
                    else if (response?.data.metaData?.resultCode === 'R03') {

                    }
                    else if (response?.data.metaData?.resultCode === 'R00') {

                        localStorage.setItem('authToken', response?.data?.data?.authToken)
                        localStorage.setItem('branchList', JSON.stringify(response?.data?.data?.branchList))
                        localStorage.setItem('financialYearList', JSON.stringify(response?.data?.data?.financialYearList))
                        debugger
                        navigate(home)
                        // window.location.href = '/dashboard';

                    }
                    else {

                    }


                })
                .catch((error) => {

                })


        }
    }




    return (
        <>
            <div className="login-body login-body-wrapp cmc--">
                <div className="login-image">
                    <img src={loginimage} alt="" />

                    {/*  <LoginCrausal /> */}
                </div>
                <div className='loginForm'>
                    <div className='loginForm-inner'>
                        <div className="login_logo">
                            <img src={loginlogo1} alt="" className="agastalogo" />
                        </div>
                        {
                            !forgetToggle &&
                            <div className='login-formSec mt-5'>
                                <div className='login-form-wrap'>
                                    <div className='login_field'>
                                        <InputField
                                            field={
                                                {
                                                    id: "userName",
                                                    label: "Email or Username",
                                                    type: 'email',
                                                    value: loginFormData.userName,
                                                    required: true,
                                                    error: loginFormData.userNameerror,
                                                    helperText: 'Email or Username Required',
                                                    onChange: handleLoginOnChange
                                                }
                                            }
                                        />
                                        <InputField
                                            field={
                                                {
                                                    id: "password",
                                                    label: "User Password",
                                                    type: 'password',
                                                    value: loginFormData.password,
                                                    required: true,
                                                    error: loginFormData.passworderror,
                                                    helperText: 'User Password Required',
                                                    onChange: handleLoginOnChange
                                                }
                                            }
                                        />
                                    </div>
                                    <Button className='login-btn mt-3' sx={{ width: '100%', borderRadius: "10px", backgroundColor: "var( --color-main)" }} type='submit' variant="contained" onClick={handleSubmit}>Login</Button>
                                    <div className='remember_me cmc'>
                                        <div className='checked_container '>
                                            <Checkbox /><span>Remember me</span>
                                        </div>
                                        <Link onClick={() => setForgetToggle(!forgetToggle)}>Forgot Password?</Link>
                                    </div>
                                </div>
                            </div>
                        }


                        <div className='forgotPassword-Sec' style={{ display: forgetToggle ? 'block' : 'none' }}>
                            <h2>Forgot Your Password</h2>
                            <div className='login-form-wrap'>
                                <p className="mb16">Having trouble logging in?</p>
                                <div className="message">
                                    <ul>
                                        <li className="mb8">Usernames are in the form of an email address.</li>
                                        <li className="mb8">Passwords are case sensitive.</li>
                                    </ul>
                                </div>
                                <div className='login_field'>
                                    <InputField
                                        field={
                                            {
                                                id: "userName",
                                                label: "Username",
                                                type: 'text',
                                                value: loginFormData.userName,
                                                required: true,
                                                error: loginFormData.userNameerror,
                                                helperText: 'Email or Username Required',
                                                onChange: handleLoginOnChange
                                            }
                                        }
                                    />
                                </div>
                                <div className='forgotButton-wrap cmc'>
                                    <Button className='cancel-loginBtn' sx={{ width: '100%', borderRadius: "4px" }} type='button' onClick={() => setForgetToggle(!forgetToggle)}>Cancel</Button>
                                    <Button className='login-btn' sx={{ width: '100%', borderRadius: "4px", backgroundColor: "var( --color-main)" }} type='button' variant="contained">Continue</Button>
                                </div>

                                <div className='remember_me'>
                                    <div className='forgot-password'>
                                        <Link>Need Help Logging In?</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='followUs-sec'>
                            <h3>Follow Us :</h3>
                            <ul className='followUs-listWrap'>
                                <li><Link><img src={facebook} alt='Facebook' /></Link></li>
                                <li><Link><img src={instagram} alt='instagram' /></Link></li>
                                <li><Link><img src={linkedin} alt='linkedin' /></Link></li>
                                <li><Link><img src={twitter} alt='twitter' /></Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className='login-footer'>
                        © 2024 Agrastha. All rights reserved. | &nbsp;
                        <Link id="privacy-link" target="_blank">Privacy</Link>
                    </div>

                </div>

                {/* <div className="row">
                    <div className=" d-none d-lg-block col-lg-6">
                        <div className="cmd">
                            <div className="login-image">
                                <img src={loginimage} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className='loginForm'>
                            <div className='w-100'>
                                <div className="login_logo">
                                    <img src={loginlogo} alt="" className="agastalogo" />
                                </div>
                                <h2>Sign In</h2>
                                <div className='login_field'>
                                    <InputField
                                        field={
                                            {
                                                id: "userName",
                                                label: "Email or Username",
                                                type: 'email',
                                                value: loginFormData.userName,
                                                required: true,
                                                error: loginFormData.userNameerror,
                                                helperText: 'Email or Username Required',
                                                onChange: handleLoginOnChange
                                            }
                                        }
                                    />
                                    <InputField
                                        field={
                                            {
                                                id: "password",
                                                label: "User Password",
                                                type: 'password',
                                                value: loginFormData.password,
                                                required: true,
                                                error: loginFormData.passworderror,
                                                helperText: 'User Password Required',
                                                onChange: handleLoginOnChange
                                            }
                                        }
                                    />

                                </div>

                                <div className='remember_me cmd'>
                                    <div className='checked_container '>
                                        <Checkbox /><span>Remember me</span>
                                    </div>

                                    <Link>Forget Password</Link>
                                </div>

                                <Button className='mt-5' sx={{ width: '100%', borderRadius: "10px", backgroundColor: "var( --color-main)" }} type='submit' variant="contained" onClick={handleSubmit}>Login</Button>

                            </div>

                        </div>
                    </div>

                </div> */}
            </div>
        </>
    )
}

export default UserLogin
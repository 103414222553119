import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Textarea } from '@mui/joy';
import IosShareIcon from '@mui/icons-material/IosShare';
import RecordingComponent from './field/audiorecorder';

export default function DivisonDrawer({ drawerFormFields, currentColumndata, divisiondrawerbody, divisonValidator, closeValidator, callback, openCallBack, onSelectChange }) {

    const [formData, setFormData] = useState({});

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
        // this function run when we want to run openCallBack function when we open drawer
        openCallBack && (
            openCallBack()
        )
    };

    useEffect(() => {
        if (divisonValidator) {
            // Assuming `toggleDrawer` is a function to open the drawer
            setState({ ...state, ['right']: true });
            callback()
        }
        if (closeValidator) {
            setState({ ...state, ['right']: false });
        }

    }, [divisonValidator, closeValidator]);

    const handleFormChange = (field, value) => {
        setFormData({
            ...formData,
            [field.id]: typeof value === 'string' ? value : value,
        });
    };


    const handleSubmit = () => {
        callback();
    };

    return (
        <>
            <Tooltip title={drawerFormFields?.tooltitle ? drawerFormFields?.tooltitle : 'Add Divison'}>
                <IconButton className={drawerFormFields?.iconclass ? drawerFormFields?.iconclass : 'table-toggle'} onClick={toggleDrawer('right', true)} >
                    {drawerFormFields?.icon ? drawerFormFields?.icon : <AddIcon />}
                </IconButton>
            </Tooltip >

            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >
                <Box
                    sx={{ width: drawerFormFields?.width ? drawerFormFields?.width : 350 }}
                    role="presentation"
                // onKeyDown={toggleDrawer('right', false)}
                >
                    <List>
                        <div className='table-divi-drawer'>

                            <div>
                                <h5>{currentColumndata ? currentColumndata.contactPerson : drawerFormFields.title}</h5>
                                <p style={{ margin: 0, fontSize: '13px' }}>{currentColumndata && currentColumndata.mobileNumber}</p>
                            </div>

                            <div >
                                {
                                    currentColumndata && <Tooltip title='share'>
                                        <IconButton>
                                            <IosShareIcon />
                                        </IconButton>
                                    </Tooltip>
                                }

                                <Tooltip title='close'>
                                    <IconButton onClick={toggleDrawer('right', false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>

                        </div>

                        <Divider />

                        {
                            divisiondrawerbody ?
                                <ListItem>
                                    {divisiondrawerbody({ handleFormChange })}
                                </ListItem> : false
                        }

                        {
                            drawerFormFields?.data && <ListItem ><DynamicForm formData={formData} fields={drawerFormFields.data} onChange={handleFormChange} onSelectChange={onSelectChange} /></ListItem>
                        }

                        {
                            drawerFormFields?.defaultbtnfalse ? false
                                : <ListItem disablePadding sx={{ justifyContent: "center" }}>
                                    <Button sx={{ m: 2, borderRadius: "10px" }} onClick={toggleDrawer('right', false)} variant="outlined">Cancel</Button>
                                    <Button sx={{ m: 2, borderRadius: "10px" }} type='submit' onClick={handleSubmit} variant="contained">Submit</Button>
                                </ListItem>
                        }


                    </List>
                    {/* <Divider /> */}
                </Box>
            </Drawer>
        </>
    );
}







export const DynamicForm = ({ fields, formData, onChange, onSelectChange }) => {

    const handleFieldChange = (field, e) => {
        onChange(field, e);
    };

    return (
        <form className='w-100'>
            <div className="row">
                {fields && fields.map((field, index) => (
                    <div className={`${field.grid ? field.grid : 'col-12'} mt-3`} key={index}>
                        {field.type === 'select' ?
                            <ListItem disablePadding>
                                <FormControl key={index} sx={{ width: '100%', fontSize: '13px' }}
                                    size="small"
                                    error={field.error}
                                >
                                    <InputLabel id={`label-${field.label}`}>{field.label}</InputLabel>
                                    <Select
                                        // className='select'
                                        labelId={`label-${field.label}`}
                                        id={`select-${field.label}`}
                                        name={field.label}
                                        value={formData[field.id] || field.value}
                                        label={field.label}
                                        required={field.required}
                                        onChange={(e) => {

                                            handleFieldChange(field, e.target.value);
                                            if (onSelectChange) {
                                                onSelectChange(e.target.value, field.id)
                                            }

                                        }}

                                    >
                                        {
                                            field.item && field.item.map((item, index) => {
                                                return <MenuItem value={item?.value ? item?.value : item} key={index} >{item?.name ? item?.name : item}</MenuItem>
                                            })
                                        }

                                    </Select>
                                    <FormHelperText>{field.helperText}</FormHelperText>
                                </FormControl>
                            </ListItem>
                            : field.type === 'upload' ?
                                <ListItem disablePadding sx={{ height: "100%", width: "100%" }}>
                                    <label for={field.label} className="attach-btn w-100">
                                        <AttachFileIcon />  {field.label}</label><input type="file" id={field.label} name={field.label} className="d-none" />
                                </ListItem>
                                : field.type === 'textarea' ?
                                    <ListItem disablePadding >
                                        <div className="w-100">
                                            <p style={{ color: "gray", fontSize: '13px', marginBottom: "0" }}>Special Requirements/ Remarks</p>
                                            <Textarea minRows={3} onChange={(e) => { handleFieldChange(field, e.target.value) }} />
                                        </div>

                                    </ListItem>
                                    : field.type === 'audio' ?
                                        <ListItem disablePadding >
                                            <div className="w-100">
                                                <p style={{ color: "gray", fontSize: '13px', marginBottom: "0" }}>{field.label}</p>
                                                <RecordingComponent />
                                            </div>

                                        </ListItem>

                                        : <ListItem disablePadding>
                                            <TextField fullWidth label={field.label} id="fullWidth"
                                                sx={{ width: '100%', fontSize: '13px' }} size="small"
                                                onChange={(e) => handleFieldChange(field, e.target.value)}
                                                // value={field.value}
                                                value={formData[field.id] || field.value}
                                                error={field.error}
                                                helperText={field.helperText}
                                                required={field.required}
                                            />
                                        </ListItem>
                        }
                    </div>
                ))}
            </div>
        </form >
    );
};


import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetMasterSectorByPage, GetTDSMasterByPage, UpdateTDSMasterStatus, PostMasterSector, UpdateMasterSector, UpdateMasterSectorStatus } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';

import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createtdsmaster } from '../../../navigation/constant';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { SmallSwitch } from '../../component/controls';
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in vendorFormthe Authorization header
};

const TdsMaster = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sectorMasterData, setSectorMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetTDSMasterByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }
    useEffect(() => {
        fatch_MasterDataBYPagination();
        sessionStorage.removeItem('tds');
    }, []);

    const setTableDataHandler = ({ response }) => {
        console.log(response.data)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            tdsSection: res.tdsSection,
            name: res.name,
            paymentCode: res.paymentCode,
            branchId:res.branchId,
            valueLimitForSingleInvoice: res.valueLimitForSingleInvoice,
            totalThresholdLimit: res.totalThresholdLimit,
            status: res.status,
            isTransportalCategory: res.isTransportalCategory == true ? 'Yes' : 'No',
            bizInstituteTypeWithPan: res.bizInstituteTypeWithPan,
            bizInstituteTypeWithoutPan: res.bizInstituteTypeWithoutPan,
            rateForOtherDeductiesWithPan: res.rateForOtherDeductiesWithPan,
            rateForOtherDeductiesWithoutPan: res.rateForOtherDeductiesWithoutPan
        }));
        setSectorMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'tdsSection',
            label: 'TDS Section',
            numeric: false,
        },
        {
            id: 'name',
            label: 'Name',
            numeric: false,
        },
        {
            id: 'paymentCode',
            label: 'Payment Code',
            numeric: false,
        },
        {
            id: 'valueLimitForSingleInvoice',
            label: 'Limit for single inv.',
            numeric: false,
        },
        {
            id: 'totalThresholdLimit',
            label: 'Threshold / Exemption',
            numeric: false,
        },


        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];

    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const updatedStatus = !row?.status;
            const data = {

                id: row?.id,
                status: updatedStatus
            }
            axios.get(UpdateTDSMasterStatus(data), { headers })
                .then((response) => {
                    fatch_MasterDataBYPagination()
                })
        }
        const editBtnClicked = () => {
            sessionStorage.setItem('tds', JSON.stringify(row));
            navigate(createtdsmaster);
        }
        return <>
            <Tooltip title="Update TDS Master">
                <IconButton
                    className='tablebtn1'
                    onClick={editBtnClicked}
                >
                    <i className="las la-edit"></i>
                </IconButton>
            </Tooltip >

            <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>

    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <Tooltip title="Add TDS Master">
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${createtdsmaster}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }

    return (
        <>
            {/* filterItems={filterItems} */}
            <MultiTable
                title={'TDS Master'}
                columns={columns}
                data={sectorMasterData}
                // drawerFormFields={drawerFormFields}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default TdsMaster;



import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { GetMasterAccountLedgerByPage, GetMasterSubGroupByPage, PostMasterAccountGroup, PostMasterAccountLedger, PostMasterSubGroup, UpdateMasterAccountLedger, UpdateMasterAccountLedgerStatus, } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch, useSelector } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { columnConstant } from '../../constant/formconstant';
import { SmallSwitch } from '../../component/controls';


const AccountLedger = () => {
    const dispatch = useDispatch();
    const [accountLedgerMasterData, setAccountLedgerMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const [subgroupItem, setSubgroupItem] = useState([])


    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterAccountLedgerByPage,
            callback: setTableDataHandler,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item,
        })
    }

    const set_SubgroupItemData = () => {

        GetCaller({
            API: PostMasterSubGroup,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {

                const newSubgroupItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                    primaryGroup: item.primaryGroup,
                    accountGroup: item.accountGroup,
                

                }))
                setSubgroupItem(newSubgroupItemData)
            }
        })

    }



    useEffect(() => {
        fatch_MasterDataBYPagination()
        set_SubgroupItemData()
    }, []);


    const setTableDataHandler = ({ response }) => {

        const newmasterdata = response?.data?.data?.records.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            accountledger: res.name,
            subgroup: res.subGroups.SubGroupName,
            subgroupId: res.subGroups.SubGroupId,
           primarygroup : res.primaryGroupName,
            status: res.status
        }));
        debugger
        setAccountLedgerMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: columnConstant.sno,
            numeric: false,
        },

        {
            id: 'accountledger',
            label: columnConstant.accountledger,
            numeric: false,

        },

        {
            id: 'subgroup',
            label: columnConstant.subgroup,
            numeric: false,
        },

        {
            id: 'primarygroup',
            label: columnConstant.primarygroup,
            numeric: false,
        },
        {
            id: 'action',
            label: columnConstant.action,
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateMasterAccountLedgerStatus,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({ response }) => {
                    fatch_MasterDataBYPagination();
                }
            });
        }

        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} subgroupItem={subgroupItem} />
            <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <DesignationDrawer subgroupItem={subgroupItem} callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>
            <MultiTable
                data={accountLedgerMasterData}
                columns={columns}
                // filterItems={filterItems}
                title={columnConstant.accountledger}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default AccountLedger




const DesignationDrawer = ({ callAPI, editor, row, subgroupItem }) => {
    const dispatch = useDispatch()

    const enquirybranch = useSelector(state => state.enquirybranch)
    const [closeValidator, setcloseValidator] = useState(false)

    const [primaryGroup, setPrimaryGroup] = useState('');
    const [accountGroup, setAccountGroup] = useState('');

    const [accountLedger, setAccountLedger] = useState(row?.accountledger || '')
    const [accountLedgerError, setAccountLedgerError] = useState(false)

    const [subGroup, setSubGroup] = useState(row?.subgroup || '')
    const [subGroupError, setsubGroupError] = useState(false)

    const drawerOpenCallbackHandler = () => {
        setAccountLedger(row?.accountledger || '');
        setSubGroup(row?.subgroupId || '');
        setAccountLedgerError(false);
        setsubGroupError(false);

        if (row) {
            let rowData = subgroupItem.filter((res => res.value === row?.subgroupId))
            setPrimaryGroup(rowData[0]?.primaryGroup?.value);
            setAccountGroup(rowData[0]?.accountGroup?.value);
        } else {
            setPrimaryGroup('');
            setAccountGroup('');
        }

    }



    const handleSubmit = () => {
        if (!accountLedger || !subGroup) {
            setAccountLedgerError(!accountLedger)

            setsubGroupError(!subGroup)
        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: accountLedger,
                subgroupId: subGroup,
                branchId: enquirybranch,
                status: row.status
            } : {
                name: accountLedger,
                subgroupId: subGroup,
                branchId: enquirybranch,
            }
            editor ?
                PostCaller({
                    API: UpdateMasterAccountLedger,
                    callback: () => callAPI(),
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: postmasterareaData
                })

                :
                PostCaller({
                    API: PostMasterAccountLedger,
                    callback: () => callAPI(),
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }


    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);

    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        debugger
        switch (field.id) {
            case 'accountledger':
                setAccountLedger(value)
                setAccountLedgerError(!value);
                break;
            case 'subgroup':
                setSubGroup(value)
                setsubGroupError(!value);
                setSubGroup(value)

                subgroupItem.map((res => {
                    if (res.value === value) {
                        setPrimaryGroup(res.primaryGroup.value);
                        setAccountGroup(res.accountGroup

                            .value);
                    }
                }))



                break;
            default:
                break;
        }

    }




    const drawerFormFields = editor ?
        {
            title: columnConstant.updateaccountledger,
            icon: <i className="las la-edit"></i>,
            tooltitle: columnConstant.updateaccountledger,
            iconclass: 'noClass',
            defaultbtnfalse: true
        }
        : {
            title: columnConstant.addaccountledger,
            tooltitle: columnConstant.addaccountledger,
            defaultbtnfalse: true
        }


    const FieldData = [

        {
            id: 'accountledger',
            label: columnConstant.accountledger,
            type: 'text',
            value: accountLedger,
            required: true,
            error: accountLedgerError,
            onChange: handleInputChange,

        },
        {
            id: 'subgroup',
            label: columnConstant.subgroup,
            type: 'select',
            value: subGroup,
            required: true,
            item: subgroupItem,
            error: subGroupError,
            onChange: handleInputChange,

        },
        {
            id: 'primary',
            label: columnConstant.primarygroup,
            type: '',
            required: true,
            value: primaryGroup,
            error: subGroupError,
            disable: true,
            onChange: handleInputChange,

        },
        {
            id: 'account',
            label: columnConstant.accountgroup,
            type: 'text',
            required: true,
            value: accountGroup,
            error: subGroupError,
            disable: true,
            onChange: handleInputChange,

        },
  
    ]

    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }

    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}


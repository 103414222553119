import React, { useEffect, useState } from 'react'
import MapMenu, { MultiTable } from '../component/multiTable';
import { Avatar, IconButton, Switch, Tooltip } from '@mui/material';
import AlertDialogSlide from '../component/snackbar/AlertDialogSlide';
import CircularWithValueLabel from '../component/field/circleProgressBar';
import { useNavigate } from 'react-router-dom';
import { hremployelistForm } from '../../navigation/constant';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import MasterCallBypagination from '../../services/masterpaginationConfig';
import { GetEmployeeByPage, UpdateEmployeeStatus } from '../../services/constant';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import EmployeeDetails from '../component/employeeDetails';
import { SmallSwitch } from '../component/controls';

const HrEmployelist = () => {
    const dispatch = useDispatch();
    const [EmployeeListData, setEmployeeListData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const [employeeDetailShow, setEmployeeDetailShow] = useState(false)
    const authToken = localStorage.getItem('authToken')
    const headers = {
        Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
    };
    const navigate = useNavigate();

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetEmployeeByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);




    const setTableDataHandler = ({ response }) => {
        console.log('response', response)

        const newmasterdata = response?.data?.data?.records && response?.data?.data?.records.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            image: res.image,
            profile: <Avatar alt="Remy Sharp" src={res.image} />,
            empid: res.empId,
            name: res.name,
            email: res.email,
            mobile: res.mobile,
            designation: res.designation,
            department: res.department,
            empstatus: res.employmentStatus,
            status: res.status,
            // action: res.status,
            // referredBy: res.referredBy,
            // shiftTiming: res.shiftTiming,
            // tradeExperience: res.tradeExperience,
            // fatherOrHusbandName: res.fatherOrHusbandName,
            // motherName: res.motherName,
            // gender: res.gender,
            // dateOfBirth: res.dateOfBirth,
            // idateOfJoining: res.idateOfJoining,
            // dateOfAnneversary: res.dateOfAnneversary,
            // mobileNumber: res.mobileNumber,
            // officialEmail: res.officialEmail,
            // personalEmail: res.personalEmail,
            // adharNumber: res.adharNumber,
            // panNumber: res.panNumber,
            // meritalStatus: res.meritalStatus,
            // bloodGroup: res.bloodGroup,
            // extensionNumber: res.extensionNumber,
            // landlineNumber: res.landlineNumber,
            // noticePeriod: res.noticePeriod,
            // image: res.image,
            // branch: res.branch?.value,
            // branchid: res.branch?.key,
            // division: res.division?.value,
            // divisionid: res.division?.key,
            // department: res.department?.value,
            // departmentid: res.department?.key,
            // subDepartment: res.subDepartment?.value,
            // subDepartmentid: res.subDepartment?.key,
            // designation: res.designationId,
            // designationid: res.designation?.key,


        }));
        setEmployeeListData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
        {
            id: 'profile',
            label: 'Image',
            numeric: false,
        },
        {
            id: 'empid',
            label: 'EMP.Code',
            numeric: false,
        },
        {
            id: 'name',
            label: 'Name',
            numeric: false,
        },
        {
            id: 'email',
            label: 'Email',
            numeric: false,
        },
        {
            id: 'mobile',
            label: 'Mobile',
            numeric: false,
        },
        {
            id: 'designation',
            label: 'Designation',
            numeric: false,
        },
        {
            id: 'department',
            label: 'Department',
            numeric: false,
        },
        {
            id: 'empstatus',
            label: 'Status',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];

    const sampleData = [
        {
            id: 1,
            profile: <Avatar alt="Remy Sharp" src="https://virtualcoderz.com/logistic/tasks/img/1.png" />,
            empid: 121,
            name: 'john singh',
            email: 'global123@skybarge.com',
            mobileNumber: 7898999778,
            designation: 'Director',
            department: 'Management',
            empstatus: 'Confirmed',
        },

        // Add more objects for additional rows
    ];
    const filterItems = [
        { id: 1, label: 'EMP ID' },
        { id: 2, label: 'Name' },
        { id: 3, label: 'Profile' },
        { id: 4, label: 'Department' },
        { id: 5, label: 'Designation' },
        { id: 6, label: 'Employee Status' },
        { id: 7, label: 'Email' },
        { id: 8, label: 'Mobile' },
        { id: 9, label: 'Action' },
        // Add more custom menu items as needed
    ];

    const drawerFormFields = {
        title: "Add Divison",
        data: [
            {
                label: 'Branch*',
                type: "select",
                item: [
                    'Noida',
                    'Gurgaon',
                    'Uttarpradesh'
                ]
            },
            {
                label: 'Division*',
                type: "input"
            },
            {
                label: 'Address*',
                type: "input"
            }
        ]

    }

    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
         
            const updatedStatus = !row?.status;
            const data = {
                id: row?.id
            }
            axios.get(UpdateEmployeeStatus(data), { headers })
                .then((response) => {
                    fatch_MasterDataBYPagination()
                })
                setEmployeeDetailShow(false);
        }

        return <>
      

           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>
    }
    const TableTool = ({ filterCallback }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        return <>
            {
                columns ? <Tooltip title="Filter">
                    <IconButton
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        aria-controls={anchorEl ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? 'true' : undefined}
                    >
                        <FilterListIcon />
                    </IconButton>
                </Tooltip> : false
            }
            <MapMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuItems={columns} filterCallback={filterCallback} />

            <Tooltip title="Add Employee Details">
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${hremployelistForm}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >
        </>
    }

    return (
        <>
            <div className='d-flex w-100'>
                <div style={{ width: employeeDetailShow ? "40%" : "100%" }}>
                    <MultiTable
                        data={EmployeeListData}
                        columns={columns}
                        totaldatalength={totaldatalength}
                        filterItems={filterItems}
                        drawerFormFields={drawerFormFields}
                        title={'Employee List'}
                        tabletool={TableTool}
                        actioncell={ActionCell}
                        paginationhandler={fatch_MasterDataBYPagination}
                        rowCallBack={(row) => setEmployeeDetailShow(row)}
                    /> 
                </div>
                <div className={`${employeeDetailShow  ? "showemployeeDetail" : "hideemployeeDetail"}`}>
                    <EmployeeDetails row={employeeDetailShow} closeCallback={() => setEmployeeDetailShow(false)} />
                </div>
            </div>


        </>
    )
}

export default HrEmployelist
import { Button, Checkbox } from "@mui/material";
import { useState } from "react";
import Dropdown, { AutoSelect, DateField, InputField } from "../component/controls";
import { Add, CheckBox, Close } from "@mui/icons-material";

import {
  GetMasterCityByPage,
  GetMasterCountryByPage,
  GetMasterBizInstituteTypeByPage,
  GetMasterAccountLedgerByPage,
  GetTDSMasterByPage,
  GetMasterStateByPage,
  GetGSAMasterByPage,
  PostAirlineMaster,
  UpdateAirlineMaster,
  PostGSTStatus,
  PostMasterCountry,
  PostMasterState,
  PostMasterCity,
  PostGSAMaster,
  GetAirlineMasterDetailsById

} from "../../services/constant";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { applicableFormat } from "../../services/modalData";
import GetCaller from "../../services/apiServices";
import { columnConstant } from "../constant/formconstant";
import formValueReseter from "../../services/utilities/formValueReseter";
import CheckMail from "../../services/utilities/checkEmailFormat";
const authToken = localStorage.getItem("authToken");
const headers = {
  Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};
export const AirlineForm = ({ onChange }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [gsaDetailsError, setGSADetailsError] = useState({})

  const [stateData, setstateData] = useState([])
  const [cityData, setcityData] = useState([])

  const [countryItem, setCountryItem] = useState([]);
  const [stateItem, setStateItem] = useState([]);
  const [cityItem, setCityItem] = useState([]);
  const [bizInstituteTypeItem, setBizInstituteTypeItem] = useState([]);
  const [accountLedgerItem, setAccountLedgerItem] = useState([])
  const [gstStatusItem, setGSTStatusItem] = useState([]);
  const [tdsItem, setTDSItem] = useState([]);
  const [gstCode, setGstCode] = useState("");

  const [isTagWithGSA, setIsTagWithGSA] = useState(false);
  const [isExportCarrier, setisExportCarrier] = useState(false);



  const set_CityItemData = () => {
    GetCaller({
      API: PostMasterCity
      ,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        debugger
        const newCityItemData = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.name,
          gstCode: item.gstCode
        }))
        setCityItem(newCityItemData)
      }

    })

  }




  const set_BizInstituteItemData = (e) => {
    const requestData = {
      searchKey: "",
      sortKey: "",
      sortDirection: "",
      itemsPerPage: 100,
      page: 1,
    };
    axios
      .get(GetMasterBizInstituteTypeByPage(requestData), { headers })
      .then((response) => {
        // console.log(e)

        const newVendorItemData = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.name,
        }));


        setBizInstituteTypeItem(newVendorItemData);
      });
  };

  const set_AccountLedgerItemData = (e) => {
    const requestData = {
      searchKey: "",
      sortKey: "",
      sortDirection: "",
      itemsPerPage: 100,
      page: 1,
    };
    axios
      .get(GetMasterAccountLedgerByPage(requestData), { headers })
      .then((response) => {
        // console.log(e)

        const newVendorItemData = response?.data?.data?.records.map((item) => ({
          value: item.id,
          name: item.name,
        }));

        setAccountLedgerItem(newVendorItemData);
      });
  };

  const set_TDSItemData = (e) => {
    const requestData = {
      searchKey: "",
      sortKey: "",
      sortDirection: "",
      itemsPerPage: 100,
      page: 1,
    };
    axios.get(GetTDSMasterByPage(requestData), { headers }).then((response) => {
      // console.log(e)

      const newVendorItemData = response?.data?.data?.records.map((item) => ({
        value: item.id,
        name: item.name,
      }));

      setTDSItem(newVendorItemData);
    });
  };

  const set_GstStatus = (e) => {
    GetCaller({
      API: PostGSTStatus,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: {},
      callback: ({ response }) => {
        const item = []
        response?.data?.data?.records?.map(res => {
          item.push({
            value: res.id,
            name: res.gstStatus
          })
        })
        setGSTStatusItem(item)
      }

    });
  };

  useEffect(() => {
    set_CityItemData();
    set_GstStatus();
    set_BizInstituteItemData();
    set_AccountLedgerItemData();
    set_TDSItemData();

  }, [])

  const [airlineForm, setAirlineForm] = useState({
    id: "",
    bizInstituteTypeId: "",
    otherBizInstitute: "",
    nameOnCheque: "",
    isPanAvailable: "",
    isTDSApplicable: "",
    code: "",
    legalName: "",
    accountLedgerId: "",
    awbStockReorderLevel: "",
    airlinePrefix: "",
    address1: "",
    address2: "",
    address3: "",
    address4: "",
    cityId: "",
    pinCode: "",
    email: "",
    contactPerson: "",
    telephone: "",
    mobileNumber: "",
    gstStatus: "",
    gstNumber: "",
    cin: "",
    gsaaddress1: "",
    gsaaddress2: "",
    gsaaddress3: "",
    gsaaddress4: "",
    gsaaddress1: "",
    gsaState: "",
    gsagstNo: "",
    panNumber: "",
    tdsId: "",
    gsaTanNo: "",
    airlineGSAs: [
      {
        gsaId: "",
        gsaEffectiveDate: "",
        gsaEndDate: '',
      }
    ]
  })

  const requestdata = (() => {
    const data = {
      id: airlineForm.id,
      bizInstituteTypeId: airlineForm.bizInstituteTypeId,
      code: airlineForm.code,
      legalName: airlineForm.legalName,
      airlinePrefix: airlineForm.airlinePrefix,
      tagWithGSA: isTagWithGSA,
      createAsExportCarrier: isExportCarrier,
      airlineGSAs: isTagWithGSA ? airlineForm.airlineGSAs : []
    }



    if (isExportCarrier) data.nameOnCheque = airlineForm.nameOnCheque
    if (isExportCarrier) data.isPanAvailable = airlineForm.isPanAvailable
    if (isExportCarrier) data.isTDSApplicable = airlineForm.isTDSApplicable
    if (isExportCarrier) data.accountLedgerId = airlineForm.accountLedgerId
    if (isExportCarrier) data.awbStockReorderLevel = airlineForm.awbStockReorderLevel
    if (isExportCarrier) data.address1 = airlineForm.address1
    if (isExportCarrier) data.cityId = airlineForm.cityId
    if (isExportCarrier) data.pinCode = airlineForm.pinCode
    if (isExportCarrier) data.email = airlineForm.email
    if (isExportCarrier) data.contactPerson = airlineForm.contactPerson
    if (isExportCarrier) data.telephone = airlineForm.telephone
    if (isExportCarrier) data.mobileNumber = airlineForm.mobileNumber
    if (isExportCarrier) data.gstStatus = airlineForm.gstStatus
    if (isExportCarrier) data.gstNumber = airlineForm.gstNumber
    if (isExportCarrier) data.cin = airlineForm.cin



    if (isExportCarrier && airlineForm.address2) data.address2 = airlineForm.address2
    if (isExportCarrier && airlineForm.address3) data.address3 = airlineForm.address3
    if (isExportCarrier && airlineForm.gstStatus === 2) data.gstNumber = "Unregistered"
    if (isExportCarrier && airlineForm.isTDSApplicable === 1) data.tdsId = airlineForm.tdsId
    if (isExportCarrier && airlineForm.isPanAvailable === 1) data.panNumber = airlineForm.panNumber
    if (isExportCarrier && bizInstituteTypeItem && bizInstituteTypeItem[airlineForm?.bizInstituteTypeId - 1]?.name.toLowerCase() === 'other') data.otherBizInstitute = airlineForm.otherBizInstitute
    return data;
  })();

  const handleInputChange = (value, field, resetField) => {
    if (field.id === 'cityId') {
      set_CityItemData(value);

      cityItem?.map((res) => {
        if (res.value === value) {

          setGstCode(res.gstCode)
        }
      })
    }

    setAirlineForm({
      ...airlineForm,
      ...resetField,
      [field.id]: value,
      [field.id + "error"]: !value,
    });
  };

  const handleFormSubmit = () => {

    const hasUndefinedValue = (obj) => {
      let changes = {};
      Object.entries(obj).forEach(([key, value]) => {
        if (key === "id" ||
          key === 'tagWithGSA' ||
          key === 'createAsExportCarrier' ||
          (bizInstituteTypeItem[airlineForm.bizInstituteTypeId - 1]?.name.toLowerCase() !== 'other' && key === 'otherBizInstitute')
        ) { }
        else if (!value) {
          changes[key] = value;
          changes[key + "error"] = !value;
        }
      });
      console.log('Request Data Airline', requestdata)
      console.log('Request Data Airline changes', changes)

      return changes;
    };

    const gsaDetailshasUndefinedValue = (array) => {
      let changes = {};
      debugger
      array.map((obj, index) => {
        Object.entries(obj).forEach(([key, value]) => {
          if (!value && (key === 'gsaId' || key === "gsaEffectiveDate" || key === 'gsaEndDate')) {
            changes[key + index] = !value;
          }
        });
      })

      return changes;
    };

    const changes = hasUndefinedValue(requestdata);
    const gsaDetailsChanges = isTagWithGSA ? gsaDetailshasUndefinedValue(requestdata.airlineGSAs) : {};

    const isMail = CheckMail(requestdata?.email)


    if (Object.keys(changes).length > 0 || (isMail && isExportCarrier) ||
      Object.keys(gsaDetailsChanges).length > 0
    ) {
      setAirlineForm({
        ...airlineForm, ...changes,
        ['emailerror']: isMail && isExportCarrier
      });
      setGSADetailsError(gsaDetailsChanges)
    }
    else if (isEdit) {

      EditAreaMasterSubmit(requestdata);

    }
    else {
      AddAreaMasterSubmit(requestdata)
    }
  };



  const EditAreaMasterSubmit = (requestdata) => {
    requestdata.isPanAvailable = requestdata.isPanAvailable === 2 ? false : true;
    requestdata.isTDSApplicable = requestdata.isTDSApplicable === 2 ? false : true;

    axios.post(UpdateAirlineMaster(), requestdata, { headers })
      .then((response) => {
        const friendlymess = response?.data?.metaData?.friendlyMessage;
        const resultmess = response?.data?.metaData?.resultMessage
        if (response?.data?.metaData?.resultCode === "R00") {
          navigate("/airline");
          dispatch({
            type: "SNACKBARALERT", payload: {
              active: true,
              message: friendlymess || resultmess,
              type: 'success'
            }
          });
        }
        else {
          dispatch({
            type: "SNACKBARALERT", payload: {
              active: true,
              message: friendlymess || resultmess,
              type: 'error'
            }
          });
        }
      })
  }
  const AddAreaMasterSubmit = (requestdata) => {

    requestdata.isPanAvailable = requestdata.isPanAvailable === 2 ? false : true;
    requestdata.isTDSApplicable = requestdata.isTDSApplicable === 2 ? false : true;

    axios.post(PostAirlineMaster(), requestdata, { headers }).then((response) => {
      const friendlymess = response?.data?.metaData?.friendlyMessage;
      const resultmess = response?.data?.metaData?.resultMessage;

      if (response?.data?.metaData?.resultCode === "R00") {
        navigate("/airline");
        dispatch({
          type: "SNACKBARALERT",
          payload: {
            active: true,
            message: friendlymess || resultmess,
            type: "success",
          },
        });
      } else {
        dispatch({
          type: "SNACKBARALERT",
          payload: {
            active: true,
            message: friendlymess || resultmess,
            type: "error",
          },
        });
      }
    });
  };


  useEffect(() => {
    let airline = JSON.parse(sessionStorage.getItem("airline"));
    if (airline) {
      set_CityItemData();
      //details data
      GetCaller({
        API: GetAirlineMasterDetailsById,
        dispatch: (e) => {
          dispatch({ type: e.type, payload: e.payload })
        },
        item: { id: airline?.id },

        callback: ({ response }) => {

          console.log('idbased data', response?.data?.data)
          let airline = response?.data?.data
          airline.isPanAvailable = airline.isPanAvailable === false ? 2 : 1;
          airline.isTDSApplicable = airline.isTDSApplicable === false ? 2 : 1;
          airline.gstNumber = airline.gstStatus === 2 ? '' : airline.gstNumber

          setIsTagWithGSA(airline.tagWithGSA)
          setisExportCarrier(airline.createAsExportCarrier)
          setAirlineForm(airline);
          setIsEdit(true);
        }
      });

    }
    else {

    }

  }, []);




  function DuplicateInputDocs() {
    const dispatch = useDispatch();
    const [gsaItem, setGSAItem] = useState([]);

    const set_GSAItemData = () => {
      GetCaller({
        API: PostGSAMaster,
        dispatch: (e) => {
          dispatch({ type: e.type, payload: e.payload })
        },
        item: {},
        callback: ({ response }) => {
          const newGSAItemData = response?.data?.data?.records.map((item) => ({
            value: item.id,
            name: item.legalName,
            address1: item.address1,
            address2: item.address2,
            address3: item.address3,
            panNumber: item.panNumber,
            tanNumber: item.tanNumber,
            gstNumber: item.gstNumber,
            countryId: item.country.value,
            stateId: item.state.value,
            cityId: item.city.value,
            gstCode: item.gstCode,
          }))

          setGSAItem(newGSAItemData)
        }
      });

    }

    const generateUniqueKey = () => {
      return Math.random().toString(36).substr(2, 9);
    };

    useEffect(() => {
      set_GSAItemData()
      if (sessionStorage.airline) {
        const airline = JSON.parse(sessionStorage.getItem("airline"));

        GetCaller({
          API: GetAirlineMasterDetailsById,
          dispatch: (e) => {
            dispatch({ type: e.type, payload: e.payload })
          },
          item: { id: airline?.id },

          callback: ({ response }) => {


            console.log('idbased data', response?.data?.data)
            let airline = response?.data?.data


            // setGSAItem(airline.airlineGSAs);

            let updatedDetailsData = []
            airline.airlineGSAs?.map((item) => {
              updatedDetailsData.push({
                key: generateUniqueKey(),
                id: item.id || "N/A",
                gsaId: item.gsaId || "N/A",
                gsaEffectiveDate: item.gsaEffectiveDate || "N/A",
                gsaEndDate: item.gsaEndDate || "N/A",
                address1: item.address1 || "N/A",
                address2: item.address2 || "N/A",
                address3: item.address3 || "N/A",
                panNumber: item.panNumber || "N/A",
                tanNumber: item.tanNumber || "N/A",
                gstNumber: item.gstNumber || "N/A",
                countryId: item.country.value || "N/A",
                stateId: item.state.value || "N/A",
                cityId: item.city.value || "N/A",
                gstCode: item.gstCode || "N/A",
              })
            })
            if (updatedDetailsData && updatedDetailsData?.length !== 0) {
              setInputFields(updatedDetailsData)
            }



          }
        });


      }
    }, [])

    const [inputFields, setInputFields] = useState([
      {
        key: generateUniqueKey(), // Add a unique key for each input field
        gsaId: '',
        gsaEffectiveDate: '',
        gsaEndDate: "",
        address1: "",
        address2: "",
        address3: "",
        panNumber: "",
        tanNumber: "",
        gstNumber: "",
        countryId: "",
        stateId: "",
        cityId: "",
        gstCode: ""
      },
    ]);

    const addInputField = () => {
      setInputFields([...inputFields, {
        key: generateUniqueKey(),
        gsaId: '',
        gsaEffectiveDate: '',
        gsaEndDate: "",
        address1: "",
        address2: "",
        address3: "",
        panNumber: "",
        tanNumber: "",
        gstNumber: "",
        countryId: "",
        stateId: "",
        cityId: "",
        gstCode: ""
      }]);
    };

    const handleDuplicateInputChange = (key, field, value) => {

      const updatedInputFields = [...inputFields];
      const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
      if (fieldToUpdate) {
        if (field === 'gsaId') {
          debugger
          fieldToUpdate[field] = value;
          fieldToUpdate['address1'] = gsaItem[value - 1]?.address1 || "N/A";
          fieldToUpdate['address2'] = gsaItem[value - 1]?.address2 || "N/A";
          fieldToUpdate['address3'] = gsaItem[value - 1]?.address3 || "N/A";
          fieldToUpdate['panNumber'] = gsaItem[value - 1]?.panNumber || "N/A";
          fieldToUpdate['tanNumber'] = gsaItem[value - 1]?.tanNumber || "N/A";
          fieldToUpdate['gstNumber'] = gsaItem[value - 1]?.gstNumber || "N/A";
          fieldToUpdate['countryId'] = gsaItem[value - 1]?.countryId || "N/A";
          fieldToUpdate['stateId'] = gsaItem[value - 1]?.stateId || "N/A";
          fieldToUpdate['cityId'] = gsaItem[value - 1]?.cityId || "N/A";
          fieldToUpdate['gstCode'] = gsaItem[value - 1]?.gstCode || "N/A";
        } else {
          fieldToUpdate[field] = value;
        }
        setInputFields(updatedInputFields);

        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
          return data.push(
            {
              id: res.id,
              gsaId: res.gsaId,
              gsaEffectiveDate: res.gsaEffectiveDate,
              gsaEndDate: res.gsaEndDate
            }
          )
        })

        handleInputChange(data, { id: 'airlineGSAs' });

      }
    };

    const removeInputField = (key) => {

      const updatedInputFields = inputFields.filter((field) => field.key !== key);
      setInputFields(updatedInputFields);
      const data = []
      updatedInputFields && updatedInputFields.map((res) => {
        return data.push(
          {
            id: res.id,
            gsaId: res.gsaId,
            gsaEffectiveDate: res.gsaEffectiveDate,
            gsaEndDate: res.gsaEndDate
          }
        )

      })
      handleInputChange(data, { id: 'airlineGSAs' });
    };

    return (
      <>
        {
          isTagWithGSA &&
          <div className='hr-form-box'>

            <div className="cmd">
              <h6 className="m-0 p-0">GSA Details</h6>
              <Button sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                <Add />
              </Button>
            </div>

            {inputFields.map((field, key) => (
              <div className='row' key={field.key}>
                <div className="col-md-11">
                  <div className="row my-2">
                    <div className="col-md-3">
                      <Dropdown
                        field={
                          {
                            id: 'gsaId',
                            label: 'GSA ',
                            type: "select",
                            value: field.gsaId,
                            required: true,
                            error: !field.gsaId && gsaDetailsError['gsaId' + key],
                            item: gsaItem,
                            onChange: (value) => {
                              handleDuplicateInputChange(field.key, 'gsaId', value);
                            }
                          }
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <DateField
                        format='YYYY-MM-DD'
                        field={{
                          id: "gsaEffectiveDate",
                          label: "Effective Date ",
                          value: field?.gsaEffectiveDate,
                          required: true,
                          error: !field?.gsaEffectiveDate && gsaDetailsError['gsaEffectiveDate' + key],
                          onChange: (value, fielditem) => { handleDuplicateInputChange(field.key, fielditem?.id, value) }
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <DateField
                        format='YYYY-MM-DD'
                        field={{
                          id: "gsaEndDate",
                          label: "End Date ",
                          value: field?.gsaEndDate,
                          required: true,
                          error: !field?.gsaEndDate && gsaDetailsError['gsaEndDate' + key],
                          onChange: (value, fielditem) => { handleDuplicateInputChange(field.key, fielditem?.id, value) }
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <InputField
                        field={
                          {
                            id: 'address1',
                            label: 'Address1',
                            type: "text",
                            value: field.address1,
                            required: false,
                            error: false,
                            disable: true,
                            onChange: (value) => {
                              handleInputChange(field.key, 'address1', value);
                            }
                          }
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <InputField
                        field={
                          {
                            id: 'address2',
                            label: 'Address 2',
                            type: "text",
                            value: field.address2,
                            required: false,
                            error: false,
                            disable: true,
                            onChange: (value) => {
                              handleInputChange(field.key, 'address2', value);
                            }
                          }
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <InputField
                        field={
                          {

                            id: 'address3',
                            label: 'Address 3',
                            type: "text",
                            value: field.address3,
                            required: false,
                            disable: true,
                            error: false,
                            onChange: (value) => {
                              handleInputChange(field.key, 'address3', value);
                            }
                          }
                        }
                      />
                    </div>

                    <div className="col-md-3">
                      <InputField
                        field={
                          {
                            id: 'cityId',
                            label: 'City',
                            type: "text",
                            value: field.cityId,
                            required: false,
                            error: false,
                            disable: true,
                            onChange: (value) => {
                              handleInputChange(field.key, 'cityId', value);
                            }
                          }
                        }
                      />
                    </div>
                    <div className="col-md-3 ">
                      <div className="d-flex gstWrapper">

                        <InputField
                          field={
                            {
                              id: 'gstCode',
                              label: 'Gst Code',
                              value: field.gstCode,
                              required: false,
                              disable: true,
                              error: false,
                            }
                          }
                        />


                        <InputField
                          field={
                            {
                              id: 'gstNumber',
                              label: 'Gst No',
                              value: field.gstNumber,
                              required: false,
                              disable: true,
                              error: '',
                              // onChange: handleBasicInputChange
                            }
                          }
                        />

                      </div>
                    </div>
                    <div className="col-md-3">
                      <InputField
                        field={
                          {
                            id: 'panNumber',
                            label: 'PAN No',
                            type: 'text',
                            value: field.panNumber,
                            required: false,
                            disable: true,

                          }
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <InputField
                        field={
                          {
                            id: 'tanNumber',
                            label: 'TAN No',
                            type: 'text',
                            value: field.tanNumber,
                            required: false,
                            error: false,
                            disable: true,
                          }
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-1 cmd" style={{ justifyContent: "right" }}>
                  {inputFields.length > 1 && (
                    <Button
                      sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                      onClick={() => removeInputField(field.key)}
                      variant='contained'
                    >
                      <Close />
                    </Button>
                  )}
                </div>
              </div>
            ))}

          </div>
        }

      </>
    );
  }


  return <>
    <div className="hr-form-box">
      {isEdit ? <h6>{columnConstant.editairline}</h6> : <h6>{columnConstant.createairline}</h6>}
      <div className="row">
        <div className="col-md-3">
          <Dropdown
            field={
              {
                id: 'bizInstituteTypeId',
                label: columnConstant.bizinstitutetype  ,
                type: 'select',
                value: airlineForm.bizInstituteTypeId,
                required: true,
                error: airlineForm.bizInstituteTypeIderror,
                item: bizInstituteTypeItem,
                disable: isEdit ? true : false,
                onChange: handleInputChange
              }
            }
          />
        </div>
        {
          bizInstituteTypeItem && bizInstituteTypeItem[airlineForm.bizInstituteTypeId - 1]?.name.toLowerCase() === 'other' &&
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'otherBizInstitute',
                  label: columnConstant.typeofcompanyspecifyothertype,
                  type: 'text',
                  value: airlineForm.otherBizInstitute,
                  required: true,
                  error: airlineForm.otherBizInstituteerror,
                  disable: isEdit ? true : false,
                  onChange: handleInputChange
                }
              }
            />
          </div>
        }

        {
          isExportCarrier && <>

            <div className="col-md-3">
              <InputField
                field={
                  {
                    id: 'nameOnCheque',
                    label: columnConstant.nameoncheque,
                    type: "text",
                    value: airlineForm.nameOnCheque,
                    required: true,
                    error: airlineForm.nameOnChequeerror,
                    onChange: handleInputChange
                  }
                }
              />
            </div>
            <div className="col-md-3">
              <Dropdown
                field={
                  {
                    id: 'isPanAvailable',
                    label: columnConstant.panavailable  ,
                    type: 'select',
                    value: airlineForm.isPanAvailable,
                    required: true,
                    error: airlineForm.isPanAvailableerror,
                    item: applicableFormat,
                    onChange: handleInputChange
                  }
                }
              />
            </div>

            {
              airlineForm.isPanAvailable === 1 && <div className="col-md-3">
                <InputField
                  field={
                    {
                      id: 'panNumber',
                      label: columnConstant.panNumber,
                      type: 'text',
                      value: airlineForm.panNumber,
                      required: true,
                      error: airlineForm.panNumbererror,
                      onChange: handleInputChange
                    }
                  }
                />
              </div>

            }



            <div className="col-md-3">
              <Dropdown
                field={
                  {
                    id: 'isTDSApplicable',
                    label: columnConstant.tdsavailable  ,
                    type: 'select',
                    value: airlineForm.isTDSApplicable,
                    required: true,
                    error: airlineForm.isTDSApplicableerror,
                    item: applicableFormat,
                    onChange: handleInputChange
                  }
                }
              />
            </div>
            {
              airlineForm.isTDSApplicable === 1 &&
              <div className="col-md-3">
                <Dropdown
                  field={
                    {
                      id: 'tdsId',
                      label: columnConstant.tdssection  ,
                      type: 'select',
                      value: airlineForm.tdsId,
                      required: true,
                      error: airlineForm.tdsIderror,
                      item: tdsItem,
                      onChange: handleInputChange
                    }
                  }
                />
              </div>
            }

          </>
        }



        <div className="col-md-3">
          <InputField
            field={
              {
                id: "code",
                label: columnConstant.Code,
                type: "text",
                value: airlineForm.code,
                required: true,
                error: airlineForm.codeerror,
                onChange: handleInputChange
              }
            }
          />
        </div>

        <div className="col-md-3">
          <InputField
            field={
              {
                id: 'legalName',
                label: columnConstant.LegalName,
                type: 'text',
                value: airlineForm.legalName,
                required: true,
                error: airlineForm.legalNameerror,
                onChange: handleInputChange
              }
            }
          />
        </div>
        {
          isExportCarrier &&
          <>

            <div className="col-md-3">

              <AutoSelect field={{
                id: 'accountLedgerId',
                label: columnConstant.AccountLedgerName,
                value: airlineForm.accountLedgerId,
                required: true,
                error: airlineForm.accountLedgerIderror,
                item: accountLedgerItem,
                onChange: handleInputChange

              }}
              />
            </div>

            <div className="col-md-3">
              <InputField
                field={
                  {
                    id: "awbStockReorderLevel",
                    label: columnConstant.awbStockRecorderLevel,
                    type: "type",
                    value: airlineForm.awbStockReorderLevel,
                    required: true,
                    error: airlineForm.awbStockReorderLevelerror,
                    onChange: handleInputChange
                  }
                }
              />
            </div>
          </>
        }

        <div className="col-md-3">
          <InputField
            field={
              {
                id: "airlinePrefix",
                label: 'Airlines Prefix',
                type: "text",
                value: airlineForm.airlinePrefix,
                required: true,
                error: airlineForm.airlinePrefixerror,
                onChange: handleInputChange
              }
            }
          />
        </div>


        <div className="col-md-3 mt-3">
          <div className="d-flex align-items-center gap-3">
            <Checkbox checked={isExportCarrier} onChange={() => { setisExportCarrier(!isExportCarrier) }} />
            <div> Do you want to create as export carrier ?</div>
          </div>
        </div>

        <div className="col-md-3 mt-3">
          <div className="d-flex align-items-center gap-3">
            <Checkbox checked={isTagWithGSA} onChange={() => { setIsTagWithGSA(!isTagWithGSA) }} /> <div> Tag with GSA? </div>
          </div>
        </div>

      </div>
    </div>

    {
      isExportCarrier &&
      <div className="hr-form-box">
        {<h6>Airline details</h6>}
        <div className="row">
          <div className="col-md-3">
            <Dropdown
              field={
                {
                  id: "cityId",
                  label: ' City',
                  type: "select",
                  value: airlineForm.cityId,
                  required: true,
                  error: airlineForm.cityIderror,
                  item: cityItem,
                  onChange: handleInputChange
                }
              }
            />
          </div>

          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'address1',
                  label: 'Address1',
                  type: "text",
                  value: airlineForm.address1,
                  required: true,
                  error: airlineForm.address1error,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'address2',
                  label: 'Address 2',
                  type: "text",
                  value: airlineForm.address2,
                  required: false,
                  error: airlineForm.address2error,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'address3',
                  label: 'Address 3',
                  type: "text",
                  value: airlineForm.address3,
                  required: false,
                  error: airlineForm.address3error,
                  onChange: handleInputChange
                }
              }
            />
          </div>


          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'pinCode',
                  label: 'ZIP/Post Code',
                  type: "text",
                  value: airlineForm.pinCode,
                  required: true,
                  error: airlineForm.pinCodeerror,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'contactPerson',
                  label: 'Contact Person',
                  type: "text",
                  value: airlineForm.contactPerson,
                  required: true,
                  error: airlineForm.contactPersonerror,
                  onChange: handleInputChange

                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'email',
                  label: 'Email',
                  type: "text",
                  value: airlineForm.email,
                  required: true,
                  error: airlineForm.emailerror,
                  onChange: handleInputChange
                }
              }
            />
          </div>

          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'telephone',
                  label: 'Telephone',
                  type: "number",
                  value: airlineForm.telephone,
                  required: true,
                  error: airlineForm.telephoneerror,
                  helperText: 'TAN No required',
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'mobileNumber',
                  label: 'Mobile No',
                  type: "number",
                  value: airlineForm.mobileNumber,
                  required: true,
                  error: airlineForm.mobileNumbererror,
                  onChange: handleInputChange
                }
              }
            />
          </div>

          <div className="col-md-3">
            <Dropdown
              field={
                {
                  id: 'gstStatus',
                  label: 'GST STATUS',
                  type: "select",
                  value: airlineForm.gstStatus,
                  required: true,
                  error: airlineForm.gstStatuserror,
                  item: gstStatusItem,
                  onChange: handleInputChange
                }
              }
            />
          </div>
          <div className="col-md-3">
            <div className="d-flex gstWrapper">
              <InputField
                field={
                  {
                    id: "gstCode",
                    label: "Gst Code",
                    type: "text",
                    value: gstCode,
                    required: false,
                    disable: true,
                    error: false,
                    onChange: handleInputChange
                  }
                }
              />

              <InputField
                field={
                  {
                    id: "gstNumber",
                    label: "Gst No",
                    type: "text",
                    disable: airlineForm.gstStatus === 2 ? true : false,
                    value: airlineForm.gstStatus === 2 ? 'Unregistered' : airlineForm.gstNumber,
                    required: true,
                    error: airlineForm.gstNumbererror,
                    onChange: handleInputChange
                  }
                }
              />

            </div>
          </div>
          <div className="col-md-3">
            <InputField
              field={
                {
                  id: 'cin',
                  label: 'CIN',
                  type: "text",
                  value: airlineForm.cin,
                  required: true,
                  error: airlineForm.cinerror,
                  helperText: 'CIN required',
                  onChange: handleInputChange
                }
              }
            />
          </div>
        </div>
      </div>

    }

    {DuplicateInputDocs()}


    <div className='cmd'>
      <div></div>
      <Button sx={{ m: 2, borderRadius: "10px", }} onClick={handleFormSubmit} variant="contained">Submit</Button>
    </div>

  </>
}

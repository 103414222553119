import { Add } from "@mui/icons-material"
import { Button } from "@mui/material"
import { useEffect, useState } from "react"

const Preview = () => {
    const [totalairVolume, setTotalairvolumn] = useState([0]);
    const [totalgrossweight, setTotalgrossweight] = useState([0]);
    const [totalchargeweight, setTotalChargeweight] = useState([0]);
    const [totalpackage, setTotalpackage] = useState([0]);
    const data = JSON.parse(sessionStorage.getItem('previewData'))
    const Totaldata = () => {
        debugger
        let totalpackage = 0;
        let totalairVolume = 0;
        let totalgrossweight = 0;
        let totalchargeweight = 0;

        // Iterate through the array
        for (let obj of data) {
            // Add the 'number' property of each object to the sum
            totalpackage += parseInt(obj.packages);
            totalairVolume += parseInt(obj.airVolumeWeight);
            totalgrossweight += parseInt(obj.grossWeight)
            totalchargeweight += parseInt(obj.chargeWeight)

        }
        setTotalairvolumn(totalairVolume);
        setTotalgrossweight(totalgrossweight);
        setTotalpackage(totalpackage);
        setTotalChargeweight(totalchargeweight);
    }


    useEffect(() => {
        Totaldata();
    }, []);
    return <>
        <div className="hr-form-box">
            <div className="row Adddesign">
                <div className="col-md-2 staticvalue" >
                    <table >
                        <thead>
                            <tr>
                                <th>Basic Information</th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <ul>
                                    <li><span>Pacakages</span></li>
                                    <li><span>Gross Weight</span></li>
                                    <li>Volumn Weight</li>
                                    <li>Charge Weight</li>

                                    <li>T.Date</li>

                                </ul>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-md-8 middlepart" >

                    <table className="text-center">
                        <thead>
                            <tr>
                                {
                                    data?.map((item, index) => (
                                        <th>{item.shipperName}</th>
                                    ))
                                }
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                {
                                    data?.map((item, index) => (
                                        <td>{item.packages}</td>
                                    ))
                                }
                            </tr>
                            <tr>
                                {
                                    data?.map((item, index) => (
                                        <td>{item.grossWeight}</td>
                                    ))
                                }
                            </tr>
                            <tr>


                                {data?.map((item, index) => (
                                    <td key={index}>{parseFloat(item.airVolumeWeight).toFixed(2)}</td>
                                ))}
                            </tr>
                            <tr>
                                {
                                    data?.map((item, index) => (
                                        <td>{item.chargeWeight}</td>
                                    ))
                                }
                            </tr>

                            <tr>
                                {
                                    data?.map((item, index) => (
                                        <td>{item.tentativeDateOfShipment}</td>
                                    ))
                                }
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className="col-md-2 endparttotal" >
                    <table>
                        <thead>
                            <tr>
                                <th>Total Count</th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <ul>
                                    <li> <span>{totalpackage}</span></li>
                                    <li> <span>{totalgrossweight}</span></li>
                                    <li> <span>{totalairVolume}</span></li>
                                    <li> <span>{totalchargeweight}</span></li>
                                    <li></li>
                                </ul>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div >

       

    </>

}
export default Preview
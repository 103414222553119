import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Checkbox, IconButton, Switch, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dropdown, { InputField, SmallSwitch } from "../../component/controls";
import { Add, Close, Delete } from '@mui/icons-material';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import {  GetThirdCountryCustomerContactDelete, GetThirdCountryCustomerContacts, GetThirdCountryCustomerContactsStatus, PostThirdCountryCustomerAddContact } from '../../../services/constant';
import { DepartmentType } from '../../../services/modalData';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const CustomerThirdCountryMiscContactDetail = ({ row, callback }) => {

    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [contactEdit, setContactEdit] = useState(false);
    const [customerContactDetailsData, setcustomerContactDetailsData] = useState([])

    const fatch_CustomerListContact = () => {
        GetCaller({
            API: GetThirdCountryCustomerContacts,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                branchId: row?.id
            },
            callback: ({ response }) => {
                console.log('customerContactList', response?.data?.data)
                setcustomerContactDetailsData(response?.data?.data)
            }

        });

    }
    const handleClickOpen = () => {
        setOpen(true);
        fatch_CustomerListContact()
    };

    const handleClose = () => {
        setOpen(false);
        callback()
        setcustomerBranchFormData(
            {
                contactList: []
            }
        )
    };



    const [customerBranchFormData, setcustomerBranchFormData] = useState(
        {
            contactList: []
        }
    )

    const requestData = {
        branchId: row.id,
        thirdCountryMiscellaneousCustomerBranchContactList: customerBranchFormData.contactList
    }

    const handleBasicInputChange = (value, field) => {
        setcustomerBranchFormData({
            ...customerBranchFormData,
            [field.id]: value,
            [field.id + 'error']: !value,
        })
    }


    const handleSubmit = () => {
        console.log('branchInfoRequestData', requestData)

        function hasUndefinedValues(array) {
            let changes = {}; // Temporary object to accumulate changes

            array.map(obj => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (
                        key === 'landline'
                    ) {
                        return false
                    }
                    else if (!value) {
                        changes[key] = value;
                        changes[key + 'error'] = !value;
                    }
                });
            })

            // Check if any value in the object is undefined

            console.log(changes)
            return changes;
        }

        const customerBranchCheck = hasUndefinedValues(requestData?.thirdCountryMiscellaneousCustomerBranchContactList)


        if (
            Object.keys(customerBranchCheck).length > 0 ||
            requestData?.thirdCountryMiscellaneousCustomerBranchContactList.length === 0
        ) {
            setcustomerBranchFormData(prevFormData => ({
                ...prevFormData,
                ...customerBranchCheck,
            }));
        }
        else {
            PostCaller({
                API: PostThirdCountryCustomerAddContact,
                callback: () => { fatch_CustomerListContact() },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: requestData
            })
        }
    }

    const ContactForm = () => {

        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };
        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                name: '',
                email: '',
                departmentType: '',
                mobileNumber: '',
                landline: ''
            },
        ]);


        const addInputField = () => {
            // if (inputFields.length !== 2) {
            //     setInputFields([...inputFields, { key: generateUniqueKey(), name: '', email: '', departmentType: '', mobileNumber: '', landline: '' }]);
            // }
            setInputFields([...inputFields, { key: generateUniqueKey(), name: '', email: '', departmentType: '', mobileNumber: '', landline: '' }]);
        };

        const handleInputChange = (key, field, value) => {
            const updatedInputFields = [...inputFields];
            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
            if (fieldToUpdate) {
                fieldToUpdate[field] = value;
                setInputFields(updatedInputFields);
                const data = []
                updatedInputFields && updatedInputFields.map((res) => {
                    return data.push(
                        {
                            name: res.name,
                            email: res.email,
                            departmentType: res.departmentType,
                            mobileNumber: res.mobileNumber,
                            landline: res.landline
                        }
                    )

                })
                handleBasicInputChange(data, { id: 'contactList' });
            }
        };

        const removeInputField = (key) => {
            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        name: res.name,
                        email: res.email,
                        departmentType: res.departmentType,
                        mobileNumber: res.mobileNumber,
                        landline: res.landline
                    }
                )

            })
            handleBasicInputChange(data, { id: 'contactList' });
        };


        return <>
            <div className='mt-4'>
                <h5>Contact From</h5>

                {inputFields.map((field, key) => (
                    <div className='row' key={field.key}>
                        <div className="col-md-11">
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "name",
                                                label: "Contact Person Name",
                                                type: 'text',
                                                value: field.name,
                                                required: true,
                                                error: false,
                                                helperText: 'Contact Person Name Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'name', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "email",
                                                label: "Email",
                                                type: 'email',
                                                value: field.email,
                                                required: true,
                                                error: false,
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'email', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <Dropdown
                                        // className="mt-0"
                                        field={
                                            {
                                                id: "departmentType",
                                                label: "Department Type ",
                                                value: field.departmentType,
                                                required: true,
                                                error: false,
                                                helperText: 'Department Type Required',
                                                item: DepartmentType,
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'departmentType', value);
                                                }
                                            }
                                        }

                                    />

                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "mobileNumber",
                                                label: "Mobile No",
                                                type: 'number',
                                                value: field.mobileNumber,
                                                required: true,
                                                error: false,
                                                helperText: 'Mobile No Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'mobileNumber', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "landline",
                                                label: "Land Line",
                                                type: 'number',
                                                value: field.landline,
                                                required: false,
                                                error: false,
                                                helperText: 'Land Line Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'landline', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 cmd" style={{ justifyContent: "right" }}>
                            {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                <Add />
                            </Button> : (
                                <Button
                                    sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                    onClick={() => removeInputField(field.key)}
                                    variant='contained'
                                >
                                    <Close />
                                </Button>
                            )}
                        </div>
                    </div>
                ))}
            </div>

        </>
    }


    const handleSwitchChange = (id) => {

        GetCaller({
            API: GetThirdCountryCustomerContactsStatus,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: id,
            callback: ({ response }) => {
                fatch_CustomerListContact();
            }

        });
    }

    const deleteContactHandler = (id) => {
        GetCaller({
            API: GetThirdCountryCustomerContactDelete,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: id,
            callback: ({ response }) => {
                fatch_CustomerListContact();
            }

        });
    }


    // Function to handle editing a cell
    const handleEdit = (rowIndex, columnName, newValue) => {
        // Assuming customerContactDetailsData is not mutated directly (e.g., it is fetched from an API)
        const newData = [...customerContactDetailsData];
        newData[rowIndex].thirdCountryMiscellaneousCustomerBranchContact[columnName] = newValue;
        // You may need to update the state or send this data to the server, depending on your use case.
        // For simplicity, I'm just logging the updated data here.
        console.log('Updated data:', newData);
        setcustomerContactDetailsData(newData)
    };


    return (
        <React.Fragment >

            <IconButton onClick={handleClickOpen} >
                <Add />
            </IconButton>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "90% !important",
                    },
                }}
                sx={{
                    '& .MuiDialog-container': {
                        alignItems: "start"
                    }
                }
                }


            >
                <div className=''>
                    <DialogTitle>
                        <div className="cmd">
                            <h2>Misc Contact Details</h2>
                            <Tooltip title='Close'>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <hr />
                    </DialogTitle>
                    <DialogContent>

                        <div className="">

                            <div className='check-customer-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Mobile No.</th>
                                            <th>Landline No.</th>
                                            <th>Email</th>
                                            <th>Dep. Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customerContactDetailsData.length !== 0 ? customerContactDetailsData.map((res, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>

                                                    {
                                                        key === contactEdit ?
                                                            <EditableCell
                                                                value={res.thirdCountryMiscellaneousCustomerBranchContact.name}
                                                                onEdit={(newValue) => handleEdit(key, 'name', newValue)}
                                                            /> : res.thirdCountryMiscellaneousCustomerBranchContact.name
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        key === contactEdit ?
                                                            <EditableCell
                                                                value={res.thirdCountryMiscellaneousCustomerBranchContact.mobileNumber}
                                                                onEdit={(newValue) => handleEdit(key, 'mobileNumber', newValue)}
                                                            /> : res.thirdCountryMiscellaneousCustomerBranchContact.mobileNumber
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        key === contactEdit ?
                                                            <EditableCell
                                                                value={res.thirdCountryMiscellaneousCustomerBranchContact.landline || ''}
                                                                onEdit={(newValue) => handleEdit(key, 'landline', newValue)}
                                                            /> : res.thirdCountryMiscellaneousCustomerBranchContact.landline
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        key === contactEdit ?
                                                            <EditableCell
                                                                value={res.thirdCountryMiscellaneousCustomerBranchContact.email}
                                                                onEdit={(newValue) => handleEdit(key, 'email', newValue)}
                                                            /> : res.thirdCountryMiscellaneousCustomerBranchContact.email
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        key === contactEdit ?
                                                            <EditableCell
                                                                value={DepartmentType[res.thirdCountryMiscellaneousCustomerBranchContact.departmentType - 1].name}
                                                                onEdit={(newValue) => handleEdit(key, 'departmentType', newValue)}
                                                            /> : DepartmentType[res.thirdCountryMiscellaneousCustomerBranchContact.departmentType - 1].name
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        key === contactEdit ? <div>
                                                            <i className=' las la-times me-3 text-danger' onClick={() => setContactEdit(false)} />
                                                            <i className=' las la-check text-success' />

                                                        </div> :
                                                            <div>
                                                                <i className=' las la-edit' onClick={() => setContactEdit(key)}></i>
                                                                <i className=' las la-trash' onClick={() => deleteContactHandler(res.id)}></i>
                                                                <SmallSwitch checked={res.status} onChange={() => handleSwitchChange(res.id)} />
                                                            </div>

                                                    }

                                                </td>
                                            </tr>

                                        ))
                                            : <tr> <td colSpan={7}>No Data found !</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* <EditableTable /> */}

                            {ContactForm()}
                        </div>

                    </DialogContent>
                    <DialogActions className='mb-5'>
                        <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                        <Button style={{ background: 'var(--color-main)', color: '#fff' }} onClick={handleSubmit}>Submit</Button>
                    </DialogActions>
                </div>
            </Dialog>

        </React.Fragment >
    );
}
export default CustomerThirdCountryMiscContactDetail;


const EditableCell = ({ value, onEdit }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(value);

    const handleDoubleClick = () => {
        setIsEditing(true);
    };

    const handleBlur = () => {
        setIsEditing(false);
        onEdit(editedValue);
    };

    const handleChange = (e) => {
        setEditedValue(e.target.value);
    };

    return (
        <div>
            {isEditing ? (
                <input
                    type="text"
                    value={editedValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                />
            ) : (
                <div onClick={handleDoubleClick}>{value}</div>
            )}
        </div>
    );
};



import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import AddIcon from '@mui/icons-material/Add';
import { Avatar, Checkbox, Collapse, Menu, MenuItem, TableCell } from '@mui/material';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import DivisonDrawer from '../../component/divisionDrawer';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Add } from '@mui/icons-material';
import nodataFound from '../../assets/img/nodata.svg';
import { SmallSwitch } from '../../component/controls';
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells, onSelectAllClick, numSelected, rowCount, conditions } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {
                    conditions?.multiselect && !conditions?.isMainCheckBoxHide ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{
                                    'aria-label': 'select all desserts',
                                }}
                            />
                        </TableCell>
                        : <TableCell padding="checkbox"> <div></div></TableCell >

                }

                {headCells?.map((headCell) => (
                    headCell.id === 'subtable' ?
                        <TableCell padding="checkbox"></TableCell>
                        :
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            // IconComponent={order === 'desc' ? ArrowDownward : ArrowUpward}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar({ numSelected, drawerFormFields, title, conditions, columns, filterCallback, tabletool }) {

    const [anchorE3, setAnchorE3] = useState(null);

    return (
        <Toolbar
            className={conditions?.tablebarclass}
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected < 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                justifyContent: "space-between"
            }}
        >
            {/* when you want to show another tool kit than change arrow > this side  */}
            {numSelected < 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (

                <div className='table-head-bar'>
                    {numSelected > 0 ? (
                        <Tooltip title="Filter">
                            <IconButton
                                onClick={(e) => setAnchorE3(e.currentTarget)}
                                aria-controls={anchorE3 ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={anchorE3 ? 'true' : undefined}
                            >
                                <LabelOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    ) : ''}

                    <MapMenu anchorEl={anchorE3} setAnchorEl={setAnchorE3} menuItems={conditions?.sendleadfilter} />


                    <h1 className='table-head-title'>  {title}</h1>
                    <i className='las la-search '></i>
                    <input type="text" placeholder='Search...' />
                </div>
            )}

            {numSelected < 0 ? (
                <Tooltip title="Delete">
                    <IconButton onClick={() => { console.log(numSelected) }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Typography>

                    {/* tabletool  */}
                    {tabletool ? tabletool({ filterCallback })
                        : <DivisonDrawer drawerFormFields={drawerFormFields} />
                    }


                </Typography>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

export function CustomerTable(props) {
    const {
        title,
        data: propData,
        columns,
        drawerFormFields,
        conditions,
        actioncell,
        tabletool,
        subTableCell,
        paginationhandler,
        totaldatalength: datalength,
    } = props;

    const [data, setData] = useState(propData);
    const [totaldatalength, setTotaldatalength] = useState(false)


    useEffect(() => {
        setData(propData);
        setTotaldatalength(datalength)
    }, [propData]);

    const [filteredColumns, setFilteredColumns] = useState(columns)
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(columns[0].id);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    useEffect(() => {
        if (conditions?.hanldeSingleSelectCheckbox) {
            handleClick(true, conditions?.hanldeSingleSelectCheckbox)
        }
    }, [conditions?.hanldeSingleSelectCheckbox])

    // handle checkbox 
    const handleClick = (event, id) => {

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        console.log(newSelected);
        conditions?.selectedCallback(newSelected)
    };





    // const handleChangeDense = (event) => {
    //     setDense(event.target.checked);
    // };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totaldatalength) : 0;

    // we use visibleRows data when we encountring static data list 
    const visibleRows = useMemo(() =>
        stableSort(data, getComparator(order, orderBy))?.slice(
            // page * rowsPerPage,
            // page * rowsPerPage + rowsPerPage,
        ),
        [order, orderBy, page, rowsPerPage, data],
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const item = {
            searchKey: '',
            sortKey: '',
            sortDirection: '',
            itemsPerPage: rowsPerPage,
            page: newPage + 1,
        };

        paginationhandler(item)

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        const item = {
            searchKey: '',
            sortKey: '',
            sortDirection: '',
            itemsPerPage: event.target.value,
            page: rowsPerPage === event.target.value ? page + 1 : 1,
        };
        paginationhandler(item)

    };

    const filterCallback = (obj) => {
        setFilteredColumns(obj);
        console.log('filteredcoloumns', obj)
    }
    const [open, setOpen] = useState(false);

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>

                {/* table top tool bar  */}
                <EnhancedTableToolbar
                    columns={columns}
                    numSelected={selected.length}
                    drawerFormFields={drawerFormFields}
                    title={title}
                    conditions={conditions}
                    tabletool={tabletool}
                    filterCallback={filterCallback}
                />

                <TableContainer sx={{ maxHeight: 500 }}>

                    <Table
                        sx={{ minWidth: 100 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        stickyHeader
                        aria-label="sticky table"
                    >
                        {/* table header  */}
                        <EnhancedTableHead
                            onRequestSort={handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                            headCells={filteredColumns}
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={totaldatalength}
                            conditions={conditions}
                        />

                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const PriorityStyle = {
                                    borderLeft: row?.priority && row?.priority === 1 ? '5px solid red' :
                                        row?.priority && row?.priority === 2 ? '5px solid yellow' :
                                            row?.priority && row?.priority === 3 ? '5px solid green' : '5px solid transparent',
                                    borderTopLeftRadius: '10px'
                                }

                                return (
                                    <>
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            className={row?.className}
                                            sx={{ cursor: "pointer" }}
                                        >

                                            {
                                                conditions?.multiselect && (conditions?.isHideSubTableCheckBox && row?.customerBranchList.length === 0) ?


                                                    <TableCell padding="checkbox" style={row?.priority ? PriorityStyle : {}}>
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            onClick={(event) => handleClick(event, row.id)}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    : <TableCell padding="checkbox" style={row?.priority ? PriorityStyle : {}}></TableCell>

                                            }

                                            {
                                                filteredColumns.map((column) => (
                                                    row[column.id] ?
                                                        <TableCell
                                                            key={column.id}
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            align={column.numeric ? 'right' : 'left'}
                                                            rowSpan={column?.rowSpan ? (row?.rowSpan || 1) : 1}
                                                        >
                                                            {
                                                                column.id === 'sn' ? index + 1
                                                                    : row[column.id]
                                                            }

                                                        </TableCell>
                                                        : <TableCell align={column.numeric ? 'right' : 'left'}>
                                                            <div className='fca'>
                                                                {
                                                                    column.id === 'action' ?
                                                                        actioncell ? actioncell({ row }) :
                                                                            <>
                                                                                <Tooltip title='Edit'>
                                                                                    <IconButton>
                                                                                        <i className="las la-edit"></i>
                                                                                    </IconButton>
                                                                                </Tooltip>

                                                                                <SmallSwitch
                                                                                    // onChange={handleSwitchChange}
                                                                                    checked={row?.status}
                                                                                />
                                                                            </>

                                                                        : column.id === 'subtable' ?
                                                                            row?.customerBranchList?.length !== 0 ?
                                                                                <IconButton
                                                                                    aria-label="expand row"
                                                                                    size="small"
                                                                                    onClick={() => {

                                                                                        if (open === index) {
                                                                                            setOpen(false)
                                                                                        }
                                                                                        else {
                                                                                            setOpen(index)
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        <ArrowDropDownCircleIcon style={{ fontSize: '20px' }} className={open === index ? 'rotate_down' : 'rotate_up'} />
                                                                                    }
                                                                                </IconButton>

                                                                                : conditions?.subActionHeadCell ? conditions?.subActionHeadCell(row) : " "



                                                                            : <> N/A </>
                                                                }
                                                            </div>
                                                        </TableCell>

                                                ))
                                            }

                                        </TableRow>

                                        <TableRow>

                                            <TableCell className='subTableTH' style={{ padding: 0, }} colSpan={columns.length + 1}>
                                                <Collapse in={(conditions.openAllSubTable ? index : open) === index} timeout="auto" unmountOnExit>
                                                    {
                                                        subTableCell ? subTableCell(row) :
                                                            <Box sx={{ margin: 0 }}>
                                                                <div className='check-customer-table'>
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                {
                                                                                    conditions?.subColumns?.map((res, key) => {
                                                                                        return <th key={key}>{res.label}</th>
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {
                                                                                row?.customerBranchList?.map((row, index) => (
                                                                                    <tr key={index}>
                                                                                        {
                                                                                            conditions?.subColumns?.map((column, key) => (
                                                                                                column?.rendor ? <td> {column.rendor(row)}</td> :
                                                                                                    column.id === 'sn' ? <td>{index + 1}</td> :
                                                                                                        column.id === 'action' ? <td>{conditions?.subActionCell({ row })}</td>
                                                                                                            : row[column.id] ? <td>{row[column.id]}</td>
                                                                                                                : <td> N/A </td>
                                                                                            ))
                                                                                        }
                                                                                    </tr>
                                                                                ))
                                                                            }


                                                                            {/* Add more rows as needed */}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Box>
                                                    }

                                                </Collapse>
                                            </TableCell>
                                        </TableRow>

                                        {
                                            conditions.openAllSubTable &&
                                            <TableRow>
                                                <TableCell colSpan={columns.length + 1}></TableCell>
                                            </TableRow>
                                        }
                                    </>
                                );
                            })}

                            {
                                totaldatalength === 0 && totaldatalength !== false &&
                                <TableRow>
                                    <TableCell colSpan={columns.length} >
                                        <div className="cmc">
                                            <img style={{ height: "300px" }} src={nodataFound}></img>
                                        </div>
                                        <h5 className='text-center'>No Data Found !</h5>

                                    </TableCell>
                                </TableRow>

                            }


                        </TableBody>
                    </Table>
                </TableContainer>


                {/* table pagination  */}
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    // count={data.length}
                    count={totaldatalength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}








export default function MapMenu({ anchorEl, setAnchorEl, menuItems, filterCallback }) {
    const [selectedItemIds, setSelectedItemIds] = useState(menuItems && menuItems.map(item => item.id));

    const handleClose = () => {
        if (typeof filterCallback === 'function') {
            const selectedItems = menuItems.filter(item => selectedItemIds.includes(item.id));
            filterCallback(selectedItems);
        }
        setAnchorEl(null);
    };

    const toggleCheckbox = (id) => {
        setSelectedItemIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                // If the item is already selected, remove it
                return prevSelectedIds.filter(itemId => itemId !== id);
            } else {
                // If the item is not selected, add it to the end of the array
                return [...prevSelectedIds, id];
            }
        });
    };

    return (
        <Menu
            anchorEl={anchorEl}
            id="filter-menu"
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            sx={{ height: "50vh" }}
        >
            {menuItems && menuItems.map((menuItem) => (
                <MenuItem
                    key={menuItem.id}
                    sx={{ fontSize: '14px' }}
                    onClick={() => toggleCheckbox(menuItem.id)}
                    disabled={
                        selectedItemIds.length === 1 && selectedItemIds[0] === menuItem.id
                    }
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            color="primary"
                            checked={selectedItemIds.includes(menuItem.id)}
                        />
                        {menuItem.label}
                    </div>
                </MenuItem>
            ))}
        </Menu>
    );
}

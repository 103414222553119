import axios from "axios";
import { useSelector } from "react-redux";

const MasterCallBypagination = ({ API, callback, item, dispatch, enquirybranch }) => {
  const authToken = localStorage.getItem('authToken')

  dispatch({ type: "LOADER", payload: true });

  const requestData = item
    ? item
    : {
      searchKey: "",
      sortKey: "",
      sortDirection: "",
      itemsPerPage: 10,
      page: 1,
      status: false,
      date: ""

    };

  const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
  };

  axios

    .get(API(requestData, enquirybranch), { headers })
    .then((response) => {
      dispatch({ type: "LOADER", payload: false });
      callback({ response });
    })
    .catch((error) => {
      dispatch({ type: "LOADER", payload: false });
      callback({ error });
    });
};

export default MasterCallBypagination;

export const TradeExperience = [
    { name: 'Fresher', value: 1 },
    { name: '1 Year', value: 2 },
    { name: '2 Year', value: 3 },
    { name: '3 Year', value: 4 },
    { name: '4 Year', value: 5 },
    { name: '5+ Year', value: 6 },
];

export const MeritalStatus = [
    { name: 'Married', value: 1 },
    { name: 'Unmarried', value: 2 },
    { name: 'Divorce', value: 3 },
];

export const BloodGroup = [
    { name: 'A+', value: 1 },
    { name: 'A-', value: 2 },
    { name: 'O+', value: 3 },
    { name: 'O-', value: 4 },
];

export const EmploymentStatus = [
    { name: 'Confirmed', value: 1 },
    { name: 'Probation', value: 2 },
    { name: 'Trainee', value: 3 },
    // { name: 'Terminated', value: 4 },
    // { name: 'Resigned', value: 5 },
];

export const BankAccountType = [
    { name: 'Saving Account', value: 1 },
    { name: 'Current Account', value: 2 },
    { name: 'Salaried Account', value: 3 }
];


export const ReportingTo = [
    { name: "Select", value: 1 }
];


export const EmployeMentManager = [
    { name: "Select", value: 1 }
];

export const LeadLogAction = [
    { name: "Phone Call", value: 1 },
    { name: "Email", value: 2 },
    { name: "Personal Meeting", value: 3 },
    { name: "Close The Lead", value: 4 },

];

export const LeadLogResponse = [
    { name: "Call Me Later", value: 1 },
    { name: "Meeting Scheduled", value: 2 },
    { name: "Not Interested", value: 3 },
    { name: "No Response", value: 4 },
    { name: "Enquiry Recieved", value: 5 },
    { name: "Other", value: 6 },
];

export const LeadLogPriority = [
    { name: "High", value: 1 },
    { name: "Medium", value: 2 },
    { name: "Low", value: 3 },
];


export const BizInstituteType = [
    { name: "Sole Proprietorship", value: 1 },
    { name: "Partnership", value: 2 },
    { name: "Pvt. Ltd.", value: 3 },
    { name: "Limited", value: 4 },
    { name: "LLP", value: 5 },
    { name: "OPC", value: 6 },
    { name: "LLC", value: 7 },
    { name: "Other", value: 8 },
];

export const TypeOfCustomer = [
    { name: "Direct Party", value: 1 },
    { name: "Sub Agent", value: 2 },
    { name: "Overseas Network Partner", value: 3 },
    { name: "Overseas Direct Customer", value: 4 },
    { name: "Future Potential Client", value: 5 },

];

export const TypeOfFPC = [
    { name: "Direct Party", value: 1 },
    { name: "Sub Agent", value: 2 },
    { name: "Overseas Network Partner", value: 3 },
    { name: "Overseas Direct Customer", value: 4 },
    { name: "Unknown", value: 5 },
];

export const DepartmentType = [
    { name: "All", value: 1 },
    { name: "Accounts", value: 2 },
    { name: "Operation", value: 3 },
    { name: "Sales", value: 4 },
];

export const SectorTypeItem = [
    { name: 'Air', value: 1, },
    { name: 'Ocean', value: 2, }
];

export const AssetCateType = [
    { name: 'Moveable', value: 1, },
    { name: 'Fixed', value: 2, }
];

export const ChargehadeType = [
    {
        name: 'Agent',
        value: 1,
    },
    {
        name: "Subagent",
        value: 2,
    },
    {
        name: "Carrier",
        value: 3,
    }
]

export const EmployeeQualification = [
    {
        name: "Post Graduate",
        value: 1
    },
    {
        name: "Graduate",
        value: 2
    },
    {
        name: "12th or Equivalent",
        value: 3
    },
    {
        name: "10th",
        value: 4
    },
    {
        name: "Diploma",
        value: 5
    },

]

export const EmployeeNoticePeriod = [
    {
        name: "Immediate",
        value: 1
    },
    {
        name: "15 Days",
        value: 2
    },
    {
        name: "30 Days",
        value: 3
    },
    {
        name: "45 Days",
        value: 4
    },
    {
        name: "60 Days",
        value: 5
    },

]

export const applicableFormat = [
    {
        name: "Yes",
        value: 1
    },
    {
        name: "No",
        value: 2
    },
]
export const mawbShipmenttype = [
    {
        name: "Single Job",
        value: 1
    },
    {
        name: "Console Type",
        value: 2
    },
]

export const mawbshippers = [
    {
        name: "Shipper Consolidation",
        value: 1
    },
    {
        name: "Buyers Consolidation",
        value: 2
    },
    {
        name: "Others Consolidation",
        value: 3
    },
]

export const goodsExpectedItem = [
    {
        name: "Ware House",
        value: 2
    },
    {
        name: "Airport",
        value: 1
    },
    {
        name: "Both",
        value: 3
    },

]

export const carrierListawb = [
    {
        name: "carrier1",
        value: 1
    },
    {
        name: "carrier2",
        value: 2
    },
]

export const listofHoldReasons = [
    {
        name: "WithDrawn By Airline",
        value: 1
    },
    {
        name: "Issued To Other Iata ",
        value: 2
    },
    {
        name: "Wrong Awb No",
        value: 3
    },
    {
        name: "Awb Already Used",
        value: 4
    },
    {
        name: "Issue With Booking",
        value: 5
    },
    {
        name: "Others",
        value: 5
    },
]

export const currentStockType = [
    {
        name: "Iata Stock",
        value: 1
    },
    {
        name: "Courier Stock",
        value: 2
    }
]

export const currentIataStockType = [
    {
        name: "Awb Stock",
        value: 1
    },
    {
        name: "Courier Stock",
        value: 2
    }
]



export const EnquiryIconTermsData = [
    {
        name: "FOB",
        value: 1,
    },
    {
        name: "CIF",
        value: 2,
    },
    {
        name: "C&F/CFR",
        value: 3,
    },
    {
        name: "DDU",
        value: 4,
    },
    {
        name: "DAP/DPJ",
        value: 5,
    },
    {
        name: "EXW",
        value: 6,
    },
    {
        name: "FCA",
        value: 7,
    },
    {
        name: "CPT",
        value: 8,
    },
    {
        name: "CIP",
        value: 9,
    },
    {
        name: "FAS",
        value: 10,
    },
    {
        name: "DDP",
        value: 11,
    },

]

export const EnquiryTypeOfPackages = [
    {
        name: "Cartons",
        value: 1,
    },
    {
        name: "Pallets",
        value: 2,
    },
    {
        name: "Drums",
        value: 3,
    },
    {
        name: "Crates",
        value: 4,
    },
    {
        name: "Bales",
        value: 5,
    },
    {
        name: "Rolls",
        value: 6,
    },
    {
        name: "Container",
        value: 7,
    },
    {
        name: "Bags",
        value: 8,
    },
    {
        name: "Other",
        value: 9,
    },

]

export const EnquiryDimension = [
    {
        name: "CMS",
        value: 1,
    },
    {
        name: "INCH",
        value: 2,
    },
]

export const ChallanType = [
    {
        name: "All",
        value: 1,
    },
    {
        name: "Vehicle",
        value: 2,
    },
]

export const TypeofShipment = [

    {
        name: "Air Export",
        value: 1,
    },
    {
        name: "Air Import",
        value: 2,
    },
]

export const FreightTypeDirectType = [
    {
        name: "Port to Port",
        value: 1,
    },
    {
        name: "3rd Country",
        value: 2,
    },
    {
        name: "DAP",
        value: 3,
    },
    {
        name: "DPU",
        value: 4,
    },
    {
        name: "DDU",
        value: 5,
    },
    {
        name: "DDP",
        value: 6,
    },
    {
        name: "CC Only",
        value: 7,
    },
    {
        name: "Port to Port -PP/CC",
        value: 8,
    },
]
export const FreightTypeSubAgent = [
    {
        name: "Port to Port",
        value: 1,
    },
    {
        name: "3rd Country",
        value: 2,
    },
    {
        name: "DAP",
        value: 3,
    },
    {
        name: "DPU",
        value: 4,
    },
    {
        name: "DDU",
        value: 5,
    },
    {
        name: "DDP",
        value: 6,
    },
    {
        name: "CC Only",
        value: 7,
    },
    {
        name: "Freight/Service Type",
        value: 8,
    },
    {
        name: "Port to Port -PP/CC",
        value: 9,
    },
]
export const FreightTypeONP = [
    {
        name: "Port to Port",
        value: 1,
    },
    {
        name: "EXW",
        value: 2,
    },
    {
        name: "FCA",
        value: 3,
    },
    {
        name: "3rd Country",
        value: 4,
    },

]
export const FreightTypeODC = [
    {
        name: "Port to Port",
        value: 1,
    },
    {
        name: "EXW",
        value: 2,
    },
    {
        name: "FCA",
        value: 3,
    },
    {
        name: "3rd Country",
        value: 4,
    },
    {
        name: "DAP",
        value: 5,
    },
    {
        name: "DPU",
        value: 6,
    },
    {
        name: "DDU",
        value: 7,
    },
    {
        name: "DDP",
        value: 8,
    },
    {
        name: "DTD-Duty Unpaid",
        value: 9,
    },

    {
        name: "DTD-DAP",
        value: 10,
    },
    {
        name: "DTD-DDP",
        value: 11,
    },



]

export const FreightTypeSubType = [
    { name: "PTP", value: 1, },
    { name: "PTD", value: 2, },
    { name: "DTP", value: 3, },
    { name: "DDT", value: 4, },
];

export const consgneeSubAgencyType = [
    { name: "Own", value: 1 },
    { name: "SubAgency", value: 2 }
];

export const SingleShipmentType = [
    { name: "Single MAWB", value: 1 },
    { name: "Single MAWB & Single HAWB", value: 2 },
];

export const ChargeExportImport = [
    { name: "Air Export", value: 1 },
    { name: "Air Import", value: 2 },
    { name: "Ocean Export-LCL", value: 3 },
    { name: "Ocean Export-FCL", value: 4 },
    { name: "Ocean Import-LCL", value: 5 },
    { name: "Ocean Import-FCL", value: 6 },
];

export const JobTypeForGoodsInRegister = [
    { name: "Air Export", value: 1 },
    { name: "Air Import", value: 2 },
    { name: "Ocean Export-LCL", value: 3 },
    { name: "Ocean Export-FCL", value: 4 },
    { name: "Ocean Import-LCL", value: 5 },
    { name: "Ocean Import-FCL", value: 6 },
    { name: "Export Returns", value: 7 },
    { name: "Goods Return", value: 8 },
    { name: "Unknown", value: 9 },
];

export const DeliveryAt = [
    { name: "Airport", value: 1 },
    { name: "ICD", value: 2 },
    { name: "SEA PORT", value: 3 },
    { name: "OTHER", value: 4 }
];

export const WareHouseGoodsStatus = [
    { name: "Pending", value: 1 },
    { name: "Received", value: 2 },
    { name: "Cancelled", value: 3 },

];

export const GoodsReceivedStatus = [
    { name: "Pending", value: 1 },
    { name: "Received", value: 2 },
    { name: "Partial Received", value: 3 },
];

export const FixedCharges = [
    { name: 'Due Carrier', value: 1 },
    { name: 'Initial Buying', value: 2 },
    { name: 'Initial Selling', value: 3 },
    { name: 'Final Buying', value: 4 },
    { name: 'Final Selling', value: 5 },
];

export const PickUpType = [
    { name: "Self", value: 1 },
    { name: "Party", value: 2 },
];

export const frightpp = [
    { name: "PP", value: 1 },
    { name: "CC", value: 2 },
];

export const pretransport = [
    { name: "Self Pickup", value: 1 },
    { name: "Party Pickup", value: 2 },
    { name: "Both", value: 3 },

];

export const DestinationType = [
    { name: "Direct To Airport", value: 1 },
    { name: "Direct To Warehouse", value: 2 },

];

export const ConditionofGoodsType = [
    { name: "Ok", value: 1 },
    { name: "Wet", value: 2 },
    { name: "Damages", value: 3 },
    { name: "Open/Tampered", value: 4 },
    { name: "Counting Mismatch", value: 5 },
    { name: "Other Specification", value: 6 },
];

export const ConditionofEntry = [
    { name: "Inspection", value: 1 },
    { name: "Internal Exam", value: 2 },

];

export const StockStatus = [
    { name: "Unused", value: 1 },
    { name: "Used", value: 2 },
    { name: "Damage", value: 3 }
];

export const PickupStatus = [
    { name: "Import SHPT", value: 1 },
    { name: "Export Return", value: 2 },
    { name: "From APT", value: 3 },
    { name: "Withdrawl & Shipment", value: 4 },
];

export const PickupStatusForOut = [
    { name: "Import SHPT", value: 1 },
    { name: "Export Return", value: 2 },
    { name: "To APT", value: 3 },
    { name: "Withdrawl & Shipment", value: 4 },
];

export const GoodsConditions = [
    { name: "Ok", value: 1 },
    { name: "Wet", value: 2 },
    { name: "Damages", value: 3 },
    { name: "Open/Tempered", value: 4 },
    { name: "Counting Mismatch", value: 5 },
    { name: "Other Specification", value: 6 }
];

export const IntialStockProvider = [
    { name: "Self", value: 1 },
    { name: "Other", value: 2 }
];

export const ApplicableOn = [
    { name: "Minimum", value: 1 },
    { name: "Charge Weight", value: 2 },
    { name: "Gross Weight", value: 3 },
]

export const DueCarriersApplicableOn = [
    { name: "Minimum", value: 1 },
    { name: "Gross Weight", value: 2 },
    { name: "Chargeable Weight", value: 3 },
    { name: "HAWB", value: 4 },
    { name: "MAWB", value: 5 },
];

export const EnquiryStatus = [
    { name: 'New', value: 1 },
    { name: 'Quoted', value: 2 },
    { name: 'Requoted', value: 3 },
    { name: 'Final Quote', value: 4 },
    { name: 'Won', value: 5 },
    { name: 'Lost', value: 6 },
    { name: 'Repricing', value: 7 }
];

export const EnquiryTypeofvendor = [
    { name: "Airline/IATA", value: 1 },
    { name: "ONP-Origin", value: 2 },
    { name: "ONP-Destination", value: 3 },
    { name: "Other-Vendors", value: 4 },
];

export const EnquirySelectVendor = [
    { name: 'CHA', value: 1 },
    { name: 'Transportation', value: 2 },

];

export const QuoteTitleTheme = [
    { name: "new_title" },
    { name: "quoted_title" },
    { name: "requoted_title" },
    { name: "final_title" },
    { name: "won_title" },
    { name: "lost_title" },
];

export const Rateclass = [
    { name: "M", value: 1 },
    { name: "N", value: 2 },
    { name: "Q", value: 3 },
];

export const TypeofCsr = [
    { name: "All In", value: 1 },
    { name: "Net Net", value: 2 },
    { name: "Only IATA Comm.", value: 3 },
    { name: "IATA Incentive", value: 4 },
    { name: "Net Net Discount", value: 5 },
    { name: "Discounted with IATA", value: 6 },
    { name: "Discounted with IATA/Incentive", value: 6 },
];


export const SHIPPERMASTERTYPE = [
    { name: "CUSTOMER MASTER -DIRECT PARTY", value: 1 },
    { name: "3RD COUNTRY/MISC SHIPPER", value: 2 },
    { name: "S/AGENT SHIPPERS (AE,OE)", value: 3 }
];

export const CONSIGNEEMASTERTYPE = [
    { name: "Export Consignee", value: 1 },
    { name: "ODC - CUSTOMER MASTER", value: 2 },
    { name: "Export Consignee Or ODC", value: 3 }
];

export const ONPMASTERTYPE = [
    { name: "ONP-1 Auto From Buying", value: 1 },
    { name: "ONP-1 Auto From Customer", value: 2 },
    { name: "Export Consignee", value: 3 },
    { name: "Manual Entry (Auto Billing Party)", value: 4 },
];

export const AirExportCustomerTypeFrightOptions = [
    {
        name: "Direct Party", value: 1,
        FrightServiceType: [
            {
                name: "Port To Port",
                IncoTerm: [
                    { name: "C&F" },
                    { name: "CIF" },
                ],
                ONP: {
                    ONP1: false,
                    ONP1isrequired: false,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "Direct Party Biz",
                        BizType: [
                            {
                                name: "N/A",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    }
                                ],
                            }],
                    },

                ],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "3rd Country",
                IncoTerm: [
                    { name: "C&F" },
                    { name: "CIF" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: ONPMASTERTYPE[1],
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [
                    { name: "PTP" },
                    { name: "PTD" },
                    { name: "DTP" },
                    { name: "DTD" }
                ],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[1],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false

                                    },

                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "DAP",
                IncoTerm: [
                    { name: "C&F" },
                    { name: "CIF" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false

                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true

                                    }
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DPU",
                IncoTerm: [
                    { name: "C&F" },
                    { name: "CIF" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false

                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true

                                    }
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DDU",
                IncoTerm: [
                    { name: "C&F" },
                    { name: "CIF" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false

                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true

                                    }
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DDP",
                IncoTerm: [
                    { name: "C&F" },
                    { name: "CIF" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false

                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true

                                    }
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "CC Only",
                IncoTerm: [
                    { name: 'All Types Possible' },
                ],
                ONP: {
                    ONP1: false,
                    ONP1isrequired: false,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "Direct Party Biz",
                        BizType: [
                            {
                                name: "With Transporation",
                                BizSubType: [
                                    {
                                        name: "CC Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true

                                    }

                                ],
                            },
                            {
                                name: "Without Transporation",
                                BizSubType: [
                                    {
                                        name: "CC Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            },

                        ],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "Port to Port -PP/CC & CC Only",
                IncoTerm: [
                    { name: "C&I" },
                    { name: "FOB" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[3],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[2],
                                        KYC: false

                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[2],
                                        KYC: true

                                    }

                                ],
                            },
                        ],
                    },
                    {
                        name: "ODC Direct Biz",
                        BizType: [
                            {
                                name: "N/A",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false

                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[0],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true

                                    }

                                ],
                            },
                        ],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: false
            }
        ]
    },
    {
        name: "Sub Agent", value: 2,
        FrightServiceType: [
            {
                name: "Port To Port",
                IncoTerm: [
                    { name: "C&F" },
                    { name: "CIF" },
                ],
                ONP: {
                    ONP1: false,
                    ONP1isrequired: false,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "Direct S/A Biz",
                        BizType: [
                            {
                                name: "N/A",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    }
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "3rd Country",
                IncoTerm: [
                    { name: "All Types Possible" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: ONPMASTERTYPE[1],
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [
                    { name: "PTP" },
                    { name: "PTD" },
                    { name: "DTP" },
                    { name: "DTD" }],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[1],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },

                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "DAP",
                IncoTerm: [
                    { name: "All Types Possible" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },

                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DPU",
                IncoTerm: [
                    { name: "All Types Possible" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },

                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DDU",
                IncoTerm: [
                    { name: "All Types Possible" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },

                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DDP",
                IncoTerm: [
                    { name: "All Types Possible" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },

                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "CC Only",
                IncoTerm: [
                    { name: "All Types Possible" }
                ],
                ONP: {
                    ONP1: false,
                    ONP1isrequired: false,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "Direct S/A Biz",
                        BizType: [
                            {
                                name: "With Transportation",
                                BizSubType: [
                                    {
                                        name: "CC Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },


                                ],
                            },
                            {
                                name: "Without Transporation",
                                BizSubType: [
                                    {
                                        name: "CC Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },


                                ],
                            }

                        ],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "Freight Collection Only",
                IncoTerm: [
                    { name: "C&I" },
                    { name: "FOB / EXW / FCA" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[3],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "N/A",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },

                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "Port to Port -PP/CC",
                IncoTerm: [
                    { name: "C&I" },
                    { name: "FOB / EXW / FCA" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[3],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[2],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: false
            },
        ]
    },
    {
        name: "Overseas Network Partner", value: 3,
        FrightServiceType: [
            {
                name: "Port To Port",
                IncoTerm: [
                    { name: "C&I" },
                    { name: "FOB" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "Port To Port -PP/CC & CC Only",
                IncoTerm: [
                    { name: "C&I" },
                    { name: "FOB" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "EXW",
                IncoTerm: [
                    { name: "EXW" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: false
            },
            {
                name: "DAP",
                IncoTerm: [
                    { name: "EXW" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DPU",
                IncoTerm: [
                    { name: "EXW" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DDU",
                IncoTerm: [
                    { name: "EXW" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DDP",
                IncoTerm: [
                    { name: "EXW" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DTD-Duty Unpaid",
                IncoTerm: [
                    { name: "EXW" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            },
            {
                name: "DTD-DAP",
                IncoTerm: [
                    { name: "EXW" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            },
            {
                name: "DTD-DDP",
                IncoTerm: [
                    { name: "EXW" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            },
            {
                name: "FCA",
                IncoTerm: [
                    { name: "FCA" },
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: false
            },
            {
                name: "3rd Country",
                IncoTerm: [
                    { name: "Not Applicabel" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[1],
                    ONP1isrequired: true,
                    ONP2: ONPMASTERTYPE[1],
                    ONP2isrequired: true,
                    ONP3: ONPMASTERTYPE[0],
                    ONP3isrequired: true
                },
                FreightServiceSubType: [
                    { name: "PTP" },
                    { name: "PTD" },
                    { name: "DTP" },
                    { name: "DTD" }
                ],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Received",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[0],
                                        KYC: false
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            }
        ]
    },
    {
        name: "Overseas Direct Customer", value: 4,
        FrightServiceType: [
            {
                name: "Port To Port",
                IncoTerm: [
                    { name: "C&I" },
                    { name: "FOB" },
                ],
                ONP: {
                    ONP1: false,
                    ONP1isrequired: false,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "ODC Direct Biz",
                        BizType: [
                            {
                                name: "N/A",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    },
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "N/A",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "EXW",
                IncoTerm: [
                    { name: 'EXW' }
                ],
                ONP: {
                    ONP1: false,
                    ONP1isrequired: false,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "ODC Direct Biz",
                        BizType: [
                            {
                                name: "N/A",
                                BizSubType: [

                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[1],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    },
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "N/A",
                                BizSubType: [

                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[1],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: false
            },
            {
                name: "FCA",
                IncoTerm: [
                    { name: 'FCA' }
                ],
                ONP: {
                    ONP1: false,
                    ONP1isrequired: false,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "ODC Direct Biz",
                        BizType: [
                            {
                                name: "N/A",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    },
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "N/A",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: false
            },
            {
                name: "3rd Country",
                IncoTerm: [
                    { name: 'Not Applicabel' }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: ONPMASTERTYPE[0],
                    ONP2isrequired: true,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [
                    { name: "PTP" },
                    { name: "PTD" },
                    { name: "DTP" },
                    { name: "DTD" }
                ],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [

                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: SHIPPERMASTERTYPE[1],
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: false
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            },
            {
                name: "DAP",
                IncoTerm: [
                    { name: 'C&I' },
                    { name: "FOB" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DPU",
                IncoTerm: [
                    { name: 'C&I' },
                    { name: "FOB" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [

                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DDU",
                IncoTerm: [
                    { name: 'C&I' },
                    { name: "FOB" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DDP",
                IncoTerm: [
                    { name: 'C&I' },
                    { name: "FOB" }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [
                                    {
                                        name: "FF Only",
                                        ShipperFromMaster: '3RD COUNTRY /MISC SHIPPER',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: false
                                    },
                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "DTD-Duty Unpaid",
                IncoTerm: [
                    { name: 'EXW' }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [
                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [

                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            },
            {
                name: "DTD-DAP",
                IncoTerm: [
                    { name: 'EXW' }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [

                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [

                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            },
            {
                name: "DTD-DDP",
                IncoTerm: [
                    { name: 'EXW' }
                ],
                ONP: {
                    ONP1: ONPMASTERTYPE[0],
                    ONP1isrequired: true,
                    ONP2: false,
                    ONP2isrequired: false,
                    ONP3: false,
                    ONP3isrequired: false
                },
                FreightServiceSubType: [],
                BizCategoryType: [

                    {
                        name: "International Logistic",
                        BizType: [
                            {
                                name: "Nomination Given",
                                BizSubType: [

                                    {
                                        name: "FF+CC",
                                        ShipperFromMaster: 'CUSTOMER MASTER- DIRECT PARTY',
                                        ConsigneeFromMaster: CONSIGNEEMASTERTYPE[1],
                                        KYC: true
                                    },
                                ],
                            }],
                    }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            },

        ]
    },
    {
        name: "Future Potential Client", value: 5, FrightServiceType: []
    }
];

export const AirImportCustomerTypeFrightOtions = [
    {
        name: "Direct Party", value: 1, FrightServiceType: [
            {
                name: "Port To Port",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Party Biz" }
                ],
                BizType: [
                    { name: 'FF Only' }
                ],
                BizSubType: [],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "FCA",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Party Biz" }
                ],
                BizType: [
                    { name: 'FF Only' }
                ],
                BizSubType: [],
                MultiPickUp: true,
                MultiDelivery: false
            },
            {
                name: "EXW",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Party Biz" }
                ],
                BizType: [
                    { name: 'FF Only' }
                ],
                BizSubType: [],
                MultiPickUp: true,
                MultiDelivery: false
            },
            {
                name: "Port To Door",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Party Biz" }
                ],
                BizType: [
                    { name: "DAP" },
                    { name: "DDU" },
                    { name: "DDP" },
                ],
                BizSubType: [
                    { name: "FF Only" },
                    { name: "FF+CC" },
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "FCA To Door",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Party Biz" }
                ],
                BizType: [
                    { name: "DAP" },
                    { name: "DDU" },
                    { name: "DDP" },
                ],
                BizSubType: [
                    { name: "FF Only" },
                    { name: "FF+CC" }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            },
            {
                name: "EXW To Door",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Party Biz" }
                ],
                BizType: [
                    { name: "DAP" },
                    { name: "DDU" },
                    { name: "DDP" },
                ],
                BizSubType: [
                    { name: "FF Only" },
                    { name: "FF+CC" }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            },
            {
                name: "CC Only",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Party Biz" }
                ],
                BizType: [
                    { name: "With Transporation" },
                    { name: "without TPT" },
                ],
                BizSubType: [],
                MultiPickUp: false,
                MultiDelivery: true
            },
        ]
    },
    {
        name: "Sub Agent", value: 2, FrightServiceType: [
            {
                name: "Port To Port",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Sub Agency Biz" }
                ],
                BizType: [
                    { name: "FF Only" }
                ],
                BizSubType: [],
                MultiPickUp: false,
                MultiDelivery: false
            },
            {
                name: "FCA",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Sub Agency Biz" }
                ],
                BizType: [
                    { name: "FF Only" }
                ],
                BizSubType: [],
                MultiPickUp: true,
                MultiDelivery: false
            },
            {
                name: "Port to Door",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Sub Agency Biz" }
                ],
                BizType: [
                    { name: "DAP" },
                    { name: "DDU" },
                    { name: "DDP" },
                ],
                BizSubType: [
                    { name: "FF Only" },
                    { name: "FF+CC" }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "FCA to Door",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Sub Agency Biz" }
                ],
                BizType: [
                    { name: "DAP" },
                    { name: "DDU" },
                    { name: "DDP" },
                ],
                BizSubType: [
                    { name: "FF Only" },
                    { name: "FF+CC" }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            },
            {
                name: "EXW to Door",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Sub Agency Biz" }
                ],
                BizType: [
                    { name: "DAP" },
                    { name: "DDU" },
                    { name: "DDP" },
                ],
                BizSubType: [
                    { name: "FF Only" },
                    { name: "FF+CC" }
                ],
                MultiPickUp: true,
                MultiDelivery: true
            },
            {
                name: "CC Only",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Sub Agency Biz" }
                ],
                BizType: [
                    { name: "CC Only" },

                ],
                BizSubType: [],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "CARN Use Only",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Direct Sub Agency Biz" }
                ],
                BizType: [],
                BizSubType: [],
                MultiPickUp: false,
                MultiDelivery: false
            },

        ]
    },
    {
        name: "Overseas Network Partner", value: 3, FrightServiceType: [
            {
                name: "CC Only",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "PP Shipment" },
                    { name: "CC Shipment" }
                ],
                BizType: [
                    { name: "DO Issue Only" },
                    { name: "DAP" },
                    { name: "DDU" },
                    { name: "DDP" },
                ],
                BizSubType: [
                    { name: "CC Only" }
                ],
                MultiPickUp: false,
                MultiDelivery: true
            },
            {
                name: "3RD COUNTRY IMPORT INTO INDIA",
                FreightServiceSubType: [],
                BizCategoryType: [
                    { name: "Port to Port" },
                    { name: "FCA to Port" },
                    { name: "EXW to Port" },
                    { name: "Port to Door" },
                    { name: "FCA to Door" },
                    { name: "EXW to Door" },
                ],
                BizType: [],
                BizSubType: [],
                MultiPickUp: false,
                MultiDelivery: false
            },
        ]
    }


];




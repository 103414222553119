import React, { useEffect, useState } from "react";
import Dropdown, { AutoSelect, DateField, InputField, InputFileField, StackDropdown, TextAreafield } from "../../component/controls";
import { EnquiryDimension } from "../../../services/modalData";
import { Button, Checkbox, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import GetCaller, { PostCaller } from "../../../services/apiServices";
import { GetAirportMasterByPage, GetAllEmployee, GetCragoTypeByPage, GetCustomerByPage, GetMasterBranchByPage, GetMasterCountryByPage, GetPackageMasterByPage } from "../../../services/constant";
import { useDispatch, useSelector } from "react-redux";
import { PostAirExportEnquiry, UpdateAirExportEnquiry } from "../../../services/APIConstant/enquiryConstant";
import AlertShow from "../../component/snackbar/AlertShow";
import { airenquirylist } from "../../../navigation/constant";
import { useNavigate } from "react-router-dom";
import { ConvertValue } from "../../../services/utilities/valueConverter";
import AlertDialogBox from "../../component/tabs/alertDialog";
import Transition from "../../../services/utilities/transition";
import { CalculateTotalAirVolumnWeight } from "../../../services/utilities/calculateTotalVolumeWeight";


const EnquiryAirExport = (
    {
        MultiDelivery,
        MultiPickUp,
        CommonFormData,
        FreightItemsItem,
        FreightServiceSubTypeItem,
        BizTypeItem,
        BizSubTypeItem,
        BizCategoryTypeItem,
        callBack
    }

) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [edit, setEdit] = useState(false)
    // redux common data 
    const enuirydivision = useSelector(state => state.enuirydivision)
    const enquiryfinancialyear = useSelector(state => state.enquiryfinancialyear)

    // checkbox constant 
    const [ApprovalMSDS, setApprovalMSDS] = useState(false)
    const [OddDimension, setOddDimension] = useState(false)
    const [customerRoutingAndTransmitm, setcustomerRoutingAndTransmit] = useState(false)

    // total values constant
    const [totalVolumeWeight, setTotalVolumeWeight] = useState(0)
    const [totalPackages, setTotalPackages] = useState(0);

    const [deliveryTotalPackages, setDeliveryTotalPackages] = useState(0);
    const [deliveryTotalGrossWeight, setDeliveryTotalGrossWeight] = useState(0);

    const [pickupTotalPackages, setPickupTotalPackages] = useState(0);
    const [pickupTotalGrossWeight, setPickupTotalGrossWeight] = useState(0);

    // Array Object Details error constant 
    const [dimentionDetailsError, setDimentionDetailsError] = useState({});
    const [deliveryDetailsError, setDeliveryDetailsError] = useState({});
    const [pickupDetailsError, setPickupDetailsError] = useState({});

    // API Item Data Constant
    const [cargoItem, setCargoItem] = useState([])
    const [airportItem, setAirportItem] = useState([])
    const [packageItem, setPackageItem] = useState([])
    const [customerItem, setCustomerItem] = useState([])
    const [customerLedgerItem, setCustomerLedgerItem] = useState([])
    const [customerBranchItem, setCustomerBranchItem] = useState([])
    const [salesPersonItem, setSalesPersonItem] = useState([]);
    // Alert constant
    const [showAlert, setShowAlert] = useState(false);


    const CustomerAPIHandler = (value, customerId) => {
        GetCaller({
            API: GetCustomerByPage,
            dispatch: (e) => {
                // dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true,
                searchKey: value
            },
            callback: ({ response }) => {

                const customerItem = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.legalName,
                        accountLedger: item.accountLedger,
                        customerBranchList: item?.customerBranchList
                    }));

                setCustomerItem(customerItem);

                if (customerId) {
                    const customerFilterItem = customerItem.filter(res => res.value === customerId)

                    // const branchItem = customerFilterItem[0]?.customerBranchList?.map(res => ({
                    //     name: res?.branch,
                    //     value: res?.id
                    // }));

                    // setCustomerBranchItem(branchItem);

                    const ledgerItem = customerFilterItem[0]?.customerBranchList?.map(res => ({
                        name: res?.accountLedger,
                        value: res?.accountLedgerId
                    }));

                    setCustomerLedgerItem(ledgerItem)
                }

            }

        });
    }

    // API Calling 
    useEffect(() => {
        GetCaller({
            API: GetAllEmployee,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const airportItem = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setSalesPersonItem(airportItem)
            }
        })

        GetCaller({
            API: GetAirportMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const airportItem = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setAirportItem(airportItem)
            }
        })

        GetCaller({
            API: GetPackageMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const packagesItem = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setPackageItem(packagesItem)
            }
        })


        // branch master data
        GetCaller({
            API: GetMasterBranchByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const packagesItem = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setCustomerBranchItem(packagesItem)
            }
        })

        GetCaller({
            API: GetCragoTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const cargoItem = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setCargoItem(cargoItem)
            }
        })
    }, [])

    const airportItemFilterData = (id) => {
        let newFilterItem = airportItem.filter(item => item.value !== id)
        return newFilterItem
    }


    // default values form constant 
    const [EnquiryFormData, setEnquiryFormData] = useState({
        Customer: '',
        Branch: '',
        AccountLedger: "",
        BizGenerator: "",
        Packages: '',
        GrossWeight: '',
        AirVolumeWeight: '',
        TypeOfPackages: '',
        AirportOfLoading: '',
        AirportOfDestination: '',
        Dimention: '',
        CBM: "",
        CargoList: [],
        HiddenDangerousGood: 2,
        dimensions: [],
        pickupAddressList: [{
            address: "",
            packages: "",
            grossWeight: "",
            airVolumeWeight: "",
            dimensions: ""
        }],
        deliveryAddressList: [{
            address: "",
            packages: "",
            grossWeight: "",
            airVolumeWeight: "",
            dimensions: ""
        }]
    })


    useEffect(() => {
        const airExportEnquiryData = JSON.parse(sessionStorage.getItem('airExportEnquiryData'));
        if (airExportEnquiryData) {
            setEdit(true)
            const CustomerItem = CustomerAPIHandler(airExportEnquiryData?.customerName, airExportEnquiryData?.customer)
            setApprovalMSDS(airExportEnquiryData?.msdsApproval)
            setOddDimension(airExportEnquiryData?.isOddDimension)
            setcustomerRoutingAndTransmit(airExportEnquiryData?.routingAndTransitRequirement)
            setEnquiryFormData({
                ...EnquiryFormData,
                ['id']: airExportEnquiryData?.id,
                ['Customer']: airExportEnquiryData?.customer,
                ['Branch']: airExportEnquiryData?.branch,
                ['BizGenerator']: airExportEnquiryData?.bizGenerator,
                ['AccountLedger']: airExportEnquiryData?.accountLedger,
                ['Packages']: airExportEnquiryData?.packages,
                ['TypeOfPackages']: airExportEnquiryData?.typeOfPackages,
                ['GrossWeight']: airExportEnquiryData?.grossWeight,
                ['AirVolumeWeight']: airExportEnquiryData?.airVolumeWeight,
                ['Commodity']: airExportEnquiryData?.commodity,
                ['CBM']: airExportEnquiryData?.cbm,
                ['AirportOfLoading']: airExportEnquiryData?.airportOfLoading,
                ['AirportOfDestination']: airExportEnquiryData?.airportOfDestination,
                ['Dimention']: airExportEnquiryData?.dimention,
                ['TentativeDateOfShipment']: airExportEnquiryData?.tentativeDateOfShipment,
                ['Remarks']: airExportEnquiryData?.remarks,
                ['CargoList']: airExportEnquiryData?.cargoList,
                ['HiddenDangerousGood']: airExportEnquiryData?.hiddenDangerousGood === true ? 1 : 2,
                ['UnNumber']: airExportEnquiryData?.unNumber,
                ['MSDSDocument']: airExportEnquiryData?.msdsDocument,
                ['DocumentFile']: airExportEnquiryData?.document,
                ['dimensions']: revertDimensionsData(airExportEnquiryData?.dimensions || ''),
                ['CourierVolumeWeight']: airExportEnquiryData?.courierVolumeWeight
            })
        }

    }, [])

    const convertDimensions = (data) => {
        if (data && data.length !== 0) {
            return data?.map(item => {
                const isOddDimension = item.isOddDimension ? 'true' : 'false';
                return `${isOddDimension}*${item.length || 0}*${item.width || 0}*${item.height || 0}*${item.boxes || 0}*${item.airVolumeWeight || 0}`;
            }).join(',');
        } else {
            return ''
        }
    };

    const revertDimensionsData = (dataString) => {
        if (dataString) {
            return dataString.map(item => {
                const parts = item.split('*');
                return {
                    isOddDimension: parts[0] === 'true',
                    length: parseInt(parts[1], 10),
                    width: parseInt(parts[2], 10),
                    height: parseInt(parts[3], 10),
                    boxes: parseInt(parts[4], 10),
                    airVolumeWeight: parseInt(parts[5], 10)
                };
            });
        } else {
            return []
        }

    };


    const requestdata = (() => {
        const data = {
            AirExportEnquiry: {
                CustomerType: CommonFormData.CustomerType,
                Customer: EnquiryFormData.Customer,
                BizGenerator: EnquiryFormData.BizGenerator,
                Packages: EnquiryFormData.Packages,
                TypeOfPackages: EnquiryFormData.TypeOfPackages,
                GrossWeight: Number(EnquiryFormData.GrossWeight),
                Dimention: EnquiryFormData.Dimention,
                AirVolumeWeight: Number(EnquiryFormData.AirVolumeWeight),
                CargoList: EnquiryFormData?.CargoList,
                Commodity: EnquiryFormData.Commodity,
                CBM: EnquiryFormData.CBM,
                AirportOfLoading: EnquiryFormData.AirportOfLoading,
                AirportOfDestination: EnquiryFormData.AirportOfDestination,
                TentativeDateOfShipment: EnquiryFormData.TentativeDateOfShipment,
                Remarks: EnquiryFormData.Remarks,
                HiddenDangerousGood: EnquiryFormData.HiddenDangerousGood === 1 ? true : false,
                MSDSApproval: ApprovalMSDS,
                OddDimension: OddDimension,
                RoutingAndTransitRequirement: customerRoutingAndTransmitm,
                // Branch: enquirybranch,
                Branch: EnquiryFormData.Branch,
                Division: enuirydivision,
                FinancialYear: enquiryfinancialyear,




            },
            Document: EnquiryFormData.Document,
        }
        if (customerRoutingAndTransmitm) data.AirExportEnquiry.routing = EnquiryFormData?.routing
        if (edit) data.AirExportEnquiry.id = EnquiryFormData?.id
        if (ApprovalMSDS) data.AirExportEnquiry.UnNumber = EnquiryFormData.UnNumber
        if (ApprovalMSDS) data.AirExportEnquiry.MSDSDocument = EnquiryFormData.MSDSDocument
        if (ApprovalMSDS) data.MSDSDocument = EnquiryFormData.MSDSDocument
        if (FreightItemsItem?.length !== 0) data.AirExportEnquiry.FreightServiceType = CommonFormData.FreightServiceType
        if (FreightServiceSubTypeItem?.length !== 0) data.AirExportEnquiry.FreightServiceSubType = CommonFormData.FreightServiceSubType
        if (BizCategoryTypeItem?.length !== 0) data.AirExportEnquiry.BizCategoryType = CommonFormData.BizCategoryType
        if (BizTypeItem?.length !== 0) data.AirExportEnquiry.BizType = CommonFormData.BizType
        if (BizSubTypeItem?.length !== 0) data.AirExportEnquiry.BizSubType = CommonFormData.BizSubType
        if (EnquiryFormData.DimentionFile) data.DimentionFile = EnquiryFormData.DimentionFile
        if (EnquiryFormData.DimentionFile) data.AirExportEnquiry.DimentionFile = EnquiryFormData.DimentionFile
        if (packageItem && packageItem[EnquiryFormData.TypeOfPackages - 1]?.name.toLowerCase() === 'other') data.AirExportEnquiry.TypeOfPackagesOther = EnquiryFormData.TypeOfPackagesOther
        if (MultiPickUp) data.AirExportEnquiry.pickupAddressList = EnquiryFormData.pickupAddressList
        if (MultiDelivery) data.AirExportEnquiry.deliveryAddressList = EnquiryFormData.deliveryAddressList
        if (EnquiryFormData.Dimention === 1) data.AirExportEnquiry.CourierVolumeWeight = EnquiryFormData.CourierVolumeWeight
        if (EnquiryFormData.DocumentFile) data.AirExportEnquiry.Document = EnquiryFormData.DocumentFile
        if (EnquiryFormData.AccountLedger) data.AirExportEnquiry.AccountLedger = EnquiryFormData.AccountLedger
        if (EnquiryFormData?.dimensions?.length !== 0 && EnquiryFormData?.dimensions) data.AirExportEnquiry.dimensions = convertDimensions(EnquiryFormData?.dimensions)

        return data;
    })();


    useEffect(() => {



    }, [])

    // onChanges Function for set values & error on id bases in form constant
    const handleBasicInputChange = (value, field) => {
        setEnquiryFormData({
            ...EnquiryFormData,
            [field.id]: value,
            [field.id + 'error']: !value,
        })
    }

    const AirExportSubmit = () => {

        // set error for undefined id values on submit 
        function hasUndefinedValues(obj) {
            let changes = {};

            Object.entries(obj).forEach(([key, value]) => {

                if (typeof value === 'boolean' ||
                    (key === 'Customer' && EnquiryFormData.BizGenerator) ||
                    (key === 'BizGenerator' && EnquiryFormData.Customer) ||
                    (key === 'OddDimension') ||
                    (key === 'CourierVolumeWeight')
                ) {

                }
                else if (!value) {
                    changes[key + 'error'] = !value;
                }
            });

            return changes;
        }

        // multi dimention values error set 
        const dimentionDetailshasUndifinedValues = (array) => {
            let changes = {};

            array?.map((obj, index) => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (key === 'id' || key === 'isOddDimension') {

                    }
                    else if (!value) {
                        changes[key + index] = !value;
                    }
                });
            })

            return changes;
        };

        const airExportEnquiryChanges = hasUndefinedValues(requestdata.AirExportEnquiry)
        const dimentionChanges = dimentionDetailshasUndifinedValues(EnquiryFormData.dimensions || [])
        const pickupChanges = dimentionDetailshasUndifinedValues(requestdata.AirExportEnquiry.pickupAddressList)
        const deliveryChanges = dimentionDetailshasUndifinedValues(requestdata.AirExportEnquiry.deliveryAddressList)

        callBack()
        debugger
        if (Object.keys(airExportEnquiryChanges).length > 0 ||
            Object.keys(dimentionChanges).length > 0 ||
            Object.keys(pickupChanges).length > 0 ||
            Object.keys(deliveryChanges).length > 0
        ) {
            setDimentionDetailsError(dimentionChanges)
            setPickupDetailsError(pickupChanges)
            setDeliveryDetailsError(deliveryChanges)

            setEnquiryFormData(prevFormData => ({
                ...prevFormData,
                ...airExportEnquiryChanges,
            }));

        }
        else if ((MultiPickUp && totalPackages !== pickupTotalPackages) ||
            (MultiPickUp && Number(EnquiryFormData.GrossWeight) !== pickupTotalGrossWeight) ||
            (MultiDelivery && totalPackages !== deliveryTotalPackages) ||
            (MultiDelivery && Number(EnquiryFormData.GrossWeight) !== deliveryTotalGrossWeight)
        ) {
            setShowAlert(true)
        }
        else if (edit) {
            PostCaller({
                API: UpdateAirExportEnquiry,
                callback: () => {
                    navigate(airenquirylist)
                },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                contenttype: true,
                type: "PUT",
                item: requestdata
            })
        }
        else {
            PostCaller({
                API: PostAirExportEnquiry,
                callback: () => {
                    navigate(airenquirylist)
                },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                contenttype: true,
                item: requestdata
            })
        }



    }

    const calculateTotalVolumeWeight = (inputFields, typeOfDimenion) => {

        const obj = CalculateTotalAirVolumnWeight(inputFields, typeOfDimenion)

        DimentionChangesHandler(obj, inputFields, typeOfDimenion)

    };

    const DimentionChangesHandler = (obj, inputFields, typeOfDimenion) => {
        setTotalVolumeWeight(obj.totalWeight);
        setTotalPackages(obj.totalPackages)
        setEnquiryFormData({
            ...EnquiryFormData,
            ['AirVolumeWeight']: ConvertValue(obj.totalWeight),
            ["Packages"]: obj.totalPackages,
            ['CourierVolumeWeight']: ConvertValue(obj.CourierVolumeWeight),
            ['CBM']: ConvertValue(obj.CBM),
            ['dimensions']: inputFields,
            ['Dimention']: typeOfDimenion,
            ['Dimentionerror']: !typeOfDimenion

        })
    }




    const Dimension = () => {
        const [open, setOpen] = React.useState(false);
        const [file, setFile] = React.useState(false);


        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
            handleBasicInputChange(null, { id: 'DimentionFile' })

        }
        const handleSubmit = () => {
            handleBasicInputChange(file, { id: 'DimentionFile' })
            setOpen(false);
        }

        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };

        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                isOddDimension: false,
                length: '',
                width: '',
                height: '',
                boxes: '',
                airVolumeWeight: ''
            },
        ]);

        useEffect(() => {
            const airExportEnquiryData = JSON.parse(sessionStorage.getItem('airExportEnquiryData'));
            if (airExportEnquiryData && airExportEnquiryData?.dimensions && airExportEnquiryData?.dimensions?.length !== 0) {
                let item = airExportEnquiryData?.dimensions?.map(res => (
                    {
                        ...res,
                        key: generateUniqueKey(),
                    }
                ));
                setInputFields(item);
            }
        }, [])


        const addInputField = () => {
            setInputFields([...inputFields, { key: generateUniqueKey(), isOddDimension: false, length: '', width: '', height: '', boxes: '', airVolumeWeight: '' }]);
        };



        const handleInputChange = (key, field, value, index) => {

            const updatedInputFields = [...inputFields];
            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);

            if (fieldToUpdate) {
                setDimentionDetailsError({
                    ...dimentionDetailsError,
                    [field + index]: !value
                })

                fieldToUpdate[field] = value;
                setInputFields(updatedInputFields);

                const data = []
                updatedInputFields && updatedInputFields.map((res) => {
                    return data.push(
                        {
                            id: res?.id,
                            isOddDimension: res?.isOddDimension,
                            length: res.length,
                            width: res.width,
                            height: res.height,
                            boxes: res.boxes,
                            airVolumeWeight: res.airVolumeWeight
                        }
                    )
                })
                calculateTotalVolumeWeight(updatedInputFields, EnquiryFormData.Dimention);
            }
        };

        const removeInputField = (key) => {
            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res?.id,
                        isOddDimension: res?.isOddDimension,
                        length: res.length,
                        width: res.width,
                        height: res.height,
                        boxes: res.boxes,
                        airVolumeWeight: res.airVolumeWeight
                    }
                )

            })
            calculateTotalVolumeWeight(data, EnquiryFormData.Dimention);
        };


        return <>
            <div className=''>
                <div className="d-flex align-items-center">
                    <h4 className="m-0">Dimensions</h4>

                    <React.Fragment>
                        <div onClick={handleClickOpen}>
                            <i className="ms-3 las la-plus-circle"></i>
                        </div>


                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            PaperProps={{
                                sx: {
                                    width: "100%",
                                    maxWidth: "430px!important",
                                },
                            }}
                            sx={{
                                '& .MuiDialog-container': {
                                    alignItems: "start"
                                }
                            }
                            }


                        >
                            <div className=''>
                                <DialogTitle>
                                    <div className="cmd">
                                        <div>{'Attach Dimention'}</div>
                                        <Tooltip title='Close'>
                                            <IconButton onClick={handleClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <hr />
                                </DialogTitle>
                                <DialogContent>
                                    <InputFileField
                                        className='mt-0'
                                        field={{
                                            id: "",
                                            label: "",
                                            type: 'file',
                                            required: false,
                                            error: false,
                                            helperText: 'Upload Pan Card Required',
                                            onChange: (value, field) => {
                                                setFile(value)
                                            }
                                        }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                                    <Button onClick={handleSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
                                </DialogActions>
                            </div>
                        </Dialog>
                    </React.Fragment >
                </div>


                {inputFields.map((field, key) => (
                    <div className='cmd' key={field.key}>
                        {OddDimension &&
                            <div>
                                <Tooltip title='Odd Dimension'>
                                    <Checkbox value={field.isOddDimension} onChange={() => {
                                        handleInputChange(field.key, 'isOddDimension', !field.isOddDimension, key)
                                    }} />
                                </Tooltip>
                            </div>
                        }

                        <div className="w-100">
                            <div className="row mb-3">
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "length",
                                                label: "Length",
                                                type: 'number',
                                                value: field.length,
                                                required: true,
                                                error: dimentionDetailsError["length" + key],
                                                helperText: 'Contact Person Name Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'length', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "width",
                                                label: "Width",
                                                type: 'number',
                                                value: field.width,
                                                required: true,
                                                error: dimentionDetailsError["width" + key],
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'width', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        // className="mt-0"
                                        field={
                                            {
                                                id: "height",
                                                label: "Height",
                                                type: 'number',
                                                value: field.height,
                                                required: true,
                                                error: dimentionDetailsError["height" + key],
                                                helperText: 'Department Type Required',

                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'height', value, key);
                                                }
                                            }
                                        }

                                    />

                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "boxes",
                                                label: "Boxes",
                                                type: 'number',
                                                value: field.boxes,
                                                required: true,
                                                error: dimentionDetailsError["boxes" + key],
                                                helperText: 'Mobile No Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'boxes', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "airVolumeWeight",
                                                label: "Air Volume Weight",
                                                type: 'number',
                                                value: field.airVolumeWeight,
                                                required: false,
                                                error: dimentionDetailsError["airVolumeWeight" + key],
                                                disable: true,
                                                helperText: 'Land Line Required',
                                                // onChange: (value) => {
                                                //     handleInputChange(field.key, 'airVolumeWeight', value);
                                                // }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div >
                            {key === 0 ? <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                <Add />
                            </Button> : (
                                <Button
                                    sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                    onClick={() => removeInputField(field.key)}
                                    variant='contained'
                                >
                                    <Close />
                                </Button>
                            )}
                        </div>
                    </div>
                ))}
            </div>

        </>
    }






    return <>

        <div className="hr-form-box">

            <div className="row">


                <div className="col-md-2">
                    <AutoSelect
                        field={
                            {
                                id: "Customer",
                                label: "Enquiring Customer Name",
                                type: 'text',
                                value: EnquiryFormData.Customer,
                                required: EnquiryFormData.BizGenerator ? false : true,
                                error: EnquiryFormData.Customererror,
                                helperText: 'Enquiring Customer Name Required',
                                item: customerItem,
                                onChange: (value, field) => {
                                    // handleBasicInputChange(value, field)
                                    setEnquiryFormData({
                                        ...EnquiryFormData,
                                        [field.id]: value,
                                        [field.id + 'error']: !value,

                                        ['AccountLedger']: '',
                                        // ['Branch']: ''
                                    })

                                    const customerFilterItem = customerItem?.filter(res => res.value === value)

                                    const ledgerItem = customerFilterItem[0]?.customerBranchList?.map(res => ({
                                        name: res?.accountLedger,
                                        value: res?.accountLedgerId
                                    }));

                                    setCustomerLedgerItem(ledgerItem)
                                },
                                onInputProps: (value) => {

                                    if (value?.length > 1) {
                                        CustomerAPIHandler(value)
                                    }
                                    else {
                                        setCustomerItem([]);
                                        setEnquiryFormData({
                                            ...EnquiryFormData,
                                            ['Customer']: value,
                                            ['AccountLedger']: '',
                                            // ['Branch']: ''
                                        })
                                    }

                                }
                            }
                        }
                    />
                </div>
                {
                    EnquiryFormData.Customer && <>

                        {/* <div className="col-md-2">
                            <Dropdown
                                field={
                                    {
                                        id: "AccountLedger",
                                        label: "Account Ledger ",
                                        value: EnquiryFormData.AccountLedger,
                                        required: true,
                                        error: EnquiryFormData.AccountLedgererror,
                                        helperText: 'Type of Packages Required',
                                        item: customerLedgerItem,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div> */}

                        <div className="col-md-2">
                            <Dropdown
                                field={
                                    {
                                        id: "Branch",
                                        label: "Branch ",
                                        value: EnquiryFormData.Branch,
                                        required: true,
                                        error: EnquiryFormData.Brancherror,
                                        helperText: 'Type of Packages Required',
                                        item: customerBranchItem,
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        </div>


                    </>
                }


                <div className="col-md-2">
                    <InputField
                        field={
                            {
                                id: "BizGenerator",
                                label: "Biz. Generator/Reference",
                                type: 'text',
                                value: EnquiryFormData.BizGenerator,
                                required: EnquiryFormData.Customer ? false : true,
                                error: EnquiryFormData.BizGeneratorerror,
                                helperText: 'Biz. Generator/Reference Required',
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                {/* <div className="col-md-2">
                    <Dropdown
                        field={
                            {
                                id: "IncoTerms",
                                label: "Inco Terms ",
                                value: EnquiryFormData.IncoTerms,
                                required: true,
                                error: EnquiryFormData.IncoTermserror,
                                helperText: 'Inco Terms Required',
                                item: EnquiryIconTermsData,
                                onChange: handleBasicInputChange
                            }
                        }
                    />

                </div> */}
                <div className="col-md-2">
                    <InputField
                        field={
                            {
                                id: "Packages",
                                label: "Packages",
                                type: 'number',
                                value: EnquiryFormData.Packages,
                                required: true,
                                error: EnquiryFormData.Packageserror,
                                disable: totalPackages === 0 ? false : true,
                                helperText: 'Packages Required',
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <Dropdown
                        field={
                            {
                                id: "TypeOfPackages",
                                label: "Type of Packages ",
                                value: EnquiryFormData.TypeOfPackages,
                                required: true,
                                error: EnquiryFormData.TypeOfPackageserror,
                                helperText: 'Type of Packages Required',
                                item: packageItem,
                                onChange: handleBasicInputChange
                            }
                        }
                    />

                </div>
                {
                    packageItem && packageItem[EnquiryFormData.TypeOfPackages - 1]?.name.toLowerCase() === 'other' &&
                    <div className="col-md-2">
                        <InputField
                            field={
                                {
                                    id: "TypeOfPackagesOther",
                                    label: "(Other) Type Of Packages",
                                    type: 'text',
                                    value: EnquiryFormData.TypeOfPackagesOther,
                                    required: true,
                                    error: EnquiryFormData.TypeOfPackagesOthererror,
                                    helperText: 'Identification Required',
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                }

                <div className="col-md-2">
                    <InputField
                        field={
                            {
                                id: "GrossWeight",
                                label: "Gross Weight (kg)",
                                type: 'number',
                                value: EnquiryFormData.GrossWeight,
                                required: true,
                                error: EnquiryFormData.GrossWeighterror,
                                helperText: 'Identification Required',
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>

                <div className="col-md-2">
                    <InputField
                        field={
                            {
                                id: "AirVolumeWeight",
                                label: "Air Volume Weight",
                                type: 'number',
                                value: EnquiryFormData.AirVolumeWeight,
                                required: true,
                                error: EnquiryFormData.AirVolumeWeighterror,
                                disable: totalVolumeWeight === 0 ? false : true,
                                helperText: 'Identification Required',
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <StackDropdown field={{
                        id: "CargoList",
                        label: 'Cargo Type ',
                       
                        value: EnquiryFormData.CargoList,
                        required: true,
                        error: EnquiryFormData.CargoListerror,
                        item: cargoItem,
                        onChange: handleBasicInputChange
                    }} />
                </div>
                <div className="col-md-2">
                    <InputField
                        field={
                            {
                                id: "Commodity",
                                label: "Commodity",
                                type: 'text',
                                value: EnquiryFormData.Commodity,
                                required: true,
                                error: EnquiryFormData.Commodityerror,
                                helperText: 'Identification Required',
                                onChange: (value, field) => {
                                    let newValue = value.toUpperCase();
                                    handleBasicInputChange(newValue, field)
                                }
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <InputField
                        field={
                            {
                                id: "CBM",
                                label: "CBM",
                                type: 'text',
                                value: EnquiryFormData.CBM,
                                required: true,
                                error: EnquiryFormData.CBMerror,
                                disable: true,
                                helperText: 'Identification Required',
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <Dropdown
                        field={
                            {
                                id: "AirportOfLoading",
                                label: "Airport Of Loading ",
                                type: 'text',
                                value: EnquiryFormData.AirportOfLoading,
                                required: true,
                                error: EnquiryFormData.AirportOfLoadingerror,
                                helperText: 'Identification Required',
                                item: EnquiryFormData.AirportOfDestination ? airportItemFilterData(EnquiryFormData.AirportOfDestination) : airportItem,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                <div className="col-md-2">
                    <Dropdown
                        field={
                            {
                                id: "AirportOfDestination",
                                label: "Airport Of Destination ",
                                type: 'text',
                                value: EnquiryFormData.AirportOfDestination,
                                required: true,
                                error: EnquiryFormData.AirportOfDestinationerror,
                                helperText: 'Identification Required',
                                item: EnquiryFormData.AirportOfLoading ? airportItemFilterData(EnquiryFormData.AirportOfLoading) : airportItem,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
                {
                    (CommonFormData?.FreightServiceSubType === 'DTP' ||
                        CommonFormData?.FreightServiceSubType === 'DTD') && !MultiPickUp &&
                    <div className="col-md-2">
                        <InputField
                            field={
                                {
                                    id: "identificationDetails",
                                    label: "Pickup Address",
                                    type: 'text',
                                    value: EnquiryFormData.identificationDetails,
                                    required: true,
                                    error: EnquiryFormData.identificationDetailserror,
                                    helperText: 'Identification Required',
                                    onChange: (value, field) => {

                                        handleBasicInputChange(value, field)
                                    }
                                }
                            }
                        />
                    </div>
                }

                {
                    (CommonFormData?.FreightServiceSubType === 'PTD' ||
                        CommonFormData?.FreightServiceSubType === 'DTD') && !MultiDelivery &&
                    <div className="col-md-2">
                        <InputField
                            field={
                                {
                                    id: "identificationDetails",
                                    label: "Delivery Address",
                                    type: 'text',
                                    value: EnquiryFormData.identificationDetails,
                                    required: true,
                                    error: EnquiryFormData.identificationDetailserror,
                                    helperText: 'Identification Required',
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                }

                <div className="col-md-2">
                    <Dropdown
                        field={
                            {
                                id: "Dimention",
                                label: "Dimension ",
                                value: EnquiryFormData.Dimention,
                                required: true,
                                error: EnquiryFormData.Dimentionerror,
                                helperText: 'Network Required',
                                item: EnquiryDimension,
                                onChange: (value, field) => {
                                    // handleBasicInputChange(value, field)
                                    calculateTotalVolumeWeight(EnquiryFormData?.dimensions, value);
                                }
                            }
                        }
                    />

                </div>

                <div className="col-md-2">

                    <DateField
                        field={
                            {
                                id: "TentativeDateOfShipment",
                                label: "Tentative Date Of Shipment",
                                type: 'date',
                                value: EnquiryFormData.TentativeDateOfShipment,
                                required: true,
                                error: EnquiryFormData.TentativeDateOfShipmenterror,
                                helperText: 'Identification Required',
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>

                {/* <div className="col-md-2">

                    <Dropdown
                        field={
                            {
                                id: "salesSupport",
                                label: "Sales Support",
                                value: EnquiryFormData.salesSupport,
                                required: false,
                                error: EnquiryFormData.salesSupporterror,
                                helperText: 'Identification Required',
                                item: salesPersonItem,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>

                <div className="col-md-2">

                    <Dropdown
                        field={
                            {
                                id: "salePerson",
                                label: "Sales Person ",
                                value: EnquiryFormData.salePerson,
                                required: true,
                                error: EnquiryFormData.salePersonerror,
                                helperText: 'Identification Required',
                                item: salesPersonItem,
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div> */}
                {
                    customerRoutingAndTransmitm &&
                    <div className="col-md-2">
                        <InputField
                            field={
                                {
                                    id: "routing",
                                    label: "Routing",
                                    type: 'text',
                                    value: EnquiryFormData.routing,
                                    required: true,
                                    error: EnquiryFormData.routingerror,
                                    helperText: 'Identification Required',
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                }

            </div>

            {MultiDeliveryAddress(
                {
                    setDeliveryDetailsError,
                    deliveryDetailsError,
                    MultiDelivery,
                    handleBasicInputChange,
                    setDeliveryTotalPackages,
                    setDeliveryTotalGrossWeight
                }
            )}
            {MultiPickupAddress(
                {
                    setPickupDetailsError,
                    pickupDetailsError,
                    MultiPickUp,
                    handleBasicInputChange,
                    setPickupTotalGrossWeight,
                    setPickupTotalPackages,
                }
            )}

            <hr className="my-4" />
            {Dimension()}

            <hr className="my-4" />

            <div className="row">
                <div className="col-md-5">
                    <TextAreafield
                        field={{
                            id: "Remarks",
                            label: "Special Requirements/ Remarks",
                            value: EnquiryFormData.Remarks,
                            required: true,
                            error: EnquiryFormData.Remarkserror,
                            onChange: handleBasicInputChange
                        }}
                    />
                </div>
                <div className="col-md-1">

                </div>
                <div className="col-md-3">
                    <InputFileField
                        className='mt-0'
                        field={
                            {
                                id: "Document",
                                label: "Upload Document",
                                type: 'file',
                                value: '',
                                required: false,
                                error: EnquiryFormData.Documenterror,
                                helperText: 'Limit in INR Required',
                                onChange: handleBasicInputChange
                            }
                        }
                    />
                </div>
            </div>
            <hr className="my-4" />
            <div className="row">
                <div className="col-md-6 fca">
                    <div className="px-3" style={{ border: '1px solid #dcdcdc', display: "inline-flex" }}>
                        <RadioGroup
                            row
                            sx={{ flexWrap: "nowrap", marginLeft: 0 }}
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={EnquiryFormData.HiddenDangerousGood}
                            onChange={(e) => {
                                handleBasicInputChange(Number(e.target.value), { id: 'HiddenDangerousGood' })
                            }}
                        >
                            <FormControlLabel
                                sx={{ marginLeft: 0 }}
                                value={1}
                                control={<Radio color="primary" />}
                                label="Yes"
                            />
                            <FormControlLabel
                                sx={{ marginLeft: 0 }}
                                value={2}
                                control={<Radio color="primary" />}
                                label="No"
                            />
                        </RadioGroup>
                    </div>
                    <h6 className="p-0 m-0 ms-2">Did I identify for Hidden Dangerous Good? *</h6>
                </div>
                {
                    EnquiryFormData.HiddenDangerousGood === 1 && <div className="col-md-6 cmd">
                        <div>
                            <Checkbox
                                checked={ApprovalMSDS}
                                onChange={() => setApprovalMSDS(!ApprovalMSDS)}
                            /> Have I arranged for MSDS and its approval.*
                        </div>
                        {
                            ApprovalMSDS &&

                            <InputField
                                className='mx-md-5'
                                field={
                                    {
                                        id: "UnNumber",
                                        label: "UN No",
                                        type: 'text',
                                        value: EnquiryFormData.UnNumber,
                                        required: true,
                                        error: EnquiryFormData.UnNumbererror,
                                        helperText: 'Identification Required',
                                        onChange: handleBasicInputChange
                                    }
                                }
                            />
                        }
                    </div>
                }

                <div className="col-md-6 mt-4">
                    <Checkbox
                        checked={OddDimension}
                        onChange={() => setOddDimension(!OddDimension)}
                    />  Did , I identified for Odd Dimension? *
                </div>
                <div className="col-md-6 mt-4">
                    <Checkbox
                        checked={customerRoutingAndTransmitm}
                        onChange={() => setcustomerRoutingAndTransmit(!customerRoutingAndTransmitm)}
                    />  Did i checked Customer Routing and transit requirement?*
                </div>
                {
                    ApprovalMSDS && <div className="col-md-5">
                        <InputFileField
                            className='mt-3'
                            field={
                                {
                                    id: "MSDSDocument",
                                    label: "Attach MSDS",
                                    type: 'file',
                                    value: '',
                                    required: true,
                                    error: EnquiryFormData.MSDSDocumenterror,
                                    helperText: 'Limit in INR Required',
                                    onChange: handleBasicInputChange
                                }
                            }
                        />
                    </div>
                }
            </div>

            <React.Fragment>
                <AlertShow
                    option={{
                        button: true
                    }}
                    callback={showAlert}
                    setFalse={() => setShowAlert(false)}
                    submitCallBack={() => { setShowAlert(false) }}
                    message={
                        <div>
                            Your
                            {
                                (MultiPickUp && totalPackages !== pickupTotalPackages) ? ' Pickup Packages, ' : ""}
                            {
                                (MultiPickUp && Number(EnquiryFormData.GrossWeight) !== pickupTotalGrossWeight) ? " Pickup GrossWeight, " : ""
                            }
                            {
                                (MultiDelivery && totalPackages !== deliveryTotalPackages) ? " Delivery Packages, " : ""
                            }
                            {
                                (MultiDelivery && Number(EnquiryFormData.GrossWeight) !== deliveryTotalGrossWeight) ? " Delivery GrossWeight, " : ''
                            }

                            Not Matching from Your Enquiry Data !.
                        </div>

                    }
                />
            </React.Fragment>



            <div className="cmd mt-5">
                <div></div>
                <div>
                    <Button variant='contained' className='ms-3' onClick={AirExportSubmit}> Save & Submit</Button>
                    {/* <Button variant='contained' className='ms-3' onClick={() => { }}>Create Initial Quote</Button> */}

                </div>
            </div>




        </div >

    </>
}
export default EnquiryAirExport;



export const MultiDeliveryAddress = ({
    deliveryDetailsError,
    MultiDelivery,
    handleBasicInputChange,
    setDeliveryTotalPackages,
    setDeliveryTotalGrossWeight,
    setDeliveryDetailsError

}) => {

    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };
    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            address: '',
            packages: '',
            grossWeight: '',
            airVolumeWeight: '',
            dimensions: ''
        },
    ]);

    const calculateDeliveryTotalVolumeWeight = () => {
        const obj = {
            totalWeight: 0,
            totalPackages: 0
        }
        inputFields.forEach((res, key) => {
            const { packages, grossWeight } = res;
            obj.totalWeight += Number(grossWeight)
            obj.totalPackages += Number(packages)
        });

        return obj;
    };


    useEffect(() => {
        const obj = calculateDeliveryTotalVolumeWeight();
        setDeliveryTotalGrossWeight(obj.totalWeight)
        setDeliveryTotalPackages(obj.totalPackages)
    }, [inputFields]);



    const addInputField = () => {
        setInputFields([...inputFields, { key: generateUniqueKey(), address: '', packages: '', grossWeight: '', airVolumeWeight: '', dimensions: '' }]);
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
        if (fieldToUpdate) {
            setDeliveryDetailsError({
                ...deliveryDetailsError,
                [field + index]: !value
            })
            fieldToUpdate[field] = value;
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        address: res.address,
                        packages: res.packages,
                        grossWeight: res.grossWeight,
                        airVolumeWeight: res.airVolumeWeight,
                        dimensions: res.dimensions
                    }
                )

            })
            handleBasicInputChange(data, { id: 'deliveryAddressList' });
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    address: res.address,
                    packages: res.packages,
                    grossWeight: res.grossWeight,
                    airVolumeWeight: res.airVolumeWeight,
                    dimensions: res.dimensions
                }
            )

        })
        handleBasicInputChange(data, { id: 'deliveryAddressList' });
    };


    return <>{
        <div className='mt-4'>
            {MultiDelivery &&
                inputFields.map((field, key) => (
                    <div className='row m-0 ' style={{ border: '1px solid #dcdcdc', }} key={field.key}>
                        <div className="col-md-1 cmd" style={{ justifyContent: "left" }}>
                            {key === 0 ?
                                <IconButton onClick={addInputField} style={{ borderRadius: '4px', border: '1px solid #dcdcdc', padding: '4px' }}>
                                    <Add />
                                </IconButton>
                                : (
                                    <IconButton onClick={() => removeInputField(field.key)}>
                                        <i className="las la-trash " style={{ fontSize: '20px' }}></i>
                                    </IconButton>
                                )}
                        </div>
                        <div className="col-md-11">
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "address",
                                                label: "Delivery Address",
                                                type: 'text',
                                                value: field.address,
                                                required: true,
                                                error: deliveryDetailsError['address' + key],
                                                helperText: 'Delivery Address Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'address', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "packages",
                                                label: "Packages",
                                                type: 'number',
                                                value: field.packages,
                                                required: true,
                                                error: deliveryDetailsError['packages' + key],
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'packages', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>

                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "grossWeight",
                                                label: "Gross Weight",
                                                type: 'number',
                                                value: field.grossWeight,
                                                required: true,
                                                error: deliveryDetailsError['grossWeight' + key],
                                                helperText: 'Mobile No Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'grossWeight', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "airVolumeWeight",
                                                label: "CBM",
                                                type: 'number',
                                                value: field.airVolumeWeight,
                                                required: false,
                                                error: deliveryDetailsError['airVolumeWeight' + key],
                                                helperText: 'Land Line Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'airVolumeWeight', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div> <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "dimensions",
                                                label: "Pin Code",
                                                type: 'text',
                                                value: field.dimensions,
                                                required: false,
                                                error: deliveryDetailsError['dimensions' + key],
                                                helperText: 'Land Line Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'dimensions', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
        </div >
    }


    </>
}

export const MultiPickupAddress = ({
    setPickupDetailsError,
    pickupDetailsError,
    MultiPickUp,
    handleBasicInputChange,
    setPickupTotalGrossWeight,
    setPickupTotalPackages,


}) => {

    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };
    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            address: '',
            packages: '',
            grossWeight: '',
            airVolumeWeight: '',
            dimensions: ''
        },
    ]);

    const calculatePickUpTotalVolumeWeight = () => {
        const obj = {
            totalWeight: 0,
            totalPackages: 0
        }
        inputFields.forEach((res, key) => {
            const { packages, grossWeight } = res;
            obj.totalWeight += Number(grossWeight)
            obj.totalPackages += Number(packages)
        });

        return obj;
    };


    useEffect(() => {
        const obj = calculatePickUpTotalVolumeWeight();
        setPickupTotalGrossWeight(obj.totalWeight)
        setPickupTotalPackages(obj.totalPackages)
    }, [inputFields]);


    const addInputField = () => {

        setInputFields([...inputFields, { key: generateUniqueKey(), address: '', packages: '', grossWeight: '', airVolumeWeight: '', dimensions: '' }]);
    };

    const handleInputChange = (key, field, value, index) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
        if (fieldToUpdate) {
            setPickupDetailsError({
                ...pickupDetailsError,
                [field + index]: !value
            })
            fieldToUpdate[field] = value;
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        address: res.address,
                        packages: res.packages,
                        grossWeight: res.grossWeight,
                        airVolumeWeight: res.airVolumeWeight,
                        dimensions: res.dimensions
                    }
                )

            })
            handleBasicInputChange(data, { id: 'pickupAddressList' });
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    address: res.address,
                    packages: res.packages,
                    grossWeight: res.grossWeight,
                    airVolumeWeight: res.airVolumeWeight,
                    dimensions: res.dimensions
                }
            )

        })
        handleBasicInputChange(data, { id: 'pickupAddressList' });
    };


    return <>
        {
            MultiPickUp &&
            <div className='mt-4'>


                {inputFields.map((field, key) => (
                    <div className='row m-0 ' style={{ border: '1px solid #dcdcdc', }} key={field.key}>
                        <div className="col-md-1 cmd" style={{ justifyContent: "left" }}>
                            {key === 0 ?
                                <IconButton onClick={addInputField} style={{ borderRadius: '4px', border: '1px solid #dcdcdc', padding: '4px' }}>
                                    <Add />
                                </IconButton>
                                : (
                                    <IconButton onClick={() => removeInputField(field.key)}>
                                        <i className="las la-trash " style={{ fontSize: '20px' }}></i>
                                    </IconButton>
                                )}
                        </div>
                        <div className="col-md-11">
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "address",
                                                label: "Pickup Address",
                                                type: 'text',
                                                value: field.address,
                                                required: true,
                                                error: pickupDetailsError['address' + key],
                                                helperText: 'Delivery Address Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'address', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <InputField
                                        field={
                                            {
                                                id: "packages",
                                                label: "Packages",
                                                type: 'number',
                                                value: field.packages,
                                                required: true,
                                                error: pickupDetailsError['packages' + key],
                                                helperText: 'Email Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'packages', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>

                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "grossWeight",
                                                label: "Gross Weight",
                                                type: 'number',
                                                value: field.grossWeight,
                                                required: true,
                                                error: pickupDetailsError['grossWeight' + key],
                                                helperText: 'Mobile No Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'grossWeight', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "airVolumeWeight",
                                                label: "CBM",
                                                type: 'number',
                                                value: field.airVolumeWeight,
                                                required: false,
                                                error: pickupDetailsError['airVolumeWeight' + key],
                                                helperText: 'Land Line Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'airVolumeWeight', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div> <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: "dimensions",
                                                label: "Pin Code",
                                                type: 'text',
                                                value: field.dimensions,
                                                required: false,
                                                error: pickupDetailsError['dimensions' + key],
                                                helperText: 'Land Line Required',
                                                onChange: (value) => {
                                                    handleInputChange(field.key, 'dimensions', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
            </div >
        }

    </>
}




import React, { useState, MouseEvent, useEffect } from 'react'
import { Avatar, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import Button from '@mui/material/Button';
import { Add, BorderColor, Close, Edit } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios';
import { GetEmployeeMasterData, GetMasterBranchByPage, GetMasterCountryByPage, GetMasterDepartmentByPage, GetMasterDesignationByPage, GetMasterDivisionByPage, PostAssetCategory, PostAssetMaster, PostEmployeUser, PostEmployeeAdd, PostMasterCity, PostMasterCountry } from '../../services/constant';
import { BankAccountType, BloodGroup, EmployeMentManager, EmployeeNoticePeriod, EmployeeQualification, EmploymentStatus, MeritalStatus, ReportingTo, TradeExperience } from '../../services/modalData';
import { hremployelist } from '../../navigation/constant';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Dropdown, { AutoSelect, DateField, InputField, TimeField } from '../component/controls';
import AlertDialog from '../component/snackbar/AlertDialog';
import GetCaller, { PostCaller } from '../../services/apiServices';
import { red } from '@mui/material/colors';
import dayjs from 'dayjs';
import formValueReseter from '../../services/utilities/formValueReseter';
import { formatDataToFormData } from '../../services/utilities/formUtilities';

const HrEmployeeLIstForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authToken = localStorage.getItem('authToken')
    const headers = {
        Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
    };

    const [employeeListItems, setEmployeeListItems] = useState([])
    const [countryItem, setCountryItem] = useState([]);
    const [stateItem, setStateItem] = useState([])
    const [currstateItem, setCurrStateItem] = useState([])
    const [cityItem, setCityItem] = useState([])
    const [currcityItem, setCurrCityItem] = useState([])
    const [branchItems, setBranchItems] = useState([]);
    const [divisionItems, setDivisionItems] = useState([]);
    const [departmentItems, setDepartmentItems] = useState([]);
    const [subDepartmentItems, setSubDepartmentItems] = useState([]);
    const [designationItems, setDesignationItems] = useState([]);
    const [employementReportingToItem, setEmployementReportingToItem] = useState([])
    const [employementManagerItem, setEmployementManagerItem] = useState([]);
    const [AdharVerif, setAdharVerif] = useState(false);
    const [PoliceVerif, setPoliceVerif] = useState(false);
    const [EmpFormVerif, setEmpFormVerif] = useState(false);
    const [ResumeVerify, setResumeVerify] = useState(false);
    const [PanVerify, setPanVerify] = useState(false);
    const [RelievLetterVerify, setRelievLetterVerify] = useState(false);
    const [relevlet, setRelivelet] = useState(false);
    const [JoinAgreeVerify, setJoinAgreeVerify] = useState(false);
    const [OfferLetterVerify, setOfferLetterVerify] = useState(false);
    const [AppointLetterVerify, setAppointLetterVerify] = useState(false);
    const [SalarySlipVerify, setSalarySlipVerify] = useState(false);
    // muiti data error states
    const [assetDetailsError, setAssetDetailsError] = useState({})
    const [educationDetailsError, setEducationDetailsError] = useState({})
    const [designationItem, setDesignationItem] = useState([])


    useEffect(() => {
        GetCaller({
            API: GetMasterDesignationByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                let data = response?.data?.data?.records

                const newData = data?.map(res => ({
                    value: res?.id,
                    name: res?.name
                }))

                setDesignationItem(newData)
            }
        })
    }, [])


    const [formData, setFormData] = useState({

        EducationQualification: [{
            educationAndQualification: {
                qualification: "",
                schoolUniversity: "",
                passingYear: "",
                document: "string"
            },
            file: ""
        }],
        documents: [
            {
                documentType: "adhaarcard",
                file: ""
            },

            {
                documentType: "employeeform",
                file: ""
            },
            {
                documentType: "resume",
                file: ""
            },

            {
                documentType: "bankstatement",
                file: ""
            },

            {
                documentType: "appionmentlatter",
                file: ""
            },
            {
                documentType: "offerlater",
                file: ""
            },
            {
                documentType: "joiningagreement",
                file: ""
            },
            {
                documentType: "pancard",
                file: ""
            },
            {
                documentType: "relivingletter",
                file: ""
            },

        ]
    });

    const createEmpRequestData = (() => {
        const data = {
            Employee: {
                BranchId: formData.BranchId,
                DivisionId: formData.DivisionId,
                DepartmentId: formData.DepartmentId,
                SubDepartmentId: formData.SubDepartmentId,
                DesignationId: formData.DesignationId,
                ReferredBy: formData.ReferredBy || '',
                ShiftTiming: formData.ShiftTiming,
                TradeExperience: formData.TradeExperience,
                FirstName: formData.FirstName,
                LastName: formData.LastName,
                FatherOrHusbandName: formData.FatherOrHusbandName,
                MotherName: formData.MotherName,
                Gender: formData.Gender,
                DateOfBirth: formData.DateOfBirth,
                DateOfJoining: formData.DateOfJoining,
                employmentStatus: formData.employmentStatus,
                DateOfAnneversary: formData.MeritalStatus === 1 ? formData.DateOfAnneversary : '',
                MobileNumber: formData.MobileNumber,
                OfficialEmail: formData.OfficialEmail,
                PersonalEmail: formData.PersonalEmail,
                AdharNumber: formData.AdharNumber,
                PanNumber: formData.PanNumber,
                MeritalStatus: formData.MeritalStatus,
                SpouseName: formData.MeritalStatus === 1 ? formData.SpouseName : '',
                BloodGroup: formData.BloodGroup,
                ExtensionNumber: formData.ExtensionNumber,
                LandlineNumber: formData.LandlineNumber,
                NoticePeriod: formData.NoticePeriod,  // not on fron+tened
                AccountType: formData.AccountType,
                BankName: formData.BankName,
                BeneficiaryName: formData.BeneficiaryName,
                BankAccountNumber: formData.BankAccountNumber,
                ConfirmBankAccountNumber: formData.ConfirmBankAccountNumber,
                IfscCode: formData.IfscCode,
                EmergencyContactDetails: [
                    {
                        contactPersonName: formData.contactPerson1,
                        mobileNumber: formData.mobile1,
                        contactRelation: formData.contactRelation1,
                        contactAddress: formData.contactAddres1
                    },
                    {
                        contactPersonName: formData.contactPerson2,
                        mobileNumber: formData.mobile2,
                        contactRelation: formData.contactRelation2,
                        contactAddress: formData.contactAddres2
                    }

                ],

                PermanentAddress: {
                    //Country: formData.Country,                
                    /* State: formData.state,*/
                    CityId: formData.city,
                    Address1: formData.address1,
                    Address2: formData.address2 || null,
                    Address3: formData.address3 || null,
                    pinCode: formData.pinCode || null,


                },
                CurrentAddress: {
                    //Country: formData.SameAsCurrentAddress ? formData.Country : formData.currentcountry,
                    //State: formData.SameAsCurrentAddress ? formData.state : formData.currentState,
                    CityId: formData.SameAsCurrentAddress ? formData.city : formData.currentCity,
                    Address1: formData.SameAsCurrentAddress ? formData.address1 : formData.currentAddress,
                    Address2: formData.SameAsCurrentAddress ? formData.address2 || null : formData.currentAddress2 || null,
                    Address3: formData.SameAsCurrentAddress ? formData.address3 || null : formData.currentAddress3 || null,
                    pinCode: formData.SameAsCurrentAddress ? formData.pinCode || null : formData.currentpinCode || null,

                },

                EmploymentDetails: {
                    ReportingTo: formData.ReportingTo,
                    Manager: formData.Manager,
                    DateOfJoining: formData.DateOfJoining,
                    ConfirmationDate: formData.ConfirmationDate
                },
                SalaryDetails: {
                    BasicSalary: formData.BasicSalary,
                    DA: formData.DA,
                    HRA: formData.HRA,
                    PFNumber: formData.PFNumber,
                    PFPercentage: formData.PFPercentage,
                    EsicNumber: formData.EsicNumber,
                    EsicPercentage: formData.EsicPercentage,
                    ConveyanceAllowance: formData.ConveyanceAllowance,
                    PhoneAllowance: formData.PhoneAllowance,
                    OtherAllowance: formData.OtherAllowance,
                    CTC: formData.CTC,
                    PFEmployee: formData.PFEmployee,
                    PFEmployer: formData.PFEmployer,
                    VPF: formData.VPF,
                },
                Assets: formData.assets
            },
            ImageFile: formData.ImageFile,
            SignatureFile: formData.SignatureFile,
            EducationAndQualificationList: formData.EducationQualification,
            Documents: formData.documents
        }

        // if (formData.isPanAvailable === 1) data.vendor.panNumber = vendorForm.panNumber


        return data;

    })();




    const handleFormChange = (field, value) => {
        console.log('formdata', formData)

        let resetField = {}

        if (field.id === 'BranchId') {
            // resetField = formValueReseter([ "DivisionId","DepartmentId"]);
            let filterDivisionItem = []
            employeeListItems.branchList.map((res) => {
                if (res.id === value) {
                    res?.divisionList?.map((res) => {
                        filterDivisionItem.push({
                            value: res.id,
                            name: res.name
                        })
                    })
                }
            })
            setDivisionItems(filterDivisionItem)
        }

        if (field.id === 'DivisionId') {
            let filterDepartmentItem = []
            employeeListItems.branchList.map((res) => {
                if (res.id === formData.BranchId) {
                    res?.divisionList?.map((res) => {
                        if (res.id === value) {
                            res?.departmentList.map((res) => {
                                filterDepartmentItem.push({
                                    value: res.id,
                                    name: res.name
                                })
                            })
                        }

                    })
                }
            })
            setDepartmentItems(filterDepartmentItem)
        }

        if (field.id === 'DepartmentId') {
            let subDepartmentListItem = []
            let designationListItem = []
            employeeListItems.branchList.map((res) => {
                if (res.id === formData.BranchId) {
                    res?.divisionList?.map((res) => {
                        if (res.id === formData.DivisionId) {
                            res?.departmentList.map((res) => {
                                if (res.id === value) {
                                    res.subDepartmentList?.map((res) => {
                                        subDepartmentListItem.push({
                                            value: res.key,
                                            name: res.value
                                        })
                                    })

                                    res.designationList?.map((res) => {
                                        designationListItem.push({
                                            value: res.key,
                                            name: res.value
                                        })
                                    })
                                }
                            })
                        }

                    })
                }
            })
            setSubDepartmentItems(subDepartmentListItem)
            // setDesignationItems(designationListItem)
        }


        //if (field.id === 'Country') {
        //    // resetField = formValueReseter(["state", "city"]);

        //    let stateItem = [];
        //    employeeListItems?.countryList?.map((res) => {
        //        if (res.id === value) {

        //            res.stateCityList.map(res => {
        //                stateItem.push({
        //                    value: res.id,
        //                    name: res.name
        //                })
        //            })

        //            setStateItem(stateItem)
        //        }
        //    })
        //}

        //if (field.id === 'currentcountry') {
        //    let stateItem = [];
        //    employeeListItems?.countryList?.map((res) => {
        //        if (res.id === value) {

        //            res?.stateCityList?.map(res => {
        //                stateItem.push({
        //                    value: res.id,
        //                    name: res.name
        //                })
        //            })

        //            setCurrStateItem(stateItem)
        //        }
        //    })
        //}




        //if (field.id === 'state') {


        //    let cityItem = [];
        //    employeeListItems?.countryList?.map((res) => {
        //        if (res.id === formData.Country) {
        //            res.stateCityList.map(res => {
        //                if (res.id === value) {
        //                    res.cityList.map(res => {
        //                        cityItem.push({
        //                            value: res.key,
        //                            name: res.value
        //                        })
        //                    })
        //                }

        //            })

        //        }
        //    })
        //    setCityItem(cityItem)
        //}


        //if (field.id === 'currentState') {

        //    let cityItem = [];

        //    employeeListItems?.countryList?.map((res) => {
        //        if (res.id === formData.Country) {
        //            res.stateCityList.map(res => {
        //                if (res.id === value) {
        //                    res.cityList.map(res => {
        //                        cityItem.push({
        //                            value: res.key,
        //                            name: res.value
        //                        })
        //                    })
        //                }

        //            })

        //        }
        //    })
        //    setCurrCityItem(cityItem)
        //}




        if (field.id === 'ConfirmBankAccountNumber' && formData?.BankAccountNumber) {
            setFormData({
                ...formData,
                [field.id]: value,
                [field.id + 'error']: formData?.BankAccountNumber !== value ? true : false,

            });
        }
        else {

            setFormData({
                ...formData,
                ...resetField,
                [field.id]: value,
                [field.id + 'error']: !value,

            });
        }
    };



    const handleSubmit = async (event) => {

        dispatch({ type: "LOADER", payload: true });


        function hasUndefinedValues(obj) {

            let changes = {}; // Temporary object to accumulate changes

            // Check if any value in the object is undefined
            Object.entries(obj).forEach(([key, value]) => {
                if ((key === 'SpouseName' && formData.MeritalStatus !== 1) ||
                    (key === 'DateOfAnneversary' && formData.MeritalStatus !== 1) ||
                    (key === 'ReferredBy') ||
                    (key === 'PanNumber') ||
                    key === 'PersonalEmail' ||
                    key === 'ExtensionNumber' ||
                    key === 'LandlineNumber'
                ) {
                    return false
                }
                else if (!value) {
                    changes[key] = value;
                    changes[key + 'error'] = !value;
                }
            });
            console.log('asdfghjkl', changes)
            return changes;
        }

        // permanent address validation
        function PermanentAddressUndefinedValues(obj) {
            let changes = {}; // Temporary object to accumulate changes

            // Check if any value in the object is undefined
            Object.entries(obj).forEach(([key, value], index) => {

                if (index === 0 && !value) {
                    changes['cityerror'] = !value;
                }

                else if (index === 1 && !value) {
                    changes['address1error'] = !value;
                }
                else if (index === 4 && !value) {
                    changes['pinCodeerror'] = !value;
                }
                else {
                    return false
                }

            });
            console.log(changes)
            return changes;
        }

        // current address validation
        function CurrentAddressUndefinedValues(obj) {

            let changes = {}; // Temporary object to accumulate changes

            // Check if any value in the object is undefined
            Object.entries(obj).forEach(([key, value], index) => {

                //if (index === 0 && !value) {
                //    changes['currentcountryerror'] = !value;
                //}
                //else if (index === 1 && !value) {
                //    changes['currentStateerror'] = !value;
                //}
                if (index === 0 && !value) {
                    changes['currentCityerror'] = !value;
                }
                else if (index === 1 && !value) {
                    changes['currentAddresserror'] = !value;
                }
                else if (index === 4 && !value) {
                    changes['currentpinCodeerror'] = !value;
                }
                else {
                    return false
                }

            });
            console.log(changes)
            return changes;
        }

        // EmergencyContact validation
        function EmergencyContactUndefinedValues(array) {
            let changes = {}; // Temporary object to accumulate changes
            array.map((obj, arrIndex) => {
                // Check if any value in the object is undefined
                Object.entries(obj).forEach(([key, value], index) => {
                    if (arrIndex === 0 && index === 0 && !value) {
                        changes['contactPerson1'] = value;
                        changes['contactPerson1error'] = !value;
                    }
                    else if (arrIndex === 0 && index === 1 && !value) {
                        changes['mobile1'] = value;
                        changes['mobile1error'] = !value;
                    }
                    else if (arrIndex === 0 && index === 2 && !value) {
                        changes['contactRelation1'] = value;
                        changes['contactRelation1error'] = !value;
                    }
                    else if (arrIndex === 0 && index === 3 && !value) {
                        changes['contactAddres1'] = value;
                        changes['contactAddres1error'] = !value;
                    }
                    else {
                        return false
                    }

                });
            })


            console.log(changes)
            return changes;
        }

        // Education & Qualification
        function validateEducationAndQualificationList(educationList) {
            let changes = {};

            educationList.map((obj, index) => {
                if (typeof obj === 'object') {
                    Object.entries(obj).forEach(([key, value]) => {
                        if (key === "educationAndQualification" && typeof value === 'object') {
                            Object.entries(value).forEach(([subKey, subValue]) => {
                                if (!subValue && (subKey === 'qualification' || subKey === 'schoolUniversity' || subKey === 'passingYear')) {
                                    changes[subKey + index] = !subValue;
                                }
                            });
                        }
                    });
                }
            });

            return changes;
        }


        // Asset Details check
        const AssetDetailsUndefinedValue = (array) => {
            let changes = {};

            array?.map((obj, index) => {
                Object.entries(obj).forEach(([key, value]) => {
                    if (!value && (key === 'assetId' || key === "assetType")) {
                        changes[key + index] = !value;
                    }
                });
            })
            console.log('AssetDetailsUndefinedValue changes', changes)

            return changes;
        };

        const documentsDetailsUndefined = (documents) => {
            ;
            let changes = {}
            documents?.map((res, key) => {
                if (res.documentType === "adhaarcard" && !res.file) {
                    setAdharVerif(true)
                    changes["adhaarcard"] = true
                }
                if (res.documentType === "resume" && !res.file) {
                    setResumeVerify(true)
                    changes["resume"] = true
                }
                if (res.documentType === "pancard" && !res.file) {
                    setPanVerify(true)
                    changes["pancard"] = true
                }

                if (res.documentType === "relivingletter" && !res.file && createEmpRequestData.Employee.TradeExperience !== 1) {
                    setRelievLetterVerify(true)
                    changes["relivingletter"] = true
                }

                if (res.documentType === "joiningagreement" && !res.file) {
                    setJoinAgreeVerify(true)
                    changes["joiningagreement"] = true
                }
                if (res.documentType === "bankstatement" && !res.file && createEmpRequestData.Employee.TradeExperience !== 1) {
                    setSalarySlipVerify(true)
                    changes["bankstatement"] = true
                }
                if (res.documentType === "appionmentlatter" && !res.file) {
                    setAppointLetterVerify(true)
                    changes["appionmentlatter"] = true
                }
                if (res.documentType === "offerlater" && !res.file) {
                    setOfferLetterVerify(true)
                    changes["offerlater"] = true
                }

                if (res.documentType === "employeeform" && !res.file) {
                    setEmpFormVerif(true)
                    changes["employeeform"] = true
                }
            })
            return changes;
        }



        // Example usage:
        const createEmpRequestDataChanges = hasUndefinedValues(createEmpRequestData)
        const employeeChanges = hasUndefinedValues(createEmpRequestData.Employee);
        const salaryDetailsChanges = hasUndefinedValues(createEmpRequestData.Employee.SalaryDetails);
        const employmentDetailsChanges = hasUndefinedValues(createEmpRequestData.Employee.EmploymentDetails);
        const PermanentAddressChanges = PermanentAddressUndefinedValues(createEmpRequestData.Employee.PermanentAddress);
        const CurrentAddressChanges = CurrentAddressUndefinedValues(createEmpRequestData.Employee.CurrentAddress);
        const EmergencyContactChanges = EmergencyContactUndefinedValues(createEmpRequestData.Employee.EmergencyContactDetails);
        const EduactionChanges = validateEducationAndQualificationList(createEmpRequestData.EducationAndQualificationList)
        const assetDetailsChanges = AssetDetailsUndefinedValue(createEmpRequestData.Employee?.Assets);
        const documentsDetailsChanges = documentsDetailsUndefined(formData?.documents)
        // Check if any undefined values were found in any object
        if (
            Object.keys(createEmpRequestDataChanges)?.length > 0 ||
            Object.keys(employeeChanges)?.length > 0 ||
            Object.keys(salaryDetailsChanges)?.length > 0 ||
            Object.keys(employmentDetailsChanges)?.length > 0 ||
            Object.keys(PermanentAddressChanges)?.length > 0 ||
            Object.keys(CurrentAddressChanges)?.length > 0 ||
            Object.keys(EmergencyContactChanges)?.length > 0 ||
            Object.keys(EduactionChanges)?.length > 0 ||
            Object.keys(assetDetailsChanges)?.length > 0 ||
            Object.keys(documentsDetailsChanges)?.length > 0 ||
            formData?.BankAccountNumber !== formData?.ConfirmBankAccountNumber
        ) {

            setAssetDetailsError(assetDetailsChanges);
            setEducationDetailsError(EduactionChanges)

            const ConfirmValidvalue = ConfirmValid()
            function ConfirmValid() {
                let itemObj = {}
                if (formData?.BankAccountNumber !== formData?.ConfirmBankAccountNumber) {
                    itemObj['ConfirmBankAccountNumbererror'] = true;
                }
                return itemObj
            }

            // Update the form state with accumulated changes
            setFormData(prevFormData => ({
                ...prevFormData,
                ...employeeChanges,
                ...salaryDetailsChanges,
                ...employmentDetailsChanges,
                ...createEmpRequestDataChanges,
                ...PermanentAddressChanges,
                ...CurrentAddressChanges,
                ...EmergencyContactChanges,
                ...ConfirmValidvalue

            }));

            dispatch({ type: "LOADER", payload: false })

        }
        else {


            const formData = formatDataToFormData(createEmpRequestData);

            PostCaller({
                API: PostEmployeeAdd,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: formData,
                contenttype: true,
                callback: () => {
                    navigate(hremployelist)
                },

            })



        }
    };

    const requestData = {
        searchKey: '',
        sortKey: '',
        sortDirection: '',
        itemsPerPage: 100,
        page: 1,
    };


    const fatch_EmployeeMasterData = () => {
        axios.get(GetEmployeeMasterData(), { headers })
            .then((response) => {
                console.log('employeeData', response.data.data)
                setEmployeeListItems(response?.data?.data)

                const branchdata = response?.data?.data?.branchList.map((item) => ({
                    value: item?.id,
                    name: item?.name
                }))
                setBranchItems(branchdata)

                const countryList = response?.data?.data?.countryList.map((item) => ({
                    value: item?.id,
                    name: item?.name,

                }))
                setCountryItem(countryList)

                const reportingTo = response?.data?.data?.employeeList.map((item) => ({
                    value: item.key,
                    name: item.value,

                }))

                setEmployementReportingToItem(reportingTo);
                setEmployementManagerItem(reportingTo);
            })
    }


    // Country item set
    const set_CityItemData = () => {
        GetCaller({
            API: PostMasterCity,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item?.id,
                    name: item?.name
                }))
                setCityItem(newCityItemData);
            }

        })

    }
    const set_City_currentItemData = () => {
        let cityItem = [];
        GetCaller({
            API: PostMasterCity,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item?.id,
                    name: item?.name
                }))
                setCurrCityItem(newCityItemData);
            }

        })

    }

    useEffect(() => {
        fatch_EmployeeMasterData()
        set_CityItemData()
        set_City_currentItemData()
    }, [])
    console.log("formdata", formData)

    const fieldData = [
        {
            title: "Company Details",
            data: [
                {

                    id: 'BranchId',
                    grid: "col-md-2",
                    label: 'Branch',
                    type: 'select',
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Branch required',
                    item: branchItems

                },
                {
                    id: 'DivisionId',
                    grid: "col-md-2",
                    label: 'Division',
                    type: 'select',
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Division required',
                    item: divisionItems
                },
                {
                    id: 'DepartmentId',
                    grid: "col-md-2",
                    label: 'Department',
                    type: 'select',
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Department required',
                    item: departmentItems
                },
                {


                    id: 'SubDepartmentId',
                    grid: "col-md-2",
                    label: 'Sub-Department',
                    type: 'select',
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Sub-Department required',
                    item: subDepartmentItems
                },
                {
                    id: 'DesignationId',
                    grid: "col-md-2",
                    label: 'Designation',
                    type: 'select',
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Designation required',
                    item: designationItem
                },


                {
                    id: 'ShiftTiming',
                    grid: "col-md-2",
                    label: 'Shift Timing',
                    type: "time",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Shift required',


                },
                {
                    id: "TradeExperience",
                    grid: "col-md-2",
                    label: 'Experience in Trade',
                    type: "select",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Experience required',
                    item: TradeExperience
                },
                {
                    id: 'NoticePeriod',
                    grid: "col-md-2",
                    label: "Notice Period ",
                    type: "select",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'NoticePeriod Date',
                    item: EmployeeNoticePeriod
                },
                {
                    id: 'ReferredBy',
                    grid: "col-md-2",
                    label: 'Referred By',
                    type: 'text',
                    value: '',
                    required: false,
                    error: true,
                    helperText: 'Referred required',
                },
            ]
        },

        {

            title: "Personal Details",
            row: true,
            data: [
                {
                    id: 'FirstName',
                    label: 'First Name',
                    type: "text",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'FirstName required',
                },
                {
                    id: 'LastName',
                    label: 'Last Name',
                    type: "text",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'LastName required',
                },
                {
                    id: "DateOfBirth",
                    label: 'Date Of Birth ',
                    type: "date",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Date Of Birth required',
                },
                {
                    id: "Gender",
                    label: 'Gender',
                    type: "gender",
                    grid: 'col-md-3',
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Gender required',
                },

                {
                    id: "FatherOrHusbandName",
                    label: 'Father/Husband Name',
                    type: "text",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Father/Husband Name required',
                },
                {
                    id: "MotherName",
                    label: 'Mother Name',
                    type: "text",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Mother Name required',
                },


                {
                    id: "MobileNumber",
                    label: 'Mobile No',
                    type: "number",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Mobile Number required',
                },
                {
                    id: "OfficialEmail",
                    label: 'Email (Official)',
                    type: "email",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Email required',
                },
                {
                    id: "PersonalEmail",
                    label: 'Email (Personal)',
                    type: "email",
                    value: '',
                    required: false,
                    error: true,
                    helperText: 'Email required',
                },
                {
                    id: "MeritalStatus",
                    label: 'Marital Status',
                    type: "select",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Marital Status required',
                    item: MeritalStatus
                },
                {
                    id: "BloodGroup",
                    label: 'Blood Group',
                    type: "select",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Blood Group required',
                    item: BloodGroup
                },
                {
                    id: "AdharNumber",
                    label: 'Aadhar No',
                    type: "number",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Aadhar required',
                },
                {
                    id: "PanNumber",
                    label: 'Pan No',
                    type: "text",
                    value: '',
                    required: false,
                    error: true,
                    helperText: 'Pan required',

                },

                {
                    id: "DateOfAnneversary",
                    label: 'Anneversary Date ',
                    type: "date",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Anniversary Date required',
                },
                {
                    id: "SpouseName",
                    label: 'Spouse Name',
                    type: "text",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Spouse Name required',
                },

                {
                    id: "ExtensionNumber",
                    label: 'Extension No.',
                    type: "number",
                    value: '',
                    error: true,
                    helperText: 'Extension required',
                },
                {
                    id: "LandlineNumber",
                    label: 'Land Line No',
                    type: "number",
                    value: '',
                    error: true,
                    helperText: 'Land Line Number required',
                },
            ],
        },

        {
            title: "Emergency Contact Details",
            data: [
                {
                    id: "contactPerson1",
                    label: 'Contact Person Name',
                    type: "text",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Contact Person Name required',

                },
                {
                    id: "mobile1",
                    label: 'Mobile No',
                    type: "number",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Mobile required',
                },
                {
                    id: "contactRelation1",
                    label: 'Contact Relation',
                    type: "input",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Contact Relation required',
                },
                {
                    id: "contactAddres1",
                    label: 'Contact Address',
                    type: "input",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Address required',
                },
                {
                    id: "contactPerson2",
                    label: 'Contact Person Name',
                    type: "input",
                    value: '',
                    required: false,
                    error: true,
                    helperText: 'Contact Person Name required',
                },
                {
                    id: "mobile2",
                    label: 'Mobile No',
                    type: "input",
                    value: '',
                    required: false,
                    error: true,
                    helperText: 'Mobile required',
                },
                {
                    id: "contactRelation2",
                    label: 'Contact Relation',
                    type: "input",
                    value: '',
                    required: false,
                    error: true,
                    helperText: 'Contact Relation required',
                },
                {
                    id: "contactAddres2",
                    label: 'Contact Address',
                    type: "input",
                    value: '',
                    required: false,
                    error: true,
                    helperText: 'Address required',
                },

            ]
        },

        {
            title: "Permanent Address",
            data: [
                //{
                //    id: 'Country',
                //    label: 'Country ',
                //    type: "select",
                //    grid: "col-md-2",
                //    value: '',
                //    required: true,
                //    error: true,
                //    helperText: 'Country required',
                //    item: countryItem

                //},
                //{
                //    id: 'state',
                //    label: 'State ',
                //    type: "select",
                //    grid: "col-md-2",
                //    value: "",
                //    required: true,
                //    error: true,
                //    helperText: 'State required',
                //    item: stateItem
                //},
                {
                    id: 'city',
                    label: 'City ',
                    type: "select",
                    grid: "col-md-2",
                    value: "",
                    required: true,
                    error: true,
                    helperText: 'City required',
                    item: cityItem

                },
                {
                    id: 'address1',
                    label: 'Address 1',
                    type: "input",
                    grid: "col-md-2",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Address required',
                },
                {
                    id: 'address2',
                    label: 'Address 2',
                    type: "input",
                    grid: "col-md-2",
                    value: '',
                    required: false,
                    error: true,
                    helperText: 'Address required',
                },
                {
                    id: 'address3',
                    label: 'Address 3',
                    type: "input",
                    grid: "col-md-2",
                    value: '',
                    required: false,
                    error: true,
                    helperText: 'Address required',

                },

                {
                    id: 'pinCode',
                    label: 'Pin Code',
                    type: "input",
                    grid: "col-md-2",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'PinCode required',

                },




            ]
        },


        {
            title: "Current Address",
            sameas: true,
            labelid: 'current',

            data: !formData['SameAsCurrentAddress'] && [
                //{
                //    id: formData['SameAsCurrentAddress'] ? 'Country' : 'currentcountry',
                //    label: formData['SameAsCurrentAddress'] ? '' : 'Country ',
                //    type: "select",
                //    grid: "col-md-2",
                //    value: '',
                //    required: true,
                //    error: true,
                //    helperText: 'Country required',
                //    item: countryItem

                //},
                //{
                //    id: formData['SameAsCurrentAddress'] ? 'state' : 'currentState',
                //    label: formData['SameAsCurrentAddress'] ? '' : 'State',
                //    type: "select",
                //    grid: "col-md-2",
                //    value: '',
                //    required: true,
                //    error: true,
                //    helperText: 'State required',
                //    item: formData['SameAsCurrentAddress'] ? stateItem : currstateItem

                //},
                {
                    id: formData['SameAsCurrentAddress'] ? 'city' : 'currentCity',
                    label: formData['SameAsCurrentAddress'] ? '' : 'City',
                    type: "select",
                    grid: "col-md-2",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'City required',
                    item: formData['SameAsCurrentAddress'] ? cityItem : currcityItem

                },
                {
                    id: formData['SameAsCurrentAddress'] ? 'address1' : 'currentAddress',
                    label: formData['SameAsCurrentAddress'] ? '' : 'Address 1',
                    type: "input",
                    grid: "col-md-2",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Address required',
                },
                {
                    id: formData['SameAsCurrentAddress'] ? 'address2' : 'currentAddress2',
                    label: formData['SameAsCurrentAddress'] ? '' : 'Address 2',
                    type: "input",
                    grid: "col-md-2",
                    value: '',
                    required: false,
                    error: true,
                    helperText: 'Address required',

                },
                {

                    id: formData['SameAsCurrentAddress'] ? 'address3' : 'currentAddress3',
                    label: formData['SameAsCurrentAddress'] ? '' : 'Address 3',
                    type: "input",
                    grid: "col-md-2",
                    value: '',
                    required: false,
                    error: true,
                    helperText: 'Address required',

                },
                {

                    id: formData['SameAsCurrentAddress'] ? 'pinCode' : 'currentpinCode',
                    label: formData['SameAsCurrentAddress'] ? '' : 'PinCode',
                    type: "input",
                    grid: "col-md-2",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'PinCode required',

                },



            ]
        },
        {
            title: "Employment Details",
            data: [
                {
                    id: 'ReportingTo',
                    grid: "col-md-2",
                    label: "Reporting To",
                    type: "select",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Reporting required',
                    item: employementReportingToItem?.length === 0 ? [{ name: 'self', value: 1 }] : employementReportingToItem

                },
                {
                    id: 'Manager',
                    grid: "col-md-2",
                    label: "Manager",
                    type: "select",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Manager required',
                    item: employementManagerItem?.length === 0 ? [{ name: 'self', value: 1 }] : employementManagerItem

                }, {
                    id: 'DateOfJoining',
                    grid: "col-md-2",
                    label: "Joining Date",
                    type: "date",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Joining Date required',


                },
                {
                    id: 'ConfirmationDate',
                    grid: "col-md-2",
                    label: "Confirmation Date",
                    type: "date",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Confirmation Date',

                },

                {
                    id: 'employmentStatus',
                    grid: "col-md-2",
                    label: "Status",
                    type: "select",
                    value: '',
                    required: true,
                    error: true,
                    helperText: 'Status required',
                    item: EmploymentStatus

                }
            ]
        }




    ]




    function NewMultiAssets() {

        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };

        const [AssestsTypeItem, setAssestsTypeItem] = useState([])


        useEffect(() => {
            let asetCategory = [];
            employeeListItems?.assetCategoryAndAssetList?.map(res => {
                asetCategory?.push({
                    value: res?.id,
                    name: res?.name
                })
            })
            setAssestsTypeItem(asetCategory)
        }, [employeeListItems])


        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                assetType: '',
                assetIdItem: [],
                assetId: '',
                assignedDate: null,
                releasedDate: null
            },
        ]);

        const addInputField = () => {
            setInputFields([...inputFields, {
                key: generateUniqueKey(),
                assetType: '',
                assetIdItem: [],
                assetId: '',
                assignedDate: null,
                releasedDate: null
            }]);

        };

        const handleDuplicateInputChange = (key, field, value) => {

            const updatedInputFields = [...inputFields];
            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
            if (fieldToUpdate) {
                if (field === 'assetType') {
                    let asset = [];
                    employeeListItems.assetCategoryAndAssetList.map(res => {
                        if (res.id === value) {
                            res.assetList.map(res => {
                                asset.push({
                                    value: res.key,
                                    name: res.value
                                })
                            })
                        }

                    })

                    fieldToUpdate["assetType"] = value;
                    fieldToUpdate["assetIdItem"] = asset;

                }
                else {
                    fieldToUpdate[field] = value;

                }

                setInputFields(updatedInputFields);

                const data = []
                updatedInputFields && updatedInputFields.map((res) => {
                    return data.push(
                        {
                            assetType: res.assetType,
                            assetId: res.assetId,
                            assignedDate: res.assignedDate,
                            releasedDate: res.releasedDate
                        }
                    )
                })

                handleFormChange({ id: 'assets' }, data);

            }
        };

        const removeInputField = (key) => {

            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        assetType: res.assetType,
                        assetId: res.assetId,
                        assignedDate: res.assignedDate,
                        releasedDate: res.releasedDate
                    }
                )

            })
            handleFormChange({ id: 'assets' }, data);
        };

        return (
            <div className='hr-form-box'>

                <div className="cmd">
                    <h6 className="m-0 p-0">Assets</h6>
                    <Button sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                        <Add />
                    </Button>
                </div>

                {inputFields.map((field, key) => (
                    <div className='row' key={field.key}>
                        <div className="col-md-11">
                            <div className="row my-2">
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: 'assetType',
                                                label: 'Asset Category ',
                                                type: "select",
                                                value: field.assetType,
                                                required: true,
                                                error: !field.assetType && assetDetailsError['assetType' + key],
                                                item: AssestsTypeItem,
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'assetType', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        field={
                                            {
                                                id: 'assetId',
                                                label: 'Asset ',
                                                type: "select",
                                                value: field.assetId,
                                                required: true,
                                                error: !field.assetId && assetDetailsError['assetId' + key],
                                                item: field.assetIdItem,
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'assetId', value);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-3">
                                    <DateField
                                        field={{
                                            id: "assignedDate",
                                            label: "Assigned Date ",
                                            value: field?.assignedDate,
                                            required: field?.required,
                                            error: assetDetailsError['assignedDate' + key],
                                            onChange: (date) => handleDuplicateInputChange(field.key, 'assignedDate', date)

                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="col-md-1 cmd" style={{ justifyContent: "right" }}>
                            {inputFields.length > 1 && (
                                <Button
                                    sx={{ mt: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                    onClick={() => removeInputField(field.key)}
                                    variant='outlined'
                                >
                                    <Close />
                                </Button>
                            )}
                        </div>
                    </div>
                ))}

            </div>
        );
    }

    return (
        <>
            <form>
                <HrDynamicForm fields={fieldData} onChange={handleFormChange} formData={formData} />
                {NewMultiAssets()}
                <SalaryDetails onChange={handleFormChange} formData={formData} />
                <DuplicateInputDocs onChange={handleFormChange} educationDetailsError={educationDetailsError} />
                <BankDetails onChange={handleFormChange} formData={formData} />

                <FormOtherDetails
                    onChange={handleFormChange}
                    formdata={formData}

                    policeVerif={(e) => setPoliceVerif(e)}
                    adhaarVerif={(e) => setAdharVerif(e)}
                    EmpFormVerif={(e) => setEmpFormVerif(e)}
                    ResumeVerify={(e) => setResumeVerify(e)}
                    PanVerify={(e) => setPanVerify(e)}
                    RelievLetterVerify={(e) => setRelievLetterVerify(e)}
                    JoinAgreeVerify={(e) => setJoinAgreeVerify(e)}
                    OfferLetterVerify={(e) => setOfferLetterVerify(e)}
                    AppointLetterVerify={(e) => setAppointLetterVerify(e)}
                    SalarySlipVerify={(e) => setSalarySlipVerify(e)}

                    resumnVerification={ResumeVerify}
                    adhaarVerification={AdharVerif}
                    pancardVerification={PanVerify}
                    empRelievingLetter={RelievLetterVerify}
                    joinAgreeVerification={JoinAgreeVerify}
                    offerLetterVerification={OfferLetterVerify}
                    appointLetterVerification={AppointLetterVerify}
                    salarySlipVerification={SalarySlipVerify}
                    employeeVerification={EmpFormVerif}
                />

                <Button sx={{ m: 2, borderRadius: "10px" }} onClick={handleSubmit} variant="contained">Submit</Button>
            </form>

        </>
    )
}

export default HrEmployeeLIstForm;


export const HrDynamicForm = ({ fields, onChange, formData }) => {


    const handleFieldChange = (field, value) => {
        onChange(field, value)

    };

    const [marrielStatus, setMarrielStatus] = useState('s');
    const [uploadimgPersonal, setUploadimgPersonal] = useState(null);
    const [uploadSigPersonal, setUploadSigPersonal] = useState(null)
    const [curentAsPermanentAddress, setCurentAsPermanentAddress] = useState(false)
    const [alignment, setAlignment] = useState();

    const handleChange = (event, newAlignment, field) => {
        setAlignment(newAlignment);
        handleFieldChange(field, newAlignment); // Update the form data with the selected value and fieldId
    };

    const handleimageFieldChange = (id, file) => {
        handleFieldChange(id, file)
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (id.id === 'ImageFile') {
                    setUploadimgPersonal(reader.result);
                }

                if (id.id === "SignatureFile") {
                    setUploadSigPersonal(reader.result)
                }
            };
            reader.readAsDataURL(file);
        } else {
            setUploadimgPersonal(null);
            setUploadSigPersonal(null)
        }
    };

    return (
        <div>
            {fields &&
                fields.map((data, index) => (
                    <div className="hr-form-box " key={index}>
                        <div className="cmd">
                            <h6>{data.title}</h6>
                            {data.sameas ? <span className='ms-3'> <input type="checkbox" checked={curentAsPermanentAddress} onChange={() => { setCurentAsPermanentAddress(!curentAsPermanentAddress); handleFieldChange({ id: 'SameAsCurrentAddress' }, !curentAsPermanentAddress) }} /> Same as Permanent Address</span> : false}
                        </div>
                        <div className="row">
                            <div className={`${data.row ? 'col-md-10' : 'col-md-12'}`}>
                                <div className="row">
                                    {data.data &&
                                        data.data.map((field, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={` ${marrielStatus === 1 ? '' : (field.label === 'Spouse Name' || field.id === 'DateOfAnneversary' ? 'd-none' : '')} ${field.grid ? field.grid : 'col-md-3'} ${field.type === 'time' || field.type === 'date' ? 'my-0' : 'my-2'}`}
                                                >
                                                    {
                                                        field.type === 'select' ? (
                                                            <>



                                                                {/* data vlean not working on this */}
                                                                <Dropdown
                                                                    className="mt-0"
                                                                    field={
                                                                        {
                                                                            id: field.id,
                                                                            label: field.label,
                                                                            type: field.type,
                                                                            value: formData[field.id],
                                                                            required: field.required,
                                                                            error: formData[field.id + 'error'],
                                                                            item: field.item,
                                                                            onChange: (value) => {
                                                                                handleFieldChange(field, value)
                                                                                if (field.label === 'Marital Status*') {
                                                                                    setMarrielStatus(value)
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                />
                                                            </>



                                                        ) : field.type === 'gender' ? (
                                                            <ToggleButtonGroup
                                                                color="primary"
                                                                value={alignment}
                                                                exclusive
                                                                onChange={(event, newAlignment) => { handleChange(event, newAlignment, field); handleFieldChange(field, newAlignment) }}
                                                                aria-label="Gender"
                                                                size='small'
                                                                sx={{
                                                                    border: formData[field.id + 'error'] ? '1px solid red' : '',
                                                                }}
                                                            >
                                                                <ToggleButton value="male">Male</ToggleButton>
                                                                <ToggleButton value="female">Female</ToggleButton>
                                                                <ToggleButton value="transgender">Other</ToggleButton>

                                                            </ToggleButtonGroup>
                                                        ) : field.type === 'date' ?
                                                            <DateField
                                                                className={'mt-0'}
                                                                field={{
                                                                    id: field.id,
                                                                    label: field.label,
                                                                    value: formData[field.id],
                                                                    required: field?.required,
                                                                    error: formData[field.id + 'error'],
                                                                    onChange: (value) => { handleFieldChange(field, value) }
                                                                }}
                                                            />

                                                            : field.type === 'time' ? (

                                                                <TimeField
                                                                    className={'mt-0'}
                                                                    field={{
                                                                        id: field?.id,
                                                                        label: field?.label,
                                                                        value: formData[field.id],
                                                                        required: field?.required,
                                                                        error: formData[field.id + 'error'],
                                                                        onChange: (value) => { handleFieldChange(field, value) }
                                                                    }}
                                                                />
                                                            ) : (
                                                                <TextField
                                                                    size="small" fullWidth sx={{ m: 0, width: '100%', fontSize: '13px' }}

                                                                    id={field.id}
                                                                    label={field.label}
                                                                    value={formData[field.id] || ""}
                                                                    error={field.required && formData[field.id + 'error']}
                                                                    // helperText={formData[field.id + 'error'] && field.required && field.helperText}
                                                                    required={field.required}
                                                                    type={field.type}
                                                                    onChange={(e) => handleFieldChange(field, e.target.value)}
                                                                />
                                                            )
                                                    }
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            {
                                data.row ?
                                    <div className="col-md-2">
                                        <div className='hr-form-grid' >
                                            <div className='hr-img-upload' htmlFor="hr-form-img" style={{ border: formData['ImageFileerror'] ? '1px solid red' : '' }} >
                                                <label htmlFor="hr-form-img">
                                                    <Avatar src={uploadimgPersonal} style={{ background: formData['ImageFileerror'] ? 'red' : '' }} />

                                                    <p style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", color: formData['ImageFileerror'] ? 'red' : '' }}>{uploadimgPersonal || 'Upload Image'}</p>
                                                </label>
                                            </div>

                                            <input type="file" id='hr-form-img' onChange={(e) => { handleimageFieldChange({ id: 'ImageFile' }, e.target.files[0]); }} className='d-none' />
                                            <div>

                                            </div>
                                            <label htmlFor="hr-form-sign">
                                                <div className='hr-edit-upload' style={{ border: formData['SignatureFileerror'] ? '1px solid red' : '', height: "44px", backgroundImage: `url(${uploadSigPersonal})` }}>



                                                    <p style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", color: formData['SignatureFileerror'] ? 'red' : '' }}> {uploadSigPersonal ? null : 'Upload Signature'}</p>
                                                </div>
                                            </label>
                                            <input type="file" id='hr-form-sign' onChange={(e) => { handleimageFieldChange({ id: 'SignatureFile' }, e.target.files[0]) }} className='d-none' />

                                        </div>
                                    </div>
                                    : false
                            }

                        </div>

                    </div>
                ))
            }

        </div>
    );
};


const SalaryDetails = ({ onChange, formData }) => {

    const handleFieldChange = (fieldName, value) => {
        onChange(fieldName, value);
    };

    const EmploymentDetailsData = [



        {
            id: 'BasicSalary',
            label: "Basic Salary",
            type: "number",
            value: '',
            required: true,
            error: true,
            helperText: 'Basic Salary required',


        },
        {
            id: 'DA',
            label: "DA",
            type: "input",
            value: '',
            required: true,
            error: true,
            helperText: 'DA required',

        },
        {
            id: 'HRA',
            label: "HRA",
            type: "input",
            value: '',
            required: true,
            error: true,
            helperText: 'HRA required',
        },
        {
            id: 'PFNumber',
            label: "PF NO",
            type: "number",
            value: '',
            required: true,
            error: true,
            helperText: 'PF NO required',
        },
        {
            id: 'PFPercentage',
            label: "PF %",
            type: "number",
            value: '',
            required: true,
            error: true,
            helperText: 'PF% required',
        },
        {
            id: 'EsicNumber',
            label: "ESIC No",
            type: "number",
            value: '',
            required: true,
            error: true,
            helperText: 'ESIC No required',
        },
        {
            id: 'EsicPercentage',
            label: "ESIC %",
            type: "number",
            value: '',
            required: true,
            error: true,
            helperText: 'ESIC required',
        },
        {
            id: 'CTC',
            label: "CTC",
            type: "number",
            value: '',
            required: true,
            error: true,
            helperText: 'CTC required',
        },
        {
            id: 'PFEmployee',
            label: "PFEmployee",
            type: "number",
            value: '',
            required: true,
            error: true,
            helperText: 'PFEmployee required',
        },
        {
            id: 'PFEmployer',
            label: "PFEmployer",
            type: "number",
            value: '',
            required: true,
            error: true,
            helperText: 'PFEmployer required',
        },
        {
            id: 'VPF',
            label: "VPF",
            type: "number",
            value: '',
            required: true,
            error: true,
            helperText: 'VPF required',
        },
        {
            id: 'ConveyanceAllowance',
            label: "Conveyance Allowance",
            type: "input",
            value: '',
            required: true,
            error: true,
            helperText: 'Conveyance Allowance required',
        },
        {
            id: 'PhoneAllowance',
            label: "Phone Allowance",
            type: "input",
            value: '',
            required: true,
            error: true,
            helperText: 'Phone Allowance required',

        },
        {
            id: 'OtherAllowance',
            label: "Other Allowance",
            type: "input",
            value: '',
            required: true,
            error: true,
            helperText: 'Other Allowance required',
        },

    ]
    return (
        <>
            <div className="hr-form-box">
                <h6>Salary Details</h6>

                <div className="row">
                    {
                        EmploymentDetailsData.map((field, index) => {
                            return <div className={`col-md-2 ${field.type === 'time' || field.type === 'date' ? 'my-0' : 'my-2'}`} key={index}>
                                <TextField
                                    size="small" fullWidth sx={{ m: 0, width: '100%', fontSize: '13px' }}
                                    id={field.id}
                                    label={field.label}
                                    value={formData[field.id]}
                                    error={field.required && formData[field.id + 'error']}
                                    // helperText={formData[field.id + 'error'] && field.required && field.helperText}
                                    required={field.required}
                                    type={field.type}
                                    onChange={(e) => handleFieldChange(field, e.target.value)}
                                />
                            </div>
                        })
                    }

                </div>
            </div>
        </>
    )
}

const BankDetails = ({ onChange, formData }) => {
    const handleFieldChange = (fieldName, value) => {
        onChange(fieldName, value);
    };

    const EmploymentDetailsData = [

        {
            id: 'AccountType',
            label: "Type Of Account ",
            type: "select",
            value: '',
            required: true,
            error: true,
            helperText: 'Type Of Account required',
            item: BankAccountType

        },

        {
            id: 'BankName',
            label: "Bank Name",
            type: "input",
            value: '',
            required: true,
            error: true,
            helperText: 'Bank Name required',
            inputtype: "text"
        },

        {
            id: 'BeneficiaryName',
            label: "Beneficiary Name",
            type: "input",
            value: '',
            required: true,
            error: true,
            helperText: 'Beneficiary Name required',
            inputtype: "text"
        },

        {
            id: 'BankAccountNumber',
            label: "Bank A/C No",
            type: "number",
            value: '',
            required: true,
            error: true,
            helperText: 'Bank A/C No required',
            inputtype: "number"
        },

        {
            id: 'ConfirmBankAccountNumber',
            label: "Confirm Bank A/C No",
            type: "number",
            value: '',
            required: true,
            error: true,
            helperText: 'Confirm Bank A/C Not Matched',
            inputtype: "number"
        },
        {
            id: 'IfscCode',
            label: "IFSC Code",
            type: "input",
            value: '',
            required: true,
            error: true,
            helperText: 'IFSC Code required',
            inputtype: "text"
        },


    ]
    return (
        <>
            <div className="hr-form-box">
                <h6>Bank Details</h6>

                <div className="row">
                    {
                        EmploymentDetailsData?.map((field, index) => {
                            return <div className={`col-md-2 ${field.type === 'time' || field.type === 'date' ? 'my-0' : 'my-2'}`} key={index}>
                                {
                                    field.type === 'select' ? (
                                        <Dropdown
                                            className={'mt-0'}
                                            field={{
                                                name: field.label,
                                                label: field.label,
                                                required: field.required,
                                                value: formData[field.id],
                                                item: field?.item,
                                                onChange: (value) => {
                                                    handleFieldChange(field, value);
                                                }
                                            }}
                                        />

                                    ) : field.type === 'date' ?
                                        <DateField
                                            className={'mt-0'}
                                            field={{
                                                id: field?.id,
                                                label: field?.label,
                                                value: formData[field?.id],
                                                required: field?.required,
                                                error: formData[field.id + 'error'],
                                                onChange: (value) => handleFieldChange(field.label, value)
                                            }}
                                        />
                                        : (
                                            <TextField
                                                size="small" fullWidth sx={{ m: 0, width: '100%', fontSize: '13px' }}
                                                id={field.id}
                                                label={field.label}
                                                value={formData[field.id]}
                                                error={field.required && formData[field.id + 'error']}
                                                // helperText={formData[field.id + 'error'] && field.required && field.helperText}
                                                required={field.required}
                                                type={field.type}
                                                onChange={(e) => handleFieldChange(field, e.target.value)}
                                            />
                                        )
                                }
                            </div>
                        })
                    }

                </div>
            </div>
        </>
    )
}

function DuplicateInputDocs({ onChange, educationDetailsError }) {
    console.log('educationDetailsError', educationDetailsError)
    const generateUniqueKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const [inputFields, setInputFields] = useState([
        {
            key: generateUniqueKey(), // Add a unique key for each input field
            qualification: '',
            schoolUniversity: '',
            passingYear: '',
            attachDocs: '',
        },
    ]);

    const addInputField = () => {
        setInputFields([...inputFields, { key: generateUniqueKey(), qualification: '', schoolUniversity: '', passingYear: '', attachDocs: '' }]);
    };

    const handleInputChange = (key, field, value) => {
        const updatedInputFields = [...inputFields];
        const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
        if (fieldToUpdate) {
            fieldToUpdate[field] = value;
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        educationAndQualification: {
                            qualification: res.qualification,
                            schoolUniversity: res.schoolUniversity,
                            passingYear: res.passingYear,
                            document: "string"
                        },
                        file: res.attachDocs
                    }
                )

            })
            onChange({ id: 'EducationQualification' }, data);
        }
    };

    const removeInputField = (key) => {
        const updatedInputFields = inputFields.filter((field) => field.key !== key);
        setInputFields(updatedInputFields);
        const data = []
        updatedInputFields && updatedInputFields.map((res) => {
            return data.push(
                {
                    educationAndQualification: {
                        qualification: res.qualification,
                        schoolUniversity: res.schoolUniversity,
                        passingYear: res.passingYear,
                        document: "string"
                    },
                    file: res.attachDocs
                }
            )

        })
        onChange({ id: 'EducationQualification' }, data);
    };

    return (
        <div className='hr-form-box'>
            <div className="cmd">
                <h6>Education & Qualification</h6>
                <Button sx={{ my: 2, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                    <Add />
                </Button>
            </div>

            {inputFields.map((field, key) => (
                <div className='row' key={field.key}>
                    <div className="col-md-11">
                        <div className="row my-2">
                            <div className="col-md-3">
                                <Dropdown
                                    className="mt-0"
                                    field={
                                        {
                                            id: "qualification",
                                            label: "Qualification",
                                            value: field.qualification,
                                            required: true,
                                            error: !field.qualification && educationDetailsError['qualification' + key],
                                            helperText: '',
                                            item: EmployeeQualification,
                                            onChange: (value) => {
                                                handleInputChange(field.key, 'qualification', value);
                                            }
                                        }
                                    }

                                />

                            </div>
                            <div className="col-md-3">
                                <TextField
                                    fullWidth
                                    label={'School/University*'}
                                    sx={{ m: 0, width: '100%', fontSize: '13px' }}
                                    size="small"
                                    value={field.schoolUniversity}
                                    error={!field.schoolUniversity && educationDetailsError['schoolUniversity' + key]}

                                    onChange={(e) => {
                                        handleInputChange(field.key, 'schoolUniversity', e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                                <TextField
                                    fullWidth
                                    label={'Passing Year*'}
                                    sx={{ m: 0, width: '100%', fontSize: '13px' }}
                                    size="small"
                                    value={field.passingYear}
                                    error={!field.passingYear && educationDetailsError['passingYear' + key]}

                                    onChange={(e) => {
                                        handleInputChange(field.key, 'passingYear', e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-md-3">
                                <label htmlFor={`attach-edu-${field.key}`} className='attach-btn'>
                                    <p className='mb-0' style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {<AttachFileIcon />} {field?.attachDocs.name || 'Attach Docs '}</p>
                                </label>
                                <input type="file" id={`attach-edu-${field.key}`} name={`attach-edu-${field.key}`} className="d-none"
                                    onChange={(e) => {
                                        handleInputChange(field.key, 'attachDocs', e.target.files[0]);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1 cmd" style={{ justifyContent: "right" }}>
                        {inputFields.length > 1 && (
                            <Button className='closeBtn'
                                sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                onClick={() => removeInputField(field.key)}
                                variant='contained'
                            >
                                <Close />
                            </Button>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

const FormOtherDetails = ({
    onChange,
    formdata,

    employeeVerification,
    appointLetterVerification,
    salarySlipVerification,
    offerLetterVerification,
    joinAgreeVerification,
    adhaarVerification,
    resumnVerification,
    AppointLetterVerify,
    pancardVerification,

    EmpFormVerif,
    empRelievingLetter,
    JoinAgreeVerify,
    SalarySlipVerify,
    OfferLetterVerify,
    PanVerify,
    RelievLetterVerify,
    ResumeVerify,
    adhaarVerif,
}) => {

    const [otherDetailsData, setOtherDetailsData] = useState(
        [
            {
                documentType: "adhaarcard",
                file: ""
            },
            {
                documentType: "resume",
                file: ""
            },

            {
                documentType: "pancard",
                file: ""
            },

            {
                documentType: "relivingletter",
                file: ""
            },

            {
                documentType: "joiningagreement",
                file: ""
            },
            {
                documentType: "offerlater",
                file: ""
            },


            {
                documentType: "bankstatement",
                file: ""
            },

            {
                documentType: "appionmentlatter",
                file: ""
            },

            {
                documentType: "employeeform",
                file: ""
            },
        ]
    )
    const [otherDetailsCheckValue, setOtherDetailsCheckValue] = useState({})

    useEffect(() => {
        let checkValueData = {};

        otherDetailsData?.forEach((res) => {
            checkValueData[res?.documentType] = res?.file;
        });

        setOtherDetailsCheckValue(checkValueData);
    }, [otherDetailsData]);


    const handleFieldChange = (fieldName, value) => {

        if (fieldName.id === 'employeeform') {
            if (value) {
                EmpFormVerif(false)
            } else {
                EmpFormVerif(true)
            }

        }

        if (fieldName.id === 'adhaarcard') {
            if (value) {
                adhaarVerif(false)
            } else {
                adhaarVerif(true)
            }
        }

        if (fieldName.id === 'resume') {
            if (value) {
                ResumeVerify(false)
            } else {
                ResumeVerify(true)
            }
        }


        if (fieldName.id === 'joiningagreement') {
            if (value) {
                JoinAgreeVerify(false)
            } else {
                JoinAgreeVerify(true)
            }
        }

        if (fieldName.id === 'offerlater') {
            if (value) {
                OfferLetterVerify(false)
            } else {
                OfferLetterVerify(true)
            }
        }


        if (fieldName.id === 'relivingletter') {
            if (value) {
                RelievLetterVerify(false)
            } else {
                RelievLetterVerify(true)
            }
        }

        if (fieldName.id === 'pancard') {
            if (value) {
                PanVerify(false)
            } else {
                PanVerify(true)
            }
        }

        if (fieldName.id === 'bankstatement') {
            if (value) {
                SalarySlipVerify(false)
            } else {
                SalarySlipVerify(true)
            }
        }


        if (fieldName.id === 'appionmentlatter') {
            if (value) {
                AppointLetterVerify(false)
            } else {
                AppointLetterVerify(true)
            }
        }




        setOtherDetailsData((prevData) => {
            // If prevData is undefined, initialize it as an empty array
            if (!prevData) {
                prevData = [];
            }

            // Remove the item from the array if value is null and documentType already exists
            if (!value && (
                fieldName.id !== "adhaarcard" ||
                fieldName.id !== "resume" ||
                fieldName.id !== "pancard" ||
                fieldName.id !== "joiningagreement" ||
                fieldName.id !== "offerlater" ||
                fieldName.id !== "appionmentlatter" ||
                fieldName.id !== 'employeeform' ||
                (formdata.TradeExperience !== 1 && fieldName.id === 'relivingletter') ||
                (formdata.TradeExperience !== 1 && fieldName.id === 'bankstatement')
            )) {
                const updatedData = prevData.filter((item) => item.documentType !== fieldName.id);
                console.log('otherDetailsData', updatedData);
                onChange({ id: 'documents' }, updatedData);
                return updatedData;

            }

            // Update the file property if documentType already exists
            const updatedData = prevData.map((item) => {
                if (item.documentType === fieldName.id) {
                    return { ...item, file: value };
                } else {
                    return item;
                }
            });

            // If the documentType doesn't exist in any item, add a new object to the array
            if (!updatedData.some((item) => item.documentType === fieldName.id)) {
                updatedData.push({ documentType: fieldName.id, file: value });
            }

            let newData = []
            updatedData.map((item) => {
                if (!item.file && (
                    item.id !== "adhaarcard" ||
                    item.id !== "resume" ||
                    item.id !== "pancard" ||
                    item.id !== "joiningagreement" ||
                    item.id !== "offerlater" ||
                    item.id !== "appionmentlatter" ||
                    item.id !== 'employeeform' ||
                    (formdata.TradeExperience !== 1 && item.id === 'relivingletter') ||
                    (formdata.TradeExperience !== 1 && item.id === 'bankstatement')
                )) {

                } else {
                    newData.push({ documentType: item.documentType, file: item.file })
                }
            })

            console.log('otherDetailsData', newData);
            onChange({ id: 'documents' }, newData);
            // onChange({ id: 'documents' }, updatedData);

            return updatedData;
        });
    };


    return <>
        <div className="hr-form-box">
            <h6>Joining Formalities</h6>

            <div className="row">
                <div className="col-md-3 mt-3" style={{ color: pancardVerification ? 'red' : '' }}>
                    <AlertDialog
                        data={{
                            title: "Pan Card",
                            checked: !otherDetailsCheckValue['pancard'],
                            onChange: (value) => {
                                handleFieldChange({ id: 'pancard' }, value);
                            }
                        }}
                    />
                </div>
                <div className="col-md-3 mt-3" style={{ color: adhaarVerification ? 'red' : '' }}>
                    <AlertDialog
                        data={{
                            title: "Adhaar Card",
                            checked: !otherDetailsCheckValue['adhaarcard'],
                            onChange: (value) => {
                                handleFieldChange({ id: 'adhaarcard' }, value);
                            }
                        }}
                    />
                </div>
                <div className="col-md-3 mt-3">
                    <AlertDialog
                        data={{
                            title: "Police Verification",
                            checked: !otherDetailsCheckValue['policeverification'],
                            onChange: (value) => {
                                handleFieldChange({ id: 'policeverification' }, value);
                            }
                        }}
                    />
                </div>
                <div className="col-md-3 mt-3" style={{ color: employeeVerification ? 'red' : '' }}>
                    <AlertDialog
                        data={{
                            title: "Employee Form",
                            checked: !otherDetailsCheckValue['employeeform'],
                            onChange: (value) => {
                                handleFieldChange({ id: 'employeeform' }, value);
                            }
                        }}
                    />
                </div>

                <div className="col-md-3 mt-3" style={{ color: empRelievingLetter && formdata.TradeExperience !== 1 ? 'red' : '' }}>
                    <AlertDialog
                        data={{
                            title: "Employee Relieving Letter",
                            checked: !otherDetailsCheckValue['relivingletter'],
                            onChange: (value) => {
                                handleFieldChange({ id: 'relivingletter' }, value);
                            }
                        }}
                    />
                </div>
                <div className="col-md-3 mt-3" style={{ color: resumnVerification ? 'red' : '' }}>
                    <AlertDialog
                        data={{
                            title: "Resume",
                            checked: !otherDetailsCheckValue['resume'],
                            onChange: (value) => {
                                handleFieldChange({ id: 'resume' }, value);
                            }
                        }}
                    />
                </div>

                <div className="col-md-3 mt-3" style={{ color: joinAgreeVerification ? 'red' : '' }}>
                    <AlertDialog
                        data={{
                            title: "Joining Agreement",
                            checked: !otherDetailsCheckValue['joiningagreement'],
                            onChange: (value) => {
                                handleFieldChange({ id: 'joiningagreement' }, value);
                            }
                        }}
                    />
                </div>

                <div className="col-md-3 mt-3" style={{ color: offerLetterVerification ? 'red' : '' }}>
                    <AlertDialog
                        data={{
                            title: "Offer Letter",
                            checked: !otherDetailsCheckValue['offerlater'],
                            onChange: (value) => {
                                handleFieldChange({ id: 'offerlater' }, value);
                            }
                        }}
                    />
                </div>


                <div className="col-md-3 mt-3" style={{ color: appointLetterVerification ? 'red' : '' }}>
                    <AlertDialog
                        data={{
                            title: "Appointment Letter",
                            checked: !otherDetailsCheckValue['appionmentlatter'],
                            onChange: (value) => {
                                handleFieldChange({ id: 'appionmentlatter' }, value);
                            }
                        }}
                    />
                </div>

                <div className="col-md-3 mt-3" style={{ color: salarySlipVerification && formdata.TradeExperience !== 1 ? 'red' : '' }}>
                    <AlertDialog
                        data={{
                            title: "Bank Statement/Salary Slip",
                            checked: !otherDetailsCheckValue['bankstatement'],
                            onChange: (value) => {
                                handleFieldChange({ id: 'bankstatement' }, value);
                            }
                        }}
                    />
                </div>



            </div>

        </div>
    </>
}



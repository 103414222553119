import { useDispatch } from "react-redux"
import { GetHawbByPage } from "../../../services/constant"
import { useEffect, useState } from "react"
import MasterCallBypagination from "../../../services/masterpaginationConfig"
import GetCaller from "../../../services/apiServices"
import { MultiTable } from "../../component/multiTable"
import { createhawbform } from "../../../navigation/constant"
import { IconButton, Tooltip } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom"
const Hawb = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [hawbMasterData, setHawbMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetHawbByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item

        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);
    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            financialYear: res.financialYear,
            jobId: res.jobId,
            hawbNumber: res.hawbNumber,
            mawbNumber: res.mawbNumber,
            airportOfDeparture: res.airportOfDeparture,
            airportOfDestination: res.airportOfDestination

        }));
        setHawbMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false
        },
        {
            id: 'financialYear',
            label: 'Financial Year',
            numeric: false
        },
        {
            id: 'jobId',
            label: 'Job id',
            numeric: false
        },
        {
            id: 'hawbNumber',
            label: 'Hawb Number',
            numeric: false
        },
        {
            id: 'mawbNumber',
            label: 'Mawb Number',
            numeric: false
        },
        {
            id: 'airportOfDeparture',
            label: 'APOL',
            numeric: false
        },
        {
            id: 'airportOfDestination',
            label: 'APOD',
            numeric: false
        },

    ];
    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            // GetCaller({
            //     Api: UpadateMasterNetworkTypeStatus,
            //     dispatch: (e) => {
            //         dispatch({type:e.type , payload: e.payload})
            //     },
            //     item:data,
            //     callback:({response}) => {
            //         fatch_MasterDataBYPagination();
            //     }
            // });
        }
    }
    const TableTool = ({ }) => {
        return <>
            <Tooltip title={"Add Hawb"}>
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${createhawbform}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }

    return (
        <>
            <MultiTable
                data={hawbMasterData}
                columns={columns}
                title={"HAWB"}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )

}
export default Hawb

const HawbDrawer = ({ callAPI, editor, row }) => {


}
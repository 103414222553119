import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';

export default function AlertDialogBox({
    handleClose,
    handleSubmit,
    open,
    title,
    description,
    style,
    cancelButtonTitle,
    submitButtonTitle,
    maxWidth
}) {




    return (
        <React.Fragment>
            <Dialog
                // fullWidth={fullWidth}
                maxWidth={maxWidth ? maxWidth : 'sm'}
sx={{borderRadius:"20px"}}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle sx={style}>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={style}>
                        {description}
                    </DialogContentText>
                    <Box
                        noValidate
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            width: 'fit-content',
                        }}
                    >

                    </Box>
                </DialogContent>
                <DialogActions sx={style}>
                    {handleClose &&
                        <Button variant='outlined' onClick={handleClose}>{cancelButtonTitle ? cancelButtonTitle : 'Cancel'}</Button>
                    }
                    {
                        handleSubmit &&
                        <Button variant='contained' onClick={handleSubmit}>{submitButtonTitle ? submitButtonTitle : 'Submit'}</Button>
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

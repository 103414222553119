import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { LeadLogAction, LeadLogResponse } from '../../../services/modalData';

const steps = [
    {
        label: 'Follow Up Remainder',
        description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
    },
    {
        label: 'CTI Manual - UCID - 21121662',
        description:
            'An ad group contains one or more ads which target a shared set of keywords.',
    },
    {
        label: 'Follow Up Remainder',
        description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
    },
];

export function VerticalLinearSteppere() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (e) => {
        setActiveStep(e)
    };

    return (
        <Box sx={{ maxWidth: 400, }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={
                                <Typography variant="caption">You have an upcoming task</Typography>
                            }
                            onClick={() => handleNext(index)}
                        >
                            <div className="cmd">
                                <span>{step.label}</span>
                                <span style={{ fontSize: '12px' }}> Today 12:02 pm</span>


                            </div>
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>

                        </StepContent>
                    </Step>
                ))}
            </Stepper>

        </Box>
    );
}



export default function VerticalLinearStepper({ response }) {
    const [activeStep, setActiveStep] = React.useState(0);
    console.log('response', response)
    const handleNext = (e) => {
        setActiveStep(e)
    };

    return (
        <Box sx={{ maxWidth: 400, }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {response.map((step, index) => (
                    <Step key={index}>
                        <StepLabel
                            optional={
                                <Typography variant="caption">{LeadLogResponse[step.response - 1]?.name}</Typography>
                            }
                            onClick={() => handleNext(index)}
                        >
                            <div className="cmd">
                                <span>{LeadLogAction[step.action - 1]?.name}</span>
                                <span style={{ fontSize: '12px' }}>{step.nextFollowUp}</span>
                            </div>
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.comments}</Typography>

                        </StepContent>
                    </Step>
                ))}
            </Stepper>

            {/* <VerticalLinearSteppere /> */}

        </Box>
    );
}
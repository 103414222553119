import React, { useEffect, useState } from 'react';
import { columnConstant } from '../../constant/formconstant';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Dropdown, { InputField, InputFileField, TextAreafield } from '../../component/controls';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Tooltip } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { useDispatch } from 'react-redux';
import { PostAirExportBuyingingCharges, PostAirExportSellingCharges } from '../../../services/APIConstant/enquiryConstant';
import { GetAirlineMasterByPage, GetAirportMasterByPage, GetChargeHeadByPage, GetUnitMasterByPage, GetVendorMasterByPage, PostMasterCountry } from '../../../services/constant';
import { IntialStockProvider } from '../../../services/modalData';
import { BuyingQuotes } from './intialQuote/buyingQuote';
import { PricingSheet } from './intialQuote/pricingSheet';
import { SellingQuotes } from './intialQuote/sellingQuotes';


const CreateInitialQuote = () => {
    const dispatch = useDispatch();
    const [initialQuoteData, setInitialQuoteData] = useState({})
    const [value, setValue] = React.useState('2');

    // APi Item Data 
    const [airportItem, setAirportItem] = useState([]);
    const [countrycurrency, setCountrycurrency] = useState([]);
    const [unitItem, setUnitItem] = useState([]);
    const [vendorIATAItem, setVendorIATAItem] = useState([]);
    const [chargeHeadItem, setChargeHeadItem] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [openTable, setOpenTable] = useState(true)

    useEffect(() => {

        GetCaller({
            API: PostMasterCountry,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.currencyCode
                }))
                setCountrycurrency(newCountryItemData)
            }
        });

        GetCaller({
            API: GetAirlineMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const airportItem = data?.map(res => ({
                    value: res.id,
                    name: res.legalName,
                    gsaItem: res.airlineGSAs || []
                }))
                setAirportItem(airportItem)
            }
        })
        // Unit Items
        GetCaller({
            API: GetUnitMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const unit = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setUnitItem(unit)
            }
        })

        // Vendor IATa Agent Items
        GetCaller({
            API: GetVendorMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const IATA = data?.map(res => ({
                    value: res.id,
                    name: res.legalName
                }))
                setVendorIATAItem(IATA)
            }
        })

        // Charge head Agent Items
        GetCaller({
            API: GetChargeHeadByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const chargehead = data?.map(res => ({
                    value: res.id,
                    name: res.chargeName
                }))
                setChargeHeadItem(chargehead)
            }
        })


    }, [])

    useEffect(() => {
        let enquiryInitialQuoteData = JSON.parse(sessionStorage.getItem('enquiryInitialQuoteData'))
        if (enquiryInitialQuoteData) {
            setInitialQuoteData(enquiryInitialQuoteData)
        }
        // sessionStorage.removeItem('enquiryInitialQuoteData');
    }, [])

    return <>
        <h4>Create Initial Quote</h4>
        <div className="hr-form-box">

            <div className='Initial_Quote_head cmd'>
                <div><span>Enquiry Id</span> - {initialQuoteData.eid}</div>
                <div><span>A/C Ledger Name</span>- {initialQuoteData.accountLedgerName}</div>
                <div><span>Organization Name/Biz. Generator</span>- {initialQuoteData.bizGenerator}</div>
                <div>
                    <KeyboardArrowUpIcon onClick={() => setOpenTable(!openTable)} className={openTable ? 'rotate_up' : 'rotate_down'} />
                </div>
            </div>

            {
                openTable &&
                <div className='check-customer-table' style={{ textAlign: "left" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>{columnConstant.packages}</th>
                                <th>{initialQuoteData?.packages}</th>
                                <th>{columnConstant.grosswt}</th>
                                <th>{initialQuoteData?.grossWeight}</th>
                                <th>{columnConstant.airvolumeweightcbm}</th>
                                <th>{initialQuoteData?.airVolumeWeight}</th>
                                <th>{"Freight Services/Biz Category"}</th>
                                <th>{initialQuoteData?.freightServiceType} / {initialQuoteData?.bizCategoryType}</th>
                            </tr>
                            <tr>
                                <th>{columnConstant.airportofloading}</th>
                                <th>{initialQuoteData?.airportOfLoadingName}</th>
                                <th>{columnConstant.airportofdestination}</th>
                                <th>{initialQuoteData?.airportOfDestinationName}</th>
                                <th>{columnConstant.commodityhscode}</th>
                                <th>{initialQuoteData?.commodity}</th>
                                <th>{columnConstant.specialreqremarks}</th>
                                <th>{initialQuoteData?.remarks}</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            }

        </div>

        <div className='hr-form-box' style={{ position: "relative" }} >
            <div style={{ position: "absolute", top: 30, left: "50%" }} > <h5>AIR EXPORT</h5></div>
            <TabContext value={value}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Pricing Sheet" value="1" />
                        <Tab label="Buying Charges" value="2" />
                        <Tab label="Selling Charges" value="3" />
                    </TabList>


                </Box>

                <TabPanel value="1">
                    {PricingSheet({ initialQuoteData, airportItem, countrycurrency })}
                </TabPanel>

                <TabPanel value="2">
                    {BuyingQuotes({ initialQuoteData, airportItem, countrycurrency, unitItem, vendorIATAItem, chargeHeadItem })}
                </TabPanel>

                <TabPanel value="3">
                    {SellingQuotes({ initialQuoteData, airportItem, countrycurrency, unitItem, vendorIATAItem, chargeHeadItem })}
                </TabPanel>
            </TabContext>
        </div>

    </>

}

export default CreateInitialQuote;
export const columnConstant = {
    sno: "#",
    id: "ID",
    name: "Name",
    email: "Email",
    label: "Label",
    address1: "Address 1",
    ContactPerson: "Contact Person",
    action: "Action",
    createairline: 'Create Airline',
    editairline: "Edit Airline",
    bizinstitutetype: "Biz Institute Type",
    typeofcompanyspecifyothertype: "Type of company(Specify other type)",
    nameoncheque: 'Name On Cheque',
    panavailable: 'PAN Available',
    panNumber: 'Pan Number',
    tdsavailable: 'TDS Applicable',
    tdssection: "TDS Section",
    Code: "Code",
    LegalName: "Legal Name",
    AccountLedgerName: "A/c Ledger Name",
    awbStockRecorderLevel: 'AWB Stock Recorder Level',
    address2: "Address 2",
    address3: "Address 3",
    contactPerson: "Contact Person",
    country: "Country",
    city: "City",
    state: "State",
    zipPostCode: "Zip/Post Code",
    telephone: "Telephone",
    code: "Code",
    beneficiaryName: "Beneficiary Name",
    banName: "Bank Name",
    aCNo: "A/CNo.",
    bankCode: "Bank Code",
    swiftCode: "Swift Code",
    acgroupcode: "A/C Group Code",
    acgroup: "A/C Group",
    accountype: 'Account Type',
    accountledger: 'Account Ledger',
    subgroup: 'Sub Group',
    primarygroup: 'Primary Group',
    accountgroup: 'Account Group',
    action: "Action",
    updateaccountledger: "Edit Account Ledger",
    addaccountledger: "Add Account Ledger",
    updateacgroup: "Edit A/c Group",
    addacgroup: "Add A/c Group",
    mobilenumber: 'Mobile No.',
    acledgername: 'A/c Ledger Name',
    constantperson: 'Contact Person',
    gstno: 'Gstno',
    editairline: "Edit Airline",
    addairline: "Add Airline",
    airlinelist: 'Airline List',
    airportname: 'Airport Name',
    airportcode: 'Airport Code',
    airport: "AirPort",
    editairport: "Edit Airport",
    addairport: "Add Airport",
    areaname: "Area Name",
    sector: "Sector",
    area: "Area",
    sectortype: "Sector Type",
    editareamaster: "Edit Area Master",
    addareamaster: "Add Area Master",
    assectcategoryname: 'Asset Category Name',
    assettype: 'Asset Type',
    enqid: "Enq Id",
    apol: 'APOL',
    apod: 'APOD',
    grosswt: 'Gross Wt.',
    pkgs: 'Pkgs',
    enquirydate: 'Enquiry Date',
    dateofShipment: 'Tent. Date of Shpmnt',
    marketedby: 'Marketed By',
    status: "Status",
    stockno: "Stock No.",
    date: "Date",
    stockregister: "Stock Register",
    addstockregister: "Add Stock Register",
    updatestockregister: "Edit Stock Register",
    entryno: "Entry No.",
    partyname: "Party Name",
    jobtype: "Job Type ",
    jobno: "Job No.",
    packages: "Packages",
    shippername: "Shipper Name",
    mawbno: "MAWB NO.",
    hawbno: "HAWB NO.",
    pickup: "Pickup",
    editgoodofexport: "Edit Goods of Exports",
    addgoodofexport: "Add Goods of Exports",
    goodofexports: 'Goods of Exports',
    editgoodofexport: "Edit Expected Goods",
    addgoodofexport: "Add Expected Goods",
    goodofexports: 'Expected Goods',
    delete: "Delete",
    goodsrecived: "Goods Recived",
    goodsinregister: "Goods In Register",
    dimension: "Dimension",
    actualshipper: "Actual Shipper",
    transport: "Transpoter",
    driverdetails: "Driver Details",
    addgoodinregister: "Add Goods In Register",
    editgoodinregister: "Edit Goods In Register",
    editgoodoutregister: "Edit Goods Out Register",
    goodsoutregister: "Goods Out Register",
    destinationtype: "Destination Type",
    updategoodoutregister: "Update Goods Out Register",

    entryno: "Entry No.",
    partyname: "Party Name",
    jobtype: "Job Type ",
    jobno: "Job No.",
    packages: "Packages",
    shippername: "Shipper Name",
    mawbno: "MAWB NO.",
    hawbno: "HAWB NO.",
    pickup: "Pickup",
    delete: "Delete",
    goodsrecived: "Goods Recived",
    goodsinregister: "Goods In Register",
    dimensionsheet: "Dimension Sheet",
    vehiclename: 'Vehicle Name',
    editvehicalname: "Edit Vehicle Name",
    addvehiclename: "Add Vehicle Name",
    goodsout: "Goods Out",
    volumnweight: "Volume Weight",
    freightsb: "Freight on S/B",
    sbno: "S/B No",
    lht: "L.H.T.",
    slotno: "Slot No.",
    vehicalno: "Vehicle No.",
    drivernameno: "Driver Name Ph No.",
    sealno: "Seal No.",
    chalanno: "Challan No.",
    remark: "Remark",
    jobregister: "Job Register",
    airvolumeweightcbm: "Air Volume Weight.CBM",
    incoterms: "Inco Terms",
    airportofloading: "Airport Of Loading",
    airportofdestination: "Airport Of Destination",
    commodityhscode: "Commodity",
    specialreqremarks: "Special Req./Remarks",
    volumnwcbm: "VOLUME WEIGHT/CBM",
    chargeableweight: "Chargeable Weight",
    exchangerate: "Exchange Rate",
    commodite: "Commodity",
    pickupaddress: "Pickup Address",
    deliveryaddress: "Delivery Address",
    airlines: "Airlines",
    chargehead: "Charge Head",
    currency: "Currency",
    rate: "Rate",
    unit: "unit",
    quantity: "Quantity",
    amount: "Amount",
    bizsubtype: "Biz Sub Type",
    enqrefno: 'Enq. Ref. No.',
    jobno: 'Job No',
    jobdate: 'Job Date',
    enquiring: 'Enquiring Customer',
    Customertype: 'Customer Type',
    freightservicrtype: 'Freight Service Type',
    bizgenerator: 'Biz Generator',
    pkgs: 'Pkgs',
    grweight: 'Gr.Weight',
    vweight: 'V.Weight',
    originapt: 'Origin Apt.',
    destnapt: 'Destn Apt.',
    cargotype: 'Cargo Type',
    tentativedateofship: 'T. Date',
    splreq: 'SPL.Req'


}

export const formconstant = {
    bizInstituteType: "Biz Institute Type",
    otherBizInstituteType: "Type of company(Specify other type)",
    nameOnCheque: "Name On Cheque",
    panAvailable: "PAN Available",
    tdsApplicable: "TDS Applicable",
    code: "Code",
    legalName: "Legal Name",
    accLedgerName: "Account Ledger Name",
    awbStockRecorderLabel: "AWB Stock Recorder Label",
    airlinePrefix: "Airline Prefix",
    address1: "Address 1",
    address2: "Address 2",
    address3: "Address 3",
    country: "Country",
    state: "State",
    city: "City",
    zipCode: "ZIP/Post Code",
    contactPerson: "Contact Person",
    email: "Email",
    telephone: "Telephone",
    mobileNo: "Mobile No",
    gstStatus: "GST Status",
    gstCode: "GSTCode",
    cin: "CIN",
    gsa: "GSA",
    assignedDate: "Assigned Date",
    name: "Name",
    iecNo: "IEC No",
    panNumber: "PAN Number",
    subAgent: "Sub Agents",
    tan: "TAN",
    fax: "FAX",
    telFAX: "TEL FAX",
    remarks: "Remarks",
    beneficiaryName: "Benificiary Name",
    bankName: "Bank Name",
    accNumber: "A/C No",
    bankCode: "BankCode",
    swiftCode: "Swift Code",
    bankAddress: "Bank Address",
    openAsNotify: "Open As Notify",
    attachKYC: "Attach KYC",
    from: "From",
    to: "To",
    number: "Number",
    date: "Date",
    linkedconsignee: 'linked Consignee',
    date: "Date",
    partyname: "Party Name",
    jobtype: "Job Type",
    packages: "Packages",
    expectedpackages: "Expected Packages",
    grossweight: "Gross Weight",
    expectedgrossweight: "Expected Gross Wt",
    dimension: "Dimension",
    actualshipper: "Actual Shippers",
    mawbno: "MAWB NO.",
    hawbno: "HAWB NO.",
    jobno: "Job No.",
    cost: "Cost",
    pickupstatus: "Pickup Status",
    transportname: "Transporter Name",
    pickupvehicaltype: "Pickup Vehicle Type",
    vehicalno: "Vehicle No.",
    drivername: "Driver Name",
    driverno: "Driver No.",
    goodsconditions: "Goods Conditions",
    comments: "Comments",
    uploadpic: "Upload Pic.",
    pickuptype: "Pickup Type",
    consoleno: "Console No.",
    vehiclename: "Vehicle Name",
    consoleno: "Console No.",
    challanstatus: "Challan Status",
    destinationtype: "Destination Type",
    enquiryno: 'Enquiry No',
    quantitino: 'Quantity No.',
    enqconstomername: 'Enq. Customer Name',
    bizgenerat: 'Biz. Geneartor',
    customertype: 'Customer Type',
    frtservicetype: 'Frt. Service Type',
    frservicesubtyp: 'Frt. Service Sub Type',
    bizcategorytype: 'Biz. Category Type',
    biztype: 'Biz. Type',
    bizsubtype: 'Biz. Sub Type',
    volumnweight: 'Volume Weight',
    chargableweight: 'Chargeable Weight',
    dimensions: 'Dimensions',
    apol: 'APOL',
    apod: 'APOD',
    pickupaddress: 'Pickup Address',
    deliveryaddress: 'Door Delivery Address',
    gcr: 'GCR',
    gsp: 'GSP',
    consoletype: 'Console Type',
    shipper: 'Shipper',
    consignee: 'Consignee',
    notify: 'Notify',
    subagent: 'Sub Agent',
    onp1: 'ONP-1',
    onp2: 'ONP-2',
    onp3: 'ONP-3',
    mawbrate: 'MAWB Rate',
    mawbprint: 'MAWB Print Rate',
    shipperinvoiceno: 'Shipper Invoice No.',
    shipperincoterm: 'Shipper Inco term',
    freight: 'Freight',
    checklist: 'CheckList',
    checkAprvbyshipper: 'CheckList Approved By Shipper',
    sbno: 'S.B No',
    appr: 'Appraisal(Appr.)',
    apprdc: 'Appraisal(DC)',
    goodsexpectat: 'Goods Expected At',
    ptransport: 'Pre Transportation',
    goodsreceived: 'Goods Received',
    mawbno: 'MAWB No.',
    hawb: 'HAWB',
    bookwithairline: 'Booking With Airline',
    tetivfligtdetail: 'Tentative Flight Detail',
    lasthandovertime: 'Last handover Time',
    actuaslhandtime: 'Actual Handover Time',
    asignjobto: 'Assign Job To',
    salespersion: 'Sales Person',
    salessupppersion: 'Sales Support Person',




}


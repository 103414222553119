import { Link, FormControl, MenuItem, Select, Tooltip, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import videoCamera from '../assets/img/video-camera.png';
import laptop from '../assets/img/laptop.svg';
import focussed from '../assets/img/focussed.svg';
import meal from '../assets/img/meal.svg';
import coffee from '../assets/img/coffee.svg';
import dnd from '../assets/img/dnd.svg';
import { Close } from '@mui/icons-material';

const LiveFeed = ({ remoteShow }) => {
    const [show, setShow] = useState(false)
    const [coffeeTime, setCoffeeTime] = React.useState('');
    const coffeehandleChange = (event) => {
        setCoffeeTime(event.target.value);
    };

    const [dndTime, setDndTime] = React.useState('');
    const handleChange = (event) => {
        setDndTime(event.target.value);
    };

    useEffect(() => {
        if (remoteShow) {
            setShow(false)

        } else {
            setShow(true)

        }
    }, [remoteShow])
    return (
        <>
            <div className={`liveFeedWrapper  ${show ? "shown" : 'hidden'}`}>
                <div className='liveFeedBoxes'>
                    <div className='liveFeed-leftBox d-flex align-items-center'>
                        <h3 className='liveFeedHD me-3'>Live video feed:</h3>
                        <div className='videoIconBox'>
                            <img src={videoCamera} alt='' />
                            {/* <Switch {...label} /> */}
                        </div>
                    </div>
                    <div className='liveFeed-centerBox d-flex align-items-center'>
                        <h3 className='liveFeedHD'>Status:</h3>
                        <ul>
                            <li className='feedBtn availableForCollaboration'>
                                <Tooltip title="Available for Collaboration">
                                    <span className='laptop-imgBox'><img src={laptop} alt='' /></span>
                                </Tooltip>
                            </li>
                            <li className='feedBtn engaged-at-work'>
                                <Tooltip title="Engaged at work">
                                    <span className='laptop-imgBox'><img src={focussed} alt='' /></span>
                                </Tooltip>
                            </li>
                            <li className='feedBtn meal-break d-flex align-items-center'>
                                <Tooltip title="Meal break for 60 mins">
                                    <>
                                        <span className='meal-imgBox'>
                                            <img src={meal} alt='' />
                                        </span>
                                        <span>for 60 min</span>
                                    </>
                                </Tooltip>
                            </li>
                            <li className='feedBtn short-break-dropBox statusDropBox'>
                                <Tooltip title="Short break for 45 mins">
                                    <>
                                        <span className='statusDrop-imgBox'>
                                            <img src={coffee} alt='' />
                                        </span>

                                        <FormControl className='statusSelectBox'>
                                            <Select
                                                value={coffeeTime}
                                                onChange={coffeehandleChange}
                                                displayEmpty
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                <MenuItem value={30}>for 30 mins</MenuItem>
                                                <MenuItem value={45}>for 45 mins</MenuItem>
                                                <MenuItem value={60}>for 60 mins</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </>
                                </Tooltip>

                            </li>
                            <li className='feedBtn statusDropBox'>
                                <Tooltip title="Do not disturbe for 15 mins">
                                    <>
                                        <span className='statusDrop-imgBox'>
                                            <img src={dnd} alt='' />
                                        </span>
                                        <FormControl className='statusSelectBox'>
                                            <Select
                                                value={dndTime}
                                                onChange={handleChange}
                                                displayEmpty
                                            >
                                                <MenuItem value=""><em>None</em></MenuItem>
                                                <MenuItem value={30}>for 15 mins</MenuItem>
                                                <MenuItem value={45}>for 20 mins</MenuItem>
                                                <MenuItem value={60}>for 30 mins</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </>
                                </Tooltip>
                            </li>
                        </ul>
                    </div>
                    <div className='liveFeed-rightBox d-flex align-items-center'>
                        <div className='checkIn-outBtn me-3'>Check In</div>

                    </div>

                </div>
                <div className='liveFeed-closeBtn'>
                    <IconButton sx={{ border: "1px solid #e6e6e6", padding: "4px" }}
                        onClick={() => setShow(false)}
                    >
                        <Close />
                    </IconButton>
                </div>

            </div>
        </>
    );
};

export default LiveFeed;
import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { GetMasterDepartmentByPage, GetMasterDivisionByPage, GetMasterSubDepartmentByPage, GetMasterSubGroupByPage, PostMasterDepartment, PostMasterDivision, PostMasterSubDepartment, UpdateMasterDepartment, UpdateMasterDepartmentStatus, UpdateMasterSubDepartment, UpdateMasterSubDepartmentStatus, UpdateMasterSubGroupStatus } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { IconButton, Switch, Tooltip } from '@mui/material';
import DivisonDrawer from '../../component/divisionDrawer';
import { useDispatch } from 'react-redux';
import { subdepartment } from '../../../navigation/constant';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';

const SubDepartment = () => {
    const dispatch = useDispatch()
    const [subdDpartmentMasterData, setSubDepartmentMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterSubDepartmentByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            subdepartment: res.name,
            division: res.division.value,
            divisionid: res.division.key,
            department: res.department.value,
            departmentid: res.department.key,
            status: res.status
        }));
        setSubDepartmentMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
        {
            id: 'division',
            label: 'Division (Branch)',
            numeric: false,
        },
        {
            id: 'department',
            label: 'Department',
            numeric: false,
        },
        {
            id: 'subdepartment',
            label: 'Sub-Department',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];


    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateMasterSubDepartmentStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <SubDepartemtDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <SubDepartemtDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }

    return (
        <>
            <MultiTable
                data={subdDpartmentMasterData}
                columns={columns}
                // filterItems={filterItems}
                //   drawerFormFields={drawerFormFields}
                title={'Sub-Department'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default SubDepartment






const SubDepartemtDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()
    const [departmentItem, setDepartmentItem] = useState([])
    const [divisionBranchItem, setDivisionbranchItem] = useState([])
    const [closeValidator, setcloseValidator] = useState(false)

    const [divisionType, setDivisionType] = useState(row?.divisionid || '')
    const [divisionTypeError, setDivisionTypeError] = useState(false)
    const [departmentType, setDepartmentType] = useState(row?.departmentid || '')
    const [departmentTypeError, setDepartmentTypeError] = useState(false)
    const [subDepartment, setSubDepartment] = useState(row?.subdepartment || '')
    const [subDepartmentError, setSubDepartmentError] = useState(false)

    const drawerOpenCallbackHandler = () => {
        setDivisionType(row?.divisionid || '')
        setDepartmentType(row?.departmentid || '')
        setSubDepartment(row?.subdepartment || '')
        setDivisionTypeError(false)
        setDepartmentTypeError(false)
        setSubDepartmentError(false)
    }


    const set_DivisionbranchData = () => {

        GetCaller({
            API: PostMasterDivision,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newSubDivisionbranchItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name + " ( " + item.branch.value + " ) "
                }))
                setDivisionbranchItem(newSubDivisionbranchItemData)
            }
        })

    }
    const set_DepartmentData = (division) => {
        GetCaller({
            API: PostMasterDepartment,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                let newDepartmentItemData;
                if (division) {
                    newDepartmentItemData = response?.data?.data?.records
                        .filter((item) => item.division.key === division)
                        .map((item) => ({
                            value: item.id,
                            name: item.name,
                            key: item.division.key
                        }));
                } else {
                    newDepartmentItemData = response?.data?.data?.records
                        .map((item) => ({
                            value: item.id,
                            name: item.name,
                            key: item.division.key
                        }));
                }
                setDepartmentItem(newDepartmentItemData)
            }

        })
    }

    useEffect(() => {
        set_DivisionbranchData()
        set_DepartmentData()
    }, []);

    useEffect(() => {
        setcloseValidator(false)

    }, [closeValidator]);

    const handleSubmit = () => {
        if (!divisionType || !departmentType || !subDepartment) {
            setDepartmentTypeError(!departmentType)
            setDivisionTypeError(!divisionType)
            setSubDepartmentError(!subDepartment)

        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: subDepartment,
                departmentId: departmentType,
                status: row.status   
            } : {
                name: subDepartment,
                branchId: divisionType,
                departmentId: departmentType,
            }     
            editor ?
              PostCaller({
                API: UpdateMasterSubDepartment,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })     
              :    
              PostCaller({
                API: PostMasterSubDepartment,
                callback: () => callAPI(),
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: postmasterareaData
              })
            setcloseValidator(true)
      
          }


    }




    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'division':
                setDivisionType(value)
                setDivisionTypeError(!value);
                set_DepartmentData(value)
                break;
            case 'department':
                setDepartmentType(value)
                setDepartmentTypeError(!value);
               
                break;
            case 'subdepartment':
                setSubDepartment(value)
                setSubDepartmentError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit SubDepartment",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit SubDepartment',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add SubDepartment",
            tooltitle: 'Add SubDepartment',
            defaultbtnfalse: true
        }





    const FieldData = editor ? [

        {
            id: 'subdepartment',
            label: 'Sub_Department',
            type: "text",
            value: subDepartment,
            error: subDepartmentError,
            required: true,
            helperText: "Sub_Department required",
            onChange: handleInputChange,

        },

        {
            id: 'department',
            label: 'Department',
            type: "select",
            value: departmentType,
            error: departmentTypeError,
            required: true,
            helperText: "Department required",
            item: departmentItem,
            onChange: handleInputChange,
        },
    

    ]



        : [


            {
                id: 'division',
                label: 'Division (Branch)',
                type: "select",
                value: divisionType,
                error: divisionTypeError,
                required: true,
                helperText: "Division (Branch) required",
                item: divisionBranchItem,
                onChange: handleInputChange,
            },
            {
                id: 'department',
                label: 'Department',
                type: "select",
                value: departmentType,
                error: departmentTypeError,
                required: true,
                helperText: "Department required",
                item: departmentItem,
                onChange: handleInputChange,
            },
            {
                id: 'subdepartment',
                label: 'Sub_Department',
                type: "text",
                value: subDepartment,
                error: subDepartmentError,
                required: true,
                helperText: "Sub_Department required",
                onChange: handleInputChange,

            },


        ];






    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}



import { useEffect, useState } from "react";
import axios from 'axios';
import { Button, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dropdown, { InputField, TextAreafield } from "../../component/controls";
import { GetAllEmployee, PostSubAgencyShipperMaster, UpdateSubAgencyShipperMaster, PostMasterBizInstituteType, GetMasterStateByPage, PostMasterCountry, GetMasterCityByPage, GetCustomerBySearch, PostMasterCity } from "../../../services/constant";
import GetCaller from "../../../services/apiServices";
import { PostCaller } from "../../../services/apiServices";
import { formconstant } from "../../constant/formconstant";
import { subagencyshippeplist } from "../../../navigation/constant";
import formValueReseter from "../../../services/utilities/formValueReseter";
import CheckMail from "../../../services/utilities/checkEmailFormat";
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in vendorFormthe Authorization header
};

export const SubAgencyShipperForm = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);
    const [cityItem, setCityItem] = useState([]);
    const [stateItem, setStateItem] = useState([]);
    const [countryItem, setCountryItem] = useState([]);
    const [customerItem, setCustomerItem] = useState([]);
    const [attachkyc, setAttachKyc] = useState('Attach Kyc')
    const [bizInstituteItem, setBizInstituteItem] = useState([]);
    const [employeeItem, setEmployeeItem] = useState([]);
    const branchId = useSelector(state => state.enquirybranch);
    const [openAsNotify, setOpenAsNotify] = useState(false)

    const [shipperForm, setShipperForm] = useState({
        id: 0,
        name: '',
        branchId: '',
        bizInstituteId: '',
        code: '',
        cin: '',
        subAgentId: '',
        countryId: '',
        stateId: '',
        cityId: '',
        address1: '',
        address2: '',
        address3: '',
        pinCode: '',
        mobileNumber: '',
        telephone: '',
        fax: '',
        telfax: '',
        email: '',
        contactPerson: '',
        gstNumber: '',
        addressLabel: '',
        pan: '',
        tan: '',
        iec: '',
        kycFile: '',
        remarks: '',
        status: '',
        bankName: '',
        beneficiaryName: '',
        accountNumber: '',
        bankCode: '',
        swiftCode: '',
        bankAddress: '',
        bankDetails: [
            {
                id: 0,
                name: "",
                status: "",
                beneficiaryName: "",
                accountNumber: "",
                bankCode: "",
                swiftCode: "",
                bankAddress: ""
            }
        ]

    })

    const set_BizInstituteItemData = (e) => {
        GetCaller({
            API: PostMasterBizInstituteType,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newbizinstitude = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }));
                setBizInstituteItem(newbizinstitude)
            }

        });
    };
    // const set_CountryItemData = (e) => {
    //     GetCaller({
    //         API: PostMasterCountry,
    //         dispatch: (e) => {
    //             dispatch({ type: e.type, payload: e.payload })
    //         },
    //         item: {},
    //         callback: ({ response }) => {
    //             const newcountrydata = response?.data?.data?.records.map((item) => ({
    //                 value: item.id,
    //                 name: item.name
    //             }));

    //             setCountryItem(newcountrydata)
    //         }

    //     });
    // };
    // const set_StateItemData = (countryId) => {
    //     const requestData = {
    //         searchKey: '',
    //         sortKey: '',
    //         sortDirection: '',
    //         itemsPerPage: 100,
    //         page: 1,
    //     };

    //     axios.get(GetMasterStateByPage(requestData), { headers })
    //         .then((response) => {

    //             let newStateItemData;
    //             if (countryId) {

    //                 newStateItemData = response?.data?.data?.records
    //                     .filter((item) => item.country.key === countryId)
    //                     .map((item) => ({
    //                         value: item.id,
    //                         name: item.name,
    //                     }));
    //             }
    //             setStateItem(newStateItemData);
    //         })
    //         .catch((error) => {
    //             // Handle error
    //             console.error("Error fetching state data:", error);
    //         });
    // }

    // const set_CityItemData = (stateId) => {
    //     const requestData = {
    //         searchKey: "",
    //         sortKey: "",
    //         sortDirection: "",
    //         itemsPerPage: 100,
    //         page: 1,
    //     };
    //     axios
    //         .get(GetMasterCityByPage(requestData), { headers })
    //         .then((response) => {
    //             // console.log(e)
    //             let newCityItemData;
    //             if (stateId) {
    //                 newCityItemData = response?.data?.data?.records
    //                     .filter((item) => item.state.key === stateId)
    //                     .map((item) => ({
    //                         value: item.id,
    //                         name: item.name,
    //                         key: item.country.key
    //                     }));
    //             }

    //             setCityItem(newCityItemData);

    //         });
    // };

    const set_CityItemData = () => {
        GetCaller({
            API: PostMasterCity
            ,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({response }) => {
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                    gstCode: item.gstCode                                     
                }))
                setCityItem(newCityItemData)
            }
    
        })
    
    }

    const set_CustomerItemData = () => {
        debugger
        GetCaller({
            API: GetCustomerBySearch,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                debugger
                const newCountryItemData = response?.data?.data?.records
                    .filter(item => item.partyType === 2)
                    .map(item => ({
                        value: item.id,
                        name: item.legalName
                    }));

                setCustomerItem(newCountryItemData);

            }

        });

    };


    const set_EmployeeItemData = () => {
        GetCaller({
            API: GetAllEmployee,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {

                console.log('Sales Person', response?.data?.data)
                const data = response?.data?.data?.records

                const employeelist = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
               
                setEmployeeItem(employeelist)

            }

        });
    }

    useEffect(() => {
        set_CustomerItemData();
        set_BizInstituteItemData();
    

        if (sessionStorage.shipper) {
          
            let shipper = JSON.parse(sessionStorage.getItem("shipper"));
            shipper.bankName = shipper.bankDetails[0].name
            shipper.accountNumber = shipper.bankDetails[0].accountNumber
            shipper.bankAddress = shipper.bankDetails[0].bankAddress
            shipper.bankCode = shipper.bankDetails[0].bankCode
            shipper.beneficiaryName = shipper.bankDetails[0].beneficiaryName
            shipper.swiftCode = shipper.bankDetails[0].swiftCode
            setOpenAsNotify(shipper.openAsNotify)
            setShipperForm(shipper);
            setIsEdit(true);
            set_CityItemData(shipper.stateId);
            sessionStorage.removeItem('shipper');

        }
        else {
     
            set_CityItemData();
        }
    }, []);

    const requestdata = (() => {
        const data = {
            name: shipperForm.name,
            branchId: branchId,
            bizInstituteId: shipperForm.bizInstituteId,
            code: shipperForm.code,
            cin: shipperForm.cin,
            subAgentId: shipperForm.subAgentId,
            countryId: shipperForm.countryId,
            stateId: shipperForm.stateId,
            cityId: shipperForm.cityId,
            address1: shipperForm.address1,
            pinCode: shipperForm.pinCode,
            mobileNumber: shipperForm.mobileNumber,
            telephone: shipperForm.telephone,
            pan: shipperForm.pan,
            fax: shipperForm.fax,
            telfax: shipperForm.telfax,
            email: shipperForm.email,
            contactPerson: shipperForm.contactPerson,
            tan: shipperForm.tan,
            iec: shipperForm.iec,
            openAsNotify: openAsNotify,
            status: true,
            bankName: shipperForm.bankName,
            beneficiaryName: shipperForm.beneficiaryName,
            accountNumber: shipperForm.accountNumber,
            bankCode: shipperForm.bankCode,
            swiftCode: shipperForm.swiftCode,
            bankDetails: [
                {
                    id: 0,
                    name: shipperForm.bankName,
                    status: true,
                    beneficiaryName: shipperForm.beneficiaryName,
                    accountNumber: shipperForm.accountNumber,
                    bankCode: shipperForm.bankCode,
                    swiftCode: shipperForm.swiftCode,
                    bankAddress: shipperForm.bankAddress
                }
            ]
        }
        if (bizInstituteItem && bizInstituteItem[shipperForm.bizInstituteId - 1]?.name.toLowerCase() === 'other') data.otherBizInstituteType = shipperForm.otherBizInstituteType
        // if (shipperForm.pan) data.pan = shipperForm.pan
        if (shipperForm.bankAddress) data.bankAddress = shipperForm.bankAddress
        if (shipperForm.kycFile) data.kycFile = shipperForm.kycFile
        if (shipperForm.remarks) data.remarks = shipperForm.remarks
        if (shipperForm.address2) data.address2 = shipperForm.address2
        if (shipperForm.address3) data.address3 = shipperForm.address3
        if (isEdit) data.id = shipperForm.id
        return data;
    })();

    const handleInputChange = (value, field) => {
        let fieldData = {};

        setShipperForm({
            ...shipperForm,
            ...fieldData,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
        fieldData = {}
    };

    const handleFormSubmit = () => {

        console.log('shipperForm', requestdata)
        const changes = handleSubmit(requestdata);
       
        const isMail = CheckMail(requestdata?.email)
        if (Object.keys(changes).length > 0 || isMail) {
            setShipperForm({ ...shipperForm, ...changes,
            ['emailerror']: isMail
            });
        }
        else if (isEdit) {

            EditSubAgencyShipperSubmit(requestdata);
            setIsEdit(false);
        }
        else {

            AddSubAgencyShipperSubmit(requestdata);

        }
    };


    const EditSubAgencyShipperSubmit = (requestdata) => {
        axios.post(UpdateSubAgencyShipperMaster(), requestdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${authToken}`
            },
        }).then((response) => {
            const friendlymess = response?.data?.metaData?.friendlyMessage;
            const resultmess = response?.data?.metaData?.resultMessage;

            if (response?.data?.metaData?.resultCode === "R00") {
                navigate(subagencyshippeplist);
                dispatch({
                    type: "SNACKBARALERT",
                    payload: {
                        active: true,
                        message: friendlymess || resultmess,
                        type: "success",
                    },
                });
            } else {
                dispatch({
                    type: "SNACKBARALERT",
                    payload: {
                        active: true,
                        message: friendlymess || resultmess,
                        type: "error",
                    },
                });
            }
        });
    }
    const AddSubAgencyShipperSubmit = (requestdata) => {
     
        axios.post(PostSubAgencyShipperMaster(), requestdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${authToken}`
            },
        }).then((response) => {

            const friendlymess = response?.data?.metaData?.friendlyMessage;
            const resultmess = response?.data?.metaData?.resultMessage;

            if (response?.data?.metaData?.resultCode === "R00") {
                navigate(subagencyshippeplist);
                dispatch({
                    type: "SNACKBARALERT",
                    payload: {
                        active: true,
                        message: friendlymess || resultmess,
                        type: "success",
                    },
                });
            } else {
                dispatch({
                    type: "SNACKBARALERT",
                    payload: {
                        active: true,
                        message: friendlymess || resultmess,
                        type: "error",
                    },
                });
            }
        });
    };

    const handleSubmit = (obj) => {

        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (
                key === "id" || key === "branchId" || key === "openAsNotify" || key === "status"
                || key === "countryId" || key === "stateId"
            ) {
            }
            else if (!value) {
                changes[key + "error"] = !value;
            }
        });
        return changes;
    };

    const handleTextAreaChange = (e) => {
        setShipperForm({
            ...shipperForm,
            [e.target.id]: e.target.value,
            [e.target.id + 'error']: !e.target.value,
        });
    }

    return (
        <>
            <div className="hr-form-box">
                {<h6>Sub Agency Shipper</h6>}
                <div className="row">
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'bizInstituteId',
                                    label: formconstant.bizInstituteType,
                                    type: 'select',
                                    value: shipperForm.bizInstituteId,
                                    required: true,
                                    error: shipperForm.bizInstituteIderror,
                                    item: bizInstituteItem,
                                    disable: false,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    {
                        bizInstituteItem && bizInstituteItem[shipperForm.bizInstituteId - 1]?.name.toLowerCase() === 'other' &&
                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: 'otherBizInstituteType',
                                        label: formconstant.otherBizInstituteType,
                                        type: 'text',
                                        disable: isEdit ? true : false,
                                        value: shipperForm.otherBizInstituteType,
                                        required: true,
                                        error: shipperForm.otherBizInstituteTypeerror,
                                        onChange: handleInputChange
                                    }
                                }
                            />
                        </div>
                    }
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'code',
                                    label: formconstant.code,
                                    type: 'text',
                                    value: shipperForm.code,
                                    required: true,
                                    error: shipperForm.codeerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'name',
                                    label: formconstant.name,
                                    type: 'text',
                                    value: shipperForm.name,
                                    required: true,
                                    error: shipperForm.nameerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'iec',
                                    label: formconstant.iecNo,
                                    type: 'text',
                                    value: shipperForm.iec,
                                    required: true,
                                    error: shipperForm.iecerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'pan',
                                    label: formconstant.panNumber,
                                    type: 'text',
                                    value: shipperForm.pan,
                                    required: true,
                                    error: shipperForm.panerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'cin',
                                    label: formconstant.cin,
                                    type: "text",
                                    value: shipperForm.cin,
                                    required: true,
                                    error: shipperForm.cinerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'subAgentId',
                                    label: formconstant.subAgent  ,
                                    type: 'select',
                                    value: shipperForm.subAgentId,
                                    required: true,
                                    error: shipperForm.subAgentIderror,
                                    item: customerItem,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'tan',
                                    label: formconstant.tan,
                                    type: "text",
                                    value: shipperForm.tan,
                                    required: true,
                                    error: shipperForm.tanerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'address1',
                                    label: formconstant.address1,
                                    type: "text",
                                    value: shipperForm.address1,
                                    required: true,
                                    error: shipperForm.address1error,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'address2',
                                    label: formconstant.address2,
                                    type: "text",
                                    value: shipperForm.address2,
                                    required: false,
                                    error: shipperForm.address2error,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'address3',
                                    label: formconstant.address3,
                                    type: "text",
                                    value: shipperForm.address3,
                                    required: false,
                                    error: shipperForm.address3error,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    {/* <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "countryId",
                                    label: formconstant.country,
                                    type: "select",
                                    value: shipperForm.countryId,
                                    required: true,
                                    error: shipperForm.countryIderror,
                                    item: countryItem,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "stateId",
                                    label: formconstant.state,
                                    type: "select",
                                    value: shipperForm.stateId,
                                    required: true,
                                    error: shipperForm.stateIderror,
                                    item: stateItem,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div> */}
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "cityId",
                                    label: formconstant.city,
                                    type: "select",
                                    value: shipperForm.cityId,
                                    required: true,
                                    error: shipperForm.cityIderror,
                                    item: cityItem,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'pinCode',
                                    label: formconstant.zipCode,
                                    type: "text",
                                    value: shipperForm.pinCode,
                                    required: true,
                                    error: shipperForm.pinCodeerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'email',
                                    label: formconstant.email,
                                    type: "text",
                                    value: shipperForm.email,
                                    required: true,
                                    error: shipperForm.emailerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'contactPerson',
                                    label: formconstant.contactPerson,
                                    type: "text",
                                    value: shipperForm.contactPerson,
                                    required: true,
                                    error: shipperForm.contactPersonerror,
                                    onChange: handleInputChange

                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'telephone',
                                    label: formconstant.telephone,
                                    type: "number",
                                    value: shipperForm.telephone,
                                    required: true,
                                    error: shipperForm.telephoneerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'mobileNumber',
                                    label: formconstant.mobileNo,
                                    type: "number",
                                    value: shipperForm.mobileNumber,
                                    required: true,
                                    error: shipperForm.mobileNumbererror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'fax',
                                    label: formconstant.fax,
                                    type: "string",
                                    value: shipperForm.fax,
                                    required: true,
                                    error: shipperForm.faxerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'telfax',
                                    label: formconstant.telFAX,
                                    type: "text",
                                    value: shipperForm.telfax,
                                    required: true,
                                    error: shipperForm.telfaxerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="row cmd">
                        <div className="col-md-5 mt-3">
                            <TextAreafield
                                field={
                                    {
                                        id: 'remarks',
                                        label: formconstant.remarks,
                                        type: "text",
                                        value: shipperForm.remarks,
                                        required: true,
                                        error: shipperForm.remarkserror,
                                        onChange: handleInputChange
                                    }
                                }
                            />

                        </div>
                        <div className="col-md-2" >
                            <div className='kyc-button' >
                                <label htmlFor="attach-kyc" className="button.MuiButton-outlinedPrimary">
                                    Attach KYC
                                </label>
                            </div>
                            <input type="file" id='attach-kyc' name="attach-kyc" onChange={(e) => { handleInputChange(e.target.files[0], { id: 'kycFile' }); }} className='d-none' />

                        </div>
                        <div className="col-md-3 mt-2">
                            <Checkbox
                                checked={openAsNotify}
                                onChange={() => setOpenAsNotify(!openAsNotify)}
                            />
                            {"Open as notify"}
                        </div>
                    </div>


                </div>
            </div >
            <div className="hr-form-box">
                {"Bank Details"}
                <div className="row">

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'beneficiaryName',
                                    label: formconstant.beneficiaryName,
                                    type: 'text',
                                    value: shipperForm.beneficiaryName,
                                    required: true,
                                    error: shipperForm.beneficiaryNameerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'bankName',
                                    label: formconstant.bankName,
                                    type: 'text',
                                    value: shipperForm.bankName,
                                    required: true,
                                    error: shipperForm.bankNameerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'accountNumber',
                                    label: formconstant.accNumber,
                                    type: 'number',
                                    value: shipperForm.accountNumber,
                                    required: true,
                                    error: shipperForm.accountNumbererror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'bankCode',
                                    label: formconstant.bankCode,
                                    type: 'text',
                                    value: shipperForm.bankCode,
                                    required: true,
                                    error: shipperForm.bankCodeerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'swiftCode',
                                    label: formconstant.swiftCode,
                                    type: 'text',
                                    value: shipperForm.swiftCode,
                                    required: true,
                                    error: shipperForm.swiftCodeerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3 mt-3">
                        <TextAreafield
                            field={
                                {
                                    id: 'bankAddress',
                                    label: formconstant.bankAddress,
                                    type: "text",
                                    value: shipperForm.bankAddress,
                                    required: true,
                                    error: shipperForm.bankAddresserror,
                                    onChange: handleInputChange
                                }
                            }
                        />

                    </div>

                </div>
            </div>
            <div className="cmd">
                <div></div>
                <Button
                    sx={{ m: 2, borderRadius: "10px" }}
                    onClick={handleFormSubmit}
                    variant="contained"
                >
                    Submit
                </Button>
            </div>
        </>
    );

}


export const formatDataToFormData = (data) => {
    const formData = new FormData();

    const flattenObject = (obj, prefix = '') => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const propName = prefix ? isNaN(key) ? `${prefix}.${key}` : `${prefix}[${key}]` : key;
                const propValue = obj[key];
                if (propValue === null) {
                    formData.append(propName, '');
                }
                else if (propValue instanceof Object && !(propValue instanceof File)) {
                    flattenObject(propValue, propName);
                } else {
                    formData.append(propName, propValue);
                }
            }
        }
    };

    const handleFiles = (obj, prefix = '') => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const propName = prefix ? `${prefix}.${key}` : key;
                const propValue = obj[key];

                if (propValue instanceof File) {
                    formData.append(propName, propValue);
                }
            }
        }
    };

    flattenObject(data);
    handleFiles(data);

    debugger

    return formData;
};
import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import { GetAssetCategoryByPage, GetAssetMasterByPage, GetMasterAreaByPage, GetMasterSectorByPage, PostAssetCategory, PostAssetMaster, PostMasterArea, UpdateAssetCategory, UpdateAssetMaster, UpdateAssetMasterStatus, UpdateMasterArea, UpdateMasterAreaStatus } from '../../../services/constant';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch, useSelector } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';

const authToken = localStorage.getItem('authToken')
const headers = {
    Authorization: `Bearer ${authToken}`,
  };
const AssetMaster = () => {
    const enquirybranch = useSelector(state => state.enquirybranch)
    const dispatch = useDispatch();
    const [masterareadata, setMasterareadata] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        debugger
        MasterCallBypagination({
            API: GetAssetMasterByPage,
            callback: setTableDataHandler, 
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item,
            enquirybranch: enquirybranch
        })
    }
    useEffect(() => {
        fatch_MasterDataBYPagination()
    }, []);
    const setTableDataHandler = ({ response }) => {
        debugger
        const newMasterAreaData = response?.data?.data?.records?.map((res, index) => ({
            sn: res.id,
            id: res.id,
            name: res.name,
            employeeName: res.employeeName,
            assetCategory : res.assetCategory.value,
            assetCategoryId : res.assetCategory.key,
            status: res.status
        }));
        setMasterareadata(newMasterAreaData);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
     
        {
            id: 'name',
            label: 'Asset Name',
            numeric: false,
        },
        {
            id: 'assetCategory',
            label: 'Asset Category',
            numeric: false,
        },
        {
            id: 'employeeName',
            label: 'Employee Name',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
    ];

    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateAssetMasterStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }


        return <>
            <AssetMasterDrawer callAPI={fatch_MasterDataBYPagination}editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>
    }
    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <AssetMasterDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }

    return (
        <>
            <MultiTable
                data={masterareadata}
                columns={columns}
                // drawerFormFields={drawerFormFields}
                title={'Asset Master'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default AssetMaster;
const AssetMasterDrawer = ({ callAPI, editor, row }) => {
    const enquirybranch = useSelector(state => state.enquirybranch)
    const dispatch = useDispatch()
    const [assetCategory, setAssetCategory] = useState(row?. assetCategoryId || '')
    const [assetCategoryError, setAssetCategoryError] = useState(false)

    const [assetName, setAssetName] = useState(row?.name || '')
    const [assetNameError, setAssetNameError] = useState(false)

    const [closeValidator, setcloseValidator] = useState(false)
    const [assetCatItem,setAssetCatItem] = useState([])


    const  set_AssetCategoryItemData = (item) => {
      
        GetCaller({
          API: PostAssetCategory,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item,
            enquirybranch: enquirybranch,
            callback: ({ response }) => {
                
                const newAssetCatItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setAssetCatItem(newAssetCatItemData)
            }
        })
    }
   const drawerOpenCallbackHandler = () => {           
    setAssetName(row?.name || '')
    setAssetCategory(row?.assetCategoryId || '')
    setAssetCategoryError(false)
    setAssetNameError(false)
    }


    useEffect(() => {
        set_AssetCategoryItemData();
        setcloseValidator(false)
    }, [closeValidator]);

    const handleInputChange = (value, field) => {
     
        switch(field.id){
            case 'assetCategory':
                setAssetCategory(value);
                setAssetCategoryError(!value)
                break;
            case 'assetName':
                setAssetName(value)
                setAssetNameError(!value)
                break;
            default:
                break;
        }
        
    };

    const handleSubmit = () => {
        if (!assetName || !assetCategory) {
            setAssetNameError(!assetName)
            setAssetCategoryError(!assetCategory)
        }
        else {
            const postmasterareaData = editor ? {
                id: row?.id,
                branchId:enquirybranch,
                name: assetName,
                assetCategoryId: assetCategory,
                status: row?.status
              } : {
                name: assetName,
                branchId:enquirybranch,
                assetCatId: assetCategory,
              }
        
        
              editor ?
                PostCaller({
                  API: UpdateAssetMaster,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  
                  },
                  item: postmasterareaData
                })
        
                :
        
                PostCaller({
                  API: PostAssetMaster,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }

    const drawerFormFields = editor ?
        {
            title: "Edit Asset Category",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Asset Category',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Asset Name",
            tooltitle: 'Add Asset Name',
            defaultbtnfalse: true
        }





    const FieldData = [
        {
            id: 'assetName',
            label: 'Asset Name',
            type: 'text',
            value: assetName, // Corrected from leadLogState.sectortype
            required: true,
            error: assetNameError,
            helperText: 'Asset required',
            onChange: handleInputChange,
        },
        {
            id: 'assetCategory',
            label: 'Asset Category',
            type: 'select',
            value: assetCategory,
            required: true,
            error: assetCategoryError,
            helperText: 'Asset Category required',
            onChange: handleInputChange,
            item: assetCatItem
        },

      
    ];



    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />;

    };


    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}



import { Checkbox } from '@mui/material';
import React from "react";
import { useEffect, useState } from "react";
import { Button } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    GetTDSMasterByPage,
    GetMasterTaxRateByPage,
    GetMasterVendorTypeByPage,
    UpdateChargeHead,
    GetSACMasterByPage,
    PostChargeHead,
    GetChargeHeadById,
    GetMasterSubGroupByPage,
    GetIncomeExpenseAccountLedgerByPage,
    GetMasterBranchByPage,
    GetMasterAccountLedgerByPage,
} from "../../services/constant";
import { ChargeExportImport, ChargehadeType, FixedCharges, applicableFormat } from '../../services/modalData';
import GetCaller, { PostCaller } from '../../services/apiServices';
import Dropdown, { AutoSelect, InputField, StackDropdown } from '../component/controls';
import { chargehead } from '../../navigation/constant';
import { Add, Close } from '@mui/icons-material';
import { GetIdsByKeyValuePair, GetKeyValuePairDataByIds } from '../../services/utilities/KeyValuePairDataByIds';


export const ChargeHeadForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const enquirybranch = useSelector(state => state.enquirybranch)



    const checkboxes = [
        // {
        //     name: 'DG CHARGE',
        //     id: 'dgCharge'
        // },
        // {
        //     name: 'MIN RATE APPLICABLE',
        //     id: 'minRateApplicable'
        // },
        {
            name: 'ONLY IGST APPLICABLE',
            id: 'onlyIGSTApplicable'
        },
        {
            name: 'IGST ON OVERSEAS INVOICING',
            id: 'igstOnOverseasInvoicing'
        },
        {
            name: 'DEBIT NOTE CHARGE',
            id: 'debitNoteCharge'
        },



    ];



    const [tdsItem, setTdsItem] = useState([]);
    const [taxrate, setTaxrate] = useState([]);
    const [saccode, setSACcode] = useState([]);
    const [vendorTypeItem, setVendorTypeItem] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [accountledgerItem, setAccountLedgerItem] = useState([]);
    const [chargeType, setchargeType] = useState([])
    const [subGroupItem, setSubGroupItem] = useState([]);
    const [branchitem, setBranchTypeItem] = useState([]);
    const [subgroupDetailsError, setSubGroupDetailsError] = useState({})
    const [chargeHeaddetailsDataByID, setChargeHeaddetailsDataByID] = useState({})


    const set_AccountLedgerItemData = (e) => {
        GetCaller({
            API: GetMasterAccountLedgerByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { id: chargehead },
            callback: ({ response }) => {

                const newVendorItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                }));

                setAccountLedgerItem(newVendorItemData);
            }
        })
    };


    const set_TDSItemData = (e) => {
        GetCaller({
            API: GetTDSMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {

                const newVendorItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                }));

                setTdsItem(newVendorItemData);
            }
        })
    };


    const set_TaxRateItemData = (e) => {
        GetCaller({
            API: GetMasterTaxRateByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, paylode: e.paylode })
            },
            item: {},
            callback: ({ response }) => {
                const newVendorItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                }));

                setTaxrate(newVendorItemData);
            }
        })
    };

    const set_SaccodeItemData = (e) => {
        GetCaller({
            API: GetSACMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, paylode: e.paylode })
            },
            item: {},
            callback: ({ response }) => {
                const newVendorItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                }));

                setSACcode(newVendorItemData);
            }
        })
    };

    const set_VendorTypeItemData = () => {

        GetCaller({
            API: GetMasterVendorTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, paylode: e.paylode })
            },
            item: {},
            callback: ({ response }) => {
                const newVendorItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,

                }))
                setVendorTypeItem(newVendorItemData)
            }
        })
    }

    const set_BranchItemData = () => {

        GetCaller({
            API: GetMasterBranchByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, paylode: e.paylode })
            },
            item: {},
            callback: ({ response }) => {
                const newBranchItemData = response?.data?.data?.records.map((item) => ({

                    value: item.id,
                    name: item.name,
                }))

                setBranchTypeItem(newBranchItemData)
            }
        })

    }

    const [branchKey, setBranchKey] = useState('')
    useEffect(() => {
        if (branchKey) {
            const revertbranchid = branchKey?.split(',')
            const filterbranchData = branchitem?.filter(item => revertbranchid?.includes(item.value.toString()));
            setChargeheadForm({
                ...chargeheadForm,
                ['branchId']: filterbranchData,
            });
        }
    }, [branchitem])



    const fatch_SubGroupItems = () => {
        GetCaller({
            API: GetMasterSubGroupByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const subItems = data?.map(res => ({
                    value: res.id,
                    name: res.name
                }))
                setSubGroupItem(subItems)
            }
        })
    }

    useEffect(() => {
        debugger
        let chargehead = sessionStorage.getItem("chargehead")
        set_TaxRateItemData();
        set_SaccodeItemData();
        set_VendorTypeItemData();
        set_AccountLedgerItemData();
        fatch_SubGroupItems();
        set_TDSItemData();
        set_BranchItemData();

        if (chargehead) {

            GetCaller({
                API: GetChargeHeadById,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: { id: chargehead },
                callback: ({ response }) => {
                    console.log(response)
                    debugger
                    let data = response?.data?.data

                    setChargeHeaddetailsDataByID(data)

                    const revertchargeHeadModule = data?.chargeHeadModule?.split(',')
                    const filteredData = ChargeExportImport?.filter(item => revertchargeHeadModule.includes(item.value.toString()));
                    console.log('filteredData utyygyttytyftg', filteredData)
                    data.chargeHeadModule = filteredData
                    setBranchKey(data?.branchId)
                    data.branchId = ''
                    data.isIneligibleInputCredit = (data?.isIneligibleInputCredit === true) ? 1 : 2;
                    data.isReverseChargeApplicable = (data?.isReverseChargeApplicable === true) ? 1 : 2;
                    data.fixedCharge = GetKeyValuePairDataByIds(FixedCharges, data.fixedCharge)
                    setChargeheadForm(data)
                }

            });
            setIsEdit(chargehead);
        }

    }, []);

    const [chargeheadForm, setChargeheadForm] = useState({
        chargesCode: "",
        name: "",
        taxRateId: "",
        systemAccountLedgerId: "",
        sacCodeId: "",
        subGroupId: "",
        branchId: "",
        dgCharge: '',
        tdsSlab: "",
        chargeHeadType: "",
        minRateApplicable: '',
        onlyIGSTApplicable: '',
        igstOnOverseasInvoicing: '',
        debitNoteCharge: '',
        isReverseChargeApplicable: '',
        isIneligibleInputCredit: '',
        fixedCharge: []
    })


    const requestdata = (() => {
        const data = {
            id: Number(isEdit),
            status: true,
            chargeHead: {
                name: chargeheadForm.name,
                chargesCode: chargeheadForm.chargesCode,
                taxRateId: chargeheadForm.taxRateId,
                chargeHeadType: chargeheadForm.chargeHeadType,
                dgCharge: chargeheadForm.dgCharge || false,
                minRateApplicable: chargeheadForm.minRateApplicable || false,
                onlyIGSTApplicable: chargeheadForm.onlyIGSTApplicable || false,
                igstOnOverseasInvoicing: chargeheadForm.igstOnOverseasInvoicing || false,
                debitNoteCharge: chargeheadForm.debitNoteCharge || false,
                systemAccountLedgerId: chargeheadForm.systemAccountLedgerId,
                sacCodeId: chargeheadForm.sacCodeId,
                tdsSlab: chargeheadForm.tdsSlab,
                chargeHeadModule: chargeheadForm.chargeHeadModule,
                isReverseChargeApplicable: chargeheadForm.isReverseChargeApplicable,
                isIneligibleInputCredit: chargeheadForm.isIneligibleInputCredit,
                branchId: chargeheadForm.branchId,
                subGroupId: chargeheadForm?.subGroupId,
            }
        }


        if (GetIdsByKeyValuePair(chargeheadForm.fixedCharge)) data.chargeHead.fixedCharge = GetIdsByKeyValuePair(chargeheadForm.fixedCharge)

        return data;
    })()



    // const SubGroupHasUndefinedValues = (array) => {

    //     let changes = {};
    //     array.map((obj, index) => {
    //         Object.entries(obj).forEach(([key, value]) => {

    //             if (
    //                 key === 'id' ||
    //                 key === 'chargeHeadId' ||
    //                 key === 'systemAccountLedgerName' && value === null ||
    //                 key === 'systemAccountLedgerId' && value === null
    //             ) {

    //             } else if (!value) {
    //                 changes[key + index] = !value;

    //             }
    //         });
    //     })
    //     console.log('changes', changes)

    //     return changes;
    // }

    const handleFormSubmit = () => {
        debugger
        console.log('vendorrequestData', requestdata)
        const changes = handleSubmit(requestdata.chargeHead);
        if (Object.keys(changes)?.length > 0
        ) {
            setChargeheadForm({ ...chargeheadForm, ...changes });
        }
        else if (isEdit) {

            EditAreaMasterSubmit(requestdata);
        }
        else {
            AddAreaMasterSubmit(requestdata)
        }
    };


    const handleSubmit = (obj) => {

        let changes = {};

        Object.entries(obj).forEach(([key, value]) => {
            if (
                key === 'id' ||
                key === 'debitNoteCharge' ||
                key === 'igstOnOverseasInvoicing' ||
                key === 'onlyIGSTApplicable' ||
                key === 'dgCharge' ||
                key === 'minRateApplicable' ||
                key === 'subGroupId'
            ) {

            }
            else if (!value) {
                changes[key] = value;
                changes[key + 'error'] = !value;
            }
        });
        console.log("changes", changes)
        return changes;
    };




    const handleInputChange = (value, field) => {

        setChargeheadForm({
            ...chargeheadForm,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    };


    const EditAreaMasterSubmit = (requestdata) => {
        // requestdata.branchId = 1;
        requestdata.chargeHead.isReverseChargeApplicable = (requestdata.chargeHead.isReverseChargeApplicable === 1) ? true : false;
        requestdata.chargeHead.isIneligibleInputCredit = (requestdata.chargeHead.isIneligibleInputCredit === 1) ? true : false;
        const keysString = chargeheadForm.chargeHeadModule?.map(item => item.value).join(',');
        const branchString = chargeheadForm.branchId?.map(item => item.value).join(',');
        requestdata.chargeHead.branchId = branchString
        requestdata.chargeHead.chargeHeadModule = keysString

        // console.log(`before ${keysString}`)

        PostCaller({
            API: UpdateChargeHead,
            callback: () => {
                navigate(`${chargehead}`)
                setIsEdit(false);

            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })

    }

    const AddAreaMasterSubmit = () => {
        console.log('keystring payload', requestdata)
        requestdata.chargeHead.isReverseChargeApplicable = (requestdata.chargeHead.isReverseChargeApplicable === 1) ? true : false;
        requestdata.chargeHead.isIneligibleInputCredit = (requestdata.chargeHead.isIneligibleInputCredit === 1) ? true : false;

        const keysString = chargeheadForm.chargeHeadModule?.map(item => item.value).join(',');
        const branchString = chargeheadForm.branchId?.map(item => item.value).join(',');

        requestdata.chargeHead.chargeHeadModule = keysString
        requestdata.chargeHead.branchId = branchString
        // console.log(`before ${keysString}`)

        PostCaller({
            API: PostChargeHead,
            callback: () => {
                navigate(`${chargehead}`)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })


    }




    return (
        <>
            <div className="hr-form-box">
                {isEdit ? <h6>Edit Chargehead</h6> : <h6>Create Chargehead</h6>}
                <div className="row">
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'name',
                                    label: 'Charges Name',
                                    type: 'text',
                                    value: chargeheadForm.name,
                                    required: true,
                                    error: chargeheadForm.nameerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'chargesCode',
                                    label: 'Charges Code',
                                    type: 'text',
                                    value: chargeheadForm.chargesCode,
                                    required: true,
                                    error: chargeheadForm.chargesCodeerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'taxRateId',
                                    label: 'TAX Slab ',
                                    type: 'select',
                                    value: chargeheadForm.taxRateId,
                                    required: true,
                                    error: chargeheadForm.taxRateIderror,
                                    item: taxrate,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'tdsSlab',
                                    label: 'TDS Slab ',
                                    type: 'select',
                                    value: chargeheadForm.tdsSlab,
                                    required: true,
                                    error: chargeheadForm.tdsSlaberror,
                                    item: tdsItem,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <AutoSelect field={{
                            id: 'systemAccountLedgerId',
                            label: 'A/c Ledger Name',
                            value: chargeheadForm.systemAccountLedgerId,
                            required: true,
                            error: chargeheadForm.systemAccountLedgerIderror,
                            item: accountledgerItem,
                            onChange: handleInputChange
                        }}
                        />
                    </div>

                    <div className="col-md-3">
                        <StackDropdown field={{
                            id: "chargeHeadModule",
                            label: 'Module',

                            value: chargeheadForm.chargeHeadModule,
                            required: true,
                            error: chargeheadForm.chargeHeadModuleerror,
                            item: ChargeExportImport,
                            onChange: handleInputChange
                        }}
                        />

                    </div>


                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'chargeHeadType',
                                    label: 'Type',
                                    type: "select",
                                    value: chargeheadForm.chargeHeadType,
                                    required: true,
                                    error: chargeheadForm.chargeHeadTypeerror,
                                    item: ChargehadeType,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "sacCodeId",
                                    label: ' SAC Code',
                                    type: "select",
                                    value: chargeheadForm.sacCodeId,
                                    required: true,
                                    error: chargeheadForm.sacCodeIderror,
                                    item: saccode,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'isReverseChargeApplicable',
                                    label: 'Is Reverse Charge Applicable ',
                                    type: "select",
                                    value: chargeheadForm.isReverseChargeApplicable,
                                    required: true,
                                    error: chargeheadForm.isReverseChargeApplicableerror,
                                    item: applicableFormat,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'isIneligibleInputCredit',
                                    label: 'Is Ineligible For Input Credit ',
                                    type: "select",
                                    value: chargeheadForm.isIneligibleInputCredit,
                                    required: true,
                                    error: chargeheadForm.isIneligibleInputCrediterror,
                                    item: applicableFormat,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <StackDropdown field={{
                            id: "branchId",
                            label: 'Branch',

                            value: chargeheadForm.branchId,
                            required: true,
                            error: chargeheadForm.branchIderror,
                            item: branchitem,
                            onChange: handleInputChange
                        }}
                        />
                    </div>

                    <div className="col-md-3">
                        <StackDropdown field={{
                            id: "fixedCharge",
                            label: 'Fixed Charges',
                            value: chargeheadForm.fixedCharge,
                            required: true,
                            error: chargeheadForm.fixedChargeerror,
                            item: FixedCharges,
                            onChange: handleInputChange
                        }}
                        />

                    </div>

                </div>

                {/* {MultipleSubGroup()} */}
            </div>



            <div className='hr-form-box'>
                <div className='row'>
                    {checkboxes.map((checkbox) => (
                        <div key={checkbox} className="col-md-3">
                            <Checkbox checked={chargeheadForm[checkbox.id]} onChange={() => { handleInputChange(!chargeheadForm[checkbox.id], checkbox) }} />{checkbox.name}

                        </div>
                    ))}
                </div>
            </div>

            <div className="cmd">
                <div></div>
                <Button
                    sx={{ m: 2, borderRadius: "10px" }}
                    onClick={handleFormSubmit}
                    variant="contained"
                >
                    Submit
                </Button>
            </div>
        </>
    );

}


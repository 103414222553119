

import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetGSAMasterByPage, GetMasterSectorByPage, PostMasterSector, UpdateMasterSector, UpdateGSAMasterStatus } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { creategoodinregister, creategsamaster, createtdsmaster, goodsoutregisterform } from '../../../navigation/constant';

import { useDispatch } from 'react-redux';
import { columnConstant } from '../../constant/formconstant';
import DivisonDrawer from '../../component/divisionDrawer';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import { GetExpectedGoodofExportsByPage, GetWerehouseGoodsInRegisterByPage, GetWerehouseGoodsOutRegisterByPage, PostExpectedGoodofExports, UpdateExpectedGoodofExports } from '../../../services/APIConstant/warehouseConstant';
import { PostCaller } from '../../../services/apiServices';
import { ChargeExportImport, DeliveryAt, EnquiryDimension, GoodsConditions, PickUpType, applicableFormat } from '../../../services/modalData';
import { Add, Download } from '@mui/icons-material';
import { CustomerTable } from '../customer/customerTable';
import { ConsigneeSubAgencyAddressForm } from '../addConsignee/consigneeAgencyAddressform';
import CreateGoodInRegister from './createGoodInRegister';
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};

const GoodOutRegisterList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [goodofExportData, setGoodofExportData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetWerehouseGoodsOutRegisterByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
        sessionStorage.removeItem('selectedGoodsOutId')
        sessionStorage.removeItem('goodsOutRegisterDetails')
    }, []);

    const setTableDataHandler = ({ response }) => {
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({




            sn: index + 1,
            id: res.id,
            pickup: res.pickup,
            pickupname: PickUpType[res.pickup - 1]?.name,
            vendorId: res.vendorId,
            vendorName: res.vendorName,
            vehicleNumber: res.vehicleNumber,
            driverName: res.driverName,
            driverNumber: res.driverNumber,
            dimensionName: EnquiryDimension[res.dimension - 1]?.name,
            packages: res.packages,
            grossWeight: res.grossWeight,
            sealNumber: res.sealNumber,
            challanNumber: res.challanNumber,
            deliveryAt: res.deliveryAt,
            deliveryAtName: DeliveryAt[res?.deliveryAt - 1]?.name,

            dimensionFilePath: res.dimensionFilePath,
            dimension: res.dimension,

            pickupVehicleTypeId: res.pickupVehicleTypeId,
            vehicleTypeName: res.vehicleTypeName,

            number: res.number,
            comment: res.comment,
            cost: res.cost,
            createdById: res.createdById,
            createdByName: res.createdByName,
            goodsCondition: res.goodsCondition,
            uploadFilePath: res.uploadFilePath,
            goodsOutDateTime: res.goodsOutDateTime,
            preTransportation: res.preTransportation,
            sealIntact: res.sealIntact,
            sealIntactName: applicableFormat[res.sealIntact - 1]?.name,
            sealIntactStatus: res.sealIntactStatus,
            slotTime: res.slotTime,
            customerBranchList: res.shippers.map(({ goodsCondition, ...res }) => ({
                ...res,
                goodsConditionName: GoodsConditions[goodsCondition - 1]?.name,
                dimensionName: EnquiryDimension[res.dimension - 1]?.name,
                id: res.id,
                nocTypes: res.nocTypes,
                airportOfDestination: res.airportOfDestination,
                airportOfLoading: res.airportOfLoading,



                allotMawbNumber: res.allotMawbNumber,
                allotHawbNumber: res.allotHawbNumber,
                shippeingBillNumber: res.shippeingBillNumber,
                shipmentDate: res.shipmentDate,
                goodsStatus: res.goodsStatus,
                slotTime: res.slotTime,
                handOverTime: res.handOverTime,
                remarks: res.remarks,

                dimension: res.dimension,
                destinationType: res.destinationType,
                dimensionFilePath: res.dimensionFilePath,
                lastHandoverTime: res.lastHandoverTime,
                actualShipper: res.actualShipper,
                uploadFilePath: res.uploadFilePath,
                cost: res.cost,
                goodsCondition: res.goodsCondition,
                shipperAddress: res.shipperAddress,

                packages: res.packages,
                grossWeight: res.grossWeight,
                totalJobPackages: res.totalJobPackages,
                totalJobGrossWeight: res.totalJobGrossWeight,
                totalJobReceivedPackages: res.totalJobReceivedPackages,
                totalJobReceivedGrossWeight: res.totalJobReceivedGrossWeight,

                showPackagesComparison: `${res.totalJobPackages}/${res.packages}`,
                showGrossWeightComparison: `${res.totalJobGrossWeight}/${res.grossWeight}`,
                numberandcomment: <div>{res.number} <br /> {res.comment}</div>

            })),


        }));

        setGoodofExportData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [

        {
            id: 'id',
            label: 'Id',
            numeric: false,
        },
        {
            id: 'pickupname',
            label: 'Dispatch',
            numeric: false,
        },
        {
            id: 'vendorName',
            label: 'Vendor Name',
            numeric: false,
        },

        {
            id: 'vehicleNumber',
            label: 'Vehicle No.',
            numeric: false,
        },


        {
            id: 'driverName',
            label: 'Driver Name',
            numeric: false,
        },

        {
            id: 'driverNumber',
            label: 'Driver No.',
            numeric: false,
        },
        {
            id: 'dimensionName',
            label: columnConstant.dimension,
            numeric: false,
        },
        {
            id: 'packages',
            label: 'Packages',
            numeric: false,
        },
        {
            id: 'grossWeight',
            label: 'Gross Weight',
            numeric: false,
        },
        {
            id: "sealNumber",
            label: 'Seal Number',
            numeric: false,
        },
        {
            id: 'challanNumber',
            label: 'Challan No',
            numeric: false,
        },
        {
            id: 'deliveryAtName',
            label: 'Delivery At',
            numeric: false,
        },
        {
            id: 'sealIntactName',
            label: 'Seal Assign',
            numeric: false,
        },

        {
            id: 'goodsOutDateTime',
            label: 'Date',
            numeric: false,
        },
        {
            id: 'slotTime',
            label: 'Slot',
            numeric: false,
        },


        {
            id: 'sealIntactStatus',
            label: 'Status',
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        {
            id: 'subtable',
            label: '',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];

    const subColumns = [
        {
            id: 'sn',
            label: '#',
            numeric: false,
        },

        {
            id: 'consoleNumber',
            label: 'Console No.',
            numeric: false,
        },
        {
            id: 'jobNumber',
            label: 'Job No.',
            numeric: false,
        },
        {
            id: 'actualShipperName',
            label: 'Shipper',
            numeric: false,
        },
        {
            id: 'partyName',
            label: 'Party',
            numeric: false,
        },
        {
            id: 'airportOfDestinationName',
            label: 'APOD',
            numeric: false,
        },


        {
            id: 'showPackagesComparison',
            label: 'Packages',
            numeric: false,
        },
        {
            id: 'showGrossWeightComparison',
            label: 'Gross Weight',
            numeric: false,
        },
        {
            id: 'allotMawbNumber',
            label: 'MAWB',
            numeric: false,
        },
        {
            id: 'shippeingBillNumber',
            label: 'Shipper Inv. No.',
            numeric: false,
        },
        {
            id: 'dimensionName',
            label: 'Dimension',
            numeric: false,
        },
        {
            id: 'tcNumber',
            label: 'TC No',
            numeric: false,
        },
        {
            id: 'slotTime',
            label: 'Slot Time',
            numeric: false,
        },
        {
            id: 'lastHandoverTime',
            label: 'LHT',
            numeric: false,
        },
        {
            id: 'goodsConditionName',
            label: 'Goods Condition',
            numeric: false,
        },
        {
            id: 'numberandcomment',
            label: 'Remark',
            numeric: false,
        },

    ];

    const subActionCell = ({ row }) => {

        return <>



        </>
    }

    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const updatedStatus = !row?.status;
            const data = {
                id: row?.id,
                status: updatedStatus
            }
            axios.get(UpdateGSAMasterStatus(data), { headers })
                .then((response) => {
                    fatch_MasterDataBYPagination()
                })
        }

        return <>
            <Tooltip title='Download Challan'>
                <IconButton  >
                    <Download />
                </IconButton>
            </Tooltip>

            <Tooltip title={columnConstant.editgoodoutregister}>
                <IconButton
                    onClick={() => {
                        // sessionStorage.setItem("updateGoodsOutListData", JSON.stringify(row))
                        sessionStorage.setItem("goodsOutRegisterDetails", JSON.stringify(row.id))
                        navigate(goodsoutregisterform)
                    }}>
                    <i className='las fa-edit'></i>
                </IconButton>
            </Tooltip >

            {/* <Tooltip title={columnConstant.goodsrecived}>
                <IconButton onClick={() => {
                    sessionStorage.setItem("goodsInRegisterListData", JSON.stringify(row))
                    navigate(creategoodinregister)
                }}>
                    <i className='las la-truck' style={{ transform: 'scaleX(-1)' }}></i>
                </IconButton>
            </Tooltip > */}

        </>


    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>

        </>
    }

    const conditions = {
        subColumns: subColumns,
        subActionCell: subActionCell
    }

    return (
        <>
            <CustomerTable
                data={goodofExportData}
                conditions={conditions}
                columns={columns}
                title={columnConstant.goodsoutregister}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />

        </>
    )
}

export default GoodOutRegisterList;




import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { GetMasterDepartmentByPage, GetMasterDesignationByPage, GetMasterDivisionByPage, GetMasterNetworkTypeByPage, GetMasterSubGroupByPage, GetReasonTypeByPage, PostMasterDepartment, PostMasterDesignation, PostMasterNetworkType, PostReasonType, UpdateMasterDesignation, UpdateMasterDesignationStatus, UpdateMasterNetworkType, UpdateMasterNetworkTypeStatus, UpdateReasonType, UpdateReasonTypeStatus } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';

const ReasonType = () => {
    const dispatch = useDispatch()
    const [networkNameMasterData, setNetworkNameMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)


    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetReasonTypeByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({

            sn: index + 1,
            id: res.id,
            reason: res.name,
            status: res.status
        }));
        setNetworkNameMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
  
        {
            id: 'reason',
            label: 'Reason Type',
            numeric: false,
        },


        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateReasonTypeStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>
            <MultiTable
                data={networkNameMasterData}
                columns={columns}
                // filterItems={filterItems}
                title={'Reason Type '}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default ReasonType




const DesignationDrawer = ({ callAPI, editor, row }) => {
    const [closeValidator, setcloseValidator] = useState(false)
    const [reasonType, setReasonType] = useState(row?.reason || '')
    const [reasonTypeError, setReasonTypeError] = useState(false)

    const dispatch = useDispatch()

    const drawerOpenCallbackHandler = () => {
        setReasonType(row?.reason || '')
        setReasonTypeError(false)
    }

    const handleSubmit = () => {
        if (!reasonType) {
            setReasonTypeError(!reasonType)
        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: reasonType,
                status: row.status
              } : {
                name: reasonType,  
              }             
              editor ?
                PostCaller({
                  API: UpdateReasonType,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
        
                :
        
                PostCaller({
                  API: PostReasonType,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
            setcloseValidator(true)
        }

    }

    useEffect(() => {
        setcloseValidator(false)

    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'reasontype':
                setReasonType(value)
                setReasonTypeError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Update Reason Type",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Update Reason Type',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Reason Type",
            tooltitle: 'Add Reason Type',
            defaultbtnfalse: true
        }

    const FieldData = [


        {
            id: 'reasontype',
            label: 'Reason Type',
            type: 'text',
            value: reasonType,
            required: true,
            error: reasonTypeError,
            helperText: 'Reason Type required',
            onChange: handleInputChange,

        },
    ];




    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody} />

    </>

}


import { Badge, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useEffect, useState } from 'react';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import DivisonDrawer from './divisionDrawer';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { AddLeadFormHandler } from '../pages/newlead';
import { AddLeadForm } from '../forms/addleadform';
import { useDispatch } from 'react-redux';
import { login } from '../../navigation/constant';
const Header = ({ callback, sideCallbackHandler }) => {

    const dispatch = useDispatch();



    const financialYearList = JSON.parse(localStorage.getItem('financialYearList'))
    const branchList = localStorage.getItem('branchList')


    const [BranchItem, setBranchItem] = useState([])
    const [DivisionItem, setDivisionItem] = useState([])


    const [branch, setBranch] = useState('');
    const [divison, setDivison] = useState('');
    const [financial, setFinancial] = useState('');

    useEffect(() => {


        const branchParseData = JSON.parse(branchList)
        const branchListFilter = branchParseData?.map((res) => ({
            key: res.id,
            value: res.name,
            divisionList: res.divisionList
        }))
        setBranchItem(branchListFilter)

        setBranch(branchParseData[0]?.id)
        setDivisionItem(branchParseData[0]?.divisionList)
        setDivison(branchParseData[0]?.divisionList[0]?.key)
        setFinancial(financialYearList[financialYearList.length - 1]?.key)


    }, [])

    useEffect(() => {
        localStorage.setItem('ENQUIRYBRANCH', branch)

        dispatch({ type: 'ENQUIRYFINANCIALYEAR', payload: financial })
        dispatch({ type: 'ENQUIRYBRANCH', payload: branch })
        dispatch({ type: 'ENQUIRYDIVISION', payload: divison })
    }, [financial, branch, divison])








    const drawerFormFields = {
        title: "Branch",
        icon: <i className="las la-ellipsis-v"></i>,
        iconclass: 'noclass',
        data: [

            {
                label: 'Branch*',
                type: "select",
                item: [
                    'Delhi',
                    'Noida',
                    'Gurgaon',
                ]
            },
            {
                label: 'Division*',
                type: "select",
                item: [
                    'Adarsh Nagar',
                    'Tilak Nagar',
                    'Ghitorni',
                ]
            },
            {
                label: 'Financial Year*',
                type: "select",
                item: [
                    '20-21',
                    '21-22',
                    '22-23',
                ]
            },


        ]

    }

    return (
        <>
            {/* <!-- top nav --> */}
            <div className="top_navbar">
                <div className="header-left-icon">
                    {/* <!-- hamburger --> */}
                    <div id="main-hamburger" className="hamburger header-circle-card " onClick={() => { callback(); sideCallbackHandler(false); }}> <a href="#"><i className="fas fa-bars"></i></a></div>

                    <div className="header-dotedNav">
                        <DivisonDrawer drawerFormFields={drawerFormFields} />
                    </div>

                    <div className="headerFilter-fieldBox">
                        <FormControl sx={{ m: 2, minWidth: 150 }} size="small">
                            <InputLabel id="demo-simple-select-label" sx={{ fontSize: 14, padding: 0 }}>Branch*</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={branch}
                                label="Branch*"
                                className='select'
                                onChange={(e) => {
                                    const SelectedBranch = BranchItem?.find(res => res.key === e.target.value)
                                    setDivisionItem(SelectedBranch?.divisionList)
                                    setBranch(e.target.value)

                                }}
                                sx={{ fontSize: 14, padding: 0 }}
                            >
                                {/* <MenuItem value={1} sx={{ fontSize: 16, '&:hover': { backgroundColor: 'lightblue' } }}>Delhi</MenuItem> */}
                                {
                                    BranchItem?.map((res, index) => {
                                        return <MenuItem key={index} value={res.key}>{res.value}</MenuItem>
                                    })
                                }

                            </Select>
                        </FormControl>




                        <FormControl sx={{ m: 2, minWidth: 150 }} size="small">
                            <InputLabel id="demo-simple-select-label" sx={{ fontSize: 14, padding: 0 }}>Division*</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={divison}
                                className='select'
                                label="Division"
                                onChange={(e) => { setDivison(e.target.value) }}
                                sx={{ fontSize: 14, padding: 0 }}
                            >
                                {
                                    DivisionItem?.map((res,index) => {
                                        return <MenuItem key={index} value={res.key}>{res.value}</MenuItem>

                                    })
                                }

                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 2, minWidth: 150 }} size="small">
                            <InputLabel id="demo-simple-select-label" sx={{ fontSize: 14, padding: 0 }}>Financial Year*</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={financial}
                                className='select'
                                label="Financial Year*"
                                onChange={(e) => { setFinancial(e.target.value) }}
                                sx={{ fontSize: 14, padding: 0 }}
                            >
                                {
                                    financialYearList?.map((res, index) => {
                                        return <MenuItem key={index} value={res.key}>{res.value}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                    </div>


                </div>

                <div>
                    <AccountMenu />
                </div>

            </div>
            {/* <!-- topnav end  --> */}

        </>
    )
}

export default Header;






export function AccountMenu() {
    const [anchorEl, setAnchorEl] = useState(false);
    const [anchorE2, setAnchorE2] = useState(false);
    const [anchorE3, setAnchorE3] = useState(false);
    const [anchorE4, setAnchorE4] = useState(false);
    const [divisonValidator, setDivisionValidator] = useState(false);
    const [enquiryValidator, setEnquiryValidator] = useState(false);

    const logOut = () => {
        localStorage.removeItem('authToken');
        window.location.href = login
    }

    const handleClose = () => {
        setAnchorEl(false);
        setAnchorE2(false);
        setAnchorE3(false);
        setAnchorE4(false)
    };


    const drawerFormFields = {
        title: "Add Lead",
        tooltitle: "Add Lead",
        defaultbtnfalse: true,
        width: 450,
        leadform: true,
        iconclass: "d-none",
    }

    const enquiryData = {
        title: "New Enquiry",
        iconclass: "d-none",
        width: 450,
        data: [

            {
                label: 'Type Of Shipment*',
                type: "select",
                grid: 'col-md-6',
                item: [
                    'Air Import',
                    'Air Export',
                    'Ocean Import',
                    'Ocean Export',
                    'RFQ'
                ]
            },

            {
                label: 'Biz. Type*',
                type: "select",
                grid: 'col-md-6',
                item: [
                    'Direct Party',
                    'Sub Agent',
                    'Overseas Network Biz.',
                    'Overseas Direct Customer',
                    'Future Potential Customer'
                ]
            },
            {
                label: 'Organization Name*',
                grid: 'col-md-6',
                type: "input"
            },
            {
                label: 'Mobile No*',
                grid: 'col-md-6',
                type: "input"
            },
            {
                label: 'Address*',
                grid: 'col-md-6',
                type: "input"
            },
            {
                label: 'Email*',
                grid: 'col-md-6',
                type: "input"
            },
            {
                label: 'Referred By',
                grid: 'col-md-6',
                type: "input"
            },

            {
                label: 'Upload Document',
                grid: 'col-md-6',
                type: "upload"
            },
            {
                label: 'Special Requirements/Remarks',
                type: "textarea"
            },
            {
                label: 'Record',
                type: "audio"
            }


        ]

    }


    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                {/* <Typography sx={{ minWidth: 100 }}>  </Typography> */}

                <ShowFullScreen />

                <Tooltip title="Notification">
                    <IconButton
                        onClick={(e) => { setAnchorE2(e.currentTarget) }}
                        size="small"
                        sx={{ ml: 1 }}
                        aria-controls={anchorE2 ? 'account-notification' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorE2 ? 'true' : undefined}
                    >
                        <Badge color="secondary" overlap="circular" badgeContent=" " variant="dot">
                            <Avatar sx={{ width: 32, height: 32 }}>
                                <NotificationImportantOutlinedIcon />
                            </Avatar>

                        </Badge>
                    </IconButton>
                </Tooltip>

                <Tooltip title="Message">
                    <IconButton
                        onClick={(e) => setAnchorE3(e.currentTarget)}
                        size="small"
                        sx={{ ml: 1 }}
                        aria-controls={anchorE3 ? 'account-message' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorE3 ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}><ChatBubbleOutlineOutlinedIcon /></Avatar>
                    </IconButton>
                </Tooltip>

                <Tooltip title="dashboard">
                    <IconButton
                        onClick={(e) => setAnchorE4(e.currentTarget)}
                        size="small"
                        sx={{ ml: 1 }}
                        aria-controls={anchorE4 ? 'account-dashboard' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorE4 ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}><GridViewOutlinedIcon /></Avatar>
                    </IconButton>
                </Tooltip>

                {/* <DivisonDrawer drawerFormFields={drawerFormFields} divisonValidator={divisonValidator} callback={() => setDivisionValidator(false)} divisiondrawerbody={AddLeadFormHandler} /> */}
                {/* <AddLeadForm /> */}
                <DivisonDrawer drawerFormFields={enquiryData} divisonValidator={enquiryValidator} callback={() => setEnquiryValidator(false)} />

                <Tooltip title="Account settings">
                    <IconButton
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        size="small"
                        sx={{ ml: 1 }}
                        aria-controls={anchorEl ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? 'true' : undefined}
                    >
                        <Avatar alt="Remy Sharp" src={require('../assets/img/1.png')} />
                    </IconButton>
                </Tooltip>

            </Box>




            {/* Account Menu  */}
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={anchorEl}
                onClose={() => setAnchorEl(false)}
                onClick={() => setAnchorEl(false)}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    <Avatar /> Profile
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Avatar /> My account
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Add another account
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={logOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>


            {/* NOtification Menu  */}
            <Menu
                anchorEl={anchorE2}
                id="account-notification"
                open={anchorE2}
                onClose={() => setAnchorE2(false)}
                onClick={() => setAnchorE2(false)}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: 300,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    <div className='menuitem-content'>
                        <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis, praesentium.
                        </p>
                        <span>
                            17 October 2016 | 9:32 pm
                        </span>
                    </div>

                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <div className='menuitem-content'>
                        <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis, praesentium.
                        </p>
                        <span>
                            17 October 2016 | 9:32 pm
                        </span>
                    </div>

                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <div className='menuitem-content'>
                        <p>
                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis, praesentium.
                        </p>
                        <span>
                            17 October 2016 | 9:32 pm
                        </span>
                    </div>

                </MenuItem>
                <Divider />



            </Menu>

            {/* Account Message  */}
            <Menu
                anchorEl={anchorE3}
                id="account-message"
                open={anchorE3}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                    Messages
                </MenuItem>

            </Menu>

            {/* Account Dashboard  */}
            <Menu
                anchorEl={anchorE4}
                id="account-dashboard"
                open={anchorE4}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem >
                    <div className="header-icon-dash">
                        <div className="cmd" style={{ justifyContent: "space-around" }}>
                            <div onClick={() => { setDivisionValidator(true) }}>
                                <IconButton>
                                    <ArticleOutlinedIcon ></ArticleOutlinedIcon>
                                </IconButton>
                                <p>New Lead</p>
                            </div>

                            <div onClick={() => { setEnquiryValidator(true) }}>
                                <IconButton>
                                    <i className="las la-search-dollar "></i>
                                </IconButton>
                                <p>New Enquiry</p>
                            </div>
                        </div>
                    </div>
                </MenuItem>

            </Menu>



        </>
    );
}




export const ShowFullScreen = () => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    useEffect(() => {
        const fullScreenChangeHandler = () => {
            setIsFullScreen(!!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement));
        };

        document.addEventListener('fullscreenchange', fullScreenChangeHandler);
        document.addEventListener('mozfullscreenchange', fullScreenChangeHandler);
        document.addEventListener('webkitfullscreenchange', fullScreenChangeHandler);
        document.addEventListener('MSFullscreenChange', fullScreenChangeHandler);

        return () => {
            document.removeEventListener('fullscreenchange', fullScreenChangeHandler);
            document.removeEventListener('mozfullscreenchange', fullScreenChangeHandler);
            document.removeEventListener('webkitfullscreenchange', fullScreenChangeHandler);
            document.removeEventListener('MSFullscreenChange', fullScreenChangeHandler);
        };
    }, []);
    return <>
        <Tooltip title="Full Screen">
            <IconButton
                className='avtarbtn1'
                onClick={toggleFullScreen}
                size="small"
                sx={{ ml: 1 }}
            >
                <Avatar sx={{ width: 32, height: 32 }}><i className=" iconListBox las la-expand"></i></Avatar>
            </IconButton>
        </Tooltip>
    </>
}
import { BaseURL } from "../constant";

//StockRegister
export const GetWarehouseStockRegisterByPage = (item) => `${BaseURL.URL}Warehouse/StockRegister?status=${item.status || false}&date=${item.date || ""}&itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}`;
export const PostWarehouseStockRegister = () => `${BaseURL.URL}Warehouse/StockRegister`;
export const UpdateWarehouseStockRegister = () => `${BaseURL.URL}Warehouse/UpdateStockRegister`;
export const UpdateWarehouseStockRegisterStatus = (item) => `${BaseURL.URL}Warehouse/StockRegisterStatus?id=${item.id}`;

//ExpectedGoodofExports
export const GetExpectedGoodofExportsByPage = (item) => `${BaseURL.URL}Warehouse/ExpectedGoods?status=${item.status || false}&date=${item.date || ""}&itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const PostExpectedGoodofExports = () => `${BaseURL.URL}Warehouse/ExpectedGoods`;
export const UpdateExpectedGoodofExports = () => `${BaseURL.URL}Warehouse/UpdateExpectedGoods`;
export const UpdateExpectedGoodofExportsStatus = (item) => `${BaseURL.URL}Warehouse/ExpectedGoodsStatus?id=${item.id}`;

//GooodsIn Register
export const GetWarehouseGoodsInRegisterById = (item) => `${BaseURL.URL}Warehouse/GoodsInRegister/${item?.id}`;
export const GetWerehouseGoodsInRegisterByPage = (item) => `${BaseURL.URL}Warehouse/GoodsInRegister?status=${item.status || false}&date=${item.date || ""}&itemsPerPage=${item.itemsPerPage || ''}&page=${item.page || ''}`;
export const PostWerehouseGoodsInRegister = () => `${BaseURL.URL}Warehouse/GoodsInRegister`;
export const UpdateWerehouseGoodsInRegister = () => `${BaseURL.URL}Warehouse/UpdateGoodsInRegister`;
export const UpdateWerehouseGoodsInRegisterStatus = (item) => `${BaseURL.URL}Warehouse/GoodsInRegisterStatus?id=${item.id}`;
export const CancelVehicleForGoodsIn = (item) => `${BaseURL.URL}Warehouse/CancelledVehicle?vehicleId=${item.vehicleId}&entryId=${item.entryId}&remark=${item.remark}&GoodsStatus=${item.GoodsStatus}`
export const UpdateGoodsInVehicleReceived = () => `${BaseURL.URL}Warehouse/GoodsInVehicleReceived/Update`;
//Gooods Out Register
export const GetWarehouseGoodsOutRegisterById = (item) => `${BaseURL.URL}Warehouse/GoodsOutRegister/${item?.id}`;
export const GetWerehouseGoodsOutRegisterByPage = (item) => `${BaseURL.URL}Warehouse/GoodsOutRegister?status=${item.status || false}&date=${item.date || ""}&itemsPerPage=${item.itemsPerPage || ""}&page=${item.page || ''}`;
export const PostWerehouseGoodsOutRegister = () => `${BaseURL.URL}Warehouse/GoodsOutRegister`;
export const UpdateWerehouseGoodsOutRegister = () => `${BaseURL.URL}Warehouse/UpdateGoodsOutRegister`;
export const UpdateWerehouseGoodsOutRegisterStatus = (item) => `${BaseURL.URL}Warehouse/GoodsOutRegisterStatus?id=${item.id}`;

// airportlist Shipper Data

export const GetAirportListShipperData = (item) => `${BaseURL.URL}Airport/GetShipperData?status=${item.status || false}&date=${item.date || ""}&itemsPerPage=${item.itemsPerPage}&page=${item.page}`;
export const GetAiportStatusData = (item) => `${BaseURL.URL}Airport/GetAiportStatusData?jobId=${item.id}`;
export const GetTransportDataByJob = (item) => `${BaseURL.URL}Airport/GetTransportData?jobNumber=${item.id}`;
export const UpdateAirportSealstatus = () => `${BaseURL.URL}Airport/UpdateSealStatus`;
export const UpdateJobStatusByAirport = (item) => `${BaseURL.URL}Airport/UpdateJobStatus`;





// Unused Or not need Full

export const PostAirportchecklist = () => `${BaseURL.URL}Airport/AirportCheckList`;
export const PostAirportvehical = () => `${BaseURL.URL}Airport/VehicleStatus`;
export const PostAirportSealstatus = () => `${BaseURL.URL}Airport/SealStatus`;



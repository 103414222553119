import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import RouterConfig from "./navigation/routerConfig";
import "./mycomponent/css/sidebar.css";
import "./mycomponent/css/header.css";
import "./mycomponent/css/auth.css";
import "./mycomponent/css/table.css";
import "./mycomponent/css/form.css";
import "./mycomponent/css/config.css";
import Loader from "./mycomponent/component/controls/loader";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const loader = useSelector((state) => state.loader);

  return (
    <div className="App">
      {loader && (
        <div className="fcca">
          <Loader />
        </div>
      )}
      <RouterConfig />

    </div>
  );
}

export default App;

import React, { useState } from 'react';
import Dropdown, { InputField } from '.';
import { Button, ListItem } from '@mui/material';

const ControlDynamicForm = ({ FieldData, handleSubmit, handleCancel }) => {


  return (
    <>
      <div className='w-100'>
        <div className="row">
          {FieldData.map((field, index) => {

            return <div className="col-md-12">

              {
                field?.isHide ? '' :
                  field.type === 'select' ?
                    <Dropdown key={index} field={field} />
                    :

                    <InputField key={index} field={field} />
              }
            </div>
          })}
        </div>
        <ListItem className='sidebar-buttonBox' disablePadding sx={{ justifyContent: "center" }}>
          <Button sx={{ m: 2, borderRadius: "10px" }} variant="outlined" onClick={handleCancel}>Cancel</Button>
          <Button sx={{ m: 2, borderRadius: "10px" }} type='submit' onClick={handleSubmit} variant="contained">Submit</Button>
        </ListItem>
      </div>
    </>
  );
};

export default ControlDynamicForm;






































// import React, { useState } from 'react';
// import Dropdown, { InputField } from '.';
// import { Button, FormControl, FormHelperText, InputLabel, ListItem, MenuItem, Select, TextField } from '@mui/material';

// const ControlDynamicForm = ({ FieldData, handleSubmit }) => {
//   const [data, setState] = useState(FieldData)

//   const submitForm = (event) => {
//     if (validateForm()) {
//       handleSubmit(data);
//     }
//   };

//   const validateForm = () => {
//     var isValidated = true;

//     data && data.map((field, index) => {
//       validateField(field);
//       if (field.error) {
//         isValidated = false;
//       }
//     });

//     setDataState();

//     return isValidated;
//   };


//   const validateField = (field) => {
//     if (field.required && field.value) {
//       field.error = false;
//       field.helperText = "";
//     } else {
//       field.error = true;
//       field.helperText = field.validationText;
//     }
//   };

//   const handleInputChange = (e, field) => {
//     field.value = e.target.value;
//     validateField(field);
//     setDataState();
//   }

//   const setDataState = () => {
//     setState((data) => {
//       const newData = [];

//       for (const item in data) {
//         if (data[item]) {
//           newData.push({ ...data[item], select: true });
//         } else {
//           newData.push({ ...data[item] });
//         }
//       }
//       return newData;
//     })}

//     return (
//       <>
//         <div className='w-100'>
//           {FieldData.map((field, index) => (
//             field.type === 'select' ?
//               <FormControl className='mt-3' sx={{ width: '100%', fontSize: '13px' }} size="small"
//                 error={field.error}
//               >
//                 <InputLabel id={`label-${field.label}`}>{field.label}</InputLabel>
//                 <Select
//                   labelId={`label-${field.label}`}
//                   id={`select-${field.label}`}
//                   name={field.label}
//                   defaultValue={field.value}
//                   label={field.label}
//                   required={field.required}
//                   onChange={(e) =>
//                     handleInputChange(e, field)}
//                 >
//                   {
//                     field.item && field.item.map((item, index) => {
//                       return <MenuItem value={item?.value ? item?.value : item} key={index} >{item?.name ? item?.name : item}</MenuItem>
//                     })
//                   }
//                 </Select>
//                 <FormHelperText>{field.error && field.required && field.helperText}</FormHelperText>
//               </FormControl>
//               :
//               <TextField className='mt-3'
//                 sx={{ width: '100%', fontSize: '13px' }} fullWidth size="small"

//                 id={field.id}
//                 label={field.label}
//                 defaultValue={field.value}
//                 error={field.error}
//                 helperText={field.error && field.required && field.helperText}
//                 required={field.required}
//                 type={field.type}
//                 onChange={(e) =>
//                   handleInputChange(e, field)}
//               />
//           ))}

//           <ListItem disablePadding sx={{ justifyContent: "center" }}>
//             {/* <Button sx={{ m: 2, borderRadius: "10px" }} onClick={toggleDrawer('right', false)} variant="outlined">Cancel</Button> */}
//             <Button sx={{ m: 2, borderRadius: "10px" }} type='submit' onClick={submitForm} variant="contained">Submit</Button>
//           </ListItem>
//         </div>
//       </>
//     );
//   };

//   export default ControlDynamicForm;


import React, { useState, useEffect } from 'react'
import { MultiTable } from '../../component/multiTable';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { GetMasterDepartmentByPage, GetMasterDesignationByPage, PostMasterDepartment, PostMasterDesignation, UpdateMasterDesignation, UpdateMasterDesignationStatus } from '../../../services/constant';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { SmallSwitch } from '../../component/controls';

const Designation = () => {
    const dispatch = useDispatch()
    const [designationMasterData, setDesignationMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)


    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMasterDesignationByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({

            sn: index + 1,
            id: res.id,
            designation: res.name,
            department: res.department?.value,
            departmentid: res.department?.key,
            status: res.status
        }));
        setDesignationMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },

        {
            id: 'department',
            label: 'Department',
            numeric: false,
        },
        {
            id: 'designation',
            label: 'Designation',
            numeric: false,
        },

        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];



    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateMasterDesignationStatus,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({ response }) => {
                    fatch_MasterDataBYPagination();
                }
            });
        }

        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
            <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>


    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <DesignationDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>
            <MultiTable
                data={designationMasterData}
                columns={columns}
                // filterItems={filterItems}
                title={'Designation'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    )
}

export default Designation




const DesignationDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()
    const [departmentItem, setDepartmentItem] = useState([])
    const [closeValidator, setcloseValidator] = useState(false)
    const [designationName, setDesignationName] = useState(row?.designation || '')
    const [designationNameError, setDesignationNameError] = useState(false)
    const [departmentName, setDepartmentName] = useState(row?.departmentid || '')
    const [departmentNameError, setDepartmentNameError] = useState(false)


    const drawerOpenCallbackHandler = () => {
        setDesignationName(row?.designation || '')
        setDepartmentName(row?.departmentid || '')
        setDesignationNameError(false)
        setDepartmentNameError(false)
    }

    const handleSubmit = () => {
        if (!designationName || !departmentName) {
            setDesignationNameError(!designationName)
            setDepartmentNameError(!departmentName)

        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                name: designationName,
                departmentId: departmentName,
                status: row.status
            } : {
                name: designationName,
                departmentId: departmentName,
            }
            editor ?
                PostCaller({
                    API: UpdateMasterDesignation,
                    callback: () => callAPI(),
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: postmasterareaData
                })
                :
                PostCaller({
                    API: PostMasterDesignation,
                    callback: () => callAPI(),
                    dispatch: (e) => {
                        dispatch({ type: e.type, payload: e.payload })
                    },
                    item: postmasterareaData
                })
            setcloseValidator(true)

        }

    }
    const set_DepartmentItemData = () => {
        GetCaller({
            API: PostMasterDepartment,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newDepartmentItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setDepartmentItem(newDepartmentItemData)
            }
        })

    }


    useEffect(() => {
        set_DepartmentItemData()
    }, []);

    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);

    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'designation':
                setDesignationName(value)
                setDesignationNameError(!value);
                break;
            case 'department':
                setDepartmentName(value)
                setDepartmentNameError(!value);
                break;
            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: "Edit Designation",
            icon: <i className="las la-edit"></i>,
            tooltitle: 'Edit Designation',
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: "Add Designation",
            tooltitle: 'Add Designation',
            defaultbtnfalse: true
        }

    const FieldData = [
        {
            id: 'department',
            label: 'Department Name',
            type: 'select',
            value: departmentName,
            required: true,
            error: departmentNameError,
            helperText: 'Department required',
            onChange: handleInputChange,
            item: departmentItem
        },
        {
            id: 'designation',
            label: 'Designation Name',
            type: 'text',
            value: designationName,
            required: true,
            error: designationNameError,
            helperText: 'Designation required',
            onChange: handleInputChange,
        },
    ];

    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}


import React from "react";
import { useEffect, useState } from "react";
import axios from 'axios';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    GetMasterCityByPage,
    UpdateVendorMaster,
    GetMasterCountryByPage,
    GetMasterBizInstituteTypeByPage,
    GetMasterAccountLedgerByPage,
    GetTDSMasterByPage,
    GetMasterStateByPage,
    PostVendorMaster,
    GetMasterVendorTypeByPage,
    PostGSTStatus,
    GetVendorMasterById,
    PostMasterAccountLedger,
    GetCustomerBySearch,
    PostMasterCity
} from "../../services/constant";

import { applicableFormat } from '../../services/modalData';
import GetCaller, { PostCaller } from '../../services/apiServices';
import Dropdown, { AutoSelect, InputField, StackDropdown } from '../component/controls';
import { vendormaster } from '../../navigation/constant';
import CheckMail from '../../services/utilities/checkEmailFormat';

const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};



export const VendorForm = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);
    const [cityItem, setCityItem] = useState([]);
    const [stateItem, setStateItem] = useState([]);
    const [Vendortyp, setVendortyp] = useState([]);
    const [countryItem, setCountryItem] = useState([]);
    const [tdsItem, setTDSItem] = useState([]);
    const [vendorTypeItem, setVendorTypeItem] = useState([]);
    const [accountLedgerItem, setAccountLedgerItem] = useState([]);
    const [bizInstituteItem, setBizInstituteItem] = useState([]);
    const [gstStatus, setGSTStatus] = useState([]);
    const [gstCode, setGstCode] = useState('')
    const [customeritem, setCustomerItem] = useState([]);
    const enquirybranch = useSelector(state => state.enquirybranch)
    const [vendorForm, setVendorForm] = useState({

        id: 0,
        name: "",
        bizInstituteTypeId: "",
        otherBizInstituteType: "",
        isPanAvailable: "",
        isTDSApplicable: "",
        accountLedgerId: "",
        nameOnCheck: "",
        parentCompanyId: "",
        iataCode: "",
        countryId: "",
        stateId: "",
        cityId: "",
        address1: "",
        address2: "",
        address3: "",
        pinCode: "",
        mobileNumber: "",
        panNumber: "",
        tanNumber: "",
        cin: "",
        email: "",
        contactPerson: "",
        gstStatus: "",
        gstNumber: "",
        tdsId: null,
        creditPeriodDays: "",
        vendorTypeIds: []
    })


    const handleInputChange = (value, field) => {


        if (field.id === 'cityId') {
            set_CityItemData(value);

            cityItem?.map((res) => {
                if (res.value === value) {

                    setGstCode(res.gstCode)
                }
            })
        }

        setVendorForm({
            ...vendorForm,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
    };


    const requestdata = (() => {

        const data = {
            id: isEdit,
            status: true,
            vendor: {
                branchId: enquirybranch,
                name: vendorForm.name,
                bizInstituteTypeId: vendorForm.bizInstituteTypeId,
                otherBizInstituteType: vendorForm.otherBizInstituteType,
                isPanAvailable: vendorForm.isPanAvailable,
                isTDSApplicable: vendorForm.isTDSApplicable,
                accountLedgerId: vendorForm.accountLedgerId,
                nameOnCheck: vendorForm.nameOnCheck,
                iataCode: vendorForm.iataCode,
                cityId: vendorForm.cityId,
                address1: vendorForm.address1,
                address2: vendorForm.address2,
                address3: vendorForm.address3,
                pinCode: vendorForm.pinCode,
                mobileNumber: vendorForm.mobileNumber,
                tanNumber: vendorForm.tanNumber,
                cin: vendorForm.cin,
                email: vendorForm.email,
                contactPerson: vendorForm.contactPerson,
                gstStatus: vendorForm.gstStatus,
                gstNumber: vendorForm.gstNumber,
                creditPeriodDays: vendorForm.creditPeriodDays,
                vendorTypeIds: vendorForm.vendorTypeIds,
            }
        }
        if (vendorForm.isPanAvailable === 1) data.vendor.panNumber = vendorForm.panNumber
        if (vendorForm.isTDSApplicable === 1) data.vendor.tdsId = vendorForm.tdsId

        if (vendorForm.parentCompanyId) data.vendor.parentCompanyId = vendorForm.parentCompanyId
        if (vendorForm.gstStatus === 2) data.vendor.gstNumber = "Unregistered"


        return data;
    })();



    const AddVendorSubmit = (requestdata) => {
         
        // Extracting keys from the array of objects and joining them with commas
        const keysString = vendorForm?.vendorTypeIds?.map(item => item?.value)?.join(',');
        // Updating the requestdata object
        requestdata.vendor.vendorTypeIds = keysString;
        console.log('Updated requestdata:', requestdata);

        requestdata.vendor.isPanAvailable = requestdata.vendor.isPanAvailable === 2 ? false : true;
        requestdata.vendor.isTDSApplicable = requestdata.vendor.isTDSApplicable === 2 ? false : true;
        // requestdata.vendor.gstNumber = requestdata.vendor.gstNumber ===
        PostCaller({
            API: PostVendorMaster,
            callback: () => {
                navigate(`${vendormaster}`)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })

    }

    const EditVenderSubmit = (requestdata) => {
        console.log('Updated requestdata:', requestdata);
        const keysString = vendorForm?.vendorTypeIds?.map(item => item?.value)?.join(',');
        // Updating the requestdata object
        requestdata.vendor.vendorTypeIds = keysString;
        requestdata.vendor.isPanAvailable = requestdata.vendor.isPanAvailable === 2 ? false : true;
        requestdata.vendor.isTDSApplicable = requestdata.vendor.isTDSApplicable === 2 ? false : true;

        PostCaller({
            API: UpdateVendorMaster,
            callback: () => {
                navigate(`${vendormaster}`)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })
    }


    const handleFormSubmit = () => {

        const hasUndefinedValue = (obj) => {
            let changes = {};
             
            Object.entries(obj).forEach(([key, value]) => {
                 
                if ((key == 'id' ||
                    key == 'status' ||
                    key == 'address2' ||
                    key == 'address3' ||
                    key == 'countryId' ||
                    key == 'stateId' ||
                    key === 'nameOnCheck' ||
                    key === 'parentCompanyId' ||
                    (vendorForm.isPanAvailable !== 1 && key === 'panNumber') ||
                    (vendorForm.isTDSApplicable !== 1 && key === 'tdsId') ||
                    (bizInstituteItem[vendorForm.bizInstituteTypeId - 1]?.name.toLowerCase() !== 'other' && key === 'otherBizInstituteType') ||
                    (vendorForm.gstStatus === 2 && key === 'gstNumber')

                )) {

                }
                else if (key === 'vendorTypeIds' && value.length === 0) {
                    changes[key + 'error'] = true;
                }
                else if ((!value && typeof value !== 'boolean')) {
                    changes[key + 'error'] = true;
                }
            });

            return changes;
        };

        const changes = hasUndefinedValue(requestdata.vendor);

        const isMail = CheckMail(requestdata?.vendor?.email)

        if (Object.keys(changes).length > 0 || isMail) {
             
            setVendorForm({
                ...vendorForm, ...changes,
                ['emailerror']: isMail
            });
        }
        else if (isEdit) {
            EditVenderSubmit(requestdata);
        }
        else {
            AddVendorSubmit(requestdata);

        }

    };

    const set_Customertype = () => {
        GetCaller({
            API: GetCustomerBySearch,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.legalName
                }))

                setCustomerItem(newCountryItemData)
            }

        })
    }
    const set_VendorTypeItemData = (id) => {

        GetCaller({
            API: GetMasterVendorTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newVendorItemData = []
                response?.data?.data?.records?.map((item) => {

                    newVendorItemData.push({
                        value: item.id,
                        name: item.name,

                    })

                })
                setVendorTypeItem(newVendorItemData)
            }

        })
    }


    const set_CityItemData = () => {
        GetCaller({
            API: PostMasterCity,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                 
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                    gstCode: item.gstCode
                }))
                setCityItem(newCityItemData)
            }

        })

    }


    const set_GstStatus = (e) => {
        GetCaller({
            API: PostGSTStatus,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const item = []
                response?.data?.data?.records?.map(res => {
                    item.push({
                        value: res.id,
                        name: res.gstStatus
                    })
                })
                setGSTStatus(item)
            }

        });
    };


    const set_BizInstituteItemData = (e) => {

        GetCaller({
            API: GetMasterBizInstituteTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newVendorItem = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                }));

                setBizInstituteItem(newVendorItem);
            }

        })
    };

    const set_AccountLedgerItemData = (id) => {
        GetCaller({
            API: GetMasterAccountLedgerByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newVendorItemData = []
                response?.data?.data?.records.map((item) => {
                    newVendorItemData.push({
                        value: item.id,
                        name: item.name,
                    })

                });

                setAccountLedgerItem(newVendorItemData);
            }

        })
    };

    const set_TDSItemData = (e) => {
        GetCaller({
            API: GetTDSMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newVendorItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name,
                }));

                setTDSItem(newVendorItemData);
            }

        })

    };


    useEffect(() => {
        set_CityItemData();
        set_GstStatus();
        set_BizInstituteItemData();
        set_TDSItemData();
        set_Customertype()

        if (sessionStorage.vendor) {
            let vendorid = sessionStorage.getItem("vendor")
            console.log(vendorid)
            GetCaller({
                API: GetVendorMasterById,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: { id: vendorid },
                callback: ({ response }) => {
                    let vendor = response?.data?.data
                    console.log(vendor)
                    GetCaller({
                        API: PostMasterAccountLedger,
                        dispatch: (e) => {
                            dispatch({ type: e.type, payload: e.payload })
                        },
                        item: {},
                        callback: ({ response }) => {
                            const newVendorItemData = []
                            response?.data?.data?.records.map((item) => {
                                newVendorItemData.push({
                                    value: item.id,
                                    name: item.name,
                                })
                            });

                            setAccountLedgerItem(newVendorItemData);
                        }

                    })

                    GetCaller({
                        API: GetMasterVendorTypeByPage,
                        dispatch: (e) => {
                            dispatch({ type: e.type, payload: e.payload })
                        },
                        item: {},
                        callback: ({ response }) => {
                            const newVendorItemData = []
                            response?.data?.data?.records.map((item) => {

                                newVendorItemData.push({
                                    value: item.id,
                                    name: item.name,

                                })

                            })
                            const revertchargevendortype = vendor.vendorTypeIds.split(',')
                            const filteredData = newVendorItemData.filter(item => revertchargevendortype.includes(item.value.toString()));
                             
                            vendor.vendorTypeIds = filteredData

                            setVendorTypeItem(newVendorItemData)

                        }

                    })



                    vendor.isPanAvailable = vendor.isPanAvailable === false ? 2 : 1
                    vendor.isTDSApplicable = vendor.isTDSApplicable === false ? 2 : 1
                    vendor.gstNumber = vendor.gstStatus === 2 ? '' : vendor.gstNumber
                     
                    setVendorForm(vendor)

                }

            });

            setIsEdit(vendorid);
            sessionStorage.removeItem('vendor');


        } else {
            set_CityItemData();
            set_AccountLedgerItemData();
            set_VendorTypeItemData();

        }

    }, []);

    useEffect(() => {
        stateItem?.map(res => {
            if (res.value === vendorForm.stateId) {
                setGstCode(res.gstCode)
            }
        })
    }, [stateItem])



    return (
        <>
            <div className="hr-form-box">
                {isEdit ? <h6>Edit Vendor</h6> : <h6>Create Vendor</h6>}
                <div className="row">
                    <div className="col-md-3">
                        <Dropdown
                            field={

                                {
                                    id: 'bizInstituteTypeId',
                                    label: 'Biz Institute Type ',
                                    type: 'select',
                                    value: vendorForm.bizInstituteTypeId,
                                    required: true,
                                    error: vendorForm.bizInstituteTypeIderror,
                                    item: bizInstituteItem,
                                    disable: isEdit ? true : false,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    {
                        bizInstituteItem && bizInstituteItem[vendorForm.bizInstituteTypeId - 1]?.name.toLowerCase() === 'other' &&
                        <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: 'otherBizInstituteType',
                                        label: 'Type of company(Specify other type)',
                                        type: 'text',
                                        disable: isEdit ? true : false,
                                        value: vendorForm.otherBizInstituteType,
                                        required: true,
                                        error: vendorForm.otherBizInstituteTypeerror,
                                        onChange: handleInputChange
                                    }
                                }
                            />
                        </div>
                    }

                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'isPanAvailable',
                                    label: 'PAN Available ',
                                    type: 'select',
                                    value: vendorForm.isPanAvailable,
                                    required: true,
                                    error: vendorForm.isPanAvailableerror,
                                    item: applicableFormat,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    {
                        vendorForm.isPanAvailable === 1 && <div className="col-md-3">
                            <InputField
                                field={
                                    {
                                        id: 'panNumber',
                                        label: 'Pan Number ',
                                        type: 'text',
                                        value: vendorForm.panNumber,
                                        required: true,
                                        error: vendorForm.panNumbererror,
                                        onChange: handleInputChange
                                    }
                                }
                            />
                        </div>
                    }

                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'isTDSApplicable',
                                    label: 'TDS Applicable ',
                                    type: 'select',
                                    value: vendorForm.isTDSApplicable,
                                    required: true,
                                    error: vendorForm.isTDSApplicableerror,
                                    item: applicableFormat,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    {
                        vendorForm.isTDSApplicable === 1 &&
                        <div className="col-md-3">
                            <Dropdown
                                field={
                                    {
                                        id: 'tdsId',
                                        label: 'TDS Section ',
                                        type: 'select',
                                        value: vendorForm.tdsId,
                                        required: true,
                                        error: vendorForm.tdsIderror,
                                        item: tdsItem,
                                        onChange: handleInputChange
                                    }
                                }
                            />
                        </div>
                    }

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'name',
                                    label: 'Legal Name',
                                    type: 'text',
                                    value: vendorForm.name,
                                    required: true,
                                    error: vendorForm.nameerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">

                        <AutoSelect
                            field={{
                                id: 'accountLedgerId',
                                label: 'A/c Ledger Name',
                                value: vendorForm.accountLedgerId,
                                required: true,
                                error: vendorForm.accountLedgerIderror,
                                item: accountLedgerItem,
                                onChange: handleInputChange

                            }}
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'nameOnCheck',
                                    label: 'Name On Cheque',
                                    type: "text",
                                    value: vendorForm.nameOnCheck,
                                    required: false,
                                    error: vendorForm.nameOnCheckerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <AutoSelect
                            field={
                                {
                                    id: "parentCompanyId",
                                    label: 'Parent Company',
                                    value: vendorForm.parentCompanyId,
                                    required: false,
                                    error: vendorForm.parentCompanyIderror,
                                    item: customeritem,
                                    onChange: handleInputChange
                                }
                            }
                        />

                    </div>


                    <div className="col-md-3">
                        <StackDropdown field={{
                            id: "vendorTypeIds",
                            label: 'Vendor Type ',
                           
                            value: vendorForm.vendorTypeIds,
                            required: true,
                            error: vendorForm.vendorTypeIdserror,
                            item: vendorTypeItem,
                            onChange: handleInputChange
                        }} />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'iataCode',
                                    label: 'IATA Code',
                                    type: "text",
                                    value: vendorForm.iataCode,
                                    required: true,
                                    error: vendorForm.iataCodeerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: "cityId",
                                    label: ' City ',
                                    type: "select",
                                    value: vendorForm.cityId,
                                    required: true,
                                    error: vendorForm.cityIderror,
                                    item: cityItem,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'address1',
                                    label: 'Address1',
                                    type: "text",
                                    value: vendorForm.address1,
                                    required: true,
                                    error: vendorForm.address1error,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'address2',
                                    label: 'Address 2',
                                    type: "text",
                                    value: vendorForm.address2,
                                    required: false,
                                    error: vendorForm.address2error,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'address3',
                                    label: 'Address 3',
                                    type: "text",
                                    value: vendorForm.address3,
                                    required: false,
                                    error: vendorForm.address3error,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'pinCode',
                                    label: 'PIN Code',
                                    type: "text",
                                    value: vendorForm.pinCode,
                                    required: true,
                                    error: vendorForm.pinCodeerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'tanNumber',
                                    label: 'TAN No',
                                    type: "text",
                                    value: vendorForm.tanNumber,
                                    required: true,
                                    error: vendorForm.tanNumbererror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'cin',
                                    label: 'CIN',
                                    type: "text",
                                    value: vendorForm.cin,
                                    required: true,
                                    error: vendorForm.cinerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'contactPerson',
                                    label: 'Contact Person',
                                    type: "text",
                                    value: vendorForm.contactPerson,
                                    required: true,
                                    error: vendorForm.contactPersonerror,
                                    onChange: handleInputChange

                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'mobileNumber',
                                    label: 'Mobile No',
                                    type: "number",
                                    value: vendorForm.mobileNumber,
                                    required: true,
                                    error: vendorForm.mobileNumbererror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'email',
                                    label: 'Email',
                                    type: "text",
                                    value: vendorForm.email,
                                    required: true,
                                    error: vendorForm.emailerror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                    <div className="col-md-3">
                        <Dropdown
                            field={
                                {
                                    id: 'gstStatus',
                                    label: 'Gst Status ',
                                    type: "select",
                                    value: vendorForm.gstStatus,
                                    required: true,
                                    error: vendorForm.gstStatuserror,
                                    item: gstStatus,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>


                    <div className="col-md-3">
                        <div className="d-flex gstWrapper">
                            <InputField
                                field={
                                    {
                                        id: "",
                                        label: "Gst Code",
                                        type: "dualInput",
                                        value: gstCode,
                                        required: false,
                                        disable: true,
                                        error: vendorForm.gstNumbererror,
                                        onChange: handleInputChange
                                    }
                                }
                            />
                            <InputField
                                style={{ borderRadius: '0px' }}
                                field={
                                    {
                                        id: "gstNumber",
                                        label: "Gst No",
                                        type: "text",
                                        disable: vendorForm.gstStatus === 2 ? true : false,
                                        value: vendorForm.gstStatus === 2 ? 'Unregistered' : vendorForm.gstNumber,
                                        required: vendorForm.gstStatus === 2 ? false : true,
                                        error: vendorForm.gstNumbererror,
                                        onChange: handleInputChange
                                    }
                                }
                            />

                        </div>
                    </div>

                    <div className="col-md-3">
                        <InputField
                            field={
                                {
                                    id: 'creditPeriodDays',
                                    label: 'Credit Period (No of days)',
                                    type: "number",
                                    value: vendorForm.creditPeriodDays,
                                    required: true,
                                    error: vendorForm.creditPeriodDayserror,
                                    onChange: handleInputChange
                                }
                            }
                        />
                    </div>
                </div>
            </div >

            <div className="cmd">
                <div></div>
                <Button
                    sx={{ m: 2, borderRadius: "10px" }}
                    onClick={handleFormSubmit}
                    variant="contained"
                >
                    Submit
                </Button>
            </div>
        </>
    );

}


import { Link } from '@mui/material';
import React, { useState } from 'react';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
const RightSideBar = ({ callback }) => {
    const [activeSideBar, setActiveSidebar] = useState(true)
    return (
        <div className={activeSideBar ? "rightstripWrapper" : 'rightstripWrapper hidestrip'}>
            <div className='rightstrip-inner'>
                <ul className='rightstripNav'>
                    <li>
                        <Link><i className="las la-cog"></i></Link>
                    </li>
                    <li>
                        <Link><i className="las la-pen"></i></Link>
                    </li>
                    <li>
                        <Link><i className="lar la-handshake"></i></Link>
                    </li>
                    <li>
                        <Link><i className="las la-pen"></i></Link>
                    </li>
                </ul>
                <ul>
                    <li>
                        <Link><i className="las la-cog"></i></Link>
                    </li>
                    <li>
                        <Link><i className="las la-cog"></i></Link>
                    </li>
                </ul>
                <ul className='rightstripNav'>
                    <li>
                        <Link><i className="las la-sticky-note"></i></Link>
                    </li>
                    <li>
                        <Link><BoltOutlinedIcon /></Link>
                    </li>
                  
                    <li>
                        <Link><i className="las la-cloud-upload-alt"></i></Link>
                    </li>
                    <li>
                        <Link><i className="las la-cog"></i></Link>
                    </li>
                </ul>
            </div>
            <button className='rightstrip-toggleBtn' onClick={() => { setActiveSidebar(!activeSideBar); callback() }}>
                <i className="las la-angle-right"></i>
            </button>
        </div>
    );
};

export default RightSideBar;
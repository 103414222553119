import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import { GetGSAMasterByPage, GetMasterSectorByPage, PostMasterSector, UpdateMasterSector, UpdateGSAMasterStatus , UpdateSubAgentShipperStatus, GetMasterVendorTypeByPage, GetConsigneeSubAgencyPage, PostMasterCountry, PostMasterState, PostMasterCity, UpdateConsigneeSubAgencyStatus, GetCustomerByPage, GetAirportMasterByPage, GetEmployeeByPage, } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { Box, Button, IconButton, Switch, Tab, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { json, useNavigate } from 'react-router-dom';
import { adesign, createconsigneesubagency, creategsamaster, createjob, createtdsmaster, preview, subagencyshipperform } from '../../../navigation/constant';

import { useDispatch } from 'react-redux';
import { CustomerTable } from '../customer/customerTable';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import Dropdown, { SmallSwitch } from '../../component/controls';
import { columnConstant, formconstant } from '../../constant/formconstant';
import { Add, CellTower, Close, Menu } from '@mui/icons-material';
import { AirExpoortJobConsolAdd, GetAirExportPlannerByPage } from '../../../services/APIConstant/jobConstant';
import { AirExportCustomerTypeFrightOptions, EnquiryStatus } from '../../../services/modalData';
import destination from '../../assets/img/icons/destination.png';
import job from '../../assets/img/icons/job.png';
import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { SplitJobForm } from './component/Splitjob';
import { AddNewJobForm } from './component/addnewJob';
import { ConvertValue } from '../../../services/utilities/valueConverter';
import { CreateCddForm } from './component/createCddForm';

const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};

const PlannerList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState('1')
    const [splitSelectedValue, setSplitSelectedValue] = useState(false)
    const [togglenewJobForm, setToggleNewJobForm] = useState(false)
    const [shipperItem, setShipperItem] = useState([])
    const [airportItem, setAirportItem] = useState([])
    const [employeeItem, setEmployeeItem] = useState([])
    const [jobPlannerData, setJobPlannerData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState()
    const [selectCallbackdata, setSelectCallbackdata] = useState([])
    const [filterdata, setFilterdata] = useState([])


    const fatch_MasterDataBYPagination = (item) => {
        setSelectCallbackdata([])
        MasterCallBypagination({
            API: GetAirExportPlannerByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }



    useEffect(() => {
        filterselectcheckbox();
    }, [selectCallbackdata]);

    useEffect(() => {
        fatch_MasterDataBYPagination();

        //get customer by page API
        GetCaller({
            API: GetCustomerByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, paylode: e.paylode })
            },
            item: {},
            callback: ({ response }) => {
                const newSalesperson = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.legalName
                }))

                setShipperItem(newSalesperson)
            }
        })

        // Get Airport Master by Page 
        GetCaller({
            API: GetAirportMasterByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const airportItem = data?.map(res => ({
                    value: res?.id,
                    name: res?.name
                }))
                setAirportItem(airportItem)
            }
        })


        // Get Airport Master by Page 
        GetCaller({
            API: GetEmployeeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {
                status: true
            },
            callback: ({ response }) => {
                let data = response?.data?.data?.records
                const employeeItem = data?.map(res => ({
                    value: res?.id,
                    name: res?.name
                }))
                setEmployeeItem(employeeItem)
            }
        })
    }, []);

    const getIncoTermType = (customerType, freightServiceType) => {
        debugger
        const foundOption = AirExportCustomerTypeFrightOptions[customerType - 1]?.FrightServiceType?.find(res => res?.name === freightServiceType);
        console.log(foundOption?.IncoTerm)
        return foundOption?.IncoTerm;
    }

    const setTableDataHandler = ({ response }) => {

        const newmasterdata = response?.data?.data?.jobs?.map((res, index) => ({
            className: res?.splitJobs?.length !== 0 ? 'splitStyle' : res?.consoleJobs?.length !== 0 && 'consoleStyle',
            sn: index + 1,
            enquiryId: res?.enquiryId,
            cddenquiryModifyId: 'AE-' + res?.enquiryId,
            enquiryModifyId: (
                <div className=''>
                    {
                        res?.consoleJobs.length === 0 && res?.splitJobs.length === 0 ?
                            <Tooltip title='Split'>
                                <IconButton className='me-2' onClick={() => setSplitSelectedValue(res)}>
                                    <Add />
                                </IconButton>
                            </Tooltip> : <span className='me-5'> </span>
                    }

                    {'AE-' + res?.enquiryId}
                </div>
            ),
            id: res?.id,
            customerName: res?.customerName,
            bizGenerator: res?.bizGenerator,
            shipperName: res?.shipperName,
            jobTypeDetail: (
                <div>
                    {AirExportCustomerTypeFrightOptions[res?.customerType - 1]?.name} <br />
                    <div style={{ whiteSpace: "nowrap" }}>( {res?.freightServiceType} )</div>
                </div>
            ),
            airportOfLoadingName: res?.airportOfLoadingName,
            airportOfDestinationName: res?.airportOfDestinationName,
            shipperIncoTerms: res?.shipperIncoTerms,
            apoltoapod: (
                <div className="apol_apod">
                    {res?.airportOfLoadingName} <img style={{ width: "20px", padding: "0 2px" }} src={destination} /> {res?.airportOfDestinationName}
                </div>
            ),
            customerType: res?.customerType,
            freightServiceType: res?.freightServiceType,
            incoTerm: 'N/A', // Or you can use your own logic here
            freightServiceSubType: res?.freightServiceSubType,
            bizCategoryType: res?.bizCategoryType,
            bizType: res?.bizType,
            chargeWeight: res?.chargeWeight,
            bizSubType: res?.bizSubType,
            marketedBy: res?.marketedBy,
            customer: res?.customer,
            accountLedger: res?.accountLedger,
            courierVolumeWeight: res?.courierVolumeWeight,
            packages: res?.packages,
            typeOfPackages: res?.typeOfPackages,
            typeOfPackagesOther: res?.typeOfPackagesOther,
            grossWeight: res?.grossWeight,
            qtyChargeWeight: res?.qtyChargeWeight,
            dimention: res?.dimention,
            airVolumeWeight: ConvertValue(Number(res?.airVolumeWeight)),
            commodity: res?.commodity,
            cbm: res?.cbm,
            airportOfLoading: res?.airportOfLoading,
            airportOfDestination: res?.airportOfDestination,
            tentativeDateOfShipment: res?.tentativeDateOfShipment,
            status: res?.status,
            enquiryDate: res?.enquiryDate,
            dimentionFile: res?.dimentionFile,
            remarks: res?.remarks,
            document: res?.document,
            hiddenDangerousGood: res?.hiddenDangerousGood,
            msdsApproval: res?.msdsApproval,
            unNumber: res?.unNumber,
            msdsDocument: res?.msdsDocument,
            oddDimension: res?.oddDimension,
            routingAndTransitRequirement: res?.routingAndTransitRequirement,
            enquiryStatus: res?.enquiryStatus,
            enquiryStatusTheme: <span className='quote-title'>{EnquiryStatus[res?.enquiryStatus - 1]?.name}</span>,
            branch: res?.branch,
            assigningJobTo: res?.assigningJobTo,
            allotMawbNumber: res?.allotMawbNumber,
            bookingWithAL: res?.bookingWithAL,
            bookingWithALModify: res?.bookingWithAL === true ? 'Yes' : 'NO',
            checkListByEmployeeId: res?.checkListByEmployeeId,
            checkListByEmployeeDocument: res?.checkListByEmployeeDocument,
            appraisalDC: res?.appraisalDC,
            appraisalSuprident: res?.appraisalSuprident,
            checkList: res?.checkList,
            checkListModify: res?.checkList === true ? 'Yes' : 'NO',
            customerBranchList: res?.splitJobs.length !== 0 ?
                res?.splitJobs?.map(item => ({ ...item, airVolumeWeight: ConvertValue(Number(item?.airVolumeWeight)) })) :
                res?.consoleJobs?.map(item => ({ ...item, airVolumeWeight: ConvertValue(Number(item?.airVolumeWeight)) }))
        }));


        setJobPlannerData(newmasterdata);
        console.log(newmasterdata)
        setTotalDataLength(newmasterdata?.length)
    }

    const columns = [



        {
            id: 'enquiryModifyId',
            label: <span className='ps-5'> Enq No. </span>,
            numeric: false,
        },
        {
            id: 'id',
            label: <div>Job No./<br /> Console No. </div>,
            numeric: false,
        },
        {
            id: 'shipperName',
            label: "Shipper Name",
            numeric: false,
        },
        {
            id: 'bizGenerator',
            label: columnConstant.bizgenerator,
            numeric: false,
        },
        {
            id: 'jobTypeDetail',
            label: formconstant.jobtype,
            numeric: false,
        },
        {
            id: 'bizSubType',
            label: "Biz Sub Type",
            numeric: false,
        },

        {
            id: 'shipperIncoTerms',
            label: "Inco Term",
            numeric: false,
        },
        {
            id: 'packages',
            label: columnConstant.pkgs,
            numeric: false,
        },
        {
            id: 'grossWeight',
            label: columnConstant.grweight,
            numeric: false,
        },
        {
            id: 'qtyChargeWeight',
            label: 'CH. WT.',
            numeric: false,
        },
        {
            id: 'airVolumeWeight',
            label: columnConstant.vweight,
            numeric: false,
        },
        {
            id: 'apoltoapod',
            label: 'APOL - APOD',
            numeric: false,
        },
        {
            id: 'cargotype',
            label: columnConstant.cargotype,
            numeric: false,
        },
        {
            id: 'tentativeDateOfShipment',
            label: columnConstant.tentativedateofship,
            numeric: false,
        },
        {
            id: 'assigningJobTo',
            label: 'Assign To',
            numeric: false,
        },
        {
            id: 'allotMawbNumber',
            label: 'MAWB NO.',
            numeric: false,
        },
        {
            id: 'bookingWithALModify',
            label: 'Booking',
            numeric: false,
        },
        {
            id: 'tcNumber',
            label: 'TC No.',
            numeric: false,
        },
        {
            id: 'slotTime',
            label: 'Slot',
            numeric: false,
        },
        {
            id: 'checkListModify',
            label: 'Check List',
            numeric: false,
        },
        {
            id: 'shippingBill',
            label: 'S. Bill',
            numeric: false,
        },
        {
            id: 'aprasel',
            label: 'Aprasel',
            numeric: false,
        },
        {
            id: 'apraselDc',
            label: 'Aprasel DC',
            numeric: false,
        },
        {
            id: 'noc',
            label: 'NOC',
            numeric: false,
        },



        {
            id: 'enquiryStatusTheme',
            label: columnConstant.status,
            numeric: false,
        },

        // {
        //     id: 'action',
        //     label: 'Action',
        //     numeric: false,
        // },
        {
            id: 'subtable',
            label: '',
            numeric: false,
        },
    ];

    const cddColumns = [
        {
            id: 'cddenquiryModifyId',
            label: 'Enq No.',
            numeric: false,
        },
        {
            id: 'id',
            label: <div>Job No./<br /> Console No. </div>,
            numeric: false,
        },
        {
            id: 'shipperName',
            label: "Shipper Name",
            numeric: false,
        },

        {
            id: 'tcNumber',
            label: 'TC No.',
            numeric: false,
        },
        {
            id: 'slotTime',
            label: 'Slot',
            numeric: false,
        },
        {
            id: 'checkList',
            label: 'Check List',
            numeric: false,
        },
        {
            id: 'shippingBill',
            label: 'S. Bill',
            numeric: false,
        },
        {
            id: 'aprasel',
            label: 'Aprasel',
            numeric: false,
        },
        {
            id: 'apraselDc',
            label: 'Aprasel DC',
            numeric: false,
        },
        // {
        //     id: 'action',
        //     label: 'Action',
        //     numeric: false,
        // },
        {
            id: 'subtable',
            label: '',
            numeric: false,
        },

    ];


    const subColumns = [
        {
            id: 'sn',
            label: '#',
            numeric: false,
        },
        {
            id: 'id',
            label: columnConstant.jobno,
            numeric: false,
        },
        {
            id: 'shipperName',
            label: columnConstant.shippername,
            numeric: false,
        },

        {
            id: 'packages',
            label: formconstant.packages,
            numeric: false,
        },
        {
            id: 'grossWeight',
            label: formconstant.grossweight,
            numeric: false,
        },
        {
            id: 'airVolumeWeight',
            label: formconstant.volumnweight,
            numeric: false,
        },
        {
            id: 'qtyChargeWeight',
            label: 'CH. WT.',
            numeric: false,
        },
        {
            id: 'allotHawbNumber',
            label: 'HAWB No.',
            numeric: false,
        },
        {
            id: 'tentativeDateOfShipment',
            label: "T.Date",
            numeric: false,
        },
        {
            id: 'action',
            label: '',
            numeric: false,
        },

    ];


    const cddSubColumns = [
        {
            id: 'sn',
            label: '#',
            numeric: false,
        },
        {
            id: 'id',
            label: columnConstant.jobno,
            numeric: false,
        },
        {
            id: 'shipperName',
            label: columnConstant.shippername,
            numeric: false,
        },

        {
            id: 'packages',
            label: formconstant.packages,
            numeric: false,
        },
        {
            id: 'grossWeight',
            label: formconstant.grossweight,
            numeric: false,
        },
        {
            id: 'airVolumeWeight',
            label: formconstant.volumnweight,
            numeric: false,
        },
        {
            id: 'qtyChargeWeight',
            label: 'CH. WT.',
            numeric: false,
        },
        {
            id: 'allotHawbNumber',
            label: 'HAWB No.',
            numeric: false,
        },
        {
            id: 'tentativeDateOfShipment',
            label: "T.Date",
            numeric: false,
        },

    ];



    const subActionCell = ({ row }) => {

        return <>
            <Tooltip title={'Edit Job'}>
                <IconButton>
                    <div style={{ display: "inline-flex" }} onClick={() => {
                        sessionStorage.setItem('jobplanner', JSON.stringify(row));
                        navigate(createjob)
                    }}>
                        <img style={{ width: "20px" }} src={job}></img>
                    </div>
                </IconButton>
            </Tooltip>
        </>

    }


    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateConsigneeSubAgencyStatus,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({ response }) => {
                    fatch_MasterDataBYPagination();
                }
            });
        }
        const editBtnClicked = () => {
            sessionStorage.setItem('sunAgencyShipperlist', JSON.stringify(row));
            navigate(createconsigneesubagency);
        }
        return <>

            {/* <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            /> */}

        </>

    }
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setSelectCallbackdata([])
    };

    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <div className='cmd'>


                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Planning" value="1" />
                            <Tab label="CDD" value="2" />
                            {/* <Tab label="SDD" value="3" /> */}


                        </TabList>
                    </Box>

                </TabContext>

                <Tooltip title="Add Job">
                    <IconButton
                        className='table-toggle'
                        onClick={() => setToggleNewJobForm(true)}
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip >
            </div>
        </>
    }

    const subActionHeadCell = (row) => {
        return <>
            <Tooltip title={'Edit Job'}>
                <IconButton>
                    <div style={{ display: "inline-flex" }} onClick={() => {
                        sessionStorage.setItem('jobplanner', JSON.stringify(row));
                        navigate(createjob)
                    }}>
                        <img style={{ width: "20px" }} src={job}></img>
                    </div>
                </IconButton>
            </Tooltip>
        </>
    }

    const cddSubActionHeadCell = (row) => {
        return <>
            <CreateCddForm
                employeeItem={employeeItem}
                row={row}
                callBack={fatch_MasterDataBYPagination}
            />
        </>
    }



    const selecterCallback = (data) => {
        setSelectCallbackdata(data)
    }


    const filterselectcheckbox = () => {
        const filteredArray = jobPlannerData?.filter(obj => selectCallbackdata.includes(obj.id));
        setFilterdata(filteredArray)

    };
    const [targetSignleCheckBox, setTargetSignleCheckBox] = useState(false)

    const conditions = {
        subColumns: subColumns,
        subActionCell: subActionCell,
        multiselect: true,
        subActionHeadCell: subActionHeadCell,
        selectedCallback: selecterCallback,
        hanldeSingleSelectCheckbox: targetSignleCheckBox,
        isMainCheckBoxHide: true,
        isHideSubTableCheckBox: true,
    }

    const cddConditions = {
        subColumns: cddSubColumns,
        subActionCell: subActionCell,
        multiselect: false,
        subActionHeadCell: cddSubActionHeadCell,
    }

    const selectedupdate = (id) => {
        setTargetSignleCheckBox(id)
    }

    const newArray = filterdata?.map(item => {
        return { jobId: item.id, enquiryId: item.enquiryId };
    });


    const postmasterareaData = {
        jobs: newArray
    }

    const CreateConsole = () => {

        PostCaller({
            API: AirExpoortJobConsolAdd,
            callback: () => { fatch_MasterDataBYPagination(); setSelectCallbackdata([]) },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: postmasterareaData
        })

    }
    const [consoleSelectedValue, setConsoleSelectedValue] = useState('')
    const [comparisionToggle, setcomparisionToggle] = useState(false)
    console.log('filterdata', filterdata)
    return (
        <>
            {/* // Add job New Form  */}
            <AddNewJobForm
                togglenewJobForm={togglenewJobForm}
                shipperItem={shipperItem}
                airportItem={airportItem}
                callBack={() => {
                    setToggleNewJobForm(false)
                    fatch_MasterDataBYPagination()
                }}
            />


            <TabContext value={tabValue}>

                <TabPanel value="1">
                    <div className={comparisionToggle ? 'd-none' : 'd-block'}>
                        <CustomerTable
                            data={jobPlannerData}
                            conditions={conditions}
                            columns={columns}
                            title={'Planner'}
                            tabletool={TableTool}
                            actioncell={ActionCell}
                            totaldatalength={totaldatalength}
                            paginationhandler={fatch_MasterDataBYPagination}
                        />



                        <SplitJobForm
                            splitSelectedValue={splitSelectedValue}
                            shipperItem={shipperItem}
                            callBack={() => {
                                setSplitSelectedValue(false)
                                fatch_MasterDataBYPagination()
                            }}
                        />

                    </div>

                    <div className={comparisionToggle ? 'd-block' : 'd-none'}>
                        <Button variant='contained' className='mb-3' onClick={() => setcomparisionToggle(!comparisionToggle)}>
                            Go Back
                        </Button>
                        <div className="row">
                            {
                                filterdata?.map((res, key) => (
                                    <div className="col-md-4 mt-4">
                                        <div className="consolcard">
                                            <div className="cmd upper_header">
                                                <div className="close-icon" style={{ display: 'inline-flex' }}>{res?.enquiryModifyId} </div> {/* Cross icon */}
                                                <div> {res?.apoltoapod}</div> {/* Cross icon */}
                                            </div>
                                            <div className="cmd mt-2" style={{ alignItems: 'end' }}>
                                                <div className="card_info">{res?.shipperName}</div>
                                                <div>
                                                    {/* {res?.apoltoapod} */}
                                                </div>
                                                {/* <div className="header-text">{res?.shipperName} </div> */}
                                                <div>{res?.tentativeDateOfShipment}</div>
                                            </div>
                                            <hr className="line" />
                                            <div className="date_section">
                                                <div className='cmd'>
                                                    <div>
                                                        <p>PKGS :<span>{res?.packages}</span></p>
                                                        <p>Gross Wt :<span>{res?.grossWeight}</span></p>
                                                    </div>
                                                    <div>
                                                        <p>CH. WT. :<span>{res?.packages}</span></p>
                                                        <p>Vol. WT. :<span>{res?.grossWeight}</span></p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </div >

                    {
                        selectCallbackdata.length !== 0 &&
                        <div className='Comparision_Strip'>
                            <div className="cmd">
                                <div>
                                    <p className='m-0 p-0'> MY COMPARISON </p>

                                </div>
                                <div className='Comparision_Strip_dropdown'>

                                    <span style={{ color: "yellow" }}>  {selectCallbackdata?.length} Selected </span>
                                    {/* <div style={{ display: "inline-flex", width: "120px" }}>
                                <Dropdown
                                    className='mt-0'
                                    field={{
                                        id: '',
                                        label: 'Console',
                                        value: consoleSelectedValue,
                                        item: [1, 2],
                                        onChange: (value, field) => { setConsoleSelectedValue(value) }
                                    }}
                                />
                            </div > */}

                                    <button onClick={() => setcomparisionToggle(!comparisionToggle)}>Comparison</button>
                                    <button onClick={CreateConsole}>Create Console</button>



                                </div>
                            </div>
                        </div>
                    }
                </TabPanel>
                <TabPanel value='2'>
                    <CustomerTable
                        data={jobPlannerData}
                        conditions={cddConditions}
                        columns={cddColumns}
                        title={'CDD'}
                        tabletool={TableTool}
                        actioncell={ActionCell}
                        totaldatalength={totaldatalength}
                        paginationhandler={fatch_MasterDataBYPagination}
                    />

                </TabPanel>

                <TabPanel value='3'>
                    <CustomerTable
                        data={jobPlannerData}
                        conditions={conditions}
                        columns={cddColumns}
                        title={'CDD'}
                        tabletool={TableTool}
                        actioncell={ActionCell}
                        totaldatalength={totaldatalength}
                        paginationhandler={fatch_MasterDataBYPagination}
                    />

                </TabPanel>

            </TabContext>




        </>
    )
}

export default PlannerList;


export const JobComparisonFooter = ({ filterdata, callback, callapi }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const newArray = filterdata?.map(item => {
        return { jobId: item.id, enquiryId: item.enquiryId };
    });

    const handelclick = (id) => {
        callback(id)
    }

    const callAPI = () => {
        callapi()
    }

    const postmasterareaData = {
        jobs: newArray
    }

    const handleSubmit = () => {

        PostCaller({
            API: AirExpoortJobConsolAdd,
            callback: () => callAPI(),
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: postmasterareaData
        })

    }
    return <>
        <div className="stripFooter p-3">
            <h5>
                My Comparison
            </h5>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className="sfooterItams">
                    {filterdata?.slice(0, 4).map((item, index) => (

                        <React.Fragment key={item.id}>
                            <div className="sfooterItamsbox">
                                <div>
                                    <div className='sfooterCrsIcon' onClick={() => handelclick(item.id)}>
                                        <CloseIcon />

                                    </div>
                                    <p>Gross.W:<span>{item.grossWeight}</span></p>
                                    <p>Volumn.W:<span>{parseFloat(item.airVolumeWeight).toFixed(3)}</span></p>
                                    <p>Charge H:<span>{item.grossWeight}</span></p>
                                </div>
                            </div>
                            {index !== filterdata.length - 1 && <h3 className='footerVS'>V/S</h3>}
                        </React.Fragment>
                    ))}
                    <div className="sfooterIcon">
                        <Add />
                    </div>
                </div>
                <div className="cmc">
                    <Button variant='outlined' onClick={() => {
                        sessionStorage.setItem("previewData", JSON.stringify(filterdata))
                        navigate(preview)
                    }} > Preview </Button>
                    <Button className='mx-3' variant='contained' onClick={handleSubmit}>Console</Button>
                </div>
            </div>
        </div>

    </>
}
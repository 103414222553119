
import React, { useEffect, useState } from "react";
import GetCaller from "../../../../services/apiServices";
import { GetAirExportPermanentJoblistByPage } from "../../../../services/APIConstant/AirExport.jsx/finalJob";
import { useDispatch } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { SmallSwitch } from "../../../component/controls";
import { MultiTable } from "../../../component/multiTable";
import { columnConstant } from "../../../constant/formconstant";
import MasterCallBypagination from "../../../../services/masterpaginationConfig";
import { Flight } from "@mui/icons-material";
import destination from '../../../assets/img/icons/destination.png';
import { flightRegister } from "../../../../navigation/constant";

const FlightRegisterList = () => {
    const [flightlist, setFrightListData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetAirExportPermanentJoblistByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.map((res, index) => ({
            sn: index + 1,
            id: res?.id,
            consoleNumber: res.consoleNumber,
            shipperName: res.shipperName,
            packages: res.packages,
            grossWeight: res.grossWeight,
            airVolumeWeight: res.airVolumeWeight,
            qtyChargeWeight: res.qtyChargeWeight,
            shipperName: res.shipperName,
            allotMawbNumber: res.allotMawbNumber,
            allotHawbNumber: res.allotHawbNumber,
            apoltoapod: (
                <div className="apol_apod">
                    {res?.airportOfLoadingName} <img style={{ width: "20px", padding: "0 2px" }} src={destination} /> {res?.airportOfDestinationName}
                </div>
            ),
            shipperInvoiceNumberList: res.shipperInvoiceNumber?.map(item => (`${'-'}`))
        }));
        setFrightListData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
        {
            id: 'consoleNumber',
            label: 'Console No.',
            numeric: false,
        },
        {
            id: 'id',
            label: 'Job No.',
            numeric: false,
        },

        {
            id: 'shipperName',
            label: 'Shipper Name',
            numeric: false,
        },
        {
            id: 'apoltoapod',
            label: 'APOL/APOD',
            numeric: false,
        },
        
        {
            id: 'packages',
            label: 'Package',
            numeric: false,
        },
        {
            id: 'grossWeight',
            label: 'Gross Wt.',
            numeric: false,
        },
        {
            id: 'airVolumeWeight',
            label: 'Vol Wt.',
            numeric: false,
        },
        {
            id: 'shipperName',
            label: 'Airline',
            numeric: false,
        },
        {
            id: 'allotMawbNumber',
            label: 'MAWB No.',
            numeric: false,
        },
        {
            id: 'allotHawbNumber',
            label: 'HAWB No.',
            numeric: false,
        },


        // {
        //     id: 'shipperInvoiceNumberList',
        //     label: 'SHP. INV.',
        //     numeric: false,
        // },
        // {
        //     id: 'shipperName',
        //     label: 'Invoice No.',
        //     numeric: false,
        // },
        // {
        //     id: 'id',
        //     label: 'Job DT.',
        //     numeric: false,
        // },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
    ];

    const ActionCell = ({ row }) => {


        return <>
            <Tooltip title="Flight Register">
                <IconButton
                    style={{ rotate: '90deg' }}
                    className='tablebtn1'
                    onClick={() => {
                        navigate(flightRegister)
                        // sessionStorage.setItem('',)
                    }}
                >
                    <Flight />
                </IconButton>
            </Tooltip >

        </>

    }


    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <Tooltip title="Add Flight List">
                <IconButton
                    className='table-toggle'
                    onClick={() => navigate(`${''}`)}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip >

        </>
    }
    return <>
        <>
            <MultiTable
                data={flightlist}
                columns={columns}
                // filterItems={filterItems}
                title={'Flight Register'}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}
            />
        </>
    </>
}

export default FlightRegisterList;
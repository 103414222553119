import React, { useEffect, useState } from 'react'
import { MultiTable } from '../../component/multiTable';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { GetAirportMasterByPage, GetMasterBranchByPage, GetMasterCityByPage, GetMasterCountryByPage, GetMasterStateByPage, PostAirportMaster, PostMasterCity, PostMasterCountry, PostMasterState, UpdateAirportMaster, UpdateAirportMasterStatus, UpdateMasterCountry, UpdateMasterCountryStatus } from '../../../services/constant';
import axios from 'axios';
import { IconButton, Switch, Tooltip } from '@mui/material';
import DivisonDrawer from '../../component/divisionDrawer';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import { branch } from '../../../navigation/constant';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import {columnConstant} from '../../constant/formconstant';
import { SmallSwitch } from '../../component/controls';
const authToken = localStorage.getItem('authToken')
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
  };
const AirportMaster = () => {
    const dispatch = useDispatch()
    const [airportMasterData, setAirportMasterData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)

    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({

            API: GetAirportMasterByPage,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();
    }, []);
    const setTableDataHandler = ({ response }) => {
        console.log('pagination data ', response)
        const newmasterdata = response?.data?.data?.records?.map((res, index) => ({
            sn: index + 1,
            id: res.id,
            countryid: res.country.key,
            country: res.country.value,
            stateid: res.country.key,
            state: res.state.value,
            cityid: res.city.key,
            city: res.city.value,
            name: res.name,
            airportCode: res.airportCode,
            status: res.status

        }));
        console.log("airport data ", newmasterdata);
        setAirportMasterData(newmasterdata);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }

    const columns = [
        {
            id: 'sn',
            label: columnConstant.sno,
            numeric: false,
        },

        {
            id: 'name',
            label: columnConstant.airportname,
            numeric: false,
        },
        {
            id: 'airportCode',
            label: columnConstant.airportcode,
            numeric: false,
        },
        {
            id: 'country',
            label: columnConstant.country,
            numeric: false,
        },
        {
            id: 'state',
            label: columnConstant.state,
            numeric: false,
        },
        {
            id: 'city',
            label: columnConstant.city,
            numeric: false,
        },


        {
            id: 'action',
            label: columnConstant.action,
            numeric: false,
        },
        // You can add more column definitions as needed
    ];





    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {       
            const data = {
                id: row?.id,   
              }
              GetCaller({
                API: UpdateAirportMasterStatus,
                dispatch: (e) => {
                  dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({response}) => {
                  fatch_MasterDataBYPagination();
                    }               
                  });       
                }

        return <>
            <AirportDrawer callAPI={fatch_MasterDataBYPagination} editor={true} row={row} />
           <SmallSwitch
                onChange={handleSwitchChange}
                checked={row?.status}
            />
        </>



    }



    //table head section tool
    const TableTool = ({ }) => {
        return <>
            <AirportDrawer callAPI={fatch_MasterDataBYPagination} />
        </>
    }


    return (
        <>    
            <MultiTable
                data={airportMasterData}
                columns={columns}
                title={columnConstant.airport}
                tabletool={TableTool}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default AirportMaster



const AirportDrawer = ({ callAPI, editor, row }) => {
    const dispatch = useDispatch()
    const [cityItem, setCityItem] = useState([])
   

    const [closeValidator, setcloseValidator] = useState(false)
    const [airport, setAirport] = useState(row?.name || '')
    const [airportError, setAirportError] = useState(false)

    const [cityName, setCityName] = useState(row?.cityid || '')
    const [cityNameError, setCityNameError] = useState(false)

    const [airpotCode, setAirportCode] = useState(row?.airportCode || '')
    const [airpotCodeError, setAirportCodeError] = useState(false)




    const drawerOpenCallbackHandler = () => {
        setAirportCode(row?.airportCode || '')
        setCityName(row?.cityid || '')
        setAirport(row?.name || '')
        setAirportError(false)
        setCityNameError(false)
        setAirportCodeError(false)
    }



    const set_CityItemData = () => {
        GetCaller({
            API: PostMasterCity,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCityItemData = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }))
                setCityItem(newCityItemData);
            }

        })

    }

    useEffect(() => {
        set_CityItemData(); 
    }, []);

    const handleSubmit = () => {
        if (!cityName || !airport || !airpotCode ) {
            setCityNameError(!cityName)
            setAirportError(!airport)
            setAirportCodeError(!airpotCode)

        }
        else {
            const postmasterareaData = editor ? {
                id: row.id,
                cityId: cityName,
                airportCode: airpotCode,
                name: airport,
                status: row.status
              } : {
                cityId: cityName,
                airportCode: airpotCode,
                name: airport,
              }             
              editor ?
                PostCaller({
                  API: UpdateAirportMaster,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
        
                :    
                PostCaller({
                  API: PostAirportMaster,
                  callback: () => callAPI(),
                  dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                  },
                  item: postmasterareaData
                })
            setcloseValidator(true)
        }
    }

    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);


    const handleInputChange = (value, field) => {
        switch (field.id) {
            case 'name':
                setAirport(value)
                setAirportError(!value);
                break;
            case 'city':
                setCityName(value)
                setCityNameError(!value);
                break;
            case 'airportCode':
                setAirportCode(value)
                setAirportCodeError(!value);
                break;

            default:
                break;
        }
    };



    const drawerFormFields = editor ?
        {
            title: columnConstant.editairport,
            icon: <i className="las la-edit"></i>,
            tooltitle: columnConstant.editairport,
            iconclass: 'noClass',
            defaultbtnfalse: true

        }
        : {
            title: columnConstant.addairport,
            tooltitle: columnConstant.addairport,
            defaultbtnfalse: true
        }





    const FieldData = [

        {
            id: 'name',
            label: columnConstant.airportname,
            type: 'text',
            value: airport,
            required: true,
            error: airportError,
            helperText: 'Airport Name required',
            onChange: handleInputChange,
        },
        {
            id: 'airportCode',
            label: columnConstant.airportcode,
            type: 'text',
            value: airpotCode,
            required: true,
            error: airpotCodeError,
            helperText: 'Airport Code required',
            onChange: handleInputChange,
        },

        {
            id: 'city',
            label: columnConstant.city,
            type: 'select',
            value: cityName,
            required: true,
            error: cityNameError,
            item: cityItem,
            helperText: 'City Name required',
            onChange: handleInputChange,
        },

      

       
    ];




    const divisionDrawerody = () => {
        return <ControlDynamicForm FieldData={FieldData} handleCancel={() => setcloseValidator(true)} handleSubmit={handleSubmit} />
    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}


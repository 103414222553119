
import { useEffect, useState } from "react";

import { Button } from '@mui/material';

import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import Dropdown, { DateField, InputField, InputFileField } from "../../component/controls";
import { Add, Close } from "@mui/icons-material";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


const VendorManageBalance = () => {
    const [customerDetail, setCustomerDetail] = useState({
        customerName: "",
        cusomerType: "",
        globalnetwork: "",
        gst: "",
    })

    const handleBasicInputChange = () => {
        console.log('')
    }

    const handleFormSubmit = () => {
        console.log('')
    }
    return <>
        <div className="hr-form-box">
            <h5>CREATE TDS EXEMPTIONS</h5>
            <div className='check-customer-table'>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Certificate Number</th>
                            <th>PAN No.</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Exemption Amount</th>
                            <th>Exemption %</th>
                            <th>Exemption Availed</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Name</td>
                            <td>675987</td>
                            <td>Account</td>
                            <td>XXXX</td>
                            <td>depty</td>
                            <td>depty</td>
                            <td>depty</td>
                            <td>depty</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <VendorBalaceForm onChange={handleBasicInputChange} />
            <div className="cmd">
                <Button sx={{ m: 2, borderRadius: "10px" }} onClick={() => Navigate('')} variant="contained">Create</Button>
            </div>
        </div>
    </>
}
export default VendorManageBalance

function VendorBalaceForm({ onChange }) {

    const handleInputChange = () => { }

    return (
        <div className=' mt-4'>
            <div className="cmd">
                <h5>ADD & UPDATE EXEMPTION</h5>
            </div>

            <div className="row">

                <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: "certification",
                                label: "Certificate No.",
                                type: 'email',
                                value: "",
                                required: true,
                                error: "",

                                onChange: (value) => {
                                    // handleInputChange(field.key, 'email', value);
                                }
                            }
                        }
                    />
                </div>
                <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: "branchtan",
                                label: "Branch Tan No.",
                                type: 'text',
                                value: "",
                                required: true,
                                error: "",
                                disable: false,
                                onChange: (value) => {
                                    // handleInputChange(field.key, 'email', value);
                                }
                            }
                        }
                    />
                </div>
                <div className="col-md-3 ">
                    <DateField
                        field={{
                            id: "",
                            label: "From Date ",
                            value: '',
                            required: true,
                            error: false,
                            onChange: () => { }
                        }}
                    />

                </div>
                <div className="col-md-3 mt-2">
                    <DateField
                        field={{
                            id: "",
                            label: "From Date ",
                            value: '',
                            required: true,
                            error: false,
                            onChange: () => { }
                        }}
                    />

                </div>


                <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: "exemptionAmount",
                                label: "Exemption Amount",
                                type: 'number',
                                value: "",
                                required: true,
                                error: "",

                                onChange: (value) => {
                                    // handleInputChange(field.key, 'email', value);
                                }
                            }
                        }
                    />
                </div>
                <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: "exemption",
                                label: "Exemption%",
                                type: 'number',
                                value: "",
                                required: true,
                                error: "",

                                onChange: (value) => {
                                    // handleInputChange(field.key, 'email', value);
                                }
                            }
                        }
                    />
                </div>
                <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: "exemptionavailed",
                                label: "Exemption Availed",
                                type: 'text',
                                value: "",
                                required: true,
                                error: "",

                                onChange: (value) => {
                                    // handleInputChange(field.key, 'email', value);
                                }
                            }
                        }
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 mt-3">

                    <h6>Uplode Certificate</h6>
                    <InputFileField
                        field={
                            {
                                id: "",
                                label: "Address Proof",
                                type: 'file',
                                value: '',
                                required: true,
                                error: false,

                                onChange: (value) => {

                                }
                            }
                        }
                    />
                </div>
            </div>
        </div>
    );
}
import React, { useEffect, useState } from 'react'
import { GetThirdCountryMiscCustomerByPage, UpdateThirdCountryMiscCustomerStatus } from '../../../services/constant';
import { IconButton, Switch, Tooltip } from '@mui/material';
import DivisonDrawer from '../../component/divisionDrawer';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import { useDispatch } from 'react-redux';
import ControlDynamicForm from '../../component/controls/controlDynamicForm';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { CustomerTable } from './customerTable';
import CircularWithValueLabel from '../../component/field/circleProgressBar';
import { checkcustomer, checkthirdcountrymisccustomer } from '../../../navigation/constant';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import CustomerBranch from './customerBranchForm';
import CustomerContactDetail from './customerContactDetail';
import { TypeOfCustomer } from '../../../services/modalData';
import { SmallSwitch } from '../../component/controls';
import ThirdCountryMiscBranchForm from './thirdCountrymiscCustomerBranchForm';
import CustomerThirdCountryMiscContactDetail from './thirdcountrymisccustomercontactdetail';


const ThirdCountryMiscCustomerList = () => {
  const dispatch = useDispatch();
  const [customerListData, setCustomerListData] = useState([])
  const [totaldatalength, setTotalDataLength] = useState(0)
  const navigate = useNavigate();

  const fatch_MasterDataBYPagination = (item) => {
    MasterCallBypagination({
      API: GetThirdCountryMiscCustomerByPage,
      callback: setTableDataHandler,
      dispatch: (e) => {
        dispatch({ type: e.type, payload: e.payload })
      },
      item: item
    })
  }
  useEffect(() => {
    fatch_MasterDataBYPagination();
  }, []);

  const setTableDataHandler = ({ response }) => {
    const newMasterAreaData = response?.data?.data?.records?.map((res, index) => ({
      sn: 'd',
      subtable: '',
      id: res?.id,
      cid: res?.cid,
      legalName: res?.legalName,
      partyType: res?.partyType,
      partyTypeName: TypeOfCustomer[res?.partyType - 1]?.name,
      contact: res?.contact,
      email: res?.email,
      promoter: res?.promoter,
      kycStatus: res?.kycStatus,
      openingDate: res?.openingDate,
      // customerBranchList: res?.customerBranchList,
      customerBranchList: res?.thirdCountryMiscellaneousCustomerBranchList.map(({ addressLabel, cityName, countryName, state, contactEmail, contactName, contactMobileNumber, ...rest }) => ({
        ...rest,
        branchDetails: <div>
          {state} ({cityName}) - {countryName}
          <br />
          {addressLabel}
        </div>,
        contactDetails: <div>
          {contactName} <br /> {contactEmail} <br /> {contactMobileNumber}
        </div>
      })),


      status: res?.status
    }));
    setCustomerListData(newMasterAreaData);
    setTotalDataLength(response?.data?.data?.totalRecords)
  }


  const columns = [
    {
      id: 'sn',
      label: 'S.No',
      numeric: false,
    },

    {
      id: 'cid',
      label: 'C.ID',
      numeric: false,
    },
    {
      id: 'legalName',
      label: 'Legal Name',
      numeric: false,
    },
    {
      id: 'partyTypeName',
      label: 'Customer Type',
      numeric: false,
    },

    {
      id: 'promoter',
      label: 'Director Detail',
      numeric: false,
    },
    {
      id: 'kycStatus',
      label: 'KYC Status',
      numeric: false,
    },
    {
      id: 'openingDate',
      label: 'Created Date',
      numeric: false,
    },
    {
      id: 'action',
      label: 'Action',
      numeric: false,
    },
    {
      id: 'subtable',
      label: '',
      numeric: false,
    },
  ];



  const subColumns = [
    {
      id: 'sn',
      label: '#',
      numeric: false,
    },

    {
      id: 'branch',
      label: 'Identification Label',
      numeric: false,
    },

    {
      id: 'accountLedger',
      label: 'A/C Ledger',
      numeric: false,
    },
    {
      id: 'gstNumber',
      label: 'GST No.',
      numeric: false,
    },
    {
      id: 'contactDetails',
      label: 'Contact Details',
      numeric: false,
    },
    {
      id: 'branchDetails',
      label: 'Branch',
      numeric: false,
    },

    {
      id: 'action',
      label: '',
      numeric: false,
    },

  ];

  const ActionCell = ({ row }) => {
    const handleSwitchChange = () => {
      const data = {
        id: row?.id,
      }
      GetCaller({
        API: UpdateThirdCountryMiscCustomerStatus,
        dispatch: (e) => {
          dispatch({ type: e.type, payload: e.payload })
        },
        item: data,
        callback: ({ response }) => {
          fatch_MasterDataBYPagination();
        }

      });
    }
    return <>

      <div className='me-4'>
        <CircularWithValueLabel />
      </div>

      <ThirdCountryMiscBranchForm row={row} callback={fatch_MasterDataBYPagination} />

      <SmallSwitch
        onChange={handleSwitchChange}
        checked={row?.status}
      />
    </>
  }
  const subActionCell = ({ row }) => {
    return <>
      <CustomerThirdCountryMiscContactDetail row={row} callback={fatch_MasterDataBYPagination} />
    </>
  }
  const TableTool = ({ }) => {
    return <>
      <Tooltip title="Add Customer">
        <IconButton
          className='table-toggle'
          onClick={() => navigate(`${checkthirdcountrymisccustomer}`)}
        >
          <AddIcon />
        </IconButton>
      </Tooltip >

    </>
  }

  const conditions = {
    subColumns: subColumns,
    subActionCell: subActionCell
  }

  return (
    <>

      <CustomerTable
        data={customerListData}
        conditions={conditions}
        columns={columns}
        title={'Misc. Customer List'}
        tabletool={TableTool}
        actioncell={ActionCell}
        totaldatalength={totaldatalength}
        paginationhandler={fatch_MasterDataBYPagination}

      />


    </>
  )
}
export default ThirdCountryMiscCustomerList;



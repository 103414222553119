import React, { useEffect, useState } from 'react'
import MapMenu, { MultiTable } from '../component/multiTable';
import DivisonDrawer from '../component/divisionDrawer';
import { IconButton, Tooltip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import LeadTab from '../component/tabs/tabone';
import MasterCallBypagination from '../../services/masterpaginationConfig';
import { GetMaterializedLeadsByPage } from '../../services/constant';
import { useDispatch } from 'react-redux';

const Materializelead = () => {
    const dispatch = useDispatch();
    const [MarketingLeadData, setMarketingLeadData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const [closeValidator, setcloseValidator] = useState(false)

    const fatch_MarketingLeadBYPagination = (item) => {
        MasterCallBypagination({
            API: GetMaterializedLeadsByPage,
           callback: setTableDataHandler,   dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MarketingLeadBYPagination();

    }, []);

    const setTableDataHandler = ({ response }) => {
        const newMarketingLeadData = response?.data?.data?.records?.map((res, index) => ({
            sn: 'd',
            id: res.lead.id,
            leadid: res.lead.id,
            contactPerson: res.lead.contactPerson,
            mobileNumber: res.lead.mobileNumber,
            address: res.lead.address,
            email: res.lead.email,
            organizationNameOrBizGenerator: res.lead.organizationNameOrBizGenerator,
            referredBy: res.lead.referredBy,
            designation: res.lead.designation,
            biztype: res.bizType || ' ',
            noofshipment: res.totalNoOfShipment,
            nextfollowup: res.nextFollowUpDate || ' ',
            specialRequirementOrRemarks: res.lead.specialRequirementOrRemarks,
            aiShipments: res.lead.aiShipments,
            aiTotalShipments: res.lead.aiTotalShipments,
            aeShipments: res.lead.aeShipments,
            aeTotalShipments: res.lead.aeTotalShipments,
            oifclShipments: res.lead.oifclShipments,
            oifclTotalShipments: res.lead.oifclTotalShipments,
            oilclShipments: res.lead.oilclShipments,
            oilclTotalShipments: res.lead.oilclTotalShipments,
            oefclShipments: res.lead.oefclShipments,
            oefclTotalShipments: res.lead.oefclTotalShipments,
            oelclShipments: res.lead.oelclShipments,
            oelclTotalShipments: res.lead.oelclTotalShipments,
            file: res.lead.file,
            leadLogList: res.lead.leadLogList,
            priority: res.priority,
            // action: res.Lead.sector.key,

        }));
        setMarketingLeadData(newMarketingLeadData);
        setTotalDataLength(response?.data?.data?.totalRecords)
    }


    const columns = [
        {
            id: 'sn',
            label: 'S.No',
            numeric: false,
        },
      

        {
            id: 'leadid',
            label: 'Lead Id',
            numeric: false,
        },
        {
            id: 'contactPerson',
            label: 'Contact Person',
            numeric: false,
        },
        {
            id: 'mobileNumber',
            label: 'Contact No.',
            numeric: false,
        },
        {
            id: 'address',
            label: 'Address',
            numeric: false,
        },
        {
            id: 'email',
            label: 'Email',
            numeric: false,
        },
        {
            id: 'organizationNameOrBizGenerator',
            label: 'Org. Name/Biz. Generator ',
            numeric: false,
        },
        {
            id: 'referredBy',
            label: 'Referred By',
            numeric: false,
        },
        {
            id: 'biztype',
            label: 'Biz. Type',
            numeric: false,
        },
        {
            id: 'noofshipment',
            label: 'No. Of Shpmnt ',
            numeric: false,
        },
        {
            id: 'nextfollowup',
            label: 'Next Follow Up ',
            numeric: false,
        },

        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        // You can add more column definitions as needed
    ];

    const conditions = {
        multiselect: true,
        tablebarclass: 'leadtablebar',
        shipmentfilter: [
            { id: 1, label: 'Air Import' },
            { id: 2, label: 'Air Export' },
            { id: 3, label: 'OIFCL' },
            { id: 4, label: 'OILCL' },
            { id: 5, label: 'OE-FCL' },
            { id: 6, label: 'OE-LCL' },
        ],
        sendleadfilter: [
            { id: 1, label: 'Mayank' },
            { id: 2, label: 'Sachin' },
            { id: 3, label: 'Aditi' },
            { id: 4, label: 'Akash' },
        ],
        followup: {
            title: "Follow Up",
            tooltitle: "Follow Up",
            iconclass: "tablebtn1",
            width: 470,
            icon: <i className='las  la-phone-square '></i>,
            leadtab: true,
            defaultbtnfalse: true
        },
        modifylead: {
            title: "Modify Lead",
            defaultbtnfalse: true,
            tooltitle: "Modify Lead",
            width: 450,
            leadform: true,
            icon: <i className="las la-edit"></i>,
            iconclass: "tablebtn1",
        }

    }

    const ActionCell = ({ row }) => {
        const LeadTabHandler = () => {
            return <>
                <LeadTab ID={row.id} callback={fatch_MarketingLeadBYPagination} />
            </>
        }

        return <>
            <DivisonDrawer drawerFormFields={conditions?.followup} currentColumndata={row} divisiondrawerbody={LeadTabHandler} />
        </>
    }

    const TableTool = ({ filterCallback }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [anchorE2, setAnchorE2] = useState(null);

        return <>
            {
                columns ? <Tooltip title="Filter">
                    <IconButton
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        aria-controls={anchorEl ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl ? 'true' : undefined}
                    >
                        <FilterListIcon />
                    </IconButton>
                </Tooltip> : false
            }
            {/* new leadfilter */}
            {
                conditions?.shipmentfilter ? <Tooltip title="Shipment Filter">
                    <IconButton
                        onClick={(e) => setAnchorE2(e.currentTarget)}
                        aria-controls={anchorE2 ? 'Shipment-Filter' : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorE2 ? 'true' : undefined}
                    >
                        <i className='las la-filter '></i>
                    </IconButton>
                </Tooltip> : false
            }

            <MapMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} menuItems={columns} filterCallback={filterCallback} />
            <MapMenu anchorEl={anchorE2} setAnchorEl={setAnchorE2} menuItems={conditions?.shipmentfilter} />

        </>
    }


    return (
        <>
            <div className='cmd'>
                <div style={{ width: 'calc(100%)' }}>
                    <MultiTable
                        title={'Sales Call Log'}
                        data={MarketingLeadData}
                        columns={columns}
                        conditions={conditions}
                        tabletool={TableTool}
                        actioncell={ActionCell}
                        totaldatalength={totaldatalength}
                        paginationhandler={fatch_MarketingLeadBYPagination}


                    />
                </div>
                {/* <div style={{ width: '370px' }}><LeadTab></LeadTab></div> */}
            </div>
        </>
    )
}

export default Materializelead;







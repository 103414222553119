import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Card, CardContent, Checkbox, IconButton, Paper, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import Dropdown from "../../../component/controls";
import { Close } from "@mui/icons-material";
import { mawbshippers } from "../../../../services/modalData";
import { PostCaller } from "../../../../services/apiServices";
import { AirExpoortJobConsolAdd } from "../../../../services/APIConstant/jobConstant";
import { useDispatch } from "react-redux";
import AlertDialogBox from "../../../component/tabs/alertDialog";
const MyComparisionStrip = ({ jobWithConsoles, selectedItem, cancelCallback, removeSelectedRow }) => {
    const dispatch = useDispatch();
    const [makeFreeConsole, setmakeFreeConsole] = useState(false);
    const [isExistingConsole, setIsExistingConsole] = useState(false);
    const [consoleJobId, setConsoleJobId] = useState([]);
    const [selectedConsoleJobId, setSelectedConsoleJobId] = useState("");
    const [selectedConsoleJobIdError, setSelectedConsoleJobIdError] = useState(false)
    const [selectedConsoleType, setSelectedConsoleType] = useState("");
    const [creatingConsoleErrorDetails, setCreatingConsoleErrorDetails] = useState('')
    const [totalPackages, setTotalPackages] = useState(0);
    const [totalGrossWeight, settotalGrossWeight] = useState(0);
    const [volumnWeight, setvolumnWeight] = useState(0);
    const [chargeableWeight, setchargeableWeight] = useState(0);

    useEffect(() => {
        const consoleJobIdData = jobWithConsoles?.map(res => ({
            name: res.id + ` (${mawbshippers[res.consoleType - 1]?.name})`,
            value: res.id,
            customerType: res.customerType,
            airportOfLoading: res.airportOfLoading,
            airportOfDestination: res.airportOfDestination,
            airlineId: res.airlineId,
            stockProvider: res?.stockProvider,
            vendorId:res?.vendorId,
            consoleType: res.consoleType
        }))

        // if (selectedConsoleType === 1) {
        //     const dpConsoles = consoleJobIdData?.filter(item => item.customerType === 1)
        //     setConsoleJobId(dpConsoles)
        // }
        // else if (selectedConsoleType === 2) {
        //     const odcConsoles = consoleJobIdData?.filter(item => item.customerType === 4)
        //     setConsoleJobId(odcConsoles)
        // }
        // else {
        //     setConsoleJobId(consoleJobIdData)

        // }

        setConsoleJobId(consoleJobIdData)

    }, [selectedConsoleType])


    const selectedJobDataConsoleType = (data) => {
        let setNewState;

        // Check if all types are 1
        const allType1 = data.every(item => item.customerType === 1);

        // Check if all types are 2
        const allType2 = data.every(item => item.customerType === 4);

        if (allType1) {
            setNewState = 1;
        } else if (allType2) {
            setNewState = 2;
        } else {
            setNewState = 3;
        }

        setSelectedConsoleType(setNewState)
    }

    useEffect(() => {
        let totalPackages = 0;
        let totalGrossWeight = 0;
        let totalChargeableWeight = 0;
        let totalVolumnWeight = 0;

        selectedItem.forEach(item => {
            totalPackages += Number(item.finalPackages);
            totalGrossWeight += Number(item.finalGrossWeight);
            totalChargeableWeight += Number(item.finalQtyChargeWeight);
            totalVolumnWeight += Number(item.finalAirVolumeWeight);
        });

        setTotalPackages(totalPackages)
        settotalGrossWeight(totalGrossWeight)
        setvolumnWeight(totalVolumnWeight)
        setchargeableWeight(totalChargeableWeight)

        selectedJobDataConsoleType(selectedItem)

    }, [selectedItem])


    // const isDiffrentAirportOfLoading = () => {
    //     const airportOfLoadingSet = new Set();
    //     return selectedItem.every(item => !airportOfLoadingSet.has(item.airportOfLoading) && airportOfLoadingSet.add(item.airportOfLoading));
    // }

    // const isDiffrentAirportOfDestination = () => {
    //     const airportOfDestinationSet = new Set();
    //     return selectedItem.every(item => !airportOfDestinationSet.has(item.airportOfDestination) && airportOfDestinationSet.add(item.airportOfDestination));
    // }

    const isDifferenceAny = (selectedItems, key) => {
        const [firstItem, ...restItems] = selectedItems;
        return restItems.some(item => item[key] !== firstItem[key]);
    };

    const getSelectedConsoleIdDetails = () => {
        return consoleJobId.find(item => item.value === selectedConsoleJobId)
    }

    const handleSubmit = () => {


        const SelectedConsoleIDDetails = getSelectedConsoleIdDetails()

        const isDifferentAirportOfLoading = () => isDifferenceAny(selectedItem, 'airportOfLoading');
        const isDifferentAirportOfDestination = () => isDifferenceAny(selectedItem, 'airportOfDestination');
        const isDifferentStockProvider = () => isDifferenceAny(selectedItem, 'stockProvider');
        const isDifferentVendor = () => isDifferenceAny(selectedItem, 'vendorId');
        const isDifferentAirline = () => isDifferenceAny(selectedItem, 'airlineId');


        // constant for existant console based selected Console ID
        const isDifferentAirportOfLoadingForExistingConsole = () => isDifferenceAny([...selectedItem, SelectedConsoleIDDetails], 'airportOfLoading');
        const isDifferentAirportOfDestinationForExistingConsole = () => isDifferenceAny([...selectedItem, SelectedConsoleIDDetails], 'airportOfDestination');
        const isDifferentStockProviderForExistingConsole = () => isDifferenceAny([...selectedItem, SelectedConsoleIDDetails], 'stockProvider');
        const isDifferentAirlineForExistingConsole = () => isDifferenceAny([...selectedItem, SelectedConsoleIDDetails], 'airlineId');
        const isDifferentVendorForExistingConsole = () => isDifferenceAny([...selectedItem, SelectedConsoleIDDetails], 'vendorId');



        if (!isExistingConsole && selectedItem.length === 1) {
            setCreatingConsoleErrorDetails('Please select at least two jobs for creating a New Console')
        }
        else if (isDifferentStockProvider()) {
            setCreatingConsoleErrorDetails('Please select Job with the same stockProvider')
        }
        else if (isDifferentVendor()) {
            setCreatingConsoleErrorDetails('Please select Job with the same Vendor')
        }
        else if (isDifferentAirline()) {
            setCreatingConsoleErrorDetails('Please select Job with the same Airline')
        }
        else if ((isDifferentAirportOfLoading() || isDifferentAirportOfDestination())) {
            setCreatingConsoleErrorDetails('Please select same airport of loading and destination')
        }
        else if (isExistingConsole && !selectedConsoleJobId) {
            setSelectedConsoleJobIdError(true)
        }
        else if (isExistingConsole && (isDifferentAirportOfLoadingForExistingConsole() || isDifferentAirportOfDestinationForExistingConsole())) {
            setCreatingConsoleErrorDetails('Please select airport of loading and destination regarding Existing Console')
        }
        else if (isExistingConsole && isDifferentStockProviderForExistingConsole()) {
            setCreatingConsoleErrorDetails('Please select StockProvider regarding Existing Console')
        }
        else if (isExistingConsole && isDifferentVendorForExistingConsole()) {
            setCreatingConsoleErrorDetails('Please select Vendor regarding Existing Console')
        }
        else if (isExistingConsole && isDifferentAirlineForExistingConsole()) {
            setCreatingConsoleErrorDetails('Please select Airline regarding Existing Console')
        }
        else if (isExistingConsole && (SelectedConsoleIDDetails?.consoleType !== selectedConsoleType)) {
            setmakeFreeConsole('Please select ConsoleType regarding Existing Console')
            // setCreatingConsoleErrorDetails('Please select ConsoleType regarding Existing Console')
        }
        else {
            SubmitConsole()
        }


    }

    const SubmitConsole = (isConsoleTypeChange) => {
        const selectedJobs = selectedItem?.map(item => {
            return { jobId: item.id, enquiryId: item.enquiryId };
        });

        const requestData = (() => {
            const data = {
                consoleType: isConsoleTypeChange ? isConsoleTypeChange : selectedConsoleType,
                jobs: selectedJobs
            }

            if (isExistingConsole && selectedConsoleJobId) data.id = selectedConsoleJobId

            return data;
        })();

        PostCaller({
            API: AirExpoortJobConsolAdd,
            callback: () => {
                cancelCallback()
                setmakeFreeConsole(false)
                setCreatingConsoleErrorDetails(false)
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestData
        })
    }

    return (
        <>

            {/* //  Plan For Today Dialog Box  */}
            <AlertDialogBox
                style={{ textAlign: "center", display: "flex", justifyContent: "space-around" }}
                open={creatingConsoleErrorDetails}
                title={'Console'}
                description={<div style={{ width: "350px" }}>{creatingConsoleErrorDetails}</div>}
                submitButtonTitle={'OK'}
                handleSubmit={() => { setCreatingConsoleErrorDetails('') }}
            />

            <AlertDialogBox
                style={{ textAlign: "center", display: "flex", justifyContent: "space-around" }}
                open={makeFreeConsole}
                title={'Console'}
                description={<div style={{ width: "350px" }}>{makeFreeConsole}</div>}
                handleSubmit={() => { SubmitConsole(3) }}
                handleClose={() => { setmakeFreeConsole(false) }}
                cancelButtonTitle={'Select Other Console Id'}
                submitButtonTitle={'Change to free Console'}
            />

            <div className="Comparision_Strip">
                <div className="Comparision_Layout" >
                    <div className="comparision-title">
                        Console
                    </div>

                    <div className="comparision-head-bar" >
                        <ul style={{ display: "flex", alignItems: 'center', gap: "1rem", color: "#fff" }}>
                            <li>PKGS : {totalPackages}</li>
                            <li>GROSS WT. : {totalGrossWeight}</li>
                            <li>CH. WT. : {chargeableWeight}</li>
                            <li>VOL WT. : {volumnWeight}</li>
                            <li>
                                <Tooltip title={'Is It Existing Console'}>
                                    <input className="m-0"
                                        width={'14px'}
                                        height={'14px'}
                                        type="checkbox"
                                        value={isExistingConsole}
                                        onChange={() => setIsExistingConsole(!isExistingConsole)}>
                                    </input>
                                </Tooltip>
                            </li>
                            <li onClick={cancelCallback}><Tooltip title={'Close'}><Close /></Tooltip></li>
                        </ul>
                    </div>

                    <div className="cmd">
                        <div className="comparision_body-content">
                            {selectedItem?.map((ele, index) => (<div onClick={() => { removeSelectedRow(false, ele) }}>{ele.id}</div>))}
                        </div>
                        <div className="cmd" style={{ justifyContent: "right" }}>
                            <div style={{ width: "150px" }}>
                                <Dropdown
                                    className='mt-0'
                                    field={{
                                        label: "Console Type ",
                                        value: selectedConsoleType,
                                        item: mawbshippers,
                                        disable: true,
                                        onChange: (value) => {
                                            setSelectedConsoleType(value)
                                        }
                                    }}
                                />
                            </div>
                            {
                                isExistingConsole &&
                                <div className="ms-3" style={{ width: "150px" }}>
                                    <Dropdown
                                        className='mt-0'
                                        field={{
                                            label: "Select Console ",
                                            value: selectedConsoleJobId,
                                            item: consoleJobId,
                                            required: true,
                                            error: selectedConsoleJobIdError,
                                            onChange: (value) => {
                                                setSelectedConsoleJobId(value)
                                                setSelectedConsoleJobIdError(false)
                                            }
                                        }}
                                    />
                                </div>
                            }

                            <Button className="ms-3" variant="contained" onClick={handleSubmit}> Create Console</Button>
                            {/* <Button variant="contained"> My </Button> */}
                        </div>
                    </div>

                    {/* 
                <div className="cmd" >
                    <div className="d-flex">
                        {selectedItem?.map((ele, index) => {
                            return (
                                <Card
                                    sx={{
                                        display: "flex",
                                        width: "250px",
                                        borderRadius: "15px",
                                        marginLeft: "8px",
                                        backgroundColor: "#F7F7F7",
                                        overflow: "visible"
                                    }}
                                >
                                    <CardContent sx={{ width: '100%', position: "relative" }}>
                                        <div className="cmd" style={{ alignItems: "baseline" }}>
                                            <div>
                                                <li style={{ fontSize: "13px" }}>
                                                    PKGS: <b>{ele.packages}</b>
                                                </li>
                                                <li style={{ fontSize: "13px" }}>
                                                    Gross Wt: <b>{ele.grossWeight}</b>
                                                </li>
                                                <li style={{ fontSize: "13px" }}>
                                                    CH WT: <b>{ele.qtyChargeWeight}</b>
                                                </li>
                                                <li style={{ fontSize: "13px" }}>
                                                    Vol WT: <b>{ele.airVolumeWeight}</b>
                                                </li>
                                            </div>
                                            <IconButton onClick={() => { removeSelectedRow(false, ele) }}>
                                                <CloseIcon sx={{ fontSize: "15px", }} />
                                            </IconButton>
                                        </div>
                                        {index > 0 && (
                                            <span
                                                style={{
                                                    border: "1px dotted red",
                                                    borderRadius: "50%",
                                                    fontSize: "12px",
                                                    backgroundColor: "black",
                                                    color: "white",
                                                    position: "absolute",
                                                    top: "40%",
                                                    left: "-18px",
                                                    height: "28px",
                                                    width: "28px",
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                    alignContent: "center",
                                                    zIndex: "999999"
                                                }}
                                            >
                                                VS
                                            </span>
                                        )}


                                    </CardContent>
                                </Card>
                            );
                        })}
                    </div>
                    <div>

                        <div >
                            <Checkbox> </Checkbox> In Existing Console
                            <div className="cmd my-3">
                                <Dropdown className='me-2'
                                    field={{
                                        id: "",
                                        label: "Console Type",
                                        value: 1,
                                        item: [
                                            { name: "Shippers Console", value: 1 },
                                            { name: "Buyers Console", value: 2 },
                                            { name: "All Other Consolidation", value: 3 }
                                        ],
                                        onChange: (value) => {

                                        }
                                    }}

                                />
                                <Dropdown className='mx-2'
                                    field={{
                                        id: "",
                                        label: "Select Console",
                                        value: 1,
                                        item: [
                                            { name: "1", value: 1 },
                                            { name: "Buyers Console", value: 2 },
                                            { name: "All Other Consolidation", value: 3 }
                                        ],
                                        onChange: (value) => {

                                        }
                                    }}

                                />
                            </div>


                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#F7631F",
                                    borderRadius: "4px solid #F7631F",
                                    color: "white",
                                    width: "170px",
                                }}
                            >
                                Compare Now
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#F7631F",
                                    borderRadius: "4px solid #F7631F",
                                    color: "white",
                                    width: "170px",
                                    marginLeft: "1rem"
                                }}
                            >
                                Console
                            </Button>

                        </div>

                    </div>
                </div> */}
                </div>

            </div>
        </>
    );
};

export default MyComparisionStrip;

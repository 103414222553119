import React, { useEffect, useState } from 'react';
import { EnquirySelectVendor, EnquiryTypeofvendor } from '../../../services/modalData';
import Dropdown from '../../component/controls';
import { Button } from '@mui/material';
import AttachFile from '@mui/icons-material/AttachFile';


const EnquiryVendorPdf = () => {
  const [viewEnquiryData, setViewEnquiryData] = useState({})
  const [viewType, setViewType] = useState(false)
  const [vendortype, setVendertype] = useState('')

  useEffect(() => {
    const viewExportEnquiry = JSON.parse(sessionStorage.getItem('viewExportEnquiry'));
    const viewImportEnquiry = JSON.parse(sessionStorage.getItem('viewImportEnquiry'));

    if (viewExportEnquiry) {
      setViewEnquiryData(viewExportEnquiry);
      setViewType('export')
    }

    if (viewImportEnquiry) {
      setViewEnquiryData(viewImportEnquiry);
      setViewType('import')

    }


  }, [])



  const handleInputChange = () => {
    console.log('consollog')
  }

  const handleFormSubmit = () => {

  }

  return <>
    <div className='hr-form-box'>
      <div className="row mb-3">
        <div className="col-md-10">

        </div>
        <div className="col-md-2">
          <Dropdown
            field={{
              id: 'bizInstituteTypeId',
              label: "Select Type Of Vendor",
              type: 'select',
              value: vendortype,
              required: true,
              error: "",
              item: EnquiryTypeofvendor,
              onChange: (value) => {
                setVendertype(value)
              }

            }}
          />
        </div>
      </div>
      <div style={{ border: "3px solid #ddd", borderRadius: "4px" }}>
        <div className='row '>

          <div className='col-md-12' >

            <div className='cmd' style={{ border: "1px solid #ddd", padding: '1rem' }}>
              <p><b>Enquiry Id -</b> <span>{viewEnquiryData?.eid} </span></p>
              <h6><b>{viewType === 'import' ? 'AIR IMPORT' : "AIR EXPORT"}</b></h6>
              <p><b>Enquiry Date -</b> <span>{viewEnquiryData?.enquiryDate}</span></p>
            </div>

          </div>

          <div className='col-md-6 pe-0'>
            <div className="check-customer-table">
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: "350px", textAlign: "left" }}><b>PACKAGES</b></td>
                    <td>{viewEnquiryData?.packages}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "350px", textAlign: "left" }}><b>AIR VOLUME WEIGHT/CBM</b></td>
                    <td>{viewEnquiryData?.cbm}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "350px", textAlign: "left" }}><b>AIRPORT OF LOADING</b></td>
                    <td>{viewEnquiryData?.airportOfLoadingName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-6'>


            <div className="check-customer-table">
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: "350px", textAlign: "left" }}><b>GROSS WEIGHT</b></td>
                    <td>{viewEnquiryData?.grossWeight}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "350px", textAlign: "left" }}><b>COMMODITY</b></td>
                    <td>{viewEnquiryData?.commodity}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "350px", textAlign: "left" }}><b>AIRPORT OF DESTINATION</b></td>
                    <td>{viewEnquiryData?.airportOfDestinationName}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div className='col-md-12'>
            <div className="check-customer-table">
              <table >
                <tbody>
                  <tr>
                    <td style={{ width: "350px", textAlign: "left" }}><b>DIMENSIONS LIST</b></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div className='col-md-12'>
            <div className="check-customer-table">
              <table >
                <tbody>
                  <tr>
                    <td style={{ width: "350px", textAlign: "left" }}><b>SPECIAL REQUIREMENTS/ REMARKS</b></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>

          <div className='col-md-12'  >
            <div style={{ border: "1px solid #ddd", padding: '1rem' }}>
              <p>TERMS & CONDITION :</p>
              <div className='cmd'>
                <p>1. MSDS is Attached</p>
                <p> <AttachFile /></p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div className='cmd'>
      <div></div>
      <Button sx={{ m: 2, borderRadius: "10px", }} onClick={handleFormSubmit} variant="contained">Send Enquiry</Button>
    </div>

  </>
}

export default EnquiryVendorPdf;

import React, { useEffect, useState } from 'react';
import { MultiTable } from '../../component/multiTable';
import {  PostMasterCountry, PostMasterState, PostMasterCity, UpdateConsigneeSubAgencyStatus, } from '../../../services/constant';
import MasterCallBypagination from '../../../services/masterpaginationConfig';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CustomerTable } from '../customer/customerTable';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { InputFileField, SmallSwitch } from '../../component/controls';
import { columnConstant } from '../../constant/formconstant';
import { ConsigneeSubAgencyAddressForm } from '../addConsignee/consigneeAgencyAddressform';
import { GetAirportListShipperData, PostAirportSealstatus, PostAirportvehical } from '../../../services/APIConstant/warehouseConstant';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Dropdown, { InputField } from '../../component/controls';
import { ConditionofEntry, ConditionofGoodsType } from '../../../services/modalData';
import DivisonDrawer from '../../component/divisionDrawer';
import { AirportCheckListDrawer } from './airportchecklist';
const authToken = localStorage.getItem("authToken");
const headers = {
    Authorization: `Bearer ${authToken}`, // Include the Bearer token in the Authorization header
};

const AirportList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [airportMaster, airpostMasterData] = useState([])
    const [countryItem, setCountryItem] = useState([]);
    const [stateData, setstateData] = useState([])
    const [cityData, setcityData] = useState([])
    const [totaldatalength, setTotalDataLength] = useState(0)
    const [prevSlotNo, setPrevSlotNo] = useState(null);
    const fatch_MasterDataBYPagination = (item) => {
        MasterCallBypagination({
            API: GetAirportListShipperData,
            callback: setTableDataHandler, dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: item
        })
    }

    useEffect(() => {
        fatch_MasterDataBYPagination();

        GetCaller({
            API: PostMasterCountry,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newcountrydata = response?.data?.data?.records.map((item) => ({
                    value: item.id,
                    name: item.name
                }));
                setCountryItem(newcountrydata)
            }

        });

        // State Data
        GetCaller({
            API: PostMasterState,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                setstateData(response?.data?.data?.records)
            }
        });

        //CityData
        GetCaller({
            API: PostMasterCity,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                setcityData(response?.data?.data?.records)
            }
        });

    }, []);

    const setTableDataHandler = ({ response }) => {
        debugger
        const newmasterdata = response?.data?.data?.jobData.map((res, index) => ({

            sn: index + 1,
            id: res?.id,

            goodsInRegisterId: res.goodsInRegisterId,
            partyName: res.partyName,
            jobType: res.jobType,
            packages: res.packages,
            grossWeight: res.grossWeight,
            consoleNumber: res.consoleNumber,
            dimension: res.dimension,
            destinationType: res.destinationType,
            dimensionFilePath: res.dimensionFilePath,
            slotTime: res.slotTime,
            lastHandoverTime: res.lastHandoverTime,
            actualShipper: res.actualShipper,
            customerName: res.customerName,
            mawbNumber: res.mawbNumber,
            hawbNumber: res.hawbNumber,
            jobNumber: res.jobNumber,
            totalCost: res.totalCost,
            pickupStatus: res.pickupStatus,
            challanType: res.challanType,
            airportGrossWeight: res.airportGrossWeight,
            airportVolumeWeight: res.airportVolumeWeight,
            airportPackages: res.airportPackages,
            isAirportWeightDifference: res.isAirportWeightDifference,
            customerBranchList: res.transporters.map(({ jobNumber, ...rest }) => ({
                ...rest,
                jobNumber: res.jobNumber,
                hhhhhh: "800008"

            })),

            uploadFilePath: res?.string,
            status: res?.status

        }));

        airpostMasterData(newmasterdata);
        debugger
        setTotalDataLength(newmasterdata?.length)
    }

    const columns = [
        {
            id: 'sn',
            label: columnConstant.sno,
            numeric: false,
        },

        {
            id: 'name',
            label: columnConstant.jobno,
            numeric: false,
        },
        {
            id: 'partyName',
            label: columnConstant.shippername,
            numeric: false,
        },
        {
            id: 'pinCode',
            label: columnConstant.apod,
            numeric: false,
        },
        {
            id: 'packages',
            label: columnConstant.packages,
            numeric: false,
        },

        {
            id: 'grossWeight',
            label: columnConstant.grosswt,
            numeric: false,
        },
        {
            id: 'airportVolumeWeight',
            label: columnConstant.volumnweight,
            numeric: false,
        },
        {
            id: 'mawbNumber',
            label: columnConstant.mawbno,
            numeric: false,
        },
        {
            id: 'eoriNo',
            label: columnConstant.freightsb,
            numeric: false,
        },
        {
            id: 'eoriNo',
            label: columnConstant.sbno,
            numeric: false,
        },
        {
            id: 'lastHandoverTime',
            label: columnConstant.lht,
            numeric: false,
        },
        {
            id: 'action',
            label: 'Action',
            numeric: false,
        },
        {
            id: 'subtable',
            label: '',
            numeric: false,
        },
    ];

    const subColumns = [
        {
            id: 'sn',
            label: '#',
            numeric: false,
        },
        {
            id: 'hhhhhh',
            label: columnConstant.slotno,
            numeric: false,
        },
        {
            id: 'vehicleNumber',
            label: columnConstant.vehicalno,
            numeric: false,
        },
        {
            id: 'driverNumber',
            label: columnConstant.drivernameno,
            numeric: false,
        },
        {
            id: 'sealNumber',
            label: columnConstant.sealno,
            numeric: false,
        },
        {
            id: 'packages',
            label: columnConstant.packages,
            numeric: false,
        },
        {
            id: 'challanNumber',
            label: columnConstant.chalanno,
            numeric: false,
        },
        {
            id: 'comment',
            label: columnConstant.remark,
            numeric: false,
        },
        {
            id: 'action',
            label: '',
            numeric: false,
        },

    ];

    const editAddressBtnClicked = () => {
        <ConsigneeSubAgencyAddressForm callback={fatch_MasterDataBYPagination} />
    }

    const subActionCell = ({ row }) => {
        return <>
            <VehicalStatusDrawer
                row={row}
            />
        </>
    }

    const ActionCell = ({ row }) => {
        const handleSwitchChange = () => {
            const data = {
                id: row?.id,
            }
            GetCaller({
                API: UpdateConsigneeSubAgencyStatus,
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                item: data,
                callback: ({ response }) => {
                    fatch_MasterDataBYPagination();
                }
            });
        }

        return <>
            <AirportCheckListDrawer
                row={row}
            />
        </>

    }

    //table head section tool
    const TableTool = ({ }) => {
        return <>

        </>
    }
    const conditions = {
        subColumns: subColumns,
        subActionCell: subActionCell
    }
    const SubTableCell = (row) => {

        const getRowspanValue = (hhhhhh) => {
            const count = row.customerBranchList.filter((res) => res.hhhhhh === hhhhhh).length;
            return count > 1 ? count : 0;
        };
        return <>
            <div>
                {
                    <div className='check-customer-table'>
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{columnConstant.slotno}</th>
                                    <th>{columnConstant.vehicalno}</th>
                                    <th>{columnConstant.drivernameno}</th>
                                    <th>{columnConstant.sealno}</th>
                                    <th>{columnConstant.packages}</th>
                                    <th>{columnConstant.chalanno}</th>
                                    <th>{columnConstant.remark}</th>
                                </tr>
                            </thead>

                            <tbody>

                                {row.customerBranchList?.map((res, key) => {
                                    const shouldRenderSlotNo = res.hhhhhh !== prevSlotNo;
                                    const rowspanValue = shouldRenderSlotNo ? getRowspanValue(res.hhhhhh) : 0;

                                    // Update the previous slotno value
                                    setPrevSlotNo(res.hhhhhh);

                                    return (
                                        <tr key={key}>
                                            <td>{res.id || ''}</td>
                                            {shouldRenderSlotNo && <td rowSpan={rowspanValue}>{res.hhhhhh}</td>}
                                            {!shouldRenderSlotNo && <td></td>} {/* Empty cell for rowspan case */}
                                            <td>{res.vehicleNumber || ''}</td>
                                            <td>{res.driverNumber || ''}</td>
                                            <td>{res.sealNumber || ''}</td>
                                            <td>{res.packages || ''}</td>
                                            <td>{res.challanNumber || ''}</td>
                                            <td>{res.comment || ''}</td>
                                        </tr>
                                    );
                                })}


                            </tbody>
                        </table>
                    </div>

                }
            </div>
        </>
    }

    return (
        <>

            <CustomerTable
                data={airportMaster}
                conditions={conditions}
                columns={columns}
                title={'Search'}
                tabletool={TableTool}
                subTableCell={SubTableCell}
                actioncell={ActionCell}
                totaldatalength={totaldatalength}
                paginationhandler={fatch_MasterDataBYPagination}

            />
        </>
    )
}

export default AirportList;

const VehicalStatusDrawer = ({ callAPI, editor, row }) => {
    const [closeValidator, setcloseValidator] = useState(false)
    const [activeStep, setActiveStep] = React.useState(0);
    const [goodcondition, setGoodcondition] = React.useState(1);
    const [valueradio, setValue] = useState('');
    const steps = [
        {
            label: 'Seal Intact',
            id: "",
            value: "",
            hasRadio: true,
        },
        {
            label: 'Condition of Goods',
            id: "",
            value: "",
            hasBody: true,

        },

    ];
    const [airportcheck, setAirportcheck] = useState({

        // GoodsOutTransporterId: row?.transporters["id"],
        GoodsOutTransporterId: "",
        GoodsCondition: "",
        Comments: "",
        UploadFile: "",
        JobNumber: '',
        UploadFile: ""
    });

    const [airportvehical, setAirportVehical] = useState({
        GoodsOutTransporterId: "",
        SealStatusVal: "",
        SealImage: "",
        SealIntactValue: ""
    });

    const requestdata = (() => {
        const data = {
            GoodsCondition: airportcheck.GoodsCondition,
        }
        if (airportcheck.GoodsCondition !== 1) data.Comments = airportcheck.Comments
        if (airportcheck.GoodsCondition !== 1) data.UploadFile = airportcheck.UploadFile
        return data;
    })()

    const requestdataVehical = {
        SealImage: airportvehical.SealImage,
        SealIntactValue: airportvehical.SealIntactValue,
    };

    const handleInputradioChange = (event) => {
        setValue(event.target.value);
    };

    const handleNext = (index) => {
        setActiveStep(index)

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    const handleInputChange = (value, field) => {
        let fieldData = {};
        debugger;
        setAirportcheck({
            ...airportcheck,
            [field.id]: value,
            [field.id + 'error']: !value,
        });
        setAirportVehical({
            ...airportvehical,
            [field.id]: value,
            [field.id + 'error']: !value,
        });

    };


    const handleFormSubmit = () => {
        const changes = handleSubmit(requestdata);
        if (Object.keys(changes).length > 0) {
            // If there are changes, call PostAirportSealstatus API with requestdataVehical
            setAirportcheck({ ...airportcheck, ...changes });

        } else {
            // If there are no changes, call PostAirportvehical API with requestdata
            AddAirportcheckStatus(requestdata);
        }
    };


    const handleSubmit = (obj) => {
        debugger
        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                changes[key + 'error'] = !value;
            }
        });
        return changes;
    };






    const callseelstatus = () => {
        Updatesealstatus(requestdataVehical)
    }


    //     const handleBlur = () => {
    // debugger
    //         // Check if radio button is set to "No" and both fields have values
    //         if (valueradio === "No" && airportvehical.SealIntactValue && airportcheck.SealImage) {
    //             // Call the API
    //             Updatesealstatus(requestdataVehical);
    //         }
    //     };


    const Updatesealstatus = (requestdataVehical) => {
        debugger;
        console.log(requestdataVehical);
        requestdataVehical.GoodsOutTransporterId = row.id
        if (valueradio === "Yes") {
            requestdataVehical.SealStatusVal = 1
        } else {
            requestdataVehical.SealStatusVal = 2
        }

        PostCaller({
            API: PostAirportSealstatus,
            callback: () => {
                // handleClose();
                // callback()
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdataVehical
        })

    }

    const AddAirportcheckStatus = (requestdata) => {
        debugger;
        requestdata.GoodsOutTransporterId = row.id
        requestdata.JobNumber = row.jobNumber




        PostCaller({
            API: PostAirportvehical,
            callback: () => {
                // handleClose();
                // callback()
            },
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: requestdata
        })

    }

    const dispatch = useDispatch()

    const drawerOpenCallbackHandler = () => {

    }


    const drewarclosed = () => {
        setcloseValidator(true)
        setValue('')
    }


    useEffect(() => {
        setcloseValidator(false)
    }, [closeValidator]);


    const drawerFormFields = {
        title: "Vehicle Status",
        icon: <i className="las la-list-alt"></i>,
        tooltitle: "Vehicle Status",
        iconclass: 'noClass',
        defaultbtnfalse: true

    }




    const divisionDrawerody = () => {

        return (
            <Box>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label} onClick={() => handleNext(index)}>
                            <StepLabel>
                                <div className='airport_clecklist_stepper'>
                                    <span className='airport_checklist_label'>{step.label}</span>
                                    {step.hasRadio ? (
                                        <RadioGroup
                                            row
                                            sx={{ flexWrap: "nowrap" }}
                                            // value={index < activeStep ? 'Yes' : 'No'}
                                            value={valueradio}
                                            onChange={handleInputradioChange}
                                        //    onsubmit={Updatesealstatus}                                           

                                        >
                                            <FormControlLabel
                                                sx={{ marginLeft: 0 }}
                                                value="Yes"
                                                control={<Radio color="primary" />}
                                                label="Yes"
                                                onClick={callseelstatus}
                                            //  onBlur={handleBlur}
                                            // disabled
                                            />
                                            <FormControlLabel
                                                sx={{ marginLeft: 0 }}
                                                value="No"
                                                control={<Radio color="primary"

                                                />}
                                                label="No"
                                            // disabled
                                            />
                                        </RadioGroup>

                                    ) : (
                                        <Dropdown
                                            field={
                                                {
                                                    id: 'GoodsCondition',
                                                    label: 'Goods Conditions',
                                                    type: 'select',
                                                    value: airportcheck.GoodsCondition,
                                                    required: true,
                                                    error: airportcheck.GoodsConditionerror,
                                                    item: ConditionofGoodsType,
                                                    disable: false,
                                                    onChange: handleInputChange
                                                }
                                            }
                                        />
                                    )}

                                </div>
                            </StepLabel>

                            {
                                step.hasRadio && valueradio === "No" && <StepContent>
                                    <Box sx={{ mb: 2 }}>
                                        <div>
                                            <InputField
                                                field={
                                                    {
                                                        id: 'SealIntactValue',
                                                        label: "Enter Seal Intact",
                                                        type: "text",
                                                        value: airportvehical.SealIntactValue,
                                                        required: true,
                                                        error: airportvehical.SealSealIntactValueerror,
                                                        onChange: handleInputChange,
                                                        // onBlur: handleBlur
                                                    }
                                                }
                                            />
                                        </div>
                                        <div>
                                            <InputFileField
                                                field={
                                                    {
                                                        id: 'SealImage',
                                                        label: "Upload Pic.",
                                                        type: "file",
                                                        value: airportcheck.SealImage,
                                                        required: true,
                                                        error: airportcheck.SealImageerror,
                                                        onChange: handleInputChange,
                                                        // onblur: handleBlur
                                                    }
                                                }
                                            />
                                        </div>
                                    </Box>
                                </StepContent>
                            }
                            {
                                step.hasBody && airportcheck.GoodsCondition !== 1 && <StepContent>
                                    <Box sx={{ mb: 2 }}>
                                        <div>
                                            <InputField
                                                field={
                                                    {
                                                        id: 'Comments',
                                                        label: "Comments",
                                                        type: "text",
                                                        value: airportcheck.Comments,
                                                        required: true,
                                                        error: airportcheck.Commentserror,
                                                        onChange: handleInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                        <div>
                                            <InputFileField
                                                field={
                                                    {
                                                        id: 'UploadFile',
                                                        label: "Upload Pic.",
                                                        type: "file",
                                                        value: airportcheck.UploadFile,
                                                        required: true,
                                                        error: airportcheck.UploadFileerror,
                                                        onChange: handleInputChange
                                                    }
                                                }
                                            />
                                        </div>
                                    </Box>
                                </StepContent>
                            }

                        </Step>
                    ))}
                </Stepper>
                <Button sx={{ m: 2, borderRadius: "10px" }} variant="outlined" onClick={drewarclosed}>Cancel</Button>
                <Button sx={{ m: 2, borderRadius: "10px" }} type='submit' onClick={handleFormSubmit} variant="contained">Submit</Button>

            </Box>
        );

    }
    return <>
        <DivisonDrawer
            drawerFormFields={drawerFormFields}
            closeValidator={closeValidator}
            openCallBack={drawerOpenCallbackHandler}
            divisiondrawerbody={divisionDrawerody}
        />

    </>

}




import React, { useEffect, useState } from 'react'
import Dropdown, { InputField, InputFileField } from '../../component/controls';
import { columnConstant, formconstant } from '../../constant/formconstant';
import { ChallanType, ChargeExportImport, DestinationType, EnquiryDimension, GoodsConditions, PickUpType, PickupStatus, PickupStatusForOut } from '../../../services/modalData';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Tooltip } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import GetCaller, { PostCaller } from '../../../services/apiServices';
import { GetCustomerBySearch, GetVehicalTypeByPage, PostVendorMaster } from '../../../services/constant';
import { useDispatch } from 'react-redux';
import { PostWerehouseGoodsInRegister, PostWerehouseGoodsOutRegister, UpdateWerehouseGoodsInRegister, UpdateWerehouseGoodsOutRegister } from '../../../services/APIConstant/warehouseConstant';
import { useNavigate } from 'react-router-dom';
import { goodsinregisterlist, goodsoutregisterlist } from '../../../navigation/constant';
import AlertShow from '../../component/snackbar/AlertShow';
import Transition from '../../../services/utilities/transition';



const CreateGoodInRegister = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState(false);
    const [actualShipperItem, setActualShipperItem] = useState([]);
    const [transportNameItem, setTransPortNameItem] = useState([]);
    const [transporterDetailsError, setTransporterDetailsError] = useState([]);
    const [vehicalTypeItem, setVehicalTypeItem] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [totalPackages, setTotalPackages] = useState(0);
    const [totalGrossWeight, setTotalGrossWeight] = useState(0);
    const [edit, setEdit] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        GetCaller({
            API: GetCustomerBySearch,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.legalName
                    }));

                setActualShipperItem(newCountryItemData);

            }

        });
        GetCaller({
            API: PostVendorMaster,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: {},
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.legalName
                    }));

                setTransPortNameItem(newCountryItemData);

            }

        });
        GetCaller({
            API: GetVehicalTypeByPage,
            dispatch: (e) => {
                dispatch({ type: e.type, payload: e.payload })
            },
            item: { status: true },
            callback: ({ response }) => {
                const newCountryItemData = response?.data?.data?.records
                    .map(item => ({
                        value: item.id,
                        name: item.name
                    }));

                setVehicalTypeItem(newCountryItemData);

            }

        });
    }, [])

    const handleFileOpen = () => {
        setOpen(true);
    };

    const handleFileClose = () => {
        setOpen(false);
        handleInputChange(null, { id: 'dimensionFile' })
    }
    const handleFileSubmit = () => {
        handleInputChange(file, { id: 'dimensionFile' })
        setOpen(false);
    }

    const [GoodInRegisterForm, setGoodInRegisterForm] = useState({
        partyName: "",
        jobType: "",
        packages: "",
        grossWeight: "",
        consoleNumber: "",
        dimension: "",
        dimensionFile: "",
        actualShipper: "",
        mawbNumber: "",
        hawbNumber: "",
        jobNumber: "",
        totalCost: "",
        pickupStatus: "",
        challanType: "",
        destinationType: "",
        transporters: [
            {
                id: "",
                vendorId: "",
                pickup: "",
                pickupVehicleTypeId: "",
                vehicleNumber: "",
                driverName: "",
                driverNumber: "",
                packages: "",
                number: "",
                comment: "",
                cost: "",
                grossWeight: "",
                goodsCondition: "",
                uploadFile: "",
                goodsOutRegisterId: 1,
                sealNumber: 1,
                dimensionFilePath: ""
            }
        ],
    });

    const requestdata = (() => {

        const data = {
            partyName: GoodInRegisterForm.partyName,
            jobType: GoodInRegisterForm.jobType,
            dimension: GoodInRegisterForm.dimension,
            transporters: GoodInRegisterForm.transporters,
        }
        if (GoodInRegisterForm.dimensionFilePath) data.dimensionFilePath = GoodInRegisterForm.dimensionFilePath
        if (GoodInRegisterForm.mawbNumber) data.mawbNumber = GoodInRegisterForm.mawbNumber
        if (GoodInRegisterForm.hawbNumber) data.hawbNumber = GoodInRegisterForm.hawbNumber
        if (totalCost) data.totalCost = Number(totalCost)
        if (totalPackages) data.packages = Number(totalPackages)
        if (totalGrossWeight) data.grossWeight = Number(totalGrossWeight)
        if (GoodInRegisterForm?.destinationType !== 1) data.pickupStatus = GoodInRegisterForm.pickupStatus
        // dimetion file condition for post 
        if (edit !== true && edit !== "updateGoodsOutListData") data.dimensionFile = GoodInRegisterForm.dimensionFile
        // actual shipper condtional required 
        if (GoodInRegisterForm.actualShipper ||
            GoodInRegisterForm.destinationType === 1 ||
            (GoodInRegisterForm.pickupStatus === 3 && (edit === 'goodsOutListData' || edit === "expectedGoodsOutListData" || edit === "updateGoodsOutListData"))
        ) data.actualShipper = GoodInRegisterForm.actualShipper

        if (GoodInRegisterForm.jobNumber ||
            GoodInRegisterForm.destinationType === 1 ||
            (GoodInRegisterForm.pickupStatus === 3 && (edit === 'goodsOutListData' || edit === "expectedGoodsOutListData" || edit === "updateGoodsOutListData"))
        ) data.jobNumber = Number(GoodInRegisterForm.jobNumber)

        if (GoodInRegisterForm.consoleNumber) data.consoleNumber = Number(GoodInRegisterForm.consoleNumber)
        if (GoodInRegisterForm.id) data.id = GoodInRegisterForm.id
        if ((edit === 'goodsOutListData' || edit === "expectedGoodsOutListData" || edit === "updateGoodsOutListData") && GoodInRegisterForm?.destinationType) data.destinationType = GoodInRegisterForm.destinationType
        if (edit === 'goodsOutListData' || edit === "expectedGoodsOutListData" || edit === "updateGoodsOutListData") data.challanType = GoodInRegisterForm.challanType
        if (edit === 'goodsOutListData' || edit === "updateGoodsOutListData" || edit === true) data.goodsInRegisterId = GoodInRegisterForm.id
        if (edit === "expectedGoodsOutListData" || edit === "expectedGoodsInRegisterData") data.expectedGoodsId = GoodInRegisterForm.id

        return data;
    })();

    useEffect(() => {
        debugger
        const expectedGoodsInRegisterData = JSON.parse(sessionStorage.getItem("expectedGoodsInRegisterData"))
        const expectedGoodsOutListData = JSON.parse(sessionStorage.getItem("expectedGoodsOutListData"))
        const goodsInRegisterListData = JSON.parse(sessionStorage.getItem("goodsInRegisterListData"))
        const goodsOutListData = JSON.parse(sessionStorage.getItem("goodsOutListData"))
        const updateGoodsOutListData = JSON.parse(sessionStorage.getItem("updateGoodsOutListData"))

        // from expected page for good in register
        if (expectedGoodsInRegisterData) {
            setEdit("expectedGoodsInRegisterData")
            expectedGoodsInRegisterData.actualShipper = expectedGoodsInRegisterData.shipperId
            expectedGoodsInRegisterData.transporters = GoodInRegisterForm?.transporters
            setGoodInRegisterForm(expectedGoodsInRegisterData)
            sessionStorage.removeItem("expectedGoodsInRegisterData")
        }
        // from expected page for good out register
        if (expectedGoodsOutListData) {
            setEdit("expectedGoodsOutListData")
            expectedGoodsOutListData.actualShipper = expectedGoodsOutListData.shipperId
            expectedGoodsOutListData.transporters = GoodInRegisterForm?.transporters
            setGoodInRegisterForm(expectedGoodsOutListData)
            sessionStorage.removeItem("expectedGoodsOutListData")
        }

        if (goodsInRegisterListData) {
            if (goodsInRegisterListData.noedit) {
                setEdit(false)

            } else {
                setEdit(true)
            }

            goodsInRegisterListData.transporters = goodsInRegisterListData?.transporters.map(({ uploadFilePath, ...rest }) => ({
                ...rest,
                uploadFile: uploadFilePath
            }))

            setGoodInRegisterForm({
                ...GoodInRegisterForm,
                ...goodsInRegisterListData
            })
        }

        if (goodsOutListData) {
            setEdit("goodsOutListData")
            goodsOutListData.totalCost = ""
            goodsOutListData.transporters = GoodInRegisterForm.transporters
            setGoodInRegisterForm({
                ...GoodInRegisterForm,
                ...goodsOutListData
            })
            sessionStorage.removeItem("goodsOutListData")
        }
        if (updateGoodsOutListData) {
            setEdit("updateGoodsOutListData")
            updateGoodsOutListData.transporters = updateGoodsOutListData?.transporters.map(({ uploadFilePath, ...rest }) => ({
                ...rest,
                uploadFile: uploadFilePath
            }))
            setGoodInRegisterForm(updateGoodsOutListData)
        }


    }, [])

    const handleInputChange = (value, field) => {
        setGoodInRegisterForm({
            ...GoodInRegisterForm,
            [field.id]: value,
            [field.id + 'error']: !value,
        })
    }

    const hasUndefinedValue = (obj) => {
        debugger
        let changes = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                changes[key + "error"] = !value;
            }
        });
        return changes;
    };

    const handleFormSubmit = () => {
        const transporterDetailshasUndefinedValue = (array) => {
            debugger
            let changes = {};
            array.map((obj, index) => {
                Object.entries(obj).forEach(([key, value]) => {
                    debugger
                    if (
                        key === 'sealNumber' ||
                        key === "dimensionFilePath" ||
                        key === 'vendorName' ||
                        key === 'uploadFilePath' ||
                        key === "id" ||
                        key === "challanNumber" ||
                        (obj.pickup !== 1 && key === 'vendorId') ||
                        (obj.pickup !== 1 && key === 'cost') ||
                        (obj.goodsCondition !== 4 && obj.goodsCondition !== 5 && key === "number") ||
                        (obj.goodsCondition === 1 && key === 'comment') ||
                        (obj.goodsCondition === 1 && key === 'uploadFile')
                    ) {

                    }
                    else if (!value) {
                        changes[key + index] = !value;
                    }
                });
            })
            console.log('transporterDetailshasUndefinedValue changes', changes)

            return changes;
        };


        debugger
        const transporterDetailsChanges = transporterDetailshasUndefinedValue(requestdata.transporters);
        const changes = hasUndefinedValue(requestdata);


        if (Object.keys(changes).length > 0 ||
            Object.keys(transporterDetailsChanges).length > 0
        ) {
            setGoodInRegisterForm({
                ...GoodInRegisterForm,
                ...changes
            });

            setTransporterDetailsError(transporterDetailsChanges)

            if (changes.dimensionFileerror) {
                handleFileOpen()
            }
        }
        else if ((Number(GoodInRegisterForm.grossWeight) !== 0 && totalGrossWeight > GoodInRegisterForm.grossWeight) ||
            (Number(GoodInRegisterForm.packages) !== 0 && totalPackages > GoodInRegisterForm.packages)) {
            setShowAlert(true);
        }
        else {
            SubmitAPI()
        }
    };

    const SubmitAPI = () => {
        if (edit === "updateGoodsOutListData") {
            PostCaller({
                API: UpdateWerehouseGoodsOutRegister,
                callback: () => { navigate(goodsoutregisterlist) },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                contenttype: true,
                item: requestdata
            })
        }
        else if (edit === 'goodsOutListData' || edit === 'expectedGoodsOutListData') {
            PostCaller({
                API: PostWerehouseGoodsOutRegister,
                callback: () => { navigate(goodsoutregisterlist) },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                contenttype: true,
                item: requestdata
            })
        }
        else if (edit === true) {
            PostCaller({
                API: UpdateWerehouseGoodsInRegister,
                callback: () => { navigate(goodsinregisterlist) },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                contenttype: true,
                item: requestdata
            })
        }
        else {
            PostCaller({
                API: PostWerehouseGoodsInRegister,
                callback: () => { navigate(goodsinregisterlist) },
                dispatch: (e) => {
                    dispatch({ type: e.type, payload: e.payload })
                },
                contenttype: true,
                item: requestdata
            })
        }
    }







    function TransportMultiDetails() {

        const generateUniqueKey = () => {
            return Math.random().toString(36).substr(2, 9);
        };

        useEffect(() => {
            const goodsInRegisterListData = JSON.parse(sessionStorage.getItem("goodsInRegisterListData"))
            // const goodsOutListData = JSON.parse(sessionStorage.getItem("goodsOutListData"))
            const goodsOutListData = false
            const updateGoodsOutListData = JSON.parse(sessionStorage.getItem("updateGoodsOutListData"))

            if (goodsInRegisterListData || goodsOutListData || updateGoodsOutListData) {

                let data = updateGoodsOutListData?.transporters ? updateGoodsOutListData?.transporters
                    : goodsInRegisterListData?.transporters ? goodsInRegisterListData?.transporters
                        : goodsOutListData?.transporters

                let updatedDetailsData = []

                data.map((res, key) => {
                    let obj = {
                        key: generateUniqueKey(), // Add a unique key for each input field
                        id: res.id,
                        vendorId: res.vendorId,
                        pickup: res.pickup,
                        pickupVehicleTypeId: res.pickupVehicleTypeId,
                        vehicleNumber: res.vehicleNumber,
                        driverName: res.driverName,
                        driverNumber: res.driverNumber,
                        packages: res.packages,
                        number: res.number,
                        comment: res.comment,
                        cost: res.cost,
                        goodsCondition: res.goodsCondition,
                        uploadFile: res.uploadFile,
                        cost: res.cost,

                        grossWeight: Number(res.grossWeight), goodsOutRegisterId: 1,
                        sealNumber: 1,
                        dimensionFilePath: ""
                    }
                    updatedDetailsData.push(obj)

                })
                setInputFields(updatedDetailsData)
                sessionStorage.removeItem("goodsInRegisterListData")
                // sessionStorage.removeItem("goodsOutListData")
                sessionStorage.removeItem("updateGoodsOutListData")
            }

        }, [])

        const [inputFields, setInputFields] = useState([
            {
                key: generateUniqueKey(), // Add a unique key for each input field
                id: "",
                vendorId: "",
                pickup: "",
                pickupVehicleTypeId: "",
                vehicleNumber: "",
                driverName: "",
                driverNumber: "",
                packages: "",
                number: "",
                comment: "",
                cost: "",
                grossWeight: "",
                goodsCondition: "",
                uploadFile: "",
                cost: "",
                goodsOutRegisterId: 1,
                sealNumber: 1,
                dimensionFilePath: ""
            },
        ]);


        const totalValueCounter = () => {
            let totalPackages = 0
            let totalGrossWeight = 0
            let totalCost = 0

            inputFields.map(res => {
                debugger
                const cost = res.cost !== "" ? Number(res.cost) : 0;
                const grossWeight = res.grossWeight !== "" ? Number(res.grossWeight) : 0;
                const packages = res.packages !== "" ? Number(res.packages) : 0;

                totalCost = Number(totalCost + cost);
                totalGrossWeight = Number(totalGrossWeight + grossWeight);
                totalPackages = Number(totalPackages + packages);
            })

            setTotalCost(totalCost)
            setTotalGrossWeight(totalGrossWeight)
            setTotalPackages(totalPackages)

            // if (edit !== "expectedGoodsInRegisterData" && (totalGrossWeight !== 0 || totalPackages !== 0)) {
            // setGoodInRegisterForm({
            //     ...GoodInRegisterForm,
            //     ["totalCost"]: totalCost,
            //     ["grossWeight"]: totalGrossWeight,
            //     ["packages"]: totalPackages,
            // })
            // }


            console.log(totalCost, totalGrossWeight, totalPackages);
        }

        useEffect(() => {
            totalValueCounter()
        }, [inputFields])

        const addInputField = () => {
            setInputFields([...inputFields, {
                key: generateUniqueKey(),
                id: "",
                vendorId: "",
                pickup: "",
                pickupVehicleTypeId: "",
                vehicleNumber: "",
                driverName: "",
                driverNumber: "",
                packages: "",
                number: "",
                comment: "",
                cost: "",
                grossWeight: "",
                goodsCondition: "",
                uploadFile: "",
                cost: "",
                goodsOutRegisterId: 1,
                sealNumber: 1,
                dimensionFilePath: ""
            }]);
        };

        const handleDuplicateInputChange = (key, field, value, index) => {
            const updatedInputFields = [...inputFields];

            const fieldToUpdate = updatedInputFields.find((field) => field.key === key);
            if (fieldToUpdate) {

                setTransporterDetailsError({
                    ...transporterDetailsError,
                    [field + index]: !value
                })

                if (field === 'pickup') {
                    fieldToUpdate['cost'] = 0;
                }
                fieldToUpdate[field] = value;

                setInputFields(updatedInputFields);

                const data = []
                updatedInputFields && updatedInputFields.map((res) => {
                    return data.push(
                        {
                            id: res.id || null,
                            vendorId: res.vendorId,
                            pickup: res.pickup,
                            pickupVehicleTypeId: res.pickupVehicleTypeId,
                            vehicleNumber: res.vehicleNumber,
                            driverName: res.driverName,
                            driverNumber: res.driverNumber,
                            number: res.number,
                            comment: res.comment,
                            goodsCondition: res.goodsCondition,
                            uploadFile: res.uploadFile,
                            cost: Number(res.cost),
                            packages: Number(res.packages),
                            grossWeight: Number(res.grossWeight),
                            goodsOutRegisterId: 1,
                            sealNumber: 1,
                            dimensionFilePath: ""
                        }
                    )
                })

                handleInputChange(data, { id: 'transporters' });

            }
        };

        const removeInputField = (key) => {

            const updatedInputFields = inputFields.filter((field) => field.key !== key);
            setInputFields(updatedInputFields);
            const data = []
            updatedInputFields && updatedInputFields.map((res) => {
                return data.push(
                    {
                        id: res.id || null,
                        vendorId: res.vendorId,
                        pickup: res.pickup,
                        pickupVehicleTypeId: res.pickupVehicleTypeId,
                        vehicleNumber: res.vehicleNumber,
                        driverName: res.driverName,
                        driverNumber: res.driverNumber,
                        number: res.number,
                        comment: res.comment,
                        goodsCondition: res.goodsCondition,
                        uploadFile: res.uploadFile,
                        cost: Number(res.cost),
                        packages: Number(res.packages),
                        grossWeight: Number(res.grossWeight),
                        goodsOutRegisterId: 1,
                        sealNumber: 1,
                        dimensionFilePath: ""
                    }
                )

            })
            handleInputChange(data, { id: 'transporters' });
        };

        return (
            <div className=''>

                <div className="cmd">
                    <h5 className="m-0 p-0">Add New Transporter</h5>

                </div>

                {inputFields.map((field, key) => (
                    <div className='row' key={field.key}>
                        <div className="col-md-1 cmd" style={{ justifyContent: "left" }}>
                            {
                                key !== 0 ? (
                                    <Button
                                        sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }}
                                        onClick={() => removeInputField(field.key)}
                                        variant='outlined'
                                    >
                                        <Close />
                                    </Button>
                                )
                                    :
                                    <Button sx={{ m: 0, borderRadius: '10px', minWidth: '45px', padding: "5px" }} onClick={addInputField} variant="contained">
                                        <Add />
                                    </Button>
                            }
                        </div>
                        <div className="col-md-11">
                            <div className="row my-2">
                                <div className="col-md-2">
                                    <Dropdown
                                        field={
                                            {
                                                id: 'pickup',
                                                label: formconstant.pickuptype  ,
                                                type: "select",
                                                value: field.pickup,
                                                required: true,
                                                error: transporterDetailsError['pickup' + key],
                                                item: PickUpType,
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'pickup', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                {
                                    field.pickup === 1 &&
                                    <>
                                        <div className="col-md-2">
                                            <Dropdown
                                                field={
                                                    {
                                                        id: 'vendorId',
                                                        label: formconstant.transportname  ,
                                                        type: "select",
                                                        value: field.vendorId,
                                                        required: true,
                                                        error: transporterDetailsError['vendorId' + key],
                                                        item: transportNameItem,
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'vendorId', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                    </>
                                }

                                <div className="col-md-2">
                                    <Dropdown
                                        field={
                                            {
                                                id: 'pickupVehicleTypeId',
                                                label: formconstant.pickupvehicaltype  ,
                                                type: "select",
                                                value: field.pickupVehicleTypeId,
                                                required: true,
                                                error: transporterDetailsError['pickupVehicleTypeId' + key],
                                                item: vehicalTypeItem,
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'pickupVehicleTypeId', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>

                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: 'vehicleNumber',
                                                label: formconstant.vehicalno,
                                                type: "text",
                                                value: field.vehicleNumber,
                                                required: true,
                                                error: transporterDetailsError['vehicleNumber' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'vehicleNumber', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: 'driverName',
                                                label: formconstant.drivername,
                                                type: "text",
                                                value: field.driverName,
                                                required: true,
                                                error: transporterDetailsError['driverName' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'driverName', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: 'driverNumber',
                                                label: formconstant.driverno,
                                                type: "number",
                                                value: field.driverNumber,
                                                required: true,
                                                error: transporterDetailsError['driverNumber' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'driverNumber', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: 'packages',
                                                label: formconstant.packages,
                                                type: "number",
                                                value: field.packages,
                                                required: true,
                                                error: transporterDetailsError['packages' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'packages', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <InputField
                                        field={
                                            {
                                                id: 'grossWeight',
                                                label: formconstant.grossweight,
                                                type: "number",
                                                value: field.grossWeight,
                                                required: true,
                                                error: transporterDetailsError['grossWeight' + key],
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'grossWeight', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <Dropdown
                                        field={
                                            {
                                                id: 'goodsCondition',
                                                label: formconstant.goodsconditions  ,
                                                type: "select",
                                                value: field.goodsCondition,
                                                required: true,
                                                error: transporterDetailsError['goodsCondition' + key],
                                                item: GoodsConditions,
                                                onChange: (value) => {
                                                    handleDuplicateInputChange(field.key, 'goodsCondition', value, key);
                                                }
                                            }
                                        }
                                    />
                                </div>
                                {
                                    (field.goodsCondition === 4 || field.goodsCondition === 5) &&
                                    <div className="col-md-2">
                                        <InputField
                                            field={
                                                {
                                                    id: 'number',
                                                    label: formconstant.number,
                                                    type: "number",
                                                    value: field.number,
                                                    required: true,
                                                    error: transporterDetailsError['number' + key],
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'number', value, key);
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                }
                                {
                                    field.goodsCondition && field.goodsCondition !== 1 && <>
                                        <div className="col-md-3">
                                            <InputField
                                                field={
                                                    {
                                                        id: 'comment',
                                                        label: formconstant.comments,
                                                        type: "comment",
                                                        value: field.comment,
                                                        required: true,
                                                        error: transporterDetailsError['comment' + key],
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'comment', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <InputFileField
                                                className='mt-3'
                                                field={{
                                                    id: "uploadFile",
                                                    label: formconstant.uploadpic,
                                                    type: 'file',
                                                    required: true,
                                                    error: transporterDetailsError['uploadFile' + key],
                                                    helperText: 'Upload Pan Card Required',
                                                    onChange: (value) => {
                                                        handleDuplicateInputChange(field.key, 'uploadFile', value, key);
                                                    }
                                                }}
                                            />
                                        </div>

                                    </>
                                }


                                {
                                    field.pickup === 1 &&
                                    <>
                                        <div className="col-md-2">
                                            <InputField
                                                field={
                                                    {
                                                        id: 'cost',
                                                        label: formconstant.cost,
                                                        type: "number",
                                                        value: field.cost,
                                                        required: true,
                                                        error: transporterDetailsError['cost' + key],
                                                        onChange: (value) => {
                                                            handleDuplicateInputChange(field.key, 'cost', value, key);
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                    </>
                                }

                            </div>
                        </div>

                    </div>
                ))}

            </div>
        );
    }


    return (


        <div className='hr-form-box'>
            <h5>{edit === "updateGoodsOutListData" ? columnConstant.updategoodoutregister : (edit === 'goodsOutListData' || edit === "expectedGoodsOutListData") ? columnConstant.goodsoutregister : columnConstant.goodsinregister}</h5>

            <div className="row">
                <div className="col-md-3">
                    <InputField
                        field={{
                            id: "partyName",
                            label: formconstant.partyname,
                            type: "text",
                            value: GoodInRegisterForm.partyName,
                            required: true,
                            error: GoodInRegisterForm.partyNameerror,
                            onChange: handleInputChange
                        }}
                    />
                </div>
                <div className="col-md-3">
                    <Dropdown
                        field={{
                            id: "jobType",
                            label: formconstant.jobtype  ,
                            type: "text",
                            value: GoodInRegisterForm.jobType,
                            required: true,
                            error: GoodInRegisterForm.jobTypeerror,
                            onChange: handleInputChange,
                            item: ChargeExportImport
                        }}
                    />
                </div>

                <div className="col-md-3">
                    <div className='d-flex gstWrapper'>
                        <InputField
                            field={{
                                id: "packages",
                                label: formconstant.expectedpackages,
                                type: "number",
                                value: GoodInRegisterForm.packages,
                                required: true,
                                error: GoodInRegisterForm.packageserror,
                                onChange: handleInputChange,
                                disable: true,
                            }}
                        />
                        <InputField
                            field={{
                                id: "packages",
                                label: formconstant.packages,
                                type: "number",
                                value: totalPackages,
                                required: true,
                                error: GoodInRegisterForm.packageserror,
                                onChange: handleInputChange,
                                disable: true,
                            }}
                        />
                    </div>

                </div>





                <div className="col-md-3">
                    <div className='gstWrapper d-flex'>
                        <InputField
                            field={{
                                id: "grossWeight",
                                label: formconstant.expectedgrossweight,
                                type: "number",
                                value: GoodInRegisterForm.grossWeight,
                                required: true,
                                error: GoodInRegisterForm.grossWeighterror,
                                onChange: handleInputChange,
                                disable: true,
                            }}
                        />
                        <InputField
                            field={{
                                id: "grossWeight",
                                label: formconstant.grossweight,
                                type: "number",
                                value: totalGrossWeight,
                                required: true,
                                error: GoodInRegisterForm.grossWeighterror,
                                onChange: handleInputChange,
                                disable: true,
                            }}
                        />
                    </div>

                </div>
                <div className="col-md-3">
                    <InputField
                        field={{
                            id: "consoleNumber",
                            label: formconstant.consoleno,
                            type: "number",
                            value: GoodInRegisterForm.consoleNumber,
                            required: false,
                            error: GoodInRegisterForm.consoleNumbererror,
                            onChange: handleInputChange
                        }}
                    />
                </div>
                <div className="col-md-3">
                    <InputField
                        field={{
                            id: "jobNumber",
                            label: formconstant.jobno,
                            type: "number",
                            value: GoodInRegisterForm.jobNumber,
                            required: edit !== "expectedGoodsInRegisterData" && GoodInRegisterForm?.destinationType === 1 || GoodInRegisterForm.pickupStatus === 3 ? true : false,
                            error: GoodInRegisterForm.jobNumbererror,
                            onChange: handleInputChange
                        }}
                    />
                </div>
                <div className="col-md-3">
                    <div className="cmd">
                        <Dropdown
                            field={{
                                id: "dimension",
                                label: formconstant.dimension  ,
                                type: "text",
                                value: GoodInRegisterForm.dimension,
                                required: true,
                                error: GoodInRegisterForm.dimensionerror,
                                onChange: handleInputChange,
                                item: EnquiryDimension
                            }}
                        />
                        <React.Fragment>
                            <div onClick={handleFileOpen}>
                                <Tooltip title="Upload sheet">
                                    <Button className='mt-3 p-1 ms-2' variant='contained' style={{ minWidth: "30px" }} >
                                        <Add />
                                    </Button>
                                </Tooltip>
                            </div>


                            <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleFileClose}
                                aria-describedby="alert-dialog-slide-description"
                                PaperProps={{
                                    sx: {
                                        width: "100%",
                                        maxWidth: "430px!important",
                                    },
                                }}
                                sx={{
                                    '& .MuiDialog-container': {
                                        alignItems: "start"
                                    }
                                }
                                }


                            >
                                <div className=''>
                                    <DialogTitle sx={{ pb: "0" }}>
                                        <div className="cmd">
                                            <div>{columnConstant.dimensionsheet}</div>
                                            <Tooltip title='Close'>
                                                <IconButton onClick={handleFileClose}>
                                                    <Close />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <hr />
                                    </DialogTitle>
                                    <DialogContent>
                                        <InputFileField
                                            className='mt-3'
                                            field={{
                                                id: "",
                                                label: "Upload Sheet",
                                                type: 'file',
                                                required: true,
                                                value: GoodInRegisterForm.dimensionFile,
                                                error: !GoodInRegisterForm.dimensionFile && GoodInRegisterForm.dimensionFileerror,
                                                helperText: 'Upload Pan Card Required',
                                                onChange: (value, field) => {
                                                    setFile(value)
                                                }
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleFileClose} variant='outlined'>Cancel</Button>
                                        <Button onClick={handleFileSubmit} style={{ background: 'var(--color-main)', color: '#fff' }}>Submit</Button>
                                    </DialogActions>
                                </div>
                            </Dialog>
                        </React.Fragment >
                    </div>
                </div>
                <div className="col-md-3">
                    <Dropdown
                        field={{
                            id: "actualShipper",
                            label: formconstant.actualshipper,
                            type: "text",
                            value: GoodInRegisterForm.actualShipper,
                            required: true,
                            error: GoodInRegisterForm.actualShippererror,
                            onChange: handleInputChange,
                            item: actualShipperItem
                        }}
                    />
                </div>
                <div className="col-md-3">
                    <InputField
                        field={{
                            id: "mawbNumber",
                            label: formconstant.mawbno,
                            type: "number",
                            value: GoodInRegisterForm.mawbNumber,
                            required: false,
                            error: GoodInRegisterForm.mawbNumbererror,
                            onChange: handleInputChange,
                            // disable: edit === 'expectedGoodsInRegisterData' ? true : false,
                            disable: true,
                        }}
                    />
                </div>
                <div className="col-md-3">
                    <InputField
                        field={{
                            id: "hawbNumber",
                            label: formconstant.hawbno,
                            type: "number",
                            value: GoodInRegisterForm.hawbNumber,
                            required: false,
                            error: GoodInRegisterForm.hawbNumbererror,
                            onChange: handleInputChange,
                            // disable: edit === 'expectedGoodsInRegisterData' ? true : false,
                            disable: true,
                        }}
                    />
                </div>

                <div className="col-md-3">
                    <InputField
                        field={{
                            id: "totalCost",
                            label: formconstant.cost,
                            type: "number",
                            value: totalCost,
                            required: true,
                            error: GoodInRegisterForm.totalCosterror,
                            disable: true,
                            onChange: handleInputChange
                        }}
                    />
                </div>
                {
                    GoodInRegisterForm?.destinationType !== 1 && <div className="col-md-3">
                        <Dropdown
                            field={{
                                id: "pickupStatus",
                                label: formconstant.pickupstatus  ,
                                type: "text",
                                value: GoodInRegisterForm.pickupStatus,
                                required: true,
                                error: GoodInRegisterForm.pickupStatuserror,
                                onChange: handleInputChange,
                                item: (edit === 'goodsOutListData' || edit === 'expectedGoodsOutListData') ? PickupStatusForOut : PickupStatus
                            }}
                        />
                    </div>
                }


                {
                    (edit === 'goodsOutListData' || edit === 'expectedGoodsOutListData' || edit === "updateGoodsOutListData") && <div className="col-md-3">
                        <Dropdown
                            field={{
                                id: "challanType",
                                label: formconstant.challanstatus  ,
                                type: "text",
                                value: GoodInRegisterForm.challanType,
                                required: true,
                                error: GoodInRegisterForm.challanTypeerror,
                                onChange: handleInputChange,
                                item: ChallanType
                            }}
                        />
                    </div>
                }

            </div>

            <hr />

            {TransportMultiDetails()}

            <React.Fragment>
                <AlertShow
                    option={{
                        button: (edit === 'goodsOutListData' || edit === "expectedGoodsOutListData" || edit === "updateGoodsOutListData") ? true : false
                    }}
                    callback={showAlert}
                    setFalse={() => setShowAlert(false)}
                    submitCallBack={() => { setShowAlert(false); SubmitAPI() }}
                    message={
                        <div>
                            {
                                `
                             ${totalGrossWeight > GoodInRegisterForm.grossWeight ? "Gross Weight," : ""}
                             ${totalPackages > GoodInRegisterForm.packages ? "Packages," : ""} 
                              not matching !  `
                            }
                            <br />
                            {
                                (edit === 'goodsOutListData' || edit === "expectedGoodsOutListData" || edit === "updateGoodsOutListData") ? "" : "Are You Still want to submit the form"
                            }

                        </div>
                    }
                />
            </React.Fragment>

            <div className='cmd'>
                <div></div>
                <Button sx={{ m: 2, borderRadius: "10px", }} onClick={handleFormSubmit} variant="contained">Submit</Button>
            </div>
        </div>
    )
}

export default CreateGoodInRegister;
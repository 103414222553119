
import { useEffect, useState } from "react";

import { Button } from '@mui/material';

import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import Dropdown, { DateField, InputField, InputFileField } from "../../component/controls";
import { Add, Close } from "@mui/icons-material";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
const VendorOpeningBalance = () => {
    const [customerDetail, setCustomerDetail] = useState({
        customerName: "",
        cusomerType: "",
        globalnetwork: "",
        gst: "",
    })

    const handleBasicInputChange = () => {
        console.log('')
    }

    const handleFormSubmit = () => {
        console.log('')
    }
    return <>
        <div className="hr-form-box">
            <h4>CREATE TDS EXEMPTIONS</h4>
            <div className='check-customer-table'>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Financial Year</th>
                            <th>Date</th>
                            <th>Balance Type</th>
                            <th>Balance Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>xxxx</td>
                            <td>12/23/23</td>
                            <td>IndCurrency</td>
                            <td>Account</td>
                            <td>XX56XX</td>

                        </tr>
                    </tbody>
                </table>
            </div>
            <VendorBalaceForm onChange={handleBasicInputChange} />
            <div className="cmd">

            </div>
            <Invoicetable />
            <Button sx={{ m: 2, borderRadius: "10px" }} onClick={() => Navigate('')} variant="contained">Save Balance</Button>
        </div>
    </>
}
export default VendorOpeningBalance

function VendorBalaceForm({ onChange }) {
    return (
        <div className=' mt-4'>
            <div className="row">

                <div className="col-md-3">
                    <Dropdown
                        field={
                            {
                                id: "financial",
                                label: "Financial Year",
                                value: '',
                                required: true,
                                error: "",
                                helperText: 'Balance Type Required',
                                item: [
                                    {
                                        name: 'FY 23-24',
                                        value: 1,
                                    },
                                    {
                                        name: 'FY 22-23',
                                        value: 2,
                                    }
                                ],
                                onChange: ({})
                            }
                        }
                    />
                </div>
                <div className="col-md-3 mt-2">
                    <DateField
                        field={{
                            id: "",
                            label: "Date ",
                            value: '',
                            required: true,
                            error: false,
                            onChange: () => { }
                        }}
                    />

                </div>
                <div className="col-md-3">
                    <Dropdown
                        field={
                            {
                                id: "balancetype",
                                label: "Balance Type",
                                value: '',
                                required: true,
                                error: "",
                                helperText: 'Balance Type Required',
                                item: [
                                    {
                                        name: 'CR',
                                        value: 1,
                                    },
                                    {
                                        name: 'DR',
                                        value: 2,
                                    }
                                ],
                                onChange: ({})
                            }
                        }
                    />
                </div>

                <div className="col-md-3">
                    <InputField
                        field={
                            {
                                id: "balanceamount",
                                label: "Balance Amount",
                                type: 'text',
                                value: "",
                                required: true,
                                error: "",

                                onChange: (value) => {
                                    // handleInputChange(field.key, 'email', value);
                                }
                            }
                        }
                    />
                </div>
            </div>

        </div>
    );
}


function Invoicetable({ }) {
    return (
        <div className='check-customer-table mt-4'>

            <table>
                <thead>
                    <tr>
                        <th><AddCircleIcon /></th>
                        <th>Invoice Number</th>
                        <th>Invoice Date</th>
                        <th>Invoice Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><DeleteIcon /></td>

                        <td> <div className="">
                            <InputField
                                field={
                                    {
                                        id: "balanceamount",
                                        label: "",
                                        type: 'text',
                                        value: "",
                                        required: true,
                                        error: "",

                                        onChange: (value) => {
                                            // handleInputChange(field.key, 'email', value);
                                        }
                                    }
                                }
                            />
                        </div></td>
                        <td>  <div className=" mt-2">
                            <DateField
                                field={{
                                    id: "",
                                    label: "Date ",
                                    value: '',
                                    required: true,
                                    error: false,
                                    onChange: () => { }
                                }}
                            />

                        </div></td>
                        <td> <div className="">
                            <InputField
                                field={
                                    {
                                        id: "balanceamount",
                                        label: "",
                                        type: 'text',
                                        value: "",
                                        required: true,
                                        error: "",

                                        onChange: (value) => {
                                            // handleInputChange(field.key, 'email', value);
                                        }
                                    }
                                }
                            />
                        </div></td>

                    </tr>
                </tbody>
            </table>
        </div>
    )
}